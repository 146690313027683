import { Dropdown, Menu } from 'antd';
import { every, map, mapValues } from 'lodash';
import React from 'react';
import { KeyValueMap } from '../../common/types/KeyValueMap.type';
import { selectedDocumentSets } from '../utils';
import { SelectableDocumentSet } from './search-form.component';

type DocumentSelectorProps = {
  documentSetSelections: KeyValueMap<SelectableDocumentSet>;
  selectedDocumentSetsUpdate: (
    selectedDocumentSets: KeyValueMap<SelectableDocumentSet>,
  ) => void;
};
type DocumentSelectorState = {
  visible: boolean;
  allSelected: boolean;
};
export class DocumentSetSelector extends React.Component<
  DocumentSelectorProps,
  DocumentSelectorState
> {
  readonly constantMenuItems: React.ReactNode[] = [
    <Menu.Item key={'all'}>All</Menu.Item>,
  ];

  constructor(props: DocumentSelectorProps) {
    super(props);
    this.state = {
      visible: false,
      allSelected: this.allAreSelected(this.props.documentSetSelections),
    };
  }

  handleMenuClick = e => {
    const updatedSelections = this.updateSelectedDocumentSets(e.key);
    this.props.selectedDocumentSetsUpdate(updatedSelections);
  };

  updateSelectedDocumentSets = (
    docId: string,
  ): KeyValueMap<SelectableDocumentSet> => {
    if (docId === 'all') {
      const newSelectedState = !this.state.allSelected;
      this.setState({ allSelected: newSelectedState });
      return mapValues(this.props.documentSetSelections, val => ({
        ...val,
        selected: newSelectedState,
      }));
    } else {
      const updateItem = this.props.documentSetSelections[parseInt(docId, 10)];
      const update = !updateItem.selected;
      const updatedItem = { ...updateItem, selected: update };
      const currentState = {
        ...this.props.documentSetSelections,
        [docId]: updatedItem,
      };
      this.setState({ allSelected: this.allAreSelected(currentState) });
      return currentState;
    }
  };

  allAreSelected(docs: KeyValueMap<SelectableDocumentSet>): boolean {
    return every(docs, doc => doc.selected);
  }

  selectedDocsToMenu = () => {
    const sortedDocSets = map(this.props.documentSetSelections, (v, k) => ({
      documentSetId: k,
      ...v,
    })).sort((ds1, ds2) =>
      ds1.name.toLowerCase() > ds2.name.toLowerCase() ? 1 : -1,
    );

    return [
      ...this.constantMenuItems,
      ...map(sortedDocSets, docSet => {
        return (
          <Menu.Item key={`${docSet.documentSetId}`}>{docSet.name}</Menu.Item>
        );
      }),
    ];
  };

  selectedDocs = () => {
    return [
      ...this.allSelectedPlaceholder(),
      ...map(
        selectedDocumentSets(this.props.documentSetSelections),
        (_, k) => `${k}`,
      ),
    ];
  };

  allSelectedPlaceholder = (): string[] => {
    return this.state.allSelected ? ['all'] : [];
  };

  handleVisibleChange = (flag: boolean | undefined) => {
    this.setState({ visible: !!flag });
  };

  numberSelected() {
    return this.selectedDocs().length - this.allSelectedPlaceholder().length;
  }

  render() {
    const menu = (
      <Menu
        onClick={this.handleMenuClick}
        multiple={true}
        selectedKeys={this.selectedDocs()}
      >
        {this.selectedDocsToMenu()}
      </Menu>
    );

    return (
      <Dropdown
        overlay={menu}
        onVisibleChange={this.handleVisibleChange}
        visible={this.state.visible}
        trigger={['click']}
      >
        {this.props.children}
      </Dropdown>
    );
  }

  componentDidUpdate(prevProps: DocumentSelectorProps) {
    if (prevProps.documentSetSelections !== this.props.documentSetSelections) {
      if (
        this.allAreSelected(this.props.documentSetSelections) &&
        !this.state.allSelected
      ) {
        this.setState({ allSelected: true });
      }
    }
  }
}
