import { connect } from 'react-redux';
import { selectCurrentUserGroupId } from '../../accounts/selectors/groups.selectors';
import { AppState } from '../../app-state';
import { createQueue } from '../actions/queue.actions';
import {
  QueueCreatorComponent,
  QueueCreatorFormValues,
  QueueCreatorProps,
} from '../components/queue-creator.component';

export const QueueCreator = connect(
  (state: AppState) => ({
    currentUserGroupId: selectCurrentUserGroupId(state),
  }),
  (dispatch): Pick<QueueCreatorProps, 'onQueueCreate'> => ({
    onQueueCreate: (v: QueueCreatorFormValues) =>
      dispatch(createQueue(v.queueName, v.adminGroups, v.workerGroups)),
  }),
)(QueueCreatorComponent);
