import { Observable } from 'rxjs';
import { AjaxResponse, ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import { AppState } from '../../app-state';
import { CONTENT_TYPE_HEADERS, authHeaders } from '../../common/utils/fetch';
import { config } from '../../config/application.config';
import { DomainLookup } from '../../domain/models/lookup-context.model';
import { EditorConfig } from '../models/annotation-schema-editor.model';
import { ValidatorConfig } from '../models/annotation-schema-validator.model';
import { AnnotationSchema } from '../models/annotation-schema.model';

export type AnnotationCategoryResource = {
  annotationCategoryId: number;
  title: string;
  createdAt: string;
  updatedAt: string;
};

export type AnnotationTypeResource = {
  annotationTypeId: number;
  key: string;
  title: string;
  keyboardShortcut: string;
  color: string;
  annotationSchemaId: number;
  annotationCategoryId?: number;
  validatorConfigs: ValidatorConfig[];
  editorConfigs: EditorConfig[];
  createdAt?: string;
  updatedAt?: string;
};

export type AnnotationRelationRuleResource = {
  annotationRelationRuleId: number;
  leftAnnotationTypeId: number;
  rightAnnotationTypeId: number;
  description?: string;
  relationType: string;
  required: boolean;
  createdAt: string;
  updatedAt: string;
};

export interface AnnotationSchemaEditor {
  annotationSchemaEditorId: number;
  annotationSchemaId: number;
  annotationType: string;
  editor: string;
  editorConfig: EditorConfig;
  createdAt: string;
}

export type AnnotationSchemaResource = {
  annotationSchemaId: number;
  groupId: number;
  title: string;
  documentSetMatcher?: string;
  classificationMatcher?: string;
  createdAt: string;
  updatedAt: string;
  categories: AnnotationCategoryResource[];
  annotationTypes: AnnotationTypeResource[];
  relationRules: AnnotationRelationRuleResource[];
  lookups: DomainLookup[];
};

export type AnnotationTypePayload = {
  id: number;
  key: string;
  title: string;
  keyboardShortcut?: string;
  color: string;
  category?: string;
  allowedChildKeys: string[];
  validatorConfigs: ValidatorConfig[];
  editorConfigs: EditorConfig[];
};

const schemasUrl = `${config.annotationService.url}/annotation-schemas`;
const schemaIdUrl = (schemaId: number) => `${schemasUrl}/${schemaId}`;
const typesUrl = (schemaId: number) => `${schemaIdUrl(schemaId)}/types`;
const rulesUrl = (schemaId: number) => `${schemaIdUrl(schemaId)}/rules`;
const updateSchemaUrl = (schemaId: number) => `${schemaIdUrl(schemaId)}/update`;

export const AnnotationSchemaResource = {
  schemasUrl,

  all(state: AppState): Observable<AnnotationSchemaResource[]> {
    return ajax
      .getJSON<{ schemas: AnnotationSchemaResource[] }>(
        schemasUrl,
        authHeaders(state),
      )
      .pipe(map(response => response.schemas));
  },

  create(
    state: AppState,
    title: string,
    groupId: number,
    documentSetMatcher?: string,
    documentTypeMatcher?: string,
  ): Observable<AnnotationSchemaResource> {
    const gId = +groupId;
    const payload = {
      title,
      groupId: gId,
      documentSetMatcher,
      classificationMatcher: documentTypeMatcher,
    };
    return ajax
      .post(schemasUrl, payload, {
        ...authHeaders(state),
        ...CONTENT_TYPE_HEADERS.JSON,
      })
      .pipe(map((r: AjaxResponse) => r.response));
  },

  updateSchema(
    state: AppState,
    schema: AnnotationSchema,
  ): Observable<AnnotationSchema> {
    return ajax
      .post(updateSchemaUrl(schema.annotationSchemaId), schema, {
        ...authHeaders(state),
        ...CONTENT_TYPE_HEADERS.JSON,
      })
      .pipe(map((r: AjaxResponse) => r.response));
  },

  deleteSchema(state: AppState, schemaId: number) {
    return ajax.delete(schemaIdUrl(schemaId), {
      ...authHeaders(state),
    });
  },

  putSchemaTypes(
    state: AppState,
    schemaId: number,
    types: AnnotationTypePayload[],
  ): Observable<AnnotationTypeResource[]> {
    return ajax
      .put(
        typesUrl(schemaId),
        { types },
        { ...authHeaders(state), ...CONTENT_TYPE_HEADERS.JSON },
      )
      .pipe(map(r => r.response));
  },

  putRules(
    schemaId: number,
    rules: AnnotationRelationRuleResource[],
    state: AppState,
  ): Observable<AnnotationRelationRuleResource[]> {
    return ajax
      .put(
        rulesUrl(schemaId),
        { rules },
        { ...authHeaders(state), ...CONTENT_TYPE_HEADERS.JSON },
      )
      .pipe(map((_: AjaxResponse) => rules));
  },
};
