import { getType } from 'typesafe-actions';
import { HighlightCollection } from '../../text-highlighter/models/highlight-collection.model';
import {
  beginAnnotationEditing,
  clearAnnotationSelection,
  emphasizeAnnotationHighlight,
  endAnnotationEditing,
  removeAnnotationHighlightEmphasis,
  setAnnotationHighlights,
  setAnnotationSelection,
  setFocusedAnnotationId,
} from '../actions/ui-annotations.actions';
import { UIAnnotationsActions } from '../actions/ui-annotations.types';
import { AnnotationSelection } from '../models/annotation-selection.model';
import { Annotation } from '../models/annotation.model';
import { AppState } from '../../app-state';
import { selectActiveSchemaAnnotationType } from '../selectors/annotation-schema.selectors';

export type AnnotationSelectionContext = 'pdf-viewer' | 'text-viewer';
export type UiAnnotationsState = {
  editingAnnotation: Annotation | null;
  highlightColor: string | null;
  selection: AnnotationSelection[];

  // Where is the selection coming from?
  selectionContext: AnnotationSelectionContext | null;
  lastSelection: AnnotationSelection[];

  // Used as the presently emphasized annotation within the document text.
  emphasizeAnnotationId: string | null;

  // Used as the presently focused annotation within the annotation table.
  focusedAnnotationId: string | null;

  // Annotation highlights currently being rendered.
  highlights: HighlightCollection;
};

export const defaultUiAnnotationsState = {
  editingAnnotation: null,
  highlightColor: null,
  selection: [],
  lastSelection: [],
  emphasizeAnnotationId: null,
  focusedAnnotationId: null,
  highlights: new HighlightCollection([]),
  selectionContext: null,
};

export function uiAnnotationsReducer(
  state: UiAnnotationsState = defaultUiAnnotationsState,
  action: UIAnnotationsActions,
  appState: AppState,
): UiAnnotationsState {
  switch (action.type) {
    case getType(beginAnnotationEditing): {
      const { annotation } = action.payload;
      const typeKey = annotation.type;
      const annotationType = selectActiveSchemaAnnotationType(
        appState,
        typeKey,
      );
      const highlightColor = annotationType.color;
      return {
        ...state,
        editingAnnotation: annotation,
        highlightColor,
      };
    }

    case getType(endAnnotationEditing):
      return {
        ...state,
        editingAnnotation: null,
        highlightColor: null,
      };

    case getType(setAnnotationSelection):
      return {
        ...state,
        selection: action.payload.selection,
        lastSelection: state.selection,
        selectionContext: action.payload.context,
      };

    case getType(clearAnnotationSelection):
      return {
        ...state,
        selection: [],
        // If selection is empty, don't remove existing lastSelection.
        lastSelection: state.selection.length
          ? state.selection
          : state.lastSelection,
        selectionContext: null,
      };

    case getType(emphasizeAnnotationHighlight):
      return {
        ...state,
        emphasizeAnnotationId: action.payload.annotationId,
      };

    case getType(removeAnnotationHighlightEmphasis):
      return {
        ...state,
        emphasizeAnnotationId: null,
      };

    case getType(setAnnotationHighlights):
      return {
        ...state,
        highlights: action.payload.highlights,
      };

    case getType(setFocusedAnnotationId):
      return {
        ...state,
        focusedAnnotationId: action.payload.annotationId,
      };

    default:
      return state;
  }
}
