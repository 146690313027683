import { RcFile } from 'antd/lib/upload';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Tooltip, Upload } from 'antd';
import React from 'react';
import PapaParse, { ParseConfig } from 'papaparse';
import { isEqual } from 'lodash';
import { HttpRequestHeader } from 'request';
import { PermissionResource } from '../../accounts/models/permission.model';

export type CsvUploadProps = {
  expectedColumns: string[];
  uploadUrl: string;
  authHeaders: HttpRequestHeader;
  handleData?: (data: object[]) => void;
  handleFile?: (csvFile: RcFile) => void;
  handleFailure?: (failure: UploadFailure) => void;
  handleMetadata?: (metadata: UploadMetadata) => void;
  onStateChange?: () => void;
  resource: PermissionResource;
  resourceId: number;
  isPermitted: (resource: PermissionResource, resourceId: number) => boolean;
  displayErrorNotification: (title: string, error: string) => void;
};

export const CsvUpload = (props: CsvUploadProps) => {
  const displayErrorNotification = (f: UploadFailure) =>
    props.displayErrorNotification('Upload failed', f.message);
  const beforeUpload = handleUpload(
    props.expectedColumns,
    props.handleData,
    props.handleFile,
    props.handleFailure || displayErrorNotification,
    props.handleMetadata,
  );
  const noopFunc = () => null;
  const onStateChange = props.onStateChange ? props.onStateChange : noopFunc;

  const disabled = !props.isPermitted(props.resource, props.resourceId);

  return (
    <Tooltip title="upload" mouseEnterDelay={0.5}>
      <Upload
        accept=".csv"
        action={props.uploadUrl}
        headers={props.authHeaders}
        beforeUpload={beforeUpload}
        onChange={onStateChange}
        disabled={disabled}
      >
        <Button disabled={disabled}>
          <UploadOutlined />
        </Button>
      </Upload>
    </Tooltip>
  );
};

export type UploadFailure = {
  message: string;
  status: string;
};
export type UploadMetadata = {
  numRows: number;
  numColumns: number;
};

function noop<T>() {
  return (_: T) => null;
}

const handleUpload = (
  expectedColumns: string[],
  handleData?: (data: object[]) => void,
  handleFile?: (csvFile: RcFile) => void,
  handleFailure?: (failure: UploadFailure) => void,
  handleMetadata?: (metadata: UploadMetadata) => void,
) => async (csvFile: RcFile) => {
  // result boolean determines whether to proceed with upload
  if (handleFile) {
    handleFile(csvFile);
  }
  const hData = handleData || noop<object[]>();
  const hFailure = handleFailure || noop<UploadFailure>();
  const hMetadata = handleMetadata || noop<UploadMetadata>();
  const parserOptions: ParseConfig = {
    header: true,
    skipEmptyLines: 'greedy',
    preview: handleData ? 0 : 2,
  };

  const handleParse = csvData => {
    const columns = csvData.meta.fields;
    const validHeader: boolean = isEqual(
      columns.sort(),
      expectedColumns.sort(),
    );
    if (!validHeader) {
      hFailure({
        status: 'failure',
        message: `Expected header: ${expectedColumns}`,
      });
      return false;
    } else {
      hMetadata({
        numRows: csvData.data.length,
        numColumns: Object.keys(columns).length,
      });
      hData(csvData.data);
      return true;
    }
  };
  await papaParse(csvFile, parserOptions, handleParse);
  return;
};

async function papaParse(file, options, handleData) {
  const result = await new Promise((resolve, reject) => {
    const complete = (results, _) => {
      handleData(results) ? resolve(results) : reject();
    };
    const opts = { ...options, complete };
    PapaParse.parse(file, opts);
  });
  return result;
}
