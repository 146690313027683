import { createAction } from 'typesafe-actions';
import { ExportParams, ExportProgress } from '../models/export.model';

enum Types {
  SUBMIT_EXPORT_JOB = 'Export: submit job',
  SET_EXTERNAL_JOB_ID = 'Export: set external job id',
  SET_EXPORT_ERROR = 'Export: set error',
  REFRESH_EXPORT_PROGRESS = 'Export: refresh progress',
  DOWNLOAD_EXPORT = 'Export: download',
  MARK_DOWNLOAD_COMPLETE = 'Export: mark download complete',
  MARK_DOWNLOADING = 'Export: mark downloading',
  CANCEL_EXPORT_JOB = 'Export: cancel job',
  DELETE_EXPORT_JOB = 'Export: delete job',
  REFRESH_EXPORT_JOB = 'Export: refresh job',
}

export const submitExportJob = createAction(
  Types.SUBMIT_EXPORT_JOB,
  resolvePayload => (jobId: string, query: object, params: ExportParams) =>
    resolvePayload({ jobId, query, params }),
);

export const refreshExportJob = createAction(
  Types.REFRESH_EXPORT_JOB,
  resolvePayload => (jobId: string) => resolvePayload({ jobId }),
);

export const setExternalJobId = createAction(
  Types.SET_EXTERNAL_JOB_ID,
  resolvePayload => (jobId: string, externalJobId: string) =>
    resolvePayload({ jobId, externalJobId }),
);

export const setExportError = createAction(
  Types.SET_EXPORT_ERROR,
  resolvePayload => (jobId: string, error: string) =>
    resolvePayload({ jobId, error }),
);

export const refreshExportProgress = createAction(
  Types.REFRESH_EXPORT_PROGRESS,
  resolvePayload => (jobId: string, progress: ExportProgress) =>
    resolvePayload({ jobId, progress }),
);

export const downloadExport = createAction(
  Types.DOWNLOAD_EXPORT,
  resolvePayload => (jobId: string) => resolvePayload({ jobId }),
);

export const markDownloadComplete = createAction(
  Types.MARK_DOWNLOAD_COMPLETE,
  resolvePayload => (jobId: string) => resolvePayload({ jobId }),
);

export const cancelExportJob = createAction(
  Types.CANCEL_EXPORT_JOB,
  resolvePayload => (jobId: string) => resolvePayload({ jobId }),
);

export const deleteExportJob = createAction(
  Types.DELETE_EXPORT_JOB,
  resolvePayload => (jobId: string) => resolvePayload({ jobId }),
);

export const markDownloading = createAction(
  Types.MARK_DOWNLOADING,
  resolvePayload => (jobId: string) => resolvePayload({ jobId }),
);
