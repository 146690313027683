export type LabelCategory =
  | 'Document Metadata'
  | 'Party Information'
  | 'Dates and Times'
  | 'Legal Description'
  | 'Legal Clauses'
  | 'Legal Tract Aliases'
  | 'Miscellaneous';

export const PREDICTION_LABEL_CATEGORIES: {
  [labelName: string]: LabelCategory;
} = {
  'document-classification': 'Document Metadata',

  'grantee-address': 'Party Information',
  'grantee-name': 'Party Information',
  'grantor-address': 'Party Information',
  'grantor-name': 'Party Information',

  'effective-date': 'Dates and Times',
  'primary-term-length': 'Dates and Times',
  'recorded-date': 'Dates and Times',
  'extension-term-length': 'Dates and Times',

  Subdivision: 'Legal Description',
  abstract: 'Legal Description',
  acreage: 'Legal Description',
  block: 'Legal Description',
  'legal-description': 'Legal Description',
  league: 'Legal Description',
  lot: 'Legal Description',
  porcion: 'Legal Description',
  'quarter-call': 'Legal Description',
  ranch: 'Legal Description',
  range: 'Legal Description',
  'section-number': 'Legal Description',
  share: 'Legal Description',
  survey: 'Legal Description',
  'survey-number': 'Legal Description',
  township: 'Legal Description',

  'depth-clause': 'Legal Clauses',
  'cessation-clause': 'Legal Clauses',
  'continuous-drilling-clause': 'Legal Clauses',
  'pooling-clause': 'Legal Clauses',
  'pugh-clause': 'Legal Clauses',
  'salt-water-disposal': 'Legal Clauses',

  'Abstract Alias': 'Legal Tract Aliases',
  'Block Section Alias': 'Legal Tract Aliases',
  'League Block Alias': 'Legal Tract Aliases',
  'League Labor Alias': 'Legal Tract Aliases',
  'League Lot Alias': 'Legal Tract Aliases',
  'League Tract Alias': 'Legal Tract Aliases',
};

export const PREDICTION_LABEL_DISPLAYS: { [labelName: string]: string } = {
  'cessation-clause': 'Cessation of Production',
  'continuous-drilling-clause': 'Continuous Drilling',
  'primary-term-length': 'Primary Term',
  'extension-term-length': 'Extension Term',
  'legal-description': 'Legal Description Paragraph',
};
