import { filter, omit, some, values } from 'lodash';
import { getType } from 'typesafe-actions';
import { KeyValueMap } from '../../common/types/KeyValueMap.type';
import {
  allSearchResultsChecked,
  changeSearchResultOrder,
  clearSelectedSearchResults,
  pageChange,
  populateResponse,
  searchResultSelect,
  submitSearch,
} from '../actions/search.actions';
import { SearchActions } from '../actions/search.types';
import {
  FormValues,
  SelectableDocumentSet,
  defaultFormValues,
} from '../components/search-form.component';
import { QueryResponse } from '../models/query-response.model';
import {
  QueryCondition,
  isContainsQueryCondition,
} from '../models/query.model';
import { queryBody } from '../models/query.model';

export type SelectedResultsState = {
  selectedIds: number[];
  inverseSelected: boolean;
};

export type OrderBy = {
  field: string;
  desc: boolean;
};

export type SearchState = {
  currentPage: number;
  pageSize: number;
  fragmentSize: number;
  orderBy: OrderBy;
  searchableDocumentSets: KeyValueMap<SelectableDocumentSet>;
  formValues: FormValues;
  queryConditions: KeyValueMap<QueryCondition>;
  showAdvanced: boolean;
  query: { query: object } | null;
  loading: boolean;
  results: QueryResponse | null;
  selectedResults: SelectedResultsState;
};

export const defaultSearchState: SearchState = {
  currentPage: 1,
  pageSize: 10,
  fragmentSize: 500,
  orderBy: {
    field: 'created_at',
    desc: true,
  },
  searchableDocumentSets: {},
  formValues: defaultFormValues,
  queryConditions: {},
  showAdvanced: false,
  loading: false,
  query: null,
  results: null,
  selectedResults: {
    selectedIds: [],
    inverseSelected: false,
  },
};

export function searchReducer(
  state: SearchState = defaultSearchState,
  action: SearchActions,
): SearchState {
  switch (action.type) {
    case getType(submitSearch):
      return {
        ...state,
        searchableDocumentSets: action.payload.documentSetsSelected,
        loading: true,
        currentPage: 1,
        formValues: action.payload.formValues,
        queryConditions: action.payload.queryConditions,
        showAdvanced: action.payload.showAdvanced,
        query: conditionsToQuery(action.payload.queryConditions),
      };

    case getType(populateResponse):
      return {
        ...state,
        loading: false,
        results: action.payload.response,
      };

    case getType(pageChange):
      return {
        ...state,
        loading: true,
        currentPage: action.payload.page,
      };

    case getType(searchResultSelect):
      const selectedResults = state.selectedResults;
      return {
        ...state,
        selectedResults: {
          ...selectedResults,
          selectedIds: resolveNewIds(
            selectedResults.selectedIds,
            action.payload.id,
          ),
        },
      };

    case getType(allSearchResultsChecked):
      return {
        ...state,
        selectedResults: {
          inverseSelected: !state.selectedResults.inverseSelected,
          selectedIds: [],
        },
      };

    case getType(clearSelectedSearchResults):
      return {
        ...state,
        selectedResults: defaultSearchState.selectedResults,
      };

    case getType(changeSearchResultOrder):
      return {
        ...state,
        orderBy: {
          field: action.payload.field,
          desc: action.payload.desc,
        },
      };

    default:
      return state;
  }
}

export function conditionsToQuery(conditions: KeyValueMap<QueryCondition>) {
  const withoutQueryString = values(omit(conditions, 'queryString'));
  const queryString: QueryCondition | undefined = conditions.queryString;
  if (!queryString) {
    return queryBody('', withoutQueryString);
  }
  const [resolvedQueryString] = isContainsQueryCondition(queryString)
    ? queryString.value
    : [undefined];
  return queryBody(resolvedQueryString, withoutQueryString);
}

function resolveNewIds(ids: number[], id: number): number[] {
  const alreadyInThere = some(ids, x => x === id);
  const newBase = filter(ids, x => x !== id);
  return alreadyInThere ? newBase : [...newBase, id];
}
