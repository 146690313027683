import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../app-state';
import { selectSyncViews } from '../selectors/page-controls.selectors';
import { toggleSyncViews } from '../actions/page-controls.actions';
import { Button, Tooltip } from 'antd';
import { SwapOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import * as Colors from '../../common/colors';

const ButtonDiv = styled.div``;

export type PageViewSyncButtonProps = {
  syncViews: boolean;
  toggleSyncViews: () => void;
};

const PageViewsSyncButton = (props: PageViewSyncButtonProps) => {
  const { syncViews } = props;
  const ButtonStyle = {
    backgroundColor: syncViews ? Colors.Blue1 : Colors.White,
    color: syncViews ? Colors.White : Colors.Blue1,
    width: '50px',
  };

  const tooltipTitle = syncViews
    ? 'Sync Panes (enabled)'
    : 'Sync Panes (disabled)';
  return (
    <ButtonDiv>
      <Tooltip title={tooltipTitle} mouseEnterDelay={0.5}>
        <Button
          style={ButtonStyle}
          icon={<SwapOutlined />}
          onClick={props.toggleSyncViews}
        ></Button>
      </Tooltip>
    </ButtonDiv>
  );
};

export default connect(
  (state: AppState) => ({
    syncViews: selectSyncViews(state),
  }),
  {
    toggleSyncViews,
  },
)(PageViewsSyncButton);
