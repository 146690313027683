import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { AppState } from '../../app-state';
import { selectSourceDocumentIsEditable } from '../../documents/selectors/source-document.selectors';
import { setActiveAnnotationSchema } from '../actions/annotation-schemas.actions';
import { createAnnotationFromSelection } from '../actions/annotations.actions';
import { clearAnnotationSelection } from '../actions/ui-annotations.actions';
import {
  AnnotationListPopover,
  PopoverProps,
} from '../components/annotation-list-popover.component';
import { AnnotationSchema } from '../models/annotation-schema.model';
import { AnnotationType } from '../models/annotation-type.model';
import {
  selectActiveAnnotationSchema,
  selectUserSelectableSchemasArray,
} from '../selectors/annotation-schema.selectors';
import {
  selectAnnotationSelectionExists,
  selectAnnotationTooltipPlacement,
} from '../selectors/ui.selectors';

const selectAnnotationPaneIsVisible = createSelector(
  [selectSourceDocumentIsEditable, selectAnnotationSelectionExists],
  (editable, exists) => editable && exists,
);

export const AnnotationPopover = connect(
  (state: AppState) => ({
    visible: selectAnnotationPaneIsVisible(state),
    placement: selectAnnotationTooltipPlacement(state),
    schema: selectActiveAnnotationSchema(state),
    allSchemas: selectUserSelectableSchemasArray(state),
  }),
  dispatch => ({
    onAnnotationTypeSelected: (type: AnnotationType) => {
      dispatch(createAnnotationFromSelection(type));
      dispatch(clearAnnotationSelection());
    },
    onSelectSchema: (schema: AnnotationSchema) => {
      dispatch(setActiveAnnotationSchema(schema));
    },
    onClose: () => {
      dispatch(clearAnnotationSelection());
    },
  }),
)((props: PopoverProps) => <AnnotationListPopover {...props} />);
// Render function gets around poor Redux typing.
