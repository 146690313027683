import { ResourceIdRangeHandler } from '../../common/utils/resource-id-range-handler';
import { Permission } from './permission.model';

export type SimpleRole = {
  action: string;
  resource: string;
  resourceId: number;
  overriddenBy?: string;
};

export const SimpleRole = {
  fromPermission(p: Permission): SimpleRole | undefined {
    const resId = ResourceIdRangeHandler.singleIdFromResourceRange(
      p.resourceIdRange,
    );
    if (typeof resId === 'number') {
      return {
        action: p.action,
        resource: p.resource,
        resourceId: resId as number,
      };
    } else return undefined;
  },

  fromPermissions(permissions: Permission[]): SimpleRole[] {
    const simpleRoles: SimpleRole[] = permissions
      .map(p => SimpleRole.fromPermission(p))
      .filter(r => r !== undefined)
      .map(r => r as SimpleRole);
    const distinctRoles = [...new Set(simpleRoles)];
    return distinctRoles;
  },
};
