import { map } from 'lodash';
import { getType } from 'typesafe-actions';
import { AuthActions } from '../../auth/actions/auth.types';
import {
  closeDocument,
  setSourceDocument,
} from '../../documents/actions/source-document.actions';
import { SourceDocumentActions } from '../../documents/actions/source-document.types';
import {
  fetchAnnotationPages,
  fetchAnnotationPagesError,
  populateAnnotationPages,
} from '../actions/annotation-pages.actions';
import { AnnotationPagesActions } from '../actions/annotation-pages.types';
import { AnnotationPage } from '../models/annotation-page.model';
import { Map } from 'immutable';
import { sortBy } from 'lodash';

export type AnnotationPagesState = {
  sourceDocumentId: number | null;
  documentTextId: number | null;
  pageCount: number;
  ocrVersion: string | null;
  entities: Map<number, AnnotationPage>;
  fullText: string;
  error: string | null;
  loading: boolean;
};

const defaultAnnotationPagesState = {
  sourceDocumentId: null,
  documentTextId: null,
  pageCount: 0,
  entities: Map<number, AnnotationPage>(),
  fullText: '',
  ocrVersion: null,
  error: null,
  loading: false,
};

export function annotationPagesReducer(
  state: AnnotationPagesState = defaultAnnotationPagesState,
  action: AnnotationPagesActions | SourceDocumentActions | AuthActions,
): AnnotationPagesState {
  switch (action.type) {
    case getType(populateAnnotationPages): {
      const {
        annotatedDocumentId,
        documentTextId,
        pages,
        ocrVersion,
      } = action.payload;
      const pageArray = sortBy(Array.from(pages.values()), p => p.pageNum);
      return {
        ...state,
        sourceDocumentId: annotatedDocumentId,
        documentTextId,
        ocrVersion,
        entities: pages,
        pageCount: Array.from(pages.keys()).length,
        fullText: map(pageArray, p => p.text).join(''),
        loading: false,
      };
    }

    case getType(setSourceDocument):
    case getType(fetchAnnotationPages):
      return {
        ...defaultAnnotationPagesState,
        loading: true,
      };

    case getType(fetchAnnotationPagesError):
      return { ...state, error: action.payload.error, loading: false };

    case getType(closeDocument):
      return defaultAnnotationPagesState;

    default:
      return state;
  }
}
