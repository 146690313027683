import { SyncOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { Set, is } from 'immutable';
import { map, toString } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Observable } from 'rxjs';
import styled from 'styled-components';
import { Restricted } from '../../accounts/containers/restricted.container';
import {
  PermissionActions,
  PermissionResources,
} from '../../accounts/models/permission.model';
import { fetchAnnotations } from '../../annotations/actions/annotations.actions';
import { AnnotationTable } from '../../annotations/containers/annotation-table.container';
import { CustomAnnotationButton } from '../../annotations/containers/custom-annotation-button.container';
import { AnnotationsLastSavedText } from '../../annotations/containers/last-saved.container';
import ManualAnnotationImporter from '../../annotations/containers/manual-annotation-importer.container';
import { SaveAnnotationsButton } from '../../annotations/containers/save-annotations-button.container';
import { AppState } from '../../app-state';
import CardWithTitle from '../../common/components/card-with-title.component';
import DangerButton from '../../common/components/danger-button.component';
import { SecondaryButton } from '../../common/components/secondary-button.component';
import { useFetchedState } from '../../common/hooks/use-fetched-state.hook';
import { useInterval } from '../../common/hooks/use-interval.hook';
import { QuerySort } from '../../common/types/QuerySort';
import { fetchAndSetSourceDocument } from '../../documents/actions/source-document.actions';
import { DeleteDocumentButton } from '../../documents/components/delete-document-button.component';
import { AnnotatedDocument } from '../../documents/models/annotated-document.model';
import {
  DocumentSummaryResource,
  DocumentSummarySearchResource,
  OcrStatus,
} from '../../documents/resources/document-summary.resource';
import {
  deleteEmptyDocumentSetAsync,
  fetchDocumentSetUploadPolicyAsync,
} from '../actions/document-sets.actions';
import { DocManagementSummary } from '../components/document-set-management-summary.component';
import { DocumentManagementTable } from '../components/document-set-management-table.component';
import { DocumentSetSummary } from '../components/document-set-summary.component';
import { OcrStatusSummary } from '../models/document-set-ocr-summary.model';
import DocumentSetUploadPolicy from '../models/document-set-upload-policy.model';
import { DocumentSet } from '../models/document-set.model';
import { DocumentSetResource } from '../resources/document-set.resource';
import { selectDocumentSetUploadPolicyByDocSetId } from '../selectors/document-set.selectors';
import DocumentSetImporter from './document-set-importer.container';

export const defaultDateFormat = 'mmmm d, yyyy HH:MM';

type DocumentSetManagerProps = {
  documentSet: DocumentSet;
  deselectDocumentSet: () => void;
  getPageOfDocsWithStatuses: (
    page?: number,
    pageSize?: number,
    sort?: QuerySort | null,
  ) => Observable<DocumentSummarySearchResource>;
  getDocumentSetStatusSummary: (
    documentSetId: number,
  ) => Observable<OcrStatusSummary>;
  onUpdateAnnotationData: (annotatedDocumentId: number) => void;
  onSetSourceDocument: (AnnotatedDocumentId: number) => void;
  onDelete: (documentSetId: number) => void;
  uploadPolicy: DocumentSetUploadPolicy | null;
  fetchDocumentSetUploadPolicy: (params: { documentSetId: number }) => unknown;
};
const ButtonSection = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  flex: 0 0 100%;
  & > * {
    margin-right: 16px;
    margin-bottom: 1em;
  }
`;

const TableSection = styled.div`
  flex: 0 0 100%;
  max-height: 30%;
  border: 1px solid #d9d9d9;
  border-radius: 0 0 4px 4px;
  overflow: auto;
`;

const CollapseSection = styled.div`
  min-width: 100%;
  margin-bottom: 1em;
`;

const DocumentSetManagerComponent: React.FunctionComponent<DocumentSetManagerProps> = ({
  documentSet,
  deselectDocumentSet,
  getPageOfDocsWithStatuses,
  getDocumentSetStatusSummary,
  onUpdateAnnotationData,
  onSetSourceDocument,
  onDelete,
}) => {
  const pageSize = 25;
  const [
    selectedDocument,
    setSelectedDocument,
  ] = useState<AnnotatedDocument | null>(null);

  const [currentPage, setCurrentPage] = useState(1);

  const resetPageState = () => {
    setCurrentPage(1);
    setActiveUploadPanelKey(toString(documentSet.documentSetId));
  };
  // Allows useEffect hook on fetchState to sync document
  const [syncDocumentSetSwitch, setSyncDocumentSet] = useState(false);

  // Sets initial sort order
  const [currentSort, setCurrentSort] = useState<QuerySort | null>({
    field: 'createdAt',
    order: 'descend',
  });

  const [currentOcrFilter, setCurrentOcrFilter] = useState<OcrStatus>();
  // Active keys to dictate which Panel is open
  const [currentlyActiveDocumentSetId, setActiveUploadPanelKey] = useState(
    toString(documentSet.documentSetId),
  );

  const handleCollapseClick = () => {
    if (currentlyActiveDocumentSetId === '') {
      setActiveUploadPanelKey(toString(documentSet.documentSetId));
    } else setActiveUploadPanelKey('');
  };

  // Drawer State
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(resetPageState, [documentSet]);

  // Update AnnotationTable when new document is selected
  useEffect(() => {
    if (selectedDocument) {
      onSetSourceDocument(selectedDocument.annotatedDocumentId);
    }
  }, [onSetSourceDocument, onUpdateAnnotationData, selectedDocument]);

  // Upload Info collected when files are added
  const [uploadInfo, setUploadInfo] = useState<UploadChangeParam | undefined>();

  const [
    documentSetResource,
    isLoading,
  ] = useFetchedState(
    getPageOfDocsWithStatuses(currentPage, pageSize, currentSort),
    [
      documentSet,
      currentPage,
      syncDocumentSetSwitch,
      currentSort,
      currentPage,
      currentOcrFilter,
    ],
  );
  const { documents, totalItems } = documentSetResource || {};

  // Pulls the First page of the documentSet so when upload is complete it can run a check
  // to see if the files being uploaded are in the documentSet
  const [uploadStatusCheck] = useFetchedState(
    getPageOfDocsWithStatuses(1, pageSize, {
      field: 'createdAt',
      order: 'descend',
    }),
    [syncDocumentSetSwitch],
  );

  const [uploadPending, setUploadPending] = useState<boolean>(false);
  useEffect(() => {
    setUploadPending(true);
  }, [uploadInfo]);

  const [
    DocumentSetOcrSummary,
    isOcrSummaryStatusLoading,
  ] = useFetchedState(getDocumentSetStatusSummary(documentSet.documentSetId), [
    documentSet,
    syncDocumentSetSwitch,
  ]);

  const [DocumentSetTotalDocuments, setDocumentSetTotalDocuments] = useState<
    number | null
  >(null);

  useEffect(() => {
    if (DocumentSetOcrSummary) {
      const { completed, pending, started, failed } = DocumentSetOcrSummary;
      setDocumentSetTotalDocuments(completed + pending + started + failed);
    } else {
      setDocumentSetTotalDocuments(null);
    }
  }, [DocumentSetOcrSummary]);

  useInterval(
    () => {
      setUploadPending(false);
      if (uploadStatusCheck && uploadInfo && uploadInfo.file.percent === 100) {
        const uploadStatusCheckNames = Set.of(
          ...map(uploadStatusCheck.documents, 'aggregateId'),
        );
        const uploadNames = Set.of(...map(uploadInfo.fileList, 'name'));
        if (
          !is(Set.intersect([uploadStatusCheckNames, uploadNames]), uploadNames)
        ) {
          setUploadPending(true);
          setSyncDocumentSet(true);
        }
      }
    },
    uploadPending ? 3000 : null,
  );

  useEffect(() => {
    if (syncDocumentSetSwitch) {
      setSyncDocumentSet(false);
    }
  }, [syncDocumentSetSwitch]);

  const deleteDocumentSet = () => {
    onDelete(documentSet.documentSetId);
    deselectDocumentSet();
  };

  return (
    <CardWithTitle
      // eslint-disable-next-line no-restricted-globals
      onBackClicked={deselectDocumentSet}
      title={documentSet.name}
    >
      <ButtonSection>
        {DocumentSetTotalDocuments === 0 && (
          <DangerButton onClick={deleteDocumentSet}>
            Delete Document Set
          </DangerButton>
        )}
        <ManualAnnotationImporter
          setDocumentListSync={setSyncDocumentSet}
          documentSetId={documentSet.documentSetId}
        />
        <SecondaryButton onClick={() => setSyncDocumentSet(true)}>
          Sync Documents <SyncOutlined />
        </SecondaryButton>
      </ButtonSection>
      <CollapseSection>
        <DocumentSetImporter
          onClick={handleCollapseClick}
          currentlyActiveDocumentSetId={currentlyActiveDocumentSetId}
          uploadDataState={[uploadInfo, setUploadInfo]}
          documentSet={documentSet}
        />
      </CollapseSection>
      <DocumentSetSummary
        totalDocuments={DocumentSetTotalDocuments}
        isOcrSummaryStatusLoading={isOcrSummaryStatusLoading}
        ocrStatusSummary={DocumentSetOcrSummary}
      />
      <TableSection>
        <DocumentManagementTable
          onOcrFilterChanged={setCurrentOcrFilter}
          documents={documents || []}
          pageState={[currentPage, setCurrentPage]}
          pageSize={pageSize}
          dataIsLoading={isLoading}
          onSortChanged={setCurrentSort}
          totalItems={totalItems}
          tableScrollSize={{ y: false }}
          onDocumentSelected={d => {
            setDrawerOpen(true);
            setSelectedDocument(d);
          }}
        />
      </TableSection>
      {selectedDocument && (
        <Drawer
          width={940}
          placement="right"
          closable={false}
          onClose={() => setDrawerOpen(false)}
          visible={drawerOpen}
        >
          <DocManagementSummary
            document={selectedDocument}
            style={{ marginBottom: '32px' }}
          />
          <ButtonSection>
            <CustomAnnotationButton />
            <SaveAnnotationsButton />
            <Restricted
              required={{
                action: PermissionActions.DeleteDocument,
                resource: PermissionResources.DocumentSet,
              }}
            >
              <DeleteDocumentButton
                setSyncDocumentSet={setSyncDocumentSet}
                setDrawerOpen={setDrawerOpen}
              />
            </Restricted>

            <AnnotationsLastSavedText />
          </ButtonSection>
          <div style={{ height: 'calc(100vh - 204px)' }}>
            <AnnotationTable />
          </div>
        </Drawer>
      )}
    </CardWithTitle>
  );
};

const DocumentSetManager = connect(
  (
    state: AppState,
    ownProps: Pick<DocumentSetManagerProps, 'documentSet'>,
  ) => ({
    getPageOfDocsWithStatuses: DocumentSummaryResource.search(state)({
      documentSetId: ownProps.documentSet.documentSetId,
      isOcred: 'both',
    }),
    getDocumentSetStatusSummary: DocumentSetResource.getDocumentSetSummary(
      state,
    ),
    uploadPolicy: selectDocumentSetUploadPolicyByDocSetId(
      ownProps.documentSet.documentSetId,
    )(state),
  }),
  dispatch => ({
    onUpdateAnnotationData: (annotatedDocumentId: number) => {
      dispatch(fetchAnnotations(annotatedDocumentId));
    },
    onSetSourceDocument: (annotatedDocumentId: number) => {
      dispatch(fetchAndSetSourceDocument(annotatedDocumentId, true));
    },
    onDelete: (documentSetId: number) => {
      dispatch(deleteEmptyDocumentSetAsync.request({ documentSetId }));
    },
    fetchDocumentSetUploadPolicy: fetchDocumentSetUploadPolicyAsync.request,
  }),
)(DocumentSetManagerComponent);

export default DocumentSetManager;
