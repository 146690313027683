import { values } from 'lodash';
import { RelationCondition } from './relation-condition.model';
import { RelationRule } from './relation-rule.model';

export type RuleOrCondition<T> = RelationCondition<T> | RelationRule<T>;

export type RelationType = string;
export const RELATION_TYPES = {
  HAS_ZERO_OR_ONE: 'has-zero-or-one',
  CHILD_OF: 'child-of',
};

export type ConditionType = string;
export const CONDITION_TYPES = {
  OR: 'or',
  AND: 'and',
};

export function isRule<T>(
  ruleOrCondition: RuleOrCondition<T>,
): ruleOrCondition is RelationRule<T> {
  return isRuleType(ruleOrCondition.type);
}

export function isRuleType(t: RelationType | ConditionType): t is RelationType {
  return values(RELATION_TYPES).indexOf(t) > -1;
}

export function isCondition<T>(
  ruleOrCondition: RuleOrCondition<T>,
): ruleOrCondition is RelationCondition<T> {
  return isConditionType(ruleOrCondition.type);
}

export function isConditionType(
  t: RelationType | ConditionType,
): t is ConditionType {
  return values(CONDITION_TYPES).indexOf(t) > -1;
}
