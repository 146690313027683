import { uniqBy } from 'lodash';
import { createSelector } from 'reselect';
import { AppState } from '../../app-state';
import { REVIEWED } from '../epics/annotation-document-statuses.epics';
import { selectAnnotationReviewIsComplete } from './annotation-review.selectors';

export const selectAvailableDocumentStatuses = (state: AppState) =>
  state.availableDocumentStatuses;

export const selectAnnotationDocumentStatuses = (state: AppState) =>
  state.annotationDocumentStatuses;

export const selectCurrentAnnotationDocumentStatuses = createSelector(
  [
    selectAnnotationDocumentStatuses,
    selectAvailableDocumentStatuses,
    selectAnnotationReviewIsComplete,
  ],
  (potentialStatuses, availableDocumentStatuses, isReviewed) => {
    const reviewedExists = potentialStatuses.find(x => x.status === REVIEWED);
    const reviewedStatus = availableDocumentStatuses.find(
      x => x.status === REVIEWED,
    );
    const shouldReviewedExist = isReviewed || reviewedExists;

    if (reviewedStatus && shouldReviewedExist) {
      return uniqBy([...potentialStatuses, reviewedStatus], 'statusId');
    }
    return potentialStatuses;
  },
);

export const selectAvailableDocumentStatusStrings = createSelector(
  [selectAvailableDocumentStatuses],
  statuses => statuses.map(ds => ds.status),
);
