import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { AppState } from '../../app-state';
import { useFetchedState } from '../../common/hooks/use-fetched-state.hook';
import { DocumentSetResource } from '../../document-sets/resources/document-set.resource';
import { SearchFormContainer } from '../containers/search-form.container';
import { SearchResultsContainer } from '../containers/search-results.container';

const SearchWrapper = styled.div`
  padding: 10px;
`;

const UnconnectedSearchPanel: React.FunctionComponent<{
  jwt: string | null;
}> = ({ jwt }) => {
  const [documentSets] = useFetchedState(
    DocumentSetResource.fetchSearchable(jwt),
    [jwt],
  );

  return (
    <SearchWrapper>
      <SearchFormContainer documentSets={documentSets || {}} />
      <SearchResultsContainer />
    </SearchWrapper>
  );
};

export const SearchPanel = connect((state: AppState) => ({
  jwt: state.auth.jwt,
}))(UnconnectedSearchPanel);
