import { createAction, createAsyncAction } from 'typesafe-actions';
import { AddNewUserPayload } from '../../admin/models/add-new-user-payload.model';
import { SimpleRole } from '../models/simple-role.model';
import { UserAccount } from '../models/user-account.model';
import { User } from '../models/user.model';

enum Types {
  POPULATE_USERS = 'POPULATE_USERS: Populates the user table with the passed array of users.',
  FETCH_USERS = 'FETCH_USERS: Triggers an api request for a list of users.',
  SET_ACTIVE_USER_ACCOUNT = 'SET_ACTIVE_USER_ACCOUNT: Sets the account for the active user.',
  STORE_USER_ACCOUNT = 'STORE_USER_ACCOUNT: Stores/adds a user account to the state store.',
  FETCH_USER_ACCOUNT = 'FETCH_USER_ACCOUNT: Fetches the account for the passed user id.',
  SAVE_USER_SIMPLE_ROLES = 'SAVE_USER_SIMPLE_ROLES: Saves user simple roles over network',
  REFRESH_ACTIVE_USER_ACCOUNT = 'REFRESH_ACTIVE_USER_ACCOUNT: Refresh active user permissions.',
}

export const populateUsers = createAction(
  Types.POPULATE_USERS,
  resolvePayload => (users: User[]) => resolvePayload({ users }),
);

export const fetchUsers = createAction(Types.FETCH_USERS);

export const setActiveUserAccount = createAction(
  Types.SET_ACTIVE_USER_ACCOUNT,
  resolvePayload => (account: UserAccount) => resolvePayload({ account }),
);

export const storeUserAccount = createAction(
  Types.STORE_USER_ACCOUNT,
  resolvePayload => (account: UserAccount) => resolvePayload({ account }),
);

export const fetchUserAccount = createAction(
  Types.FETCH_USER_ACCOUNT,
  resolvePayload => (userId: number) => resolvePayload({ userId }),
);

export const refreshActiveUserAccount = createAction(
  Types.REFRESH_ACTIVE_USER_ACCOUNT,
);

export const createUserAccount = createAsyncAction(
  'CREATE_USER_ACCOUNT_REQUEST',
  'CREATE_USER_ACCOUNT_SUCCESS',
  'CREATE_USER_ACCOUNT_FAILURE',
)<AddNewUserPayload, undefined, string>();

export const saveUserSimpleRoles = createAction(
  Types.SAVE_USER_SIMPLE_ROLES,
  resolvePayload => (userId: number, roles: SimpleRole[]) =>
    resolvePayload({ userId, roles }),
);
