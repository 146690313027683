import React from 'react';

interface SelectableProps {
  onSelectionChange: (selection: Selection) => any;
  onSelectionCleared: (selection: Selection) => any;
}
interface SelectableState {
  lastSelection: string;
  repeatSelectionCount: number;
}

/**
 * This component only emits selection events for its descendents.
 */
export class Selectable extends React.Component<
  SelectableProps,
  SelectableState
> {
  private elm: HTMLDivElement | null = null;

  private MAX_REPEAT_SELECTION_COUNT = 5;

  constructor(props: SelectableProps) {
    super(props);

    this.state = { lastSelection: '', repeatSelectionCount: 0 };
  }

  setRepeatSelectionCount(n: number) {
    this.setState({ repeatSelectionCount: n });
  }

  incrementRepeatSelectionCount() {
    this.setRepeatSelectionCount(this.state.repeatSelectionCount + 1);
  }

  resetRepeatSelectionCount() {
    this.setRepeatSelectionCount(0);
  }

  componentDidMount() {
    this.selectionListener = () => this.updateSelection();
    document.addEventListener('selectionchange', this.selectionListener);
  }

  componentWillUnmount() {
    document.removeEventListener('selectionchange', this.selectionListener);
  }

  updateSelection(): void {
    const selection = document.getSelection();
    const start = selection ? selection.anchorNode : null;
    const end = selection ? selection.focusNode : null;

    const isDescendent =
      start &&
      end &&
      (this.isDescendent(start.parentElement) ||
        this.isDescendent(end.parentElement));

    const text = selection ? selection.toString() : null;
    const lastSelection = this.state.lastSelection;
    const sameAsLast = text === lastSelection;

    if (sameAsLast) {
      this.incrementRepeatSelectionCount();
    } else {
      this.resetRepeatSelectionCount();
    }

    const repeatSelectionCount = this.state.repeatSelectionCount;
    const exceededMaxRepeat =
      repeatSelectionCount > this.MAX_REPEAT_SELECTION_COUNT;

    if (
      isDescendent &&
      text &&
      selection &&
      (!sameAsLast || !exceededMaxRepeat)
    ) {
      this.setState({ lastSelection: text });
      this.props.onSelectionChange(selection);
    } else if (!isDescendent && selection && text === '' && lastSelection) {
      this.setState({ lastSelection: text });
      this.props.onSelectionCleared(selection);
    }
  }

  /**
   * Crawls through parent elements of the passed element to see
   * if the element is a descendent of this component's html node.
   * @param elm
   */
  isDescendent(elm: HTMLElement | null): boolean {
    if (!elm) {
      return false;
    }

    if (elm.parentElement === this.elm) {
      return true;
    }

    return this.isDescendent(elm.parentElement);
  }

  render() {
    return <div ref={r => (this.elm = r)}>{this.props.children}</div>;
  }

  private selectionListener: () => void = () => ({});
}
