import { createAction } from 'typesafe-actions';
import { AnnotationSchema } from '../models/annotation-schema.model';
import { AnnotationTypePayload } from '../resources/annotation-schema.resource';

enum Types {
  FETCH_ANNOTATION_SCHEMAS = 'FETCH_ANNOTATION_SCHEMAS: Fetches all annotation schemas from web service',
  REPLACE_ANNOTATION_SCHEMAS = 'REPLACE_ANNOTATION_SCHEMAS: Replaces all annotation schemas within app.',
  SET_ACTIVE_ANNOTATION_SCHEMA = 'SET_ACTIVE_ANNOTATION_SCHEMA: User set a new active annotation schema.',
  SET_EDITING_ANNOTATION_SCHEMA = 'SET_EDITING_ANNOTATION_SCHEMA: User set a new editing annotation schema.',
  SET_SCHEMA_FOR_DOCUMENT_SET = 'SET_SCHEMA_FOR_DOCUMENT_SET: Uses schema matchers to set the active schema using the passed document set.',
  ANNOTATION_SCHEMA_VALIDATION_SUCCESS = 'ANNOTATION_SCHEMA_VALIDATION_SUCCESS',
  DELETE_SCHEMA = 'DELETE ANNOTATION SCHEMA',
  CREATE_SCHEMA = 'CREATE ANNOTATION SCHEMA',
  UPDATE_SCHEMA = 'UPDATE ANNOTATION SCHEMA',
  UPDATE_SCHEMA_TYPES = 'UPDATE ANNOTATION SCHEMA TYPES',
}

export const fetchAnnotationSchemas = createAction(
  Types.FETCH_ANNOTATION_SCHEMAS,
);

export const replaceAnnotationSchemas = createAction(
  Types.REPLACE_ANNOTATION_SCHEMAS,
  resolvePayload => (schemas: AnnotationSchema[]) =>
    resolvePayload({ schemas }),
);

export const setActiveAnnotationSchema = createAction(
  Types.SET_ACTIVE_ANNOTATION_SCHEMA,
  resolvePayload => (schema: AnnotationSchema) => resolvePayload({ schema }),
);

export const setEditingAnnotationSchema = createAction(
  Types.SET_EDITING_ANNOTATION_SCHEMA,
  resolvePayload => (schema: AnnotationSchema | null) =>
    resolvePayload({ schema }),
);

export const annotationSchemaValidationSuccess = createAction(
  Types.ANNOTATION_SCHEMA_VALIDATION_SUCCESS,
);

export const setSchemaForDocumentSet = createAction(
  Types.SET_SCHEMA_FOR_DOCUMENT_SET,
  resolvePayload => (documentSetName: string, classification?: string) =>
    resolvePayload({ documentSetName, classification }),
);

export const deleteSchema = createAction(
  Types.DELETE_SCHEMA,
  resolvePayload => (schemaId: number) => resolvePayload({ schemaId }),
);

export const createSchema = createAction(
  Types.CREATE_SCHEMA,
  resolvePayload => (
    title: string,
    groupId: number,
    documentSetMatcher?: string,
    documentTypeMatcher?: string,
  ) =>
    resolvePayload({ title, groupId, documentSetMatcher, documentTypeMatcher }),
);

export const updateSchema = createAction(
  Types.UPDATE_SCHEMA,
  resolvePayload => (schema: AnnotationSchema) => resolvePayload({ schema }),
);

export const updateSchemaTypes = createAction(
  Types.UPDATE_SCHEMA_TYPES,
  resolvePayload => (schemaId: number, types: AnnotationTypePayload[]) =>
    resolvePayload({ schemaId, types }),
);
