import { Table } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { DateComponent } from '../../common/components/date.component';
import { AnnotatedDocument } from '../models/annotated-document.model';
import { DocumentSelectionOptions } from './document-selection-options.component';
import { QuerySort } from '../../common/types/QuerySort';
import { map } from 'lodash';

const tableColumns = (
  onSelected: () => void,
  currentUserId: number | null,
  sort: QuerySort | null,
) => {
  const defaultColumnProperties = (c: { dataIndex: string }) => {
    return {
      defaultSortOrder: sort?.field === c.dataIndex ? sort?.order : null,
      sorter: (a, b) => {
        const aVal = a[c.dataIndex] ?? '';
        const bVal = b[c.dataIndex] ?? '';
        return aVal.localeCompare(bVal);
      },
    };
  };
  const cols = [
    {
      title: 'Document Set',
      dataIndex: 'documentSetName',
    },
    {
      title: 'Instrument Type',
      dataIndex: 'instrumentType',
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      render: (dateStr: string) => <DateComponent serializedDate={dateStr} />,
    },
    {
      title: 'Recorded Date',
      dataIndex: 'recordedDate',
      render: (dateStr: string) => <DateComponent serializedDate={dateStr} />,
    },
    {
      title: 'State',
      dataIndex: 'state',
    },
    {
      title: 'County',
      dataIndex: 'county',
    },
    {
      title: 'Annotator Name',
      dataIndex: 'lastAnnotatorName',
      key: 'lastAnnotatorName',
    },
    {
      title: 'Annotation Date',
      dataIndex: 'lastAnnotationTimestamp',
      key: 'lastAnnotationTimestamp',
      render: (dateStr: string) =>
        dateStr ? <DateComponent serializedDate={dateStr} /> : '',
    },
    {
      title: 'Reviewer Name',
      dataIndex: 'lastReviewerName',
      key: 'lastReviewerName',
    },
    {
      title: 'Review Date',
      dataIndex: 'lastReviewTimestamp',
      key: 'lastReviewTimestamp',
      render: (dateStr: string) =>
        dateStr ? <DateComponent serializedDate={dateStr} /> : '',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'id',
      render: (_: any, document: AnnotatedDocument) => {
        const locked =
          document.isLocked && document.lockedByUserId !== currentUserId;
        return (
          <DocumentSelectionOptions
            document={document}
            locked={locked}
            onSelected={onSelected}
          />
        );
      },
      sorter: false,
    },
  ];
  return map(cols, c => {
    return { ...defaultColumnProperties(c), ...c };
  });
};

const TableWrapper = styled.div`
  width: 100%;

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 8px 8px;
  }
`;

interface DocumentSelectionTableProps {
  documents: AnnotatedDocument[];
  currentUserId: number | null;
  className?: string;
  onDocumentSelected: () => void;
  onSort: (val: string, order: string) => void;
  sort: QuerySort | null;
}
interface DocumentSelectionTableState {
  tableColumns: any;
}
export class DocumentSelectionTable extends React.Component<
  DocumentSelectionTableProps,
  DocumentSelectionTableState
> {
  constructor(props: DocumentSelectionTableProps) {
    super(props);

    this.state = {
      tableColumns: tableColumns(
        props.onDocumentSelected,
        this.props.currentUserId,
        this.props.sort,
      ),
    };
  }

  onChange(sorter: any) {
    this.props.onSort(sorter.field, sorter.order);
  }

  render() {
    return (
      <TableWrapper className={this.props.className}>
        <Table
          dataSource={this.props.documents}
          columns={this.state.tableColumns}
          onChange={(_, __, sorter) => this.onChange(sorter)}
          pagination={false}
          rowKey="aggregateId"
        />
      </TableWrapper>
    );
  }
}
