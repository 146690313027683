import Select from 'antd/lib/select';
import { range } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { IconButton } from './icon-button.component';

const PageControlWrapper = styled.div`
  font-size: 16px;

  .ant-select-selection {
    margin: 0 4px;
  }

  .page-icon-button {
    padding: 6px 8px;
  }
`;

interface PageSelectorProps {
  page: number;
  numberPages: number;
  onSelectPage: (page: number) => void;
}
const PageSelector = (props: PageSelectorProps) => {
  const { page, numberPages, onSelectPage } = props;

  return (
    <Select
      value={page.toString()}
      onChange={(pageNum: string) => onSelectPage(parseInt(pageNum, 10))}
    >
      {range(1, numberPages + 1).map(pageNum => (
        <Select.Option value={pageNum.toString()} key={pageNum.toString()}>
          {pageNum}
        </Select.Option>
      ))}
    </Select>
  );
};

export interface PageControlsProps {
  currentPage: number;
  numberPages: number;

  incrementClicked: () => any;
  decrementClicked: () => any;
  gotoPage: (page: number) => any;
}
export class PageControls extends React.Component<PageControlsProps> {
  render() {
    const { currentPage, numberPages, gotoPage } = this.props;
    const pageSelector = (
      <PageSelector
        page={currentPage}
        numberPages={numberPages}
        onSelectPage={page => gotoPage(page)}
      />
    );

    return (
      <PageControlWrapper>
        <IconButton
          className="page-icon-button"
          iconType="left"
          onClick={() => this.props.decrementClicked()}
        />
        Page {pageSelector} of {numberPages}
        <IconButton
          className="page-icon-button"
          iconType="right"
          onClick={() => this.props.incrementClicked()}
        />
      </PageControlWrapper>
    );
  }
}
