import { Dictionary } from 'lodash';
import { AnnotationTypeResource } from '../resources/annotation-schema.resource';
import { EditorConfig } from '../models/annotation-schema-editor.model';
import { ValidatorConfig } from '../models/annotation-schema-validator.model';
import * as Colors from '../../common/colors';

export type AnnotationType = AnnotationTypeResource;

export type AnnotationTypesById = Dictionary<AnnotationType>;
export type AnnotationTypesByKey = Dictionary<AnnotationType>;

const validatorConfigs: ValidatorConfig[] = [];
const editorConfigs: EditorConfig[] = [];

export const AnnotationType = {
  WildcardAnyKey: '*',
  UnknownKey: 'unknown',
  DefaultColor: Colors.Gray1,
  Unknown: (key: string) => {
    return {
      annotationTypeId: -1,
      key: `${key}`,
      title: `Unknown (${key})`,
      keyboardShortcut: '',
      color: AnnotationType.DefaultColor,
      annotationSchemaId: -1,
      createdAt: '',
      updatedAt: '',
      validatorConfigs,
      editorConfigs,
    };
  },
};
