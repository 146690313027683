import { connect } from 'react-redux';
import { AppState } from '../../app-state';
import {
  PageScroller,
  PageScrollerDispatchProps,
} from '../components/page-scroller.component';
import { selectAnnotationPanePageOffsets } from '../selectors/ui.selectors';
import { selectPageByViewOrDefault } from '../../documents/selectors/page-controls.selectors';
import { setPage } from '../../documents/actions/page-controls.actions';
import { PageView } from '../../documents/models/page-view.model';

const PAGE_VIEW = PageView.Text;
export const AnnotationPageScroller = connect(
  (state: AppState) => ({
    page: selectPageByViewOrDefault(PAGE_VIEW, state),
    pageOffsets: selectAnnotationPanePageOffsets(state),
  }),
  (dispatch): PageScrollerDispatchProps => ({
    onPageChange: page => dispatch(setPage(PAGE_VIEW, page)),
  }),
)(PageScroller);
