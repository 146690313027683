import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { AppState } from '../../app-state';
import { authHeaders } from '../../common/utils/fetch';
import { config } from '../../config/application.config';
import { Predictions } from '../models/predictions.model';

export const UserSpecificPredictionResource = {
  baseUrl: config.annotationService.url,

  getPredictionsUrl(jobId: string): string {
    return `${this.baseUrl}/predictions/user_specific/${jobId}`;
  },

  get(state: AppState, jobId: string): Observable<Predictions> {
    return ajax.getJSON<Predictions>(
      this.getPredictionsUrl(jobId),
      authHeaders(state),
    );
  },
};
