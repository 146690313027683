import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { saveGroupSimpleRoles } from '../../accounts/actions/groups.actions';
import { GroupAccount } from '../../accounts/models/group-account.model';
import { SimpleRole } from '../../accounts/models/simple-role.model';
import { AppState } from '../../app-state';
import { AppActions } from '../../root.actions';
import { GroupAccountCardComponent } from '../components/group-account-card.component';

export interface GroupAccountCardProps {
  account: GroupAccount;
}
export const GroupAccountCard = connect(
  (_: AppState, ownProps: GroupAccountCardProps) => ({
    account: ownProps.account,
  }),
  (dispatch: Dispatch<AppActions>, ownProps: GroupAccountCardProps) => ({
    onSaveSimpleRoles: (roles: SimpleRole[]) => {
      dispatch(saveGroupSimpleRoles(ownProps.account.group.groupId, roles));
    },
  }),
)(GroupAccountCardComponent);
