import { PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Modal } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import _ from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import { AllGroupsSelector } from '../../admin/containers/group-selector.container';
import { PrimaryButton } from '../../common/components/primary-button.component';
import { SecondaryButton } from '../../common/components/secondary-button.component';
import { validateForm } from '../../common/utils/validateForm';

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
`;

function convertGroupIds(groupIdStrings: string[]): number[] {
  return _.map(groupIdStrings, id => parseInt(id, 10));
}

type QueueCreatorRawFormValues = {
  queueName: string;
  adminGroups: string[];
  workerGroups: string[];
};

export type QueueCreatorFormValues = {
  queueName: string;
  adminGroups: number[];
  workerGroups: number[];
};

export type QueueCreatorProps = {
  currentUserGroupId: number | null;
  onQueueCreate: (v: QueueCreatorFormValues) => unknown;
} & FormComponentProps;

// This is necessary to correct an error message from Ant's Form.create
const AntInput = React.forwardRef((props, _ref) => <Input {...props} />);
const AntGroupsSelector = React.forwardRef((props, _ref) => (
  <AllGroupsSelector {...props} mode="multiple" />
));

const { Item } = Form;
const QueueCreatorForm: React.FC<QueueCreatorProps> = ({
  form,
  currentUserGroupId,
  onQueueCreate,
}) => {
  const defaultGroups = currentUserGroupId
    ? [_.toString(currentUserGroupId)]
    : [];

  const createQueue = validateForm<QueueCreatorRawFormValues>(
    form,
    v =>
      onQueueCreate({
        queueName: v.queueName,
        adminGroups: convertGroupIds(v.adminGroups),
        workerGroups: convertGroupIds(v.workerGroups),
      }),
    true,
  );
  const [showModal, setModalView] = useState(false);
  const { getFieldDecorator } = form;

  return (
    <>
      <SecondaryButton onClick={() => setModalView(true)}>
        <PlusOutlined />
        Add Queue
      </SecondaryButton>
      <Modal
        title="Create Queue"
        visible={showModal}
        footer={null}
        onCancel={() => setModalView(false)}
        destroyOnClose={true}
      >
        <Form onSubmit={createQueue}>
          <Item label="Queue Name">
            {getFieldDecorator('queueName', {
              rules: [
                {
                  required: true,
                  message: 'Queue Name is required.',
                },
              ],
            })(<AntInput />)}
          </Item>
          <Item label="Administrator Groups">
            {getFieldDecorator('adminGroups', {
              initialValue: defaultGroups,
              rules: [],
            })(<AntGroupsSelector />)}
          </Item>
          <Item label="Worker Groups">
            {getFieldDecorator('workerGroups', {
              initialValue: defaultGroups,
              rules: [],
            })(<AntGroupsSelector />)}
          </Item>
          <Right>
            <PrimaryButton
              onClick={() => setModalView(false)}
              htmlType="submit"
            >
              Create Queue
            </PrimaryButton>
          </Right>
        </Form>
      </Modal>
    </>
  );
};

export const QueueCreatorComponent = Form.create<QueueCreatorProps>()(
  QueueCreatorForm,
);
