import { connect } from 'react-redux';
import { AppState } from '../../app-state';
import {
  deleteSchema,
  fetchAnnotationSchemas,
  updateSchema,
} from '../../annotations/actions/annotation-schemas.actions';
import { SchemaViewerComponent } from '../components/schema-viewer.component';
import { selectAnnotationSchemasArray } from '../../annotations/selectors/annotation-schema.selectors';
import { selectIsPermitted } from '../../accounts/selectors/users.selectors';
import {
  PermissionAction,
  PermissionResources,
} from '../../accounts/models/permission.model';
import { selectAllGroupsArray } from '../../accounts/selectors/groups.selectors';
import { fetchAllGroupAccounts } from '../../accounts/actions/groups.actions';
import { sortBy } from 'lodash';
import { selectLookups } from '../../domain/selectors/lookup-context.selectors';
import { fetchLookups } from '../../domain/actions/lookup-context.actions';

export const SchemaViewer = connect(
  (state: AppState) => ({
    schemas: selectAnnotationSchemasArray(state),
    groups: sortBy(selectAllGroupsArray(state), g => g.groupName.toLowerCase()),
    lookups: selectLookups(state),
    isPermitted: (schemaId: number, action: PermissionAction) =>
      selectIsPermitted(action, PermissionResources.Schema, schemaId)(state),
  }),
  {
    fetchSchemas: fetchAnnotationSchemas,
    fetchGroups: fetchAllGroupAccounts,
    fetchLookups,
    deleteSchema,
    updateSchema,
  },
)(SchemaViewerComponent);
