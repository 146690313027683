import { empty, of } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { EmptyAC, isActionOf } from 'typesafe-actions';
import { displaySuccessNotification } from '../../common/actions/notification.actions';
import { AppEpic } from '../../common/types/app-epic.type';
import { handleEpicError } from '../../common/utils/epics';
import {
  addMemberToRole,
  deleteMemberFromRole,
  fetchRoles,
  populateRoles,
} from '../actions/role.actions';
import { RolesResource } from '../resources/roles.resource';

export const loadRolesEpic: AppEpic = (actions$, state$) => {
  return actions$.pipe(
    filter(isActionOf(fetchRoles)),
    switchMap(() => RolesResource.getAll(state$.value)),
    map(roles => {
      return populateRoles(roles.roles);
    }),
    catchError((e: AjaxError) => {
      if (e.status === 403) {
        return empty();
      } else {
        return handleEpicError('Error fetching roles')(e);
      }
    }),
  );
};

export const addMemberToRoleEpic: AppEpic = (actions$, state$) => {
  return actions$.pipe(
    filter(isActionOf(addMemberToRole.request)),
    switchMap(({ payload: { roleId, memberId, memberType } }) =>
      RolesResource.createRoleForMember(
        state$.value,
        roleId,
        memberId,
        memberType,
      ).pipe(
        handleSuccessfulMemberChange(
          addMemberToRole.success,
          'Member was successfully added!',
          roleId,
        ),
        catchError(
          handleEpicError(
            'Error assigning member to role',
            addMemberToRole.failure,
          ),
        ),
      ),
    ),
  );
};

export const deleteMemberFromRoleEpic: AppEpic = (actions$, state$) => {
  return actions$.pipe(
    filter(isActionOf(deleteMemberFromRole.request)),
    switchMap(({ payload: { roleId, memberId, memberType } }) =>
      RolesResource.deleteMemberFromRole(
        state$.value,
        roleId,
        memberId,
        memberType,
      ).pipe(
        handleSuccessfulMemberChange(
          deleteMemberFromRole.success,
          'Member was successfully removed!',
          roleId,
        ),
        catchError(
          handleEpicError(
            'Error Deleting Member from role',
            deleteMemberFromRole.failure,
          ),
        ),
      ),
    ),
  );
};

function handleSuccessfulMemberChange(
  actionCreator: EmptyAC<any>,
  message: string,
  roleId: number,
) {
  return switchMap(_ =>
    of(actionCreator(), displaySuccessNotification(message)),
  );
}
