import React from 'react';
import styled from 'styled-components';
import {
  Gray1,
  ToolbarBackground,
  ToolbarFontColor,
} from '../../common/colors';
import { AnnotationViewerPageControls } from '../containers/annotation-viewer-page-controls.container';

const ControlBarWrapper = styled.div`
  background-color: ${ToolbarBackground};
  color: ${ToolbarFontColor};
  border-bottom: 1px solid ${Gray1};
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  max-height: 44px;

  .ant-btn-group {
    height: 100%;

    button {
      height: 100%;
      border: 1px solid white;
    }

    button.ant-btn:first-child:not(:last-child),
    button.ant-btn:last-child:not(:first-child) {
      border-radius: 0px;
    }
  }
`;

const Left = styled.div`
  text-align: left;
  width: 33%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  height: 40px;
`;

const Center = styled.div`
  text-align: center;
  width: 34%;
  padding: 4px 0;
`;

const Right = styled.div`
  width: 33%;
  text-align: right;

  .content {
    text-align: left;
    display: inline-block;
  }
`;

export class AnnotationViewerControls extends React.PureComponent {
  render() {
    return (
      <ControlBarWrapper>
        <Left />
        <Center>
          <AnnotationViewerPageControls />
        </Center>
        <Right />
      </ControlBarWrapper>
    );
  }
}
