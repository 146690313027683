import { connect } from 'react-redux';
import { AppState } from '../../app-state';
import { setAnnotationPanePageOffsets } from '../../layout/actions/ui-panes.actions';
import { HighlightCollection } from '../../text-highlighter/models/highlight-collection.model';
import { TextSelection } from '../../text-highlighter/models/text-selection';
import {
  clearAnnotationSelection,
  setAnnotationHighlights,
  setAnnotationSelection,
} from '../actions/ui-annotations.actions';
import { AnnotationViewer as AnnotationViewerComponent } from '../components/annotation-viewer.component';
import {
  selectAnnotationPagesArray,
  selectCurrentAnnotationPageNumber,
  selectFullText,
} from '../selectors/annotation-pages.selectors';
import { selectAnnotationsForHighlights } from '../selectors/annotation.selectors';
import {
  selectAnnotationHighlightColor,
  selectEmphasizeAnnotationId,
} from '../selectors/ui.selectors';
import { selectActiveAnnotationSchema } from '../selectors/annotation-schema.selectors';
import { AnnotationSchema } from '../models/annotation-schema.model';

export const AnnotationViewer = connect(
  (state: AppState) => ({
    activeSchema:
      selectActiveAnnotationSchema(state) || AnnotationSchema.unknownSchema(),
    page: selectCurrentAnnotationPageNumber(state),
    pages: selectAnnotationPagesArray(state),
    text: selectFullText(state),
    annotations: selectAnnotationsForHighlights(state),
    selectionColor: selectAnnotationHighlightColor(state),
    emphasizeAnnotationId: selectEmphasizeAnnotationId(state),
  }),
  dispatch => ({
    onSelectionChange: (sel: TextSelection) => {
      if (sel.isSelected) {
        dispatch(
          setAnnotationSelection(
            [{ start: sel.startOffset, end: sel.endOffset, text: sel.text }],
            'text-viewer',
          ),
        );
      }
    },
    onSelectionCleared: () => dispatch(clearAnnotationSelection()),
    updatePageOffsets: (offsets: number[]) =>
      dispatch(setAnnotationPanePageOffsets(offsets)),
    onHighlightsUpdated: (highlights: HighlightCollection) =>
      dispatch(setAnnotationHighlights(highlights)),
  }),
)(AnnotationViewerComponent);
