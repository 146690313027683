import React from 'react';
import { Input, Select } from 'antd';
import { find, get, map } from 'lodash';
import { ColumnApi, RowNode } from 'ag-grid-community';
import { Annotation } from '../models/annotation.model';
import {
  DomainLookup,
  DomainLookupValue,
} from '../../domain/models/lookup-context.model';
import {
  EditorConfig,
  LookupSelectEditorConfig,
  SelectEditorConfig,
} from '../models/annotation-schema-editor.model';
import { AnnotationSchema } from '../models/annotation-schema.model';
import { AnnotationType } from '../models/annotation-type.model';

export type AnnotationValueEditorProps = {
  value: string;
  node: RowNode;
  lookupValues: DomainLookupValue[];
  onClose?: () => void;
  stopEditing: (suppressNavigateAfterEdit?: boolean) => void;
  getParentAnnotations: (id: string) => Annotation[];
  columnApi: ColumnApi;
  activeSchema: AnnotationSchema | null;
  annotationValue: string;
  annotationType: string;
};

export const AnnotationValueEditor = React.forwardRef(
  (props: AnnotationValueEditorProps, ref) => {
    const schema = props.activeSchema;
    const lookups = schema?.lookups || [];
    const [value, setValue] = React.useState<string>();
    const [visible, setVisible] = React.useState(false);
    const [editing, setEditing] = React.useState(true);

    const annotationTypes = schema?.annotationTypes || [];
    const getTypeByKey = (key: string) =>
      annotationTypes.find(t => t.key === key) || AnnotationType.Unknown(key);

    const annotationId = props.node.data.annotationId;
    const prevVal = props.annotationValue;
    const annotationTypeKey = props.annotationType;
    const annotationType = getTypeByKey(annotationTypeKey);
    const editors = annotationType.editorConfigs;
    const inputRef = React.useRef(null);
    const ENTER_KEY = 13;
    const cell = find(
      props.columnApi.getColumnState(),
      c => c.colId === 'value',
    );
    const cellWidth = cell?.width;

    function close() {
      if (props.onClose) {
        props.onClose();
      }
      props.stopEditing();
    }

    const defaultEditor = (
      <Input.TextArea
        style={{ width: cellWidth }}
        key="input-1"
        ref={inputRef}
        defaultValue={value || prevVal}
        value={value || prevVal}
        onPressEnter={e => setEditing(false)}
        onChange={e => setValue(e.target.value)}
        autoSize={{ minRows: 1, maxRows: 5 }}
      />
    );

    function getEditorComponent(editor: EditorConfig) {
      switch (editor?.configType) {
        case 'select': {
          const editorConfig = editor as SelectEditorConfig;
          const validValues = editorConfig.values;
          if (validValues.length === 0) {
            return undefined;
          }
          const valueOptions = map(validValues, v => {
            return { value: v };
          });

          return (
            <Select
              key="select-1"
              ref={inputRef}
              showAction={['focus', 'click']}
              autoFocus={visible}
              defaultOpen={visible}
              open={visible}
              defaultValue={value || prevVal}
              onChange={v => {
                setValue(v);
                setEditing(false);
              }}
              onSearch={v => {
                setValue(v);
              }}
              onInputKeyDown={k => {
                if (k.which === 13) {
                  setEditing(false);
                }
              }}
              showSearch
              style={{ width: cellWidth }}
              placeholder="Search to Select"
              options={valueOptions}
            />
          );
        }
        case 'lookup-select': {
          const parents: Annotation[] = props.getParentAnnotations(
            annotationId,
          );
          const editorConfig = editor as LookupSelectEditorConfig;
          const editorLookupValueKey = annotationType.key;
          const lookup = find(
            lookups,
            l => l.domainLookupId === editorConfig.domainLookupId,
          );
          if (!lookup) {
            return undefined;
          }
          const lookupValue = DomainLookup.findLookupValue(
            lookup,
            props.lookupValues,
            parents,
          );
          const validValues =
            (get(lookupValue?.value, editorLookupValueKey) as string[]) || [];
          if (validValues.length === 0) {
            return undefined;
          }

          const valueOptions = map(validValues, v => {
            return { value: v };
          });

          return (
            <Select
              key="select-1"
              ref={inputRef}
              showAction={['focus', 'click']}
              autoFocus={visible}
              defaultOpen={visible}
              open={visible}
              defaultValue={value || prevVal}
              onChange={v => {
                setValue(v);
                setEditing(false);
              }}
              onSearch={v => {
                setValue(v);
              }}
              onInputKeyDown={k => {
                if (k.which === ENTER_KEY) {
                  setEditing(false);
                }
              }}
              showSearch
              style={{ width: cellWidth }}
              placeholder="Search to Select"
              options={valueOptions}
            />
          );
        }
        default:
          return defaultEditor;
      }
    }

    React.useEffect(() => {
      if (!editing) {
        close();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editing]);

    React.useImperativeHandle(ref, () => {
      return {
        isPopup: () => true,
        getValue: () => value || props.value,
        afterGuiAttached: () => {
          setVisible(true);
          // @ts-ignore
          inputRef?.current?.focus();
        },
      };
    });

    const editorComponent = map(editors, e => getEditorComponent(e)).filter(
      e => !!e,
    )[0];
    return editorComponent || defaultEditor;
  },
);
