/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import styled from 'styled-components';
import * as Colors from '../../common/colors';
import ExportTable from '../../search/containers/export-table.container';
import { Modal } from 'antd';
import { connect } from 'react-redux';
import { AppState } from '../../app-state';
import { values } from 'lodash';
import { AnnotationSchema } from '../../annotations/models/annotation-schema.model';
import { setEditingAnnotationSchema } from '../../annotations/actions/annotation-schemas.actions';
import AnnotationTypeTable from '../../schemas/containers/annotation-type-table.container';

const ModalLink = styled.a`
  color: white;
  cursor: pointer;
  padding: 0 12px;
  font-size: 16px;

  &.active {
    color: ${Colors.ActiveLinkColor};
    border-bottom: 2px solid ${Colors.ActiveLinkColor};
  }
`;

const ContextualModalsSpan = styled.span`
  border-left: 1px solid white;
`;

interface ContextualModalsProps {
  activeExportJobs: boolean;
  editingSchema: AnnotationSchema | null;
  setEditingAnnotationSchema: (schema: AnnotationSchema | null) => void;
}

const ContextualModals = (props: ContextualModalsProps) => {
  const [exportModalVisible, setExportModalVisible] = React.useState(false);
  const toggleExportModal = () => setExportModalVisible(!exportModalVisible);

  const closeSchemaModal = () => props.setEditingAnnotationSchema(null);

  const exportModal = (
    <Modal
      title="Exports"
      visible={exportModalVisible}
      onCancel={toggleExportModal}
      footer={false}
    >
      <ExportTable />
    </Modal>
  );

  const schemaModal = props.editingSchema ? (
    <Modal
      title={`Edit Schema: ${props.editingSchema.title}`}
      visible={true}
      onCancel={closeSchemaModal}
      footer={false}
      width={'75%'}
    >
      <AnnotationTypeTable schema={props.editingSchema} />
    </Modal>
  ) : null;

  const modals = (
    <>
      {exportModal}
      {schemaModal}
    </>
  );

  const showModalLinks = !!props.activeExportJobs;
  const modalLinks = showModalLinks ? (
    <ContextualModalsSpan>
      <ModalLink onClick={toggleExportModal}>View Exports</ModalLink>
    </ContextualModalsSpan>
  ) : null;

  return (
    <>
      {modals}
      {modalLinks}
    </>
  );
};

export default connect(
  (state: AppState) => ({
    activeExportJobs: values(state.exports.jobs).length > 0,
    editingSchema: state.annotationSchemas.editingSchema,
  }),
  { setEditingAnnotationSchema },
)(ContextualModals);
