import { RightOutlined, SearchOutlined } from '@ant-design/icons';
import { Input, Tooltip, Typography } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Group } from '../../accounts/models/group.model';
import { Card } from '../../common/components/card.component';
import { SecondaryButton } from '../../common/components/secondary-button.component';
import { DocumentSet } from '../models/document-set.model';

const ListWrap = styled(Card)`
  flex: 0 0 300px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  .document-set-list-item:hover {
    background-color: #e6f7fe;
    cursor: pointer;
  }
  .is-active {
    background-color: #e6f7fe;
  }
`;

const DSListHeader = styled.span`
  display: flex;
  flex-flow: row;
  font-size: 18px;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid #f3f3f3;
`;

const DSListBody = styled.div`
  overflow-y: scroll;
`;

const DocumentSetGroupContainer = styled.div`
  padding-top: 1em;
  margin-top: 1em;
  margin-bottom: 3em;
  border-top: 1px solid #f3f3f3;
`;

type DocumentSetListProps = {
  documentSets: DocumentSet[];
  groups: Group[];
  selectedDocumentSet: DocumentSet | null;
  onCreateNew: () => void;
  onDocumentSelect: (documentSet: DocumentSet) => void;
};

function getGroupName(groups: Group[], groupId: number): string {
  const foundGroup = _.find(groups, group => group.groupId === groupId);
  return foundGroup ? foundGroup.groupName : `[ Group ${groupId} ]`;
}

function getFilteredDocumentSets(
  searchFilter: string,
  groupName: string,
  documentSets: DocumentSet[],
): DocumentSet[] {
  const filterWords = lowerWords(searchFilter);
  return _.filter(documentSets, ds =>
    matchesFilter(filterWords, lowerWords(`${groupName} ${ds.name}`)),
  );
}

function lowerWords(s: string): string[] {
  return _.map(_.words(s), _.toLower);
}

function matchesFilter(
  filterWords: string[],
  candidateWords: string[],
): boolean {
  return (
    filterWords.length === 0 ||
    candidateWords.length === 0 ||
    _.every(
      filterWords,
      fw => _.findIndex(candidateWords, cw => cw.includes(fw)) > -1,
    )
  );
}

const DocumentSetList: React.FC<DocumentSetListProps> = props => {
  const [searchFilter, setSearchFilter] = useState<string>('');
  const documentSetsByGroup = _.groupBy(props.documentSets, ds => ds.groupId);

  return (
    <ListWrap>
      <DSListHeader>
        Document Sets{' '}
        <SecondaryButton
          style={{ alignSelf: 'flex-end', backgroundColor: '#1890ff' }}
          onClick={props.onCreateNew}
        >
          Create New
        </SecondaryButton>
      </DSListHeader>
      <DSListBody>
        <Input
          onChange={e => setSearchFilter(e.target.value)}
          placeholder="Filter document sets"
          prefix={<SearchOutlined />}
          style={{ display: 'block' }}
        />
        {_.map(documentSetsByGroup, (documentSets, groupId) => {
          const groupName = getGroupName(props.groups, parseInt(groupId, 10));
          const filteredDocumentSets = getFilteredDocumentSets(
            searchFilter,
            groupName,
            documentSets,
          );

          return filteredDocumentSets.length > 0 ? (
            <DocumentSetGroupContainer key={groupId}>
              <Typography.Title level={4}>
                {getGroupName(props.groups, parseInt(groupId, 10))}
              </Typography.Title>
              {_.map(filteredDocumentSets, ds => (
                <DocumentSetListItem
                  key={ds.documentSetId}
                  documentSet={ds}
                  onClick={props.onDocumentSelect}
                  isActive={
                    props.selectedDocumentSet &&
                    props.selectedDocumentSet.documentSetId === ds.documentSetId
                      ? true
                      : false
                  }
                />
              ))}
            </DocumentSetGroupContainer>
          ) : null;
        })}
      </DSListBody>
    </ListWrap>
  );
};
type DocumentSetListItemProps = {
  documentSet: DocumentSet;
  onClick: (documentSet: DocumentSet, setActiveItem: void) => void;
  isActive: boolean;
};

const DocumentSetListItem: React.FC<DocumentSetListItemProps> = props => {
  return (
    <div
      className={
        'document-set-list-item' + (props.isActive ? ' is-active' : '')
      }
      onClick={(e: any) => props.onClick(props.documentSet)}
      style={{
        lineHeight: '48px',
        transition: 'background-color 100ms ease-in',
        paddingLeft: '1em',
        height: '48px',
        display: 'flex',
        justifyContent: 'space-between',
        flexFlow: 'row',
        borderBottom: '1px solid #f3f3f3',
      }}
    >
      <Tooltip title={props.documentSet.name}>
        <span
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {props.documentSet.name}
        </span>
      </Tooltip>
      <RightOutlined
        style={{
          alignSelf: 'flex-end',
          color: '#53adfe',
          fontSize: '18px',
          margin: '0px 8px 14px 0px',
        }}
      />
    </div>
  );
};
export default DocumentSetList;
