import { every, filter, flatMap, flatten, get, isEqual, map } from 'lodash';
import { Action } from 'redux';
import { StateObservable } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { AjaxResponse } from 'rxjs/ajax';
import {
  catchError,
  concatMap,
  delayWhen,
  filter as filterObs,
  first,
  map as mapObservable,
  mergeMap,
  switchMap,
} from 'rxjs/operators';
import { ActionType, isActionOf } from 'typesafe-actions';
import { Annotation } from '../../annotations/models/annotation.model';
import { AnnotationRelation } from '../../annotations/models/annotation-relation.model';
import {
  selectAnnotations,
  selectAnnotationsArray,
} from '../../annotations/selectors/annotation.selectors';
import { selectAnnotationRelations } from '../../annotations/selectors/annotation-relation.selectors';
import {
  selectActiveAnnotationSchema,
  selectActiveSchemaLookups,
} from '../../annotations/selectors/annotation-schema.selectors';
import { AppState } from '../../app-state';
import { AppEpic } from '../../common/types/app-epic.type';
import { handleEpicError } from '../../common/utils/epics';
import { CONTENT_TYPE_HEADERS, authHeaders } from '../../common/utils/fetch';
import { config } from '../../config/application.config';
import { AppActions } from '../../root.actions';
import {
  appendPredictions,
  fetchPredictionsError,
  requestPredictions,
  runLookupPredictions,
  runPredictions,
  userSpecificPredictionsJobStarted,
} from '../actions/predictions.actions';
import { RawPredictionLabels } from '../models/predictions.model';
import { PredictionModel } from '../models/prediction-model.model';
import {
  selectCurrentLabels,
  selectCurrentlySelectedLabelGroup,
  selectSelectedLookupModels,
} from '../selectors/predictions.selectors';
import { Prediction } from '../models/predictions.model';
import { appendAnnotations } from '../../annotations/actions/annotations.actions';
import { appendAnnotationRelations } from '../../annotations/actions/annotation-relations.actions';
import { fetchLookupValues } from '../../domain/actions/lookup-context.actions';
import { selectAllAnnotationLookupsRetrieved } from '../../domain/selectors/lookup-context.selectors';
import { KeyValueMap } from '../../common/types/KeyValueMap.type';
import uuid from 'uuid/v4';
import { RELATION_TYPES } from '../../lib/relations/relation-types';
import { Map } from 'immutable';

type PredictionJobRequest = {
  labels: RawPredictionLabels;
  documentTextId: number;
  annotations: Annotation[];
};

type PredictionJobResponse = {
  predictionRequest: PredictionJobRequest;
  jobId: string;
};

export const runPredictionsEpic: AppEpic = (action$, state$) => {
  return action$.pipe(
    filterObs(isActionOf(runPredictions)),
    mergeMap(action => {
      const state = state$.value;
      const currentAnnotations = selectAnnotations(state);
      const currentAnnotationsArr = selectAnnotationsArray(state);
      const currentRelations = selectAnnotationRelations(state);
      const annotations: Annotation[][] = flatMap(currentAnnotationsArr, a =>
        Annotation.getDenormalizedRows(a, currentAnnotations, currentRelations),
      );
      const schemaId =
        selectActiveAnnotationSchema(state)?.annotationSchemaId || 0;
      return of(
        fetchLookupValues(schemaId, annotations),
        runLookupPredictions(),
        requestPredictions(),
      );
    }),
  );
};

export const runLookupPredictionsEpic: AppEpic = (action$, state$) => {
  return action$.pipe(
    filterObs(isActionOf(runLookupPredictions)),
    delayWhen(_ =>
      state$.pipe(filterObs(selectAllAnnotationLookupsRetrieved), first()),
    ),
    concatMap(a => {
      const state = state$.value;
      const lookups = selectActiveSchemaLookups(state);
      const lookupValues = state.lookupContext.lookupValues;
      const lookupModels = selectSelectedLookupModels(state);
      const annotations = selectAnnotations(state);
      const relations = selectAnnotationRelations(state);
      const predictions = PredictionModel.predict(
        lookups,
        lookupModels,
        lookupValues,
        annotations,
        relations,
      );
      const actions = predictions.map(ps => appendPredictions(ps));
      return from(actions);
    }),
  );
};

export const appendPredictionsEpic: AppEpic = (action$, state$) => {
  return action$.pipe(
    filterObs(isActionOf(appendPredictions)),
    concatMap(action => {
      const state = state$.value;
      const predictions = action.payload;
      const activeUserName = state.users?.activeUserAccount?.user?.username;
      const sourceDocumentId = state.annotations.sourceDocumentId;

      const groupedPredictions = KeyValueMap.groupBy(
        predictions,
        'parentAnnotationId',
      );
      const currentAnnotations = selectAnnotations(state);
      const currentAnnotationsArr = selectAnnotationsArray(state);
      const currentRelations = selectAnnotationRelations(state);
      const dedupedPredictions: Prediction[] = flatten(
        filter(
          groupedPredictions,
          ps => !checkIfExists(ps, currentAnnotations, currentRelations),
        ),
      );

      if (!activeUserName || !sourceDocumentId) {
        return from([]);
      }
      const annotationsFromPredictions = map(
        dedupedPredictions,
        (p: Prediction, k: number) => {
          const id = p.annotationId || `${p.uuid}-${k}`;
          const annotation: AnnotationWithParent = {
            id,
            type: p.label,
            start: p.startOffset,
            end: p.endOffset,
            annotatedBy: activeUserName,
            annotatedDate: p.createdAt,
            value: p.value,
            nativeText: p.nativeText,
            predictionId: p.predictionId,
            fromUserSpecificPrediction: true,
            parentAnnotationId: p.parentAnnotationId,
          };
          return annotation;
        },
      );

      const allAnnotations: Annotation[] = currentAnnotationsArr.concat(
        annotationsFromPredictions,
      );

      const relationsFromPredictions = KeyValueMap.usingKey(
        annotationsFromPredictions
          .map(a => relationFromPrediction(a, allAnnotations))
          .filter(r => !!r)
          .map(r => r as AnnotationRelation),
        'id',
      );

      const actions: Action[] = [
        appendAnnotations(
          KeyValueMap.usingKey(annotationsFromPredictions, 'id'),
          sourceDocumentId,
        ),
        appendAnnotationRelations(relationsFromPredictions),
      ];
      return from(actions);
    }),
    catchError(handleEpicError('Error appending predictions')),
  );
};

type AnnotationWithParent = Annotation & { parentAnnotationId: string | null };
function relationFromPrediction(
  annotation: AnnotationWithParent,
  annotations: Annotation[],
) {
  const parentId = annotation.parentAnnotationId || '';
  const parentAnnotation = annotations.find(a => a.id === parentId);

  if (!parentAnnotation) {
    return null;
  }

  const rule = {
    left: annotation.type,
    right: parentAnnotation.type,
    type: RELATION_TYPES.CHILD_OF,
  };
  return {
    id: uuid(),
    rule,
    left: annotation,
    right: parentAnnotation,
  };
}

export const requestPredictionsEpic: AppEpic = (action$, state$) => {
  return action$.pipe(
    filterObs(isActionOf(requestPredictions)),
    mapObservable(toMlPredictionJobRequest(state$)),
    switchMap(startMlPredictionJob(state$)),
  );
};

const toMlPredictionJobRequest = (state$: StateObservable<AppState>) => (
  _: ReturnType<typeof requestPredictions>,
): PredictionJobRequest => {
  const docTextId = getDocumentTextIdFromState(state$.value);
  const labelGroup = selectCurrentlySelectedLabelGroup(state$.value);
  const labels = selectCurrentLabels(state$.value);
  const annotations = selectAnnotationsArray(state$.value);

  return {
    annotations,
    labels: { [labelGroup]: labels },
    documentTextId: docTextId,
  };
};

type KeyValue = { key: string; value: string };

function checkIfExists(
  predictions: Prediction[],
  annotations: Map<string, Annotation>,
  relations: Map<string, AnnotationRelation>,
): boolean {
  const parentId = predictions[0].parentAnnotationId || '';
  const predictionVals: { key: string; value: string }[] = predictions.map(
    predictionToKeyValue,
  );
  const siblingVals: KeyValue[][] = Annotation.getChildren(
    parentId,
    annotations,
    relations,
  ).map(path => path.map(annotationToKeyValue));
  const exists = siblingVals.some(path => {
    return every(predictionVals, pkv => path.some(skv => isEqual(pkv, skv)));
  });
  return exists;
}

function predictionToKeyValue(p: Prediction): KeyValue {
  return { key: p.label, value: p.value };
}

function annotationToKeyValue(a: Annotation): KeyValue {
  return { key: a.type, value: a.value };
}

function getDocumentTextIdFromState(state: AppState): number {
  return get(state, 'sourceDocument.meta.documentTextId', 0);
}

function getPredictionJobUrl(baseUrl: string): string {
  return `${baseUrl}/predictions`;
}

const startMlPredictionJob = (state$: StateObservable<AppState>) => (
  predictionJobRequest: PredictionJobRequest,
): Observable<AppActions> => {
  return ajax
    .post(
      getPredictionJobUrl(config.annotationService.url),
      predictionJobRequest,
      {
        ...authHeaders(state$.value),
        ...CONTENT_TYPE_HEADERS.JSON,
      },
    )
    .pipe(
      mapObservable(toJobStartedAction),
      catchError(
        handleEpicError(
          'Error requesting predictions job',
          fetchPredictionsError,
        ),
      ),
    );
};

function toJobStartedAction(
  res: AjaxResponse,
): ActionType<typeof userSpecificPredictionsJobStarted> {
  const { jobId }: PredictionJobResponse = res.response;
  return userSpecificPredictionsJobStarted(jobId);
}
