import { RightOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { isArray, toString } from 'lodash';
import React from 'react';
import { DateComponent } from '../../common/components/date.component';
import { FileStatus } from '../../common/components/file-status.component';
import { QuerySort } from '../../common/types/QuerySort';
import { SetState } from '../../common/types/set-state.type';
import { AnnotatedDocument } from '../../documents/models/annotated-document.model';
import { OcrStatus } from '../../documents/resources/document-summary.resource';
import { defaultDateFormat } from '../containers/document-set-manager.container';
type PageState = [number, React.Dispatch<React.SetStateAction<number>>];

type DocumentManagementTableProps = {
  documents: AnnotatedDocument[];
  pageState: PageState;
  pageSize: number;
  onSortChanged: SetState<QuerySort | null>;
  onOcrFilterChanged: SetState<OcrStatus | undefined>;
  totalItems?: number | undefined;
  dataIsLoading?: boolean;
  tableScrollSize: object;
  onDocumentSelected: (ad: AnnotatedDocument) => void;
};

export function DocumentManagementTable({
  documents,
  pageState: [currentPage, setCurrentPage],
  pageSize,
  onSortChanged,
  onOcrFilterChanged,
  totalItems = 0,
  dataIsLoading = false,
  onDocumentSelected,
  tableScrollSize,
}: DocumentManagementTableProps) {
  const columns: ColumnProps<AnnotatedDocument>[] = [
    {
      title: <strong>Document Id</strong>,
      dataIndex: 'annotatedDocumentId',
      key: 'annotatedDocumentId',
      width: '10%',
      sorter: true,
    },
    {
      title: <strong>{'Original Filename/Aggregate Id'}</strong>,
      dataIndex: 'aggregateId',
      key: 'aggregateId',
      width: '60%',
      sorter: true,
    },
    {
      title: <strong>Imported At</strong>,
      dataIndex: 'createdAt',
      key: 'createdAt',
      defaultSortOrder: 'descend',
      sorter: true,
      width: '15%',
      render: (text: string) => {
        return (
          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-between',
            }}
          >
            <span>
              <DateComponent serializedDate={text} format={defaultDateFormat} />
            </span>
          </div>
        );
      },
    },
    {
      title: <strong>{'OCR Status'}</strong>,
      dataIndex: 'ocrStatus',
      key: 'ocrStatus',
      width: '15%',
      filters: [
        {
          text: 'Pending',
          value: 'pending',
        },
        {
          text: 'Completed',
          value: 'completed',
        },
        {
          text: 'Started',
          value: 'started',
        },
        {
          text: 'Failed',
          value: 'failed',
        },
      ],
      filterMultiple: false,
      sorter: true,
      render: (status: string) => {
        return (
          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-between',
            }}
          >
            <span>{<FileStatus status={status} />}</span>
            <RightOutlined
              style={{
                justifySelf: 'flex-end',
                fontSize: '21px',
                color: '#53adfe',
              }}
            />
          </div>
        );
      },
    },
  ];
  const pagination = {
    pageSize,
    current: currentPage,
    total: totalItems,
    showTotal: (total, range) =>
      `${range[0]}-${range[1]} of ${total.toLocaleString()} documents`,
    onChange: newPage => setCurrentPage(newPage),
  };

  return (
    <Table
      rowKey={doc => toString(doc.annotatedDocumentId)}
      onRow={doc => ({
        onClick: () => onDocumentSelected(doc),
      })}
      onChange={(_, filter, sorters) => {
        if (filter.ocrStatus) {
          const status = filter.ocrStatus[0] as OcrStatus;
          onOcrFilterChanged(status);
        }
        const sorter = isArray(sorters) ? sorters[0] : sorters;
        if (sorter.order && sorter.field) {
          const newSorter = {
            field: sorter.field.toString(),
            order: sorter.order,
          };
          onSortChanged(newSorter);
        }
      }}
      pagination={pagination}
      loading={dataIsLoading}
      columns={columns}
      dataSource={documents}
      scroll={tableScrollSize}
      size="middle"
    />
  );
}
