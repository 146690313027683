import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, DatePicker, Input, Select, Tabs } from 'antd';
import { SelectValue } from 'antd/lib/select/index';
import {
  filter,
  get,
  isEqual,
  isNull,
  map,
  mapValues,
  omit,
  some,
  startCase,
  toArray,
  values,
} from 'lodash';
import React from 'react';
import styled from 'styled-components';
import uuid from 'uuid/v4';
import { AnnotationType } from '../../annotations/models/annotation-type.model';
import { CountySelector } from '../../common/components/county-selector.component';
import { StateSelector } from '../../common/components/state-selector.component';
import { YesNoSelector } from '../../common/components/yes-no-selector.component';
import { KeyValueMap } from '../../common/types/KeyValueMap.type';
import { isStringArray } from '../../common/utils/isStringArray';
import { DocumentSet } from '../../document-sets/models/document-set.model';
import largeLogo from '../../oseberg_full_text_search_logo.svg';
import emblemLogo from '../../oseberg_full_text_search_logo_only-01.svg';
import textLogo from '../../oseberg_full_text_search_text.svg';
import { Queue } from '../../queues/models/queue.model';
import { GroupScopedTag } from '../../tags/models/group-scoped-tag.model';
import { AnnotationSearchPopover } from '../containers/annotation-search-popover.container';
import { QueryCondition } from '../models/query.model';
import {
  AnnotationSearchField,
  NEGATED_SEARCH_MODES,
  SEARCH_MODES_WITH_INPUT,
  SearchMode,
} from './annotation-search-field.component';
import { DocumentSetSelector } from './document-set-selector.component';
import { Moment } from 'moment';
import { EventValue } from 'rc-picker/lib/interface';

type RangePickerValue =
  | [EventValue<Moment>, EventValue<Moment>]
  | null
  | undefined;

const TabPane = Tabs.TabPane;

const LandingStyle = styled.div`
  padding-top: 50px;
  #search-form {
    max-width: 1160px;
    margin: auto !important;
  }

  .small-search-logo-component {
    display: none;
  }
`;

const ResultsStyle = styled.div`
  padding-top: 10px;
  #search-logo {
    display: none;
  }

  #search-bar {
    max-width: 800px;
  }
`;

const StyledForm = styled(Form)`
  .labeled-field {
    margin-bottom: 20px;
  }
`;

const SearchBarWrapper = styled.span`
  display: flex;
  .ant-input-group {
    > :first-child {
      border-right: 0px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    > :last-child {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    > :not(:first-child):not(:last-child):not(input) {
      border-radius: 0px;
      border-left: 0px;
      border-right: 0px;
    }
  }
`;

const SearchSection = styled.div``;

const FixedAdvancedFields = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 50%;

  .force-clear {
    display: block;
  }
`;

const AnnotationSearchFields = styled.div`
  .annotation-search-field {
    margin-top: 15px;
  }
`;

const AdvancedSection = styled.div`
  margin-bottom: 14px;
  padding: 0px 25px 0px 25px;
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  background-color: white;
  display: flex;
  flex-flow: row;
  max-width: 1158px;

  .ant-tabs-nav-container {
    font-size: 16px;
  }
`;

const FormColumn = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: space-around;
  flex-grow: 1;
  flex-basis: 33%;
`;

const LogoArea = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`;
const MidGutter = styled.div`
  flex: 0 0 50px;
`;

type SearchButtonProps = {
  onClick: () => void;
  disabled: boolean;
};

const SearchButton = ({ onClick, disabled }: SearchButtonProps) => (
  <Button
    type="primary"
    disabled={disabled}
    className="ant-input-search-button"
    onClick={onClick}
    htmlType="submit"
  >
    Search
  </Button>
);

type ResetButtonProps = {
  onClick: () => void;
  disabled: boolean;
};

const ResetButton = ({ onClick, disabled }: ResetButtonProps) => (
  <Button
    disabled={disabled}
    className="ant-input-search-button"
    onClick={onClick}
    htmlType="reset"
  >
    Reset
  </Button>
);

type LabeledFieldProps = {
  label: string;
  children: React.ReactNode | React.ReactNode[];
};

const LabeledFieldContainer = styled.div`
  display: flex;
  flex-flow: row;
`;

const Label = styled.div`
  font-size: 16px;
  display: flex;
  flex: 1;
`;

const ChildrenWrap = styled.div`
  display: flex;
  flex: 2;
`;

const LabeledField = (props: LabeledFieldProps) => (
  <LabeledFieldContainer className="labeled-field">
    <Label>{props.label}</Label>
    <ChildrenWrap>{props.children}</ChildrenWrap>
  </LabeledFieldContainer>
);

type AdvancedFilterToggleProps = {
  showingAdvanced: boolean;
  onClick: () => void;
  style?: React.CSSProperties;
};

/* eslint-disable */
const AdvancedFilterToggle: React.SFC<AdvancedFilterToggleProps> = props => (
  <div style={props.style}>
    <a href="#" onClick={props.onClick}>
      {props.showingAdvanced ? (
        <>
          Hide Advanced Filters <DownOutlined />
        </>
      ) : (
        <>
          Show Advanced Filters <RightOutlined />
        </>
      )}
    </a>
  </div>
);
/* eslint-enable */

export type SelectableDocumentSet = {
  documentSetId: number;
  name: string;
  groupId: number;
  selected: boolean;
};

export type FormValues = {
  mainSearch: string;
  is_annotated: string;
  is_reviewed: string;
  has_errors: string;
  state: string;
  county: string | string[];
  annotationSearchTerms: AnnotationSearchTerm[];
} & Partial<{
  aggregate_id: string;
  positiveStatus: SelectValue;
  negativeStatus: SelectValue;
  inQueues: SelectValue;
  notInQueues: SelectValue;
  withTags: SelectValue;
  withoutTags: SelectValue;
  instrument_type: string;
  recorded_date: RangePickerValue;
  created_at: RangePickerValue;
  last_annotation_timestamp: RangePickerValue;
  annotatedBy: string;
  reviewedBy: string;
}>;

export const defaultFormValues: FormValues = {
  mainSearch: '',
  is_annotated: 'all',
  is_reviewed: 'all',
  has_errors: 'all',
  state: 'all',
  county: ['all'],
  annotationSearchTerms: [],
};

export type AnnotationSearchTerm = {
  id: string;
  value: string;
  annotationType: AnnotationType;
  searchMode: SearchMode;
};

export interface SearchFormProps {
  documentSets: KeyValueMap<DocumentSet>;
  documentSetsSelected: KeyValueMap<SelectableDocumentSet>;
  formValues: FormValues;
  queryConditions: KeyValueMap<QueryCondition>;
  showAdvanced: boolean;
  availableStatuses: string[];
  availableQueues: Queue[];
  availableTags: GroupScopedTag[];
  showingResults: boolean;
  userHasAnnotationPermissions: boolean;
  submitQuery: (
    documentSetsSelected: KeyValueMap<SelectableDocumentSet>,
    formValues: FormValues,
    showAdvanced: boolean,
    queryConditions: KeyValueMap<QueryCondition>,
  ) => unknown;
  getDocStatuses: () => unknown;
  clearSelectedSearchResults: () => unknown;
  fetchQueues: () => unknown;
  fetchTags: () => unknown;
}
interface SearchFormState {
  documentSetsSelected: KeyValueMap<SelectableDocumentSet>;
  formValues: FormValues;
  queryConditions: KeyValueMap<QueryCondition>;
  showAdvanced: boolean;
}
export class SearchForm extends React.PureComponent<
  SearchFormProps,
  SearchFormState
> {
  state: SearchFormState = {
    documentSetsSelected: this.props.documentSetsSelected,
    queryConditions: this.props.queryConditions,
    showAdvanced: this.props.showAdvanced,
    formValues: this.props.formValues,
  };

  componentDidMount() {
    this.props.getDocStatuses();
    this.props.fetchQueues();
    this.props.fetchTags();
  }

  handleFormValueChange<K extends keyof FormValues>(
    key: K,
    value: FormValues[K],
  ): void {
    this.setState(state => {
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [key]: value,
        },
      };
    });
  }

  addAnnotationFilter = (annotationType: AnnotationType): void => {
    const id = `annotationTypeField/${uuid()}`;
    const newSearchTerm = {
      value: '',
      id,
      annotationType,
      searchMode: SearchMode.Contains,
    };
    const newAnnotationSearchTerms = [
      ...this.state.formValues.annotationSearchTerms,
      newSearchTerm,
    ];

    this.handleFormValueChange(
      'annotationSearchTerms',
      newAnnotationSearchTerms,
    );
    this.updateAnnotationFilter(newSearchTerm)('');
  };

  removeAnnotationFilter = (id: string): void => {
    const newAnnotationSearchTerms = this.state.formValues.annotationSearchTerms.filter(
      t => t.id !== id,
    );
    this.removeQueryCondition(id);
    this.handleFormValueChange(
      'annotationSearchTerms',
      newAnnotationSearchTerms,
    );
  };

  updateAnnotationSearchTerm = (id: string) => (
    newValues: Partial<AnnotationSearchTerm>,
  ): void => {
    const newAnnotationSearchTerms = this.state.formValues.annotationSearchTerms.map(
      term => (id === term.id ? { ...term, ...newValues } : term),
    );
    const [
      newAnnotationSearchTerm,
    ]: AnnotationSearchTerm[] = newAnnotationSearchTerms.filter(
      t => t.id === id,
    );
    this.handleFormValueChange(
      'annotationSearchTerms',
      newAnnotationSearchTerms,
    );
    this.updateAnnotationFilter(newAnnotationSearchTerm)(
      newAnnotationSearchTerm.value,
    );
  };

  updateAnnotationFilter = (t: AnnotationSearchTerm) => (value: string) => {
    const fieldToSearch = [`annotations.${t.annotationType.key}`];
    const negate = NEGATED_SEARCH_MODES.some(m => m === t.searchMode);
    const type = SEARCH_MODES_WITH_INPUT.some(m => m === t.searchMode)
      ? 'match_phrase'
      : 'exists';
    const id = t.id;
    const queryFilter: QueryCondition =
      type === 'exists'
        ? {
            fieldsToSearch: fieldToSearch,
            type,
            id,
            negate,
          }
        : {
            fieldsToSearch: fieldToSearch,
            value,
            type,
            id,
            negate,
          };
    this.handleFilterChange(queryFilter);
  };

  resetFormValues = (): void => {
    this.setState({
      formValues: defaultFormValues,
      queryConditions: {},
      documentSetsSelected: this.defaultSelectedDocSetsState(),
    });
  };

  get formIsDefault(): boolean {
    return (
      isEqual(this.state.formValues, defaultFormValues) &&
      isEqual(
        this.state.documentSetsSelected,
        this.defaultSelectedDocSetsState(),
      )
    );
  }

  get noDocumentSetsSelected(): boolean {
    return toArray(this.state.documentSetsSelected).every(v => !v.selected);
  }

  renderDocumentAdvancedFields = () => (
    <FixedAdvancedFields>
      <FormColumn>
        <LabeledField label="Aggregate Id">
          <Input
            placeholder="Enter id"
            value={this.state.formValues.aggregate_id}
            onChange={this.updateAggregateIdFilter()}
          />
        </LabeledField>
        <LabeledField label="Instrument Type">
          <Input
            placeholder="e.g. Assignment"
            value={this.state.formValues.instrument_type}
            onChange={this.updateInstrumentTypeFilter()}
          />
        </LabeledField>
        {this.dateFormElement('created_at', 'Created Date')}
        {this.dateFormElement('recorded_date', 'Recorded Date', [
          'annotations.recorded-date',
        ])}
      </FormColumn>
      <MidGutter />
      <FormColumn>
        <LabeledField label="State">
          <StateSelector
            selectedState={this.state.formValues.state || ''}
            onChange={this.updateStateFilter()}
          />
        </LabeledField>
        <LabeledField label="County">
          <CountySelector
            multi={true}
            selectedCounty={this.state.formValues.county}
            onChange={this.updateCountyFilter()}
            usState={this.getSelectedState()}
          />
        </LabeledField>
        <LabeledField label="With Tags">
          <Select
            mode="multiple"
            placeholder="Choose 1 or more tag"
            value={this.state.formValues.withTags}
            onChange={this.updateWithTagsFilter()}
            filterOption={(v, o) => {
              const title = o?.children || '';
              return title.toLowerCase().includes(v.toLowerCase());
            }}
          >
            {tagOptionsDisplay(this.props.availableTags)}
          </Select>
        </LabeledField>
        <LabeledField label="Without Tags">
          <Select
            mode="multiple"
            placeholder="Choose 1 or more tag"
            value={this.state.formValues.withoutTags}
            onChange={this.updateWithoutTagsFilter()}
            filterOption={(v, o) => {
              const title = o?.children || '';
              return title.toLowerCase().includes(v.toLowerCase());
            }}
          >
            {tagOptionsDisplay(this.props.availableTags)}
          </Select>
        </LabeledField>
      </FormColumn>
    </FixedAdvancedFields>
  );

  renderWorkStatusAdvancedFields = () => (
    <FixedAdvancedFields>
      <FormColumn>
        <LabeledField label="With Status">
          <Select
            mode="multiple"
            placeholder="Choose 1 or more document statuses"
            value={this.state.formValues.positiveStatus}
            onChange={this.updateWithStatusFilter()}
          >
            {statusOptionsDisplay(this.props.availableStatuses)}
          </Select>
        </LabeledField>
        <LabeledField label="Without Status">
          <Select
            mode="multiple"
            placeholder="Choose 1 or more document statuses"
            value={this.state.formValues.negativeStatus}
            onChange={this.updateWithoutStatusFilter()}
          >
            {statusOptionsDisplay(this.props.availableStatuses)}
          </Select>
        </LabeledField>
        <LabeledField label="In Queues">
          <Select
            mode="multiple"
            placeholder="Choose 1 or more queue"
            value={this.state.formValues.inQueues}
            onChange={this.updateInQueuesFilter()}
            filterOption={(v, o) => {
              const title = o?.children || '';
              return title.toLowerCase().includes(v.toLowerCase());
            }}
          >
            {queueOptionsDisplay(this.props.availableQueues)}
          </Select>
        </LabeledField>
        <LabeledField label="Not In Queues">
          <Select
            mode="multiple"
            placeholder="Choose 1 or more queue"
            value={this.state.formValues.notInQueues}
            onChange={this.updateNotInQueuesFilter()}
            filterOption={(v, o) => {
              const title = o?.children || '';
              return title.toLowerCase().includes(v.toLowerCase());
            }}
          >
            {queueOptionsDisplay(this.props.availableQueues)}
          </Select>
        </LabeledField>
        <LabeledField label="Annotated By">
          <Input
            placeholder="Enter annotator name"
            value={this.state.formValues.annotatedBy}
            onChange={this.updateAnnotatedByFilter()}
          ></Input>
        </LabeledField>
      </FormColumn>
      <MidGutter />
      <FormColumn>
        {this.booleanFormElement('is_annotated', 'Annotated?')}
        {this.booleanFormElement('is_reviewed', 'Reviewed?')}
        {this.dateFormElement(
          'last_annotation_timestamp',
          'Last Saved',
          [],
          true,
        )}
        {this.booleanFormElement('has_errors', 'Has Errors')}
        <LabeledField label="Reviewed By">
          <Input
            placeholder="Enter reviewer name"
            value={this.state.formValues.reviewedBy}
            onChange={this.updateReviewedByFilter()}
          ></Input>
        </LabeledField>
      </FormColumn>
    </FixedAdvancedFields>
  );

  renderAnnotationAdvancedFields = () => (
    <AnnotationSearchFields>
      <AnnotationSearchPopover
        onAnnotationTypeSelected={at => {
          this.addAnnotationFilter(at);
        }}
        refocusWhenVisible={true}
        placement="right"
        trigger="click"
      >
        <Button style={{ marginBottom: '16px' }}>Add Annotation Filter</Button>
      </AnnotationSearchPopover>
      {this.state.formValues.annotationSearchTerms.map(t => {
        return (
          <AnnotationSearchField
            key={t.id}
            annotationType={t.annotationType}
            id={t.id}
            initialValue={t.value}
            onChange={this.updateAnnotationSearchTerm(t.id)}
            onRemove={this.removeAnnotationFilter}
            searchMode={t.searchMode}
          />
        );
      })}
    </AnnotationSearchFields>
  );

  renderAdvancedSection = () => (
    <AdvancedSection>
      <Tabs defaultActiveKey="1" style={{ fontSize: '16px', width: '100%' }}>
        <TabPane tab="Document" key="1">
          {this.renderDocumentAdvancedFields()}
        </TabPane>
        <TabPane
          tab="Work Status"
          disabled={!this.props.userHasAnnotationPermissions}
          key="2"
        >
          {this.renderWorkStatusAdvancedFields()}
        </TabPane>
        <TabPane tab="Annotations" key="3">
          {this.renderAnnotationAdvancedFields()}
        </TabPane>
      </Tabs>
    </AdvancedSection>
  );

  private updateNotInQueuesFilter():
    | ((value: SelectValue) => void)
    | undefined {
    return (value: SelectValue) => {
      this.handleFormValueChange('notInQueues', value);
      this.handleFilterChange({
        fieldsToSearch: ['in_queues'],
        value: value as number[],
        type: 'contains',
        id: 'notInQueues',
        negate: true,
        logicalOperator: 'and',
      });
    };
  }

  private updateInQueuesFilter(): ((value: SelectValue) => void) | undefined {
    return (value: SelectValue) => {
      this.handleFormValueChange('inQueues', value);
      this.handleFilterChange({
        fieldsToSearch: ['in_queues'],
        value: value as number[],
        type: 'contains',
        id: 'inQueues',
        logicalOperator: 'and',
      });
    };
  }

  private updateWithTagsFilter = () => (value: SelectValue) => {
    this.handleFormValueChange('withTags', value);
    this.handleFilterChange({
      fieldsToSearch: ['group_scoped_tag_ids'],
      value: value as number[],
      type: 'contains',
      id: 'withTags',
      logicalOperator: 'and',
    });
  };

  private updateWithoutTagsFilter = () => (value: SelectValue) => {
    this.handleFormValueChange('withoutTags', value);
    this.handleFilterChange({
      fieldsToSearch: ['group_scoped_tag_ids'],
      value: value as number[],
      type: 'contains',
      id: 'withoutTags',
      negate: true,
      logicalOperator: 'and',
    });
  };

  private updateWithoutStatusFilter():
    | ((value: SelectValue) => void)
    | undefined {
    return (value: SelectValue) => {
      this.handleFormValueChange('negativeStatus', value);
      this.handleFilterChange({
        fieldsToSearch: ['document_statuses'],
        value: statusToStatusFilter(value),
        type: 'contains',
        id: 'negativeStatus',
        negate: true,
        logicalOperator: 'and',
      });
    };
  }

  private updateWithStatusFilter(): ((value: SelectValue) => void) | undefined {
    return (value: SelectValue) => {
      this.handleFormValueChange('positiveStatus', value);
      this.handleFilterChange({
        fieldsToSearch: ['document_statuses'],
        value: statusToStatusFilter(value),
        type: 'contains',
        id: 'positiveStatus',
        logicalOperator: 'and',
      });
    };
  }

  private updateCountyFilter(): (value: string | string[]) => any {
    return val => {
      const vals = isArray(val) ? val : [val];
      const containsAll = vals.indexOf('all') !== -1;
      const alreadyContainsAll = some(
        this.state.formValues.county,
        x => x === 'all',
      );
      const allSelected = containsAll && !alreadyContainsAll;
      const allEjectedIfIrrelevant = allSelected
        ? ['all']
        : filter(vals, v => v !== 'all');
      const formValuesToSave =
        allEjectedIfIrrelevant.length === 0 ? ['all'] : allEjectedIfIrrelevant;
      this.handleFormValueChange('county', formValuesToSave);
      // if filtering to all, we want an empty filter.
      const filterVal = some(formValuesToSave, x => x === 'all')
        ? ['']
        : expandOptionalFormats(formValuesToSave);
      this.handleFilterChange({
        fieldsToSearch: ['metadata.county', 'annotations.county'],
        value: filterVal,
        type: 'term',
        id: 'county',
      });
    };
  }

  private updateStateFilter(): (value: string) => any {
    return v => {
      this.handleFormValueChange('state', v);
      // if filtering to all, we want an empty filter.
      const val = v === 'all' ? '' : v;
      this.handleFilterChange({
        fieldsToSearch: ['metadata.state', 'annotations.state'],
        value: [val],
        type: 'term',
        id: 'state',
      });
    };
  }

  private updateInstrumentTypeFilter(): EventCallback {
    return e => {
      const val = e.target.value;
      this.handleFormValueChange('instrument_type', val);
      this.handleFilterChange({
        fieldsToSearch: ['metadata.instrumentType'],
        value: val,
        type: 'match_phrase',
        id: 'instrument_type',
      });
    };
  }

  private updateAggregateIdFilter(): EventCallback {
    return e => {
      this.handleFormValueChange('aggregate_id', e.target.value);
      this.handleFilterChange({
        fieldsToSearch: ['aggregate_id'],
        value: [e.target.value],
        type: 'exact',
        id: 'aggregate_id',
      });
    };
  }

  private updateAnnotatedByFilter(): EventCallback {
    return e => {
      this.handleFormValueChange('annotatedBy', e.target.value);
      this.handleFilterChange({
        fieldsToSearch: ['last_annotator_name'],
        value: [e.target.value],
        type: 'term',
        id: 'last_annotator_name',
      });
    };
  }

  private updateReviewedByFilter(): EventCallback {
    return e => {
      this.handleFormValueChange('reviewedBy', e.target.value);
      this.handleFilterChange({
        fieldsToSearch: ['last_reviewer_name'],
        value: [e.target.value],
        type: 'term',
        id: 'last_reviewer_name',
      });
    };
  }

  render() {
    const SearchStyle = this.props.showingResults ? ResultsStyle : LandingStyle;
    const totalDocumentSetsSelected = values(
      this.state.documentSetsSelected,
    ).filter(d => d.selected).length;
    const nDocumentSets = values(this.props.documentSets).length;
    const pluralizedDocumentSet = `document ${
      totalDocumentSetsSelected === 1 ? 'set' : 'sets'
    }`;
    const searchPlaceholder = `search across ${totalDocumentSetsSelected} ${pluralizedDocumentSet}`;
    return (
      <SearchStyle>
        <LogoArea id="search-logo">
          <img src={largeLogo} style={{ width: '350px' }} alt="Search Logo" />
        </LogoArea>
        <img
          src={textLogo}
          style={{ margin: '0 0 16px 0px' }}
          className="small-search-logo-component"
          alt="Search Logo Text"
        />
        <StyledForm id="search-form" layout="vertical">
          <SearchSection>
            <SearchBarWrapper>
              <img
                src={emblemLogo}
                style={{
                  width: '66px',
                  height: '66px',
                  marginTop: '-32px',
                  marginLeft: '-72px',
                }}
                className="small-search-logo-component"
                alt="Search Logo Emblem"
              />
              <Input.Group
                style={{ display: 'inline-flex', marginLeft: '4px' }}
              >
                <DocumentSetSelector
                  documentSetSelections={this.state.documentSetsSelected}
                  selectedDocumentSetsUpdate={this.handleSelectedDocSetsUpdate}
                >
                  <Button>
                    {`${totalDocumentSetsSelected} ${startCase(
                      pluralizedDocumentSet,
                    )}`}
                    <DownOutlined />
                  </Button>
                </DocumentSetSelector>
                <Input
                  disabled={nDocumentSets === 0}
                  id="search-bar"
                  name="queryString"
                  onChange={this.handleMainSearchInputChange}
                  value={this.state.formValues.mainSearch}
                  placeholder={searchPlaceholder}
                />
                <ResetButton
                  disabled={this.formIsDefault}
                  onClick={this.resetFormValues}
                />
                <SearchButton
                  onClick={this.submitQuery}
                  disabled={this.noDocumentSetsSelected}
                />
              </Input.Group>
            </SearchBarWrapper>
            <AdvancedFilterToggle
              showingAdvanced={this.state.showAdvanced}
              onClick={this.toggleAdvancedVisibility}
              style={{ fontSize: '16px', margin: '12px 0 12px 5px' }}
            />
          </SearchSection>
          {this.state.showAdvanced && this.renderAdvancedSection()}
          <input type="submit" style={{ display: 'none' }} />
        </StyledForm>
      </SearchStyle>
    );
  }

  getSelectedState(): string | null {
    return get(this.state.queryConditions.state, 'value[0]', null);
  }

  handleSelectedDocSetsUpdate = (
    documentSetsSelected: KeyValueMap<SelectableDocumentSet>,
  ) => {
    this.setState({
      documentSetsSelected,
    });
  };

  defaultSelectedDocSetsState: () => KeyValueMap<SelectableDocumentSet> = () =>
    mapValues(this.props.documentSets, ({ documentSetId, name, groupId }) => ({
      documentSetId,
      name,
      groupId,
      selected: true,
    }));

  componentDidUpdate(prevProps: SearchFormProps) {
    // TODO probably makes sense to make this a selector
    if (prevProps.documentSets !== this.props.documentSets) {
      const asSelectable = mapValues(this.props.documentSets, (v, k) => {
        const { documentSetId, name, groupId } = v;
        const previousOrDefault = get(
          this.props.documentSetsSelected[k],
          'selected',
          true,
        );
        return { documentSetId, name, groupId, selected: previousOrDefault };
      });
      this.setState({ documentSetsSelected: asSelectable });
    }
  }

  toggleAdvancedVisibility = () => {
    this.setState(state => ({ showAdvanced: !state.showAdvanced }));
  };

  handleFilterChange = (item: QueryCondition) => this.addQueryCondition(item);

  handleMainSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    const fieldToSearch = target.name;
    const value = target.value;
    this.setState(state => {
      const stateUpdate: Pick<
        SearchFormState,
        'queryConditions' | 'formValues'
      > = {
        queryConditions: {
          ...state.queryConditions,
          [fieldToSearch]: {
            fieldsToSearch: [fieldToSearch],
            value: [value],
            type: 'contains',
            id: fieldToSearch,
            logicalOperator: 'and',
          },
        },
        formValues: {
          ...state.formValues,
          mainSearch: value,
        },
      };
      return stateUpdate;
    });
  };

  submitQuery = () => {
    if (this.state.queryConditions) {
      this.props.submitQuery(
        this.state.documentSetsSelected,
        this.state.formValues,
        this.state.showAdvanced,
        this.state.queryConditions,
      );
      this.props.clearSelectedSearchResults();
      this.setState({
        showAdvanced: !this.props.showingResults
          ? false
          : this.state.showAdvanced,
      });
    }
  };

  booleanFormElement(
    filterName: 'is_reviewed' | 'is_annotated' | 'has_errors',
    label: string,
  ) {
    return (
      <LabeledField label={label}>
        <YesNoSelector
          value={this.state.formValues[filterName]}
          showAll={true}
          onChange={value => {
            this.handleFormValueChange(filterName, value);
            const coerced = coerceYesNo(value);
            if (isNull(coerced)) {
              this.setState(state => ({
                queryConditions: omit(state.queryConditions, filterName),
              }));
            } else {
              if (filterName === 'has_errors') {
                this.handleFilterChange({
                  fieldsToSearch: ['errors'],
                  negate: !coerced,
                  type: 'exists',
                  id: filterName,
                });
              } else {
                this.handleFilterChange({
                  fieldsToSearch: [filterName],
                  value: [coerced],
                  type: 'boolean',
                  id: filterName,
                });
              }
            }
          }}
        />
      </LabeledField>
    );
  }

  dateFormElement(
    filterName: 'recorded_date' | 'created_at' | 'last_annotation_timestamp',
    label: string,
    additionalFieldsToSearch: string[] = [],
    useEpochMicros = false,
  ) {
    return (
      <LabeledField label={label}>
        <DatePicker.RangePicker
          style={{ width: '100%' }}
          value={this.state.formValues[filterName]}
          onChange={(dates, dateStrings) => {
            this.handleFormValueChange(filterName, dates);
            if (!dates || dates.length <= 0) {
              this.removeQueryCondition(filterName);
            } else {
              this.handleFilterChange({
                fieldsToSearch: [filterName, ...additionalFieldsToSearch],
                value: map(dates, (date, index) => {
                  if (!date) {
                    return '';
                  } else {
                    if (useEpochMicros) {
                      // the value at index 0 indicates the start of our range
                      // thus we want to lock the timestamp and the beginning of the selected day
                      // otherwise it is the end of the range and we do the opposite.
                      const epochMillis =
                        index === 0
                          ? date.startOf('day').valueOf()
                          : date.endOf('day').valueOf();
                      const epochMicros = epochMillis * 1000;
                      return epochMicros;
                    } else {
                      return date.format('YYYY-MM-DD');
                    }
                  }
                }) as string[] | number[],
                type: 'range',
                id: filterName,
              });
            }
          }}
        />
      </LabeledField>
    );
  }

  addQueryCondition(qc: QueryCondition): void {
    this.setState(state => ({
      queryConditions: {
        ...state.queryConditions,
        [qc.id]: qc,
      },
    }));
  }

  removeQueryCondition(qcId: string): void {
    this.setState(state => ({
      queryConditions: omit(state.queryConditions, qcId),
    }));
  }
}

function statusOptionsDisplay(options: string[]) {
  const nodes = options.map(n => {
    return (
      <Select.Option key={n} value={n}>
        {n}
      </Select.Option>
    );
  });

  return nodes;
}

function queueOptionsDisplay(queues: Queue[]) {
  return queues.map(q => (
    <Select.Option key={q.queueId} value={q.queueId}>
      {q.queueName}
    </Select.Option>
  ));
}

function tagOptionsDisplay(tags: GroupScopedTag[]) {
  return map(tags, tag => (
    <Select.Option key={tag.groupScopedTagId} value={tag.groupScopedTagId}>
      {tag.tagName}
    </Select.Option>
  ));
}

function statusToStatusFilter(options: SelectValue): string[] {
  if (isStringArray(options)) {
    return options;
  } else {
    return [];
  }
}

function isArray<T>(thing: any): thing is T[] {
  return Array.isArray(thing);
}

function coerceYesNo(value: 'yes' | 'no' | 'all' | string): boolean | null {
  if (value === 'yes') {
    return true;
  } else if (value === 'no') {
    return false;
  }

  return null;
}

function expandOptionalFormats(vals: string[]): string[] {
  const valuesWithSpaces = filter(vals, v => v.indexOf(' ') !== -1);
  const valsWithoutSpaces = map(valuesWithSpaces, s => s.replace(/ /gi, ''));
  const expandedVals = vals.concat(valsWithoutSpaces);
  return expandedVals;
}

type EventCallback = (event: React.ChangeEvent<HTMLInputElement>) => void;
