import { connect } from 'react-redux';
import { AppState } from '../../app-state';
import { updateSchemaTypes } from '../../annotations/actions/annotation-schemas.actions';
import { AnnotationTypeTable } from '../components/annotation-type-table.component';
import { selectIsPermitted } from '../../accounts/selectors/users.selectors';
import {
  PermissionAction,
  PermissionResources,
} from '../../accounts/models/permission.model';

export default connect(
  (state: AppState) => ({
    isPermitted: (schemaId: number, action: PermissionAction) =>
      selectIsPermitted(action, PermissionResources.Schema, schemaId)(state),
  }),
  { updateSchemaTypes },
)(AnnotationTypeTable);
