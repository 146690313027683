import { Layout } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { GroupAccount } from '../../accounts/models/group-account.model';
import { GroupAccountCard } from '../containers/group-account-card.container';
import { GroupTable } from '../containers/group-table.container';
import { ContentCard } from './content-card.component';
const Content = Layout.Content;

const GroupManagerStyle = styled(Content)`
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  align-items: flex-start;

  .ant-table {
    border: none;
  }
`;

interface GroupManagerProps {
  selectedGroupAccount: GroupAccount | null;
  onGroupSelected: (account: GroupAccount) => any;
}
interface GroupManagerState {
  selectedGroupAccount: GroupAccount | null;
}
export class GroupManagerComponent extends React.Component<
  GroupManagerProps,
  GroupManagerState
> {
  constructor(props: GroupManagerProps) {
    super(props);

    this.state = {
      selectedGroupAccount: props.selectedGroupAccount,
    };
  }

  componentWillReceiveProps(props: GroupManagerProps) {
    if (props.selectedGroupAccount !== this.state.selectedGroupAccount) {
      this.setState({ selectedGroupAccount: props.selectedGroupAccount });
    }
  }

  selectAccount = (groupAccount: GroupAccount) => {
    this.setState({ selectedGroupAccount: groupAccount });
    this.props.onGroupSelected(groupAccount);
  };

  render() {
    const { selectedGroupAccount } = this.state;

    return (
      <GroupManagerStyle>
        <ContentCard>
          <GroupTable onSelect={this.selectAccount} />
        </ContentCard>
        <ContentCard>
          {selectedGroupAccount && (
            <GroupAccountCard account={selectedGroupAccount} />
          )}
        </ContentCard>
      </GroupManagerStyle>
    );
  }
}
