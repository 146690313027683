import { Button, Checkbox, Input, Table, Tooltip } from 'antd';
import { map } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import {
  PermissionAction,
  PermissionActions,
  PermissionResource,
} from '../../accounts/models/permission.model';
import { SimpleRole } from '../../accounts/models/simple-role.model';
import { distinctOn } from '../../common/utils/distinctOn';
import { SearchOutlined } from '@ant-design/icons';

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  text-align: left;
  padding: 0 8px;

  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }

  &:first-child {
    border-top: 1px solid #eee;
  }

  >* {
    flex: 1 50%;
    padding: 14px;
  }

  ul {
    list-style: none;
    margin-bottom: 0;
  }

  .ant-checkbox + span {
    font-size: 16px;
  }

  .ant-checkbox-wrapper {
    padding: 4px; 0;
  }

  .ant-select-selection {
    border-radius: 0;
    border-color: #eee;
  }
`;

const ButtonRow = styled(Row)`
  padding: 12px 12px 6px;

  > * {
    flex: 1 40%;
    padding: 14px;
  }

  .ant-btn {
    border-radius: 0;
    height: 38px;

    &:first-child {
      margin-right: 12px;
    }
  }
`;

type ResourceValue = {
  id: number;
  title: string;
};

export type SimpleRoleOverride = {
  role: SimpleRole;
  overriddenBy: string;
};

interface PermissionTableProps {
  resources: ResourceValue[];
  onSaveSimpleRoles: (roles: SimpleRole[]) => any;
  resourceType: PermissionResource;
  actions: PermissionAction[];
  targetRoles: SimpleRole[];
  targetRoleOverrides: SimpleRoleOverride[];
}

export const PermissionTable = (props: PermissionTableProps) => {
  const [targetRoles, setTargetRoles] = React.useState(props.targetRoles);
  const [changed, setChanged] = React.useState(false);
  React.useEffect(reset, [props.targetRoles]);

  const {
    Admin,
    All,
    Annotate,
    Clear,
    Delete,
    DeleteDocument,
    Download,
    Export,
    Import,
    Modify,
    Read,
    Review,
    Upload,
    UseModel,
    Write,
  } = PermissionActions;
  const actionTitles = {
    [Admin]: 'Admin',
    [All]: 'All',
    [Annotate]: 'Annotate',
    [Clear]: 'Clear',
    [DeleteDocument]: 'Delete Document',
    [Delete]: 'Delete',
    [Download]: 'Download',
    [Export]: 'Export',
    [Import]: 'Import',
    [Modify]: 'Modify',
    [Read]: 'Read',
    [Review]: 'Review',
    [Upload]: 'Upload',
    [UseModel]: 'Use Model',
    [Write]: 'Write',
  };
  const actionColumns = map(props.actions, a => {
    return { title: actionTitles[a], key: a, render: renderCheckbox(a) };
  });
  const resourceTitle = map(props.resourceType.split('-'), titleCase).join(' ');
  const columns = [
    {
      title: resourceTitle,
      dataIndex: 'title',
      key: 'title',
      ...columnSearchProps('title'),
    },
    ...actionColumns,
  ];
  const data = distinctOn(
    map(props.resources, r => {
      return { ...r, key: r.id };
    }),
    r => r.key,
  );
  return (
    <>
      <Table columns={columns} dataSource={data} />
      {changed && (
        <ButtonRow>
          <Button onClick={() => save()} type="primary">
            Save Changes
          </Button>
          <Button onClick={() => reset()} danger={true} ghost={true}>
            Cancel
          </Button>
        </ButtonRow>
      )}
    </>
  );

  function columnSearchProps(dataIndex: string) {
    let searchInput: Input | null;
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value: string | number | boolean, record: ResourceValue) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          : '',
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (visible) {
          setTimeout(() => searchInput?.select(), 100);
        }
      },
    };
  }

  function titleCase(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  function isPermitted(resource: number, action: PermissionAction): boolean {
    return !!findRole(resource, action);
  }

  function overriddenBy(
    resource: number,
    action: PermissionAction,
  ): string | undefined {
    return findRoleOverride(resource, action)?.overriddenBy;
  }

  function findRole(
    resource: number,
    action: PermissionAction,
  ): SimpleRole | undefined {
    return targetRoles.find(
      r =>
        r.resource === props.resourceType &&
        r.resourceId === resource &&
        (r.action === action || r.action === PermissionActions.All),
    );
  }

  function findRoleOverride(
    resource: number,
    action: PermissionAction,
  ): SimpleRoleOverride | undefined {
    return props.targetRoleOverrides.find(
      r =>
        r.role.resource === props.resourceType &&
        r.role.resourceId === resource &&
        (r.role.action === action || r.role.action === PermissionActions.All),
    );
  }

  function isPermittedAll(resource: number): boolean {
    return isPermitted(resource, PermissionActions.All);
  }

  function save() {
    props.onSaveSimpleRoles(targetRoles);
    setChanged(false);
  }

  function reset() {
    setChanged(false);
    setTargetRoles(props.targetRoles);
  }

  function onPermissionChanged(
    resourceId: number,
    action: PermissionAction,
    allowed: boolean,
  ) {
    const roles = targetRoles;
    const rolesMinusChanged = roles.filter(
      r => !(r.resourceId === resourceId && r.action === action),
    );
    const newRole = {
      action,
      resource: props.resourceType,
      resourceId: resourceId,
    };
    const newSimpleRoles = allowed
      ? rolesMinusChanged.concat([newRole])
      : rolesMinusChanged;
    setChanged(true);
    setTargetRoles(newSimpleRoles);
  }
  function renderCheckbox(action: PermissionAction) {
    return (resource: ResourceValue) => {
      const resourceId = resource.id;
      const overriddenByAll =
        isPermittedAll(resourceId) && action !== PermissionActions.All
          ? '"All" selected'
          : undefined;
      const disabledCause = overriddenBy(resourceId, action) || overriddenByAll;
      return (
        <Tooltip title={disabledCause}>
          <Checkbox
            checked={isPermitted(resourceId, action)}
            value={action}
            onChange={e =>
              onPermissionChanged(resourceId, e.target.value, e.target.checked)
            }
            disabled={!!disabledCause}
          ></Checkbox>
        </Tooltip>
      );
    };
  }
};
