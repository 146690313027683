import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import React from 'react';
import { RemoveButton } from '../../common/components/remove-button.component';

interface ActionCellProps {
  onRemoveClicked: (annotationId: string) => any;
}
export const ActionCell = (props: ActionCellProps) => (
  cellParams: ICellRendererParams,
) => {
  const { annotationId } = cellParams.data;
  return (
    <div style={{ textAlign: 'center' }}>
      <RemoveButton
        iconType="close-circle"
        onClick={() => props.onRemoveClicked(annotationId)}
      />
    </div>
  );
};
