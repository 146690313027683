import { Observable, empty } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { AppState } from '../../app-state';
import { authHeaders } from '../../common/utils/fetch';
import { config } from '../../config/application.config';
import { CurrentRoleInfo, Role } from '../models/role.model';

type RolesResource = { roles: Role[] };

export type MemberType = 'users' | 'groups';

export const RolesResource = {
  rolesUrl: `${config.annotationService.url}/roles`,
  currentRoleUrl(roleId: number) {
    return `${this.rolesUrl}/${roleId}`;
  },
  urlByMember: (roleId: number, memberId: number, memberType: MemberType) =>
    `${RolesResource.currentRoleUrl(roleId)}/${memberType}/${memberId}`,
  getAll: (state: AppState): Observable<RolesResource> =>
    ajax.getJSON<RolesResource>(
      RolesResource.rolesUrl + `/admin`,
      authHeaders(state),
    ),
  getCurrentRoleInfo: (state: AppState) => (
    roleId: number | null,
  ): Observable<CurrentRoleInfo> => {
    if (roleId) {
      return ajax.getJSON<CurrentRoleInfo>(
        RolesResource.currentRoleUrl(roleId),
        authHeaders(state),
      );
    } else return empty();
  },
  createRoleForMember: (
    state: AppState,
    roleId: number,
    memberId: number,
    memberType: MemberType,
  ) =>
    ajax.post(
      RolesResource.urlByMember(roleId, memberId, memberType),
      {},
      authHeaders(state),
    ),
  deleteMemberFromRole: (
    state: AppState,
    roleId: number,
    memberId: number,
    memberType: MemberType,
  ) =>
    ajax.delete(
      RolesResource.urlByMember(roleId, memberId, memberType),
      authHeaders(state),
    ),
};
