import { Middleware, MiddlewareAPI } from 'redux';
import { Dispatch } from 'redux';
import { isActionOf } from 'typesafe-actions';
import {
  createRedoAnnotationActions,
  createUndoAnnotationActions,
} from '../annotations/undo-redo-actions';
import { AppState } from '../app-state';
import { AppActions } from '../root.actions';
import {
  pushToUndoRedoHistory,
  redoAction,
  undoAction,
} from './actions/undo-redo.actions';
import { selectCurrentUndoRedoStep } from './selectors/undo-redo.selectors';

/**
 * Initial version of undo-redo middleware.
 * This middleware currently hardcodes the part of the store for which
 * it handles the undo-redo functionality. For future improvements,
 * this should be
 * configured externally or handled via passed in configuration.
 * @param store
 */
export const undoRedo: Middleware<{}, AppState> = store => next => action => {
  if (isActionOf(undoAction)(action)) {
    return performUndo(store, next, action);
  }

  if (isActionOf(redoAction)(action)) {
    return performRedo(store, next, action);
  }

  const previousState = store.getState();
  let result = next(action);
  const postState = store.getState();

  const undo = createUndoAnnotationActions(action, previousState, postState);
  if (undo.length) {
    const redo = createRedoAnnotationActions(action, previousState, postState);
    const pushStep = { redo, undo };
    result = next(pushToUndoRedoHistory(pushStep));
  }

  return result;
};

function performUndo(
  store: MiddlewareAPI<Dispatch<AppActions>, AppState>,
  next: Dispatch<AppActions>,
  action: ReturnType<typeof undoAction>,
) {
  const prevState = store.getState();
  let result = next(action);
  const nextState = store.getState();

  if (prevState.undoRedo.pointer !== nextState.undoRedo.pointer) {
    const undoStep = selectCurrentUndoRedoStep(prevState);
    for (const undo of undoStep.undo) {
      result = next(undo);
    }
  }

  return result;
}

function performRedo(
  store: MiddlewareAPI<Dispatch<AppActions>, AppState>,
  next: Dispatch<AppActions>,
  action: ReturnType<typeof redoAction>,
) {
  const prevState = store.getState();
  let result = next(action);
  const nextState = store.getState();

  if (prevState.undoRedo.pointer !== nextState.undoRedo.pointer) {
    const redoStep = selectCurrentUndoRedoStep(nextState);
    for (const redo of redoStep.redo) {
      result = next(redo);
    }
  }

  return result;
}
