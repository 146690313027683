import { RelationCondition } from '../relation-condition.model';
import { RelationRule } from '../relation-rule.model';
import {
  CONDITION_TYPES,
  ConditionType,
  RELATION_TYPES,
  RelationType,
  RuleOrCondition,
  isConditionType,
  isRuleType,
} from '../relation-types';
import { RelationViolation } from '../relation-violation.model';
import { Relation } from '../relation.model';
import { childOf } from './child-of';
import { or } from './or';

export type Validator<U, T, R extends RuleOrCondition<T>> = (
  entity: U,
  rule: R,
  relations: Relation<T, U>[],
  compare: (entity: U, type: T) => boolean,
) => RelationViolation<U, T>[];

const RuleValidators = {
  [RELATION_TYPES.CHILD_OF]: childOf,
};

const ConditionValidators = {
  [CONDITION_TYPES.OR]: or,
};

export function getValidator<U, T>(
  ruleType: RelationType | ConditionType,
): Validator<U, T, RuleOrCondition<T>> | null {
  if (isRuleType(ruleType)) {
    return getRuleValidator<U, T>(ruleType) as Validator<
      U,
      T,
      RuleOrCondition<T>
    >;
  } else if (isConditionType(ruleType)) {
    return getConditionValidator<U, T>(ruleType) as Validator<
      U,
      T,
      RuleOrCondition<T>
    >;
  } else {
    return null;
  }
}

export function getRuleValidator<U, T>(
  ruleType: RelationType,
): Validator<U, T, RelationRule<T>> | null {
  return RuleValidators[ruleType] || null;
}

export function getConditionValidator<U, T>(
  ruleType: ConditionType,
): Validator<U, T, RelationCondition<T>> | null {
  return ConditionValidators[ruleType] || null;
}
