import { PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Modal } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import React, { useState } from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '../../common/components/primary-button.component';
import { SecondaryButton } from '../../common/components/secondary-button.component';
import { validateForm } from '../../common/utils/validateForm';

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
`;

type SchemaCreatorRawFormValues = {
  title: string;
  documentSetMatcher?: string;
  documentTypeMatcher?: string;
};

export type SchemaCreatorFormValues = {
  title: string;
  groupId: number;
  documentSetMatcher?: string;
  documentTypeMatcher?: string;
};

export type SchemaCreatorProps = {
  currentUserGroupId: number | null;
  onSchemaCreate: (v: SchemaCreatorFormValues) => unknown;
} & FormComponentProps;

// This is necessary to correct an error message from Ant's Form.create
const AntInput = React.forwardRef((props, _ref) => <Input {...props} />);

const { Item } = Form;
const SchemaCreatorForm: React.FC<SchemaCreatorProps> = ({
  form,
  currentUserGroupId,
  onSchemaCreate,
}) => {
  const createSchema = validateForm<SchemaCreatorRawFormValues>(
    form,
    v =>
      onSchemaCreate({
        title: v.title,
        groupId: currentUserGroupId || 0,
        documentSetMatcher: v.documentSetMatcher,
        documentTypeMatcher: v.documentTypeMatcher,
      }),
    true,
  );
  const [showModal, setModalView] = useState(false);
  const { getFieldDecorator } = form;

  return (
    <>
      <SecondaryButton onClick={() => setModalView(true)}>
        <PlusOutlined />
        Add Schema
      </SecondaryButton>
      <Modal
        title="Create Schema"
        visible={showModal}
        footer={null}
        onCancel={() => setModalView(false)}
        destroyOnClose={true}
      >
        <Form onSubmit={createSchema}>
          <Item label="Title">
            {getFieldDecorator('title', {
              rules: [
                {
                  required: true,
                  message: 'Schema title is required.',
                },
              ],
            })(<AntInput />)}
          </Item>
          <Item label="Document Set Matcher">
            {getFieldDecorator('documentSetMatcher', {
              rules: [{ required: false }],
            })(<AntInput />)}
          </Item>
          <Item label="Document Type Matcher">
            {getFieldDecorator('documentTypeMatcher', {
              rules: [{ required: false }],
            })(<AntInput />)}
          </Item>
          <Right>
            <PrimaryButton
              onClick={() => setModalView(false)}
              htmlType="submit"
            >
              Create Schema
            </PrimaryButton>
          </Right>
        </Form>
      </Modal>
    </>
  );
};

export const SchemaCreatorComponent = Form.create<SchemaCreatorProps>()(
  SchemaCreatorForm,
);
