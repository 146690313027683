import { createAction } from 'typesafe-actions';
import { DocumentStatus } from '../resources/available-document-statuses.resource';

enum Types {
  FETCH_AVAILABLE_DOCUMENT_STATUSES = 'FETCH_AVAILABLE_DOCUMENT_STATUSES',
  POPULATE_AVAILABLE_DOCUMENT_STATUSES = 'POPULATE_AVAILABLE_DOCUMENT_STATUSES',
  FETCH_AVAILABLE_DOCUMENT_STATUSES_ERROR = 'FETCH_AVAILABLE_DOCUMENT_STATUSES_ERROR',
}

export const fetchAvailableDocumentStatuses = createAction(
  Types.FETCH_AVAILABLE_DOCUMENT_STATUSES,
);

export const populateAvailableDocumentStatuses = createAction(
  Types.POPULATE_AVAILABLE_DOCUMENT_STATUSES,
  resolvePayload => (availableStatuses: DocumentStatus[]) =>
    resolvePayload({
      availableStatuses,
    }),
);

export const fetchAvailableDocumentStatusesError = createAction(
  Types.FETCH_AVAILABLE_DOCUMENT_STATUSES_ERROR,
);
