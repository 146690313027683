import { getType } from 'typesafe-actions';
import { AppState } from '../../app-state';
import {
  closeDocumentSelector,
  openDocumentSelector,
  toggleDocumentSelector,
} from '../actions/document-selector.actions';
import { DocumentSelectorActions } from '../actions/document-selector.types';

export type DocumentSelectorState = {
  visible: boolean;
};

const defaultState = {
  visible: false,
};

export function documentSelectorReducer(
  state: DocumentSelectorState = defaultState,
  action: DocumentSelectorActions,
  appState: AppState,
): DocumentSelectorState {
  switch (action.type) {
    case getType(openDocumentSelector):
      return { ...state, visible: true };

    case getType(closeDocumentSelector):
      return { ...state, visible: false };

    case getType(toggleDocumentSelector):
      return { ...state, visible: !state.visible };

    default:
      return state;
  }
}
