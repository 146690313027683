import { connect } from 'react-redux';
import { AppState } from '../../app-state';
import { setActiveAdminTab } from '../actions/ui-admin.actions';
import { AdminSiderComponent } from '../components/admin-sider.component';
import { AdminTab } from '../models/admin-tab.model';
import { selectActiveTab } from '../selectors/ui.selectors';

export const AdminSider = connect(
  (state: AppState) => ({
    activeTab: selectActiveTab(state),
  }),
  dispatch => ({
    setActiveTab: (tab: AdminTab) => dispatch(setActiveAdminTab(tab)),
  }),
)(AdminSiderComponent);
