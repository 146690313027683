import { Button } from 'antd';
import styled from 'styled-components';
import { DodgerBlue } from '../colors';

export const ConfirmButton = styled(Button as any)`
  &.ant-btn {
    background-color: ${DodgerBlue};
    color: white;
    border: none;
  }
`;
