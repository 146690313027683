import { getType } from 'typesafe-actions';
import { signOut } from '../../auth/actions/auth.actions';
import { AuthActions } from '../../auth/actions/auth.types';
import { KeyValueMap } from '../../common/types/KeyValueMap.type';
import { saveGroupSimpleRoles } from '../actions/groups.actions';
import { GroupsActions } from '../actions/groups.types';
import {
  createUserAccount,
  fetchUsers,
  populateUsers,
  setActiveUserAccount,
  storeUserAccount,
} from '../actions/users.actions';
import { UsersActions } from '../actions/users.types';
import { UserAccount } from '../models/user-account.model';
import { User } from '../models/user.model';

export type UsersState = {
  entities: KeyValueMap<User>;
  accounts: KeyValueMap<UserAccount>;
  activeUserAccount: UserAccount | null;
  loading: boolean;
};

export const defaultUserState = {
  entities: {},
  accounts: {},
  activeUserAccount: null,
  loading: false,
};

export function usersReducer(
  state: UsersState = defaultUserState,
  action: GroupsActions | AuthActions | UsersActions,
): UsersState {
  switch (action.type) {
    case getType(populateUsers): {
      const users = KeyValueMap.usingPropertyKey<User>(
        action.payload.users,
        'userId',
      );

      return {
        ...state,
        entities: users,
        loading: false,
      };
    }

    case getType(fetchUsers):
      return { ...state, loading: true };

    case getType(setActiveUserAccount): {
      return {
        ...state,
        activeUserAccount: action.payload.account,
      };
    }

    case getType(storeUserAccount): {
      const account = action.payload.account;
      const key = account.user.userId;
      const isActiveUser = key === state.activeUserAccount?.user?.userId;
      const activeUserAccount = isActiveUser
        ? account
        : state.activeUserAccount;
      return {
        ...state,
        activeUserAccount,
        accounts: {
          ...state.accounts,
          [key]: account,
        },
      };
    }

    case getType(saveGroupSimpleRoles): {
      // Remove stale user account data when their groups are updated.
      return {
        ...state,
        accounts: {},
      };
    }

    case getType(createUserAccount.request): {
      return {
        ...state,
        loading: true,
      };
    }
    case getType(createUserAccount.success): {
      return {
        ...state,
        loading: false,
      };
    }
    case getType(createUserAccount.failure): {
      return {
        ...state,
        loading: false,
      };
    }

    case getType(signOut):
      return defaultUserState;

    default:
      return state;
  }
}
