import { getType } from 'typesafe-actions';
import {
  setLookupAnnotationIds,
  setLookupValues,
  setLookups,
} from '../actions/lookup-context.actions';
import {
  DomainLookup,
  DomainLookupValue,
} from '../models/lookup-context.model';
import { LookupContextActions } from '../actions/lookup-context.types';
import { distinctOn } from '../../common/utils/distinctOn';

export type LookupContextState = {
  lookups: DomainLookup[];
  lookupValues: DomainLookupValue[];
  lookupAnnotationIds: string[];
};

const defaultLookupContextState = {
  lookups: [],
  lookupValues: [],
  lookupAnnotationIds: [],
};

export function lookupContextReducer(
  state: LookupContextState = defaultLookupContextState,
  action: LookupContextActions,
): LookupContextState {
  switch (action.type) {
    case getType(setLookups): {
      return { ...state, lookups: action.payload.lookups };
    }
    case getType(setLookupValues): {
      const lookupValues = distinctOn(
        action.payload.lookupValues,
        lv => `${lv.domainLookupId} {lv.key} ${lv.value}`,
      );
      return {
        ...state,
        lookupValues,
      };
    }
    case getType(setLookupAnnotationIds): {
      const ids = action.payload.ids;
      return {
        ...state,
        lookupAnnotationIds: ids,
      };
    }
    default:
      return state;
  }
}
