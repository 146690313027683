import {
  LockOutlined,
  TeamOutlined,
  UserAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React from 'react';
import { AdminTab, AdminTabs } from '../models/admin-tab.model';

const { Sider } = Layout;

interface AdminSiderProps {
  activeTab: AdminTab;
  setActiveTab: (tab: AdminTab) => any;
}
export class AdminSiderComponent extends React.Component<AdminSiderProps> {
  render() {
    const { activeTab, setActiveTab } = this.props;

    return (
      <Sider>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[activeTab]}
          onClick={tab => setActiveTab(tab.key as AdminTab)}
          key={activeTab}
        >
          <Menu.Item key={AdminTabs.Users}>
            <UserOutlined />
            <span>Users</span>
          </Menu.Item>
          <Menu.Item key={AdminTabs.Groups}>
            <TeamOutlined />
            <span>Groups</span>
          </Menu.Item>
          <Menu.Item key={AdminTabs.Roles}>
            <LockOutlined />
            <span>Roles</span>
          </Menu.Item>
          <Menu.Item key={AdminTabs.CreateUser}>
            <UserAddOutlined />
            <span>Create User</span>
          </Menu.Item>
        </Menu>
      </Sider>
    );
  }
}
