import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../app-state';
import { SecondaryButton } from '../../common/components/secondary-button.component';
import { selectSourceDocumentIsEditable } from '../../documents/selectors/source-document.selectors';
import { setActiveAnnotationSchema } from '../actions/annotation-schemas.actions';
import { createAnnotationFromType } from '../actions/annotations.actions';
import { AnnotationListPopover } from '../components/annotation-list-popover.component';
import { AnnotationSchema } from '../models/annotation-schema.model';
import { AnnotationType } from '../models/annotation-type.model';
import { Annotation } from '../models/annotation.model';
import {
  selectActiveAnnotationSchema,
  selectUserSelectableSchemasArray,
} from '../selectors/annotation-schema.selectors';
import { selectAnnotationsArray } from '../selectors/annotation.selectors';

interface ButtonProps {
  schema: AnnotationSchema | null;
  allSchemas: AnnotationSchema[];
  onAnnotationTypeSelected: (type: AnnotationType) => void;
  onSelectSchema: (schema: AnnotationSchema) => void;
  annotations: Annotation[];
  disabled: boolean;
}

export const CustomAnnotationButtonComponent = (props: ButtonProps) => {
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    setVisible(false);
  }, [props.annotations.length]);

  return (
    <AnnotationListPopover
      {...props}
      annotationMode="manual"
      refocusWhenVisible={true}
      hotkeysDisabled={!visible || props.disabled}
      trigger="click"
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <SecondaryButton
        onClick={() => setVisible(true)}
        disabled={props.disabled}
        style={{ lineHeight: '1.4' }}
      >
        <PlusOutlined />
        Add Annotation
      </SecondaryButton>
    </AnnotationListPopover>
  );
};

export const CustomAnnotationButton = connect(
  (state: AppState) => ({
    schema: selectActiveAnnotationSchema(state),
    allSchemas: selectUserSelectableSchemasArray(state),
    annotations: selectAnnotationsArray(state),
    disabled: !selectSourceDocumentIsEditable(state),
  }),
  dispatch => ({
    onAnnotationTypeSelected: (type: AnnotationType) =>
      dispatch(createAnnotationFromType(type)),
    onSelectSchema: (schema: AnnotationSchema) =>
      dispatch(setActiveAnnotationSchema(schema)),
  }),
)(CustomAnnotationButtonComponent);
