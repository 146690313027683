import { sortBy } from 'lodash';
import { getType } from 'typesafe-actions';
import { fetchTagsAsync } from '../actions/tag.actions';
import { TagActions } from '../actions/tag.types';
import { GroupScopedTag } from '../models/group-scoped-tag.model';

export type TagsState = {
  groupScopedTags: GroupScopedTag[];
};

const defaultTagsState: TagsState = {
  groupScopedTags: [],
};

export function tagsReducer(
  state: TagsState = defaultTagsState,
  action: TagActions,
): TagsState {
  switch (action.type) {
    case getType(fetchTagsAsync.success):
      return {
        ...state,
        groupScopedTags: sortBy(action.payload, t => t.tagName.toLowerCase()),
      };
    default:
      return state;
  }
}
