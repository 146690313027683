import { createAction } from 'typesafe-actions';
import { GroupAccount } from '../models/group-account.model';
import { Group } from '../models/group.model';
import { SimpleRole } from '../models/simple-role.model';

enum Types {
  POPULATE_GROUPS = 'POPULATE_GROUPS: Populates groups.',
  FETCH_GROUPS = 'FETCH_GROUPS: Triggers a request of new groups.',
  FETCH_ALL_GROUP_ACCOUNTS = 'FETCH_ALL_GROUP_ACCOUNTS: Fetches all group accounts.',
  POPULATE_GROUP_ACCOUNTS = 'POPULATE_GROUP_ACCOUNTS: Populates groups accounts.',
  STORE_GROUP_ACCOUNT = 'SET_GROUP_ACCOUNT: Stores a single group account to the state store.',
  SAVE_GROUP_SIMPLE_ROLES = 'SAVE_GROUP_SIMPLE_ROLES: Saves group simple roles over network',
}

export const populateGroups = createAction(
  Types.POPULATE_GROUPS,
  resolvePayload => (groups: Group[]) => resolvePayload({ groups }),
);

export const fetchGroups = createAction(Types.FETCH_GROUPS);

export const fetchAllGroupAccounts = createAction(
  Types.FETCH_ALL_GROUP_ACCOUNTS,
);

export const populateGroupAccounts = createAction(
  Types.POPULATE_GROUP_ACCOUNTS,
  resolvePayload => (accounts: GroupAccount[]) => resolvePayload({ accounts }),
);

export const storeGroupAccount = createAction(
  Types.STORE_GROUP_ACCOUNT,
  resolvePayload => (account: GroupAccount) => resolvePayload({ account }),
);

export const saveGroupSimpleRoles = createAction(
  Types.SAVE_GROUP_SIMPLE_ROLES,
  resolvePayload => (groupId: number, roles: SimpleRole[]) =>
    resolvePayload({ groupId, roles }),
);
