import { reduce } from 'lodash';
import { Action } from 'redux';
import { getType } from 'typesafe-actions';
import { groupsReducer } from './accounts/reducers/groups.reducer';
import { usersReducer } from './accounts/reducers/users.reducer';
import { uiAdminReducer } from './admin/reducers/ui-admin.reducer';
import { annotationReviewReducer } from './annotation-review/reducers/annotation-review.reducer';
import { annotationDocumentStatusReducer } from './annotations/reducers/annotation-document-status.reducer';
import { annotationPagesReducer } from './annotations/reducers/annotation-pages.reducer';
import { pageControlsReducer } from './documents/reducers/page-controls.reducer';
import { annotationRelationsReducer } from './annotations/reducers/annotation-relations.reducer';
import { annotationSchemasReducer } from './annotations/reducers/annotation-schemas.reducer';
import { annotationsReducer } from './annotations/reducers/annotations.reducer';
import { availableDocumentStatusReducer } from './annotations/reducers/available-document-status.reducer';
import { bulkAnnotationsImportStatusesReducer } from './annotations/reducers/bulk-annotations-importer-status.reducer';
import { uiAnnotationsReducer } from './annotations/reducers/ui-annotations.reducer';
import { signOut } from './auth/actions/auth.actions';
import { authReducer } from './auth/reducers/auth.reducer';
import { KeyValueMap } from './common/types/KeyValueMap.type';
import { config } from './config/application.config';
import { documentListReducer } from './documents/reducers/document-list.reducer';
import { documentSelectorReducer } from './documents/reducers/document-selector.reducer';
import { documentSetsReducer } from './documents/reducers/document-sets.reducer';
import { sourceDocumentReducer } from './documents/reducers/source-document.reducer';
import { uiPdfViewerReducer } from './documents/reducers/ui-pdf-viewer.reducer';
import { createFlagsReducer } from './flags';
import { uiPanesReducer } from './layout/reducers/ui-panes.reducer';
import { predictionSelectorReducer } from './predictions/reducers/prediction-selector.reducer';
import { predictionsReducer } from './predictions/reducers/predictions.reducer';
import { queuesReducer } from './queues/reducers/queues.reducer';
import { rolesReducer } from './roles/reducers/roles.reducer';
import { exportsReducer } from './search/reducers/export.reducer';
import { searchReducer } from './search/reducers/search.reducer';
import { tagsReducer } from './tags/reducers/tags.reducer';
import { uiSettingsReducer } from './ui-settings/reducers/ui-settings.reducer';
import { undoRedoReducer } from './undo-redo/reducers/undo-redo.reducer';
import { usageDocumentSummaryLoadReducer } from './usage/reducers/usage-document-summary-load.reducer';
import { lookupContextReducer } from './domain/reducers/lookup-context.reducer';

export const appReducers = {
  annotationDocumentStatuses: annotationDocumentStatusReducer,
  annotationPages: annotationPagesReducer,
  annotationRelations: annotationRelationsReducer,
  annotationReview: annotationReviewReducer,
  annotationSchemas: annotationSchemasReducer,
  annotations: annotationsReducer,
  auth: authReducer,
  bulkAnnotationsImportStatuses: bulkAnnotationsImportStatusesReducer,
  availableDocumentStatuses: availableDocumentStatusReducer,
  documentList: documentListReducer,
  documentSets: documentSetsReducer,
  flags: createFlagsReducer(config.flags),
  groups: groupsReducer,
  predictions: predictionsReducer,
  queues: queuesReducer,
  roles: rolesReducer,
  search: searchReducer,
  sourceDocument: sourceDocumentReducer,
  tags: tagsReducer,
  undoRedo: undoRedoReducer,
  users: usersReducer,
  exports: exportsReducer,
  lookupContext: lookupContextReducer,
  pageControls: pageControlsReducer,

  _UI_documentSelector: documentSelectorReducer,
  _UI_predictionSelector: predictionSelectorReducer,
  _UI_pdfViewer: uiPdfViewerReducer,
  _UI_panes: uiPanesReducer,
  _UI_annotations: uiAnnotationsReducer,
  _UI_settings: uiSettingsReducer,
  _UI_admin: uiAdminReducer,

  _USAGE_document_summary_load: usageDocumentSummaryLoadReducer,
};

const defaultState = {};

export function createRootReducer(reducers: KeyValueMap<any>) {
  return function rootReducer(
    appState: any = defaultState,
    appAction: Action & any,
  ) {
    const appReducer = (state: any, action: Action & any) =>
      reduce(
        reducers,
        (nextState: any, reducer: any, key: string) => ({
          ...nextState,
          [key]: reducer(nextState[key], action, nextState),
        }),
        state,
      );

    return appAction.type === getType(signOut)
      ? appReducer(defaultState, appAction)
      : appReducer(appState, appAction);
  };
}
