import { reject } from 'lodash';
import { getType } from 'typesafe-actions';
import { closeDocument } from '../../documents/actions/source-document.actions';
import { SourceDocumentActions } from '../../documents/actions/source-document.types';
import {
  addAnnotationDocumentStatus,
  populateAnnotationDocumentStatuses,
  removeAnnotationDocumentStatus,
} from '../actions/annotation-document-status.actions';
import { AnnotationDocumentStatusActions } from '../actions/annotation-document-status.types';
import { AnnotationDocumentStatus } from '../resources/annotation-document-status.resource';

export type AnnotationDocumentStatusState = AnnotationDocumentStatus[];

const defaultState: AnnotationDocumentStatusState = [];

export function annotationDocumentStatusReducer(
  state: AnnotationDocumentStatusState = defaultState,
  action: AnnotationDocumentStatusActions | SourceDocumentActions,
): AnnotationDocumentStatusState {
  switch (action.type) {
    case getType(populateAnnotationDocumentStatuses): {
      return action.payload.documentStatuses;
    }
    case getType(addAnnotationDocumentStatus): {
      const status = action.payload.status;
      return status ? addStatus(status, state) : state;
    }
    case getType(removeAnnotationDocumentStatus): {
      const status = action.payload.status;
      return status ? removeStatus(status, state) : state;
    }
    case getType(closeDocument):
      return defaultState;
    default:
      return state;
  }
}

function addStatus(
  status: AnnotationDocumentStatus,
  state: AnnotationDocumentStatusState,
): AnnotationDocumentStatusState {
  return [...state, { status: status.status, statusId: status.statusId }];
}

function removeStatus(
  status: AnnotationDocumentStatus,
  state: AnnotationDocumentStatusState,
): AnnotationDocumentStatusState {
  return reject(state, status);
}
