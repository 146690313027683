import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select } from 'antd';
import { DataSourceItemObject } from 'antd/lib/auto-complete';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import Fuse, { FuseOptions } from 'fuse.js';
import React, { useState } from 'react';
import { Group } from '../../accounts/models/group.model';
import { User } from '../../accounts/models/user.model';
import { PrimaryButton } from '../../common/components/primary-button.component';
import { SetState } from '../../common/types/set-state.type';
import { StateWithSetter } from '../../common/types/state-with-setter.type';
import { validateForm } from '../../common/utils/validateForm';
import { MemberToRoleProps } from '../containers/member-roles.container';
import { MemberType } from '../resources/roles.resource';
import { map } from 'lodash';

type addMemberToRoleProps = {
  currentRoleIdState: StateWithSetter<number | null>;
  currentMemberTypeState: StateWithSetter<MemberType | null>;
  setRoleAction: SetState<boolean>;
  addNewMemberToRole: (payload: MemberToRoleProps) => void;
  users: User[];
  groups: Group[];
  roleTitleAndDescriptions: DataSourceItemObject[];
} & FormComponentProps;

const { Item } = Form;
const { Option } = Select;

export const AddMemberToRoleForm: React.FC<addMemberToRoleProps> = ({
  currentRoleIdState,
  currentMemberTypeState,
  roleTitleAndDescriptions,
  setRoleAction,
  addNewMemberToRole,
  users,
  groups,
  form,
}) => {
  const { getFieldDecorator } = form;

  const [, setCurrentRoleId] = currentRoleIdState;
  const [currentMemberType, setCurrentMemberType] = currentMemberTypeState;
  const createMemberRole = validateForm<MemberToRoleProps>(
    form,
    addNewMemberToRole,
    true,
    ['memberId'],
  );
  const fuseOptions: FuseOptions<DataSourceItemObject> = {
    shouldSort: true,
    threshold: 0.5,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['text', 'value'],
  };
  const searchableRoles: Fuse<
    DataSourceItemObject,
    FuseOptions<DataSourceItemObject>
  > = new Fuse(roleTitleAndDescriptions, fuseOptions);
  const [searchRoles, setSearchRoles] = useState<string | null>(null);
  const filteredRoles: DataSourceItemObject[] = searchRoles
    ? (searchableRoles.search(searchRoles) as DataSourceItemObject[])
    : roleTitleAndDescriptions;
  return (
    <Form
      onSubmit={e => {
        setRoleAction(true);
        return createMemberRole(e);
      }}
    >
      <Item style={{ width: '100%' }} label="Roles">
        {getFieldDecorator('roleId', {
          rules: [
            {
              required: true,
              message: 'Role is required.',
            },
          ],
        })(
          <Select
            showSearch
            filterOption={(v, o) => {
              const title: string = o?.label?.toString() || '';
              return title.toLowerCase().includes(v.toLowerCase());
            }}
            placeholder={'Please select a role'}
            onSearch={setSearchRoles}
            style={{ width: '70vw', paddingRight: 5 }}
            defaultActiveFirstOption={false}
            onSelect={value => setCurrentRoleId(Number(value))}
            options={map(filteredRoles, r => {
              return { value: r.value, label: r.text };
            })}
          />,
        )}
      </Item>
      <div style={{ display: 'flex' }}>
        <Item style={{ flexFlow: 'row nowrap' }} label="Member Type">
          {getFieldDecorator('memberType', {
            rules: [
              {
                required: true,
                message: 'Member Type is required.',
              },
            ],
          })(
            <Select
              onChange={(memberType: MemberType) =>
                setCurrentMemberType(memberType)
              }
              style={{ paddingRight: 5, width: 150 }}
            >
              <Option value="groups">Group</Option>
              <Option value="users">User</Option>
            </Select>,
          )}
        </Item>
        <Item
          style={{ flexFlow: 'row nowrap', paddingLeft: 15 }}
          label="Member Name"
        >
          {getFieldDecorator('memberId', {
            rules: [
              {
                required: true,
                message: 'User or Group is required.',
              },
            ],
          })(
            <Select
              showArrow={false}
              showSearch
              style={{ width: 200, paddingRight: 5 }}
              placeholder="Select a member"
              optionFilterProp="children"
              filterOption={(input, option) => {
                const children = option?.children || '';
                return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {currentMemberType === 'users'
                ? users.map((user: User) => (
                    <Option key={user.userId} value={user.userId}>
                      {user.username}
                    </Option>
                  ))
                : groups.map((group: Group) => (
                    <Option key={group.groupId} value={group.groupId}>
                      {group.groupName}
                    </Option>
                  ))}
            </Select>,
          )}

          <PrimaryButton style={{ borderRadius: 8 }} htmlType="submit">
            Add
          </PrimaryButton>
        </Item>
      </div>
    </Form>
  );
};
export const AddMemberToRoleComponent = Form.create<any>()(AddMemberToRoleForm);
