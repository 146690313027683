import { combineEpics } from 'redux-observable';
import {
  getAllGroupAccountsEpic,
  groupsEpic,
  saveGroupSimpleRolesEpic,
} from './accounts/epics/groups.epics';
import {
  createUserAccountEpic,
  getAuthenticatedUserAccountEpic,
  getUserAccountEpic,
  refreshActiveUserEpic,
  saveUserSimpleRolesEpic,
} from './accounts/epics/users.epics';
import { usersEpic } from './accounts/epics/users.epics';
import { fetchSaveEventReviewEpic } from './annotation-review/epics/annotation-review.epic';
import {
  addReviewedAnnotationDocumentStatusEpic,
  getAnnotationDocumentStatusesEpic,
  removeReviewedAnnotationDocumentStatusEpic,
  saveAnnotationDocumentStatusesEpic,
  setReviewedFromDocumentStatusEpic,
} from './annotations/epics/annotation-document-statuses.epics';
import {
  fetchAnnotationPagesEpic,
  fetchAnnotationPagesOnSourceDocumentUpdate,
} from './annotations/epics/annotation-pages.epics';
import {
  decrementPageEpic,
  incrementPageEpic,
  toggleSyncViewsEpic,
} from './documents/epics/page-controls.epics';
import {
  createSchemaEpic,
  deleteSchemaEpic,
  fetchAnnotationSchemasEpic,
  setActiveAnnotationSchemaEpic,
  setSchemaForDocumentSetEpic,
  updateSchemaEpic,
  updateSchemaTypesEpic,
  validateFunctionalSchemaEpic,
} from './annotations/epics/annotation-schemas.epics';
import {
  annotationsEpic,
  copySelectedAnnotationsEpic,
  createAnnotationEpic,
  createAnnotationFromSelectionEpic,
  createAnnotationFromTypeEpic,
  createAnnotationRelationEpic,
  deleteAnnotationChildRelationsEpic,
  deleteSelectedAnnotationsEpic,
  fetchAnnotationsOnSourceDocumentUpdate,
  pasteAnnotationsEpic,
  saveAnnotationsBulkEpic,
  saveAnnotationsEpic,
  setDragTargetIdEpic,
  triggerDeleteAnnotationsEpic,
  updateAnnotationEpic,
  updateAnnotationTypesEpic,
  updateAnnotationValuesEpic,
  validateAnnotationsEpic,
} from './annotations/epics/annotations.epics';
import { getAvailableDocumentStatusesEpic } from './annotations/epics/available-document-statuses.epics';
import {
  saveButtonClickedEpic,
  scrollAnnotationIntoViewEpic,
} from './annotations/epics/ui-annotations.epics';
import { authEpic } from './auth/epics/auth.epics';
import {
  calculateFeatureFlagsEpic,
  runInitActionsAfterAppInit,
  runInitActionsAfterAuthSuccess,
} from './common/epics/app-init.epics';
import {
  downloadDocByIdEpic,
  downloadEpic,
} from './common/epics/download.epics';
import {
  displayErrorNotificationEpic,
  displaySuccessNotificationEpic,
} from './common/epics/notifications.epics';
import { AppEpic } from './common/types/app-epic.type';
import { batchUntilIdleEpic } from './common/epics/util.epics';
import {
  createDocumentSetEpic,
  deleteEmptyDocumentSetEpic,
  fetchDocumentSetUploadPolicyEpic,
  fetchDocumentSetsEpic,
} from './document-sets/epics/document-sets.epics';
import { documentListEpic } from './documents/epics/document-list.epics';
import {
  deleteDocumentEpic,
  fetchAndSetSourceDocumentEpic,
  fetchSourceDocumentFromUrlEpic,
  getSourceUrlEpic,
  lockNewSourceDocumentEpic,
  lockSourceDocumentEpic,
  openDocumentEpic,
  refreshDocumentLockEpic,
  refreshSourceDocument,
  updateDocumentMetadataEpic,
  upgradeDocumentLockEpic,
} from './documents/epics/source-document.epics';
import {
  fetchPredictionModelsEpic,
  getPredictionLabels,
  pollUserSpecificPredictionJob,
} from './predictions/epics/predictions.epics';
import {
  appendPredictionsEpic,
  requestPredictionsEpic,
  runLookupPredictionsEpic,
  runPredictionsEpic,
} from './predictions/epics/request-predictions.epics';
import {
  addQueueItemsEpic,
  addQueueItemsEpicBulk,
} from './queues/epics/add-queue-items.epic';
import { completeQueueItemEpic } from './queues/epics/complete-queue-item.epic';
import { createQueueEpic } from './queues/epics/create-queue.epic';
import { deleteQueueEpic } from './queues/epics/delete-queue.epic';
import { getNextQueueItemEpic } from './queues/epics/get-next-queue-item.epic';
import { loadQueuesEpic } from './queues/epics/load-queues.epic';
import {
  addMemberToRoleEpic,
  deleteMemberFromRoleEpic,
  loadRolesEpic,
} from './roles/epics/roles.epic';
import {
  downloadExportEpic,
  refreshExportJobEpic,
  refreshExportProgressEpic,
  setExternalJobIdEpic,
  submitExportJobEpic,
} from './search/epics/export.epic';
import { searchEpic } from './search/epics/search.epic';
import {
  addTagSuccessEpic,
  addTagToDocumentsBulkEpic,
  addTagToDocumentsEpic,
  fetchGroupScopedTagsEpic,
  refreshTagsOnAddSuccess,
  removeTagFromDocumentsBulkEpic,
  removeTagFromDocumentsEpic,
  removeTagSuccessEpic,
} from './tags/epics/group-scoped-tags.epic';
import { applicationEventLoggingEpic } from './usage/epics/application-event-logging.epics';
import {
  loadDocumentSummaryEndEpic,
  loadDocumentSummaryStartEpic,
} from './usage/epics/document-summary-load.epics';
import {
  addLookupAnnotationIdsEpic,
  addLookupValuesEpic,
  clearLookupEpic,
  createLookupEpic,
  deleteLookupEpic,
  fetchLookupValuesEpic,
  fetchLookupsEpic,
  fetchNullKeyLookupValuesEpic,
  setLookupValuesEpic,
  updateLookupEpic,
} from './domain/epics/lookup-context.epics';

export const rootEpic: AppEpic = combineEpics<AppEpic>(
  addReviewedAnnotationDocumentStatusEpic,
  addTagSuccessEpic,
  addTagToDocumentsBulkEpic,
  addTagToDocumentsEpic,
  annotationsEpic,
  applicationEventLoggingEpic,
  authEpic,
  completeQueueItemEpic,
  addQueueItemsEpic,
  addQueueItemsEpicBulk,
  calculateFeatureFlagsEpic,
  createDocumentSetEpic,
  createQueueEpic,
  deleteEmptyDocumentSetEpic,
  deleteQueueEpic,
  deleteSelectedAnnotationsEpic,
  pasteAnnotationsEpic,
  copySelectedAnnotationsEpic,
  displayErrorNotificationEpic,
  displaySuccessNotificationEpic,
  documentListEpic,
  downloadEpic,
  downloadDocByIdEpic,
  fetchAndSetSourceDocumentEpic,
  fetchAnnotationPagesEpic,
  fetchAnnotationPagesOnSourceDocumentUpdate,
  fetchAnnotationsOnSourceDocumentUpdate,
  fetchAnnotationSchemasEpic,
  fetchDocumentSetUploadPolicyEpic,
  validateFunctionalSchemaEpic,
  fetchDocumentSetsEpic,
  fetchGroupScopedTagsEpic,
  fetchSaveEventReviewEpic,
  // Temporary: Disables batch predictions
  // fetchSavedPredictionsEpic,
  // fetchSavedPredictionsOnSourceDocumentUpdate,
  fetchSourceDocumentFromUrlEpic,
  getAllGroupAccountsEpic,
  getAnnotationDocumentStatusesEpic,
  getAuthenticatedUserAccountEpic,
  getAuthenticatedUserAccountEpic,
  getAvailableDocumentStatusesEpic,
  getNextQueueItemEpic,
  getPredictionLabels,
  getSourceUrlEpic,
  getUserAccountEpic,
  groupsEpic,
  loadDocumentSummaryEndEpic,
  loadDocumentSummaryStartEpic,
  loadQueuesEpic,
  loadRolesEpic,
  addMemberToRoleEpic,
  deleteMemberFromRoleEpic,
  lockNewSourceDocumentEpic,
  lockSourceDocumentEpic,
  openDocumentEpic,
  pollUserSpecificPredictionJob,
  refreshDocumentLockEpic,
  refreshSourceDocument,
  refreshTagsOnAddSuccess,
  removeReviewedAnnotationDocumentStatusEpic,
  removeTagSuccessEpic,
  removeTagFromDocumentsEpic,
  removeTagFromDocumentsBulkEpic,
  requestPredictionsEpic,
  runPredictionsEpic,
  runLookupPredictionsEpic,
  runInitActionsAfterAppInit,
  runInitActionsAfterAuthSuccess,
  saveAnnotationDocumentStatusesEpic,
  saveAnnotationsEpic,
  saveAnnotationsBulkEpic,
  saveButtonClickedEpic,
  saveGroupSimpleRolesEpic,
  saveUserSimpleRolesEpic,
  scrollAnnotationIntoViewEpic,
  setReviewedFromDocumentStatusEpic,
  upgradeDocumentLockEpic,
  searchEpic,
  updateDocumentMetadataEpic,
  deleteDocumentEpic,
  usersEpic,
  createUserAccountEpic,
  submitExportJobEpic,
  setExternalJobIdEpic,
  refreshExportJobEpic,
  refreshExportProgressEpic,
  downloadExportEpic,
  fetchPredictionModelsEpic,
  addLookupValuesEpic,
  addLookupAnnotationIdsEpic,
  setLookupValuesEpic,
  fetchLookupsEpic,
  fetchLookupValuesEpic,
  fetchNullKeyLookupValuesEpic,
  clearLookupEpic,
  createLookupEpic,
  updateLookupEpic,
  deleteLookupEpic,
  createAnnotationRelationEpic,
  deleteAnnotationChildRelationsEpic,
  triggerDeleteAnnotationsEpic,
  createAnnotationFromSelectionEpic,
  createAnnotationFromTypeEpic,
  createAnnotationEpic,
  updateAnnotationEpic,
  updateAnnotationTypesEpic,
  updateAnnotationValuesEpic,
  validateAnnotationsEpic,
  appendPredictionsEpic,
  createSchemaEpic,
  deleteSchemaEpic,
  updateSchemaEpic,
  updateSchemaTypesEpic,
  setActiveAnnotationSchemaEpic,
  setSchemaForDocumentSetEpic,
  refreshActiveUserEpic,
  batchUntilIdleEpic,
  incrementPageEpic,
  decrementPageEpic,
  toggleSyncViewsEpic,
  setDragTargetIdEpic,
);
