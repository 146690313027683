import { ICellRendererParams } from 'ag-grid-community';
import React, { useState } from 'react';
import styled from 'styled-components';
import * as Colors from '../../common/colors';
import { AnnotationTableRow } from '../models/annotation-table-row.model';
import {
  CheckCircleTwoTone,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  ExclamationCircleTwoTone,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { AppState } from '../../app-state';
import {
  selectAnnotationValidations,
  selectAnnotationValues,
} from '../selectors/annotation.selectors';

const ShowMoreToggle = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  color: ${Colors.AnchorColor};
  text-decoration: underline;
  cursor: pointer;
  padding: 0 6px;
  background-color: rgba(236, 240, 241, 0.9);
  padding-top: 4px;

  &:hover {
    color: ${Colors.ActiveLinkColor};
  }
`;

const ShowLessToggle = styled(ShowMoreToggle)``;

const RightIcon = styled(DoubleRightOutlined)`
  margin-left: 4px;
`;
const LeftIcon = styled(DoubleLeftOutlined)`
  margin-right: 4px;
`;

export type ValueCellProps = {
  expandCell: (id: string) => void;
  unexpandCell: (id: string) => void;
  annotationValue: string;
  annotationValidation: boolean | undefined;
} & ICellRendererParams;

export const ValueCell = React.memo((props: ValueCellProps) => {
  const rowData = props.data as AnnotationTableRow;
  const atnId = rowData.annotationId;
  const annotationValue = props.annotationValue;
  const annotationIsValid = props.annotationValidation;
  const initExpansion = props.node.rowHeight > 30;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isExpanded, setIsExpanded] = useState(initExpansion);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [mouseOver, setMouseOver] = useState(false);

  const width = props.eGridCell.offsetWidth;
  const lines = AnnotationTableRow.valueCellLineCount(rowData, width);

  const expandedStyle = {
    whiteSpace: 'normal' as const,
    lineHeight: '18px',
    paddingTop: '4px',
  };

  const unexpandedStyle = {
    whiteSpace: 'nowrap' as const,
    overflow: 'hidden' as const,
    textOverflow: 'ellipsis' as const,
    lineHeight: '18px',
    paddingTop: '4px',
  };

  const onShowMoreClick = () => {
    setIsExpanded(true);
    props.expandCell(atnId);
  };

  const onShowLessClick = () => {
    setIsExpanded(false);
    props.unexpandCell(atnId);
  };

  const validationIcon = () => {
    switch (annotationIsValid) {
      case true:
        return <CheckCircleTwoTone twoToneColor={Colors.LightGreen} />;
      case false:
        return <ExclamationCircleTwoTone twoToneColor={Colors.LightRed} />;
      default:
        return <QuestionCircleOutlined />;
    }
  };
  return (
    <div
      style={isExpanded ? expandedStyle : unexpandedStyle}
      onMouseOver={() => setMouseOver(true)}
      onMouseOut={() => setMouseOver(false)}
    >
      {validationIcon()} {annotationValue}
      {lines > 1 && !isExpanded && (
        <ShowMoreToggle
          style={{ display: mouseOver ? 'inherit' : 'none' }}
          onClick={onShowMoreClick}
        >
          Show More
          <RightIcon />
        </ShowMoreToggle>
      )}
      {lines > 1 && isExpanded && (
        <ShowLessToggle
          style={{ display: mouseOver ? 'inherit' : 'none' }}
          onClick={onShowLessClick}
        >
          <LeftIcon />
          Show Less
        </ShowLessToggle>
      )}
    </div>
  );
});

export default connect(
  (state: AppState, ownProps: ICellRendererParams) => ({
    annotationValue:
      selectAnnotationValues(state).get(ownProps.data.annotationId) || '',
    annotationValidation: selectAnnotationValidations(state).get(
      ownProps?.data?.annotationId || '',
    ),
  }),
  dispatch => ({}),
)(ValueCell);
