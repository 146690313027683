import { Tooltip } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { IconButton } from '../../common/components/icon-button.component';
import { ShortcutKeys as UndoRedoShortcuts } from '../shortcut-keys';

const XYFlippedIcon = styled(IconButton)`
  i {
    -moz-transform: scaleX(-1) scaleY(-1);
    -o-transform: scaleX(-1) scaleY(-1);
    -webkit-transform: scaleX(-1) scaleY(-1);
    transform: scaleX(-1) scaleY(-1);
  }
`;

const YFlippedIcon = styled(IconButton)`
  i {
    -moz-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
  }
`;

const ButtonWrapper = styled.span`
  a.ant-btn,
  button.ant-btn {
    height: 50px;
    font-size: 22px;
    font-weight: bold;
    padding: 8px 10px;
  }
`;

export type UndoRedoButtonsProps = {
  undoEnabled: boolean;
  redoEnabled: boolean;
  undoAction: () => void;
  redoAction: () => void;
};

export const UndoRedoButtonsComponent = (props: UndoRedoButtonsProps) => {
  const { undoAction, redoAction, undoEnabled, redoEnabled } = props;

  const undoButton = (
    <ButtonWrapper>
      <YFlippedIcon
        iconType="enter"
        onClick={() => undoAction()}
        disabled={!undoEnabled}
      />
    </ButtonWrapper>
  );

  const redoButton = (
    <ButtonWrapper>
      <XYFlippedIcon
        iconType="enter"
        onClick={() => redoAction()}
        disabled={!redoEnabled}
      />
    </ButtonWrapper>
  );

  return (
    <>
      {undoEnabled ? (
        <Tooltip title={`Undo (${UndoRedoShortcuts.Undo[0]})`}>
          {undoButton}
        </Tooltip>
      ) : (
        undoButton
      )}
      {redoEnabled ? (
        <Tooltip title={`Redo (${UndoRedoShortcuts.Redo[0]})`}>
          {redoButton}
        </Tooltip>
      ) : (
        redoButton
      )}
    </>
  );
};
