import { mapValues, reduce, sortBy, startCase, values } from 'lodash';
import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { AppState } from '../../app-state';
import { authHeaders } from '../../common/utils/fetch';
import { config } from '../../config/application.config';
import {
  LabelCategory,
  PREDICTION_LABEL_CATEGORIES,
  PREDICTION_LABEL_DISPLAYS,
} from '../config/labels-display';
import { KeyValueMap } from '../../common/types/KeyValueMap.type';
import { PredictionModel } from '../models/prediction-model.model';

export type RawPredictionLabels = {
  [documentType: string]: string[];
};

export const RawPredictionLabels = {
  get(state: AppState): Observable<{ labels: RawPredictionLabels }> {
    return ajax.getJSON<{ labels: RawPredictionLabels }>(
      `${config.annotationService.url}/prediction-labels`,
      authHeaders(state),
    );
  },
};

export type PredictionLabel = {
  display: string;
  value: string;
};

export type PredictionLabelsCategory = {
  display: string;
  labels: PredictionLabel[];
};

export type PredictionLabels = {
  [documentType: string]: PredictionLabelsCategory[];
};

export const PredictionLabels = {
  fromRawLabels(predictionLabels: RawPredictionLabels): PredictionLabels {
    return mapValues(predictionLabels, labels => {
      const labelsCategories: {
        [category: string]: PredictionLabelsCategory;
      } = reduce(
        labels,
        (cats, label) => {
          const predictionLabel: PredictionLabel = {
            display: PREDICTION_LABEL_DISPLAYS[label] || startCase(label),
            value: label,
          };
          const category: LabelCategory =
            PREDICTION_LABEL_CATEGORIES[label] || 'Miscellaneous';
          const contents: PredictionLabelsCategory = cats[category] || {
            display: category,
            labels: [],
          };

          return {
            ...cats,
            [category]: {
              ...contents,
              labels: sortBy(contents.labels.concat([predictionLabel]), [
                l => l.display,
              ]),
            },
          };
        },
        {},
      );

      return sortBy(values(labelsCategories), [cr => cr.display]);
    });
  },
};

export type PredictionsStatus = 'ready' | 'running';

export type PredictionsState = {
  availableLabels: PredictionLabels;
  currentlySelectedLabelGroup: string;
  labelsToPredictOn: RawPredictionLabels;
  predictionsStatus: PredictionsStatus;
  predictionModels: KeyValueMap<PredictionModel>;
};

export type Prediction = {
  predictionId: number | undefined;
  uuid: number;
  userSpecific: boolean;
  documentTextId: number;
  label: string;
  startOffset: number;
  endOffset: number;
  nativeText: string;
  value: string;
  requestedAt: string;
  createdAt: string;
  updatedAt: string;
  annotationId: string | null;
  parentAnnotationId: string | null;
};

export type Predictions = Prediction[];
