import { startsWith } from 'lodash';
import React from 'react';
import { RouteComponentProps, match } from 'react-router';
import { withSize } from 'react-sizeme';
import SplitPane from 'react-split-pane';
import styled from 'styled-components';
import { AnnotationPopover } from '../../annotations/containers/annotation-popover.container';
import { AnnotationToolbar } from '../../annotations/containers/annotation-toolbar.container';
import { AnnotationPageRouteParams } from '../../app-routes';
import { AnnotationTablePanel } from './annotation-table-panel.component';
import { AnnotationViewerPanel } from './annotation-viewer-panel.component';
import { PdfViewerPanel } from './pdf-viewer-panel.component';

const PopoverAnchor = styled.div`
  position: sticky;
  top: 0;
  height: 1px;
  width: 1px;
  margin-left: 0px;
`;

const SizedSplitPane = styled.div`
  overflow: hidden;

  .SplitPane {
    height: calc(100% - 100px) !important;
  }

  .SplitPane.annotation-toolbar-hidden {
    height: calc(100% - 48px) !important;
  }

  .Pane {
    overflow: hidden;
    min-height: 1px;
    min-width: 1px;
  }

  .Resizer {
    background: #000;
    opacity: 0.2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
  }

  .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }

  .Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
  }

  .Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  }

  .Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
  }

  .Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
  }
  .Resizer.disabled {
    cursor: not-allowed;
  }
  .Resizer.disabled:hover {
    border-color: transparent;
  }
`;

const MeasuredPdfViewerPanel = withSize()(PdfViewerPanel);

export type AppPanesDispatchProps = {
  onPaneSizeChange: (id: string, size: number) => unknown;
  fetchSourceDocumentFromUrl: (
    match: match<AnnotationPageRouteParams>,
  ) => unknown;
  closeDocument: () => unknown;
};

export type AppPanesProps = {
  pdfPaneWidth: number;
  showAnnotationToolbar: boolean;
  match: match<AnnotationPageRouteParams>;
} & AppPanesDispatchProps &
  RouteComponentProps;

export class AppPanes extends React.PureComponent<AppPanesProps> {
  constructor(props: AppPanesProps) {
    super(props);

    if (startsWith(props.match.url, '/document')) {
      props.fetchSourceDocumentFromUrl(props.match);
    }
  }

  componentWillReceiveProps(nextProps: AppPanesProps) {
    // route has changed, need to prompt for document reloading
    if (nextProps.match.params !== this.props.match.params) {
      this.props.fetchSourceDocumentFromUrl(nextProps.match);
    }
  }

  componentWillUnmount() {
    this.props.closeDocument();
  }

  render() {
    return (
      <>
        {this.props.showAnnotationToolbar && <AnnotationToolbar />}
        <SizedSplitPane>
          <SplitPane
            className={
              this.props.showAnnotationToolbar
                ? ''
                : 'annotation-toolbar-hidden'
            }
            split="vertical"
            minSize={200}
            defaultSize={this.props.pdfPaneWidth}
            onChange={(size: number) => this.props.onPaneSizeChange('0', size)}
          >
            <MeasuredPdfViewerPanel />
            <div>
              <AnnotationPopover>
                <PopoverAnchor />
              </AnnotationPopover>
              <AnnotationPopover>
                <PopoverAnchor />
              </AnnotationPopover>
              <SplitPane
                split="horizontal"
                minSize={200}
                defaultSize={360}
                primary="second"
              >
                <AnnotationViewerPanel />
                <AnnotationTablePanel />
              </SplitPane>
            </div>
          </SplitPane>
        </SizedSplitPane>
      </>
    );
  }
}
