import { filter, find, reduce, some } from 'lodash';
import { getType } from 'typesafe-actions';
import uuid from 'uuid/v4';
import { AppState } from '../../app-state';
import { AuthActions } from '../../auth/actions/auth.types';
import { KeyValueMap } from '../../common/types/KeyValueMap.type';
import {
  closeDocument,
  setSourceDocument,
} from '../../documents/actions/source-document.actions';
import { SourceDocumentActions } from '../../documents/actions/source-document.types';
import {
  RELATION_TYPES,
  RelationType,
} from '../../lib/relations/relation-types';
import { Relation } from '../../lib/relations/relation.model';
import {
  appendAnnotationRelations,
  createAnnotationRelation,
  deleteAnnotationChildRelations,
  deleteAnnotationRelation,
  replaceAnnotationRelations,
} from '../actions/annotation-relations.actions';
import { AnnotationRelationsActions } from '../actions/annotation-relations.types';
import {
  copyAnnotations,
  deleteAnnotation,
  deleteAnnotations,
  fetchAnnotations,
} from '../actions/annotations.actions';
import { AnnotationsActions } from '../actions/annotations.types';
import { AnnotationRelation } from '../models/annotation-relation.model';
import { Annotation } from '../models/annotation.model';
import { Map } from 'immutable';
import { selectAsRelation } from '../selectors/annotation-relation.selectors';

export type AnnotationRelationsState = {
  entities: Map<string, AnnotationRelation>;
  clipboard: Map<string, AnnotationRelation>;
};

export const defaultState = {
  entities: Map<string, AnnotationRelation>(),
  clipboard: Map<string, AnnotationRelation>(),
};

export function annotationRelationsReducer(
  state: AnnotationRelationsState = defaultState,
  action:
    | AnnotationRelationsActions
    | AnnotationsActions
    | AuthActions
    | SourceDocumentActions,
  appState: AppState,
): AnnotationRelationsState {
  switch (action.type) {
    case getType(fetchAnnotations): {
      return {
        ...state,
        entities: Map(),
      };
    }

    case getType(createAnnotationRelation): {
      const newId = uuid();
      const { left, right, relationType } = action.payload;
      const baseRelation = selectAsRelation(appState)(
        left,
        right,
        relationType,
      );
      if (!baseRelation) return state;
      const relation = {
        id: newId,
        ...baseRelation,
      };
      if (relationExists(relation, state.entities)) {
        return state;
      }

      // doesn't make sense to have a relationship with yourself
      // giggity.
      if (AnnotationRelation.isSelfReferencing(relation)) {
        return state;
      }

      // If moving a child, remove existing child relationships.
      const stateWithoutOldChildRelations =
        relation.rule.type === RELATION_TYPES.CHILD_OF
          ? deleteLeftRelationTypes(
              relation.left.id,
              RELATION_TYPES.CHILD_OF,
              state,
            )
          : state;

      // need to kill the problematic relations to allow the new one to be created if we have a cycle.
      const stateWithoutAllOldRelations = AnnotationRelation.isCyclical(
        relation,
        state.entities,
      )
        ? deleteRightRelationTypes(
            relation.left.id,
            RELATION_TYPES.CHILD_OF,
            stateWithoutOldChildRelations,
          )
        : stateWithoutOldChildRelations;

      const newRelations = Map(
        Array.from(stateWithoutAllOldRelations.entities.entries()).concat([
          [newId, relation],
        ]),
      );

      return {
        ...stateWithoutAllOldRelations,
        entities: newRelations,
      };
    }

    case getType(deleteAnnotation): {
      const annotationId = action.payload.annotationId;
      const currentRels = Array.from(state.entities.values());
      const delRelations = filter(currentRels, r => {
        return r.left.id === annotationId || r.right.id === annotationId;
      });
      const delRelIds = delRelations.map(r => r.id);
      const newRelations = currentRels.filter(
        r => !some(delRelIds, i => i === r.id),
      );

      return {
        ...state,
        entities: KeyValueMap.usingKey(newRelations, 'id'),
      };
    }

    case getType(deleteAnnotations): {
      const annotationIds = action.payload.annotationIds;
      const currentRels = Array.from(state.entities.values());
      const delRelations = filter(currentRels, r => {
        return (
          annotationIds.indexOf(r.left.id) >= 0 ||
          annotationIds.indexOf(r.right.id) >= 0
        );
      });
      const delRelIds = delRelations.map(r => r.id);
      const newRelations = currentRels.filter(
        r => !some(delRelIds, i => i === r.id),
      );

      return {
        ...state,
        entities: KeyValueMap.usingKey(newRelations, 'id'),
      };
    }

    case getType(deleteAnnotationRelation): {
      return deleteRelation(action.payload.id, state);
    }

    case getType(deleteAnnotationChildRelations): {
      return deleteLeftRelations(action.payload.annotationId, state);
    }

    case getType(appendAnnotationRelations): {
      const newRelations = Array.from(action.payload.relations.entries());
      const oldRelations = Array.from(state.entities.entries());
      const relations = Map(oldRelations.concat(newRelations));
      return {
        ...state,
        entities: relations,
      };
    }

    case getType(replaceAnnotationRelations): {
      return {
        ...state,
        entities: action.payload.relations,
      };
    }

    case getType(closeDocument):
    case getType(setSourceDocument):
      return { ...defaultState, clipboard: state.clipboard };

    case getType(copyAnnotations): {
      const annotationIds = action.payload.annotationIds;
      const currentRels = Array.from(state.entities.values());
      const relations = currentRels.filter((r, i) => {
        const leftExists = annotationIds.indexOf(r.left.id) > -1;
        const rightExists = annotationIds.indexOf(r.right.id) > -1;
        const exists = leftExists && rightExists;
        return exists;
      });
      const relationsMap = KeyValueMap.usingKey(relations, 'id');
      return {
        ...state,
        clipboard: relationsMap,
      };
    }

    default:
      return state;
  }
}

/**
 * Does the passed relationship already exist between 2 annotations?
 * @param relation
 * @param relations Existing relationships.
 */
function relationExists(
  relation: Relation<string, Annotation>,
  relations: Map<string, AnnotationRelation>,
): boolean {
  const currentRels = Array.from(relations.values());
  return !!find(currentRels, r1 => {
    return (
      r1.left.id === relation.left.id &&
      r1.right.id === relation.right.id &&
      r1.rule.type === relation.rule.type
    );
  });
}

/**
 * Find a relation type that an annotation
 * is the left-hand side of.
 * Use-case example: checking to see if a annotation
 * is already a child of another annotation.
 * @param annotation
 * @param type
 * @param relations
 */
function findLeftRelations(
  annotationId: string,
  type: RelationType,
  relations: Map<string, AnnotationRelation>,
): AnnotationRelation[] {
  return filter(
    Array.from(relations.values()),
    r => r.left.id === annotationId && r.rule.type === type,
  );
}

/**
 * Find a relation type that an annotation
 * is the right-hand side of.
 * Use-case example: checking to see if a annotation
 * is already a parent of another annotation.
 * @param annotation
 * @param type
 * @param relations
 */
function findRightRelations(
  annotationId: string,
  type: RelationType,
  relations: Map<string, AnnotationRelation>,
): AnnotationRelation[] {
  return filter(
    Array.from(relations.values()),
    r => r.right.id === annotationId && r.rule.type === type,
  );
}

/**
 * Deletes all left-hand relations for an annotation.
 * @param annotationId
 * @param state
 */
function deleteLeftRelations(
  annotationId: string,
  state: AnnotationRelationsState,
): AnnotationRelationsState {
  const currentRels = Array.from(state.entities.values());
  const relations = filter(currentRels, r => r.left.id === annotationId);
  return reduce(relations, (rState, r) => deleteRelation(r.id, rState), state);
}

/**
 * Deletes all left-hand relations for an annotation of a specific type.
 * @param annotationId
 * @param type
 * @param state
 */
function deleteLeftRelationTypes(
  annotationId: string,
  type: RelationType,
  state: AnnotationRelationsState,
): AnnotationRelationsState {
  const relations = findLeftRelations(annotationId, type, state.entities);

  // Delete all relations from state.
  return reduce(relations, (rState, r) => deleteRelation(r.id, rState), state);
}

/**
 * Deletes all right-hand relations for an annotation of a specific type.
 * @param annotationId
 * @param type
 * @param state
 */
function deleteRightRelationTypes(
  annotationId: string,
  type: RelationType,
  state: AnnotationRelationsState,
): AnnotationRelationsState {
  const relations = findRightRelations(annotationId, type, state.entities);

  // Delete all relations from state.
  return reduce(relations, (rState, r) => deleteRelation(r.id, rState), state);
}

/**
 * Deletes a relation from passed state & returns new state.
 * @param id
 * @param state
 */
function deleteRelation(
  id: string | number,
  state: AnnotationRelationsState,
): AnnotationRelationsState {
  const relations = Array.from(state.entities.values()).filter(
    a => a.id !== id,
  );
  return {
    ...state,
    entities: KeyValueMap.usingKey(relations, 'id'),
  };
}
