import { Button, Input, Popconfirm, Select, Table, Tooltip } from 'antd';
import React from 'react';
import { AnnotationSchema } from '../../annotations/models/annotation-schema.model';
import { AnnotationType } from '../../annotations/models/annotation-type.model';
import { ColorBox } from '../../common/components/color-box.component';
import { map, some, sortBy } from 'lodash';
import { tableSearchProps } from '../../common/utils/tableSearchProps';
import styled from 'styled-components';
import { isEqual } from 'lodash/fp';
import { AnnotationTypePayload } from '../../annotations/resources/annotation-schema.resource';
import { AnnotationTypeCreatorComponent } from './annotation-type-creator.component';
import { CirclePicker } from 'react-color';
import { PickerColors } from '../../common/colors';
import { DeleteOutlined } from '@ant-design/icons';
import {
  PermissionAction,
  PermissionActions,
} from '../../accounts/models/permission.model';
import { DomainLookup } from '../../domain/models/lookup-context.model';

export type AnnotationTypeTableDispatchProps = {
  updateSchemaTypes: (
    schemaId: number,
    types: AnnotationTypePayload[],
  ) => unknown;
  isPermitted: (schemaId: number, action: PermissionAction) => boolean;
};
export type AnnotationTypeTableProps = {
  schema: AnnotationSchema;
} & AnnotationTypeTableDispatchProps;

type EditingKey = {
  rowKey: number;
  columnKey: string;
};

const AnnotationTypeDiv = styled.div`
  overflow: auto;
  max-height: calc(100vh - 76px);
  width: 100%;
  margin-top: 1em;
`;

const AnyType = AnnotationType.WildcardAnyKey;

export const AnnotationTypeTable = (props: AnnotationTypeTableProps) => {
  const schema = props.schema;
  const schemaId = schema.annotationSchemaId;
  const annotationTypes = schema.annotationTypes;
  const categories = schema.categories;
  const rules = schema.relationRules;
  const originalRows = sortBy(
    annotationTypes.map(t => toRow(t)),
    r => `${r.category?.toLowerCase()} ${r.key.toLowerCase()}`,
  );
  const [rows, setRows] = React.useState(originalRows);
  const [changed, setChanged] = React.useState(false);
  const [editingKey, setEditingKey] = React.useState<EditingKey | null>(null);

  function isPermitted(action: PermissionAction) {
    return props.isPermitted(schemaId, action);
  }

  function createAnnotationType(
    category: string,
    title: string,
    key: string,
    color: string,
    keyboardShortcut?: string,
  ) {
    const existingIds = rows.map(i => i.id);
    const maxId = Math.max(...existingIds);
    const newRow: AnnotationTypePayload = {
      category,
      title,
      key,
      color,
      keyboardShortcut,
      allowedChildKeys: [],
      id: maxId + 1,
      validatorConfigs: [],
      editorConfigs: [],
    };
    setRows(rows.concat([newRow]));
  }

  function deleteAnnotationType(id: number) {
    setRows(rows.filter(r => r.id !== id));
    setChanged(true);
  }

  function toRow(aType: AnnotationType): AnnotationTypePayload {
    const category = categories.find(
      c => c.annotationCategoryId === aType.annotationCategoryId,
    )?.title;

    const childrenRules =
      rules.filter(r => {
        const allParentsAllowed = r.right === AnyType;
        const thisTypeAllowed = r.right === aType.key;
        return allParentsAllowed || thisTypeAllowed;
      }) || [];

    const allChildrenAllowed = some(childrenRules, r => r.left === AnyType);
    const allowedChildren: string[] = childrenRules
      .map(r => annotationTypes.find(t => r.left === t.key)?.key || '')
      .filter(k => !!k)
      .sort();

    const allowedChildKeys = allChildrenAllowed ? [AnyType] : allowedChildren;
    return {
      id: aType.annotationTypeId,
      key: aType.key,
      title: aType.title,
      keyboardShortcut: aType.keyboardShortcut,
      color: aType.color,
      category,
      allowedChildKeys,
      validatorConfigs: aType.validatorConfigs,
      editorConfigs: aType.editorConfigs,
    };
  }
  function compareStrings(s1?: string, s2?: string) {
    return (s1 || '').localeCompare(s2 || '');
  }

  function handleChange(row: AnnotationTypePayload) {
    const newData = [...rows];
    const index = newData.findIndex(item => row.id === item.id);
    const item = newData[index];
    if (!isEqual(row, item)) {
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      setRows(newData);
      setChanged(true);
    }
  }

  function saveChanges(newTypes: AnnotationTypePayload[]) {
    props.updateSchemaTypes(schemaId, newTypes);
    setChanged(false);
  }

  function cancelChanges() {
    setRows(originalRows);
    setChanged(false);
  }

  const columns = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      editable: true,
      sorter: (a, b) => compareStrings(a.category, b.category),
      ...tableSearchProps('category'),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      editable: true,
      sorter: (a, b) => compareStrings(a.title, b.title),
      ...tableSearchProps('title'),
    },
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key',
      editable: true,
      sorter: (a, b) => compareStrings(a.key, b.key),
      ...tableSearchProps('key'),
    },
    {
      title: 'Keyboard Shortcut',
      dataIndex: 'keyboardShortcut',
      key: 'shortcut',
      editable: true,
      sorter: (a, b) => compareStrings(a.keyboardShortcut, b.keyboardShortcut),
      render: shortcut => shortcut || '-',
      ...tableSearchProps('keyboardShortcut'),
    },
    {
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
      editable: true,
      sorter: (a, b) => compareStrings(a.color, b.color),
      render: c => (
        <ColorBox
          style={{
            backgroundColor: c,
            width: '24px',
            minWidth: '24px',
            height: '24px',
          }}
        />
      ),
    },
    {
      title: 'Children',
      dataIndex: 'allowedChildKeys',
      editable: true,
      key: 'children',
      render: children => {
        const containsAll = some(children, c => c === AnyType);
        return containsAll ? AnyType : children.length;
      },
      sorter: (a, b) => a.allowedChildKeys.length - b.allowedChildKeys.length,
    },
    {
      title: 'Validators',
      dataIndex: 'validatorConfigs',
      editable: true,
      key: 'validators',
      render: ls => ls.length,
      sorter: (a, b) => a.validatorConfigs.length - b.validatorConfigs.length,
    },
    {
      title: 'Editors',
      dataIndex: 'editorConfigs',
      editable: true,
      key: 'editors',
      render: ls => ls.length,
      sorter: (a, b) => a.validatorConfigs.length - b.validatorConfigs.length,
    },
    {
      title: 'Actions',
      dataIndex: 'key',
      key: 'action',
      render: (_: any, r: AnnotationTypePayload) => (
        <>
          <Tooltip title="delete" mouseEnterDelay={0.5}>
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => deleteAnnotationType(r.id)}
              disabled={!isPermitted(PermissionActions.Delete)}
            >
              <Button
                disabled={!isPermitted(PermissionActions.Delete)}
                danger={true}
              >
                {' '}
                <DeleteOutlined />{' '}
              </Button>
            </Popconfirm>
          </Tooltip>
        </>
      ),
    },
  ];

  const newColumns = columns.map(col => {
    return {
      ...col,
      onCell: (record: AnnotationTypePayload) => ({
        record,
        editable: col['editable'],
        dataIndex: col.dataIndex,
        title: col.title,
        handleChange: handleChange,
        isPermitted: isPermitted,
        lookups: schema.lookups,
      }),
    };
  });

  const components = {
    body: {
      cell: editableCell(rows, editingKey, setEditingKey),
    },
  };
  return (
    <>
      <AnnotationTypeCreatorComponent
        disabled={!isPermitted(PermissionActions.Modify)}
        onCreate={f => {
          createAnnotationType(
            f.category,
            f.title,
            f.key,
            f.color || '#FFFFFF',
            f.keyboardShortcut,
          );
          setChanged(true);
        }}
      />
      <AnnotationTypeDiv>
        <Table
          components={components}
          columns={newColumns}
          dataSource={rows}
          pagination={{ position: ['bottomRight'] }}
          rowKey={(p: AnnotationTypePayload) => p.id}
        />
        {changed && (
          <ButtonRow>
            <Button onClick={() => saveChanges(rows)} type="primary">
              Save Changes
            </Button>
            <Button onClick={() => cancelChanges()} danger={true} ghost={true}>
              Cancel
            </Button>
          </ButtonRow>
        )}
      </AnnotationTypeDiv>
    </>
  );
};

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    text-align: left;
    padding: 0 8px;

    &:not(:last-child) {
    border-bottom: 1px solid #eee;
    }

    &:first-child {
    border-top: 1px solid #eee;
    }

    >* {
    flex: 1 50%;
    padding: 14px;
    }

    ul {
    list-style: none;
    margin-bottom: 0;
    }

    .ant-checkbox + span {
    font-size: 16px;
    }

    .ant-checkbox-wrapper {
    padding: 4px; 0;
    }

    .ant-select-selection {
    border-radius: 0;
    border-color: #eee;
    }
`;

const ButtonRow = styled(Row)`
  padding: 12px 12px 6px;

  > * {
    flex: 1 40%;
    padding: 14px;
  }

  .ant-btn {
    border-radius: 0;
    height: 38px;

    &:first-child {
      margin-right: 12px;
    }
  }
`;

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof AnnotationTypePayload;
  record: AnnotationTypePayload;
  handleChange: (record: AnnotationTypePayload) => void;
  isPermitted: (action: PermissionAction) => boolean;
  lookups: DomainLookup[];
}

function editableCell(
  rows: AnnotationTypePayload[],
  editingKey: EditingKey | null,
  setEditingKey: (k: EditingKey | null) => void,
) {
  return (props: EditableCellProps) => {
    const {
      title,
      editable,
      children,
      dataIndex,
      record,
      handleChange,
      isPermitted,
      lookups,
      ...restProps
    } = props;
    const inputRef = React.useRef<Input>(null);
    const oldValue = dataIndex ? props.record[dataIndex] : undefined;
    const [value, setValue] = React.useState(oldValue);
    const editing =
      record?.id === editingKey?.rowKey && dataIndex === editingKey?.columnKey;
    const setEditing = (b: boolean) => {
      b
        ? setEditingKey({ rowKey: record?.id, columnKey: dataIndex })
        : setEditingKey(null);
    };

    const toggleEdit = () => {
      setEditing(!editing);
    };

    const updateValue = (v: any) => {
      const newRecord = { ...record, [dataIndex]: v };
      handleChange(newRecord);
      setEditingKey(null);
    };

    React.useEffect(() => {
      if (editing) {
        inputRef?.current?.focus();
      }
    }, [editing]);

    const ENTER_KEY = 13;
    const ESC_KEY = 27;

    function getInputNode(ref: React.RefObject<any>, index: string) {
      switch (index) {
        case 'allowedChildKeys': {
          const typeKeys = rows
            .map(r => r.key)
            .sort()
            .filter(k => k !== record.key);
          const typeKeysAndAll = [AnyType].concat(typeKeys);
          const options = map(typeKeysAndAll, k => {
            return { value: k };
          });

          const setAllowedKeys = (values: string[]) => {
            const containsAll = some(values, v => v === AnyType);
            containsAll ? setValue([AnyType]) : setValue(values.sort());
          };
          return (
            <Select
              value={value as string[]}
              style={{ width: '100%' }}
              key="select-1"
              ref={ref}
              showAction={['focus', 'click']}
              onChange={setAllowedKeys}
              onBlur={e => updateValue(value)}
              onInputKeyDown={k => {
                const code = k.which;
                if (code === ESC_KEY || code === ENTER_KEY) {
                  k.stopPropagation();
                  updateValue(value);
                }
              }}
              showSearch
              placeholder="Search to Select"
              mode="multiple"
              options={options}
            />
          );
        }
        case 'editorConfigs':
        case 'validatorConfigs': {
          type LookupConfig = { configType: string; domainLookupId: number };
          const containsKey = (l: DomainLookup) =>
            l.values.indexOf(record.key) > -1;
          const relevantLookups = lookups
            .filter(l => l.lookupType === 'validation')
            .filter(containsKey);
          const allConfigs = relevantLookups.map(l => {
            return {
              configType: 'lookup-select',
              domainLookupId: l.domainLookupId,
            };
          });
          const options = map(relevantLookups, l => {
            return { label: `Lookup: ${l.name}`, value: l.domainLookupId };
          });

          const idValues: number[] = (value as LookupConfig[]).map(
            l => l.domainLookupId,
          );

          const setConfigs = (ids: number[]) => {
            const includes = (i: number) => ids.indexOf(i) > -1;
            const newVals = allConfigs.filter(l => includes(l.domainLookupId));
            setValue(newVals);
          };

          return (
            <Select
              value={idValues}
              style={{ width: '100%' }}
              ref={ref}
              showAction={['focus', 'click']}
              onChange={setConfigs}
              onBlur={e => updateValue(value)}
              onInputKeyDown={k => {
                const code = k.which;
                if (code === ESC_KEY || code === ENTER_KEY) {
                  k.stopPropagation();
                  updateValue(value);
                }
              }}
              showSearch
              placeholder="Search to Select"
              mode="multiple"
              options={options}
            />
          );
        }
        case 'color': {
          return (
            <CirclePicker
              colors={PickerColors}
              circleSize={14}
              circleSpacing={7}
              onChangeComplete={v => updateValue(v.hex)}
            />
          );
        }
        default: {
          return (
            <Input
              key={`${dataIndex}-input`}
              defaultValue={value as string}
              ref={ref}
              onChange={e => setValue(e.currentTarget.value)}
              onBlur={e => updateValue(value)}
              onPressEnter={e => updateValue(value)}
            />
          );
        }
      }
    }

    let childNode = children;
    const canEdit = editable && isPermitted(PermissionActions.Modify);

    if (canEdit) {
      childNode = editing ? (
        getInputNode(inputRef, dataIndex)
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{ paddingRight: 24 }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    } else {
      childNode = <div onClick={() => setEditingKey(null)}>{children}</div>;
    }

    return <td {...restProps}>{childNode}</td>;
  };
}
