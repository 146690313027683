import { RelationCondition } from '../relation-condition.model';
import { RelationViolation } from '../relation-violation.model';
import { Relation } from '../relation.model';
import { validateRule } from './validate';

export function or<U, T>(
  entity: U,
  rule: RelationCondition<T>,
  relations: Relation<T, U>[],
  compare: (entity: U, type: T) => boolean,
): RelationViolation<U, T>[] {
  const leftViolations = validateRule(entity, rule.left, relations, compare);
  const rightViolations = validateRule(entity, rule.right, relations, compare);

  if (leftViolations.length && rightViolations.length) {
    return [
      {
        rule,
        left: entity,
        right: null,
      },
    ];
  }

  return [];
}
