import { Select } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { isEmpty } from 'lodash';
import React from 'react';
const Option = Select.Option;

interface YesNoSelectorProps {
  value?: string;
  onChange: (value: string) => void;
  showAll?: boolean;
  defaultValue?: string;
}
export class YesNoSelector extends React.Component<YesNoSelectorProps> {
  defaultShowAll = true;

  onChange = (value: SelectValue) => {
    if (typeof value === 'string') {
      this.props.onChange(value);
    }
  };

  render() {
    const { showAll, value: val, defaultValue } = this.props;

    const allOption =
      typeof showAll === 'boolean' ? showAll : this.defaultShowAll;

    const selectProps = isEmpty(val)
      ? {
          defaultValue: defaultValue || 'all',
          onChange: this.onChange,
        }
      : {
          value: val,
          onChange: this.onChange,
        };

    return (
      <Select {...selectProps}>
        {allOption && (
          <Option key="all" value="all">
            All
          </Option>
        )}
        <Option key="yes" value="yes">
          Yes
        </Option>
        <Option key="no" value="no">
          No
        </Option>
      </Select>
    );
  }
}
