import { connect } from 'react-redux';
import { AppState } from '../../app-state';
import { deleteQueue, fetchQueues } from '../actions/queue.actions';
import { QueueViewerComponent } from '../components/queue-viewer.component';
import { selectAllQueuesAsArray } from '../selectors';

export const QueueViewer = connect(
  (state: AppState) => ({
    queues: selectAllQueuesAsArray(state),
  }),
  {
    fetchQueues,
    deleteQueue,
  },
)(QueueViewerComponent);
