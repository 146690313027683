import {
  filter,
  findIndex,
  flatMap,
  flatten,
  keyBy,
  map,
  some,
  uniq,
  uniqBy,
  values,
} from 'lodash';
import uuid from 'uuid/v4';
import { KeyValueMap } from '../../common/types/KeyValueMap.type';
import { unexhaustiveError } from '../../common/utils/unexhaustiveError';
import { RelationCondition } from '../../lib/relations/relation-condition.model';
import { RelationRule } from '../../lib/relations/relation-rule.model';
import {
  RELATION_TYPES,
  RelationType,
  RuleOrCondition,
  isCondition,
  isRule,
} from '../../lib/relations/relation-types';
import { RelationViolation } from '../../lib/relations/relation-violation.model';
import { Relation } from '../../lib/relations/relation.model';
import { validate } from '../../lib/relations/validators/validate';
import { AnnotationRelationResource } from '../resources/annotation-relation.resource';
import { AnnotationResource } from '../resources/annotation.resource';
import {
  AnnotationRelationRule,
  AnnotationRelationsRulesByName,
} from './annotation-relation-rule.model';
import { AnnotationTableRow } from './annotation-table-row.model';
import { FlatTree } from './annotation-table-settings.model';
import { AnnotationType } from './annotation-type.model';
import { Annotation } from './annotation.model';
import { SimpleTreeNode } from './simple-tree.model';
import { Map } from 'immutable';

export interface AnnotationRelation extends Relation<string, Annotation> {
  id: string;
}

// TODO: Refactor "SaveAnnotationRelation" into a "NewAnnotationRelationResource" class.
export type SaveAnnotationRelation = {
  relationType: string;
  leftIndex: number;
  rightIndex: number;
};

export const AnnotationRelation = {
  annotationMatchesType(atn: Annotation, type: string): boolean {
    return atn.type === type || atn.type === AnnotationType.WildcardAnyKey;
  },

  /**
   * Returns all rule violations for the passed annotations
   * and the current set of relations.
   * @param entities
   * @param relations
   */
  validate(
    entities: Map<string, Annotation>,
    relations: Map<string, AnnotationRelation>,
    rules: AnnotationRelationRule[],
  ): RelationViolation<Annotation, string>[] {
    const grouped = AnnotationRelation.groupLeft(
      Array.from(relations.values()),
    );

    return validate(
      entities,
      entity => AnnotationRelation.getRulesAndConditions(entity, rules),
      (a: Annotation) => grouped.get(a.id) || [],
      this.annotationMatchesType,
    );
  },

  validateObject(relation: AnnotationRelation): string[] | null {
    const errors: string[] = [];

    if (!relation.id) {
      errors.push('Relation missing id');
    }

    if (!relation.rule || RELATION_TYPES[relation.rule.type]) {
      errors.push('Invalid relation rule.');
    }

    if (Annotation.validateObject(relation.left)) {
      errors.push('Relation left annotation is invalid');
    }

    if (Annotation.validateObject(relation.right)) {
      errors.push('Relation right annotation is invalid');
    }

    return errors.length ? errors : null;
  },

  /**
   * 1) Maps relation resources to AnnotationRelations
   * 2) Logs any erroneous relations.
   * 3) Filters erroroneus relations to prevent them from breaking application.
   * @param resources
   * @param annotations
   */
  mapAndFilterResource(
    resources: AnnotationRelationResource[],
    annotations: Map<string, Annotation>,
    rulesByName: AnnotationRelationsRulesByName,
    conditions: RelationCondition<string>[],
  ): Map<string, AnnotationRelation> {
    const relations = Array.from(
      AnnotationRelation.mapFromResource(
        resources,
        annotations,
        rulesByName,
        conditions,
      ).values(),
    );

    const filteredRels = relations.filter(rln => {
      const errors = AnnotationRelation.validateObject(rln);
      if (errors) {
        console.error('Relation filtered with errors: ' + errors, rln);
      }
      return !errors;
    });

    return KeyValueMap.usingKey(filteredRels, 'id');
  },

  /**
   * Returns a standardized rulename for the passed rule or condition.
   * @param rule
   */
  ruleName(ruleOrCondition: RuleOrCondition<string>): string {
    if (isCondition(ruleOrCondition)) {
      const condition = ruleOrCondition;
      return AnnotationRelation.conditionName(condition);
    } else if (isRule(ruleOrCondition)) {
      const rule = ruleOrCondition;
      return `${rule.left}-${rule.type}-${rule.right}`;
    } else {
      return unexhaustiveError(
        `ruleName called with something that is neither a rule or condition: ${JSON.stringify(
          ruleOrCondition,
        )}`,
      );
    }
  },

  /**
   * Returns a standardized rulename for the passed condition.
   * @param rule
   */
  conditionName(condition: RelationCondition<string>) {
    const leftId = AnnotationRelation.ruleName(condition.left);
    const rightId = AnnotationRelation.ruleName(condition.right);

    return `[${leftId}]-${condition.type}-[${rightId}]`;
  },

  getRulesAndConditions(
    annotation: Annotation,
    rules: AnnotationRelationRule[],
  ): RuleOrCondition<string>[] {
    return [
      ...AnnotationRelation.getRules(annotation, rules),
      ...AnnotationRelation.getConditions(
        annotation,
        rules.filter(isCondition) as any,
      ),
    ];
  },

  /**
   * Retrieves any relations rules that apply to an annotation.
   * Only returns left-hand relationship rules for an annotation.
   * @param annotation
   */
  getRules(
    annotation: Annotation,
    rules: AnnotationRelationRule[],
  ): RelationRule<string>[] {
    return filter(rules, rule => rule.left === annotation.type);
  },

  getConditions(
    annotation: Annotation,
    conditions: RelationCondition<string>[],
  ): RelationCondition<string>[] {
    return filter(conditions, (cond: RelationCondition<string>) =>
      isCondition(cond.left)
        ? !!AnnotationRelation.getConditions(annotation, [cond.left])
        : cond.left.left === annotation.type,
    );
  },

  getRelationsFn(
    relations: AnnotationRelation[],
  ): (a: Annotation) => AnnotationRelation[] {
    const grouped = AnnotationRelation.groupLeft(relations);
    return (a: Annotation) => grouped.get(a.id) || [];
  },

  /**
   * Searches for first rule applicable to the passed types and
   * relation. This may return wildcard type matches.
   * For example, passing these params:
   *  left: SECTION
   *  ruleType: CHILD-OF
   *  right: LEGAL_DESCRIPTION
   * will match a rule defined as
   *  left: ANY
   *  ruleType: CHILD-OF
   *  right: LEGAL_DESCRIPTION
   *
   * Note: the reverse is not true. Passing "ANY" as the type will
   * only match an ANY rule.
   *
   */
  findApplicableRule(
    left: string,
    ruleType: RelationType,
    right: string,
    rulesByName: AnnotationRelationsRulesByName,
    conditions: RelationCondition<string>[],
  ): RelationRule<string> | null {
    const anyType = AnnotationType.WildcardAnyKey;
    const ruleMatchers = [
      { left, type: ruleType, right },
      { left: anyType, type: ruleType, right },
      { left, type: ruleType, right: anyType },
      { left: anyType, type: ruleType, right: anyType },
    ];

    // 1) Search for any valid top-level rules.
    const matcher = ruleMatchers.find(m => !!rulesByName[this.ruleName(m)]);
    if (matcher) {
      return rulesByName[this.ruleName(matcher)];
    }

    // 2) Search for any valid rules nested in rule conditions.
    for (const match of ruleMatchers) {
      const maybeRule = AnnotationRelation.findRuleInConditions(
        match.left,
        match.type,
        match.right,
        conditions,
      );
      if (maybeRule) {
        return maybeRule;
      }
    }

    return null;
  },

  /**
   * Searches for a rule that exactly matches the passed types
   * and relation.
   * @param left Left-side annotation type.
   * @param ruleType Rule type.
   * @param right Right-side annotation type.
   */
  findRule(
    left: string,
    ruleType: RelationType,
    right: string,
    rulesByName: AnnotationRelationsRulesByName,
    conditions: RelationCondition<string>[],
  ): RelationRule<string> | null {
    const ruleName = AnnotationRelation.ruleName({
      left,
      type: ruleType,
      right,
    });

    return (
      rulesByName[ruleName] ||
      AnnotationRelation.findRuleInConditions(left, ruleType, right, conditions)
    );
  },

  findRuleInConditions(
    left: string,
    ruleType: RelationType,
    right: string,
    conditions: RelationCondition<string>[],
  ): RelationRule<string> | null {
    for (const cond of conditions) {
      const rule = AnnotationRelation.findRuleInCondition(
        cond,
        left,
        ruleType,
        right,
      );
      if (rule) {
        return rule;
      }
    }

    return null;
  },

  findRuleInCondition(
    condition: RelationCondition<string>,
    left: string,
    ruleType: RelationType,
    right: string,
  ): RelationRule<string> | null {
    const ruleName = AnnotationRelation.ruleName({
      left,
      type: ruleType,
      right,
    });

    let leftRuleFound;
    if (isCondition(condition.left)) {
      leftRuleFound = AnnotationRelation.findRuleInCondition(
        condition.left,
        left,
        ruleType,
        right,
      );
    } else {
      const leftRuleName = AnnotationRelation.ruleName(condition.left);
      leftRuleFound = ruleName === leftRuleName ? ruleName : null;

      if (leftRuleFound) {
        return condition.left;
      }
    }

    let rightRuleFound;
    if (isCondition(condition.right)) {
      rightRuleFound = AnnotationRelation.findRuleInCondition(
        condition.right,
        left,
        ruleType,
        right,
      );
    } else {
      const rightRuleName = AnnotationRelation.ruleName(condition.right);
      rightRuleFound = ruleName === rightRuleName ? ruleName : null;

      if (rightRuleFound) {
        return condition.right;
      }
    }

    return null;
  },

  /**
   * Groups relations by their left-hand annotation
   * with annotation id as the key for each group.
   *
   * @param relations
   */
  groupLeft(
    relations: AnnotationRelation[],
  ): Map<string, AnnotationRelation[]> {
    return KeyValueMap.grouped(relations, r => r.left.id);
  },

  /**
   * Groups relations by their right-hand annotation
   * with annotation id as the key for each group.
   *
   * @param relations
   */
  groupRight(
    relations: AnnotationRelation[],
  ): Map<string, AnnotationRelation[]> {
    return KeyValueMap.grouped(relations, r => r.right.id);
  },

  /**
   * Builds a tree from parent-child annotation relations.
   * This function currently only works for child-of relationships.
   * @param annotations
   * @param relations
   */
  createParentChildTrees(
    annotations: Annotation[],
    relations: AnnotationRelation[],
  ): SimpleTreeNode<Annotation>[] {
    const rightRelations = AnnotationRelation.groupRight(relations);
    const leftRelations = AnnotationRelation.groupLeft(relations);

    // // First, filter out all annotations that are children.
    const topParents = annotations.filter(a => !leftRelations.get(a.id));

    function createNode(
      atn: Annotation,
      leftRelationLookup: Map<string, AnnotationRelation[]>,
      rightRelationLookup: Map<string, AnnotationRelation[]>,
      depth = 1,
    ): SimpleTreeNode<Annotation> {
      if (depth > 1000) {
        throw new Error(
          'Possible infinite recursion in function AnnotationRelation.createParentChildTrees. Bailing out.',
        );
      }
      const rightRels = rightRelationLookup.get(atn.id) || [];
      const leftRels = leftRelationLookup.get(atn.id) || [];
      const children = rightRels.map(r => r.left);
      const numberOfChildren = children.length;

      return {
        parent: leftRels.length ? leftRels[0].right : null,
        value: atn,
        children: children.map(c =>
          createNode(c, leftRelationLookup, rightRelationLookup, depth + 1),
        ),
        depth,
        childrenCount: numberOfChildren,
      };
    }

    return topParents.map(p => {
      return createNode(p, leftRelations, rightRelations);
    });
  },

  /**
   * Flattens annotation tree into array of nodes. Useful for
   * working with tree in array but retaining depth & parent-child
   * information.
   */
  flattenTree(
    nodes: SimpleTreeNode<Annotation>[],
  ): SimpleTreeNode<Annotation>[] {
    return flatten(
      nodes.map(node => {
        if (node.children.length === 0) {
          return node;
        }

        return [node, ...AnnotationRelation.flattenTree(node.children)];
      }),
    );
  },

  createFlatTree(
    annotations: Annotation[],
    relations: AnnotationRelation[],
  ): SimpleTreeNode<Annotation>[] {
    return AnnotationRelation.flattenTree(
      AnnotationRelation.createParentChildTrees(annotations, relations),
    );
  },

  createKeyedFlatTree(
    annotations: Annotation[],
    relations: AnnotationRelation[],
  ): FlatTree {
    const tree = AnnotationRelation.createParentChildTrees(
      annotations,
      relations,
    );
    const flatTreeNodes = AnnotationRelation.flattenTree(tree);
    const flatTree = keyBy(flatTreeNodes, x => x.value.id);
    return flatTree;
  },

  mapFromResource(
    resources: AnnotationRelationResource[],
    annotations: Map<string, Annotation>,
    rulesByName: AnnotationRelationsRulesByName,
    conditions: RelationCondition<string>[],
  ): Map<string, AnnotationRelation> {
    return KeyValueMap.usingKey(
      resources
        .map(par => {
          const left = annotations.get(par.leftAttributeAnnotationId + '');
          const right = annotations.get(par.rightAttributeAnnotationId + '');

          if (!left || !right) {
            console.error(
              'Relation map error missing annotation with id ' +
                par.leftAttributeAnnotationId,
            );
            return null;
          }

          const id: string = par.annotationRelationId.toString();
          return {
            id: id,
            rule: AnnotationRelation.findApplicableRule(
              left.type,
              par.relationType,
              right.type,
              rulesByName,
              conditions,
            ),
            left,
            right,
          };
        })
        .filter(r => !!r)
        .map(a => a as AnnotationRelation),
      'id',
    );
  },

  mapToResource(
    relations: Map<string, AnnotationRelation>,
    annotations: AnnotationResource[],
  ): SaveAnnotationRelation[] {
    return map(Array.from(relations.values()), relation => ({
      id: relation.id.toString(),
      relationType: relation.rule.type,
      leftIndex: findIndex(
        annotations,
        a => a.clientId === relation.left.id.toString(),
      ),
      rightIndex: findIndex(
        annotations,
        a => a.clientId === relation.right.id.toString(),
      ),
    }));
  },

  isSelfReferencing(r: Relation<string, Annotation>): boolean {
    return r.left.id === r.right.id;
  },

  extractUniqueAnnotations(relations: AnnotationRelation[]): Annotation[] {
    return uniqBy(
      flatMap(relations, r => [r.left, r.right]),
      'id',
    );
  },

  isCyclical(
    newRelation: Relation<string, Annotation>,
    existingRelations: Map<string, AnnotationRelation>,
  ): boolean {
    const tempId = uuid();
    const newAnnotationRelation = { id: tempId, ...newRelation };
    const existingRelationsWithNewRelation = Map(
      Array.from(existingRelations.entries()).concat([
        [tempId, newAnnotationRelation],
      ]),
    );
    const relevantExistingAnnotations = AnnotationRelation.extractUniqueAnnotations(
      Array.from(existingRelationsWithNewRelation.values()),
    );
    let flatTree: FlatTree;
    try {
      flatTree = AnnotationRelation.createKeyedFlatTree(
        relevantExistingAnnotations,
        Array.from(existingRelationsWithNewRelation.values()),
      );
    } catch {
      // if we get an error thrown here, it is because we have hit a depth
      // in a recursive call that indicate we may soon blow the stack.
      return true;
    }

    return some(values(flatTree), (node: SimpleTreeNode<Annotation>) => {
      const treePath = AnnotationTableRow.treePath(node, flatTree);
      return uniq(treePath).length !== treePath.length;
    });
  },
};
