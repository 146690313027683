import { CloseOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';
import { Restricted } from '../../accounts/containers/restricted.container';
import {
  PermissionActions,
  PermissionResources,
} from '../../accounts/models/permission.model';
import { DefaultTextColor } from '../../common/colors';
import { DateComponent } from '../../common/components/date.component';
import { AnnotatedDocument } from '../models/annotated-document.model';

const Row = styled.div`
  display: flex;

  strong,
  span {
    flex: 1;
    text-align: left;
  }
`;

const Wrap = styled.div`
  min-width: 360px;

  h3 {
    text-align: center;
  }
`;

const CloseButton = styled.a`
  &:not(:hover) {
    color: ${DefaultTextColor};
  }
`;

const Spacer = styled.div`
  height: 10px;
`;

const annotatePermissions = {
  action: PermissionActions.Annotate,
  resource: PermissionResources.DocumentSet,
};

interface DocumentInfoProps {
  document: AnnotatedDocument;
  ocrVersion: string | null;
  onClose: () => any;
}
export class DocumentInfoComponent extends React.PureComponent<
  DocumentInfoProps
> {
  renderTimestamp(value?: string, showTime = true) {
    const format = showTime ? 'yyyy-mm-dd h:MM:ss TT' : 'yyyy-mm-dd';
    return value ? (
      <DateComponent serializedDate={value} format={format} />
    ) : (
      <span>N/A</span>
    );
  }
  private extractDataFromDocument<Field extends keyof AnnotatedDocument>(
    field: Field,
  ) {
    if (field in this.props.document.metadata) {
      return this.props.document.metadata[field];
    } else {
      return this.props.document[field];
    }
  }

  render() {
    return (
      <Wrap className="not-draggable">
        <CloseButton style={{ float: 'right' }} onClick={this.props.onClose}>
          <CloseOutlined style={{ fontSize: '18px' }} />
        </CloseButton>
        <h3>{this.extractDataFromDocument('aggregateId')}</h3>
        <Row>
          <strong>Instrument Type</strong>
          <span>{this.extractDataFromDocument('instrumentType')}</span>
        </Row>
        <Row>
          <strong>Recorded Date</strong>
          {this.renderTimestamp(
            this.extractDataFromDocument('recordedDate'),
            false,
          )}
        </Row>
        <Row>
          <strong>OCR Version</strong>
          <span>{this.props.ocrVersion}</span>
        </Row>
        <Row>
          <strong>DocumentSet</strong>
          <span>
            {this.extractDataFromDocument('documentSetName') || 'N/A'}
          </span>
        </Row>
        <Spacer />
        <Row>
          <strong>County</strong>
          <span>{this.extractDataFromDocument('county')}</span>
        </Row>
        <Row>
          <strong>State</strong>
          <span>{this.extractDataFromDocument('state')}</span>
        </Row>
        <Spacer />
        <Restricted required={annotatePermissions}>
          <Row>
            <strong>Annotated By</strong>
            <span>
              {this.extractDataFromDocument('lastAnnotatorName') || 'N/A'}
            </span>
          </Row>
          <Row>
            <strong>Annotated On</strong>
            {this.renderTimestamp(
              this.extractDataFromDocument('lastAnnotationTimestamp'),
            )}
          </Row>
          <Spacer />
          <Row>
            <strong>Reviewed By</strong>
            <span>
              {this.extractDataFromDocument('lastReviewerName') || 'N/A'}
            </span>
          </Row>
          <Row>
            <strong>Reviewed On</strong>
            {this.renderTimestamp(
              this.extractDataFromDocument('lastReviewTimestamp'),
            )}
          </Row>
        </Restricted>
      </Wrap>
    );
  }
}
