import { connect } from 'react-redux';
import { setActiveAnnotationSchema } from '../../annotations/actions/annotation-schemas.actions';
import { AnnotationListPopover } from '../../annotations/components/annotation-list-popover.component';
import { AnnotationSchema } from '../../annotations/models/annotation-schema.model';
import {
  selectActiveAnnotationSchema,
  selectUserSelectableSchemasArray,
} from '../../annotations/selectors/annotation-schema.selectors';
import { AppState } from '../../app-state';

export const AnnotationSearchPopover = connect(
  (state: AppState) => ({
    schema: selectActiveAnnotationSchema(state),
    allSchemas: selectUserSelectableSchemasArray(state),
    schemaEditorDisabled: true,
  }),
  dispatch => ({
    onSelectSchema: (schema: AnnotationSchema) => {
      dispatch(setActiveAnnotationSchema(schema));
    },
  }),
)(AnnotationListPopover);
