import { includes } from 'lodash';
import { from } from 'rxjs';
import { delayWhen, filter, first, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import {
  selectAllCurrentUserGroupNames,
  selectCurrentUserIsPopulated,
} from '../../accounts/selectors/groups.selectors';
import { selectUsername } from '../../accounts/selectors/users.selectors';
import { fetchAnnotationSchemas } from '../../annotations/actions/annotation-schemas.actions';
import { AppState } from '../../app-state';
import { authenticateSuccess } from '../../auth/actions/auth.actions';
import { selectUserIsLoggedIn } from '../../auth/selectors/auth.selectors';
import { fetchDocumentSetList } from '../../document-sets/actions/document-sets.actions';
import { fetchQueues } from '../../queues/actions/queue.actions';
import { AppFlags, setFlagsAction } from '../../flags';
import {
  fetchPredictionModels,
  getPredictionLabelsAsync,
} from '../../predictions/actions/predictions.actions';
import { appInit, appInitComplete } from '../actions/init.actions';
import { AppEpic } from '../types/app-epic.type';

const runInitActionsAfterAction: AppEpic = (action$, state$, action) =>
  action$.pipe(
    filter(isActionOf(action)),
    delayWhen(_ => state$.pipe(filter(selectUserIsLoggedIn), first())),
    mergeMap(() =>
      from([
        fetchAnnotationSchemas(),
        fetchDocumentSetList(),
        fetchQueues(),
        getPredictionLabelsAsync.request(),
        fetchPredictionModels(),
        appInitComplete(),
      ]),
    ),
  );

export const runInitActionsAfterAppInit: AppEpic = (action$, state$) =>
  runInitActionsAfterAction(action$, state$, appInit);

// For when the user logs back in after the initial appInit
export const runInitActionsAfterAuthSuccess: AppEpic = (action$, state$) =>
  runInitActionsAfterAction(action$, state$, authenticateSuccess);

export const calculateFeatureFlagsEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([appInitComplete, authenticateSuccess])),
    delayWhen(_ => state$.pipe(filter(selectCurrentUserIsPopulated), first())),
    map(() => {
      const newFlags = calculateFeatureFlags(state$.value);
      return setFlagsAction(newFlags);
    }),
  );

function calculateFeatureFlags(state: AppState): Partial<AppFlags> {
  const groupNamesThatCanManage = [
    'Oseberg',
    'Digital Blackacre',
    'XCL Resources',
    'AI Platform Demo Group',
    'Oseberg Contractor',
    'Continental JOA Project',
  ];
  const userGroupNames = selectAllCurrentUserGroupNames(state);
  const user = selectUsername(state);
  const manage = userGroupNames.some(name =>
    includes(groupNamesThatCanManage, name),
  );

  const adminUsers = ['dan'];
  const admin = includes(adminUsers, user);

  return {
    features: { manage, admin },
  } as Partial<AppFlags>;
}
