import styled from 'styled-components';

export const ContentCard = styled.div`
  flex: 1;
  margin: 12px 0px 12px 12px;

  max-height: calc(100vh - 76px);
  overflow-y: scroll;
  background-color: white;
  padding: 8px;
  box-shadow: 1px 2px 1px #ddd;

  &:last-child {
    margin-right: 12px;
  }
`;

export const ScrollLessContentCard = styled(ContentCard)`
  overflow-y: hidden;
`;
