import {
  get,
  groupBy,
  map as lodashMap,
  mapValues,
  omit,
  reduce,
  some,
  uniq,
} from 'lodash';
import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import { AppState } from '../../app-state';
import { CONTENT_TYPE_HEADERS, authHeaders } from '../../common/utils/fetch';
import { config } from '../../config/application.config';
import {
  AnnotationRelation,
  SaveAnnotationRelation,
} from '../models/annotation-relation.model';
import { AnnotationSaveEvent } from '../models/annotation-save-event.model';
import { AnnotationType } from '../models/annotation-type.model';
import { Annotation } from '../models/annotation.model';
import { BulkAnnotationImportFailures } from '../reducers/bulk-annotations-importer-status.reducer';
import { AnnotationRelationResource } from './annotation-relation.resource';
import { AnnotationResource } from './annotation.resource';
import { Map } from 'immutable';

export type AnnotationGroupResource = {
  annotatedDocumentId: number;
  saveEvent: AnnotationSaveEvent;
  annotations: AnnotationResource[];
  relations: AnnotationRelationResource[];
};

export type NewAnnotationGroupResource = {
  annotatedDocumentId?: number;
  alternateId?: {
    aggregateId: string;
    documentSetId: number;
  };
  annotatorName: string;
  documentTextId?: number;
  annotations: AnnotationResource[];
  relations: SaveAnnotationRelation[];
  reviewed?: boolean;
};

export const AnnotationGroupResource = {
  baseUrl: config.annotationService.url,
  postAnnotationsUrl: `${config.annotationService.url}/annotations`,
  bulkPostAnnotationsUrl: `${config.annotationService.url}/annotations/bulk?additive=true&overrideMetadata=true`,
  csvHeaderAggregateId: 'fileName' as const,

  getAnnotationUrl(annotatedDocumentId: number): string {
    return `${this.baseUrl}/document/${annotatedDocumentId}/annotations`;
  },

  get(
    state: AppState,
    annotatedDocumentId: number,
  ): Observable<AnnotationGroupResource> {
    return ajax.getJSON<AnnotationGroupResource>(
      this.getAnnotationUrl(annotatedDocumentId),
      authHeaders(state),
    );
  },

  post(
    state: AppState,
    group: NewAnnotationGroupResource,
  ): Observable<AnnotationGroupResource> {
    return ajax
      .post(this.postAnnotationsUrl, group, {
        ...authHeaders(state),
        ...CONTENT_TYPE_HEADERS.JSON,
      })
      .pipe(map(resp => resp.response));
  },

  postBulk(
    state: AppState,
    groups: NewAnnotationGroupResource[],
  ): Observable<{
    successCount: number;
    failures: BulkAnnotationImportFailures[];
  }> {
    return ajax
      .post(this.bulkPostAnnotationsUrl, groups, {
        ...authHeaders(state),
        ...CONTENT_TYPE_HEADERS.JSON,
      })
      .pipe(map(resp => resp.response));
  },
};

export const NewAnnotationGroupResource = {
  create(
    annotations: Map<string, Annotation> | Annotation[],
    relations: Map<string, AnnotationRelation>,
    annotatorName: string,
    annotatedDocumentId: number,
    documentTextId: number,
    reviewed = false,
  ): NewAnnotationGroupResource {
    const persistAtns = Annotation.mapToResource(annotations);
    const persistRlns = AnnotationRelation.mapToResource(
      relations,
      persistAtns,
    );

    return {
      annotatedDocumentId,
      annotatorName,
      documentTextId,
      annotations: persistAtns,
      relations: persistRlns,
      reviewed,
    };
  },

  /**
   * This function creates a few lookup tables to be used when
   * parsing the headers from the csv data into valid annotation type
   * keys. It then returns a function that will perform said parsing on
   * a per row data basis
   */
  createCSVRowDataParser: (
    annotatorName: string,
    documentSetId: number,
    validAnnotationTypes: AnnotationType[],
  ) => {
    const groupedAnnotationTypesByTitle = groupBy(
      validAnnotationTypes,
      'title',
    );
    const annotationTitleToKeyMap: {
      [title: string]: string[] | undefined;
    } = mapValues(groupedAnnotationTypesByTitle, ats =>
      uniq(lodashMap(ats, at => at.key)),
    );
    const allAnnotationTypeKeys = lodashMap(validAnnotationTypes, at => at.key);
    return (
      datum: object & {
        [AnnotationGroupResource.csvHeaderAggregateId]: string;
      },
    ): NewAnnotationGroupResource | AnnotationKeyLookupFailures[] => {
      const aggIdHeader = AnnotationGroupResource.csvHeaderAggregateId;
      const alternateId = { documentSetId, aggregateId: datum[aggIdHeader] };
      const datumWithoutId = omit(datum, aggIdHeader);
      const relations: SaveAnnotationRelation[] = [];
      const keyLookupFailures: AnnotationKeyLookupFailures[] = [];

      const annotations: AnnotationResource[] = reduce(
        datumWithoutId,
        (acc, val, key) => {
          if (!val) {
            return acc;
          } else {
            const annotationTypeCandidates: undefined | string[] =
              annotationTitleToKeyMap[key];

            const annotationKeyFallback: string | undefined = some(
              allAnnotationTypeKeys,
              k => k === key,
            )
              ? key
              : undefined;

            const annotationType =
              get(annotationTypeCandidates, 'length', 0) === 1
                ? annotationTypeCandidates![0]
                : annotationKeyFallback;

            if (!annotationType) {
              keyLookupFailures.push({
                id: alternateId.aggregateId,
                headerUsed: key,
                candidates: annotationTypeCandidates,
              });
              return acc;
            } else {
              const newAnnotation: AnnotationResource = {
                annotationType,
                value: val,
                nativeText: val,
                discontinuous: false,
                annotatorName,
                isMetadata: true,
              };
              return [...acc, newAnnotation];
            }
          }
        },
        [] as AnnotationResource[],
      );
      if (keyLookupFailures.length > 0) {
        return keyLookupFailures;
      }
      return {
        alternateId,
        annotations,
        relations,
        annotatorName,
      };
    };
  },
};

export type AnnotationKeyLookupFailures = {
  id: string;
  headerUsed: string;
  candidates: string[] | undefined;
};

export const AnnotationKeyLookupFailures = {
  isFailure: (something: any): something is AnnotationKeyLookupFailures => {
    if (
      'id' in something &&
      'headerUsed' in something &&
      'candidates' in something
    ) {
      return true;
    } else {
      return false;
    }
  },
  toErrorMessage: (f: BulkAnnotationImportFailures): string => {
    let reason = '';
    if (typeof f !== 'string') {
      if ('candidates' in f) {
        reason =
          f.candidates && f.candidates.length > 1
            ? `Annotation Type ${f.headerUsed} matches multiple possible annotations. Please provide the desired annotation type key instead.`
            : `"${f.headerUsed}" does not exist as a valid annotation type`;
      } else if ('_type' in f) {
        if (f._type === 'controllers.DocumentNotFound') {
          reason = `"${f.saveEvent.alternateId.aggregateId}" was not found in the Document Set. Please check the spelling of the file and try again.`;
        } else if (f._type === 'controllers.DocumentLocked') {
          reason = `"${f.saveEvent.alternateId.aggregateId}" is in use by another user. Please make sure to close all open instances and try again.`;
        }
      }
    } else {
      reason = f;
    }
    return reason;
  },
};
