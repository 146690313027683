import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { ErrorColor } from '../../common/colors';
const FormItem = Form.Item;

const Error = styled.div`
  color: white;
  padding: 4px;
  width: 100%;
  background-color: ${ErrorColor};
  border-radius: 6px;
  margin-bottom: 6px;
`;

export type LoginFormProps = {
  onSubmit: (username: string, password: string) => void;
  error: string | null;
  isAuthenticated: boolean;
  redirectOnAuthenticate?: boolean;
  redirectUrl?: string;
} & FormComponentProps &
  RouteComponentProps;

class LoginFormComponent extends React.Component<LoginFormProps> {
  handleSubmit: React.FormEventHandler<any> = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values.username, values.password);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { redirectOnAuthenticate, redirectUrl, isAuthenticated } = this.props;

    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        {this.props.error && <Error>{this.props.error}</Error>}
        <FormItem>
          {getFieldDecorator('username', {
            rules: [{ required: true, message: 'Please input your username!' }],
          })(
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Username"
            />,
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
            />,
          )}
        </FormItem>
        <FormItem>
          <Button
            style={{ width: '100%' }}
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Log in
          </Button>
        </FormItem>
        {isAuthenticated && redirectOnAuthenticate && redirectUrl && (
          <Redirect to={redirectUrl} />
        )}
      </Form>
    );
  }
}

export const LoginForm = Form.create<LoginFormProps>()(LoginFormComponent);
