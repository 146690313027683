import { connect } from 'react-redux';
import { empty } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppState } from '../../app-state';
import { fetchQueues, getNextQueueItem } from '../actions/queue.actions';
import { QueueControlComponent } from '../components/queue-control.component';
import { QueuesResource } from '../resources/queues.resource';
import { selectAllQueuesAsArray, selectCurrentQueueItem } from '../selectors';

export const QueueControl = connect(
  (state: AppState) => ({
    queues: selectAllQueuesAsArray(state),
    currentQueueItem: selectCurrentQueueItem(state),
    getQueueStatus: (queueId: number) =>
      QueuesResource.getStatus(state, queueId).pipe(catchError(_ => empty())),
  }),
  dispatch => ({
    onInit: () => {
      dispatch(fetchQueues());
    },
    onNextItemRequested: (queueId: number) => {
      dispatch(getNextQueueItem(queueId));
    },
  }),
)(QueueControlComponent);
