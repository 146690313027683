import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { AppState } from '../../app-state';
import { fetchDocumentSetList } from '../../document-sets/actions/document-sets.actions';
import { MemberRoles } from '../../roles/containers/member-roles.container';
import { UserManagerComponent } from '../components/user-manager.component';
import { CreateUser } from '../containers/create-user.container';
import { GroupManager } from '../containers/group-manager.container';
import { AdminTab } from '../models/admin-tab.model';
import { selectActiveTab } from '../selectors/ui.selectors';
const ContentWrap = styled.span``;

interface AdminContentProps {
  showContent: AdminTab;
  fetchDocs: () => void;
}
export class AdminContentComponent extends React.Component<AdminContentProps> {
  componentWillMount() {
    this.props.fetchDocs();
  }

  render() {
    const { showContent } = this.props;
    return (
      <ContentWrap>
        {showContent === 'users' && <UserManagerComponent />}
        {showContent === 'groups' && <GroupManager />}
        {showContent === 'roles' && <MemberRoles />}
        {showContent === 'createUser' && <CreateUser />}
      </ContentWrap>
    );
  }
}

export const AdminContent = connect(
  (state: AppState) => ({
    showContent: selectActiveTab(state),
  }),
  dispatch => ({
    fetchDocs: () => {
      dispatch(fetchDocumentSetList());
    },
  }),
)(AdminContentComponent);
