import { every } from 'lodash';
import { isArray, isNumber } from 'util';

export function isNumberArray(value: any): value is number[] {
  if (isArray(value)) {
    return every(value, isNumber);
  } else {
    return false;
  }
}
