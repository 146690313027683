import React from 'react';
import { HotKeys } from 'react-hotkeys';
import { connect } from 'react-redux';
import { AppState } from '../../app-state';
import {
  copySelectedAnnotations,
  deleteSelectedAnnotations,
  pasteAnnotations,
} from '../actions/annotations.actions';
import { selectSourceDocumentIsEditable } from '../../documents/selectors/source-document.selectors';
import { ShortcutKeys } from '../shortcut-keys';

interface AnnotationHotKeysProps {
  documentIsEditable: boolean;
  delete: () => void;
  copy: () => void;
  paste: () => void;
}
class AnnotationHotKeysComponent extends React.Component<
  AnnotationHotKeysProps
> {
  private handlers: {
    Delete: (e?: KeyboardEvent) => void;
    Copy: (e?: KeyboardEvent) => void;
    Paste: (e?: KeyboardEvent) => void;
  };

  constructor(props: AnnotationHotKeysProps) {
    super(props);

    const preventDefault = (e?: KeyboardEvent) => {
      if (e) {
        e.preventDefault();
      }
    };

    this.handlers = {
      Delete: (e?: KeyboardEvent) => {
        preventDefault(e);
        this.props.delete();
      },
      Copy: (_?: KeyboardEvent) => this.props.copy(),
      Paste: (e?: KeyboardEvent) => {
        preventDefault(e);
        this.props.paste();
      },
    };
  }

  /* tslint:disable */
  render() {
    return this.props.documentIsEditable ? (
      <HotKeys
        keyMap={ShortcutKeys}
        attach={window}
        handlers={this.handlers}
        focused
      >
        {this.props.children}
      </HotKeys>
    ) : null;
  }
  /* tslint:enable */
}

export const AnnotationHotKeys = connect(
  (state: AppState) => ({
    documentIsEditable: selectSourceDocumentIsEditable(state),
  }),
  dispatch => ({
    delete: () => {
      dispatch(deleteSelectedAnnotations());
    },
    copy: () => {
      dispatch(copySelectedAnnotations());
    },
    paste: () => {
      dispatch(pasteAnnotations());
    },
  }),
)(AnnotationHotKeysComponent);
