import React from 'react';
import { Selectable } from '../../common/components/selectable.component';
import { HighlightCollection } from '../models/highlight-collection.model';
import { RenderHighlight } from '../models/render-highlight.model';
import { TextSelection } from '../models/text-selection';
import {
  UserHighlight,
  UserHighlightProp,
} from '../models/user-highlight.model';
import { TextSegment } from './text-segment.component';

const TEXT_SEGMENT_CLASSNAME = 'oseberg-text-highlight';

interface TextHighlighterProps {
  text: string;
  highlights: UserHighlightProp[];
  className?: string;
  onUpdateSelection: (e: TextSelection) => void;
  onHighlightsUpdated?: (highlights: HighlightCollection) => any;
  render: (component: JSX.Element, highlights: UserHighlight[]) => JSX.Element;
}
interface TextHighlighterState {
  textSegments: JSX.Element[];
  selection: TextSelection;
  userHighlights: HighlightCollection;
  renderHighlights: RenderHighlight[];
}
export class TextHighlighter extends React.PureComponent<
  TextHighlighterProps,
  TextHighlighterState
> {
  textNode: HTMLDivElement | null = null;

  constructor(props: TextHighlighterProps) {
    super(props);

    this.state = {
      ...this.createTextState(props),
      selection: {
        isSelected: false,
        text: '',
        startOffset: -1,
        endOffset: -1,
        selection: null,
      },
    };
  }

  componentDidMount() {
    if (this.props.onHighlightsUpdated) {
      this.props.onHighlightsUpdated(this.state.userHighlights);
    }
  }

  updateSelection = (selection: Selection) => {
    this.setState(() => {
      const normalized = TextSelection.normalize(selection, this.props.text);
      this.props.onUpdateSelection(normalized);
      return { selection: normalized };
    });
  };

  clearSelection = (selection: Selection) => {
    this.setState(() => {
      const normalized = {
        isSelected: false,
        text: selection.toString(),
        selection,
      };
      this.props.onUpdateSelection(normalized);
      return { selection: normalized };
    });
  };

  needsUpdate(props: TextHighlighterProps) {
    return (
      props.highlights !== this.props.highlights ||
      this.props.text !== props.text
    );
  }

  componentWillReceiveProps(newProps: TextHighlighterProps) {
    if (this.needsUpdate(newProps)) {
      const newState = this.createTextState(newProps);
      this.setState(newState);
      if (this.props.onHighlightsUpdated) {
        this.props.onHighlightsUpdated(newState.userHighlights);
      }
    }
  }

  createTextState(props: TextHighlighterProps) {
    const highlights = RenderHighlight.create(
      props.highlights.map(h => UserHighlight.fromProp(h)),
    );
    const collection = new HighlightCollection(highlights);

    return {
      textSegments: this.generateTextSegments(props.text, highlights),
      userHighlights: collection,
      renderHighlights: highlights,
    };
  }

  generateTextSegments(
    sourceText: string,
    renderHighlights: RenderHighlight[],
  ): JSX.Element[] {
    const highlights = renderHighlights.filter(h => h.start >= 0 && h.end > 0);
    if (highlights.length <= 0) {
      return [this.createBumperSegment(sourceText, 0, sourceText.length - 1)];
    }

    const segments =
      highlights[0].start > 0
        ? [this.createBumperSegment(sourceText, 0, renderHighlights[0].start)]
        : [];
    for (let i = 0; i < highlights.length; i++) {
      const current = highlights[i];
      const next = highlights[i + 1];

      segments.push(this.createTextSegment(sourceText, current));

      if (this.needsBumperSegment(current, next)) {
        segments.push(
          this.createBumperSegment(sourceText, current.end, next.start),
        );
      }
    }

    const last = highlights[highlights.length - 1];
    const sourceEnd = sourceText.length - 1;
    if (last.end !== sourceEnd) {
      segments.push(this.createBumperSegment(sourceText, last.end, sourceEnd));
    }

    return segments;
  }

  render() {
    return (
      <div
        className={this.props.className}
        ref={(elm: HTMLDivElement) => (this.textNode = elm)}
      >
        <Selectable
          onSelectionChange={this.updateSelection}
          onSelectionCleared={this.clearSelection}
        >
          {this.state.textSegments}
        </Selectable>
      </div>
    );
  }

  private createTextSegment(sourceText: string, highlight: RenderHighlight) {
    const text = sourceText.slice(highlight.start, highlight.end);

    return (
      <TextSegment
        key={highlight.id}
        startOffset={highlight.start}
        endOffset={highlight.end}
        highlight={highlight}
        className={TEXT_SEGMENT_CLASSNAME}
        render={this.props.render}
      >
        {text}
      </TextSegment>
    );
  }

  private needsBumperSegment(current: UserHighlight, next: UserHighlight) {
    return next && current.end !== next.start;
  }

  private createBumperSegment(sourceText: string, start: number, end: number) {
    const text = sourceText.slice(start, end);
    return (
      <TextSegment
        key={`bumper-${start}-${end}`}
        startOffset={start}
        endOffset={end}
        className={TEXT_SEGMENT_CLASSNAME}
      >
        {text}
      </TextSegment>
    );
  }
}
