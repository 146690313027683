import { EMPTY, of } from 'rxjs';
import {
  catchError,
  concatMap,
  filter as filterObs,
  map as mapObs,
  switchMap,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AppEpic } from '../../common/types/app-epic.type';
import { handleEpicError } from '../../common/utils/epics';
import {
  annotationSchemaValidationSuccess,
  createSchema,
  deleteSchema,
  fetchAnnotationSchemas,
  replaceAnnotationSchemas,
  setActiveAnnotationSchema,
  setSchemaForDocumentSet,
  updateSchema,
  updateSchemaTypes,
} from '../actions/annotation-schemas.actions';
import { AnnotationSchema } from '../models/annotation-schema.model';
import { AnnotationType } from '../models/annotation-type.model';
import { AnnotationSchemaResource } from '../resources/annotation-schema.resource';
import { refreshActiveUserAccount } from '../../accounts/actions/users.actions';
import { fetchNullKeyLookupValues } from '../../domain/actions/lookup-context.actions';
import {
  selectActiveAnnotationSchema,
  selectAnnotationSchemas,
} from '../selectors/annotation-schema.selectors';
import { values } from 'lodash';

/**
 * Fetches annotation types from webservice.
 * @param action$
 * @param store
 */
export const fetchAnnotationSchemasEpic: AppEpic = (action$, state$) => {
  return action$.pipe(
    filterObs(isActionOf(fetchAnnotationSchemas)),
    switchMap(_ =>
      AnnotationSchemaResource.all(state$.value).pipe(
        mapObs(AnnotationSchema.mapFromResources),
        mapObs(replaceAnnotationSchemas),
        catchError(handleEpicError('Error fetching annotation types')),
      ),
    ),
  );
};

export const createSchemaEpic: AppEpic = (action$, state$) => {
  return action$.pipe(
    filterObs(isActionOf(createSchema)),
    switchMap(a => {
      const {
        title,
        groupId,
        documentSetMatcher,
        documentTypeMatcher,
      } = a.payload;
      const state = state$.value;
      return AnnotationSchemaResource.create(
        state,
        title,
        groupId,
        documentSetMatcher,
        documentTypeMatcher,
      );
    }),
    concatMap(e => of(refreshActiveUserAccount(), fetchAnnotationSchemas())),
    catchError(handleEpicError('Error creating schema')),
  );
};

export const updateSchemaEpic: AppEpic = (action$, state$) => {
  return action$.pipe(
    filterObs(isActionOf(updateSchema)),
    switchMap(a => {
      const state = state$.value;
      return AnnotationSchemaResource.updateSchema(state, a.payload.schema);
    }),
    mapObs(fetchAnnotationSchemas),
    catchError(handleEpicError('Error updating schema')),
  );
};

export const deleteSchemaEpic: AppEpic = (action$, state$) => {
  return action$.pipe(
    filterObs(isActionOf(deleteSchema)),
    switchMap(a => {
      const state = state$.value;
      return AnnotationSchemaResource.deleteSchema(state, a.payload.schemaId);
    }),
    mapObs(fetchAnnotationSchemas),
    catchError(handleEpicError('Error deleting schema')),
  );
};

export const updateSchemaTypesEpic: AppEpic = (action$, state$) => {
  return action$.pipe(
    filterObs(isActionOf(updateSchemaTypes)),
    switchMap(a => {
      const state = state$.value;
      return AnnotationSchemaResource.putSchemaTypes(
        state,
        a.payload.schemaId,
        a.payload.types,
      );
    }),
    mapObs(fetchAnnotationSchemas),
    catchError(handleEpicError('Error updating schema')),
  );
};

/**
 * The purpose of this epic to perform validation on the functional annotation
 * schemas retrieved from the database. The application holds references to the
 * expected annotation type keys that exist in this schema to simplify certain
 * functions (especially when validating annotation relations).
 * This epic validates that the keys the app expects match what was retrieved from
 * the database and is loud and clear about it when they do not.
 * @param action$
 * @param state$
 */
export const validateFunctionalSchemaEpic: AppEpic = (action$, _) => {
  return action$.pipe(
    filterObs(isActionOf(replaceAnnotationSchemas)),
    switchMap(action => {
      let anyType;
      let unknownType;
      const { schemas } = action.payload;
      const functionalSchema = schemas.find(
        schema =>
          schema.annotationSchemaId === AnnotationSchema.FunctionalSchemaId,
      );

      if (functionalSchema) {
        const atnTypes = functionalSchema.annotationTypes;
        anyType = atnTypes.find(
          atn => atn.key === AnnotationType.WildcardAnyKey,
        );
        unknownType = atnTypes.find(
          atn => atn.key === AnnotationType.UnknownKey,
        );
      }

      if (functionalSchema && anyType && unknownType) {
        return of(annotationSchemaValidationSuccess());
      } else {
        return handleEpicError('Annotation schema validation error.')(
          'Fetched annotation schemas are invalid. Please report this error.',
        );
      }
    }),
  );
};

export const setActiveAnnotationSchemaEpic: AppEpic = (action$, _) => {
  return action$.pipe(
    filterObs(isActionOf(setActiveAnnotationSchema)),
    mapObs(action => {
      const schemaId = action.payload.schema.annotationSchemaId;
      return fetchNullKeyLookupValues(schemaId);
    }),
  );
};

export const setSchemaForDocumentSetEpic: AppEpic = (action$, state$) => {
  return action$.pipe(
    filterObs(isActionOf(setSchemaForDocumentSet)),
    mapObs(action => {
      const { documentSetName, classification } = action.payload;
      const state = state$.value;
      const currentActiveSchema = selectActiveAnnotationSchema(state);
      const schemas = selectAnnotationSchemas(state);
      const activeSchema =
        AnnotationSchema.matchDocumentSet(
          documentSetName,
          classification,
          values(schemas),
        ) || currentActiveSchema;

      const retAction = activeSchema
        ? setActiveAnnotationSchema(activeSchema)
        : EMPTY;

      return retAction;
    }),
    catchError(handleEpicError('Error setting schema for document set.')),
  );
};
