import { RightOutlined, SearchOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { Input } from 'antd';
import { filter } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { User } from '../../accounts/models/user.model';
import { SelectableRow } from '../../common/components/table/selectable-row.component';
import { SearchBarWrapper } from './search-bar-wrapper.component';

const columns = [
  {
    title: 'User ID',
    dataIndex: 'userId',
    key: 'userId',
    sorter: (a: User, b: User) => (a.userId < b.userId ? -1 : 1),
  },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
    sorter: (a: User, b: User) => (a.username < b.username ? -1 : 1),
  },
  {
    title: 'Oseberg Username',
    dataIndex: 'subject',
    key: 'subject',
    sorter: (a: User, b: User) => (a.subject < b.subject ? -1 : 1),
  },
  {
    title: '',
    render: () => (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a>
        <RightOutlined />
      </a>
    ),
  },
];

const TableStyle = styled.div`
  .ant-table-wrapper {
    margin-top: 12px;
  }

  .ant-spin-nested-loading > div > .ant-spin {
    width: 0px;
  }
`;

interface UserTableProps {
  users: User[];
  loading: boolean;
  onInit: () => any;
  onSelect: (user: User) => any;
}
interface UserTableState {
  rowData: any[];
  filterValue: string;
  selectedIdx: number | null;
}
export class UserTableComponent extends React.Component<
  UserTableProps,
  UserTableState
> {
  constructor(props: UserTableProps) {
    super(props);

    this.state = {
      rowData: this.props.users,
      filterValue: '',
      selectedIdx: null,
    };
  }

  componentWillMount() {
    this.props.onInit();
  }

  componentWillReceiveProps(props: UserTableProps) {
    if (props.users !== this.props.users) {
      this.updateFilter(this.state.filterValue, props.users);
    }
  }

  setSelectedRow = (user: User, idx: number) => {
    this.setState({ selectedIdx: idx });
    this.props.onSelect(user);
  };

  updateSearch = (e: any) => {
    this.updateFilter(e.target.value, this.props.users);
  };

  updateFilter(value: string, users: User[]) {
    const regex = new RegExp(value, 'i');
    const filteredData = filter(users, user => {
      return (
        user.subject.match(regex) ||
        user.username.match(regex) ||
        (user.userId + '').match(regex)
      );
    });

    this.setState({
      rowData: filteredData,
      filterValue: value,
    });
  }

  render() {
    const { rowData, selectedIdx } = this.state;
    const { loading } = this.props;

    return (
      <TableStyle>
        <SearchBarWrapper>
          <Input
            addonBefore={<SearchOutlined />}
            placeholder={'Search Users'}
            onPressEnter={this.updateSearch}
          />
        </SearchBarWrapper>
        <Table
          columns={columns}
          dataSource={rowData}
          pagination={false}
          size="small"
          bordered={false}
          loading={loading}
          components={{ body: { row: SelectableRow } }}
          rowKey="userId"
          onRow={(user: User, idx: number | undefined) => ({
            selected: idx === selectedIdx ? true : false,
            onClick: () => {
              if (idx !== undefined) {
                this.setSelectedRow(user, idx);
              }
            },
          })}
        />
      </TableStyle>
    );
  }
}
