import { StepForwardOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { SecondaryButton } from '../../common/components/secondary-button.component';

type NextQueueItemButtonProps = {
  onClick: () => void;
  style: React.CSSProperties;
};

const TooltipMessage = () => <span>Go to next queue item</span>;

export class NextQueueItemButton extends React.PureComponent<
  NextQueueItemButtonProps
> {
  render() {
    const { onClick, style } = this.props;
    return (
      <Tooltip title={TooltipMessage}>
        <SecondaryButton onClick={onClick} style={{ height: '100%', ...style }}>
          <StepForwardOutlined />
        </SecondaryButton>
      </Tooltip>
    );
  }
}
