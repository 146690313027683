import { connect } from 'react-redux';
import { AppState } from '../../app-state';
import {
  deleteExportJob,
  downloadExport,
  refreshExportJob,
} from '../actions/export.actions';
import ExportTable from '../components/export-table.component';

export default connect(
  (state: AppState) => ({
    jobs: state.exports.jobs,
  }),
  {
    deleteExportJob: deleteExportJob,
    downloadExport: downloadExport,
    refreshExportJob: refreshExportJob,
  },
)(ExportTable);
