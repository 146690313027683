import { createAction } from 'typesafe-actions';

enum Types {
  DISPLAY_ERROR_NOTIFCATION = 'DISPLAY_ERROR_NOTIFCATION',
  DISPLAY_SUCCESS_NOTIFICATION = 'DISPLAY_SUCCESS_NOTIFICATION',
}

export const displayErrorNotification = createAction(
  Types.DISPLAY_ERROR_NOTIFCATION,
  resolvePayload => (title: string, error: string) =>
    resolvePayload({ title, error }),
);

export const displaySuccessNotification = createAction(
  Types.DISPLAY_SUCCESS_NOTIFICATION,
  resolvePayload => (title: string, message?: string) =>
    resolvePayload({ title, message }),
);
