import React from 'react';
import styled from 'styled-components';

const StyledRow = styled.tr`
  cursor: pointer;

  &.selected {
    td {
      background: #e6f7ff;
    }
  }
`;

interface SelectableRowProps {
  selected: boolean;
  className: string;
}
export class SelectableRow extends React.Component<SelectableRowProps> {
  render() {
    const { selected, className } = this.props;

    return (
      <StyledRow
        {...this.props}
        className={className + ' ' + (selected ? ' selected' : ' not-selected')}
      />
    );
  }
}
