import { get } from 'lodash';
import { Map as ImmMap } from 'immutable';

export type KeyValueMap<T> = {
  [key: string]: T;
  [key: number]: T;
};

export const KeyValueMap = {
  /**
   * Converts an array of objects into a KeyValueMap using
   * a property value on each object as its key.
   */
  usingPropertyKey<T>(collection: T[], key: string): KeyValueMap<T> {
    return collection.reduce(
      (kvMap, entity) => ({
        ...kvMap,
        [get(entity, key)]: entity,
      }),
      {},
    );
  },
  groupBy<T>(collection: T[], key: string): KeyValueMap<T[]> {
    return collection.reduce(
      (kvMap, entity) => ({
        ...kvMap,
        [get(entity, key)]: [entity].concat(kvMap[get(entity, key)] || []),
      }),
      {},
    );
  },
  usingKey<T>(collection: T[], key: string): ImmMap<string, T> {
    return ImmMap(
      collection.map(entity => [get(entity, key) as string, entity]),
    );
  },
  grouped<T>(list: T[], keyGetter): ImmMap<string, T[]> {
    const map = new Map();
    list.forEach(item => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return ImmMap(map);
  },
};
