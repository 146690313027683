import {
  endsWith,
  filter,
  head,
  isArray,
  isEmpty,
  isNull,
  isUndefined,
  last,
  map,
  omit,
  split,
  startsWith,
  trim,
} from 'lodash';
import { isNumberArray } from '../../common/utils/isNumberArray';
import { isStringArray } from '../../common/utils/isStringArray';

export type MatchQueryConditionType =
  | 'contains'
  | 'exact'
  | 'term'
  | 'exists'
  | 'match_phrase';

export type QueryConditionType = MatchQueryConditionType | 'range' | 'boolean';

export type QueryConditionCommonProperties = {
  fieldsToSearch: string[];
  id: string;
  negate?: boolean;
};

export type LogicalOperator = 'and' | 'or';

export type QueryConditionMultiValueProperties = {
  logicalOperator: LogicalOperator;
};

export type ContainsQueryCondition = {
  value: string[];
  type: 'contains';
} & QueryConditionCommonProperties &
  QueryConditionMultiValueProperties;

export type NumericContainsQueryCondition = {
  value: number[];
  type: 'contains';
} & QueryConditionCommonProperties &
  QueryConditionMultiValueProperties;

export type ExactQueryCondition = {
  value: string[];
  type: 'exact';
} & QueryConditionCommonProperties;

export type TermQueryCondition = {
  value: string[];
  type: 'term';
} & QueryConditionCommonProperties;

export type MatchPhraseQueryCondition = {
  value: string;
  type: 'match_phrase';
} & QueryConditionCommonProperties;

export type ExistsQueryCondition = {
  type: 'exists';
} & QueryConditionCommonProperties;

export type RangeQueryCondition = {
  value: string[] | number[];
  type: 'range';
} & QueryConditionCommonProperties;

export type BooleanQueryCondition = {
  type: 'boolean';
  value: boolean[];
} & QueryConditionCommonProperties;

export type QueryCondition =
  | ContainsQueryCondition
  | NumericContainsQueryCondition
  | ExactQueryCondition
  | TermQueryCondition
  | MatchPhraseQueryCondition
  | ExistsQueryCondition
  | RangeQueryCondition
  | BooleanQueryCondition;

export function isContainsQueryCondition(
  c: QueryCondition,
): c is ContainsQueryCondition {
  return c.type === 'contains' && isStringArray(c.value);
}

export function isNumericContainsQueryCondition(
  c: QueryCondition,
): c is ContainsQueryCondition {
  return c.type === 'contains' && isNumberArray(c.value);
}

export function isExactQueryCondition(
  c: QueryCondition,
): c is ExactQueryCondition {
  return c.type === 'exact';
}

export function isTermQueryCondition(
  c: QueryCondition,
): c is ExactQueryCondition {
  return c.type === 'term';
}

export function isMatchPhraseQueryCondition(
  c: QueryCondition,
): c is MatchPhraseQueryCondition {
  return c.type === 'match_phrase' && typeof c.value === 'string';
}

export function isExistsQueryCondition(
  c: QueryCondition,
): c is ExistsQueryCondition {
  return c.type === 'exists';
}

export function isRangeQueryCondition(
  c: QueryCondition,
): c is RangeQueryCondition {
  return c.type === 'range';
}

export function isBooleanQueryCondition(
  c: QueryCondition,
): c is BooleanQueryCondition {
  return c.type === 'boolean';
}

function toFilterBody(filters: QueryCondition[]) {
  const standardizedFilters = map(filters, f => {
    if (conditionIsEmpty(f)) {
      return {
        type: 'exists',
        fieldsToSearch: f.fieldsToSearch,
        id: f.id,
        negate: f.negate,
      };
    } else return f;
  });
  const filterObjects = filter(standardizedFilters, f => !f.negate);
  const mustNotObjects = filter(standardizedFilters, f => !!f.negate);
  const esFilterObjects = map(filterObjects, toEsObject);
  const esMustNotObjects = map(mustNotObjects, toEsObject);

  return { filter: esFilterObjects, must_not: esMustNotObjects };
}

function toEsObject(queryCondition: QueryCondition) {
  return queryCondition.fieldsToSearch.length <= 1
    ? toEsFilter(queryCondition)(queryCondition.fieldsToSearch[0])
    : {
        bool: {
          should: map(
            queryCondition.fieldsToSearch,
            toEsFilter(queryCondition),
          ),
        },
      };
}

const toEsFilter = (f: QueryCondition) => (fieldToSearch: string) => {
  if (isContainsQueryCondition(f) || isNumericContainsQueryCondition(f)) {
    return f.logicalOperator === 'and'
      ? toTermSetFilter(
          fieldToSearch,
          f.value,
          matchAllTermsMinimumShouldMatchScript,
        )
      : toTermFilter(fieldToSearch, f.value);
  } else if (isExactQueryCondition(f)) {
    return toTermKeywordFilter(fieldToSearch, f.value);
  } else if (isTermQueryCondition(f)) {
    return toLowerTermFilter(fieldToSearch, f.value);
  } else if (isMatchPhraseQueryCondition(f)) {
    return toMatchPhraseFilter(fieldToSearch, f.value);
  } else if (isExistsQueryCondition(f)) {
    return toExistsFilter(fieldToSearch);
  } else if (isRangeQueryCondition(f)) {
    return toRangeFilter(fieldToSearch, f.value);
  } else if (isBooleanQueryCondition(f)) {
    return toTermFilter(fieldToSearch, f.value);
  } else {
    return;
  }
};

function toExistsFilter(name: string) {
  return { exists: { field: name } };
}

function toTermKeywordFilter(name: string, value: string | string[]) {
  const updatedName = name + '.keyword';
  return toTermFilter(updatedName, value);
}

function toLowerTermFilter(name: string, value: string[]) {
  const updatedValue = map(value, v => v.toLowerCase());
  return toTermFilter(name, updatedValue);
}

const matchAllTermsMinimumShouldMatchScript = {
  source: 'params.num_terms',
};

function toTermSetFilter(
  name: string,
  values: string[] | number[],
  minShouldMatchScript?: { source: string },
) {
  const fieldName = isStringArray(values) ? `${name}.keyword` : name;
  return {
    terms_set: {
      [fieldName]: {
        terms: values,
        minimum_should_match_script: minShouldMatchScript,
      },
    },
  };
}

function toMatchPhraseFilter(fieldToSearch: string, value: string) {
  return {
    match_phrase: {
      [fieldToSearch]: value,
    },
  };
}

function toTermFilter(
  name: string,
  value: string | string[] | boolean | boolean[] | number | number[],
) {
  const searchType = isArray(value) ? 'terms' : 'term';
  return { [searchType]: { [name]: value } };
}

function toRangeFilter(name: string, value: any[]) {
  const lastValue = last(value);
  const greaterAndLessThan = {
    range: {
      [name]: {
        gte: head(value),
        lte: lastValue,
        // we can specify this field even if we
        // aren't querying a date as elasticsarch
        // does the right thing and just ignores it
        // in that case.
        format: 'strict_date_optional_time',
      },
    },
  };
  return lastValue
    ? greaterAndLessThan
    : omit(greaterAndLessThan, `range.${name}.lte`);
}

function toMultiMatches(queryStrings: string[]) {
  return {
    must: map(queryStrings, toMultiMatch),
  };
}

function toMultiMatch(queryString: string) {
  const slop = wrapped(queryString) ? 0 : 5;
  return {
    multi_match: {
      query: queryString,
      type: 'phrase',
      slop,
      fields: [],
    },
  };
}

export function conditionIsEmpty(condition: QueryCondition): boolean {
  if (isExistsQueryCondition(condition)) {
    return false;
  } else if (isNumericContainsQueryCondition(condition)) {
    return condition.value.length === 0;
  } else {
    const v = condition.value;
    if (isArray(v) && !isEmpty(v)) {
      if (typeof v[0] === 'string') {
        return v[0] === '';
      }
      return false;
    }
    if (typeof v === 'string') {
      return v === '';
    }
    return isNull(v) || isUndefined(v);
  }
}

export function wrapped(text: string): boolean {
  const s = (match: string) => startsWith(text, match);
  const e = (match: string) => endsWith(text, match);
  const single = "'";
  const double = '"';
  return (s(single) && e(single)) || (s(double) && e(double));
}

export function queryBody(queryString?: string, filters?: QueryCondition[]) {
  const queryStringObject =
    queryString && queryString !== ''
      ? toMultiMatches(handleMultipleQueryStrings(queryString))
      : {};
  const filterObject =
    filters && filters.length > 0 ? toFilterBody(filters) : {};
  return { query: { bool: { ...queryStringObject, ...filterObject } } };
}

function handleMultipleQueryStrings(queryString: string) {
  return map(split(queryString, '+'), trim);
}
