import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { AppState } from '../../app-state';
import { fetchDocumentSetUploadPolicyAsync } from '../actions/document-sets.actions';
import DocSetUploader from '../components/document-set-uploader.component';
import DocumentSetUploadPolicy from '../models/document-set-upload-policy.model';
import { DocumentSet } from '../models/document-set.model';
import { selectDocumentSetUploadPolicyByDocSetId } from '../selectors/document-set.selectors';

export type UploadDataState = [
  UploadChangeParam | undefined,
  Dispatch<SetStateAction<UploadChangeParam<UploadFile> | undefined>>,
];

type DocumentSetImporterProps = {
  documentSet: DocumentSet;
  currentlyActiveDocumentSetId: string;
  uploadPolicy: DocumentSetUploadPolicy | null;
  uploadDataState: UploadDataState;
  fetchDocumentSetUploadPolicy: (params: { documentSetId: number }) => unknown;
  onManageDocset?: (docSet: DocumentSet) => void;
  onClick: () => void;
};

const ContentColumn = styled.div`
  flex: 0 0 48%;
`;

const DocumentSetImporterComponent: React.SFC<DocumentSetImporterProps> = ({
  documentSet: { documentSetId },
  fetchDocumentSetUploadPolicy,
  uploadPolicy,
  uploadDataState,
  onClick,
  currentlyActiveDocumentSetId: activeUploadKey,
}) => {
  useEffect(() => {
    fetchDocumentSetUploadPolicy({ documentSetId });
  }, [documentSetId, fetchDocumentSetUploadPolicy]);
  return (
    <ContentColumn>
      {uploadPolicy && (
        <DocSetUploader
          currentlyActiveDocumentSetId={activeUploadKey}
          documentSetId={documentSetId}
          onClick={onClick}
          uploadPolicy={uploadPolicy}
          uploadDataState={uploadDataState}
        />
      )}
    </ContentColumn>
  );
};

const dispatchToProps = {
  fetchDocumentSetUploadPolicy: fetchDocumentSetUploadPolicyAsync.request,
};

const DocumentSetImporter = connect(
  (
    state: AppState,
    ownProps: Pick<DocumentSetImporterProps, 'documentSet'>,
  ) => ({
    uploadPolicy: selectDocumentSetUploadPolicyByDocSetId(
      ownProps.documentSet.documentSetId,
    )(state),
  }),
  dispatchToProps as Pick<
    DocumentSetImporterProps,
    keyof typeof dispatchToProps
  >,
)(DocumentSetImporterComponent);

export default DocumentSetImporter;
