import { Modal } from 'antd';
import { RcFile } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import { includes } from 'lodash';
import PapaParse, { ParseConfig } from 'papaparse';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../app-state';
import { DefaultButton } from '../../common/components/neutral-button.component';
import { SecondaryButton } from '../../common/components/secondary-button.component';
import { SetState } from '../../common/types/set-state.type';
import { saveAnnotationsBulkAsync } from '../actions/annotations.actions';
import { ManualAnnotationImporterUploadStatusBox } from '../components/manual-annotation-importer-upload-status-box';
import { BulkAnnotationUploadState } from '../reducers/bulk-annotations-importer-status.reducer';
import { AnnotationGroupResource } from '../resources/annotation-group.resource';
import {
  BulkAnnotationUploadDetailsProps,
  selectBulkAnnotationsUploadDetails,
} from '../selectors/bulk-annotation-status-message.selectors';

export type ManualAnnotationImporterProps = {
  documentSetId: number;
  uploadDetails: BulkAnnotationUploadDetailsProps;
  parseAndSaveBulkAnnotations: (payload: {
    data: object[];
    documentSetId: number;
  }) => unknown;
  bulkAnnotationsFailure: (error: BulkAnnotationUploadState) => void;
  setDocumentListSync: SetState<boolean>;
};

const parserOptions: ParseConfig = {
  header: true,
  skipEmptyLines: 'greedy',
};

const handleChangeFile = (
  callback: (data: object[]) => void,
  setUploadDataInfo: (csvFile: RcFile) => void,
  bulkAnnotationsFailure: (error: BulkAnnotationUploadState) => void,
  setItemsInCsv: (data: {
    totalDocs: number;
    totalAnnotations: number;
  }) => void,
) => (csvFile: RcFile) => {
  setUploadDataInfo(csvFile);
  const reader = new FileReader();
  reader.onload = () => {
    if (typeof reader.result === 'string') {
      const csvData = PapaParse.parse(reader.result.trim(), parserOptions);
      const aggIdHeader = AnnotationGroupResource.csvHeaderAggregateId;
      if (!includes(csvData.meta.fields, aggIdHeader)) {
        bulkAnnotationsFailure({
          status: 'failure',
          errors: {
            failures: [
              'No file names were found. Please make sure the first column in your CSV is fileName and try again.',
            ],
            successCount: 0,
          },
          originalRequest: [],
        });
      } else {
        setItemsInCsv({
          totalDocs: csvData.data.length,
          totalAnnotations: Object.keys(csvData.meta.fields).length - 1,
        });
        callback(csvData.data);
      }
    }
  };
  reader.readAsText(csvFile);
  // to stop the antd upload component from uploading on its own,
  // we want to handle that ourselves to do validation on the
  // resulting csv -> json conversion performed by papaparse above.
  return false;
};

const ManualAnnotationImporterComponent: React.FC<ManualAnnotationImporterProps> = ({
  parseAndSaveBulkAnnotations,
  bulkAnnotationsFailure,
  documentSetId,
  uploadDetails,
  setDocumentListSync,
}) => {
  const [modalShowing, setModalShowing] = useState(false);
  const [uploadData, setUploadData] = useState<RcFile>();
  const [totalCsvItems, setTotalCsvItems] = useState({
    totalDocs: 0,
    totalAnnotations: 0,
  });

  const resetUploadData = () => {
    setUploadData(undefined);
  };

  return (
    <>
      <SecondaryButton onClick={() => setModalShowing(!modalShowing)}>
        Upload Bulk Annotations
      </SecondaryButton>
      <Modal
        title="Upload Bulk Annotations"
        visible={modalShowing}
        closable={false}
        footer={[
          <SecondaryButton key="cancel" onClick={() => setModalShowing(false)}>
            Close
          </SecondaryButton>,
        ]}
      >
        {uploadData ? (
          <ManualAnnotationImporterUploadStatusBox
            resetUploadData={resetUploadData}
            documentSetId={documentSetId}
            uploadData={uploadData}
            uploadDetails={uploadDetails}
            totalCsvItems={totalCsvItems}
          />
        ) : (
          <Dragger
            onChange={() => {
              setDocumentListSync(true);
            }}
            height={100}
            style={{ margin: '1em 0' }}
            accept=".csv"
            showUploadList={false}
            beforeUpload={handleChangeFile(
              data => parseAndSaveBulkAnnotations({ data, documentSetId }),
              setUploadData,
              bulkAnnotationsFailure,
              setTotalCsvItems,
            )}
          >
            <DefaultButton>Drag or Click to Upload.</DefaultButton>
            <p style={{ marginTop: '1em' }}>
              Please avoid leaving or reloading the Document Set once the upload
              begins.
            </p>
            <p>CSV Files only. </p>
          </Dragger>
        )}
      </Modal>
    </>
  );
};

const ManualAnnotationImporter = connect(
  (state: AppState) => ({
    uploadDetails: selectBulkAnnotationsUploadDetails(state),
  }),
  dispatch => ({
    parseAndSaveBulkAnnotations: (payload: any) => {
      dispatch(saveAnnotationsBulkAsync.request(payload));
    },
    bulkAnnotationsFailure: (error: BulkAnnotationUploadState) =>
      dispatch(saveAnnotationsBulkAsync.failure(error)),
  }),
)(ManualAnnotationImporterComponent);

export default ManualAnnotationImporter;
