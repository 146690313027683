import React from 'react';

export const ZoomOutSvg = (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="310.42px"
    height="310.42px"
    viewBox="0 0 310.42 310.42"
  >
    <g>
      <g>
        <path d="M273.587,214.965c49.11-49.111,49.11-129.021,0-178.132s-129.021-49.111-178.131,0C53.792,78.497,47.482,140.462,76.509,188.85c0,0,2.085,3.496-0.731,6.312c-16.065,16.064-64.263,64.263-64.263,64.263c-12.791,12.79-15.837,30.675-4.493,42.02l1.953,1.951c11.343,11.345,29.229,8.301,42.019-4.49c0,0,48.096-48.097,64.128-64.128c2.951-2.951,6.448-0.866,6.448-0.866C169.957,262.938,231.923,256.629,273.587,214.965z M118.71,191.71c-36.288-36.288-36.287-95.332,0-131.62c36.288-36.287,95.333-36.288,131.62,0c36.288,36.287,36.288,95.332,0,131.62C214.043,227.997,154.999,227.997,118.71,191.71z" />
        <path d="M244.365,134.336c0,5.5-4.5,10-10,10h-98.334c-5.5,0-10-4.5-10-10v-16.575c0-5.5,4.5-10,10-10h98.334c5.5,0,10,4.5,10,10V134.336z" />
      </g>
    </g>
  </svg>
);
