import { get } from 'lodash';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { from } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AppState } from '../../app-state';
import { AppEpic } from '../../common/types/app-epic.type';
import { handleEpicError } from '../../common/utils/epics';
import { CONTENT_TYPE_HEADERS, authHeaders } from '../../common/utils/fetch';
import { config } from '../../config/application.config';
import { AppActions } from '../../root.actions';
import {
  fetchSaveEventReview,
  postAnnotationReview,
  postAnnotationReviewSuccess,
  setAnnotationReviewStatus,
  setReviewingSaveEventId,
} from '../actions/annotation-review.actions';
import { AnnotationReview } from '../models/annotation-review.model';

const annotationReviewUrl = `${config.annotationService.url}/annotation-review`;

// This epic has been removed from the root epic
// due to its functionality being replaced/replicated
// by the feature added in this jira:
// https://jira.oseberg.io/browse/AI-576
export const postAnnotationReviewEpic: AppEpic = (
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) => {
  return action$.pipe(
    filter(isActionOf(postAnnotationReview)),
    switchMap(action => {
      const state = state$.value;
      const postBody = {
        annotationSaveEventId: action.payload.saveEventId,
        completed: action.payload.completed,
        reviewerName: get(
          state,
          'users.activeUserAccount.user.username',
          'unknown',
        ),
      };

      return ajax
        .post(annotationReviewUrl, postBody, {
          ...authHeaders(state),
          ...CONTENT_TYPE_HEADERS.JSON,
        })
        .pipe(
          map(resp =>
            postAnnotationReviewSuccess(resp.response.annotationReview),
          ),
          catchError(handleEpicError('Error submitting annotation review')),
        );
    }),
  );
};

const saveEventReviewUrl = (saveEventId: number) =>
  `${config.annotationService.url}/annotation-save-event/${saveEventId}/review`;

const newReviewState = (state: AppState) => {
  const activeSaveEvent = state.annotations.saveEvent;
  return {
    completed: false,
    annotationSaveEventId: activeSaveEvent
      ? activeSaveEvent.annotationSaveEventId
      : null,
  };
};

interface AnnotationReviewResponse {
  annotationReview: AnnotationReview;
}
export function fetchSaveEventReviewEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(fetchSaveEventReview)),
    switchMap(action => {
      const saveEventId = action.payload.saveEventId;

      return ajax
        .getJSON<AnnotationReviewResponse>(
          saveEventReviewUrl(saveEventId),
          authHeaders(state$.value),
        )
        .pipe(
          map(resp => resp.annotationReview),
          catchError(resp => {
            return from([newReviewState(state$.value)]);
          }),
          mergeMap(review => {
            return from([
              setAnnotationReviewStatus(review.completed),
              setReviewingSaveEventId(review.annotationSaveEventId),
            ]);
          }),
          catchError(handleEpicError('Error fetching annotation review')),
        );
    }),
  );
}
