import { useDebugValue, useEffect, useState } from 'react';
import { Observable } from 'rxjs';

/**
 * Useful for when you want to hold a stateful value that comes is
 * derived using an async mechanism (in this case, an Observable)
 *
 * @param value$ Observable value from which to retrieve state
 * @param dependencies values that, if changed, should prompt a
 * re-fetching of state via the provided value$
 */

export const useFetchedState = <T>(
  value$: Observable<T>,
  dependencies?: readonly any[],
): [T | null, boolean] => {
  const [state, setState] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useDebugValue(state, JSON.stringify);

  useEffect(() => {
    setState(null);
    setIsLoading(true);
    const sub = value$.subscribe(v => {
      setState(v);
      setIsLoading(false);
    });
    return () => {
      sub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return [state, isLoading];
};
