import { Button } from 'antd';
import styled from 'styled-components';
import { ErrorColor, Gray3 } from '../colors';

export const CancelButton = styled(Button as any)`
  &.ant-btn {
    background-color: ${Gray3};
    color: ${ErrorColor};
    border: none;
  }
`;
