import { createAction } from 'typesafe-actions';
enum Types {
  ROTATE_PDF_VIEWER_BY = 'ROTATE_PDF_VIEWER_BY: Rotates Pdf in pdf viewer by degrees',
  ZOOM_PDF_VIEWER_BY = 'ZOOM_PDF_VIEWER_BY',
}

export const rotatePdfViewerBy = createAction(
  Types.ROTATE_PDF_VIEWER_BY,
  resolvePayload => (angle: number) => resolvePayload({ angle }),
);

export const zoomPdfViewerBy = createAction(
  Types.ZOOM_PDF_VIEWER_BY,
  resolvePayload => (pct: number) => resolvePayload({ pct }),
);
