import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from 'styled-components';
import { AnnotationShortcuts } from '../../annotations/containers/annotation-shortcut-keys.container';
import { AnnotationSelection } from '../../annotations/models/annotation-selection.model';
import { Annotation } from '../../annotations/models/annotation.model';
import { ErrorColor } from '../../common/colors';
import { LargeCenteredLoader } from '../../common/components/large-centered-loader.component';
import { Selectable } from '../../common/components/selectable.component';
import { ComponentSize } from '../../common/types/ComponentSize';
import { HighlightCollection } from '../../text-highlighter/models/highlight-collection.model';
import { TextSelection } from '../../text-highlighter/models/text-selection';
import { PDF_SELECTION_MODE } from '../models/pdf-selection-modes.model';
import { PDFTextContent } from '../models/pdf-text-content.model';
import { PDFJSPageViewport } from '../models/pdfjs-page-viewport.model';
import { PDFAnnotationLayer } from './pdf-annotation-layer.component';
if (pdfjs && pdfjs.GlobalWorkerOptions) {
  pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.js`;
}

const PdfViewerDiv = styled.div`
  min-height: 900px;

  .error-msg {
    color: ${ErrorColor};
    font-size: 22px;
  }

  .react-pdf__NoData {
    font-size: 18px;
    padding: 12px;
  }

  &.highlight-error .react-pdf__Page__textContent > div {
    opacity: 0.48;

    &::selection {
      color: rgba(0, 0, 0, 0);
      background: rgba(50, 151, 253, 0.4);
    }
  }
`;

interface PdfViewerProps {
  page: number;
  url: string | null;
  authToken: string | null;
  loading: boolean;
  error: string | null;
  size: ComponentSize;
  rotation: number;
  zoom: number;
  // Annotations to render on the document, if any.
  highlights: HighlightCollection;
  // PDF text item offsets within full document.
  textOffsets: number[];
  // Full document text.
  documentText: string;
  emphasizeAnnotationId: string | null;
  selectionMode: PDF_SELECTION_MODE;
  onSelectionChange: (selection: AnnotationSelection[]) => any;
  onSelectionCleared: () => any;
  onDocumentLoad: (pdf: any) => void;
  onError: (error: string) => void;
}
interface PdfViewerState {
  textOffsetError: boolean;
  pdfTextContent?: PDFTextContent | null;
  pdfPage?: any | null;
  displayAnnotations: boolean;
}
export const PdfViewer = (props: PdfViewerProps) => {
  const defaultState = {
    // "textOffsetError" occurs when
    // 1) Fulltext offsets do not exist for the words within the pdf.
    // 2) Number of fulltext word offsets do not match the number of text content items in the pdf.
    // In the event of an error, features dependent on offsets are not used.
    textOffsetError: false,
    pdfPage: null,
    pdfTextContent: null,
    // Makes annotation layer invisible while page is re-rendering.
    displayAnnotations: true,
  };
  const [state, _setState] = React.useState<PdfViewerState>(defaultState);
  const setState = (update: Partial<PdfViewerState>) => {
    _setState(prev => {
      const newState = { ...prev, ...update };
      return newState;
    });
  };

  const httpHeaders = { 'X-AUTH-TOKEN': props.authToken };

  /**
   * Whether or not to render an annotation layer.
   * This is different than "displayAnnotations" in state.
   * "shouldRenderAnnotationLayer" is whether or not to even render the component.
   * "displayAnnotations" is whether to set "display: none" while the page is re-rendering.
   */
  const shouldRenderAnnotationLayer = state.pdfTextContent && state.pdfPage;

  function getRotation(): number {
    const pageRotation = state.pdfPage ? state.pdfPage.rotate : 0;
    const { rotation } = props;

    return (rotation + pageRotation) % 360;
  }

  function getScale(): number {
    if (!state.pdfPage) {
      return 1;
    }

    return state.pdfPage.width / state.pdfPage.originalWidth;
  }

  function resetState() {
    setState(defaultState);
  }

  const { highlights, textOffsets } = props;
  const { displayAnnotations } = state;

  const {
    error,
    rotation,
    size: { width },
    url,
    loading,
    page,
    zoom,
  } = props;
  React.useEffect(resetState, [url, page]);

  React.useEffect(() => {
    // Hide annotations layer while page is rendering. This helps prevent
    // annotation layer from rendering in an incorrect location while the page
    // is re-rendering.
    setState({ displayAnnotations: false });
  }, [rotation, width, zoom]);

  const onSelectionChange = (selection: Selection) => {
    let normalized: TextSelection;
    if (state.textOffsetError) {
      normalized = {
        isSelected: false,
        text: selection.toString(),
        selection,
      };
    } else {
      normalized = TextSelection.normalize(selection, props.documentText);
    }

    props.onSelectionChange([
      {
        text: normalized.text,
        start: normalized.startOffset,
        end: normalized.endOffset,
      },
    ]);
  };

  const onSelectionCleared = (selection: Selection) => {
    props.onSelectionCleared();
  };

  const onMultiSelectionChange = (elements: HTMLElement[]) => {
    if (elements.length <= 0) {
      props.onSelectionCleared();
    } else {
      if (state.textOffsetError) {
        props.onSelectionChange([
          {
            text: elements.map(e => e.textContent).join(' '),
          },
        ]);
      } else {
        props.onSelectionChange(
          AnnotationSelection.fromElements(elements, props.documentText),
        );
      }
    }
  };

  const onLoadSuccess = (pdf: any) => {
    props.onDocumentLoad(pdf);
  };

  const onPageLoadSuccess = (pdfPage: any) => {
    pdfPage
      .getTextContent()
      .then((textContent: PDFTextContent) =>
        updatePdfPageState(pdfPage, textContent),
      )
      .catch(() => setState({ textOffsetError: true }));
  };

  const updatePdfPageState = (pdfPage: any, pdfTextContent: PDFTextContent) => {
    const textOffsetError =
      pdfTextContent.items.length !== props.textOffsets.length;

    setState({
      pdfPage,
      pdfTextContent,
      textOffsetError,
    });
  };

  const onError = (err: Error) => {
    props.onError(`PARSE ERROR: ${err.message}`);
  };

  const onLoadError = (err: Error) => {
    props.onError(`LOAD ERROR: ${err.message}`);
  };

  const onRenderSuccess = (pdfPage: any) => {
    setState({ pdfPage, displayAnnotations: true });
  };

  /**
   * Creates an inline style for the emphasized annotation.
   * This creates an inline <style>, which is usually not the ideal
   * method of styling components. In this case it allows us to target
   * the emphasized annotation by classname dynamically without causing
   * component re-renders, which is what occurs if the classname is targeted
   * by through a StyledComponent.
   *
   * This is a highly efficient method of targeting the annotation to emphasize.
   */
  function conditionalStyle() {
    const { emphasizeAnnotationId } = props;
    if (!emphasizeAnnotationId) {
      return null;
    }

    const style = `
      .ose-pdf-annotation-layer .oseberg-text-highlight {
        opacity: 0.4 !important;
      }
      .${PDFAnnotationLayer.className} .${Annotation.htmlClass(
      emphasizeAnnotationId,
    )} {
          border: 1px solid #2B6FD2 !important;
          opacity: 1 !important;
          z-index: 9999;
        }
    `;

    return <style dangerouslySetInnerHTML={{ __html: style }} />;
  }

  function renderPage() {
    return (
      <Page
        width={width * zoom}
        pageNumber={page}
        renderAnnotations={false}
        onLoadSuccess={onPageLoadSuccess}
        renderTextLayer={false}
        rotate={getRotation()}
        onRenderSuccess={onRenderSuccess}
      >
        {shouldRenderAnnotationLayer && renderAnnotationLayer()}
      </Page>
    );
  }

  function renderAnnotationLayer() {
    const viewport: PDFJSPageViewport = state.pdfPage.getViewport({
      scale: getScale(),
      rotation: getRotation(),
    });

    // Do not render any highlights if there is a text offset error.
    const renderHighlights = state.textOffsetError
      ? new HighlightCollection([])
      : highlights;

    return (
      <span
        style={{
          display: displayAnnotations ? 'inherit' : 'none',
        }}
      >
        <PDFAnnotationLayer
          key={`${highlights.id}_${viewport.width}_${viewport.rotation}_${
            viewport.scale
          }_${viewport.offsetX}_${
            viewport.offsetY
          }_${viewport.viewBox.toString()}_${page}`}
          highlights={renderHighlights}
          textOffsets={textOffsets}
          pdfTextContent={state.pdfTextContent as PDFTextContent}
          onSelectionChange={onMultiSelectionChange}
          selectionMode={props.selectionMode}
          viewport={viewport}
        />
      </span>
    );
  }

  return (
    <>
      {conditionalStyle}
      <PdfViewerDiv
        style={{ minWidth: `${width * zoom}px` }}
        className={
          state.textOffsetError
            ? 'highlight-error not-draggable'
            : 'not-draggable'
        }
      >
        <AnnotationShortcuts mode="selection">
          <Selectable
            onSelectionChange={onSelectionChange}
            onSelectionCleared={onSelectionCleared}
          >
            {loading && <LargeCenteredLoader />}
            {error && <div className="error-msg">{error}</div>}
            <div style={loading ? { display: 'none' } : { display: 'inherit' }}>
              <Document
                file={url}
                options={{ httpHeaders: httpHeaders }}
                onLoadSuccess={onLoadSuccess}
                onParseError={onError}
                onLoadError={onLoadError}
              >
                {!loading && renderPage()}
              </Document>
            </div>
          </Selectable>
        </AnnotationShortcuts>
      </PdfViewerDiv>
    </>
  );
};
