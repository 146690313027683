import { Layout } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { Header } from '../layout/containers/main-header.container';
import { SearchPanel } from '../search/components/search-panel.component';

const FullPage = styled(Layout)`
  .ant-layout-header {
    height: 48px;
    line-height: 48px;
  }
`;

const SearchWrapper = styled.span`
  display: flex;
  flex-flow: row;
`;

const SearchPanelWrapper = styled.span`
  flex 1 1 1160px;
`;

const LeftGutter = styled.div`
  min-width: 0;
  flex: 0 100 140px;
`;
const RightGutter = LeftGutter;

export class SearchPage extends React.PureComponent {
  render() {
    return (
      <FullPage>
        <Header />
        <SearchWrapper>
          <LeftGutter />
          <SearchPanelWrapper>
            <SearchPanel />
          </SearchPanelWrapper>
          <RightGutter />
        </SearchWrapper>
      </FullPage>
    );
  }
}
