import { connect } from 'react-redux';
import { clearAnnotationSelection } from '../../annotations/actions/ui-annotations.actions';
import { AnnotationValueEditor } from '../../annotations/components/annotation-value-editor.component';
import { selectActiveAnnotationSchema } from '../../annotations/selectors/annotation-schema.selectors';
import { AppState } from '../../app-state';
import { Annotation } from '../models/annotation.model';
import {
  selectAnnotationTypes,
  selectAnnotationValues,
  selectAnnotations,
} from '../selectors/annotation.selectors';
import { selectAnnotationRelations } from '../selectors/annotation-relation.selectors';
import { RowNode } from 'ag-grid-community';

export default connect(
  (state: AppState, props: { node: RowNode }) => ({
    lookupValues: state.lookupContext.lookupValues,
    getParentAnnotations: (id: string) =>
      Annotation.getParents(
        id,
        selectAnnotations(state),
        selectAnnotationRelations(state),
      ),
    activeSchema: selectActiveAnnotationSchema(state),
    annotationValue:
      selectAnnotationValues(state).get(props.node.data.annotationId) || '',
    annotationType:
      selectAnnotationTypes(state).get(props.node.data.annotationId) || '',
  }),
  dispatch => ({
    onClose: () => dispatch(clearAnnotationSelection()),
  }),
  null,
  { forwardRef: true },
)(AnnotationValueEditor);
