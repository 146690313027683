import { isString } from 'util';
import { OmitKey } from '../../utility.types';
import { WorkableQueueItem } from './workable-queue-item.model';

export type QueueItem = OmitKey<WorkableQueueItem, 'annotatedDocument'> & {
  annotatedDocumentId: number;
};

export const QueueItem = {
  isComplete(item: QueueItem | null): boolean {
    if (!item) {
      return false;
    }
    return isString(item.completedAt);
  },

  exists(maybeItem: QueueItem | null): maybeItem is QueueItem {
    return !!maybeItem;
  },
};
