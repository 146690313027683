import { createSelector } from 'reselect';
import { AppState } from '../../app-state';
import {
  selectDocumentLock,
  selectUserHasSourceDocumentLock,
} from './document-lock.selectors';
import { selectPageByViewOrDefault } from '../selectors/page-controls.selectors';
import { PageView } from '../models/page-view.model';

export const selectActiveSourceDocument = (state: AppState) => {
  return state.sourceDocument.meta;
};

export const selectSourceDocumentCurrentPageNumber = (state: AppState) =>
  selectPageByViewOrDefault(PageView.Pdf, state);

export const selectSourceDocumentPageCount = (state: AppState) =>
  state.sourceDocument.pageCount;

export const selectSourceDocumentMeta = (state: AppState) =>
  state.sourceDocument.meta;

export const selectSourceDocumentUrl = (state: AppState) =>
  state.sourceDocument.url;

export const selectSourceDocumentLoading = (state: AppState) =>
  state.sourceDocument.loading;

export const selectSourceDocumentError = (state: AppState) =>
  state.sourceDocument.error;

export const selectSourceDocumentIsEditable = createSelector(
  [
    selectDocumentLock,
    selectActiveSourceDocument,
    selectUserHasSourceDocumentLock,
  ],
  (lock, sourceDocument, userHasSourceDocumentLock) => {
    return (
      userHasSourceDocumentLock &&
      !!sourceDocument &&
      lock.annotatedDocumentId === sourceDocument.annotatedDocumentId
    );
  },
);
