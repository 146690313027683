import { createAction } from 'typesafe-actions';

enum Types {
  BATCH_UNTIL_IDLE = 'Batch specified action until idle.',
}

export type BatchAction = {
  type: string;
  payload: { action: BatchableAction };
};

export type BatchableAction = {
  type: string;
  payload: any[];
};

export const batchUntilIdle = createAction(
  Types.BATCH_UNTIL_IDLE,
  resolvePayload => (action: BatchableAction) => resolvePayload({ action }),
);
