import styled from 'styled-components';

export const ColorBox = styled.div`
  width: 20px;
  height: 20px;
  float: left;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 4px;
`;
