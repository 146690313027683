import { AppState } from '../../app-state';
import { Map } from 'immutable';
import { createSelector } from 'reselect';
import { flatMap, range, uniq } from 'lodash';

const NEARBY_PAGE_DISTANCE = 2;
const NEARBY_PAGE_RANGE = [
  ...range(NEARBY_PAGE_DISTANCE * -1, 0),
  ...range(0, NEARBY_PAGE_DISTANCE + 1),
];

export const selectPageCount = (state: AppState): number =>
  state.pageControls.pageCount;

export const selectPageByView = (state: AppState): Map<string, number> =>
  state.pageControls.pageByView;

export const selectPagesInView = createSelector([selectPageByView], m => [
  ...new Set(m.values()),
]);

export const selectNearbyPagesInView = createSelector(
  [selectPagesInView],
  pages => {
    const nearbyPages = uniq(
      flatMap(pages, p => NEARBY_PAGE_RANGE.map(i => i + p)),
    ).filter(p => p > 0);
    return nearbyPages;
  },
);

export const selectSyncViews = (state: AppState): boolean =>
  state.pageControls.syncViews;

export const selectPageByViewOrDefault = (view: string, state: AppState) => {
  const pageByView = selectPageByView(state);
  const latest = selectSyncViews(state) ? pageByView.get('latest') : null;
  return latest || pageByView.get(view) || 1;
};
