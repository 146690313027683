import { getType } from 'typesafe-actions';
import { saveAnnotationsBulkAsync } from '../actions/annotations.actions';
import { AnnotationsActions } from '../actions/annotations.types';
import { AnnotationSaveEvent } from '../models/annotation-save-event.model';
import {
  AnnotationKeyLookupFailures,
  NewAnnotationGroupResource,
} from '../resources/annotation-group.resource';

export type MissingDocumentError = {
  _type: string;
  saveEvent: {
    alternateId: {
      aggregateId: string;
    };
  };
};

export type BulkAnnotationImportFailures =
  | AnnotationKeyLookupFailures
  | AnnotationSaveEvent
  | string
  | MissingDocumentError;

export type BulkAnnotationUploadState = {
  errors?: {
    successCount: number;
    failures: BulkAnnotationImportFailures[];
  };
  originalRequest: NewAnnotationGroupResource[];
  status: 'uploading' | 'success' | 'partialSuccess' | 'failure' | undefined;
};

const defaultState: BulkAnnotationUploadState = {
  errors: {
    successCount: 0,
    failures: [],
  },
  originalRequest: [],
  status: 'uploading',
};

export function bulkAnnotationsImportStatusesReducer(
  state: BulkAnnotationUploadState = defaultState,
  action: AnnotationsActions,
) {
  switch (action.type) {
    case getType(saveAnnotationsBulkAsync.request): {
      return {
        ...state,
        status: 'pending',
        errors: [],
      };
    }
    case getType(saveAnnotationsBulkAsync.success): {
      return {
        ...state,
        status: action.payload.status,
        errors: action.payload.errors,
      };
    }
    case getType(saveAnnotationsBulkAsync.failure): {
      return {
        ...state,
        status: action.payload.status,
        errors: action.payload.errors,
      };
    }
    default: {
      return state;
    }
  }
}
