import { connect } from 'react-redux';
import { AppState } from '../../app-state';
import { PageControls } from '../../common/components/page-controls.component';
import {
  decrementPage,
  incrementPage,
  setPage,
} from '../../documents/actions/page-controls.actions';
import {
  selectPageByViewOrDefault,
  selectPageCount,
} from '../../documents/selectors/page-controls.selectors';
import { PageView } from '../../documents/models/page-view.model';

const PAGE_VIEW = PageView.Text;

export const AnnotationViewerPageControls = connect(
  (state: AppState) => ({
    currentPage: selectPageByViewOrDefault(PAGE_VIEW, state),
    numberPages: selectPageCount(state),
  }),
  dispatch => ({
    incrementClicked: () => {
      dispatch(incrementPage(PAGE_VIEW));
    },
    decrementClicked: () => {
      dispatch(decrementPage(PAGE_VIEW));
    },
    gotoPage: (page: number) => {
      dispatch(setPage(PAGE_VIEW, page));
    },
  }),
)(PageControls);
