import { createSelector } from 'reselect';
import { isNull } from 'util';
import { AppState } from '../../app-state';
import { selectSourceDocumentCurrentPageNumber } from '../../documents/selectors/source-document.selectors';
import { AnnotationPage } from '../models/annotation-page.model';
import { selectPageByViewOrDefault } from '../../documents/selectors/page-controls.selectors';
import { PageView } from '../../documents/models/page-view.model';
import { sortBy } from 'lodash';

export const selectCurrentAnnotationPageNumber = (state: AppState) =>
  selectPageByViewOrDefault(PageView.Text, state);

export const selectAnnotationPageCount = (state: AppState) =>
  state.annotationPages.pageCount;

export const selectAnnotationPages = (state: AppState) =>
  state.annotationPages.entities;

export const selectFullText = (state: AppState) =>
  state.annotationPages.fullText;

export const selectOcrVersion = (state: AppState) =>
  state.annotationPages.ocrVersion;

export const selectAnnotationPagesArray = createSelector(
  [selectAnnotationPages],
  pages => sortBy(Array.from(pages.values()), p => p.pageNum),
);

export const selectCurrentPage = createSelector(
  [selectSourceDocumentCurrentPageNumber, selectAnnotationPages],
  (currentPageNumber, pages): AnnotationPage | null =>
    pages.get(currentPageNumber) || null,
);

export const selectTextOffsets = createSelector([selectCurrentPage], page =>
  isNull(page) ? [] : page.ocrxOffsets || [],
);
