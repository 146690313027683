import { connect } from 'react-redux';
import { AppState } from '../../app-state';
import { PermissionTables } from '../components/permission-tables.component';
import { selectAllQueuesAsArray } from '../../queues/selectors';
import { selectDocumentSetsArray } from '../../document-sets/selectors/document-set.selectors';
import { values } from 'lodash';
import { selectActiveUserPermissions } from '../../accounts/selectors/users.selectors';
import { selectAnnotationSchemasArray } from '../../annotations/selectors/annotation-schema.selectors';
import { selectLookups } from '../../domain/selectors/lookup-context.selectors';
import { fetchLookups } from '../../domain/actions/lookup-context.actions';

export default connect(
  (state: AppState) => ({
    documentSets: selectDocumentSetsArray(state),
    schemas: selectAnnotationSchemasArray(state),
    queues: selectAllQueuesAsArray(state),
    exports: values(state.exports.exports),
    predictionModels: values(state.predictions.predictionModels),
    domainLookups: selectLookups(state),
    userPermissions: selectActiveUserPermissions(state),
  }),
  { fetchLookups },
)(PermissionTables);
