import { getType } from 'typesafe-actions';
import { sourceDocumentLoaded } from '../../documents/actions/source-document.actions';
import { SourceDocumentActions } from '../../documents/actions/source-document.types';
import {
  fetchPredictionsError,
  getPredictionLabelsAsync,
  predictionsSuccess,
  requestPredictions,
  setActiveLabelGroup,
  setLabelsToPredictOn,
  setPredictionModels,
} from '../actions/predictions.actions';
import { PredictionsActions } from '../actions/predictions.types';
import { PredictionsState } from '../models/predictions.model';
import { KeyValueMap } from '../../common/types/KeyValueMap.type';
import { PredictionModel } from '../models/prediction-model.model';

const defaultPredictionsState: PredictionsState = {
  availableLabels: {},
  predictionsStatus: 'ready',
  labelsToPredictOn: {},
  currentlySelectedLabelGroup: '',
  predictionModels: {},
};
export function predictionsReducer(
  state: PredictionsState = defaultPredictionsState,
  action: PredictionsActions | SourceDocumentActions,
): PredictionsState {
  switch (action.type) {
    case getType(getPredictionLabelsAsync.success):
      const documentTypes = Object.keys(action.payload);
      return {
        ...state,
        availableLabels: action.payload,
        // When we retrieve labels, we need to determine whether the selected
        // label group is a valid selection and set a default if not
        currentlySelectedLabelGroup:
          !(state.currentlySelectedLabelGroup in action.payload) &&
          documentTypes.length > 0
            ? documentTypes[0]
            : state.currentlySelectedLabelGroup,
      };
    case getType(setActiveLabelGroup):
      return {
        ...state,
        currentlySelectedLabelGroup: action.payload.labelGroupName,
      };
    case getType(setLabelsToPredictOn):
      return {
        ...state,
        labelsToPredictOn: {
          ...state.labelsToPredictOn,
          [state.currentlySelectedLabelGroup]: action.payload.labels,
        },
      };
    case getType(requestPredictions):
      return {
        ...state,
        predictionsStatus: 'running',
      };
    case getType(predictionsSuccess):
      return {
        ...state,
        predictionsStatus: 'ready',
      };
    case getType(fetchPredictionsError):
      return {
        ...state,
        predictionsStatus: 'ready',
      };
    case getType(sourceDocumentLoaded):
      return {
        ...state,
        predictionsStatus: 'ready',
      };
    case getType(setPredictionModels):
      const models = KeyValueMap.usingPropertyKey<PredictionModel>(
        action.payload,
        'modelId',
      );
      return {
        ...state,
        predictionModels: models,
      };
    default:
      return state;
  }
}
