import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Group } from '../../accounts/models/group.model';
import { GroupsResource } from '../../accounts/resources/groups.resource';
import {
  selectAllCurrentUserGroups,
  selectCurrentUserGroupId,
} from '../../accounts/selectors/groups.selectors';
import { AppState } from '../../app-state';
import { useFetchedState } from '../../common/hooks/use-fetched-state.hook';
const Option = Select.Option;

type GroupSelectorProps = {
  fetchAllGroups: Observable<Group[]>;
  onChange?: (groupIds: number[] | number) => void;
} & SelectProps<string[]>;

const GroupSelectorComponent: React.FC<GroupSelectorProps> = props => {
  const [groups, loadingGroups] = useFetchedState(props.fetchAllGroups, []);

  return (
    <Select {...props} loading={loadingGroups} style={{ width: '100%' }}>
      {(groups || []).map(group => (
        <Option key={group.groupId} value={group.groupId}>
          {group.groupName}
        </Option>
      ))}
    </Select>
  );
};

export const AllGroupsSelector = connect((state: AppState) => ({
  fetchAllGroups: GroupsResource.getAll(state).pipe(map(gr => gr.groups)),
}))(GroupSelectorComponent);

export const UserGroupsSelector = connect((state: AppState) => ({
  fetchAllGroups: of(selectAllCurrentUserGroups(state)),
  defaultValue: [_.toString(selectCurrentUserGroupId(state))],
}))(GroupSelectorComponent);
