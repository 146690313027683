import { flatMap, map } from 'lodash';
import { AnnotationPage } from './annotation-page.model';

/**
 * Represents the bounding area of an annotation within a single page.
 * If an annotation spans multiple pages, it will have multiple bounding boxes.
 */
export type AnnotationBounds = {
  page: number;

  // Bounding box around full annotation on page.
  // [x0, y0, x1, y1]
  bbox?: number[];

  // Original polys from individual annotation words.
  originalPolys?: number[][][];
};

export const AnnotationBounds = {
  /**
   * Returns the bounding box(es) for the passed annotation.
   * May or may not span multiple pages resulting in multiple bboxes.
   * @param start
   * @param end
   * @param pages
   */
  fromOffsets(
    start: number,
    end: number,
    pages: AnnotationPage[],
  ): AnnotationBounds[] {
    const pageRanges = AnnotationBounds.pageOffsetRanges(pages).filter(
      pageRange => end >= pageRange.start && start <= pageRange.end,
    );

    return pageRanges.map(pr => ({
      page: pr.page.pageNum,
      ...AnnotationBounds.bounds(start, end, pr.page),
    }));
  },

  /**
   * Returns the starting and ending word offsets of each page.
   * @param pages
   * @returns array of { page: AnnotationPage, start: number, end: number }
   */
  pageOffsetRanges(
    pages: AnnotationPage[],
  ): { page: AnnotationPage; start: number; end: number }[] {
    return pages.map((page, idx) => ({
      page,
      start: page.ocrxOffsets[0],
      end: page.ocrxOffsets[page.ocrxOffsets.length - 1],
    }));
  },

  /**
   * Returns the bounding box surrounding the words the offsets
   * intersect within the passed page.
   * @param start start offset
   * @param end ending offset
   * @param page
   */
  bounds(start: number, end: number, page: AnnotationPage) {
    // Refines the type of ocrxPolys (possibly undefined).
    let ocrxPolys: number[][][];
    if (!page.ocrxPolys) {
      return {};
    } else {
      ocrxPolys = page.ocrxPolys;
    }

    const offsetIndexes: number[] = [];
    for (let i = 0; i < page.ocrxOffsets.length; i++) {
      if (
        (start <= page.ocrxOffsets[i] && end > page.ocrxOffsets[i]) ||
        (start < page.ocrxOffsets[i + 1] && end > page.ocrxOffsets[i])
      ) {
        offsetIndexes.push(i);
      }
    }

    const polys = offsetIndexes.map(idx => ocrxPolys[idx]);
    if (!polys.length) {
      return {};
    }

    return {
      bbox: [
        Math.min(...flatMap(polys, p => map(p, p1 => p1[0]))),
        Math.min(...flatMap(polys, p => map(p, p1 => p1[1]))),
        Math.max(...flatMap(polys, p => map(p, p1 => p1[0]))),
        Math.max(...flatMap(polys, p => map(p, p1 => p1[1]))),
      ],
      originalPolys: polys,
    };
  },
};
