import React from 'react';
import styled from 'styled-components';
import { Loader } from '../components/loader.component';

export const LoaderDiv = styled.div`
  width: 160px;
  margin: auto;
`;

export const LargeCenteredLoader = () => (
  <LoaderDiv>
    <Loader />
  </LoaderDiv>
);
