import { ActionsObservable, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { AjaxResponse, ajax } from 'rxjs/ajax';
import { catchError, filter, map, mapTo, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AppState } from '../../app-state';
import { AppEpic } from '../../common/types/app-epic.type';
import { CONTENT_TYPE_HEADERS } from '../../common/utils/fetch';
import { config } from '../../config/application.config';
import { closeDocument } from '../../documents/actions/source-document.actions';
import { AppActions } from '../../root.actions';
import {
  authenticateFailure,
  authenticateSuccess,
  authenticateUser,
  signOut,
} from '../actions/auth.actions';

const tokenUrl = config.auth.url + '/token';

export function authEpic(
  action$: ActionsObservable<AppActions>,
  _: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(authenticateUser)),
    switchMap(action => {
      const { username, password } = action.payload;
      // The Oseberg Auth endpoint returns raw text errors, so
      // we have to manually parse the json response in order
      // to properly grab any auth errors.
      return ajax({
        url: tokenUrl,
        method: 'POST',
        body: JSON.stringify(createAuthBody(username, password)),
        headers: CONTENT_TYPE_HEADERS.JSON,
        responseType: 'text',
      }).pipe(
        map((resp: AjaxResponse) => {
          const responseBody = JSON.parse(resp.response);
          return authenticateSuccess(responseBody.token);
        }),
        catchError(resp => of(authenticateFailure(resp && resp.response))),
      );
    }),
  );
}

function createAuthBody(user: string, password: string) {
  return {
    email: user,
    password,
    appName: config.auth.appName,
  };
}

export const closeDocOnSignOut: AppEpic = actions$ =>
  actions$.pipe(filter(isActionOf(signOut)), mapTo(closeDocument()));
