import { DeleteOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { Group } from '../../accounts/models/group.model';
import { User } from '../../accounts/models/user.model';
import { SecondaryButton } from '../../common/components/secondary-button.component';
import { SetState } from '../../common/types/set-state.type';
import { MemberToRoleProps } from '../containers/member-roles.container';
import { CurrentRoleInfo } from '../models/role.model';

type MemberToRoleTableProps = {
  // usersAndGroups: (User | Group)[];
  currentRole: CurrentRoleInfo | null;
  setRoleAction: SetState<boolean>;
  deleteCurrentMemberFromRole: (payload: MemberToRoleProps) => void;
};

export const MemberToRoleTableComponent: React.FC<MemberToRoleTableProps> = ({
  currentRole,
  setRoleAction,
  deleteCurrentMemberFromRole,
}) => {
  const [usersAndGroups, setUsersAndGroups] = useState<(User | Group)[]>();
  const columns: ColumnProps<User | Group>[] = [
    {
      title: 'Type',
      key: 'type',
      width: '10%',
      render: (member: User & Group) => (member.username ? 'User' : 'Group'),
    },
    {
      title: 'Name',
      key: 'name',
      width: '80%',
      render: (member: User & Group) =>
        member.username ? member.username : member.groupName,
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '10%',
      render: data => {
        const memberId = data.userId ? data.userId : data.groupId;
        const memberType = data.userId ? 'users' : 'groups';
        const roleId = currentRole ? currentRole.role.roleId : undefined;
        return (
          <SecondaryButton
            onClick={() => {
              if (roleId) {
                setRoleAction(true);
                deleteCurrentMemberFromRole({ roleId, memberId, memberType });
              }
            }}
          >
            <DeleteOutlined style={{ paddingRight: 5 }} /> Delete
          </SecondaryButton>
        );
      },
    },
  ];
  const currentRoleDescription = currentRole
    ? currentRole.role.description
    : '';
  useEffect(() => {
    if (currentRole) {
      setUsersAndGroups([...currentRole.users, ...currentRole.groups]);
    }
  }, [currentRole]);
  return (
    <Table
      title={() => <h3>{currentRoleDescription}</h3>}
      rowKey={(row, i) => i?.toString() || ''}
      style={{ width: '100%' }}
      columns={columns}
      dataSource={usersAndGroups}
      pagination={false}
    />
  );
};
