import { Icon as LegacyIcon } from '@ant-design/compatible';
import { IconProps } from '@ant-design/compatible/lib/icon';
import { startCase } from 'lodash/fp';
import React from 'react';
import styled from 'styled-components';
import { OcrStatus } from '../../documents/resources/document-summary.resource';

const StatusSpan = styled.span`
  padding-right: 5px;
`;

const StatusIcon = props => <LegacyIcon {...props} />;

const statusIconPropsMap: { [status in OcrStatus]: IconProps } = {
  pending: { type: 'clock-circle' },
  started: { type: 'sync', spin: true },
  completed: {
    type: 'check-circle',
    theme: 'twoTone',
    twoToneColor: '#52c41a',
  },
  failed: { type: 'close-circle', theme: 'twoTone', twoToneColor: '#eb2f96' },
};

export const FileStatus: React.FC<{ status: string }> = ({ status }) => {
  const currentStatusIcon = statusIconPropsMap[status];
  return (
    <>
      <StatusSpan>{startCase(status)}</StatusSpan>
      <StatusIcon {...currentStatusIcon} />
    </>
  );
};
