import { Observable } from 'rxjs';
import { AjaxResponse, ajax } from 'rxjs/ajax';
import { AppState } from '../../app-state';
import { CONTENT_TYPE_HEADERS, authHeaders } from '../../common/utils/fetch';
import { config } from '../../config/application.config';
import { AnnotatedDocument } from '../models/annotated-document.model';
import { RawMetadata } from '../models/raw-metadata.model';

type UpdateDocumentMetadataParams = {
  annotatedDocumentId: number;
  metadata: RawMetadata;
};

export const DocumentResource = {
  documentUrl: (annotatedDocumentId: number) =>
    `${config.annotationService.url}/document/${annotatedDocumentId}`,

  managedDocumentUrl: (documentSetId: number, annotatedDocumentId: number) =>
    `${config.annotationService.url}/document-set/${documentSetId}/document/${annotatedDocumentId}`,

  metadataUrl: (annotatedDocumentId: number) =>
    `${DocumentResource.documentUrl(annotatedDocumentId)}/metadata`,

  updateDocumentMetadata(
    state: AppState,
    { metadata, annotatedDocumentId }: UpdateDocumentMetadataParams,
  ) {
    return ajax.post(
      this.metadataUrl(annotatedDocumentId),
      { metadata },
      { ...authHeaders(state), ...CONTENT_TYPE_HEADERS.JSON },
    );
  },

  getDocument(state: AppState, annotatedDocumentId: number) {
    return ajax.getJSON<{
      annotatedDocumentId: number;
      document: AnnotatedDocument;
    }>(DocumentResource.documentUrl(annotatedDocumentId), authHeaders(state));
  },
  deleteDocument(
    state: AppState,
  ): (
    documentSetId: number,
    annotatedDocumentId: number,
  ) => Observable<AjaxResponse> {
    return (documentSetId: number, annotatedDocumentId: number) =>
      ajax.delete(
        DocumentResource.managedDocumentUrl(documentSetId, annotatedDocumentId),
        authHeaders(state),
      );
  },
};
