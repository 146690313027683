import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { selectUserGroupCount } from '../../accounts/selectors/groups.selectors';
import { AppState } from '../../app-state';
import { CancelButton } from '../../common/components/cancel-button.component';
import CardWithTitle from '../../common/components/card-with-title.component';
import { ConfirmButton } from '../../common/components/confirm-button.component';
import { createDocumentSetAsync } from '../actions/document-sets.actions';

const ButtonStyle = { width: '240px', height: '40px' };

type DocumentSetCreatorFormValues = {
  name: string;
  owningGroupId: number;
};

type DocumentSetCreatorProps = {
  createDocumentSet: (params: { name: string; owningGroupId: number }) => void;
  onCancel: () => void;
  userGroupCount: number;
} & RouteComponentProps;

const DocumentSetCreatorComponent: React.SFC<DocumentSetCreatorProps> = ({
  createDocumentSet,
  onCancel,
}) => {
  const [form] = Form.useForm();
  return (
    <CardWithTitle
      style={{ height: 'max-content', width: '100%' }}
      contentStyle={{ flexFlow: 'column' }}
      title="Create New Document Set"
      // eslint-disable-next-line no-restricted-globals
      onBackClicked={() => history.back()}
    >
      <div>
        <ExclamationCircleOutlined style={{ color: '#f8c470' }} />{' '}
        {`Once a
      document set is created, the name cannot be changed.`}
      </div>
      <Form style={{ maxWidth: '500px' }} onFinish={createDocumentSet}>
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message:
                'You must provide a name for the Document Set to create.',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <div
          style={{
            width: '500px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <ConfirmButton htmlType="submit" style={ButtonStyle}>
            Confirm
          </ConfirmButton>
          <CancelButton
            onClick={(_: any) => {
              form.resetFields();
              onCancel();
            }}
            style={ButtonStyle}
          >
            Cancel
          </CancelButton>
        </div>
      </Form>
    </CardWithTitle>
  );
};

const mapStateToProps = (state: AppState) => ({
  userGroupCount: selectUserGroupCount(state),
});

const dispatchToProps = {
  createDocumentSet: createDocumentSetAsync.request,
};

const DocumentSetCreator = connect(
  mapStateToProps,
  dispatchToProps as Pick<
    DocumentSetCreatorProps,
    keyof typeof dispatchToProps
  >,
)(DocumentSetCreatorComponent);

export default DocumentSetCreator;
