import {
  DomainLookup,
  DomainLookupValue,
} from '../../domain/models/lookup-context.model';
import { Annotation } from './annotation.model';
import { filter, find, get, map } from 'lodash';

export type ValidatorConfig =
  | SelectValidatorConfig
  | LookupSelectValidatorConfig;

export type LookupSelectValidatorConfig = {
  configType: string;
  domainLookupId: number;
};

export type SelectValidatorConfig = {
  configType: string;
  values: string[];
};

export interface AnnotationSchemaValidator {
  annotationSchemaValidatorId: number;
  annotationSchemaId: number;
  annotationType: string;
  validator: string;
  validatorConfig: SelectValidatorConfig | LookupSelectValidatorConfig;
  createdAt: string;
}

export const AnnotationSchemaValidator = {
  validate(
    lookups: DomainLookup[],
    validators: ValidatorConfig[],
    lookupValues: DomainLookupValue[],
    annotation: Annotation,
    parents: Annotation[],
  ): boolean | undefined {
    const validations = filter(
      map(validators, v =>
        this.validateWithValidator(
          lookups,
          v,
          lookupValues,
          annotation,
          parents,
        ),
      ),
      v => v !== undefined,
    );
    return validations.length > 0
      ? validations.reduce((c, n) => c && n)
      : undefined;
  },
  validateWithValidator(
    lookups: DomainLookup[],
    validator: ValidatorConfig,
    lookupValues: DomainLookupValue[],
    annotation: Annotation,
    parents: Annotation[],
  ): boolean | undefined {
    switch (validator.configType) {
      case 'select': {
        const validatorConfig = validator as SelectValidatorConfig;
        return validatorConfig.values.indexOf(annotation.value) !== -1;
      }
      case 'lookup-select': {
        const validatorConfig = validator as LookupSelectValidatorConfig;
        const validatorLookup = validatorConfig.domainLookupId;
        const validatorLookupValueKey = annotation.type;
        const lookup = find(lookups, l => l.domainLookupId === validatorLookup);
        if (!lookup) {
          return undefined;
        }
        const lookupValue = DomainLookup.findLookupValue(
          lookup,
          lookupValues,
          parents,
        );
        const validValues = get(
          lookupValue?.value,
          validatorLookupValueKey,
        ) as string[];
        return validValues
          ? validValues.indexOf(annotation.value) !== -1
          : undefined;
      }
      default:
        return undefined;
    }
  },

  validatorConfig(l: DomainLookup): ValidatorConfig {
    return {
      configType: 'lookup-select',
      domainLookupId: l.domainLookupId,
    };
  },
};
