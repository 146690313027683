import { Icon } from '@ant-design/compatible';
import React from 'react';
import styled from 'styled-components';
import { CustomIcon, CustomIcons } from './custom-icon.component';

const IconButtonStyle = styled.span`
  background: transparent;
  padding: 0;
  height: inherit;
  font-size: 15px;
  border: 0px;

  .goat-icon {
    height: 1em;
    fill: currentColor;
  }
`;

export interface PerformantIconButtonProps {
  iconType: string;
  className?: string;
  onClick: (e: React.FormEvent<any>) => void;
}
export const PerformantIconButton: React.SFC<PerformantIconButtonProps> = props => {
  const { onClick, className, iconType } = props;
  const isCustom = CustomIcons.indexOf(iconType) >= 0;
  const IconComponent = isCustom ? CustomIcon : Icon;

  return (
    <IconButtonStyle className={className}>
      <IconComponent type={iconType} onClick={onClick} />
    </IconButtonStyle>
  );
};
