import { AppState } from '../../app-state';
import { KeyValueMap } from '../../common/types/KeyValueMap.type';
import { createSelector } from 'reselect';
import { filter, includes } from 'lodash';
import { PredictionModel } from '../models/prediction-model.model';

export const selectPredictionLabels = (
  state: AppState,
): KeyValueMap<string[]> => state.predictions.labelsToPredictOn;

export const selectCurrentlySelectedLabelGroup = (state: AppState): string =>
  state.predictions.currentlySelectedLabelGroup;

export const selectCurrentLabels = createSelector(
  [selectPredictionLabels, selectCurrentlySelectedLabelGroup],
  (labels, currentLabelGroup) => labels[currentLabelGroup],
);

export const selectLookupModels = (state: AppState): PredictionModel[] =>
  filter(state.predictions.predictionModels, m => m.modelType === 'lookup');

export const selectSelectedLookupModels = createSelector(
  [selectCurrentLabels, selectLookupModels],
  (selectedLabels, models) =>
    models.filter(m => {
      const labelIntersect = selectedLabels.filter(l => includes(m.labels, l));
      return labelIntersect.length > 0;
    }),
);
