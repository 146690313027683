import { config } from '../../config/application.config';
import { EsAnnotatedDoc } from '../../search/models/query-response.model';
import { AnnotatedDocument } from '../models/annotated-document.model';

export function pdfUrl(doc: AnnotatedDocument) {
  return `${config.annotationService.url}/document/${doc.aggregateId}.pdf`;
}

export function documentIsDownloadable(
  doc: EsAnnotatedDoc | AnnotatedDocument,
): boolean {
  // TODO: This fork is temporary in order to support one of our
  // consulting projects and should be removed once that project is completed
  if (isEsAnnotatedDoc(doc)) {
    return doc.document_set !== 'q-proj-1';
  } else {
    return doc.documentSetName !== 'q-proj-1';
  }
}

function isEsAnnotatedDoc(doc: object): doc is EsAnnotatedDoc {
  return 'document_set' in doc;
}
