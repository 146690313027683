export type Export = {
  id: number;
  title: string;
  params: ExportParams;
};

export type ExportParams = {
  output: string;
  strategy: string;
  limit: number;
};

export type ExportDownloadResponse = {
  status: number;
};

export type ExportProgress = {
  status: string;
  total: number;
  current: number;
};

export type ExportStatus = {
  error: string | null;
  pending: boolean;
  completed: boolean;
  progress: ExportProgress;
  downloading: boolean;
};

export type ExportJob = {
  jobId: string;
  createdAt: Date;
  externalJobId: string | null;
  query: object;
  params: ExportParams;
  status: ExportStatus;
};

export const defaultExportProgress: ExportProgress = {
  status: 'PROGRESS',
  total: 1,
  current: 0,
};
export const defaultExportStatus: ExportStatus = {
  error: null,
  pending: true,
  completed: false,
  progress: defaultExportProgress,
  downloading: false,
};

export function exportJob(
  jobId: string,
  query: object,
  params: ExportParams,
): ExportJob {
  return {
    jobId,
    createdAt: new Date(),
    externalJobId: null,
    query,
    params,
    status: defaultExportStatus,
  };
}
