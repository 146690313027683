import { Dictionary } from 'lodash';
import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { AppState } from '../../app-state';
import { authHeaders } from '../../common/utils/fetch';
import { config } from '../../config/application.config';

export interface DocumentTextResource {
  annotatedDocumentId: number;
  documentText: {
    aggregateId: string;
    createdAt: string;
    docImageKey: string;
    dockImageBucket: string;
    documentTextId: number;
    ocrVersion: string;
    documentText: {
      full_text: string;
      ocr_date: string;
      ocr_service_version: string;
      resolution: number;
      scaled_height: number;
      scaled_width: number;
      pages: Dictionary<DocumentPageResource>;
    };
  };
}

export interface DocumentPageResource {
  text: string;
  ocrx_offsets: number[];
  ocrx_polys: number[][][];
}

export const DocumentTextResource = {
  baseUrl: config.annotationService.url,
  postAnnotationsUrl: `${config.annotationService.url}/annotations`,

  getDocumentTextUrl(documentId: number): string {
    return `${this.baseUrl}/document/${documentId}/text`;
  },

  get(state: AppState, documentId: number): Observable<DocumentTextResource> {
    return ajax.getJSON<DocumentTextResource>(
      this.getDocumentTextUrl(documentId),
      authHeaders(state),
    );
  },
};
