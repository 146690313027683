import React from 'react';

interface ScrollAreaProps {
  defaultScrollTop: number;
  onScrollTopChange: (pos: number) => any;
}
export class ScrollArea extends React.PureComponent<ScrollAreaProps> {
  private _elm: HTMLDivElement | null = null;

  componentWillReceiveProps(newProps: ScrollAreaProps) {
    if (
      this._elm &&
      newProps.defaultScrollTop !== this.props.defaultScrollTop
    ) {
      this._elm.scrollTop = newProps.defaultScrollTop;
    }
  }

  set elm(elm: any) {
    if (elm !== null && elm !== this._elm) {
      this._elm = elm;
      elm.addEventListener('scroll', this.updateScroll);
    }
  }

  updateScroll = (e: any) => {
    const scrollTop = e.target.scrollTop;
    this.props.onScrollTopChange(scrollTop);
  };

  render() {
    return (
      <div
        ref={ref => (this.elm = ref)}
        style={{
          overflow: 'scroll',
          height: '100%',
          width: '100%',
        }}
      >
        {this.props.children}
      </div>
    );
  }
}
