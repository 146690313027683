import React from 'react';
import { withSize } from 'react-sizeme';
import styled from 'styled-components';
import { AnnotationTable } from '../../annotations/containers/annotation-table.container';
import { ComponentSize } from '../../common/types/ComponentSize';
import { AnnotationHotKeys } from '../../annotations/containers/annotation-hotkeys.container';

const InnerPanel = styled.div`
  margin: 0px;
  width: 100%;
  height: 100%;
`;

const PaneContent = styled.div`
  width: 100%;
  height: 100%;
`;

const ScrollableContent = styled(PaneContent)`
  overflow: scroll;
`;

type AnnotationTableProps = {
  size?: ComponentSize;
};

const MeasuredAnnotationTablePanel = withSize()(AnnotationTable);

export const AnnotationTablePanel = ({ size }: AnnotationTableProps) => (
  <InnerPanel>
    <AnnotationHotKeys />
    <ScrollableContent>
      <MeasuredAnnotationTablePanel />
    </ScrollableContent>
  </InnerPanel>
);
