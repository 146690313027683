import {
  CopyOutlined,
  ExclamationOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import shortNumber from 'short-number';
import styled from 'styled-components';
import { isNull, isNumber } from 'util';
import { Queue } from '../models/queue.model';
import { QueueStatusResponse } from '../resources/queues.resource';

type QueueSummaryProps = {
  queue: Queue;
  queueStatus: Partial<QueueStatusResponse> | null;
};

const QueueSummaryDiv = styled.div`
  min-width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Left = styled.span`
  flex: 1;
  justify-self: flex-start;
  text-align: left;
`;

const Right = styled.span`
  flex: 1;
  justify-self: flex-end;
  text-align: right;
`;

type RemainingDocsProps = {
  remainingDocs: null | undefined | number;
  totalDocs: null | undefined | number;
};

const DocLoadFailureWarning = () => (
  <span>
    There was a problem loading the
    <br /> document counts. Try again later.
  </span>
);

const CountExplaination = (remaining: number, total: number) => (
  <span>
    remaining / total: <br />
    {`${remaining.toLocaleString()} / ${total.toLocaleString()}`}
  </span>
);

const RemainingDocs: React.SFC<RemainingDocsProps> = (
  props: RemainingDocsProps,
) => {
  let Display;
  if (isNumber(props.remainingDocs) && isNumber(props.totalDocs)) {
    Display = (
      <Tooltip title={CountExplaination(props.remainingDocs, props.totalDocs)}>
        <span>
          {`${shortNumber(props.remainingDocs)} / ${shortNumber(
            props.totalDocs,
          )}`}
        </span>
      </Tooltip>
    );
  } else if (isNull(props.remainingDocs)) {
    Display = (
      <Tooltip title={DocLoadFailureWarning()}>
        <ExclamationOutlined style={{ color: 'red' }} />
      </Tooltip>
    );
  } else {
    Display = <LoadingOutlined />;
  }
  return (
    <>
      <CopyOutlined style={{ marginRight: '5px' }} />
      {Display}
    </>
  );
};

export class QueueSummary extends React.PureComponent<QueueSummaryProps> {
  render() {
    const { queue, queueStatus } = this.props;
    return (
      <QueueSummaryDiv>
        <Left>{queue.queueName}</Left>
        <Right>
          <RemainingDocs
            remainingDocs={
              isFailure(queueStatus) ? null : queueStatus.remainingItems
            }
            totalDocs={isFailure(queueStatus) ? null : queueStatus.totalItems}
          />
        </Right>
      </QueueSummaryDiv>
    );
  }
}

function isFailure(
  queueStatus: Partial<QueueStatusResponse> | null,
): queueStatus is null {
  return isNull(queueStatus);
}
