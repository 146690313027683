import {
  DomainLookup,
  DomainLookupValue,
} from '../../domain/models/lookup-context.model';
import { Annotation } from '../../annotations/models/annotation.model';
import { find, flatMap, get, map } from 'lodash';
import { Prediction } from './predictions.model';
import { AnnotationRelation } from '../../annotations/models/annotation-relation.model';
import { distinctOn } from '../../common/utils/distinctOn';
import uuid from 'uuid/v4';
import { Map } from 'immutable';

export type PredictionModel = {
  modelId: number;
  projectId: string;
  modelName: string;
  modelType: string;
  modelConfig: LookupPredictionModelConfig | {};
  labels: string[];
  groupId: number;
  documentType: string;
  createdAt: string;
  updatedAt?: string;
};

export type LookupPredictionModelConfig = {
  lookup: string;
  rootAnnotationType: string;
};

export const PredictionModel = {
  predict(
    lookups: DomainLookup[],
    models: PredictionModel[],
    lookupValues: DomainLookupValue[],
    annotations: Map<string, Annotation>,
    relations: Map<string, AnnotationRelation>,
  ): Prediction[][] {
    const predictions = flatMap(models, model =>
      this.predictWithModel(
        lookups,
        model,
        lookupValues,
        annotations,
        relations,
      ),
    );
    return map(predictions, ps =>
      distinctOn(ps, p => `${p.label} ${p.value} ${p.parentAnnotationId}`),
    );
  },

  predictWithModel(
    lookups: DomainLookup[],
    model: PredictionModel,
    lookupValues: DomainLookupValue[],
    annotations: Map<string, Annotation>,
    relations: Map<string, AnnotationRelation>,
  ): Prediction[][] {
    const modelConfig = model.modelConfig as LookupPredictionModelConfig;
    const lookup = find(lookups, l => l.name === modelConfig.lookup);
    if (!lookup) {
      return [];
    }
    const targetAnnotations = Array.from(annotations.values()).filter(
      a => a.type === modelConfig.rootAnnotationType,
    );
    const annotationPaths = flatMap(targetAnnotations, a =>
      map(Annotation.getChildren(a.id, annotations, relations), c =>
        [a].concat(c),
      ),
    );
    const predictions = flatMap(annotationPaths, p =>
      this.predictLookup(lookup, lookupValues, p),
    );
    return predictions;
  },

  predictLookup(
    lookup: DomainLookup,
    lookupValues: DomainLookupValue[],
    annotationPath: Annotation[],
  ): Prediction[][] {
    const value =
      DomainLookup.findLookupValue(lookup, lookupValues, annotationPath)
        ?.value || {};
    const aliases = get(value, 'aliases') as object[];
    const parentId = annotationPath[0]?.id || null;
    const predictions = map(aliases, a => toPredictions(a, parentId));
    return predictions;
  },
};

function toPredictions(value: Object, parentId: string | null): Prediction[] {
  const predictions = Object.keys(value).map(k =>
    toPrediction(k, value[k], parentId),
  );
  return predictions;
}

function toPrediction(k: string, v: string, p: string | null): Prediction {
  const now = new Date();
  const nowIso = now.toISOString();
  const uuid1 = parseInt(uuid().replace('-', ''), 16);
  return {
    predictionId: undefined,
    uuid: uuid1,
    userSpecific: true,
    documentTextId: 0,
    label: k,
    startOffset: 0,
    endOffset: 0,
    nativeText: v,
    value: v,
    requestedAt: nowIso,
    createdAt: nowIso,
    updatedAt: nowIso,
    annotationId: null,
    parentAnnotationId: p,
  };
}
