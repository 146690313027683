import createFlags from 'flag';
import { createReduxBindings } from 'flag/redux';

export type AppFlags = {
  features: {
    queues: boolean;
    manage: boolean;
    search: boolean;
    admin: boolean;
  };
  layout: {
    dragAndDrop: boolean;
  };
};

const { FlagsProvider, Flag, useFlag, useFlags } = createFlags<AppFlags>();

export { FlagsProvider, Flag, useFlag, useFlags };

const {
  setFlagsAction,
  getFlagsSelector,
  createFlagsReducer,
  ConnectedFlagsProvider,
} = createReduxBindings(FlagsProvider);

export {
  setFlagsAction,
  getFlagsSelector,
  createFlagsReducer,
  ConnectedFlagsProvider,
};
