import { createAction } from 'typesafe-actions';
import { AnnotationReview } from '../models/annotation-review.model';

enum Types {
  FETCH_ANNOTATION_SAVE_EVENT_REVIEW = 'FETCH_ANNOTATION_SAVE_EVENT_REVIEW',
  SET_REVIEWING_SAVE_EVENT_ID = 'SET_REVIEWING_SAVE_EVENT_ID',
  SET_ANNOTATION_REVIEW_STATUS = 'SET_ANNOTATION_REVIEW_STATUS',
  POST_ANNOTATION_REVIEW = 'POST_ANNOTATION_REVIEW',
  POST_ANNOTATION_REVIEW_SUCCESS = 'POST_ANNOTATION_REVIEW_SUCCESS',
}

export const fetchSaveEventReview = createAction(
  Types.FETCH_ANNOTATION_SAVE_EVENT_REVIEW,
  resolvePayload => (saveEventId: number) => resolvePayload({ saveEventId }),
);

export const setReviewingSaveEventId = createAction(
  Types.SET_REVIEWING_SAVE_EVENT_ID,
  resolvePayload => (saveEventId: number | null) =>
    resolvePayload({ saveEventId }),
);

export const setAnnotationReviewStatus = createAction(
  Types.SET_ANNOTATION_REVIEW_STATUS,
  resolvePayload => (completed: boolean) => resolvePayload({ completed }),
);

export const postAnnotationReview = createAction(
  Types.POST_ANNOTATION_REVIEW,
  resolvePayload => (saveEventId: number, completed: boolean) =>
    resolvePayload({ saveEventId, completed }),
);

export const postAnnotationReviewSuccess = createAction(
  Types.POST_ANNOTATION_REVIEW_SUCCESS,
  resolvePayload => (review: AnnotationReview) => resolvePayload({ review }),
);
