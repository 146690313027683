import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { AppState } from '../../app-state';
import { authHeaders } from '../../common/utils/fetch';
import { config } from '../../config/application.config';
import { Predictions } from '../models/predictions.model';

export const SavedPredictionResource = {
  baseUrl: config.annotationService.url,

  getPredictionsUrl(
    documentTextId: number,
    saveEventTimestamp: number | null,
  ): string {
    const resolvedSaveEventTimestampParam = saveEventTimestamp
      ? `?last_save_event_timestamp=${saveEventTimestamp}`
      : '';
    return `${this.baseUrl}/predictions/${documentTextId}${resolvedSaveEventTimestampParam}`;
  },

  get(
    state: AppState,
    documentTextId: number,
    saveEventTimestamp: number | null,
  ): Observable<Predictions> {
    return ajax.getJSON<Predictions>(
      this.getPredictionsUrl(documentTextId, saveEventTimestamp),
      authHeaders(state),
    );
  },
};
