import Modal from 'antd/lib/modal';
import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../app-state';
import { ErrorColor } from '../../common/colors';
import { LoginFormContainer } from './login-form.container';

type LoginModalProps = {
  visible: boolean;
};
export function LoginModalComponent(props: LoginModalProps) {
  return (
    <Modal visible={props.visible} footer={null} closable={false}>
      <div style={{ color: ErrorColor, padding: '0 0 8px' }}>
        Your authentication has expired
      </div>
      <h2>Please Login to Continue</h2>
      <LoginFormContainer redirectOnAuthenticate={false} />
    </Modal>
  );
}

export const LoginModal = connect((state: AppState) => ({
  visible: state.auth.showLoginModal,
}))(LoginModalComponent);
