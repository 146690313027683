import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Observable, of } from 'rxjs';
import { AjaxError, AjaxResponse } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';
import { createUserAccount } from '../../accounts/actions/users.actions';
import { ExternalGroup } from '../../accounts/models/external-group.model';
import {
  ExternalGroupsResource,
  GroupsResource,
} from '../../accounts/resources/groups.resource';
import { UsersResource } from '../../accounts/resources/users.resource';
import { selectAllGroupsArray } from '../../accounts/selectors/groups.selectors';
import { selectUsersAreLoading } from '../../accounts/selectors/users.selectors';
import { AddNewUserPayload } from '../../admin/models/add-new-user-payload.model';
import { AppState } from '../../app-state';
import CardWithTitle from '../../common/components/card-with-title.component';
import { useFetchedState } from '../../common/hooks/use-fetched-state.hook';
import { CreateUserFormComponent } from '../components/create-user-form.component';

type CreateUserContainerProps = {
  groups: ExternalGroup[];
  createAccount: (payload: AddNewUserPayload) => void;
  isSubmitting: boolean;
  getGroups: Observable<ExternalGroupsResource>;
  isCheckingUsername: (payload: { username: string }) => Observable<string>;
  isCheckingEmail: (payload: { email: string }) => Observable<string>;
};

const SourceCheckSuffix = (
  source: string,
  input: string,
  isLoading: boolean,
  checkResults: AjaxResponse['response'] | null,
): JSX.Element => {
  if (input !== '') {
    if (isLoading) {
      return <LoadingOutlined />;
    }
    if (checkResults.success) {
      return (
        <span style={{ color: 'green' }}>
          <CheckCircleOutlined /> {source} is available!
        </span>
      );
    }
    if (checkResults.error) {
      return (
        <span style={{ color: 'red' }}>
          <CloseCircleOutlined /> {source} already exists!
        </span>
      );
    }
  }
  return <></>;
};

const CreateUserContainer: React.FC<CreateUserContainerProps> = ({
  createAccount,
  isSubmitting,
  getGroups,
  isCheckingUsername,
  isCheckingEmail,
}) => {
  const addNewUserAccount = (form: any) => {
    const postUserObj: AddNewUserPayload = {
      username: form.username,
      firstName: form.first_name,
      lastName: form.last_name,
      groupIds: form.groups,
      email: form.email,
    };

    createAccount(postUserObj);
  };
  const [externalGroups] = useFetchedState(getGroups, []);
  const [username, setUsername] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [
    doesUserExist,
    isLoadingUsername,
  ] = useFetchedState(isCheckingUsername({ username }), [username]);
  const [doesEmailExist, isLoadingEmail] = useFetchedState(
    isCheckingEmail({ email: userEmail }),
    [userEmail],
  );

  return (
    <>
      <CardWithTitle
        title="Create User"
        style={{ margin: '1em 1em 0 1em', minHeight: '90vh' }}
      >
        <CreateUserFormComponent
          usernameCheckResults={SourceCheckSuffix(
            'Username',
            username,
            isLoadingUsername,
            doesUserExist,
          )}
          emailCheckResults={SourceCheckSuffix(
            'Email',
            userEmail,
            isLoadingEmail,
            doesEmailExist,
          )}
          userEmailState={[userEmail, setUserEmail]}
          usernameState={[username, setUsername]}
          isSubmitting={isSubmitting}
          groups={externalGroups ? externalGroups.groups : null}
          addNewUserAccount={addNewUserAccount}
        />
      </CardWithTitle>
    </>
  );
};

export const CreateUser = connect(
  (state: AppState) => ({
    groups: selectAllGroupsArray(state),
    getGroups: GroupsResource.getAllExternal(state),
    isSubmitting: selectUsersAreLoading(state),
    isCheckingUsername: (payload: { username: string }) =>
      UsersResource.checkUsername(state, payload).pipe(
        map((response: AjaxResponse) => response.response),
        catchError((response: AjaxError) => of(response.response)),
      ),
    isCheckingEmail: (payload: { email: string }) =>
      UsersResource.checkEmail(state, payload).pipe(
        map((response: AjaxResponse) => response.response),
        catchError((response: AjaxError) => of(response.response)),
      ),
  }),
  dispatch => ({
    createAccount: (payload: AddNewUserPayload) =>
      dispatch(createUserAccount.request(payload)),
  }),
)(CreateUserContainer);
