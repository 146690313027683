import React from 'react';
import AnnotationListDropdown from '../../annotations/containers/annotation-list-dropdown.container';
import { AnnotationSchema } from '../../annotations/models/annotation-schema.model';
import { AnnotationType } from '../../annotations/models/annotation-type.model';
import { ColumnApi, RowNode } from 'ag-grid-community';
import { find } from 'lodash';
import styled from 'styled-components';

const AnnotationListWrapper = styled.div`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  .ant-tree {
    max-height: 10vh;
  }
`;

export type AnnotationTypeEditorProps = {
  node: RowNode;
  value: AnnotationType;
  allSchemas: AnnotationSchema[];
  schema: AnnotationSchema | null;
  onAnnotationTypeSelected: (type: AnnotationType) => void;
  onSelectSchema: (schema: AnnotationSchema) => void;
  onClose?: () => void;
  stopEditing: (suppressNavigateAfterEdit?: boolean) => void;
  columnApi: ColumnApi;
};
export const AnnotationTypeEditor = React.forwardRef(
  (props: AnnotationTypeEditorProps, ref) => {
    const [value, setValue] = React.useState<string>();
    const [visible, setVisible] = React.useState(false);

    const cell = find(
      props.columnApi.getColumnState(),
      c => c.colId === 'ag-Grid-AutoColumn',
    );
    const cellWidth = cell?.width;

    function close() {
      if (props.onClose) {
        props.onClose();
      }
      props.stopEditing();
    }

    React.useImperativeHandle(ref, () => {
      return {
        isPopup: () => true,
        getValue: () => value || props.value,
        afterGuiAttached: () => setVisible(true),
      };
    });

    React.useEffect(() => {
      if (value) {
        close();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
      <AnnotationListWrapper style={{ width: cellWidth }}>
        <AnnotationListDropdown
          visible={visible}
          refocusWhenVisible={true}
          schema={props.schema}
          onAnnotationTypeSelected={at => setValue(at.key)}
          onSelectSchema={props.onSelectSchema}
          onClose={close}
          schemaEditorDisabled={true}
        ></AnnotationListDropdown>
      </AnnotationListWrapper>
    );
  },
);
