import { empty } from 'rxjs';
import { AjaxError } from 'rxjs/internal-compatibility';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AppEpic } from '../../common/types/app-epic.type';
import { handleEpicError } from '../../common/utils/epics';
import { fetchQueues, populateQueues } from '../actions/queue.actions';
import { QueuesResource } from '../resources/queues.resource';

export const loadQueuesEpic: AppEpic = (actions$, state$) => {
  return actions$.pipe(
    filter(isActionOf(fetchQueues)),
    switchMap(() => QueuesResource.getAll(state$.value)),
    map(queues => populateQueues(queues)),
    catchError((e: AjaxError) => {
      // if a user doesn't have any queue permissions (like search only users)
      // we don't want to show a superfluous warning
      if (e.status === 403) {
        return empty();
      } else {
        return handleEpicError('Error fetching queues')(e);
      }
    }),
  );
};
