import CsvUpload from '../../common/containers/csv-upload.container';
import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../app-state';
import { LookupResource } from '../resources/lookup.resource';
import { fetchLookups } from '../actions/lookup-context.actions';
import { PermissionResources } from '../../accounts/models/permission.model';

export type DomainLookupUploadDispatchProps = {
  fetchLookups: () => unknown;
};

export type DomainLookupUploadProps = {
  lookupId: number;
  keyColumns: string[];
  valueColumns: string[];
} & DomainLookupUploadDispatchProps;

export const DomainLookupUpload = (props: DomainLookupUploadProps) => {
  const expectedColumns = props.keyColumns.concat(props.valueColumns);
  return (
    <CsvUpload
      expectedColumns={expectedColumns}
      uploadUrl={LookupResource.uploadLookupUrl(props.lookupId)}
      onStateChange={props.fetchLookups}
      resource={PermissionResources.DomainLookup}
      resourceId={props.lookupId}
    />
  );
};

export default connect((_: AppState) => ({}), { fetchLookups })(
  DomainLookupUpload,
);
