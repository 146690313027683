import jwt_decode from 'jwt-decode';

/**
 * Verifies an auth token exists and is not expired.
 *
 * @export
 * @param {(string | null)} [token=null]
 * @returns {boolean}
 */
export function tokenValid(token: string | null = null): boolean {
  return !!token && !tokenExpired(token);
}

export function decode(token: string): any {
  return jwt_decode(token);
}

export function getUsername(token: string): string {
  return jwt_decode<{ sub: string }>(token).sub;
}

/**
 * Checks if the passed auth token expired.
 * Returns "true" is expired, "false" otherwise.
 *
 * @export
 * @param {string} token
 * @returns {boolean}
 */
export function tokenExpired(token: string): boolean {
  const { exp } = jwt_decode(token);
  return Date.now() / 1000 > exp;
}
