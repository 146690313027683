import { sortBy } from 'lodash';
import { getType } from 'typesafe-actions';
import { populateAvailableDocumentStatuses } from '../actions/available-document-status.actions';
import { AvailableDocumentStatusActions } from '../actions/available-document-status.types';
import { DocumentStatus } from '../resources/available-document-statuses.resource';

export type AvailableDocumentStatusState = DocumentStatus[];
const defaultState: AvailableDocumentStatusState = [];

export function availableDocumentStatusReducer(
  state: AvailableDocumentStatusState = defaultState,
  action: AvailableDocumentStatusActions,
): AvailableDocumentStatusState {
  switch (action.type) {
    case getType(populateAvailableDocumentStatuses): {
      return sortBy(action.payload.availableStatuses, a =>
        a.status.toLowerCase(),
      );
    }
    default:
      return state;
  }
}
