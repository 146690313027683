import React from 'react';
import { ScrollArea } from './scroll-area.component';

export type PageScrollerDispatchProps = {
  onPageChange: (page: number) => any;
};

export type PageScrollerProps = {
  page: number;
  pageOffsets: number[];
} & PageScrollerDispatchProps;

interface ScrollableAnnotationPaneState {
  scrollTop: number;
  currentPage: number;
}

// Creates a scrollable continuous page content area.
// Handles updating scroll & page position.
export class PageScroller extends React.PureComponent<
  PageScrollerProps,
  ScrollableAnnotationPaneState
> {
  state = {
    scrollTop: 0,
    currentPage: 1,
  };

  private readonly PAGE_OFFSET_TOLERANCE = 50;

  componentWillReceiveProps(props: PageScrollerProps) {
    if (props.page !== this.state.currentPage) {
      const scrollTop = props.pageOffsets[props.page - 1];

      if (typeof scrollTop === 'number') {
        this.setState({ scrollTop });
      }
    }
  }

  updateScroll = (scrollTop: number) => {
    const page = this.pageAt(scrollTop);

    if (page !== this.state.currentPage) {
      this.setState((state, props) => {
        this.props.onPageChange(page);
        return { currentPage: page, scrollTop };
      });
    }
  };

  pageAt(scrollPos: number) {
    const pageOffsets = this.props.pageOffsets;
    for (let i = pageOffsets.length; i >= 0; i--) {
      if (scrollPos >= pageOffsets[i] - this.PAGE_OFFSET_TOLERANCE) {
        return i + 1;
      }
    }

    return 1;
  }

  render() {
    return (
      <ScrollArea
        defaultScrollTop={this.state.scrollTop}
        onScrollTopChange={this.updateScroll}
      >
        {this.props.children}
      </ScrollArea>
    );
  }
}
