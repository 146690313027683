import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { AppState } from '../../app-state';
import { authHeaders } from '../../common/utils/fetch';
import { config } from '../../config/application.config';

export type SourceDocumentUrlResource = {
  annotatedDocumentId: string;
  url: string;
};

export const SourceDocumentUrlResource = {
  getUrl(annotatedDocumentId: number) {
    return `${config.annotationService.url}/document/${annotatedDocumentId}/url`;
  },

  get(
    state: AppState,
    annotatedDocumentId: number,
  ): Observable<SourceDocumentUrlResource> {
    return ajax.getJSON<SourceDocumentUrlResource>(
      this.getUrl(annotatedDocumentId),
      authHeaders(state),
    );
  },
};
