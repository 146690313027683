import { getType } from 'typesafe-actions';
import { KeyValueMap } from '../../common/types/KeyValueMap.type';
import { closeDocument } from '../../documents/actions/source-document.actions';
import { SourceDocumentActions } from '../../documents/actions/source-document.types';
import { populateQueues, setCurrentQueueItem } from '../actions/queue.actions';
import { QueueActions } from '../actions/queue.types';
import { QueueItem } from '../models/queue-item.model';
import { Queue } from '../models/queue.model';

export const defaultQueueState: QueueState = {
  entities: {},
  currentQueueItem: null,
};

export type QueueState = {
  entities: KeyValueMap<Queue>;
  currentQueueItem: null | QueueItem;
};

export function queuesReducer(
  state: QueueState = defaultQueueState,
  action: QueueActions | SourceDocumentActions,
): QueueState {
  switch (action.type) {
    case getType(populateQueues):
      return {
        ...state,
        entities: KeyValueMap.usingPropertyKey(
          action.payload.queues,
          'queueId',
        ),
      };
    case getType(setCurrentQueueItem):
      return {
        ...state,
        currentQueueItem: action.payload.item,
      };
    case getType(closeDocument):
      return {
        ...state,
        currentQueueItem: null,
      };
    default:
      return state;
  }
}
