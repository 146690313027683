import { createAction } from 'typesafe-actions';
import { HighlightCollection } from '../../text-highlighter/models/highlight-collection.model';
import { AnnotationSelection } from '../models/annotation-selection.model';
import { Annotation } from '../models/annotation.model';
import { AnnotationSelectionContext } from '../reducers/ui-annotations.reducer';

enum Types {
  SCROLL_ANNOTATION_INTO_VIEW = 'SCROLL_ANNOTATION_INTO_VIEW',
  EMPHASIZE_ANNOTATION_HIGHLIGHT = 'EMPHASIZE_ANNOTATION_HIGHLIGHT',
  REMOVE_ANNOTATION_HIGHLIGHT_EMPHASIS = 'REMOVE_ANNOTATION_HIGHLIGHT_EMPHASIS',
  BEGIN_ANNOTATION_EDITING_MODE = 'BEGIN_ANNOTATION_EDITING',
  END_ANNOTATION_EDITING_MODE = 'END_ANNOTATION_EDITING',
  SET_ANNOTATION_SELECTION = 'SET_ANNOTATION_SELECTION',
  CLEAR_ANNOTATION_SELECTION = 'CLEAR_ANNOTATION_SELECTION: Clears selection data from state store',
  REMOVE_ANNOTATION_SELECTION = 'REMOVE_ANNOTATION_SELECTION: Removes selection in app, as a side effect',
  SET_ANNOTATION_HIGHLIGHTS = 'SET_ANNOTATION_HIGHLIGHTS: Sets the current highlight collection *created* within the text-highlight component. This action should only be fired by the annotation viewer container to be used externally.',
  SET_ANNOTATION_CATEGORY = 'Set the annotation category visible in the annotation popover.',
  SAVE_BUTTON_CLICKED = 'SAVE_BUTTON_CLICKED',
  SET_FOCUSED_ANNOTATION_ID = 'SET_FOCUSED_ANNOTATION_ID',
}

export const scrollAnnotationIntoView = createAction(
  Types.SCROLL_ANNOTATION_INTO_VIEW,
  resolvePayload => (annotationId: string) => resolvePayload({ annotationId }),
);

export const emphasizeAnnotationHighlight = createAction(
  Types.EMPHASIZE_ANNOTATION_HIGHLIGHT,
  resolvePayload => (annotationId: string) => resolvePayload({ annotationId }),
);

export const removeAnnotationHighlightEmphasis = createAction(
  Types.REMOVE_ANNOTATION_HIGHLIGHT_EMPHASIS,
  resolvePayload => (annotationId: string) => resolvePayload({ annotationId }),
);

export const beginAnnotationEditing = createAction(
  Types.BEGIN_ANNOTATION_EDITING_MODE,
  resolvePayload => (annotation: Annotation) => resolvePayload({ annotation }),
);

export const endAnnotationEditing = createAction(
  Types.END_ANNOTATION_EDITING_MODE,
);

export type SetAnnotationActionPayload = {
  selection: AnnotationSelection[];
  context: AnnotationSelectionContext;
};

export const setAnnotationSelection = createAction(
  Types.SET_ANNOTATION_SELECTION,
  resolvePayload => (
    selection: AnnotationSelection[],
    context: AnnotationSelectionContext,
  ) => resolvePayload({ selection, context } as SetAnnotationActionPayload),
);

export const clearAnnotationSelection = createAction(
  Types.CLEAR_ANNOTATION_SELECTION,
);

export const setAnnotationHighlights = createAction(
  Types.SET_ANNOTATION_HIGHLIGHTS,
  resolvePayload => (highlights: HighlightCollection) =>
    resolvePayload({ highlights }),
);

export const saveButtonClicked = createAction(Types.SAVE_BUTTON_CLICKED);

export const setFocusedAnnotationId = createAction(
  Types.SET_FOCUSED_ANNOTATION_ID,
  resolvePayload => (annotationId: string | null) =>
    resolvePayload({ annotationId }),
);
