import { EditOutlined } from '@ant-design/icons';
import Tooltip from 'antd/lib/tooltip';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { AppState } from '../../app-state';
import { SecondaryButton } from '../../common/components/secondary-button.component';
import spinner from '../../common/images/Loader-small.svg';
import { upgradeSourceDocumentLock } from '../../documents/actions/source-document.actions';
import { DOCUMENT_LOCK_STATUS } from '../../documents/models/document-lock-status.model';
import { selectSourceDocumentLockStatus } from '../../documents/selectors/document-lock.selectors';
import { selectActiveSourceDocument } from '../../documents/selectors/source-document.selectors';

const SpinnerImg = styled.img`
  width: 34px;
  margin-left: -12px;
  margin-top: -10px;
  margin-bottom: -10px;
`;

const tooltipText = 'Click to begin annotating.';

export const EditDocumentButton = connect(
  (state: AppState) => ({
    disabled: !selectActiveSourceDocument(state),
    showSpinner:
      selectSourceDocumentLockStatus(state) === DOCUMENT_LOCK_STATUS.PENDING,
  }),
  dispatch => ({
    onClick: () => dispatch(upgradeSourceDocumentLock()),
  }),
)((props: any) => {
  return (
    <Tooltip title={tooltipText}>
      <SecondaryButton
        disabled={props.disabled}
        onClick={() => props.onClick()}
        style={{ lineHeight: '1.4' }}
      >
        <span>
          {props.showSpinner ? (
            <SpinnerImg src={spinner} />
          ) : (
            <EditOutlined style={{ marginRight: '8px' }} />
          )}
          Edit Document
        </span>
      </SecondaryButton>
    </Tooltip>
  );
});
