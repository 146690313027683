import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { AppState } from '../../app-state';
import { authHeaders } from '../../common/utils/fetch';
import { config } from '../../config/application.config';
import { PredictionModel } from '../models/prediction-model.model';
import { map as mapObservable } from 'rxjs/operators';

export const PredictionModelResource = {
  baseUrl: config.annotationService.url,

  getPredictionModelsUrl(): string {
    return `${this.baseUrl}/prediction-models`;
  },

  getPredictionModels(state: AppState): Observable<PredictionModel[]> {
    return ajax
      .getJSON<{ predictionModels: PredictionModel[] }>(
        this.getPredictionModelsUrl(),
        authHeaders(state),
      )
      .pipe(mapObservable(r => r.predictionModels));
  },
};
