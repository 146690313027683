import { PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Modal, Select } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import React, { useState } from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '../../common/components/primary-button.component';
import { SecondaryButton } from '../../common/components/secondary-button.component';
import { validateForm } from '../../common/utils/validateForm';

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
`;

type LookupCreatorRawFormValues = {
  name: string;
  lookupType: string;
};

export type LookupCreatorFormValues = {
  name: string;
  lookupType: string;
  groupId: number;
};

export type LookupCreatorProps = {
  currentUserGroupId: number | null;
  onLookupCreate: (v: LookupCreatorFormValues) => unknown;
} & FormComponentProps;

// This is necessary to correct an error message from Ant's Form.create
const AntInput = React.forwardRef((props, _ref) => <Input {...props} />);
const typeOptions = [{ value: 'alias' }, { value: 'validation' }];
const AntSelect = React.forwardRef((props, _ref) => (
  <Select options={typeOptions} {...props} />
));

const { Item } = Form;
const LookupCreatorForm: React.FC<LookupCreatorProps> = ({
  form,
  currentUserGroupId,
  onLookupCreate,
}) => {
  const createLookupFunc = () =>
    validateForm<LookupCreatorRawFormValues>(
      form,
      v =>
        onLookupCreate({
          name: v.name,
          lookupType: v.lookupType,
          groupId: currentUserGroupId || 0,
        }),
      true,
    );
  const createLookup = React.useMemo(createLookupFunc, [currentUserGroupId]);
  const [showModal, setModalView] = useState(false);
  const { getFieldDecorator } = form;

  return (
    <>
      <SecondaryButton onClick={() => setModalView(true)}>
        <PlusOutlined />
        Add Lookup
      </SecondaryButton>
      <Modal
        title="Create Lookup"
        visible={showModal}
        footer={null}
        onCancel={() => setModalView(false)}
        destroyOnClose={true}
      >
        <Form onSubmit={createLookup}>
          <Item label="Name">
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: 'Lookup name is required.',
                },
              ],
            })(<AntInput />)}
          </Item>
          <Item label="Type">
            {getFieldDecorator('lookupType', {
              rules: [
                {
                  required: true,
                  message: 'Lookup type is required.',
                },
              ],
            })(<AntSelect />)}
          </Item>
          <Right>
            <PrimaryButton
              onClick={() => setModalView(false)}
              htmlType="submit"
            >
              Create Lookup
            </PrimaryButton>
          </Right>
        </Form>
      </Modal>
    </>
  );
};

export const LookupCreatorComponent = Form.create<LookupCreatorProps>()(
  LookupCreatorForm,
);
