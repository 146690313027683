import { flatMap } from 'lodash';
import { RuleOrCondition } from '../relation-types';
import { RelationViolation } from '../relation-violation.model';
import { Relation } from '../relation.model';
import { getValidator } from './validators';
import { Map } from 'immutable';

export function validate<U, T>(
  entities: Map<string, U> | U[],
  getRules: (entity: U) => RuleOrCondition<T>[],
  getRelations: (entity: U) => Relation<T, U>[],
  compare: (entity: U, type: T) => boolean,
): RelationViolation<U, T>[] {
  const ents = entities ? Array.from(entities.values()) : [];
  return flatMap<U, RelationViolation<U, T>>(ents, ent =>
    validateOne<U, T>(ent, getRules(ent), getRelations(ent), compare),
  );
}

export function validateOne<U, T>(
  entity: U,
  rule: RuleOrCondition<T>[],
  entityRelations: Relation<T, U>[],
  compare: (entity: U, type: T) => boolean,
): RelationViolation<U, T>[] {
  const violations = flatMap(rule, r =>
    validateRule<U, T>(entity, r, entityRelations, compare),
  );

  return violations.filter(v => !!v);
}

export function validateRule<U, T>(
  entity: U,
  rule: RuleOrCondition<T>,
  relations: Relation<T, U>[],
  compare: (entity: U, type: T) => boolean,
): RelationViolation<U, T>[] {
  if (!rule.required) {
    return [];
  }

  const validator = getValidator<U, T>(rule.type);

  if (!validator) {
    if (console) {
      console.warn(`Cannot validate rule ${rule.type}, missing validator`);
    }

    return [];
  }

  return validator(entity, rule, relations, compare);
}
