import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { fetchUserAccount } from '../../accounts/actions/users.actions';
import { saveUserSimpleRoles } from '../../accounts/actions/users.actions';
import { Group } from '../../accounts/models/group.model';
import { SimpleRole } from '../../accounts/models/simple-role.model';
import { UserAccount } from '../../accounts/models/user-account.model';
import { createUserAccountByIdSelector } from '../../accounts/selectors/users.selectors';
import { AppState } from '../../app-state';
import { Loader } from '../../common/components/loader.component';
import { AppActions } from '../../root.actions';
import {
  setActiveAdminTab,
  setSelectedGroupAccount,
} from '../actions/ui-admin.actions';
import { UserAccountCardComponent } from '../components/user-account-card.component';
import { AdminTabs } from '../models/admin-tab.model';

interface ConnectedUserCardProps {
  userAccount: UserAccount;
  userId: number;
  updateUser: (userId: number) => any;
  onGroupClick: (group: Group) => any;
  onSaveSimpleRoles: (roles: SimpleRole[]) => any;
}
class WrappedUserCard extends React.Component<ConnectedUserCardProps> {
  componentDidMount() {
    if (!this.props.userAccount) {
      this.props.updateUser(this.props.userId);
    }
  }

  componentWillReceiveProps(newProps: ConnectedUserCardProps) {
    if (!newProps.userAccount && newProps.userId !== this.props.userId) {
      this.props.updateUser(newProps.userId);
    }
  }

  render() {
    if (this.props.userAccount) {
      return <UserAccountCardComponent {...this.props} />;
    } else {
      return <Loader style={{ width: 100 }} />;
    }
  }
}

const selectUserIdFromProps = (_: AppState, props: UserAccountCardProps) =>
  props.userId;

const selectUserAccountById = createUserAccountByIdSelector(
  selectUserIdFromProps,
);

export interface UserAccountCardProps {
  userId: number;
  userAccount?: UserAccount;
}
export const UserAccountCard = connect(
  (state: AppState, ownProps: UserAccountCardProps) => ({
    userAccount: selectUserAccountById(state, ownProps),
  }),
  (dispatch: Dispatch<AppActions>, ownProps: UserAccountCardProps) => ({
    onSaveSimpleRoles: (roles: SimpleRole[]) => {
      dispatch(saveUserSimpleRoles(ownProps.userId, roles));
    },
    updateUser: (userId: number) => dispatch(fetchUserAccount(userId)),
    onGroupClick: (group: Group) => {
      dispatch(setActiveAdminTab(AdminTabs.Groups));
      dispatch(setSelectedGroupAccount(group.groupId));
    },
  }),
)(WrappedUserCard);
