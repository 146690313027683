import React from 'react';
import styled from 'styled-components';
import spinner from '../images/Loader-small.svg';

const SpinnerImg = styled.img`
  width: 34px;
  margin-left: -12px;
  margin-top: -10px;
  margin-bottom: -10px;
`;

export const ButtonSpinner = () => <SpinnerImg src={spinner} />;
