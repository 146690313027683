import { Select } from 'antd';
import React from 'react';
import { US_STATES } from '../data/us-states';
const Option = Select.Option;

interface StateSelectorProps {
  selectedState: string;
  onChange: (value: string) => any;
}
export class StateSelector extends React.Component<StateSelectorProps> {
  render() {
    const selectedState = this.props.selectedState;
    const onChange = this.props.onChange;

    return (
      <Select value={selectedState} onChange={onChange}>
        <Option key="all" value="all">
          All States
        </Option>
        {US_STATES.map(state => (
          <Option key={state} value={state}>
            {state}
          </Option>
        ))}
      </Select>
    );
  }
}
