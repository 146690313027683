import { CheckOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../app-state';
import { SecondaryButton } from '../../common/components/secondary-button.component';
import { completeQueueItem } from '../actions/queue.actions';
import { QueueItem } from '../models/queue-item.model';

type CompleteQueueItemComponentProps = {
  currentQueueItem: QueueItem | null;
  onCompleteQueueItem: (item: QueueItem) => void;
  onClick?: () => void;
  style: React.CSSProperties;
};

const TooltipMessage = (props: { isComplete: boolean }) =>
  props.isComplete ? (
    <span>
      This queue item has been <br /> completed
    </span>
  ) : (
    <span>
      Mark current queue item <br /> as complete
    </span>
  );

class CompleteQueueItemComponent extends React.PureComponent<
  CompleteQueueItemComponentProps
> {
  completeCurrentItem = (item: QueueItem | null) => () => {
    if (item) {
      this.props.onCompleteQueueItem(item);
    }

    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render() {
    const { currentQueueItem, style } = this.props;
    const currentQueueItemCompleted = QueueItem.isComplete(currentQueueItem);
    const commonCheckIconStyles: React.CSSProperties = {
      fontSize: '16px',
      fontWeight: 'bold',
    };
    const checkIconStyles: React.CSSProperties = currentQueueItemCompleted
      ? { color: 'green', ...commonCheckIconStyles }
      : { ...commonCheckIconStyles };
    return (
      <Tooltip
        title={TooltipMessage({
          isComplete: currentQueueItemCompleted,
        })}
      >
        <SecondaryButton
          disabled={currentQueueItemCompleted}
          onClick={this.completeCurrentItem(currentQueueItem)}
          style={{ height: '100%', ...style }}
        >
          <CheckOutlined style={checkIconStyles} />
        </SecondaryButton>
      </Tooltip>
    );
  }
}

export const CompleteQueueItemButton = connect(
  (_: AppState) => ({}),
  dispatch => ({
    onCompleteQueueItem: (item: QueueItem) => {
      dispatch(completeQueueItem(item.queueId, item.queueItemId));
    },
  }),
)(CompleteQueueItemComponent);
