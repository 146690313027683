import React from 'react';
import { AppState } from '../../app-state';
import { connect } from 'react-redux';
import { AnnotationType } from '../models/annotation-type.model';
import { Annotation } from '../models/annotation.model';
import { AnnotationSchema } from '../models/annotation-schema.model';
import { Card, List, Popover } from 'antd';
import { selectActiveAnnotationSchema } from '../selectors/annotation-schema.selectors';
import { setFocusedAnnotationId } from '../actions/ui-annotations.actions';
import { selectAnnotationsArray } from '../selectors/annotation.selectors';
import { some, sortBy } from 'lodash';
import styled from 'styled-components';
import { ColorBox } from '../../common/components/color-box.component';

type AnnotationPopoverProps = {
  annotations: Annotation[];
  setFocusedAnnotationId: (id: string) => void;
  activeSchema: AnnotationSchema;
  children: React.ReactNode | React.ReactNode[];
};

const MAX_VAL_LENGTH = 25;

// id/class necessary to override popover inner content padding
const CustomPopover = ({ className, ...props }) => (
  <Popover {...props} overlayClassName={className} id="annotation-popover" />
);
const PlainPopover = styled(CustomPopover)`
  #annotation-popover .ant-popover-inner-content {
    font-size: 12px;
    margin-bottom: 0px;
    padding: 5px;
    max-height: 200px;
    overflow: auto;
    scrollbar-width: none;
  }
  #annotation-popover .ant-popover-inner-content::-webkit-scrollbar {
    display: none;
  }
  > * {
    font-size: 12px;
  }
  .ant-list-sm .ant-list-item {
    padding: 1px;
  }
  .ant-card-body {
    padding: 1px;
  }
  .ant-card {
    font-size: 12px;
    padding: 4px;
    width: 100%;
    line-height: 1.2;
  }
  .ant-card-head {
    font-size: 12px;
  }
  .ant-card-meta-title {
    font-size: 13px;
  }
  .ant-card-meta-detail {
    font-size: 11px;
  }
  .ant-card-meta-detail > div:not(:last-child) {
    margin-bottom: 0px;
  }
`;
const CardWrapper = styled.div`
   {
    display: flex;
    flexflow: row nowrap;
  }
`;

export const AnnotationPopover = React.memo((props: AnnotationPopoverProps) => {
  const annotationTypes = props.activeSchema.annotationTypes;
  const getTypeByKey = (key: string) =>
    annotationTypes.find(t => t.key === key) || AnnotationType.Unknown(key);

  const title = (a: Annotation) => getTypeByKey(a.type).title;

  const truncatedValue = (a: Annotation) => {
    const valTooLong = a.value.length > MAX_VAL_LENGTH;
    const truncVal = valTooLong
      ? a.value.slice(0, MAX_VAL_LENGTH) + '...'
      : a.value;
    return truncVal;
  };

  const colorBoxSize = 18;
  const colorBox = (color: string) => (
    <ColorBox
      style={{
        backgroundColor: color,
        width: colorBoxSize,
        minWidth: colorBoxSize,
        height: colorBoxSize,
      }}
    />
  );

  const cardInfo = (a: Annotation) => ({
    id: a.id,
    title: title(a),
    value: truncatedValue(a),
    setFocus: () => props.setFocusedAnnotationId(a.id),
    color: getTypeByKey(a.type).color,
  });

  const cards = sortBy(props.annotations.map(cardInfo), c => c.title);
  const renderItem = a => (
    <List.Item onClick={a.setFocus}>
      <Card bordered={false} type="inner" size="small" hoverable={true}>
        <CardWrapper>
          {colorBox(a.color)}
          <Card.Meta title={a.title} description={a.value} />
        </CardWrapper>
      </Card>
    </List.Item>
  );

  const annotationList = (
    <List
      itemLayout="horizontal"
      dataSource={cards}
      size={'small'}
      renderItem={renderItem}
    />
  );

  return <PlainPopover content={annotationList}>{props.children}</PlainPopover>;
});

export default connect(
  (state: AppState, ownProps: { annotationIds: string[] }) => ({
    annotations: selectAnnotationsArray(state).filter(a =>
      some(ownProps.annotationIds, i => i === a.id),
    ),
    activeSchema:
      selectActiveAnnotationSchema(state) || AnnotationSchema.unknownSchema(),
  }),
  { setFocusedAnnotationId },
)(AnnotationPopover);
