import { createAction } from 'typesafe-actions';
import { AnnotationRelation } from '../models/annotation-relation.model';
import { Map } from 'immutable';
import { RelationType } from '../../lib/relations/relation-types';

enum Types {
  APPEND_ANNOTATION_RELATIONS = 'POPULATE_ANNOTATION_RELATIONS',
  REPLACE_ANNOTATION_RELATIONS = 'REPLACE_ANNOTATION_RELATIONS',
  CREATE_ANNOTATION_RELATION = 'CREATE_ANNOTATION_RELATION',
  DELETE_ANNOTATION_RELATION = 'DELETE_ANNOTATION_RELATION',
  DELETE_ANNOTATION_CHILD_RELATIONS = 'DELETE_ANNOTATION_CHILD_RELATIONS',
}

export const appendAnnotationRelations = createAction(
  Types.APPEND_ANNOTATION_RELATIONS,
  resolvePayload => (relations: Map<string, AnnotationRelation>) =>
    resolvePayload({ relations }),
);

export const replaceAnnotationRelations = createAction(
  Types.REPLACE_ANNOTATION_RELATIONS,
  resolvePayload => (relations: Map<string, AnnotationRelation>) =>
    resolvePayload({ relations }),
);

type CreateRelationPayload = {
  left: string;
  right: string;
  relationType: RelationType;
};

export const createAnnotationRelation = createAction(
  Types.CREATE_ANNOTATION_RELATION,
  resolvePayload => (p: CreateRelationPayload) => resolvePayload({ ...p }),
);

export const deleteAnnotationRelation = createAction(
  Types.DELETE_ANNOTATION_RELATION,
  resolvePayload => (id: string) => resolvePayload({ id }),
);

export const deleteAnnotationChildRelations = createAction(
  Types.DELETE_ANNOTATION_CHILD_RELATIONS,
  resolvePayload => (annotationId: string) => resolvePayload({ annotationId }),
);
