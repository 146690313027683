import { Map } from 'immutable';

export function fastShallowClone<T extends object>(o: T): T {
  return { ...(o as object) } as T;
}

export function fastShallowCloneMap<K extends any, V extends object>(
  m: Map<K, V>,
): Map<K, V> {
  return Map(m.entries());
}
