import { Annotation } from '../../annotations/models/annotation.model';
import { filter, find } from 'lodash';
import equal from 'deep-equal';

export interface DomainLookupValue {
  domainLookupValueId: number;
  createdAt: string;
  domainLookupId: number;
  key: object;
  value: object;
}

export interface DomainLookup {
  annotationSchemaId: number;
  domainLookupId: number;
  createdAt: string;
  groupId: number;
  name: string;
  lookupType: string;
  keys: string[];
  values: string[];
  size: number;
}

export const DomainLookup = {
  findLookupValue(
    lookup: DomainLookup,
    lookupValues: DomainLookupValue[],
    annotationPath: Annotation[],
  ): DomainLookupValue | undefined {
    const domainLookupId = lookup.domainLookupId;
    const treePath = filter(
      annotationPath,
      a => lookup.keys.indexOf(a.type) !== -1,
    );
    const lookupKey = treePath.reduce((c, n) => {
      const key = n.type;
      return { ...c, [key]: n.value };
    }, {});
    const value = find(
      lookupValues,
      v => v.domainLookupId === domainLookupId && !!equal(v.key, lookupKey),
    );
    return value;
  },
};
