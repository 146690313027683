import { RelationRule } from '../relation-rule.model';
import { RelationViolation } from '../relation-violation.model';
import { Relation } from '../relation.model';

export function childOf<U, T>(
  entity: U,
  rule: RelationRule<T>,
  relations: Relation<T, U>[],
  compare: (entity: U, type: T) => boolean,
): RelationViolation<U, T>[] {
  let match;
  for (const relation of relations) {
    if (compare(relation.right, rule.right)) {
      match = entity;
      break;
    }
  }

  // TODO: Should there be a violation if child of more than one parent?
  if (!match) {
    return [
      {
        rule,
        left: entity,
        right: null,
      },
    ];
  }

  return [];
}
