import { createAction } from 'typesafe-actions';

enum Types {
  LOAD_DOCUMENT_SUMMARY_START = 'LOAD_DOCUMENT_SUMMARY_START',
  LOAD_DOCUMENT_SUMMARY_END = 'LOAD_DOCUMENT_SUMMARY_END',
}

export const loadDocumentSummaryStart = createAction(
  Types.LOAD_DOCUMENT_SUMMARY_START,
);
export const loadDocumentSummaryEnd = createAction(
  Types.LOAD_DOCUMENT_SUMMARY_END,
);
