import React from 'react';
import { HotKeys, KeyMapOptions } from 'react-hotkeys';
import RTree from 'rtree';
import styled from 'styled-components';
import { Rect } from '../models/rect.model';
import { RectSelector } from './rect-selector.component';
import { SelectionOverlay } from './selection-overlay.component';

const SelectionDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: crosshair;

  .selection {
    stroke: #3c4e7b;
    stroke-width: 1px;
    stroke-dasharray: 4px;
    stroke-opacity: 1;
    fill: transparent;
  }
`;

interface DiscontinousSelectionProps {
  onSelectionChange: (elms: HTMLElement[]) => any;

  // Presently, the simplest method of accounting for rotation
  // of parent containers is by passing it in.
  // Not ideal, but presently a better option than alternatives.
  // There is no simple way to calculate a child's rotation relative
  // to the viewport. In the vast majority of use-cases, elements will
  // not be rotated. But in our library, we have to account for it somehow.
  rotatedBy?: number;
}

const keyMap = {
  startAdditive: { sequence: 'shift', action: 'keydown' } as KeyMapOptions,
  endAdditive: { sequence: 'shift', action: 'keyup' } as KeyMapOptions,
  startSubtractive: { sequence: 'alt', action: 'keydown' } as KeyMapOptions,
  endSubtractive: { sequence: 'alt', action: 'keyup' } as KeyMapOptions,
};

interface SelectableElementRef {
  rect: { x: number; y: number; w: number; h: number };
  elm: HTMLElement;
  idx: number;
}
interface DiscontinousSelectionState {
  selectedRects: Rect[];
}
export class DiscontinousSelection extends React.PureComponent<
  DiscontinousSelectionProps,
  DiscontinousSelectionState
> {
  state = {
    selectedRects: [],
  };

  private index = new RTree();
  private mode: 'default' | 'additive' | 'subtractive' = 'default';
  private selected = new Set<SelectableElementRef>();

  private childContainerDiv: HTMLDivElement | null = null;
  private selectorContainerDiv: HTMLDivElement | null = null;
  private customMouseEvent: MouseEvent | null = null;

  render() {
    return (
      <HotKeys keyMap={keyMap} handlers={this.keyHandlers} focused={true}>
        <SelectionDiv onMouseUp={this.onMouseUp} onMouseDown={this.onMouseDown}>
          <RectSelector onSelect={this.onSelect} divRef={this.setSelectorRef} />
          <div
            style={{
              width: '100%',
              height: '100%',
              pointerEvents: 'all',
              zIndex: -1,
            }}
            ref={this.setRef}
            onMouseMove={this.redispatchToSelector}
          >
            {this.props.children}
          </div>
          <SelectionOverlay selectedRects={this.state.selectedRects} />
        </SelectionDiv>
      </HotKeys>
    );
  }

  private onSelect = (rect: Rect) => {
    const selected: SelectableElementRef[] = this.index.search({
      x: rect.x,
      y: rect.y,
      w: rect.width,
      h: rect.height,
    });

    if (this.mode === 'default') {
      this.selected.clear();
    }

    if (this.mode === 'additive' || this.mode === 'default') {
      selected.forEach(r => this.selected.add(r));
    } else if (this.mode === 'subtractive') {
      selected.forEach(r => this.selected.delete(r));
    }

    const selectedArr = Array.from(this.selected.values());
    const selectedRects = selectedArr.map(c => Rect.mapFromRTreeRect(c.rect));
    const elements = selectedArr
      .sort((c1, c2) => c1.idx - c2.idx)
      .map(c => c.elm);

    this.props.onSelectionChange(elements);

    this.setState({ selectedRects });
  };

  private get keyHandlers() {
    return {
      startAdditive: this.startAdditive,
      endAdditive: this.endAdditive,
      startSubtractive: this.startSubtractive,
      endSubtractive: this.endSubtractive,
    };
  }

  private startAdditive = () => {
    this.mode = 'additive';
  };

  private endAdditive = () => {
    if (this.mode === 'additive') {
      this.mode = 'default';
    }
  };

  private startSubtractive = () => {
    this.mode = 'subtractive';
  };

  private endSubtractive = () => {
    if (this.mode === 'subtractive') {
      this.mode = 'default';
    }
  };

  private getRelativeRect(child: HTMLElement, parent: HTMLDivElement) {
    const cRect = child.getBoundingClientRect();
    const pRect = parent.getBoundingClientRect();
    const rotation = (this.props.rotatedBy || 0) % 360;

    let rtreeRect: { x: number; y: number; w: number; h: number } = {
      x: cRect.left - pRect.left,
      y: cRect.top - pRect.top,
      w: cRect.width,
      h: cRect.height,
    };

    // Not foolproof, but handles all 90 degree rotations accurately.
    if (rotation > 0) {
      if (rotation <= 90) {
        rtreeRect = {
          x: cRect.top - pRect.top,
          y: pRect.right - cRect.right,
          w: cRect.height,
          h: cRect.width,
        };
      } else if (rotation <= 180) {
        rtreeRect = {
          x: pRect.right - cRect.right,
          y: pRect.bottom - cRect.bottom,
          w: cRect.width,
          h: cRect.height,
        };
      } else if (rotation <= 270) {
        rtreeRect = {
          x: pRect.bottom - cRect.bottom,
          y: cRect.left - pRect.left,
          w: cRect.height,
          h: cRect.width,
        };
      }
    }

    return rtreeRect;
  }

  private setRef = (ref: HTMLDivElement | null) => {
    this.index = new RTree();

    if (!ref || !ref.children) {
      return;
    }

    for (let i = 0; i < ref.children.length; i++) {
      const child = ref.children[i];
      const rtreeRect = this.getRelativeRect(child as HTMLElement, ref);
      this.index.insert(rtreeRect, { rect: rtreeRect, elm: child, idx: i });
    }

    this.childContainerDiv = ref;
  };

  private setSelectorRef = (ref: HTMLDivElement | null) => {
    this.selectorContainerDiv = ref;
  };

  /***
   * *** Start of custom mouse event dispatching ***
   * The following three methods and the usage is a bit of an anti-pattern
   * but is necessary to allow mouse events to propagate through the custom
   * selection layer and the DOM elements it enhances.
   * Essentially, it listens to mouse events fired against the dom elements
   * that sit on top of our custom selection layer, and redispatch them to
   * our components.
   */
  private onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.nativeEvent === this.customMouseEvent) {
      return;
    }

    if (this.childContainerDiv) {
      this.childContainerDiv.style.pointerEvents = 'none';
    }

    this.redispatchToSelector(e);
  };

  private redispatchToSelector = (e: React.MouseEvent<HTMLDivElement>) => {
    if (this.selectorContainerDiv) {
      const svg = this.selectorContainerDiv.children.item(0);
      this.customMouseEvent = new MouseEvent(e.type, e.nativeEvent);
      if (svg) {
        svg.dispatchEvent(this.customMouseEvent);
      }
    }
  };

  private onMouseUp = () => {
    if (this.childContainerDiv) {
      this.childContainerDiv.style.pointerEvents = 'all';
    }
  };
  // *** End of custom mouse event dispatching ***
}
