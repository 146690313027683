import { values } from 'lodash';
import { sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { AppState } from '../../app-state';
import { QueueItem } from '../models/queue-item.model';

const selectQueueEntities = (state: AppState) => state.queues.entities;

export const selectAllQueuesAsArray = createSelector(
  [selectQueueEntities],
  entities => sortBy(values(entities), q => q.queueName.toLowerCase()),
);

export const selectCurrentQueueItem = (state: AppState): QueueItem | null =>
  state.queues.currentQueueItem;
