import { ActionsObservable, StateObservable } from 'redux-observable';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AppState } from '../../app-state';
import { AppEpic } from '../../common/types/app-epic.type';
import { handleEpicError } from '../../common/utils/epics';
import { setSourceDocument } from '../../documents/actions/source-document.actions';
import { AppActions } from '../../root.actions';
import {
  fetchAvailableDocumentStatuses,
  fetchAvailableDocumentStatusesError,
  populateAvailableDocumentStatuses,
} from '../actions/available-document-status.actions';
import { AvailableDocumentStatusResource } from '../resources/available-document-statuses.resource';

export const getAvailableDocumentStatusesEpic: AppEpic = (
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) => {
  return action$.pipe(
    filter(isActionOf([setSourceDocument, fetchAvailableDocumentStatuses])),
    switchMap(action => {
      return AvailableDocumentStatusResource.get(state$.value).pipe(
        map(resp =>
          populateAvailableDocumentStatuses(resp.availableDocumentStatuses),
        ),
        catchError(
          handleEpicError(
            'Error fetching available document statuses',
            fetchAvailableDocumentStatusesError,
          ),
        ),
      );
    }),
  );
};
