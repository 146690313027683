import { createAction } from 'typesafe-actions';

enum Types {
  SET_PANE_SIZE = 'SET_PANE_SIZE',
  SET_ANNOTATION_PANE_PAGE_OFFSETS = 'SET_ANNOTATION_PANE_PAGE_OFFSETS',
}

export const setPaneSize = createAction(
  Types.SET_PANE_SIZE,
  resolvePayload => (paneId: string, size: number) =>
    resolvePayload({ paneId, size }),
);

export const setAnnotationPanePageOffsets = createAction(
  Types.SET_ANNOTATION_PANE_PAGE_OFFSETS,
  resolvePayload => (offsets: number[]) => resolvePayload({ offsets }),
);
