import { Layout } from 'antd';
import React from 'react';
import { RouterProps } from 'react-router';
import styled from 'styled-components';
import { Header } from '../layout/containers/main-header.container';
import { AppPanesContainer } from '../layout/containers/panes-layout-01.container';
const { Content } = Layout;

const StyledLayout = styled(Layout)`
  height: 100%;

  .ant-layout-header {
    height: 48px;
    line-height: 48px;
  }
`;

const AnnotationPage: React.SFC<RouterProps> = () => (
  <StyledLayout>
    <Header />
    <Content>
      <AppPanesContainer />
    </Content>
  </StyledLayout>
);

export default AnnotationPage;
