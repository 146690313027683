import { find } from 'lodash';
import { Role } from '../../roles/models/role.model';
import { Group } from './group.model';
import { Permission } from './permission.model';
import { User } from './user.model';

/**
 * A user account models both a user and information
 * about that user, such as as the groups they are
 * a member of and their roles and permissions.
 */
export interface UserAccount {
  user: User;
  groups: Group[];
  roles: Role[];
  userRoles: Role[];
  groupRoles: Role[];
  permissions: Permission[];
}

export const UserAccount = {
  /**
   * Do the passed roles include the admin role?
   *
   * Note: Because permissions are independent of role name,
   * there is a chance a user could have admin permissions
   * without the "admin" role. That was not the case when this
   * was built, but if users need access to some admin features
   * without being a superadmin, then this can be updated to check
   * a user's permissions instead of their role.
   * @param roles
   */
  isAdmin(account: { roles: Role[] }): boolean {
    return !!find(account.roles, r => r.roleName === 'admin');
  },
};
