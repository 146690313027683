import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { AppState } from '../../app-state';
import { authHeaders } from '../../common/utils/fetch';
import { config } from '../../config/application.config';

export interface AvailableDocumentStatus {
  availableDocumentStatuses: DocumentStatus[];
}

export interface DocumentStatus {
  statusId: number;
  status: string;
}

export const AvailableDocumentStatusResource = {
  baseUrl: config.annotationService.url,
  getAvailableDocumentStatusUrl: `${config.annotationService.url}/available-document-status`,

  get(state: AppState): Observable<AvailableDocumentStatus> {
    return ajax.getJSON<AvailableDocumentStatus>(
      this.getAvailableDocumentStatusUrl,
      authHeaders(state),
    );
  },
};
