import React from 'react';

export const ZoomInSvg = (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="310.42px"
    height="310.42px"
    viewBox="0 0 310.42 310.42"
  >
    <g>
      <g>
        <path d="M273.586,214.965c49.11-49.111,49.11-129.021,0-178.132s-129.02-49.111-178.13,0C53.793,78.497,47.483,140.462,76.51,188.85c0,0,2.085,3.496-0.731,6.312c-16.065,16.064-64.263,64.263-64.263,64.263c-12.791,12.79-15.836,30.675-4.493,42.02l1.953,1.951c11.343,11.345,29.229,8.301,42.019-4.49c0,0,48.096-48.097,64.128-64.128c2.951-2.951,6.448-0.866,6.448-0.866C169.957,262.938,231.922,256.629,273.586,214.965z M118.711,191.71c-36.288-36.288-36.287-95.332,0.001-131.62c36.288-36.287,95.333-36.288,131.619,0c36.288,36.287,36.288,95.332,0,131.62C214.042,227.997,154.999,227.997,118.711,191.71z" />
        <path d="M234.365,107.761h-28.183c0,0-2.696-0.212-2.696-2.963c0-6.979,0-27.916,0-27.916c0-5.5-4.5-10-10-10h-16.575c-5.5,0-10,4.5-10,10c0,0,0,20.797,0,27.729c0,2.937-2.853,3.149-2.853,3.149h-28.025c-5.5,0-10,4.5-10,10v16.575c0,5.5,4.5,10,10,10h27.817c0,0,3.061,0.087,3.061,3.004c0,6.969,0,27.875,0,27.875c0,5.5,4.5,10,10,10h16.575c5.5,0,10-4.5,10-10c0,0,0-21.031,0-28.042c0-2.688,3.071-2.837,3.071-2.837h27.809c5.5,0,10-4.5,10-10V117.76C244.365,112.261,239.865,107.761,234.365,107.761z" />
      </g>
    </g>
  </svg>
);
