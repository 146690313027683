import { Button } from 'antd';
import styled from 'styled-components';
import { Blue2 } from '../colors';

export const SecondaryButton = styled(Button as any)`
  &.ant-btn {
    background-color: ${Blue2};
    color: white;
    border: none;
    border-radius: 0;
  }
`;
