import { notification } from 'antd';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { filter, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AppState } from '../../app-state';
import { AppActions } from '../../root.actions';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from '../actions/notification.actions';

export function displayErrorNotificationEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(displayErrorNotification)),
    tap(action => {
      notification.error({
        message: action.payload.title,
        description: action.payload.error,
        duration: 10,
      });
    }),
    filter(() => false),
  );
}

export function displaySuccessNotificationEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(displaySuccessNotification)),
    tap(action => {
      notification.success({
        message: action.payload.title,
        description: action.payload.message,
        duration: 4,
      });
    }),
    filter(() => false),
  );
}
