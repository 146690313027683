import { connect } from 'react-redux';
import { AppState } from '../../app-state';
import { redoAction, undoAction } from '../actions/undo-redo.actions';
import { UndoRedoButtonsComponent } from '../components/undo-redo-buttons.component';
import { selectCanRedo, selectCanUndo } from '../selectors/undo-redo.selectors';

export const UndoRedoButtons = connect(
  (state: AppState) => ({
    undoEnabled: selectCanUndo(state),
    redoEnabled: selectCanRedo(state),
  }),
  {
    undoAction,
    redoAction,
  },
)(UndoRedoButtonsComponent);
