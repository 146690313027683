import { clamp, reduce } from 'lodash';
import { getType } from 'typesafe-actions';
import { AppState } from '../../app-state';
import { signOut } from '../../auth/actions/auth.actions';
import { AuthActions } from '../../auth/actions/auth.types';
import { KeyValueMap } from '../../common/types/KeyValueMap.type';
import { QuerySort } from '../../common/types/QuerySort';
import { setDocumentSetDocumentRequestParameters } from '../../document-sets/actions/document-sets.actions';
import { DocumentSetsActions } from '../../document-sets/actions/document-sets.types';
import {
  fetchDocuments,
  setDocumentListFilter,
  setDocumentListPage,
  setDocumentListSort,
  updateDocuments,
} from '../actions/document-list.actions';
import { DocumentListActions } from '../actions/document-list.types';
import { AnnotatedDocument } from '../models/annotated-document.model';
import { DocumentListFilter } from '../models/document-list-filter.model';

export type DocumentListState = {
  entities: KeyValueMap<AnnotatedDocument>;
  loading: boolean;
  page: number;
  pageCount: number;
  totalItems: number;
  filter: DocumentListFilter;
  sort: QuerySort | null;
  fetchingForDocumentSetManagement: boolean;
};

export const defaultDocumentList = {
  entities: {},
  loading: false,
  page: 1,
  pageCount: 1,
  totalItems: 0,
  filter: {
    documentSetName: null,
    state: 'all',
    county: 'all',
    aggregateId: null,
    instrumentType: null,
    recordedDate: null,
    createdAt: null,
    lastAnnotationTimestamp: null,
    lastReviewTimestamp: null,
    isAnnotated: 'all',
    reviewCompleted: 'all',
    lastAnnotatorName: null,
    lastReviewerName: null,
    positiveStatuses: null,
    negativeStatuses: null,
    ocrStatus: undefined,
  },
  sort: null,
  fetchingForDocumentSetManagement: false,
};

export function documentListReducer(
  state: DocumentListState = defaultDocumentList,
  action: DocumentListActions | AuthActions | DocumentSetsActions,
  appState: AppState,
): DocumentListState {
  switch (action.type) {
    case getType(fetchDocuments):
      return {
        ...state,
        loading: true,
        fetchingForDocumentSetManagement:
          action.payload.fetchingForDocumentSetManagement,
      };
    case getType(updateDocuments):
      return reduce(
        action.payload.documents,
        (newState, doc) => ({
          ...newState,
          entities: {
            ...newState.entities,
            [doc.annotatedDocumentId]: doc,
          },
        }),
        {
          ...state,
          loading: false,
          pageCount: action.payload.pageCount,
          totalItems: action.payload.totalItems,
          entities: {},
        },
      );
    case getType(setDocumentSetDocumentRequestParameters):
      return {
        ...state,
        filter: action.payload.documentRequestParameters.filter,
        page: action.payload.documentRequestParameters.page,
        sort: action.payload.documentRequestParameters.sort,
      };
    case getType(setDocumentListFilter):
      return {
        ...state,
        filter: action.payload.filter,
      };

    case getType(setDocumentListPage): {
      return {
        ...state,
        page: clamp(action.payload.page, 1, state.pageCount),
      };
    }

    case getType(setDocumentListSort): {
      const { field, order } = action.payload;
      return {
        ...state,
        sort: field && order ? action.payload : null,
      };
    }

    case getType(signOut):
      return defaultDocumentList;

    default:
      return state;
  }
}
