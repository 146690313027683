import React from 'react';
import styled from 'styled-components';
import { LoginFormContainer } from '../auth/containers/login-form.container';
import { Blue3 } from '../common/colors';
import logo from '../logo.svg';

const PageWrapper = styled.div`
  height: 100%;
  background-color: ${Blue3};
  color: white;
`;

const LoginWrapper = styled.div`
  max-width: 320px;
  margin: auto;
  position: relative;
  top: 20%;
`;

const Logo = styled.img``;

export class LoginPage extends React.PureComponent {
  render() {
    return (
      <PageWrapper>
        <LoginWrapper>
          <Logo src={logo} style={{ marginBottom: '20px' }} />
          <LoginFormContainer />
        </LoginWrapper>
      </PageWrapper>
    );
  }
}
