import React from 'react';
import styled from 'styled-components';
import { AnnotationViewerControls } from '../../annotations/components/annotation-viewer-controls.component';
import { AnnotationShortcuts } from '../../annotations/containers/annotation-shortcut-keys.container';
import { AnnotationViewer } from '../../annotations/containers/annotation-viewer.container';
import { UndoRedoHotKeys } from '../../undo-redo/containers/undo-redo-hotkeys.container';
import { AnnotationPageScroller } from '../containers/annotation-page-scroller.container';

const AnnotationViewerWrapper = styled.div``;
const panelMargin = 0;
const pdfControlsSize = 42;
const InnerPanel = styled.div`
  margin: ${panelMargin}px;
  width: 100%;
  height: 100%;
`;

const PaneContent = styled.div`
  width: calc(100% - ${panelMargin * 2}px);
  height: calc(100% - ${panelMargin * 2}px);

  /* If second child, shares the pane with a toolbar. */
  &:nth-child(2) {
    height: calc(100% - ${pdfControlsSize + panelMargin}px);
  }
`;

const PageControlsWrapper = styled.div`
  position: sticky;
  z-index: 1;
  top: 0;
  width: calc(100% - ${panelMargin * 2}px);
`;

export const AnnotationViewerPanel = () => {
  return (
    <InnerPanel>
      <PageControlsWrapper>
        <AnnotationViewerControls />
      </PageControlsWrapper>
      <PaneContent>
        <AnnotationPageScroller>
          <AnnotationShortcuts mode="selection">
            <div className="poo">
              <UndoRedoHotKeys />
              <AnnotationViewerWrapper>
                <AnnotationViewer />
              </AnnotationViewerWrapper>
            </div>
          </AnnotationShortcuts>
        </AnnotationPageScroller>
      </PaneContent>
    </InnerPanel>
  );
};
