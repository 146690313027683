import { Layout } from 'antd';
import styled from 'styled-components';
import { Blue3 } from '../colors';
const { Header } = Layout;

export const StyledHeader = styled(Header)`
  border-bottom: 1px solid #888;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  color: white;

  &.ant-layout-header {
    background: ${Blue3};
    padding: 0 24px;
  }
`;
