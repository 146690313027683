import { Layout } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { AdminContent } from '../admin/containers/admin-content.container';
import { AdminSider } from '../admin/containers/admin-sider.container';
import { Header } from '../layout/containers/main-header.container';

const ContentWrap = styled(Layout)`
  display: flex;
  height: 100%;
`;

const PageWrap = styled.span`
  .ant-layout-header {
    height: 48px;
    line-height: 48px;
  }
  height: 100%;
`;

export class AdminPage extends React.PureComponent {
  render() {
    return (
      <PageWrap>
        <Header />
        <ContentWrap>
          <AdminSider />
          <Layout>
            <AdminContent />
          </Layout>
        </ContentWrap>
      </PageWrap>
    );
  }
}
