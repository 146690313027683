type RGBValues = {
  red: number;
  green: number;
  blue: number;
};

export class Color {
  r: number;
  g: number;
  b: number;
  hexRed: string;
  hexBlue: string;
  hexGreen: string;

  static blend(colors: Color[]): Color {
    const len = colors.length;

    const red = Math.floor(colors.map(c => c.r).reduce((a, b) => a + b) / len);
    const green = Math.floor(
      colors.map(c => c.g).reduce((a, b) => a + b) / len,
    );
    const blue = Math.floor(colors.map(c => c.b).reduce((a, b) => a + b) / len);

    return new Color({ red, green, blue });
  }

  constructor(color: string | RGBValues) {
    if (typeof color === 'string') {
      const str = color.replace('#', '');

      this.r = parseInt(str.slice(0, 2), 16);
      this.g = parseInt(str.slice(2, 4), 16);
      this.b = parseInt(str.slice(4, 6), 16);
    } else {
      this.r = color.red;
      this.g = color.green;
      this.b = color.blue;
    }
    this.hexRed = this.zeroPad(this.r.toString(16), 2);
    this.hexGreen = this.zeroPad(this.g.toString(16), 2);
    this.hexBlue = this.zeroPad(this.b.toString(16), 2);
  }

  get hex() {
    return `#${this.hexRed}${this.hexGreen}${this.hexBlue}`;
  }

  private zeroPad(str: string, cnt: number) {
    while (str.length < cnt) {
      str = '0' + str;
    }

    return str;
  }
}
