import { takeRight } from 'lodash';
import { getType } from 'typesafe-actions';
import { config } from '../../config/application.config';
import { sourceDocumentLoaded } from '../../documents/actions/source-document.actions';
import { SourceDocumentActions } from '../../documents/actions/source-document.types';
import {
  pushToUndoRedoHistory,
  redoAction,
  undoAction,
} from '../actions/undo-redo.actions';
import { UndoRedoActions } from '../actions/undo-redo.types';
import { UndoRedoStep } from '../models/undo-redo-step.model';

export type UndoRedoState = {
  pointer: number;
  history: UndoRedoStep[];
};

export const defaultUndoRedoState = {
  pointer: -1,
  history: [],
};

export function undoRedoReducer(
  state: UndoRedoState = defaultUndoRedoState,
  action: UndoRedoActions | SourceDocumentActions,
): UndoRedoState {
  switch (action.type) {
    case getType(undoAction):
      if (state.pointer > -1) {
        return {
          ...state,
          pointer: state.pointer - 1,
        };
      } else {
        return state;
      }

    case getType(redoAction): {
      const history = state.history;
      if (state.pointer < history.length - 1) {
        return {
          ...state,
          pointer: state.pointer + 1,
        };
      } else {
        return state;
      }
    }

    case getType(pushToUndoRedoHistory): {
      const history = state.history
        .slice(0, state.pointer + 1)
        .concat([action.payload.step]);
      return {
        ...state,
        pointer: state.pointer + 1,
        history: limitUndoRedoHistory(history),
      };
    }

    // Reset undo-redo when a new document is loaded
    case getType(sourceDocumentLoaded):
      return { ...defaultUndoRedoState };

    default:
      return state;
  }
}

function limitUndoRedoHistory(history: UndoRedoStep[]): UndoRedoStep[] {
  return takeRight(history, config.undoRedo.maxEntries);
}
