import dateformat from 'dateformat';
import React from 'react';
import styled from 'styled-components';

const DateStyle = styled.span`
  display: inline-block;
  white-space: nowrap;
`;

type DateComponentProps = {
  serializedDate: string | number;
  format?: string;
};

export const DateComponent: React.FC<DateComponentProps> = ({
  serializedDate,
  format = 'UTC:yyyy-mm-dd',
}) => {
  const annotatedDate = new Date(serializedDate);

  const dateValid = annotatedDate.toString() !== 'Invalid Date';

  return (
    <DateStyle>
      {dateValid ? dateformat(annotatedDate, format) : 'Unknown Date'}
    </DateStyle>
  );
};
