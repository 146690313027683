import { Observable } from 'rxjs';
import { AjaxResponse, ajax } from 'rxjs/ajax';
import { map as observableMap } from 'rxjs/operators';
import { AddNewUserPayload } from '../../admin/models/add-new-user-payload.model';
import { AppState } from '../../app-state';
import { CONTENT_TYPE_HEADERS, authHeaders } from '../../common/utils/fetch';
import { config } from '../../config/application.config';
import { Role } from '../../roles/models/role.model';
import { Group } from '../models/group.model';
import { Permission } from '../models/permission.model';
import { SimpleRole } from '../models/simple-role.model';
import { User } from '../models/user.model';

export interface UserAccountResource {
  userAccount: {
    user: User;
    groups: Group[];
    roles: Role[];
    userRoles: Role[];
    groupRoles: Role[];
    permissions: Permission[];
  };
}

export const UserAccountResource = {
  accountUrl: `${config.annotationService.url}/user-account`,
  externalAuthUsersUrl: `${config.annotationService.url}/external-auth/users`,

  getAccountUrl(userId: number) {
    return `${this.accountUrl}/${userId}`;
  },

  putSimpleRolesUrl(userId: number) {
    return `${this.accountUrl}/${userId}/simple-roles`;
  },

  get(userId: number, state: AppState): Observable<UserAccountResource> {
    return ajax.getJSON<UserAccountResource>(
      this.getAccountUrl(userId),
      authHeaders(state),
    );
  },
  createAccount: (payload: AddNewUserPayload, state: AppState) => {
    return ajax.post(UserAccountResource.externalAuthUsersUrl, payload, {
      ...authHeaders(state),
      ...CONTENT_TYPE_HEADERS.JSON,
    });
  },
  /**
   * Requests the account for the currently authenicated user.
   * @param state
   */
  getAuthenticatedAccount(state: AppState): Observable<UserAccountResource> {
    return ajax.getJSON<UserAccountResource>(
      this.accountUrl,
      authHeaders(state),
    );
  },

  putSimpleRoles(
    userId: number,
    roles: SimpleRole[],
    state: AppState,
  ): Observable<SimpleRole[]> {
    return ajax
      .put(
        this.putSimpleRolesUrl(userId),
        { roles },
        {
          ...authHeaders(state),
          ...CONTENT_TYPE_HEADERS.JSON,
        },
      )
      .pipe(observableMap((_: AjaxResponse) => roles));
  },
};
