import { connect } from 'react-redux';
import {
  PermissionActions,
  PermissionResources,
} from '../../accounts/models/permission.model';
import { selectHasPermissions } from '../../accounts/selectors/users.selectors';
import { AppState } from '../../app-state';
import { downloadDocumentById } from '../../common/actions/download.actions';
import {
  allSearchResultsChecked,
  changeSearchResultOrder,
  pageChange,
  searchResultSelect,
} from '../actions/search.actions';
import {
  SearchResults,
  SearchResultsProps,
} from '../components/search-results.component';
import { selectAnnotatioTypesWithExistsConditionsInQuery } from '../selectors';

const mapDispatchToProps = {
  pageChange,
  searchResultSelect,
  allSearchResultsChecked,
  downloadDocumentById,
  changeSearchResultOrder,
};

export const SearchResultsContainer = connect(
  (state: AppState) => ({
    results: state.search.results,
    pageSize: state.search.pageSize,
    currentPage: state.search.currentPage,
    loadingResults: state.search.loading,
    shownFieldsOverride: selectAnnotatioTypesWithExistsConditionsInQuery(state),
    selectedResults: state.search.selectedResults,
    showAnnotationErrors: selectHasPermissions([
      {
        action: PermissionActions.Annotate,
        resource: PermissionResources.DocumentSet,
      },
    ])(state),
    orderBy: state.search.orderBy,
  }),
  mapDispatchToProps as Pick<
    SearchResultsProps,
    keyof typeof mapDispatchToProps
  >,
)(SearchResults);
