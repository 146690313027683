/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { AutoComplete, Button, Modal, Select } from 'antd';
import { Tooltip } from 'antd';
import { Dictionary, find as _find, get, map, uniqBy } from 'lodash';
import React from 'react';
import { Observable, from } from 'rxjs';
import { mergeMap, map as observableMap, reduce } from 'rxjs/operators';
import styled from 'styled-components';
import { isString } from 'util';
import uuid from 'uuid/v4';
import { Restricted } from '../../accounts/containers/restricted.container';
import {
  PermissionActions,
  PermissionResources,
} from '../../accounts/models/permission.model';
import ExportTable from '../components/export-table.component';
import { KeyValueMap } from '../../common/types/KeyValueMap.type';
import { SecondaryButton } from '../../common/components/secondary-button.component';
import * as Colors from '../../common/colors';
import { Queue } from '../../queues/models/queue.model';
import { SelectedResultsState } from '../reducers/search.reducer';
import { GroupScopedTag } from '../../tags/models/group-scoped-tag.model';
import { Export, ExportJob, ExportParams } from '../models/export.model';

const ActionLink: React.SFC<{
  onClick: () => void;
  disabledText?: string;
  isDisabled?: boolean;
}> = props => {
  const isDisabled = props.isDisabled;
  const enabledLink = (
    <div>
      <a onClick={props.onClick} href="javascript:;">
        {props.children}
      </a>
    </div>
  );
  const disabledLink = (
    <div style={{ color: Colors.DodgerBlue, opacity: 0.5 }}>
      <Tooltip overlayStyle={{ maxWidth: '600px' }} title={props.disabledText}>
        {props.children}
      </Tooltip>
    </div>
  );

  return isDisabled ? disabledLink : enabledLink;
};

const SubmitButton = styled(Button as any)`
  margin-top: 1em;
`;

export interface ActionModalProps {
  queues: Queue[];
  tagNames: string[];
  tagsByName: Dictionary<GroupScopedTag>;
  selectedResults: SelectedResultsState;
  hitCount: number;
  userCanTakeQueueActions: boolean;
  fetchDocumentTags: (documentSetId: number) => Observable<GroupScopedTag[]>;
  fetchQueues: () => void;
  fetchTags: () => void;
  addQueueItems: (queueId: number, annotatedDocumentIds: number[]) => void;
  addQueueItemsBulk: (queueId: number) => void;
  addTagToDocuments: (params: {
    tag: string;
    annotatedDocumentIds: number[];
  }) => void;
  addTagToDocumentsBulk: (params: { tag: string }) => void;
  removeTagFromDocuments: (params: {
    tagId: number;
    annotatedDocumentIds: number[];
  }) => void;
  removeTagFromDocumentsBulk: (params: { tagId: number }) => void;
  clearSelectedSearchResults: () => void;
  exports: KeyValueMap<Export>;
  exportJobs: KeyValueMap<ExportJob>;
  submitExportJob: (jobId: string, query: object, params: ExportParams) => void;
  cancelExportJob: (jobId: string) => void;
  deleteExportJob: (jobId: string) => void;
  downloadExport: (jobId: string) => void;
  refreshExportJob: (jobId: string) => void;
  query: object;
}

type ActionMode = 'select' | 'queue:add' | 'tag:add' | 'tag:remove' | 'export';

interface ActionModalState {
  actionsModalVisible: boolean;
  actionMode: ActionMode;
  chosenQueueId?: number;
  tagToAdd?: string;
  tagToRemove?: string;
  selectedDocumentTags: GroupScopedTag[];
  exportJobId: string | null;
}

export class ActionModal extends React.Component<
  ActionModalProps,
  ActionModalState
> {
  state: ActionModalState = {
    actionMode: 'select',
    actionsModalVisible: false,
    selectedDocumentTags: [],
    exportJobId: null,
  };

  fetchAndPoolTags() {
    from(this.props.selectedResults.selectedIds)
      .pipe(
        mergeMap((documentSetId: number) =>
          this.props.fetchDocumentTags(documentSetId),
        ),
        reduce((acc: GroupScopedTag[], curr: GroupScopedTag[]) => [
          ...acc,
          ...curr,
        ]),
        observableMap((e: GroupScopedTag[]) => uniqBy(e, 'tagName')),
      )
      .subscribe(tags => this.setState({ selectedDocumentTags: tags }));
  }

  componentDidMount() {
    this.props.fetchQueues();
    this.props.fetchTags();
    this.fetchAndPoolTags();
  }

  componentDidUpdate(prevProps: ActionModalProps) {
    if (prevProps.selectedResults !== this.props.selectedResults) {
      this.fetchAndPoolTags();
    }
  }

  showAvailableActions = () => {
    this.setState({ actionsModalVisible: true });
  };

  render() {
    const count = this.selectedCount();
    const selectedCountMessage =
      count === 1
        ? 'Selected Item Action'
        : `Actions for ${count.toLocaleString('en')} Selected Items`;
    return (
      <>
        <SubmitButton onClick={this.showAvailableActions}>
          {selectedCountMessage}
        </SubmitButton>
        <Modal
          title={'Selected Results Actions'}
          visible={this.state.actionsModalVisible}
          onCancel={e =>
            this.setState({
              actionsModalVisible: false,
              actionMode: 'select',
              chosenQueueId: undefined,
              tagToAdd: undefined,
              tagToRemove: undefined,
            })
          }
          footer={this.showFooter()}
        >
          {this.displayProperModalContents()}
        </Modal>
      </>
    );
  }

  selectedCount = () => {
    const selectedResults = this.props.selectedResults;
    const inverseSelected = selectedResults.inverseSelected;
    const numSelected = selectedResults.selectedIds.length;
    return inverseSelected ? this.props.hitCount - numSelected : numSelected;
  };

  backButton() {
    return (
      <SecondaryButton
        onClick={() => {
          this.setState({
            actionMode: 'select',
            chosenQueueId: undefined,
            tagToAdd: undefined,
            tagToRemove: undefined,
          });
        }}
      >
        Go Back
      </SecondaryButton>
    );
  }
  showFooter() {
    return this.state.actionMode === 'select' ? false : this.backButton();
  }
  displayProperModalContents() {
    const jobs = this.props.exportJobs;
    switch (this.state.actionMode) {
      case 'select':
        return this.displayActionLinks();
      case 'queue:add':
        return this.displayAddQueueWorkflow();
      case 'tag:add':
        return this.displayAddTagWorkflow();
      case 'tag:remove':
        return this.displayRemoveTagWorkflow();
      case 'export':
        return (
          <ExportTable
            jobs={jobs}
            downloadExport={this.props.downloadExport}
            refreshExportJob={this.props.refreshExportJob}
            deleteExportJob={this.props.deleteExportJob}
          />
        );
      default:
        return null;
    }
  }

  displayAddQueueWorkflow() {
    return (
      <>
        <h5>Select a Queue to add to</h5>
        <Select
          style={{ width: 200 }}
          placeholder="Pick a queue"
          onChange={o => this.setState({ chosenQueueId: o as number })}
        >
          {map(this.props.queues, q => (
            <Select.Option value={q.queueId} key={q.queueId}>
              {q.queueName}
            </Select.Option>
          ))}
        </Select>
        {this.submitAddQueueSection()}
      </>
    );
  }

  submitAddQueueSection() {
    if (this.state.chosenQueueId) {
      return (
        <SubmitButton
          style={{ marginTop: '1em' }}
          onClick={this.submitAddQueueItems}
        >
          {`Add ${this.selectedCount().toLocaleString(
            'en',
          )} items to ${this.getChosenQueueName()}`}
        </SubmitButton>
      );
    }
  }

  submitAddQueueItems = () => {
    if (this.state.chosenQueueId) {
      if (this.props.selectedResults.inverseSelected) {
        this.props.addQueueItemsBulk(this.state.chosenQueueId);
      } else if (this.props.selectedResults.selectedIds.length) {
        this.props.addQueueItems(
          this.state.chosenQueueId,
          this.props.selectedResults.selectedIds,
        );
      }
      this.props.clearSelectedSearchResults();
    }
    this.setState({
      actionMode: 'select',
      chosenQueueId: undefined,
      actionsModalVisible: false,
    });
  };

  getChosenQueueName() {
    return get(
      _find(this.props.queues, q => q.queueId === this.state.chosenQueueId),
      'queueName',
      '',
    );
  }

  exportActionLink(
    output: string,
    strategy: string,
    limit: number,
    exportId: number,
    name: string,
  ) {
    const hitCount = this.selectedCount();
    const isDisabled = hitCount > limit;
    const disabledText = `Result total exceeds ${limit}, the maximum allowed for this export.`;
    const permission = {
      action: PermissionActions.Read,
      resource: PermissionResources.Export,
      resourceId: exportId,
    };
    return (
      <Restricted required={permission}>
        <ActionLink
          onClick={() => {
            this.submitExportJob(output, strategy, limit);
            this.setState({ actionMode: 'export' });
          }}
          isDisabled={isDisabled}
          disabledText={disabledText}
        >
          {name}
        </ActionLink>
      </Restricted>
    );
  }

  displayActionLinks() {
    return (
      <>
        {this.props.userCanTakeQueueActions && (
          <ActionLink
            onClick={() => this.setState({ actionMode: 'queue:add' })}
          >
            Add to Queue
          </ActionLink>
        )}
        <ActionLink onClick={() => this.setState({ actionMode: 'tag:add' })}>
          Add Tag to Documents
        </ActionLink>
        <ActionLink onClick={() => this.setState({ actionMode: 'tag:remove' })}>
          Remove Tag from Documents
        </ActionLink>
        {map(this.props.exports, e =>
          this.exportActionLink(
            e.params.output,
            e.params.strategy,
            e.params.limit,
            e.id,
            e.title,
          ),
        )}
      </>
    );
  }

  displayAddTagWorkflow() {
    return (
      <>
        <h5>Select a Tag to add</h5>
        <AutoComplete
          style={{ width: 300 }}
          dataSource={this.props.tagNames}
          filterOption={true}
          placeholder="Pick a Tag or enter a new one"
          onChange={tagToAdd => {
            if (isString(tagToAdd)) {
              this.setState({ tagToAdd });
            }
          }}
        />
        {this.submitAddTagSection()}
      </>
    );
  }

  submitAddTagSection() {
    if (this.state.tagToAdd) {
      return (
        <SubmitButton onClick={this.submitAddTag}>
          {`Add the tag "${
            this.state.tagToAdd
          }" to ${this.selectedCount().toLocaleString('en')} documents`}
        </SubmitButton>
      );
    }
  }

  submitAddTag = () => {
    if (this.state.tagToAdd) {
      const tag = this.state.tagToAdd;
      if (this.props.selectedResults.inverseSelected) {
        this.props.addTagToDocumentsBulk({ tag });
      } else if (this.props.selectedResults.selectedIds.length) {
        const annotatedDocumentIds = this.props.selectedResults.selectedIds;
        this.props.addTagToDocuments({ tag, annotatedDocumentIds });
      }
      this.props.clearSelectedSearchResults();
    }
    this.setState({
      actionMode: 'select',
      tagToAdd: undefined,
      actionsModalVisible: false,
    });
  };

  displayRemoveTagWorkflow() {
    return (
      <>
        <h5>Select a Tag to remove</h5>
        <AutoComplete
          style={{ width: 300 }}
          dataSource={map(this.state.selectedDocumentTags, 'tagName')}
          filterOption={true}
          placeholder="Pick a Tag or enter a new one"
          onSelect={tagToRemove => {
            if (isString(tagToRemove)) {
              this.setState({
                tagToRemove,
              });
            }
          }}
        />
        {this.submitRemoveTagSection()}
      </>
    );
  }

  submitRemoveTagSection() {
    if (this.state.tagToRemove) {
      return (
        <SubmitButton onClick={this.submitRemoveTag}>
          {`Remove the tag "${
            this.state.tagToRemove
          }" from ${this.selectedCount().toLocaleString('en')} documents`}
        </SubmitButton>
      );
    }
  }

  submitRemoveTag = () => {
    if (this.state.tagToRemove) {
      const tagId = this.props.tagsByName[this.state.tagToRemove]
        .groupScopedTagId;
      if (this.props.selectedResults.inverseSelected) {
        this.props.removeTagFromDocumentsBulk({ tagId });
      } else if (this.props.selectedResults.selectedIds.length) {
        const annotatedDocumentIds = this.props.selectedResults.selectedIds;
        this.props.removeTagFromDocuments({ tagId, annotatedDocumentIds });
      }
      this.props.clearSelectedSearchResults();
    }
    this.setState({
      actionMode: 'select',
      tagToRemove: undefined,
      actionsModalVisible: false,
    });
  };

  submitExportJob(output: string, strategy: string, limit: number) {
    const exportParams = { output, strategy, limit };
    const exportJobId = uuid();
    const query = this.props.query;
    this.props.submitExportJob(exportJobId, query, exportParams);
  }
}
