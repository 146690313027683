import { AppState } from '../../app-state';

export const selectDocumentSelectorIsVisible = (state: AppState) =>
  state._UI_documentSelector.visible;

export const selectPdfViewerSelectionMode = (state: AppState) =>
  state._UI_settings.pdfViewerSettings.selectionMode;

export const selectPdfViewerRotation = (state: AppState) =>
  state._UI_pdfViewer.rotation;

export const selectPdfViewerZoom = (state: AppState) =>
  state._UI_pdfViewer.zoom;
