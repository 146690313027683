import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { AnnotationMetadata } from '../models/annotation.model';
import { connect } from 'react-redux';
import { AppState } from '../../app-state';
import { selectAnnotationMetadata } from '../selectors/annotation.selectors';

export type PlainCellProps<T> = {
  data?: T;
  renderData?: (_: T) => React.ReactNode;
};

export function PlainCell<T>(props: PlainCellProps<T>) {
  const identity = (t: T) => t;
  const renderData = props.renderData || identity;
  const render = (t?: T) => (t ? renderData(t) : null);
  return <>{render(props.data)}</>;
}

type RenderDataProps<T> = {
  getData: (a: AnnotationMetadata) => T;
  renderData: (a: T) => React.ReactNode;
};

export type PlainCellContainerProps<T> = RenderDataProps<T> &
  ICellRendererParams;

function getDataFrom<T>(state: AppState, props: PlainCellContainerProps<T>) {
  const metadata = selectAnnotationMetadata(state).get(props.data.annotationId);
  return metadata ? props.getData(metadata) : undefined;
}

export default connect(
  (state: AppState, ownProps: PlainCellContainerProps<any>) => ({
    data: getDataFrom(state, ownProps),
  }),
  _ => ({}),
)(PlainCell);
