/* eslint-disable jsx-a11y/anchor-is-valid */
import { Collapse } from 'antd';
import Alert, { AlertProps } from 'antd/lib/alert';
import Icon from 'antd/lib/icon';
import { RcFile } from 'antd/lib/upload';
import { capitalize, toString, uniq } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { BulkAnnotationUploadDetailsProps } from '../selectors/bulk-annotation-status-message.selectors';

const StatusPanel = styled.section`
  max-height: 430px;
  height: 100%;
  overflow-y: scroll;
`;

const ErrorMessageList = styled.ul`
  font-size: 12px;
`;

const CsvStatusIcon = (status = '') => {
  const StatusIcon = props => <Icon {...props} />;
  const statusIconPropsMap = {
    uploading: { type: 'sync', spin: true },
    success: {
      type: 'check-circle',
    },
    partialSuccess: {
      type: 'exclamation-circle',
    },
    failure: { type: 'close-circle' },
  };
  const currentStatusIcon = statusIconPropsMap[status];
  return (
    <>
      <span style={{ paddingRight: '5px' }}>
        {status === 'partialSuccess' ? 'Partial Success' : capitalize(status)}
      </span>
      <StatusIcon {...currentStatusIcon} />
    </>
  );
};

const statusBackground = (status: string | undefined): AlertProps['type'] => {
  switch (status) {
    case 'uploading':
      return 'info';
    case 'partialSuccess':
      return 'warning';
    case 'success':
      return 'success';
    case 'failure':
      return 'error';
    default:
      return undefined;
  }
};

export const ManualAnnotationImporterUploadStatusBox: React.FC<{
  resetUploadData: () => void;
  documentSetId: number;
  uploadData: RcFile | undefined;
  uploadDetails: BulkAnnotationUploadDetailsProps;
  totalCsvItems: {
    totalDocs: number;
    totalAnnotations: number;
  };
}> = ({
  resetUploadData,
  documentSetId,
  uploadData,
  uploadDetails,
  totalCsvItems,
}) => {
  // list of unique error details. Noone wants to see the same error 30 times.
  const uniqueDetails = uploadDetails.errorList
    ? uniq(uploadDetails.errorList)
    : [];
  const detailList = uploadDetails.errorList ? (
    <ErrorMessageList>
      {uniqueDetails.map((detail, i) => (
        <li style={{ marginTop: '1em' }} key={i}>
          <>{detail}</>
        </li>
      ))}
    </ErrorMessageList>
  ) : (
    ''
  );

  return (
    <>
      <p>
        Document successfully uploaded.{' '}
        <a onClick={resetUploadData}>Click here</a> to upload another file.
      </p>
      <Collapse
        defaultActiveKey={[toString(documentSetId)]}
        style={{ marginTop: '1em' }}
      >
        <Collapse.Panel
          header={uploadData ? uploadData.name : ''}
          key={toString(documentSetId)}
          extra={CsvStatusIcon(uploadDetails.status)}
        >
          <StatusPanel>
            <section>
              <Alert
                message={uploadDetails.message}
                type={statusBackground(uploadDetails.status)}
                description={detailList}
              />
            </section>
            <section style={{ margin: '1em 0 1em 1em' }}>
              <p>
                {uploadDetails.successfulDocumentCount || 0} of{' '}
                {totalCsvItems.totalDocs} documents were updated.
              </p>
              {uploadDetails.status === 'success' ? (
                <p>{totalCsvItems.totalAnnotations} attributes updated.</p>
              ) : (
                ''
              )}
            </section>
          </StatusPanel>
        </Collapse.Panel>
      </Collapse>
    </>
  );
};
