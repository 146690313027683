import { AppState } from '../../app-state';
import { AnnotatedDocument } from '../models/annotated-document.model';
import { sortBy, values } from 'lodash';

export const selectDocumentList = (state: AppState) =>
  state.documentList.entities;

export const selectDocumentListArray = (state: AppState) =>
  values(state.documentList.entities);

export const selectDocumentListArraySorted = (state: AppState) => {
  const sortField = state.documentList.sort?.field ?? 'annotatedDocumentId';
  const sortOrder = state.documentList.sort?.order ?? 'ascend';
  const docs = sortBy(
    selectDocumentListArray(state),
    d => d[sortField] as string,
  );
  return sortOrder === 'ascend' ? docs : docs.reverse();
};

export const selectDocumentListSort = (state: AppState) =>
  state.documentList.sort;

export const selectDocumentListIsLoading = (state: AppState) =>
  state.documentList.loading;

export const selectDocumentListFilter = (state: AppState) =>
  state.documentList.filter;

export const selectDocumentListCurrentPage = (state: AppState) =>
  state.documentList.page;

export const selectDocumentListPageCount = (state: AppState) =>
  state.documentList.pageCount;

export const selectDocumentListTotalItems = (state: AppState) =>
  state.documentList.totalItems;

export const selectAnnotatedDocumentById = (
  state: AppState,
  { annotatedDocumentId }: { annotatedDocumentId: number },
): AnnotatedDocument | null =>
  state.documentList.entities[annotatedDocumentId] || null;
