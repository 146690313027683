import React from 'react';
import { connect } from 'react-redux';
import { isArray } from 'util';
import { AppState } from '../../app-state';
import { SelectedPermission } from '../models/permission.model';
import { createIsPermittedSelector } from '../selectors/users.selectors';

interface RestrictedProps {
  children: React.ReactNode | React.ReactNode[];
  required: SelectedPermission | SelectedPermission[];
  fallback?: React.ReactNode | React.ReactNode[];
}
interface FinalProps extends RestrictedProps {
  isPermitted: boolean;
}

const selectPermissionsFromProps = (_: AppState, props: RestrictedProps) =>
  isArray(props.required) ? props.required : [props.required];

const selectIsPermitted = createIsPermittedSelector(selectPermissionsFromProps);

export const Restricted = connect(
  (state: AppState, ownProps: RestrictedProps) => ({
    isPermitted: selectIsPermitted(state, ownProps),
  }),
)((props: FinalProps) => {
  return <>{props.isPermitted ? props.children : props.fallback}</>;
});
