import { ActionsObservable, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { catchError, filter as filterObs, map as mapObs } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AppState } from '../../app-state';
import { handleEpicError } from '../../common/utils/epics';
import { AppActions } from '../../root.actions';
import {
  decrementPage,
  incrementPage,
  setPage,
  setSyncViews,
  toggleSyncViews,
} from '../actions/page-controls.actions';
import { selectSyncViews } from '../selectors/page-controls.selectors';

export function incrementPageEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filterObs(isActionOf(incrementPage)),
    mapObs(action => {
      const state = state$.value;
      const { view } = action.payload;
      const page = state.pageControls.pageByView.get(view) || 1;

      return setPage(view, page + 1);
    }),
    catchError((error: AjaxError) => {
      const errorMessage = 'Failed to increment page number';
      return handleEpicError(errorMessage)(error);
    }),
  );
}

export function decrementPageEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filterObs(isActionOf(decrementPage)),
    mapObs(action => {
      const state = state$.value;
      const { view } = action.payload;
      const page = state.pageControls.pageByView.get(view) || 0;

      return setPage(view, page - 1);
    }),
    catchError((error: AjaxError) => {
      const errorMessage = 'Failed to decrement page number';
      return handleEpicError(errorMessage)(error);
    }),
  );
}

export function toggleSyncViewsEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filterObs(isActionOf(toggleSyncViews)),
    mapObs(_ => {
      const state = state$.value;
      return setSyncViews(!selectSyncViews(state));
    }),
    catchError((error: AjaxError) => {
      const errorMessage = 'Failed to decrement page number';
      return handleEpicError(errorMessage)(error);
    }),
  );
}
