import { DataSourceItemObject } from 'antd/lib/auto-complete';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Observable } from 'rxjs';
import { fetchAllGroupAccounts } from '../../accounts/actions/groups.actions';
import { Group } from '../../accounts/models/group.model';
import { User } from '../../accounts/models/user.model';
import { selectAllGroupsArray } from '../../accounts/selectors/groups.selectors';
import { selectUsersArray } from '../../accounts/selectors/users.selectors';
import { AppState } from '../../app-state';
import CardWithTitle from '../../common/components/card-with-title.component';
import { useFetchedState } from '../../common/hooks/use-fetched-state.hook';
import { useInterval } from '../../common/hooks/use-interval.hook';
import {
  addMemberToRole,
  deleteMemberFromRole,
  fetchRoles as fetchRolesAction,
} from '../actions/role.actions';
import { AddMemberToRoleComponent } from '../components/add-member-to-role.component';
import { MemberToRoleTableComponent } from '../components/member-role-table.component';
import { CurrentRoleInfo, Role } from '../models/role.model';
import { MemberType, RolesResource } from '../resources/roles.resource';
import {
  selectAllRoles,
  selectRoleTitleAndDescriptionsDataSource,
} from '../selectors/roles.selectors';
export interface MemberRolesProps {
  users: User[];
  groups: Group[];
  roles: Role[];
  roleTitleAndDescriptions: DataSourceItemObject[];
  getCurrentRole: (roleId: number | null) => Observable<CurrentRoleInfo>;
  fetchGroups: () => void;
  fetchRoles: () => void;
  addNewMemberToRole: (payload: MemberToRoleProps) => void;
  deleteCurrentMemberFromRole: (payload: MemberToRoleProps) => void;
}

export type MemberToRoleProps = {
  roleId: number;
  memberId: number;
  memberType: MemberType;
};

export const MemberRolesComponent: React.FC<MemberRolesProps> = ({
  users,
  groups,
  roles,
  getCurrentRole,
  fetchGroups,
  fetchRoles,
  addNewMemberToRole,
  deleteCurrentMemberFromRole,
  roleTitleAndDescriptions,
}) => {
  useEffect(() => {
    fetchGroups();
    fetchRoles();
  }, [fetchGroups, fetchRoles]);
  const currentMemberTypeState = useState<MemberType | null>('groups');
  const [currentRoleId, setCurrentRoleId] = useState<number | null>(null);
  const [roleAction, setRoleAction] = useState<boolean>(false);
  const [currentRole] = useFetchedState(getCurrentRole(currentRoleId), [
    currentRoleId,
    roleAction,
  ]);
  useInterval(
    () => {
      if (roleAction) {
        setRoleAction(false);
      }
    },
    roleAction ? 500 : null,
  );
  return (
    <CardWithTitle
      style={{ margin: '1em 1em 0 1em', minHeight: '90vh' }}
      title="Member Roles"
    >
      <AddMemberToRoleComponent
        users={users}
        groups={groups}
        roles={roles}
        setRoleAction={setRoleAction}
        roleTitleAndDescriptions={roleTitleAndDescriptions}
        addNewMemberToRole={addNewMemberToRole}
        currentRoleIdState={[currentRoleId, setCurrentRoleId]}
        currentMemberTypeState={currentMemberTypeState}
      />
      <MemberToRoleTableComponent
        setRoleAction={setRoleAction}
        currentRole={currentRole}
        deleteCurrentMemberFromRole={deleteCurrentMemberFromRole}
      />
    </CardWithTitle>
  );
};

export const MemberRoles = connect(
  (state: AppState) => ({
    users: selectUsersArray(state),
    groups: selectAllGroupsArray(state),
    roles: selectAllRoles(state),
    roleTitleAndDescriptions: selectRoleTitleAndDescriptionsDataSource(state),
    getCurrentRole: RolesResource.getCurrentRoleInfo(state),
  }),
  dispatch => ({
    fetchGroups: () => dispatch(fetchAllGroupAccounts()),
    fetchRoles: () => dispatch(fetchRolesAction()),
    addNewMemberToRole: (payload: MemberToRoleProps) =>
      dispatch(addMemberToRole.request(payload)),
    deleteCurrentMemberFromRole: (payload: MemberToRoleProps) =>
      dispatch(deleteMemberFromRole.request(payload)),
  }),
)(MemberRolesComponent);
