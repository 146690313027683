import { Button, List, Select, Tooltip } from 'antd';
import { some, sortBy } from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  PermissionActions,
  PermissionResources,
} from '../../accounts/models/permission.model';
import { selectHasPermissions } from '../../accounts/selectors/users.selectors';
import { AppState } from '../../app-state';
import { LargeCenteredLoader } from '../../common/components/large-centered-loader.component';
import { config } from '../../config/application.config';
import { documentIsDownloadable } from '../../documents/util/document.util';
import { pageChange as pageChangeAction } from '../actions/search.actions';
import { ActionModalContainer } from '../containers/selected-items-actions-modal.container';
import { Hit, QueryResponse } from '../models/query-response.model';
import { SelectedResultsState } from '../reducers/search.reducer';
import { selectAnnotatioTypesWithExistsConditionsInQuery } from '../selectors';
import { SearchActionLinks } from './search-action-links.component';
import { SearchResultListItem } from './search-result-list-item.component';

const ResultHeaderSection = styled.section``;

const Total = styled.h3`
  display: inline;
  margin-right: 18px;
  font-weight: bold;
  color: #475763;
`;

const ListWrapper = styled.div`
  .search-result {
    margin-left: -10px;
    padding-left: 10px;
    &:nth-child(2n) {
      background-color: #f7f8fa;
    }

    .ant-list-item {
      padding: 8px 0;
    }

    .ant-list-item-meta-description {
      line-height: 20px;
    }
  }
`;

const ActionModalWrap = styled.span`
  margin-left: 5px;
`;

const Right = styled.div`
  float: right;
`;

type RenderListItemProps = {
  inverseSelected: boolean;
  selectedIds: number[];
  showAnnotationErrors: boolean;
  shownFieldsOverride: string[];
  downloadDocumentById: (annotatedDocumentId: number, saveAs: string) => void;
  onSelect: (id: number) => void;
};

export type SearchResultsProps = {
  results: QueryResponse | null;
  pageSize: number;
  currentPage: number;
  loadingResults: boolean;
  selectedResults: SelectedResultsState;
  shownFieldsOverride: string[];
  showAnnotationErrors: boolean;
  pageChange: (page: number) => void;
  searchResultSelect: (id: number) => void;
  allSearchResultsChecked: () => void;
  downloadDocumentById: (annotatedDocumentId: number, saveAs: string) => void;
  changeSearchResultOrder: (field: string, desc: boolean) => void;
  orderBy: { field: string; desc: boolean };
};

function renderListItem({
  inverseSelected,
  selectedIds,
  showAnnotationErrors,
  shownFieldsOverride,
  downloadDocumentById,
  onSelect,
}: RenderListItemProps): React.FunctionComponent<Hit> {
  return (item: Hit) => {
    const id = item._source.annotated_document_id;
    const aggregateId = item._source.aggregate_id;
    const clicked = some(selectedIds, x => x === id);
    const selected = inverseSelected ? !clicked : clicked;
    const hasDocumentText = item._source.document_text_id !== null;

    const searchActionLinks = (
      <SearchActionLinks
        aggregateId={aggregateId}
        docIsDownloadable={documentIsDownloadable(item._source)}
        hasDocumentText={hasDocumentText}
        downloadDocumentById={downloadDocumentById}
        annotatedDocumentId={id}
        style={{ fontSize: '22px', marginLeft: '10px' }}
      />
    );
    return (
      <SearchResultListItem
        key={item._id}
        item={item}
        shownFieldsOverride={shownFieldsOverride}
        searchActionLinks={searchActionLinks}
        selectable={true}
        selected={selected}
        showAnnotationErrors={showAnnotationErrors}
        hasDocumentText={hasDocumentText}
        onSelect={onSelect}
      />
    );
  };
}

const UnconnectedSearchResults: React.FunctionComponent<SearchResultsProps> = ({
  results,
  pageSize,
  currentPage,
  loadingResults,
  selectedResults,
  shownFieldsOverride,
  showAnnotationErrors,
  pageChange,
  searchResultSelect,
  allSearchResultsChecked,
  downloadDocumentById,
  changeSearchResultOrder,
  orderBy,
}) => {
  const [selectAllTooltipVisible, setSelectAllTooltipVisible] = useState<
    boolean
  >(false);

  const { inverseSelected, selectedIds } = selectedResults;

  const nResults = results ? results.hits.total : 0;
  const nResultsPretty = nResults.toLocaleString('en');
  const preventSelectAll = nResults >= config.queues.bulkMax;
  const showActionModal = inverseSelected || selectedIds.length > 0;

  const resolvedResults = results ? results.hits.hits : [];

  const orderByList = sortBy(
    [
      {
        label: 'Sort By: Created At (asc)',
        field: 'created_at',
        desc: false,
      },
      {
        label: 'Sort By: Recorded Date (asc)',
        field: 'recorded_date',
        desc: false,
      },
      {
        label: 'Sort By: Document Set (asc)',
        field: 'document_set.keyword',
        desc: false,
      },
      {
        label: 'Sort By: Classification (asc)',
        field: 'metadata.instrumentType.keyword',
        desc: false,
      },
      {
        label: 'Sort By: County (asc)',
        field: 'metadata.county.keyword',
        desc: false,
      },
      {
        label: 'Sort By: Created At (desc)',
        field: 'created_at',
        desc: true,
      },
      {
        label: 'Sort By: Recorded Date (desc)',
        field: 'recorded_date',
        desc: true,
      },
      {
        label: 'Sort By: Document Set (desc)',
        field: 'document_set.keyword',
        desc: true,
      },
      {
        label: 'Sort By: Classification (desc)',
        field: 'metadata.instrumentType.keyword',
        desc: true,
      },
      {
        label: 'Sort By: County (desc)',
        field: 'metadata.county.keyword',
        desc: true,
      },
    ],
    o => `${o.field}${o.desc}`,
  );

  const orderByOptions = orderByList.map(o => {
    return { value: o.label };
  });
  const defaultOrderOption = orderByList.find(
    o => o.field === orderBy.field && o.desc === orderBy.desc,
  )?.label;

  if (loadingResults) {
    return <LargeCenteredLoader />;
  } else if (results) {
    return (
      <div>
        <ResultHeaderSection>
          <Total>{nResultsPretty} Total Results</Total>
          <Tooltip
            overlayStyle={{ maxWidth: '600px' }}
            visible={selectAllTooltipVisible}
            onVisibleChange={v =>
              setSelectAllTooltipVisible(v && preventSelectAll)
            }
            title={`Can only select ${config.queues.bulkMax.toLocaleString(
              'en',
            )} or less results at a time`}
          >
            <Button
              disabled={preventSelectAll}
              onClick={allSearchResultsChecked}
            >
              {inverseSelected ? 'Unselect All' : 'Select All'}
            </Button>
            <Right>
              <Select
                defaultValue={defaultOrderOption}
                onChange={v => {
                  const params = orderByList.find(o => o.label === v);
                  if (params) {
                    changeSearchResultOrder(params.field, params.desc);
                  }
                }}
                options={orderByOptions}
              />
            </Right>
          </Tooltip>
          {showActionModal && (
            <ActionModalWrap>
              <ActionModalContainer hitCount={nResults} />
            </ActionModalWrap>
          )}
        </ResultHeaderSection>
        <ListWrapper>
          <List
            rowKey={(record: Hit) => record._id}
            dataSource={resolvedResults}
            itemLayout="vertical"
            renderItem={renderListItem({
              inverseSelected,
              selectedIds,
              showAnnotationErrors,
              shownFieldsOverride,
              downloadDocumentById,
              onSelect: searchResultSelect,
            })}
            pagination={{
              current: currentPage,
              onChange: pageChange,
              pageSize,
              simple: true,
              total: nResults,
            }}
          />
        </ListWrapper>
      </div>
    );
  } else {
    return <></>;
  }
};

export const SearchResults = connect(
  (state: AppState) => ({
    loadingResults: state.search.loading,
    results: state.search.results,
    shownFieldsOverride: selectAnnotatioTypesWithExistsConditionsInQuery(state),
    showAnnotationErrors: selectHasPermissions([
      {
        action: PermissionActions.Annotate,
        resource: PermissionResources.DocumentSet,
      },
    ])(state),
  }),
  { pageChange: pageChangeAction },
)(UnconnectedSearchResults);
