import { keyBy, map } from 'lodash';
import { createSelector } from 'reselect';
import { AppState } from '../../app-state';

export const selectGroupScopedTags = (state: AppState) =>
  state.tags.groupScopedTags;

export const selectGroupScopedTagNames = createSelector(
  [selectGroupScopedTags],
  tags => map(tags, t => t.tagName),
);

export const selectGroupScopedTagsGroupedByName = createSelector(
  [selectGroupScopedTags],
  tags => keyBy(tags, tag => tag.tagName),
);
