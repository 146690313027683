import { ActionsObservable, StateObservable } from 'redux-observable';
import { empty, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import {
  setAnnotationReviewStatus,
  setReviewingSaveEventId,
} from '../../annotation-review/actions/annotation-review.actions';
import { AppState } from '../../app-state';
import { handleEpicError } from '../../common/utils/epics';
import { setSourceDocument } from '../../documents/actions/source-document.actions';
import { AppActions } from '../../root.actions';
import {
  addAnnotationDocumentStatus,
  populateAnnotationDocumentStatuses,
  removeAnnotationDocumentStatus,
  saveAnnotationDocumentStatuses,
  saveAnnotationDocumentStatusesError,
  saveAnnotationDocumentStatusesSuccess,
} from '../actions/annotation-document-status.actions';
import { AnnotationDocumentStatusResource } from '../resources/annotation-document-status.resource';
import { selectCurrentAnnotationDocumentStatuses } from '../selectors/document-status.selectors';

export function getAnnotationDocumentStatusesEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(setSourceDocument)),
    switchMap(action => {
      const annotatedDocumentId = action.payload.document.annotatedDocumentId;
      return AnnotationDocumentStatusResource.get(
        state$.value,
        annotatedDocumentId,
      ).pipe(
        map(resp => populateAnnotationDocumentStatuses(resp)),
        catchError(handleEpicError('Error fetching document statuses')),
      );
    }),
  );
}

export function saveAnnotationDocumentStatusesEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(saveAnnotationDocumentStatuses)),
    switchMap(() => {
      const state = state$.value;
      const annotatedDocumentId = state.annotationPages.sourceDocumentId;
      if (!annotatedDocumentId) {
        throw new Error(
          'Unable to save document statuses. No sourceDocumentId.',
        );
      } else {
        const documentStatuses = {
          statuses: selectCurrentAnnotationDocumentStatuses(state),
        };
        return AnnotationDocumentStatusResource.post(
          state,
          annotatedDocumentId,
          documentStatuses,
        ).pipe(map(resp => saveAnnotationDocumentStatusesSuccess(resp)));
      }
    }),
    catchError(
      handleEpicError(
        'Error saving document statuses',
        saveAnnotationDocumentStatusesError,
      ),
    ),
  );
}

export function setReviewedFromDocumentStatusEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(addAnnotationDocumentStatus)),
    switchMap(action => {
      const status = action.payload.status;
      if (status && status.status === REVIEWED) {
        return of(setAnnotationReviewStatus(true));
      }
      return empty();
    }),
  );
}

export function addReviewedAnnotationDocumentStatusEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(setReviewingSaveEventId)),
    switchMap(action => {
      const id = action.payload.saveEventId;
      const state = state$.value;
      if (id) {
        return of(
          populateAnnotationDocumentStatuses({
            documentStatuses: state.annotationDocumentStatuses,
          }),
        );
      }
      return empty();
    }),
  );
}

export function removeReviewedAnnotationDocumentStatusEpic(
  action$: ActionsObservable<AppActions>,
) {
  return action$.pipe(
    filter(isActionOf(removeAnnotationDocumentStatus)),
    switchMap(action => {
      const status = action.payload.status;
      if (status && status.status === REVIEWED) {
        return of(setAnnotationReviewStatus(false));
      }
      return empty();
    }),
  );
}

export const REVIEWED = 'Reviewed';
