import { map } from 'lodash';
import { Observable, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map as observableMap } from 'rxjs/operators';
import { selectCurrentUserGroup } from '../../accounts/selectors/groups.selectors';
import { AppState } from '../../app-state';
import { CONTENT_TYPE_HEADERS, authHeaders } from '../../common/utils/fetch';
import { config } from '../../config/application.config';
import {
  selectCurrentQueryWithIds,
  selectIndicesToSearch,
} from '../../search/selectors';
import { OmitKey } from '../../utility.types';
import { QueueItem } from '../models/queue-item.model';
import { Queue } from '../models/queue.model';
import { WorkableQueueItem } from '../models/workable-queue-item.model';

type QueuesResource = Queue[];

type QueueCreationRequest = {
  queueName: string;
  groupId: number;
  adminGroups: number[];
  workerGroups: number[];
};

export type QueueStatusResponse = {
  queueId: number;
  totalItems: number;
  completedItems: number;
  remainingItems: number;
};

export type QueueStatusFailure = {
  failedQueueId: number;
};

export type NextQueueItemResponse = {
  next_item: WorkableQueueItem;
};

type CompleteQueueItemResponse = {
  completed_item: QueueItem;
};

type DeleteQueueResponse = {
  deleted_queue_id: number;
};

type QueueCreationParams = OmitKey<QueueCreationRequest, 'groupId'>;

export const QueuesResource = {
  queuesUrl: `${config.annotationService.url}/queues`,

  queueStatusUrl(queueId: number): string {
    return `${this.queuesUrl}/${queueId}/status`;
  },

  queueItemsUrl(queueId: number): string {
    return `${this.queuesUrl}/${queueId}/items`;
  },

  queueItemsBulkUrl(queueId: number): string {
    return `${this.queuesUrl}/${queueId}/bulk`;
  },

  nextQueueItemUrl(queueId: number): string {
    return `${this.queueItemsUrl(queueId)}/next`;
  },

  completeQueueItemUrl(queueId: number, queueItemId: number): string {
    return `${this.queuesUrl}/${queueId}/items/${queueItemId}/complete`;
  },

  queueUrl(queueId: number): string {
    return `${this.queuesUrl}/${queueId}`;
  },

  create(state: AppState, params: QueueCreationParams) {
    return ajax.post(this.queuesUrl, toCreateRequest(state)(params), {
      ...authHeaders(state),
      ...CONTENT_TYPE_HEADERS.JSON,
    });
  },

  getAll(state: AppState): Observable<QueuesResource> {
    return ajax.getJSON<QueuesResource>(this.queuesUrl, authHeaders(state));
  },

  getStatus(
    state: AppState,
    queueId: number,
  ): Observable<QueueStatusResponse | QueueStatusFailure> {
    return ajax
      .getJSON<QueueStatusResponse>(
        this.queueStatusUrl(queueId),
        authHeaders(state),
      )
      .pipe(catchError(_ => of({ failedQueueId: queueId })));
  },

  getNextItem(state: AppState, queueId: number): Observable<WorkableQueueItem> {
    return ajax
      .getJSON<NextQueueItemResponse>(
        this.nextQueueItemUrl(queueId),
        authHeaders(state),
      )
      .pipe(observableMap(r => r.next_item));
  },

  completeQueueItem(
    state: AppState,
    queueId: number,
    queueItemId: number,
  ): Observable<QueueItem> {
    return ajax
      .post(
        this.completeQueueItemUrl(queueId, queueItemId),
        {},
        {
          ...authHeaders(state),
        },
      )
      .pipe(
        observableMap(r => {
          const response: CompleteQueueItemResponse = r.response;
          return response.completed_item;
        }),
      );
  },

  addQueueItems(
    state: AppState,
    queueId: number,
    annotatedDocumentIds: number[],
  ) {
    return ajax.post(
      this.queueItemsUrl(queueId),
      toAddQueueItemsRequest(annotatedDocumentIds),
      {
        ...authHeaders(state),
        ...CONTENT_TYPE_HEADERS.JSON,
      },
    );
  },

  addQueueItemsBulk(state: AppState, queueId: number) {
    return ajax.post(
      this.queueItemsBulkUrl(queueId),
      toAddQueueItemsBulkRequest(state),
      {
        ...authHeaders(state),
        ...CONTENT_TYPE_HEADERS.JSON,
      },
    );
  },

  delete(state: AppState, queueId: number) {
    return ajax.delete(this.queueUrl(queueId), {
      ...authHeaders(state),
    });
  },
};

const toCreateRequest = (state: AppState) => ({
  queueName,
  adminGroups,
  workerGroups,
}: QueueCreationParams): QueueCreationRequest => {
  const groupId = selectCurrentUserGroup(state).groupId;
  return {
    queueName,
    groupId,
    adminGroups,
    workerGroups,
  };
};

const toAddQueueItemsRequest = (ids: number[]) => {
  return map(ids, id => ({ annotatedDocumentId: id }));
};

const toAddQueueItemsBulkRequest = (state: AppState) => {
  return {
    indices: selectIndicesToSearch(state),
    ...selectCurrentQueryWithIds(state),
  };
};
