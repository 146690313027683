import React from 'react';
import styled from 'styled-components';
import { LookupTable } from '../containers/domain-lookup-table.container';

const LookupManagerStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const LookupManager: React.SFC = () => (
  <LookupManagerStyle>
    <LookupTable />
  </LookupManagerStyle>
);
