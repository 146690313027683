import { map } from 'lodash';
import { Permission } from '../../accounts/models/permission.model';

export const ResourceIdRangeHandler = {
  parseRangeFromRangeNotation: (notation: string): number[] | 'admin' => {
    const regexp = /^[(|[](\d+),(\d+)[)|\]]$/;
    switch (notation) {
      case '[,)':
      case '(,)':
      case '(,]':
      case '[,]':
        return 'admin';
      default:
        const resourceId = map(notation.match(regexp), e => parseInt(e, 10));
        const interval: number[] = [];
        for (let i = resourceId[1]; i < resourceId[2] + 1; i++) {
          interval.push(i);
        }
        interval.sort((a, b) => a - b);
        if (notation.includes('(')) {
          interval.shift();
        }
        if (notation.includes(')')) {
          interval.pop();
        }
        return interval;
    }
  },
  singleIdFromResourceRange: (notation: string) => {
    if (
      ResourceIdRangeHandler.parseRangeFromRangeNotation(notation) === 'admin'
    ) {
      return 'admin';
    }
    return ResourceIdRangeHandler.parseRangeFromRangeNotation(notation)[0];
  },
  activeDocumentSetIdsUserCanAdministrate: (permissions: Permission[]) =>
    map(
      Permission.findPermissions(permissions, '*', 'document-set'),
      permission =>
        ResourceIdRangeHandler.singleIdFromResourceRange(
          permission.resourceIdRange,
        ),
    ),
};
