import { parse } from 'query-string';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isArray, isUndefined } from 'util';
import { AppRoutes } from '../../app-routes';
import { AppState } from '../../app-state';
import { authenticateUser } from '../actions/auth.actions';
import { LoginForm, LoginFormProps } from '../components/login-form.component';
import {
  selectAuthError,
  selectUserIsLoggedIn,
} from '../selectors/auth.selectors';

export const LoginFormContainer = withRouter(
  connect(
    (
      state: AppState,
      ownProps: Pick<LoginFormProps, 'location' | 'redirectOnAuthenticate'>,
    ) => ({
      error: selectAuthError(state),
      isAuthenticated: selectUserIsLoggedIn(state),
      redirectOnAuthenticate: isUndefined(ownProps.redirectOnAuthenticate)
        ? true
        : ownProps.redirectOnAuthenticate,
      redirectUrl: getRedirectUrl(ownProps.location.search),
    }),
    {
      onSubmit: authenticateUser,
    },
  )(LoginForm),
);

function getRedirectUrl(queryString: string) {
  const params = parse(queryString);
  const { redirect } = params;
  if (redirect) {
    return isArray(redirect) ? redirect[0] : redirect;
  }
  return AppRoutes.Home;
}
