/* eslint-disable jsx-a11y/anchor-is-valid */
import { Checkbox, List, Tooltip } from 'antd';
import {
  flatMap,
  flow,
  get,
  head,
  isArray,
  map,
  mapKeys,
  pickBy,
  reduce,
  replace,
  startCase,
  startsWith,
  tail,
  take,
  uniq,
  values,
} from 'lodash';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AppRoutes, pathWithParams } from '../../app-routes';
import { EsAnnotatedDoc, Hit } from '../models/query-response.model';
import { DateComponent } from '../../common/components/date.component';

const Highlight = styled.span`
  em {
    background-color: #f7ea39;
  }
`;

const HighlightWrap = styled.div``;

const ListTitle = styled.span`
  font-size: 14px;

  :first-child {
    padding 4px 10px 4px 0px;
  }

  :nth-child(n):not(:first-child) {
    padding 4px 10px 4px 10px;
  }

  border-right: 1px solid darkgray;
`;

const ResultContent = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  width: 100%;
`;

const ResultContentSection = styled.span`
  flex: 1 0 50%;
  min-width: 500px;
`;

type SearchResultListItemProps = {
  item: Hit;
  selectable: boolean;
  searchActionLinks: JSX.Element;
  selected: boolean;
  shownFieldsOverride: string[];
  showAnnotationErrors: boolean;
  hasDocumentText: boolean;
  onSelect: (id: number) => void;
};

type SearchResultListItemState = {
  highlightsExpanded: boolean;
};

export class SearchResultListItem extends React.Component<
  SearchResultListItemProps,
  SearchResultListItemState
> {
  state: SearchResultListItemState = {
    highlightsExpanded: false,
  };

  render() {
    const { item, hasDocumentText } = this.props;
    return (
      <div
        className="search-result"
        style={{ display: 'flex', flexFlow: 'row' }}
      >
        {this.props.selectable && (
          <Checkbox
            checked={this.props.selected}
            style={{ flex: '0 1 10px', margin: '14px 14px 0px 0px' }}
            onChange={e =>
              this.props.onSelect(item._source.annotated_document_id)
            }
          />
        )}
        <ResultContent>
          <ResultContentSection style={{ paddingRight: '50px' }}>
            <List.Item>
              <List.Item.Meta
                title={this.listItemTitle(item._source, hasDocumentText)}
                description={this.listItemDescription(item)}
                style={{ wordBreak: 'break-word' }}
              />
            </List.Item>
          </ResultContentSection>
          <ResultContentSection style={{ padding: '10px', width: '50%' }}>
            {hasAnnotationHighlights(item.highlight) &&
              renderHighlights(item.highlight)}
            <AdditionalFields
              hit={item}
              includeErrors={this.props.showAnnotationErrors}
              fieldsToShow={this.props.shownFieldsOverride}
            />
          </ResultContentSection>
        </ResultContent>
      </div>
    );
  }

  listItemDescription(doc: Hit) {
    return (
      <>
        {this.showFirstDocTextHit(doc)}
        {this.state.highlightsExpanded && this.showAdditionalDocTextHits(doc)}
      </>
    );
  }

  showAdditionalDocTextHits(doc: Hit) {
    const otherHighlights = tail(get(doc, 'highlight.document_text'));
    if (isArray(otherHighlights)) {
      return (
        <div>
          {map(otherHighlights, h => {
            if (typeof h === 'string') {
              return (
                <HighlightWrap>
                  ...
                  <Highlight dangerouslySetInnerHTML={{ __html: h }} />
                  ...
                </HighlightWrap>
              );
            }
          })}
          {this.state.highlightsExpanded && this.toggleHighlightExpand()}
        </div>
      );
    }
  }

  toggleHighlightExpand() {
    return (
      <ContextToggle
        expanded={this.state.highlightsExpanded}
        onClick={() =>
          this.setState(state => ({
            highlightsExpanded: !state.highlightsExpanded,
          }))
        }
      />
    );
  }

  listItemTitle(doc: EsAnnotatedDoc, hasDocumentText: boolean) {
    const aggregateIdTitle = `${get(
      doc,
      'metadata.instrumentType',
      'Unknown',
    )} (${doc.aggregate_id})`;

    return (
      <>
        <div>
          {hasDocumentText ? (
            <Link
              to={pathWithParams(AppRoutes.AnnotationPage, {
                id: doc.annotated_document_id,
                action: 'view',
              })}
            >
              {aggregateIdTitle}
            </Link>
          ) : (
            <span>{aggregateIdTitle}</span>
          )}
        </div>
        <div>
          <Tooltip title="Recorded Date">
            <ListTitle>
              {doc.recorded_date ? (
                <DateComponent serializedDate={doc.recorded_date} />
              ) : (
                'N/A'
              )}
            </ListTitle>
          </Tooltip>
          <Tooltip title="County, State">
            <ListTitle>{`${get(doc, 'metadata.county', 'N/A')}, ${get(
              doc,
              'metadata.state',
              'N/A',
            )}`}</ListTitle>
          </Tooltip>
          <Tooltip title="Document Set">
            <ListTitle>{doc.document_set}</ListTitle>
          </Tooltip>
          {this.props.searchActionLinks}
        </div>
      </>
    );
  }

  showFirstDocTextHit(doc: Hit) {
    const firstHighlight = get(doc, 'highlight.document_text[0]');
    if (firstHighlight) {
      return (
        // I don't know of a way around dangerous set here since we need to actually render the em tag given back by the highlight api in es
        <HighlightWrap>
          ...
          <Highlight dangerouslySetInnerHTML={{ __html: firstHighlight }} />
          ...
          {!this.state.highlightsExpanded && this.toggleHighlightExpand()}
        </HighlightWrap>
      );
    }
    return;
  }
}

function getAnnotationHighlights(highlights: { [key: string]: string[] }) {
  return pickBy(highlights, (_, k) => startsWith(k, 'annotations'));
}

function hasAnnotationHighlights(highlights: {
  [key: string]: string[];
}): boolean {
  return values(getAnnotationHighlights(highlights)).length > 0;
}

function renderHighlights(highlights: { [key: string]: string[] }) {
  const desiredHighlights = getAnnotationHighlights(highlights);
  const desiredHighlightsRenamed = prepareAnnotationKeysForDisplay(
    desiredHighlights,
  );
  const readyToRenderHighlights = map(desiredHighlightsRenamed, (v, k) => {
    const firstHighlight = head(v);
    if (firstHighlight) {
      return renderSummary(k)(firstHighlight);
    }
    return null;
  });
  if (readyToRenderHighlights.length) {
    return (
      <div>
        <span>{readyToRenderHighlights}</span>
      </div>
    );
  }
}

function prepareAnnotationKeysForDisplay<T extends object>(obj: T) {
  return mapKeys(obj, (_, k) => prepareAnnotationKeyForDisplay(k));
}

function prepareAnnotationKeyForDisplay(key: string): string {
  const removeAnnotationPrefix = (k: string) => replace(k, 'annotations.', '');
  const removeKeywordSuffix = (k: string) => replace(k, '.keyword', '');

  return flow(removeAnnotationPrefix, removeKeywordSuffix, startCase)(key);
}

const renderSummary = (key: string) => (highlight: string) => {
  const highlightContext = (
    <Highlight dangerouslySetInnerHTML={{ __html: highlight }} />
  );
  return (
    <div key={key} style={{ marginBottom: '16px' }}>
      <strong>{key}</strong>
      <div>{highlightContext}</div>
    </div>
  );
};

type ContextToggleProps = {
  onClick: () => void;
  expanded: boolean;
};

const ContextToggle: React.SFC<ContextToggleProps> = ({
  onClick,
  expanded,
}) => (
  <a onClick={onClick}>
    {expanded ? ' Show Less Context' : ' Show More Context'}
  </a>
);

type AdditionalFieldsProps = {
  hit: Hit;
  fieldsToShow: string[];
  includeErrors: boolean;
  displayLimit?: number;
};

function AdditionalFields({
  hit,
  fieldsToShow,
  includeErrors,
  displayLimit = 5,
}: AdditionalFieldsProps) {
  const fieldMap: { [key: string]: string[] } = reduce(
    fieldsToShow,
    (acc, val) => ({
      ...acc,
      // these arrays need to be uniq-ed due to the fact that
      // with certain annotation types the values can be duplicated
      // because of being nested into other annotation types
      // Eventually we will change the elastic search representation
      // to be unique: https://jira.oseberg.io/browse/AI-370
      [prepareAnnotationKeyForDisplay(val)]: uniq(get(hit._source, val, [])),
    }),

    {},
  );
  const annotationErrorItems = includeErrors
    ? flatMap(get(hit._source, 'errors.annotations', {}), (value, key) => {
        return map(value, e => (
          <AnnotationError
            annotationKey={key}
            errorMessage={e.message}
            originalValue={e.value}
          />
        ));
      })
    : [];

  const annotationItemsToRender = flatMap(fieldMap, (field, key) =>
    field.map(renderSummary(key)),
  );

  const potentialItemsToRender = [
    ...annotationItemsToRender,
    ...annotationErrorItems,
  ];

  // yes, we could definitely calculate this ahead of time, but given the
  // fact that the content being rendered is static, we don't run much risk
  // of this slight inefficiency compounding to any kind of noticeable user
  // experience degradation. That said, doing it this way makes it more clear
  // what is actually happening.
  const needToTruncate = potentialItemsToRender.length > displayLimit;
  const [showingAll, setShowingAll] = useState(!needToTruncate);

  const itemsToRender =
    needToTruncate && !showingAll
      ? take(potentialItemsToRender, displayLimit)
      : potentialItemsToRender;

  return (
    <div>
      {itemsToRender}
      {needToTruncate && (
        <ContextToggle
          expanded={showingAll}
          onClick={() => setShowingAll(!showingAll)}
        />
      )}
    </div>
  );
}

type AnnotationErrorProps = {
  annotationKey: string;
  errorMessage: string;
  originalValue: string;
};

function AnnotationError({
  annotationKey,
  errorMessage,
  originalValue,
}: AnnotationErrorProps) {
  return (
    <div
      key={`errors.annotations.${annotationKey}`}
      style={{ border: '1px solid red', borderRadius: '8px', padding: '4px' }}
    >
      <strong>Annotation Error:</strong>
      <div>
        <strong>attribute:</strong> {annotationKey}
      </div>
      <div>
        <strong>message:</strong> {errorMessage}
      </div>
      <div>
        <strong>original value:</strong> {originalValue}
      </div>
    </div>
  );
}
