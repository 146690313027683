import download from 'downloadjs';
import { map } from 'lodash';
import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map as observableMap, tap } from 'rxjs/operators';
import { AppState } from '../../app-state';
import { KeyValueMap } from '../../common/types/KeyValueMap.type';
import { CONTENT_TYPE_HEADERS, authHeaders } from '../../common/utils/fetch';
import { config } from '../../config/application.config';
import { DocumentSetsState } from '../../documents/reducers/document-sets.reducer';
import { SelectableDocumentSet } from '../components/search-form.component';
import {
  ExportDownloadResponse,
  ExportParams,
  ExportProgress,
} from '../models/export.model';
import { QueryResponse } from '../models/query-response.model';
import {
  selectIndicesToSearch,
  selectSelectedSearchableDocumentSets,
} from '../selectors';
import { selectDocumentSetSearchIndices } from '../utils';

export const SearchResource = {
  exportIndicesUrl: (documentSets: KeyValueMap<SelectableDocumentSet>) => {
    const indices: string[] = selectDocumentSetSearchIndices(documentSets);
    return `${config.exportService.url}/search/${indices.join(',')}`;
  },

  exportProgressUrl: (jobId: string) => {
    return `${config.exportService.url}/progress?jobId=${jobId}`;
  },

  downloadExportUrl: (jobId: string) => {
    return `${config.exportService.url}/export?jobId=${jobId}`;
  },

  searchUrl: config.annotationService.url + '/search',

  preparedSearchUrl(
    indices: string,
    limit: number,
    offset: number,
    fragmentSize: number,
    orderBy: string,
    orderDesc: boolean,
  ): string {
    return `${this.searchUrl}/${indices}?limit=${limit}&offset=${offset}&fragmentSize=${fragmentSize}&orderBy=${orderBy}&orderDesc=${orderDesc}`;
  },
  preparedMappingsUrl(indices: string): string {
    return `${this.searchUrl}/${indices}/mapping`;
  },
  query(state: AppState): Observable<QueryResponse> {
    const limit = state.search.pageSize;
    const currentPage = state.search.currentPage;
    const offset = calculateOffset(limit, currentPage);
    const fragmentSize = state.search.fragmentSize;
    const orderBy = state.search.orderBy.field;
    const orderDesc = state.search.orderBy.desc;
    return ajax({
      url: this.preparedSearchUrl(
        selectIndicesToSearch(state),
        limit,
        offset,
        fragmentSize,
        orderBy,
        orderDesc,
      ),
      method: 'POST',
      body: JSON.stringify(state.search.query),
      headers: { ...authHeaders(state), ...CONTENT_TYPE_HEADERS.JSON },
      responseType: 'json',
    }).pipe(observableMap(e => e.response));
  },
  mappings(state: AppState): Observable<any> {
    return ajax.getJSON(
      this.preparedMappingsUrl(
        documentSetGroupNameString(state.documentSets).join(','),
      ),
      authHeaders(state),
    );
  },

  exportProgress(jobId: string): Observable<ExportProgress> {
    return ajax.getJSON(SearchResource.exportProgressUrl(jobId));
  },

  downloadExport(
    jobId: string,
    saveAs: string,
  ): Observable<ExportDownloadResponse> {
    return ajax({
      url: SearchResource.downloadExportUrl(jobId),
      method: 'GET',
      responseType: 'blob',
      headers: {
        ...CONTENT_TYPE_HEADERS.JSON,
        Accept: 'application/zip',
      },
    }).pipe(
      tap(resp => {
        download(resp.response, saveAs);
      }),
    );
  },

  submitExportJob: (state: AppState) => (
    query: object,
    params: ExportParams,
  ): Observable<string> => {
    const serialized: any = { ...params, ...query };

    const restCall = {
      url: SearchResource.exportIndicesUrl(
        selectSelectedSearchableDocumentSets(state),
      ),
      body: serialized,
      method: 'POST',
      headers: {
        ...authHeaders(state),
        ...CONTENT_TYPE_HEADERS.JSON,
        Accept: 'application/json',
      },
    };
    return ajax(restCall).pipe(observableMap(r => r.response.jobId));
  },
};

function calculateOffset(limit: number, currentPage: number) {
  // the first result is offset 0
  // so the first item offset of page two given 10 results per page is 10
  const offsetForNotPageOne = (currentPage - 1) * limit;
  return currentPage === 1 ? 0 : offsetForNotPageOne;
}

export const documentSetGroupNameString = (state: DocumentSetsState) => {
  return map(state.entities, (v, k) => `${v.groupId}-${v.name}`);
};
