import { DocumentLockResource } from '../resources/document-lock.resource';

export type DocumentLockStatus = 'none' | 'pending' | 'active' | 'error';
export const DOCUMENT_LOCK_STATUS = {
  NONE: 'none' as DocumentLockStatus,
  PENDING: 'pending' as DocumentLockStatus,
  ACTIVE: 'active' as DocumentLockStatus,
};

export type DocumentLock = {
  annotatedDocumentId: number | null;
  status: DocumentLockStatus;
  lockedUntil: number | null;
  error: string | null;
};

export const DocumentLock = {
  defaultNoLock: (): DocumentLock => ({
    annotatedDocumentId: null,
    status: DOCUMENT_LOCK_STATUS.NONE,
    lockedUntil: null,
    error: null,
  }),

  mapFromResource: function mapFromResource(docLock: DocumentLockResource) {
    return {
      annotatedDocumentId: docLock.annotatedDocumentId,
      status: docLock.success
        ? DOCUMENT_LOCK_STATUS.ACTIVE
        : DOCUMENT_LOCK_STATUS.NONE,
      lockedUntil: docLock.lockedUntil,
      error: docLock.error || null,
    };
  },
};
