import Pagination from 'antd/lib/pagination/Pagination';
import React from 'react';
import styled from 'styled-components';
import { Gray1 } from '../../common/colors';
import { IconButton } from '../../common/components/icon-button.component';
import { LargeCenteredLoader } from '../../common/components/large-centered-loader.component';
import { AnnotatedDocument } from '../models/annotated-document.model';
import { DocumentListFilter } from '../models/document-list-filter.model';
import { DocumentSelectionForm } from './document-selection-form.component';
import { DocumentSelectionTable } from './document-selection-table.component';
import { QuerySort } from '../../common/types/QuerySort';

const PopoverHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${Gray1};

  h2 {
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 0.2em;
  }

  .ant-btn {
    font-size: 22px;
    color: ${Gray1};
  }
`;

const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
`;

type BasicPopoverProps = {
  title: string;
  onClose: (e: React.FormEvent<any>) => void;
  children: React.ReactNode | React.ReactNode[];
};

const BasicPopover = (props: BasicPopoverProps) => (
  <div>
    <PopoverHeader>
      <h2>Search Documents</h2>
      <IconButton onClick={props.onClose} iconType="close" />
    </PopoverHeader>

    <PopoverContent>{props.children}</PopoverContent>
  </div>
);

const PaginationWrap = styled.div`
  text-align: center;
  padding: 20px;
`;

const StyledDocumentSelectionTable = styled(DocumentSelectionTable)`
  min-height: 418px;
`;

interface DocumentSelectorProps {
  documents: AnnotatedDocument[];
  loading: boolean;
  filter: DocumentListFilter;
  currentPage: number;
  currentUserId: number | null;
  totalPages: number;
  totalItems: number;
  availableStatuses: string[];
  onSearch: (filter: DocumentListFilter) => any;
  onDocumentSelected: () => void;
  onClose: (e: React.FormEvent<any>) => any;
  onSelectPage: (page: number) => any;
  onSort: (val: string, order: string) => void;
  sort: QuerySort | null;
}
export class DocumentSelector extends React.Component<DocumentSelectorProps> {
  render() {
    const { loading, currentUserId } = this.props;

    return (
      <BasicPopover
        title="Select Document"
        onClose={e => this.props.onClose(e)}
      >
        <DocumentSelectionForm
          availableStatuses={this.props.availableStatuses}
          onSubmit={filter => this.props.onSearch(filter)}
          filter={this.props.filter}
        />
        {loading && <LargeCenteredLoader />}
        {!loading && (
          <div>
            <StyledDocumentSelectionTable
              documents={this.props.documents}
              onDocumentSelected={this.props.onDocumentSelected}
              currentUserId={currentUserId}
              onSort={(field, value) => this.props.onSort(field, value)}
              sort={this.props.sort}
            />
            <PaginationWrap>
              <Pagination
                showQuickJumper={true}
                defaultCurrent={this.props.currentPage}
                defaultPageSize={1}
                total={this.props.totalPages}
                onChange={page => this.props.onSelectPage(page)}
                showTotal={_ => `Total ${this.props.totalItems} Documents`}
              />
            </PaginationWrap>
          </div>
        )}
      </BasicPopover>
    );
  }
}
