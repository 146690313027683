/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styled from 'styled-components';
import { Group } from '../../accounts/models/group.model';
import { SimpleRole } from '../../accounts/models/simple-role.model';
import { UserAccount } from '../../accounts/models/user-account.model';
import PermissionTables from '../containers/permission-tables.container';
import { PermissionOverride } from './permission-tables.component';

const CardStyle = styled.div`
  text-align: left;
`;

const CardContent = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
`;

const Title = styled.h2`
  text-align: center;
  font-weight: bold;
`;

const UserDetails = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column;
  font-size: 18px;
`;

const Padded = styled.div`
  text-align: left;
  padding: 0 8px;
  > * {
    flex: 1 50%;
    padding: 14px;
  }
`;

const Row = styled(Padded)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }

  &:first-child {
    border-top: 1px solid #eee;
  }
`;

interface UserAccountCardProps {
  userAccount: UserAccount;
  onGroupClick: (group: Group) => any;
  onSaveSimpleRoles: (roles: SimpleRole[]) => any;
}

export class UserAccountCardComponent extends React.Component<
  UserAccountCardProps
> {
  render() {
    const { userAccount, onGroupClick } = this.props;
    const user = (userAccount && userAccount.user) || null;
    const groups = (userAccount && userAccount.groups) || null;
    const permissions = (userAccount && userAccount.permissions) || null;
    const userRoles = userAccount.userRoles;
    const groupRoleIds = userAccount.groupRoles.map(r => r.roleId);
    const permissionOverrides: PermissionOverride[] = permissions
      .map(p => {
        const complexUserRole = userRoles.find(
          r => r.roleId === p.roleId && !r.isSimpleRole,
        );
        const groupRoleId = groupRoleIds.find(i => i === p.roleId);
        const overriddenByGroup = groupRoleId
          ? 'Overridden by group permissions.'
          : undefined;
        const overriddenByRole = complexUserRole
          ? `Overridden by role: ${complexUserRole.roleName}`
          : undefined;
        const overriddenBy = overriddenByGroup || overriddenByRole;
        return { permission: p, overriddenBy };
      })
      .filter(o => !!o.overriddenBy)
      .map(o => o as PermissionOverride);

    return (
      <CardStyle>
        <Title>{user && user.subject}</Title>
        <CardContent>
          <UserDetails>
            <Row>
              <strong>Username</strong>
              <span>{user && user.username}</span>
            </Row>
            <Row>
              <strong>User ID</strong>
              <span>{user && user.userId}</span>
            </Row>
            <Row>
              <strong>Oseberg Username</strong>
              <span>{user && user.subject}</span>
            </Row>
            <Row>
              <strong>Created On</strong>
              <span>{user && user.createdAt}</span>
            </Row>

            <Row>
              <strong>Groups</strong>
              <ul>
                {groups &&
                  groups.map(grp => (
                    <li key={grp.groupId}>
                      <a onClick={() => onGroupClick(grp)}>{grp.groupName}</a>
                    </li>
                  ))}
              </ul>
            </Row>

            <Padded>
              <strong>Permissions</strong>
              <PermissionTables
                targetPermissions={permissions}
                targetPermissionOverrides={permissionOverrides}
                onSaveSimpleRoles={this.props.onSaveSimpleRoles}
              ></PermissionTables>
            </Padded>
          </UserDetails>
        </CardContent>
      </CardStyle>
    );
  }
}
