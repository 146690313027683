import { ColDef } from 'ag-grid-community';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { Dictionary } from 'lodash';
import { createSelector } from 'reselect';
import { AppState } from '../../app-state';
import {
  AnnotationSelection,
  AnnotationSelectionContext,
} from '../models/annotation-selection.model';

export const selectAnnotationSelection = (
  state: AppState,
): AnnotationSelection[] => state._UI_annotations.selection;

export const selectAnnotationSelectionContext = (
  state: AppState,
): AnnotationSelectionContext | null => state._UI_annotations.selectionContext;

export const selectAnnotationTableColumnSettings = (
  state: AppState,
): Dictionary<ColDef> => state._UI_settings.annotationTableSettings.columns;

export const selectAnnotationHighlightColor = (
  state: AppState,
): string | null => state._UI_annotations.highlightColor;

export const selectEmphasizeAnnotationId = (state: AppState): string | null =>
  state._UI_annotations.emphasizeAnnotationId;

export const selectAnnotationHighlights = (state: AppState) =>
  state._UI_annotations.highlights;

export const selectAnnotationSelectionExists = createSelector(
  [selectAnnotationSelection],
  selection => !!selection.length,
);

export const selectAnnotationTooltipPlacement = createSelector(
  [selectAnnotationSelectionContext],
  context =>
    (context === 'text-viewer'
      ? 'leftBottom'
      : 'rightBottom') as TooltipPlacement,
);
