import { createAction } from 'typesafe-actions';
enum Types {
  AUTHENTICATE_USER = 'AUTHENTICATE_USER',
  AUTHENTICATE_SUCCESS = 'AUTHENTICATE_SUCCESS',
  AUTHENTICATE_FAILURE = 'AUTHENTICATE_FAILURE',
  SHOW_LOGIN_MODAL = 'SHOW_LOGIN_MODAL',
  SIGN_OUT = 'SIGN_OUT: [CAUTION]: WILL RESET USERS STATE TO DEFAULTS! (check root reducer for details)',
}

export const authenticateUser = createAction(
  Types.AUTHENTICATE_USER,
  resolvePayload => (username: string, password: string) =>
    resolvePayload({ username, password }),
);

export const authenticateSuccess = createAction(
  Types.AUTHENTICATE_SUCCESS,
  resolvePayload => (jwt: string) => resolvePayload({ jwt }),
);

export const authenticateFailure = createAction(
  Types.AUTHENTICATE_FAILURE,
  resolvePayload => (error: string) => resolvePayload({ error }),
);

export const showLoginModal = createAction(Types.SHOW_LOGIN_MODAL);

export const signOut = createAction(Types.SIGN_OUT);
