import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import { Restricted } from '../../accounts/containers/restricted.container';
import {
  PermissionActions,
  PermissionResources,
} from '../../accounts/models/permission.model';
import { AppState } from '../../app-state';
import { DOCUMENT_LOCK_STATUS } from '../../documents/models/document-lock-status.model';
import { selectSourceDocumentLockStatus } from '../../documents/selectors/document-lock.selectors';
import {
  selectActiveSourceDocument,
  selectSourceDocumentIsEditable,
} from '../../documents/selectors/source-document.selectors';
import { EditDocumentButton } from '../../layout/containers/edit-document-button.container';
import { SaveAnnotationsButton } from './save-annotations-button.container';

const InfoTextWrap = styled.span`
  display: flex;
  align-self: center;
  margin-right: 5px;
`;

const EditButtonWrap = styled.span`
  display: flex;
  align-self: center;
`;

const annotatePermissions = {
  action: PermissionActions.Annotate,
  resource: PermissionResources.DocumentSet,
};
type SaveOrEditButtonProps = {
  showSaveButton: boolean;
  showEditButton: boolean;
};
const SaveOrEditButtonComponent = (props: SaveOrEditButtonProps) => (
  <Restricted required={annotatePermissions}>
    {props.showSaveButton && <SaveAnnotationsButton />}
    {props.showEditButton && (
      <>
        <InfoTextWrap>You are in read-only mode.</InfoTextWrap>
        <EditButtonWrap>
          <EditDocumentButton />
        </EditButtonWrap>
      </>
    )}
  </Restricted>
);

const selectShouldShowEditButton = createSelector(
  [
    selectActiveSourceDocument,
    selectSourceDocumentIsEditable,
    selectSourceDocumentLockStatus,
  ],
  (doc, editable, lockStatus) =>
    !!doc && !editable && lockStatus === DOCUMENT_LOCK_STATUS.NONE,
);

export const SaveOrEditButton = connect((state: AppState) => ({
  showSaveButton: selectSourceDocumentIsEditable(state),
  showEditButton: selectShouldShowEditButton(state),
}))(SaveOrEditButtonComponent);
