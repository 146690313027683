import '@fortawesome/fontawesome-free/css/all.css';
import {
  CellClassParams,
  ColDef,
  ColumnApi,
  GridApi,
  GridReadyEvent,
  ICellEditorParams,
  ProcessCellForExportParams,
  RowDragEvent,
  RowNode,
  SelectionChangedEvent,
  SuppressKeyboardEventParams,
  ValueSetterParams,
} from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { Dictionary, forEach, range } from 'lodash';
import { map } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import styled from 'styled-components';
import { PayloadAction } from 'typesafe-actions';
import * as Colors from '../../common/colors';
import { DateComponent } from '../../common/components/date.component';
import { RELATION_TYPES } from '../../lib/relations/relation-types';
import AnnotationTypeEditor from '../containers/annotation-type-editor.container';
import AnnotationValueEditor from '../containers/annotation-value-editor.container';
import { AnnotationTableRow } from '../models/annotation-table-row.model';
import { AnnotationTableSettings } from '../models/annotation-table-settings.model';
import {
  AnnotationMetadata,
  AnnotationSegment,
} from '../models/annotation.model';
import { ActionCell } from './action-cell.component';
import InnerAnnotationTypeCell from './type-cell.component';
import InnerAnnotationValueCell from './value-cell.component';
import PlainCell from './plain-cell.component';
import { AnnotationSchema } from '../models/annotation-schema.model';
import { RelationType } from '../../lib/relations/relation-types';
import { DragTargetState } from '../models/drag-target-state.model';
import { Map } from 'immutable';
import { useStatusFlag } from '../../common/utils/utils';

const GridWrapper = styled.div<{ waiting: boolean }>`
  height: 100%;
  width: 100%;
  ${p => (p.waiting ? '* { cursor: wait !important };' : '')}

  &.ag-theme-balham {
    .ag-header-cell:first-child {
      .ag-header-icon.ag-header-cell-menu-button {
        /* Importants needed to overwrite grid element styles. */
        opacity: 0.6 !important;

        &:hover {
          opacity: 1 !important;
        }
      }
    }

    .ag-icon-menu:before {
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      content: '\\F013';
    }

    .ag-icon-columns:before {
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      content: '\\F0B0';
    }
  }

  .ag-group-checkbox.ag-invisible {
    display: none !important;
  }

  .ag-cell .ag-cell-wrapper.ag-row-group-leaf-indent {
    margin-left: 16px;
  }

  .ag-cell-wrapper .ag-group-value {
    margin-left: 4px;
  }

  .ag-row-drag {
    min-width: 22px;
  }

  .annotation-type-cell {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .inner-annotation-type-cell {
    color: #3b3b3b;
    display: flex;
    align-items: center;

    &:hover {
      color: #41a9ff;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .valid-relation-target {
    background-color: red;
  }

  .invalid-relation-target {
    background-color: blue;
  }
`;

export type AnnotationTableParams = {
  rows: AnnotationTableRow[];
  columns: Dictionary<ColDef>;
  loading: boolean;
  editable: boolean;
  size?: {
    height: number;
    width: number;
  };
  lastManualAnnotationId: string | null;
  activeUser: string | null;
  activeSchema: AnnotationSchema;
  dragTargetState: DragTargetState;
  focusedAnnotationId: string | null;
};

export type AnnotationTableFunctions = {
  onRemoveAnnotation: (annotationId: string) => void;
  onHideColumn: (colId: string, hide: boolean) => void;
  onCreateRelation: (
    leftId: string,
    rightId: string,
    relationType: RelationType,
  ) => void;
  onDeleteRelation: (annotationId: string) => void;
  setSelectedAnnotations: (ids: string[]) => void;
  setDragTargetId: (id: string) => void;
  updateAnnotationValues: (m: Map<string, string>) => void;
  updateAnnotationTypes: (m: Map<string, string>) => void;
  clearFocusedAnnotationId: () => void;
};

export type AnnotationTableProps = AnnotationTableParams &
  AnnotationTableFunctions;

/// if there is no offset, this function will look into the node for discontinuous segments, and look at those offsets instead.
const offsetComparator = (position: 'start' | 'end') => (
  a: number,
  b: number,
  aNode: RowNode,
  bNode: RowNode,
) => {
  const segmentA: AnnotationSegment[] =
    aNode.data.annotation.discontinuousSegments;
  const segmentB: AnnotationSegment[] =
    bNode.data.annotation.discontinuousSegments;
  if (b === null && bNode.data.annotation.discontinuous) {
    const segmentBSortPosition: number =
      position === 'start' ? 0 : segmentB.length - 1;
    if (a === null && aNode.data.annotation.discontinuous) {
      const segmentASortPosition: number =
        position === 'start' ? 0 : segmentA.length - 1;
      return (
        (segmentA[segmentASortPosition][position] || 0) -
        (segmentB[segmentBSortPosition][position] || 0)
      );
    }
    return a - (segmentB[segmentBSortPosition][position] || 0);
  }
  if (a === null && aNode.data.annotation.discontinuous) {
    const segmentASortPosition: number =
      position === 'start' ? 0 : segmentA.length - 1;
    return (segmentA[segmentASortPosition][position] || 0) - b;
  }

  return a - b;
};

const offsetParams = (position: 'start' | 'end') => (
  annotation: AnnotationMetadata,
) => {
  if (annotation[position]) {
    return annotation[position];
  } else if (annotation && annotation.discontinuous) {
    return 'multiple';
  } else return 'no offset';
};

export const AnnotationTableComponent = React.memo(
  (props: AnnotationTableProps) => {
    const {
      rows,
      columns,
      editable,
      size,
      loading,
      lastManualAnnotationId,
      activeSchema,
    } = props;
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    const [colApi, setColApi] = useState<ColumnApi | null>(null);
    const [dragSource, setDragSource] = useState<AnnotationTableRow | null>(
      null,
    );
    const [dragTarget, setDragTarget] = useState<AnnotationTableRow | null>(
      null,
    );
    const [initialYLocation, setInitialYLocation] = useState<number>(0);
    const [expandedRows, dispatchExpandRow] = useReducer(expandRowReducer, {});
    const [isFirstRender, setIfFirstRender] = useState(true);

    const annotationTypes = activeSchema.annotationTypes;
    const getTypeByKey = (key: string) =>
      annotationTypes.find(t => t.key === key);

    const keyToTitle = (key: string) => getTypeByKey(key)?.title || '';

    function resizeExpandedRows() {
      if (gridApi && colApi) {
        const col = colApi.getColumn('value');
        const colPadding = 24;
        const colWidth = col.getActualWidth() - colPadding;

        forEach(expandedRows, (isExpanded: boolean, id: string) => {
          const rowNode = gridApi.getRowNode(id);

          if (rowNode) {
            const rowData = rowNode.data;
            if (isExpanded) {
              rowNode.setRowHeight(
                AnnotationTableRow.valueCellHeight(rowData, colWidth),
              );
            } else {
              rowNode.setRowHeight(25);
            }
          }
        });

        gridApi.onRowHeightChanged();
      }
    }

    const gridSelectedRows = () => gridApi?.getSelectedRows() || [];
    const gridSelectedAnnotations = () =>
      gridSelectedRows().map(r => r.annotationId);

    useEffect(resizeExpandedRows, [expandedRows, rows]);

    const expandCell = (id: string) =>
      dispatchExpandRow({ type: 'expandRow', payload: { id } });
    const unexpandCell = (id: string) =>
      dispatchExpandRow({ type: 'unexpandRow', payload: { id } });

    const ValueCell = valueProps => (
      <InnerAnnotationValueCell
        expandCell={expandCell}
        unexpandCell={unexpandCell}
        {...valueProps}
      />
    );

    const updateAnnotationValue = (id: string, value: string) =>
      props.updateAnnotationValues(Map([[id, value]]));
    const updateAnnotationType = (id: string, type: string) =>
      props.updateAnnotationTypes(Map([[id, type]]));

    const [updateInProgress, withUpdateFlag] = useStatusFlag();

    const valueSetter = (update: (id: string, value: string) => void) => (
      params: ValueSetterParams,
    ) => {
      const isChanged = params.oldValue !== params.newValue;
      if (isChanged) {
        const id = params.data.annotationId;
        const newVal = params.newValue;
        const valUpdate = () => {
          update(id, newVal);
        };
        withUpdateFlag(valUpdate);
      }
      return isChanged;
    };

    const defaultColumnDefs: ColDef[] = [
      {
        colId: 'value',
        cellClass: 'value-cell',
        headerName: 'Value',
        filter: 'agTextColumnFilter',
        editable,
        cellEditorFramework: AnnotationValueEditor,
        maxWidth: 600,
        cellRendererFramework: ValueCell,
        cellEditorParams: (cellParams: ICellEditorParams) => {
          const maxLength = { maxLength: 99999 };
          if (!cellParams.value) {
            return { rows: 1, ...maxLength };
          }

          const maxRows = 14;
          const charWidthEstimate = 8;
          const cellWidth = cellParams.eGridCell.offsetWidth;
          const cellRows = Math.ceil(
            cellParams.value.length / (cellWidth / charWidthEstimate),
          );

          return {
            rows: Math.max(Math.min(cellRows, maxRows), 1),
            ...maxLength,
          };
        },
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        valueSetter: valueSetter(updateAnnotationValue),
        ...columns.value,
      },
      {
        colId: 'annotatorName',
        headerName: 'Annotator',
        filter: 'agTextColumnFilter',
        width: 60,
        cellRendererFramework: params => (
          <PlainCell getData={a => a?.annotatedBy} {...params} />
        ),
        ...columns.annotatorName,
      },
      {
        colId: 'annotationDate',
        headerName: 'Time',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: (filterDate, cellValue) => {
            if (cellValue == null) {
              return 0;
            }
            const cellDate = new Date(cellValue);

            if (cellDate < filterDate) {
              return -1;
            } else if (cellDate > filterDate) {
              return 1;
            }
            return 0;
          },
        },
        width: 80,
        cellRendererFramework: params => (
          <PlainCell
            getData={a => a?.annotatedDate}
            renderData={d => (
              <DateComponent
                serializedDate={d || ''}
                format={'yyyy-mm-dd, h:MM TT'}
              />
            )}
            {...params}
          />
        ),
        ...columns.annotationDate,
      },
      {
        colId: 'startOffset',
        headerName: 'Start Offset',
        filter: 'agNumberColumnFilter',
        width: 60,
        comparator: offsetComparator('start'),
        cellRendererFramework: params => (
          <PlainCell getData={offsetParams('start')} {...params} />
        ),
        ...columns.startOffset,
      },
      {
        colId: 'endOffset',
        headerName: 'End Offset',
        filter: 'agNumberColumnFilter',
        width: 60,
        comparator: offsetComparator('end'),
        cellRendererFramework: params => (
          <PlainCell getData={offsetParams('end')} {...params} />
        ),
        ...columns.endOffset,
      },
      {
        colId: 'actions',
        headerName: '',
        suppressAutoSize: true,
        filter: false,
        width: 24,
        cellRendererFramework: ActionCell({
          onRemoveClicked: props.onRemoveAnnotation,
        }),
        ...columns.actions,
      },
    ];

    const [columnDefs, setColDefs] = useState(
      AnnotationTableSettings.filterColumns(editable, defaultColumnDefs),
    );

    const width = size ? size.width : null;
    const focusedAnnotationId = props.focusedAnnotationId;
    const getParents = (n: RowNode) => {
      if (!n.parent?.data) return [];
      else return [...getParents(n.parent), n.parent];
    };
    const findAndExpand = (n: RowNode) => {
      if (n.rowIndex && gridApi) gridApi.ensureIndexVisible(n.rowIndex);
      n.setExpanded(true);
    };

    useEffect(() => {
      if (focusedAnnotationId && gridApi) {
        const node = gridApi.getRowNode(focusedAnnotationId);
        const parents = getParents(node);
        parents.forEach(findAndExpand);
        gridApi.ensureIndexVisible(node.rowIndex);
        gridApi.setFocusedCell(node.rowIndex, 'value');
        gridApi.deselectAll();
        node.setSelected(true);
        props.clearFocusedAnnotationId();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focusedAnnotationId]);

    useEffect(() => {
      if (gridApi) {
        gridApi.sizeColumnsToFit();
      }
    }, [gridApi, width]);

    useEffect(() => {
      setColDefs(
        AnnotationTableSettings.filterColumns(editable, defaultColumnDefs),
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editable]);

    function addGridSelection(id: string) {
      if (!gridApi) return;
      const node = gridApi.getRowNode(id);
      node.setSelected(true);
    }

    function setSelectedInGrid(ids: string[]) {
      if (!gridApi) return;
      gridApi.deselectAll();
      ids.forEach(addGridSelection);
    }

    const onGridReady = (event: GridReadyEvent) => {
      setGridApi(event.api);
      setColApi(event.columnApi);

      event.api.sizeColumnsToFit();
    };

    function moveRow(source: AnnotationTableRow, target: AnnotationTableRow) {
      if (source.annotationId === target.annotationId) {
        props.onDeleteRelation(source.annotationId);
        return;
      }

      props.onCreateRelation(
        source.annotationId,
        target.annotationId,
        RELATION_TYPES.CHILD_OF,
      );
    }

    function onDragEnd(ev: RowDragEvent) {
      setDragSource(null);
      setDragTarget(null);
      const selected = gridSelectedRows();
      const numSelected = selected?.length ?? 0;
      const didMove = Math.abs(initialYLocation - ev.y) > 5;
      if (!ev.overNode || !didMove) {
        return;
      }
      const target: AnnotationTableRow = ev.overNode.data;
      const moveRows = () => {
        if (selected && numSelected > 1) {
          const sources: AnnotationTableRow[] = selected;
          const isValid = props.dragTargetState === DragTargetState.Valid;
          if (isValid) {
            for (const source of sources) {
              moveRow(source, target);
            }
          }
        } else moveRow(ev.node.data, ev.overNode.data);
      };
      withUpdateFlag(moveRows);
    }

    function cancelDrag() {
      setDragSource(null);
      setDragTarget(null);
    }

    function setDragState(ev: RowDragEvent) {
      const selected = gridSelectedRows();
      const numSelected = selected?.length ?? 0;
      if (!ev.overNode) {
        return;
      }
      props.setDragTargetId(ev.overNode.data.annotationId);
      if (selected && numSelected > 1) {
        setDragSource(ev.node.data);
        setDragTarget(ev.overNode.data);
      } else {
        const source: AnnotationTableRow = ev.node.data;
        const target: AnnotationTableRow = ev.overNode.data;
        setDragSource(source);
        setDragTarget(target);
      }
    }

    function onDragEnter(row: RowDragEvent) {
      const dragAnnotationId = row.node.data.annotationId;

      const selAnnotations = gridSelectedAnnotations();
      const alreadySelected = selAnnotations.indexOf(dragAnnotationId) !== -1;
      const newSelected = alreadySelected ? selAnnotations : [dragAnnotationId];
      setSelectedInGrid(newSelected);
      setInitialYLocation(row.y);
      return setDragState;
    }

    function getRowClass({ node }: { node: RowNode }) {
      return node.id;
    }

    function dragTargetBackgroundColor() {
      switch (props.dragTargetState) {
        case DragTargetState.Invalid:
          return Colors.TableRowInvalid;
        case DragTargetState.Valid:
          return Colors.TableRowValid;
        case DragTargetState.Self:
          return 'inherit';
        default:
          return '#fff';
      }
    }

    function suppressKeyboardEvent(
      params: SuppressKeyboardEventParams,
    ): boolean {
      const event = params.event;
      const key = event.which;
      const deleteKey = 46;
      const alphabeticKeys = range(97, 123);
      const upperAlphabeticKeys = range(65, 91);
      const numericKeys = range(48, 58);
      const enterKey = 13;
      const alphaNumericKeys = alphabeticKeys
        .concat(upperAlphabeticKeys)
        .concat(numericKeys)
        .concat([deleteKey])
        .concat([enterKey]);
      const keysToSuppress =
        event.ctrlKey || event.metaKey ? [] : alphaNumericKeys;
      const suppress = keysToSuppress.indexOf(key) >= 0;
      return suppress;
    }

    function onSelectionChanged(event: SelectionChangedEvent): void {
      if (gridApi) {
        // remove focus from table header to support hotkeys after select all event
        const focusedCell = gridApi.getFocusedCell();
        const focusRowIndex = focusedCell?.rowIndex || 0;
        const focusColumn = focusedCell?.column || columns.value.colId;
        gridApi.setFocusedCell(focusRowIndex, focusColumn);
        if (!focusedCell) gridApi.clearFocusedCell();

        const annotations = gridSelectedAnnotations();
        props.setSelectedAnnotations(annotations);
      }
    }

    const dragTargetId = dragTarget
      ? dragTarget.annotationId
      : 'disabled-no-id';
    const dragSourceId = dragSource
      ? dragSource.annotationId
      : 'disabled-no-id';
    const dragTargetBg = dragTargetBackgroundColor();
    const dragTargetPaddingStyle =
      props.dragTargetState === DragTargetState.Self ? 'padding-left: 0px' : '';

    useEffect(() => {
      if (gridApi) {
        if (loading) {
          gridApi.showLoadingOverlay();
        } else if (rows.length === 0) {
          gridApi.showNoRowsOverlay();
        } else {
          gridApi.hideOverlay();
        }
      }
    }, [gridApi, loading, rows.length]);

    // if a new manual annotation is added, start editing the 'value' field
    useEffect(() => {
      if (gridApi && lastManualAnnotationId) {
        const displayedIndex = getDisplayedIndex(
          gridApi,
          lastManualAnnotationId,
        );
        gridApi.startEditingCell({ rowIndex: displayedIndex, colKey: 'value' });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastManualAnnotationId]);

    return (
      <GridWrapper waiting={updateInProgress} className="ag-theme-balham">
        <style
          dangerouslySetInnerHTML={{
            __html: `
          .ag-row[row-id='${dragTargetId}'] {
            background-color: ${dragTargetBg};
          }
          .ag-row[row-id='${dragSourceId}'] .ag-cell-wrapper {
            ${dragTargetPaddingStyle};
          }
        `,
          }}
        />
        <AgGridReact
          key={`annotation-table-${props.editable}`}
          columnDefs={columnDefs}
          onFirstDataRendered={_ => setIfFirstRender(false)}
          treeData={true}
          suppressRowDrag={!props.editable}
          suppressScrollOnNewData={true}
          rememberGroupStateWhenNewData={true}
          groupDefaultExpanded={isFirstRender ? 0 : -1}
          loadingCellRenderer={'Loading...'}
          defaultColDef={{
            filterParams: { buttons: ['apply', 'reset'] },
            filter: true,
            resizable: true,
            sortable: true,
          }}
          rowData={rows}
          onGridReady={onGridReady}
          getDataPath={(row: AnnotationTableRow) => row.treePath}
          onRowDragEnd={onDragEnd}
          onRowDragMove={setDragState}
          onRowDragEnter={onDragEnter}
          onRowDragLeave={cancelDrag}
          getRowNodeId={(atn: AnnotationTableRow) => atn.annotationId}
          getRowClass={getRowClass}
          onColumnVisible={ev => {
            if (ev.column) {
              props.onHideColumn(ev.column.getColId(), !ev.visible);
            }

            if (gridApi) {
              gridApi.sizeColumnsToFit();
            }
          }}
          autoGroupColumnDef={{
            rowDrag: true,
            colId: 'type',
            headerName: 'Type',
            editable,
            keyCreator: (params: CellClassParams) => keyToTitle(params.value),
            cellEditorFramework: AnnotationTypeEditor,
            filter: 'agSetColumnFilter',
            filterParams: {
              applyMiniFilterWhileTyping: true,
              defaultToNothingSelected: true,
            },
            cellClass: 'annotation-type-cell',
            width: 140,
            cellRendererParams: {
              innerRendererFramework: InnerAnnotationTypeCell,
            },
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            valueSetter: valueSetter(updateAnnotationType),
            ...columns.type,
          }}
          rowSelection="multiple"
          rowDeselection={true}
          suppressKeyboardEvent={suppressKeyboardEvent}
          onSelectionChanged={onSelectionChanged}
          defaultExportParams={{ processCellCallback: exporterFieldCallback }}
          immutableData={true}
          rowBuffer={30}
        ></AgGridReact>
      </GridWrapper>
    );
  },
);

/**
 * Expand row actions and reducer.
 * A reducer is used because the dictionary of expanded rows depends on the previous state.
 * Specifically, the need to maintiain the rest of the dictionary when keys are added/removed.
 */
type ExpandRowAction = PayloadAction<
  'expandRow' | 'unexpandRow',
  { id: string }
>;
function expandRowReducer(state: Dictionary<boolean>, action: ExpandRowAction) {
  switch (action.type) {
    case 'expandRow':
      return { ...state, [action.payload.id]: true };

    case 'unexpandRow':
    default:
      return { ...state, [action.payload.id]: false };
  }
}

function getDisplayedIndex(api: GridApi, id: string) {
  const rowCount = api.getDisplayedRowCount();
  const rowIndices = Array.from(Array(rowCount).keys());
  const rowIds = map(rowIndices, i => api.getDisplayedRowAtIndex(i).id);
  const displayedIndex = rowIds.findIndex(i => i === id);
  return displayedIndex;
}

function exporterFieldCallback(params: ProcessCellForExportParams) {
  const value = params.value;
  const title = value ? value.title : null;
  return title ? title : value;
}
