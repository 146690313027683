import { ActionsObservable, StateObservable } from 'redux-observable';
import { filter, map } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AppState } from '../../app-state';
import { AppActions } from '../../root.actions';

import {
  fetchDocuments,
  fetchDocumentsError,
  updateDocuments,
} from '../../documents/actions/document-list.actions';
import {
  loadDocumentSummaryEnd,
  loadDocumentSummaryStart,
} from '../actions/document-summary-load-usage.actions';

export function loadDocumentSummaryStartEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(fetchDocuments)),
    map(action => loadDocumentSummaryStart()),
  );
}

export function loadDocumentSummaryEndEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf([updateDocuments, fetchDocumentsError])),
    map(action => loadDocumentSummaryEnd()),
  );
}
