import { createAction, createAsyncAction } from 'typesafe-actions';
import { Role } from '../models/role.model';
import { MemberType } from '../resources/roles.resource';

export const fetchRoles = createAction('FETCH_ROLES');

export const populateRoles = createAction(
  'POPULATE_ROLES',
  resolvePayload => (roles: Role[]) => resolvePayload(roles),
);

export const addMemberToRole = createAsyncAction(
  'ADD_MEMBER_TO_ROLE_REQUEST',
  'ADD_MEMBER_TO_ROLE_SUCCESS',
  'ADD_MEMBER_TO_ROLE_FAILURE',
)<
  { roleId: number; memberId: number; memberType: MemberType },
  undefined,
  string
>();

export const deleteMemberFromRole = createAsyncAction(
  'DELETE_MEMBER_FROM_ROW_REQUEST',
  'DELETE_MEMBER_FROM_ROW_SUCCESS',
  'DELETE_MEMBER_FROM_ROW_FAILURE',
)<
  { roleId: number; memberId: number; memberType: MemberType },
  undefined,
  string
>();
