import { flatMap, sum } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { Gray1 } from '../../common/colors';
import { TextHighlighter } from '../../text-highlighter/components/text-highlighter.component';
import { HighlightCollection } from '../../text-highlighter/models/highlight-collection.model';
import { TextSelection } from '../../text-highlighter/models/text-selection';
import {
  UserHighlight,
  UserHighlightProp,
} from '../../text-highlighter/models/user-highlight.model';
import { AnnotationPage } from '../models/annotation-page.model';
import {
  Annotation,
  AnnotationMetadataWithType,
} from '../models/annotation.model';
import { Map } from 'immutable';
import { AnnotationSchema } from '../models/annotation-schema.model';
import AnnotationPopover from '../components/annotation-popover.component';

const NoText = styled.div`
  text-align: center;
  font-size: 18px;
  padding: 12px;
`;

const pagePadding = 6;
const TextArea = styled.div`
  white-space: pre;
  font-size: 14px;
  text-align: left;
  position: relative;
  top: -8px;
  padding: ${pagePadding}px;
  width: 100%;
  background-color: white;
  display: inline-block;

  .text-highlights {
    display: inline-block;
  }
`;
export interface AnnotationViewerProps {
  activeSchema: AnnotationSchema;
  page: number;
  pages: AnnotationPage[];
  text: string;
  annotations: Map<string, AnnotationMetadataWithType>;
  emphasizeAnnotationId: string | null;
  selectionColor: string | null;
  onSelectionChange: (e: TextSelection) => any;
  onSelectionCleared: () => any;
  updatePageOffsets: (offsets: number[]) => any;
  onHighlightsUpdated: (highlights: HighlightCollection) => any;
}
interface AnnotationViewerState {
  annotations: AnnotationMetadataWithType[];
  highlights: UserHighlightProp[];
}
export class AnnotationViewer extends React.PureComponent<
  AnnotationViewerProps,
  AnnotationViewerState
> {
  selectionColor: string | null;
  pageBreaks: number[] = [];
  private className = 'ose-annotation-viewer';
  private _updateOffsets = false;
  private _lastSelection: TextSelection | null;

  constructor(props: AnnotationViewerProps) {
    super(props);

    const annotations = Array.from(props.annotations.values());
    this.selectionColor = null;
    this._lastSelection = null;
    this.state = {
      annotations,
      highlights: this.createHighlights(annotations),
    };
  }

  /**
   * Creates an inline style for the emphasized annotation.
   * This creates an inline <style>, which is usually not the ideal
   * method of styling components. In this case it allows us to target
   * the emphasized annotation by classname dynamically without causing
   * component re-renders, which is what occurs if the classname is targeted
   * by through a StyledComponent.
   *
   * This is a highly efficient method of targeting the annotation to emphasize.
   */
  get conditionalStyle() {
    const { emphasizeAnnotationId } = this.props;
    if (!emphasizeAnnotationId) {
      return null;
    }

    return (
      <style>{`
        .${this.className} .${Annotation.htmlClass(emphasizeAnnotationId)} {
          text-decoration: underline;
          border: 1px solid #2B6FD2;
          border-radius: 4px;
        }
      `}</style>
    );
  }

  onSelectionChange(textSelection: TextSelection) {
    if (textSelection.isSelected) {
      this._lastSelection = textSelection;
      this.props.onSelectionChange(textSelection);
    } else {
      if (this._lastSelection) {
        this.props.onSelectionCleared();
      }

      this._lastSelection = null;
    }
  }

  componentWillReceiveProps(newProps: AnnotationViewerProps) {
    if (newProps.pages !== this.props.pages) {
      this._updateOffsets = true;
    }

    if (newProps.annotations !== this.props.annotations) {
      const annotations = Array.from(newProps.annotations.values());
      this.setState({
        annotations,
        highlights: this.createHighlights(annotations),
      });
    }
  }

  componentDidMount() {
    this._updateOffsets = true;
  }

  createHighlights(
    annotations: AnnotationMetadataWithType[],
  ): UserHighlightProp[] {
    const schema = this.props.activeSchema;
    const toHighlights = (a: AnnotationMetadataWithType) =>
      Annotation.mapToUserHighlightProp(a, schema);
    return flatMap(annotations, toHighlights);
  }

  renderHighlight = (
    RenderElement: JSX.Element,
    highlights: UserHighlight[],
  ) => {
    const annotationIds = highlights.map(h => h.data.id);
    return (
      <AnnotationPopover annotationIds={annotationIds}>
        {RenderElement}
      </AnnotationPopover>
    );
  };

  updateOffsets = (elm: TextHighlighter) => {
    if (!this._updateOffsets || !elm || !elm.textNode) {
      return;
    }

    const height = elm.textNode.offsetHeight;
    const pageLengths = Array.from(this.props.pages.values()).map(
      p => (p.text.match(/\n/g) || []).length,
    );

    const totalLength = sum(pageLengths);
    const lineLength = height / totalLength;

    let total = 0;
    const pageOffsets: number[] = [];
    for (const pageLength of pageLengths) {
      pageOffsets.push(total);
      const pageHeight = Math.ceil(pageLength * lineLength);
      total += pageHeight;
    }

    this._updateOffsets = false;
    if (this.props.updatePageOffsets) {
      this.pageBreaks = pageOffsets;
      this.props.updatePageOffsets(pageOffsets);
    }
  };

  createStyledOffsetElement(offset: number) {
    return styled.div`
      position: sticky;
      top: ${offset};
      height: 1px;
      width: 100%;
      border: 1px dashed ${Gray1};
    `;
  }

  render() {
    const offsetPageBreaks = 6;

    return (
      <div className={`${this.className} not-draggable`}>
        {this.conditionalStyle}
        {this.props.text && (
          <TextArea>
            <TextHighlighter
              text={this.props.text}
              className="text-highlights"
              highlights={this.state.highlights}
              onUpdateSelection={this.props.onSelectionChange}
              render={this.renderHighlight}
              onHighlightsUpdated={this.props.onHighlightsUpdated}
              ref={this.updateOffsets}
            />
            {this.pageBreaks.map((offset, idx) => {
              return (
                <div
                  key={`${idx}_${offset}`}
                  style={{
                    position: 'absolute',
                    top: `${offset + offsetPageBreaks}px`,
                    height: '1px',
                    width: '100%',
                    borderBottom: `1px dashed ${Gray1}`,
                    paddingRight: '60px',
                    textAlign: 'right',
                  }}
                >
                  <strong
                    style={{
                      backgroundColor: 'rgba(255, 255, 255, 0.6)',
                    }}
                  >
                    Page {idx + 1}
                  </strong>
                </div>
              );
            })}
          </TextArea>
        )}
        {!this.props.text && <NoText>Please Open a Document</NoText>}
      </div>
    );
  }
}
