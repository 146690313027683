import { createAction } from 'typesafe-actions';
import { UndoRedoStep } from '../models/undo-redo-step.model';

enum UndoRedoActionTypes {
  UNDO_ACTION = 'UNDO_ACTION',
  REDO_ACTION = 'REDO_ACTION',
  PUSH_TO_UNDO_REDO_HISTORY = 'PUSH_TO_UNDO_REDO_HISTORY',
}

export const undoAction = createAction(UndoRedoActionTypes.UNDO_ACTION);

export const redoAction = createAction(UndoRedoActionTypes.REDO_ACTION);

export const pushToUndoRedoHistory = createAction(
  UndoRedoActionTypes.PUSH_TO_UNDO_REDO_HISTORY,
  resolvePayload => (step: UndoRedoStep) => resolvePayload({ step }),
);
