import { Action } from 'redux';
import { getType } from 'typesafe-actions';
import { AppState } from '../app-state';
import { fastShallowCloneMap as fastShallowClone } from '../undo-redo/utils/fast-shallow-clone';
import {
  appendAnnotationRelations,
  createAnnotationRelation,
  deleteAnnotationChildRelations,
  deleteAnnotationRelation,
  replaceAnnotationRelations,
} from './actions/annotation-relations.actions';
import {
  appendAnnotations,
  createAnnotation,
  deleteAnnotation,
  deleteAnnotations,
  replaceAnnotations,
  updateAnnotation,
} from './actions/annotations.actions';
import { selectAnnotations } from './selectors/annotation.selectors';

/**
 * Create an "undo" action for a particular store action.
 * @param action The action being fired.
 * @param previousState The state before reducers have run the action.
 * @param postState The state after reducers have run the action.
 */
export function createUndoAnnotationActions(
  action: Action & any,
  previousState: AppState,
  postState: AppState,
): (Action & any)[] {
  switch (action.type) {
    case getType(appendAnnotations):
    case getType(appendAnnotationRelations):
    case getType(deleteAnnotation):
    case getType(deleteAnnotations):
    case getType(createAnnotation):
    case getType(updateAnnotation):
    case getType(createAnnotationRelation):
    case getType(deleteAnnotationRelation):
    case getType(deleteAnnotationChildRelations): {
      const prevAnnotations = fastShallowClone(
        selectAnnotations(previousState),
      );
      const prevRelations = fastShallowClone(
        previousState.annotationRelations.entities,
      );
      return [
        replaceAnnotations(
          prevAnnotations,
          previousState.annotations.sourceDocumentId,
        ),
        replaceAnnotationRelations(prevRelations),
      ];
    }

    default: {
      return [];
    }
  }
}

/**
 * Create a "redo" action for a particular store action.
 * @param action The action being fired.
 * @param previousState The state before reducers have run the action.
 * @param postState The state after reducers have run the action.
 */
export function createRedoAnnotationActions(
  action: Action & any,
  previousState: AppState,
  postState: AppState,
): (Action & any)[] {
  switch (action.type) {
    case getType(appendAnnotations):
    case getType(appendAnnotationRelations):
    case getType(deleteAnnotation):
    case getType(deleteAnnotations):
    case getType(createAnnotation):
    case getType(updateAnnotation):
    case getType(createAnnotationRelation):
    case getType(deleteAnnotationRelation):
    case getType(deleteAnnotationChildRelations): {
      const newAnnotations = fastShallowClone(selectAnnotations(postState));
      const newRelations = fastShallowClone(
        postState.annotationRelations.entities,
      );
      return [
        replaceAnnotationRelations(newRelations),
        replaceAnnotations(
          newAnnotations,
          postState.annotations.sourceDocumentId,
        ),
      ];
    }

    default: {
      return [];
    }
  }
}
