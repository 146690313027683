import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { AppState } from '../../app-state';
import { createAnnotationFromSelection } from '../actions/annotations.actions';
import { clearAnnotationSelection } from '../actions/ui-annotations.actions';
import { AnnotationShortcutKeys } from '../components/annotation-shortcut-keys.component';
import { AnnotationType } from '../models/annotation-type.model';
import { selectActiveAnnotationSchema } from '../selectors/annotation-schema.selectors';

export type AnnotationMode = 'selection' | 'manual';

interface AnnotationShortcutProps {
  // "selection" mode creates annotations from selected text.
  // "manual" mode creates an empty-value annotation in the table without text offsets.
  mode?: AnnotationMode;
  disabled?: boolean;
  onAnnotationPress?: (annotationType: AnnotationType) => void;
}

const selectDisabledFromProps = (
  _: AppState,
  props: AnnotationShortcutProps,
): boolean | undefined => props.disabled;

const selectAnnotationTypesForShortcuts = createSelector(
  [selectDisabledFromProps, selectActiveAnnotationSchema],
  (disabled, schema) =>
    !disabled && schema
      ? schema.annotationTypes.filter(atn => atn.keyboardShortcut)
      : null,
);

export const AnnotationShortcuts = connect(
  (state: AppState, ownProps: AnnotationShortcutProps) => ({
    annotations: selectAnnotationTypesForShortcuts(state, ownProps),
  }),
  (dispatch, ownProps: AnnotationShortcutProps) => ({
    onAnnotationPress: (type: AnnotationType) => {
      if (ownProps.onAnnotationPress) {
        ownProps.onAnnotationPress(type);
      }

      if (ownProps.mode === 'selection') {
        dispatch(createAnnotationFromSelection(type));
        dispatch(clearAnnotationSelection());
      }
    },
  }),
)(AnnotationShortcutKeys);
