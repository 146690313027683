import React from 'react';
import { RenderHighlight } from '../models/render-highlight.model';
import { UserHighlight } from '../models/user-highlight.model';

interface TextSegmentProps {
  highlight?: RenderHighlight | UserHighlight;
  key: string | number | null;
  startOffset?: number;
  endOffset?: number;
  className?: string;
  style?: any;
  domRef?: (ref: HTMLSpanElement | null) => any;
  render?: (component: JSX.Element, highlights: UserHighlight[]) => JSX.Element;
}
export class TextSegment extends React.PureComponent<TextSegmentProps> {
  static elementClass = 'oseberg-text-highlight';

  get classNames() {
    const { className, highlight } = this.props;
    let classNames = [TextSegment.elementClass];
    if (className) {
      classNames.push(className);
    }

    if (highlight instanceof RenderHighlight) {
      classNames = classNames.concat(
        highlight.userHighlights.map(h => h.htmlClass),
      );
    }

    return classNames.filter(name => !!name).join(' ');
  }

  get highlights() {
    const highlight = this.props.highlight;
    if (highlight instanceof RenderHighlight) {
      return highlight.userHighlights;
    } else if (highlight) {
      return [highlight];
    } else {
      return [];
    }
  }

  get style() {
    const highlight = this.props.highlight;
    const styleOverrides = this.props.style || {};
    const style: any = highlight
      ? {
          backgroundColor: highlight.color.hex,
          color: 'white',
          ...styleOverrides,
        }
      : styleOverrides;
    const highlights = this.highlights;

    if (highlights.length > 1) {
      style.borderTop = `1px solid ${highlights[0].color.hex}`;
      style.borderBottom = `1px solid ${highlights[1].color.hex}`;
    }

    return style;
  }

  handleRef = (ref: HTMLSpanElement) => {
    if (this.props.domRef) {
      this.props.domRef(ref);
    }
  };

  renderDefault(start?: number, end?: number) {
    return (
      <span
        className={this.classNames}
        data-start-offset={start}
        data-end-offset={end}
        style={this.style}
        ref={this.handleRef}
      >
        {this.props.children}
      </span>
    );
  }

  render() {
    const { highlight, startOffset, endOffset } = this.props;

    const component = this.renderDefault(startOffset, endOffset);

    return this.props.render && highlight
      ? this.props.render(component, this.highlights)
      : component;
  }
}
