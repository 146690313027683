import React from 'react';
import { Link } from 'react-router-dom';

export class NotFoundComponent extends React.PureComponent {
  render() {
    return (
      <div>
        <h1>404 NOT FOUND</h1>
        <Link to="/">
          <strong>Go Home</strong>
        </Link>
      </div>
    );
  }
}
