import styled from 'styled-components';

export const SearchBarWrapper = styled.div`
  text-align: left;
  padding: 0 0 8px 8px;

  input.ant-input,
  .ant-input-group-addon {
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #eee;
    background-color: white;
    font-size: 16px;
    height: 42px;
    font-size: 18px;

    box-shadow: none;
  }
`;
