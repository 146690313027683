import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  PermissionActions,
  PermissionResources,
} from '../../accounts/models/permission.model';
import { selectHasPermissions } from '../../accounts/selectors/users.selectors';
import { AppState } from '../../app-state';
import {
  closeDocument,
  fetchSourceDocumentFromUrl,
} from '../../documents/actions/source-document.actions';
import { setPaneSize } from '../actions/ui-panes.actions';
import { AppPanes } from '../components/panes-layout-01.component';
import { selectPdfPaneWidth } from '../selectors/ui.selectors';

const annotatePermissions = {
  action: PermissionActions.Annotate,
  resource: PermissionResources.DocumentSet,
};

export const ConnectedAppPanesContainer = connect(
  (state: AppState) => ({
    pdfPaneWidth: selectPdfPaneWidth(state),
    showAnnotationToolbar: selectHasPermissions([annotatePermissions])(state),
  }),
  { onPaneSizeChange: setPaneSize, fetchSourceDocumentFromUrl, closeDocument },
)(AppPanes);

export const AppPanesContainer = withRouter(ConnectedAppPanesContainer);
