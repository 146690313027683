import { Card, Form, Input, Popconfirm, Select, Table } from 'antd';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { SchemaCreator } from '../containers/schema-creator.container';
import { AnnotationSchema } from '../../annotations/models/annotation-schema.model';
import { Button, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import AnnotationTypeTable from '../containers/annotation-type-table.container';
import { tableSearchProps } from '../../common/utils/tableSearchProps';
import { FormInstance } from 'antd/lib/form';
import { isEqual } from 'lodash/fp';
import {
  PermissionAction,
  PermissionActions,
} from '../../accounts/models/permission.model';
import { Group } from '../../accounts/models/group.model';
import { map } from 'lodash';
import { DateComponent } from '../../common/components/date.component';
import { DomainLookup } from '../../domain/models/lookup-context.model';

export type SchemaViewerDispatchProps = {
  fetchGroups: () => void;
  fetchSchemas: () => void;
  fetchLookups: () => void;
  deleteSchema: (schemaId: number) => unknown;
  updateSchema: (schema: AnnotationSchema) => unknown;
  isPermitted: (schemaId: number, action: PermissionAction) => boolean;
};

export type SchemaViewerProps = {
  schemas: AnnotationSchema[];
  groups: Group[];
  lookups: DomainLookup[];
} & SchemaViewerDispatchProps;

const SchemaDiv = styled.div`
  overflow: auto;
  max-height: calc(100vh - 76px);
  width: 100%;
  margin-top: 1em;
`;

export const SchemaViewerComponent: React.FC<SchemaViewerProps> = props => {
  const fetchGroups = props.fetchGroups;
  const fetchLookups = props.fetchLookups;
  const fetchSchemas = props.fetchSchemas;
  useEffect(fetchGroups, [fetchGroups]);
  useEffect(fetchLookups, [fetchLookups]);
  useEffect(fetchSchemas, [fetchSchemas]);

  const [expandedRowKeys, setExpandedRowKeys] = React.useState<number[]>([]);
  const [schemas, setSchemas] = React.useState(props.schemas);

  useEffect(() => {
    setSchemas(props.schemas);
  }, [props.schemas]);

  function compareStrings(s1?: string, s2?: string) {
    return (s1 || '').localeCompare(s2 || '');
  }

  function onTableRowExpand(expanded: boolean, schema: AnnotationSchema) {
    const keys = expanded ? [schema.annotationSchemaId] : [];
    setExpandedRowKeys(keys);
  }

  function handleSave(row: AnnotationSchema) {
    const newData = [...schemas];
    const index = newData.findIndex(
      item => row.annotationSchemaId === item.annotationSchemaId,
    );
    const item = newData[index];

    if (!isEqual(row, item)) {
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      setSchemas(newData);
      props.updateSchema(row);
    }
  }

  function groupName(groupId: number) {
    return props.groups.find(g => g.groupId === groupId)?.groupName;
  }

  const expandedRowRender = schema => {
    return <AnnotationTypeTable schema={schema} />;
  };

  const columns = [
    {
      title: 'Schema ID',
      dataIndex: 'annotationSchemaId',
      rowKey: 'annotationSchemaId',
      sorter: (a, b) => a.annotationSchemaId - b.annotationSchemaId,
    },
    {
      title: 'Group',
      dataIndex: 'groupId',
      rowKey: 'group',
      sorter: (a, b) =>
        compareStrings(groupName(a.groupId), groupName(b.groupId)),
      editable: true,
      render: gid => groupName(gid) || '-',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      rowKey: 'title',
      sorter: (a, b) => compareStrings(a.title, b.title),
      editable: true,
      render: title => title || '-',
      ...tableSearchProps('title'),
    },
    {
      title: 'Document Set Matcher',
      dataIndex: 'documentSetMatcher',
      rowKey: 'documentSetMatcher',
      render: matcher => matcher || '-',
      sorter: (a, b) =>
        compareStrings(a.documentSetMatcher, b.documentSetMatcher),
      editable: true,
      ...tableSearchProps('documentSetMatcher'),
    },
    {
      title: 'Document Type Matcher',
      dataIndex: 'classificationMatcher',
      rowKey: 'classificationMatcher',
      render: matcher => matcher || '-',
      sorter: (a, b) =>
        compareStrings(a.classificationMatcher, b.classificationMatcher),
      editable: true,
      ...tableSearchProps('classificationMatcher'),
    },
    {
      title: 'Lookups',
      dataIndex: 'lookups',
      rowKey: 'lookups',
      render: ls => ls.length,
      sorter: (a, b) => a.lookups.length - b.lookups.length,
      editable: true,
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      rowKey: 'createdAt',
      render: d => <DateComponent serializedDate={d} />,
      sorter: (a, b) => compareStrings(a.createdAt, b.createdAt),
    },
    {
      title: 'Actions',
      dataIndex: '',
      rowKey: 'action',
      render: (_: any, schema: AnnotationSchema) => (
        <>
          <Tooltip title="delete" mouseEnterDelay={0.5}>
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => props.deleteSchema(schema.annotationSchemaId)}
              disabled={
                !props.isPermitted(
                  schema.annotationSchemaId,
                  PermissionActions.Delete,
                )
              }
            >
              <Button
                danger={true}
                disabled={
                  !props.isPermitted(
                    schema.annotationSchemaId,
                    PermissionActions.Delete,
                  )
                }
              >
                {' '}
                <DeleteOutlined />{' '}
              </Button>
            </Popconfirm>
          </Tooltip>
        </>
      ),
    },
  ];

  const newColumns = columns.map(col => {
    if (!col['editable']) {
      return col;
    }
    return {
      ...col,
      onCell: (record: AnnotationSchema) => ({
        record,
        editable: col['editable'],
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
        isPermitted: props.isPermitted,
        groups: props.groups,
        lookups: props.lookups,
      }),
    };
  });

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  return (
    <Card style={{ height: '85vh' }}>
      <SchemaCreator />
      <SchemaDiv>
        <Table
          components={components}
          expandedRowKeys={expandedRowKeys}
          onExpand={onTableRowExpand}
          expandable={{ expandedRowRender }}
          dataSource={schemas}
          columns={newColumns}
          pagination={false}
          scroll={{ y: '70vh' }}
          size="middle"
          rowKey={(schema: AnnotationSchema) => schema.annotationSchemaId}
        />
      </SchemaDiv>
    </Card>
  );
};

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof AnnotationSchema;
  record: AnnotationSchema;
  handleSave: (record: AnnotationSchema) => void;
  isPermitted: (schemaId: number, action: PermissionAction) => boolean;
  groups: Group[];
  lookups: DomainLookup[];
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  isPermitted,
  groups,
  lookups,
  ...restProps
}) => {
  const [editing, setEditing] = React.useState(false);
  const inputRef = React.useRef<Input>(null);
  const oldValue = dataIndex ? record[dataIndex] : undefined;
  const [value, setValue] = React.useState(oldValue);

  const updateValue = (v: any) => {
    const newRecord = { ...record, [dataIndex]: v };
    handleSave(newRecord);
    toggleEdit();
  };

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  let childNode = children;

  const canEdit =
    editable &&
    isPermitted(record.annotationSchemaId, PermissionActions.Modify);

  const ENTER_KEY = 13;
  const ESC_KEY = 27;

  function getInputNode(ref: React.RefObject<any>, index: string) {
    switch (index) {
      case 'groupId': {
        const options = map(groups, g => {
          return { label: g.groupName, value: g.groupId };
        });
        return (
          <Select
            style={{ width: '100%' }}
            ref={ref}
            showAction={['focus', 'click']}
            onChange={updateValue}
            onBlur={_ => toggleEdit()}
            showSearch
            placeholder="Search to Select"
            options={options}
            filterOption={(search, option) => {
              const group = groups.find(g => g.groupId === option?.value);
              const groupName = group?.groupName || '';
              return groupName.toLowerCase().includes(search.toLowerCase());
            }}
          />
        );
      }
      case 'lookups': {
        const options = map(lookups, l => {
          return { label: l.name, value: l.domainLookupId };
        });

        const idValues: number[] = (value as DomainLookup[]).map(
          l => l.domainLookupId,
        );
        const setLookups = (ids: number[]) => {
          const includes = (i: number) => ids.indexOf(i) > -1;
          const newVals = lookups.filter(l => includes(l.domainLookupId));
          setValue(newVals);
        };
        return (
          <Select
            value={idValues}
            style={{ width: '100%' }}
            ref={ref}
            showAction={['focus', 'click']}
            onChange={setLookups}
            onBlur={e => updateValue(value)}
            showSearch
            placeholder="Search to Select"
            options={options}
            mode="multiple"
            onInputKeyDown={k => {
              const code = k.which;
              if (code === ESC_KEY || code === ENTER_KEY) {
                k.stopPropagation();
                updateValue(value);
              }
            }}
            filterOption={(search, option) => {
              const lookup = lookups.find(
                l => l.domainLookupId === option?.value,
              );
              const lookupName = lookup?.name || '';
              return lookupName.toLowerCase().includes(search.toLowerCase());
            }}
          />
        );
      }
      default: {
        return (
          <Input
            defaultValue={value as string}
            onChange={e => setValue(e.currentTarget.value)}
            ref={inputRef}
            onPressEnter={e => updateValue(value)}
            onBlur={e => updateValue(value)}
          />
        );
      }
    }
  }

  if (canEdit) {
    childNode = editing ? (
      getInputNode(inputRef, dataIndex)
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;
