import { DownloadOutlined, EditOutlined } from '@ant-design/icons';
import { Badge, Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Restricted } from '../../accounts/containers/restricted.container';
import {
  PermissionActions,
  PermissionResources,
} from '../../accounts/models/permission.model';
import { AppRoutes, pathWithParams } from '../../app-routes';

const annotatePermissions = {
  action: PermissionActions.Annotate,
  resource: PermissionResources.DocumentSet,
};

const ActionLinkWrap = styled.span`
  a {
    margin-right: 6px;
  }
`;

export type ActionLinkProps = {
  annotatedDocumentId: number;
  aggregateId: string;
  docIsDownloadable: boolean;
  hasDocumentText: boolean;
  downloadDocumentById: (annotatedDocumentId: number, saveAs: string) => void;
  style?: React.CSSProperties;
};

export const SearchActionLinks = ({
  annotatedDocumentId,
  aggregateId,
  style,
  docIsDownloadable,
  hasDocumentText,
  downloadDocumentById,
}: ActionLinkProps) => {
  const statusTooltip = hasDocumentText ? '' : 'Document text unavailable';
  const status = hasDocumentText ? 'success' : 'warning';

  const statusBadge = (
    <Tooltip title={statusTooltip}>
      <Badge status={status} />
    </Tooltip>
  );

  const tooltip = hasDocumentText ? (
    <>
      <Restricted required={annotatePermissions}>
        <Tooltip title={hasDocumentText ? 'Edit document' : 'Text unavailable'}>
          {hasDocumentText ? (
            <Link
              to={pathWithParams(AppRoutes.AnnotationPage, {
                id: annotatedDocumentId,
                action: 'edit',
              })}
            >
              <EditOutlined />
            </Link>
          ) : (
            <Badge count={0} status="warning" />
          )}
        </Tooltip>
      </Restricted>{' '}
      {docIsDownloadable && (
        <Tooltip
          title={hasDocumentText ? 'Download document' : 'Document unavailable'}
        >
          {hasDocumentText ? (
            <Link
              to="#"
              onClick={_ =>
                downloadDocumentById(annotatedDocumentId, `${aggregateId}.pdf`)
              }
            >
              <DownloadOutlined />
            </Link>
          ) : (
            <Badge count={0} status="warning" />
          )}
        </Tooltip>
      )}
    </>
  ) : (
    <></>
  );

  return (
    <ActionLinkWrap style={style}>
      {statusBadge}
      {tooltip}
    </ActionLinkWrap>
  );
};
