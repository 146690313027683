import React from 'react';
import { connect } from 'react-redux';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
  withRouter,
} from 'react-router-dom';
import { AppRoutes, withUrlParams } from '../../app-routes';
import { AppState } from '../../app-state';
import { selectUserIsLoggedIn } from '../selectors/auth.selectors';

interface PrivateRouteOps extends RouteProps {
  isAuthenticated: boolean;
  path?: string;
  children?: React.ReactNode | React.ReactNode[];
}

// This route renders only if the user is signed in.
export const PrivateRoute = connect((state: AppState) => ({
  isAuthenticated: selectUserIsLoggedIn(state),
}))((props: PrivateRouteOps) => {
  const { component, ...restProps } = props;

  return (
    <Route
      {...restProps}
      component={props.isAuthenticated ? component : RedirectToLogin}
    />
  );
});

const RedirectToLogin = withRouter((routeProps: RouteComponentProps) => (
  <Redirect
    to={withUrlParams(AppRoutes.Login, {
      redirect: routeProps.location.pathname,
    })}
  />
));
