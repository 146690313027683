import { createAction } from 'typesafe-actions';
import {
  AnnotationDocumentStatus,
  AnnotationDocumentStatuses,
} from '../resources/annotation-document-status.resource';

export type OptionalDocumentStatus = AnnotationDocumentStatus | undefined;

enum Types {
  FETCH_ANNOTATION_DOCUMENT_STATUSES = 'FETCH_ANNOTATION_DOCUMENT_STATUSES',
  POPULATE_ANNOTATION_DOCUMENT_STATUSES = 'POPULATE_ANNOTATION_DOCUMENT_STATUSES',
  ADD_ANNOTATION_DOCUMENT_STATUS = 'ADD_ANNOTATION_DOCUMENT_STATUS',
  REMOVE_ANNOTATION_DOCUMENT_STATUS = 'REMOVE_ANNOTATION_DOCUMENT_STATUS',
  SAVE_ANNOTATION_DOCUMENT_STATUSES = 'SAVE_ANNOTATION_DOCUMENT_STATUSES',
  SAVE_ANNOTATION_DOCUMENT_STATUSES_ERROR = 'SAVE_ANNOTATION_DOCUMENT_STATUSES_ERROR',
  SAVE_ANNOTATION_DOCUMENT_STATUSES_SUCCESS = 'SAVE_ANNOTATION_DOCUMENT_STATUSES_SUCCESS',
  DOCUMENT_DOES_NOT_EXIST_ERROR = 'DOCUMENT_DOES_NOT_EXIST_ERROR',
}

export const fetchAnnotationDocumentStatuses = createAction(
  Types.FETCH_ANNOTATION_DOCUMENT_STATUSES,
  resolvePayload => (documentId: number) => resolvePayload({ documentId }),
);

export const populateAnnotationDocumentStatuses = createAction(
  Types.POPULATE_ANNOTATION_DOCUMENT_STATUSES,
  resolvePayload => (documentStatuses: AnnotationDocumentStatuses) =>
    resolvePayload(documentStatuses),
);

export const addAnnotationDocumentStatus = createAction(
  Types.ADD_ANNOTATION_DOCUMENT_STATUS,
  resolvePayload => (status: OptionalDocumentStatus) =>
    resolvePayload({
      status,
    }),
);

export const removeAnnotationDocumentStatus = createAction(
  Types.REMOVE_ANNOTATION_DOCUMENT_STATUS,
  resolvePayload => (status: OptionalDocumentStatus) =>
    resolvePayload({
      status,
    }),
);

export const saveAnnotationDocumentStatuses = createAction(
  Types.SAVE_ANNOTATION_DOCUMENT_STATUSES,
);

export const saveAnnotationDocumentStatusesError = createAction(
  Types.SAVE_ANNOTATION_DOCUMENT_STATUSES_ERROR,
  resolvePayload => (error: string) => resolvePayload({ error }),
);

export const saveAnnotationDocumentStatusesSuccess = createAction(
  Types.SAVE_ANNOTATION_DOCUMENT_STATUSES_SUCCESS,
  resolvePayload => (documentStatuses: AnnotationDocumentStatuses) =>
    resolvePayload(documentStatuses),
);

export const documentDoesNotExistError = createAction(
  Types.DOCUMENT_DOES_NOT_EXIST_ERROR,
);
