import { connect } from 'react-redux';
import {
  PermissionActions,
  PermissionResources,
} from '../../accounts/models/permission.model';
import { selectHasPermissions } from '../../accounts/selectors/users.selectors';
import { AppState } from '../../app-state';
import {
  addQueueItems,
  addQueueItemsBulk,
  fetchQueues,
} from '../../queues/actions/queue.actions';
import { selectAllQueuesAsArray } from '../../queues/selectors';
import {
  addTagToDocumentsAsync,
  addTagToDocumentsBulkAsync,
  fetchTagsAsync,
  removeTagFromDocumentsAsync,
  removeTagFromDocumentsBulkAsync,
} from '../../tags/actions/tag.actions';
import { GroupScopedTagsResource } from '../../tags/resources/group-scoped-tags.resource';
import {
  selectGroupScopedTagNames,
  selectGroupScopedTagsGroupedByName,
} from '../../tags/selectors/tag.selectors';
import {
  cancelExportJob,
  deleteExportJob,
  downloadExport,
  refreshExportJob,
  submitExportJob,
} from '../actions/export.actions';
import { ExportParams } from '../models/export.model';
import { selectCurrentQueryWithIds, selectSelectedResults } from '../selectors';
import { clearSelectedSearchResults } from '../actions/search.actions';
import { ActionModal } from '../components/selected-items-actions-modal.component';

type ActionModalContainerProps = {
  hitCount: number;
};

const selectUserHasQueueWritePermissions = selectHasPermissions([
  {
    action: PermissionActions.Write,
    resource: PermissionResources.Queue,
  },
]);

export const ActionModalContainer = connect(
  (state: AppState, ownProps: ActionModalContainerProps) => ({
    queues: selectAllQueuesAsArray(state),
    tagNames: selectGroupScopedTagNames(state),
    tagsByName: selectGroupScopedTagsGroupedByName(state),
    userCanTakeQueueActions: selectUserHasQueueWritePermissions(state),
    selectedResults: selectSelectedResults(state),
    hitCount: ownProps.hitCount,
    fetchDocumentTags: GroupScopedTagsResource.getGroupScopedTagsForDocument(
      state,
    ),
    exports: state.exports.exports,
    exportJobs: state.exports.jobs,
    query: selectCurrentQueryWithIds(state),
  }),
  dispatch => ({
    fetchQueues: () => dispatch(fetchQueues()),
    fetchTags: () => dispatch(fetchTagsAsync.request()),
    addQueueItems: (queueId: number, annotatedDocumentIds: number[]) =>
      dispatch(addQueueItems(queueId, annotatedDocumentIds)),
    addQueueItemsBulk: (queueId: number) =>
      dispatch(addQueueItemsBulk(queueId)),
    addTagToDocuments: (payload: any) =>
      dispatch(addTagToDocumentsAsync.request(payload)),
    addTagToDocumentsBulk: (payload: any) =>
      dispatch(addTagToDocumentsBulkAsync.request(payload)),
    removeTagFromDocuments: (payload: any) =>
      dispatch(removeTagFromDocumentsAsync.request(payload)),
    removeTagFromDocumentsBulk: (payload: any) =>
      dispatch(removeTagFromDocumentsBulkAsync.request(payload)),
    clearSelectedSearchResults: () => dispatch(clearSelectedSearchResults()),
    submitExportJob: (jobId: string, query: object, params: ExportParams) => {
      dispatch(submitExportJob(jobId, query, params));
    },
    cancelExportJob: (jobId: string) => dispatch(cancelExportJob(jobId)),
    deleteExportJob: (jobId: string) => dispatch(deleteExportJob(jobId)),
    downloadExport: (jobId: string) => dispatch(downloadExport(jobId)),
    refreshExportJob: (jobId: string) => dispatch(refreshExportJob(jobId)),
  }),
)(ActionModal);
