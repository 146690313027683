import React from 'react';
import {
  DeleteOutlined,
  DownloadOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { Button, Progress, Table, Tooltip } from 'antd';
import { ExportJob } from '../models/export.model';
import { KeyValueMap } from '../../common/types/KeyValueMap.type';
import { values } from 'lodash';

export interface ExportTableProps {
  jobs: KeyValueMap<ExportJob>;
  downloadExport: (jobId: string) => void;
  refreshExportJob: (jobId: string) => void;
  deleteExportJob: (jobId: string) => void;
}
export const ExportTable = (props: ExportTableProps) => {
  function renderStatus(job: ExportJob) {
    const status = job.status;
    const progress = status.progress;
    const jobComplete = progress.current === progress.total;
    const exportLimit = job.params.limit;
    const hitCount = status.progress.total;
    const limitedCount = hitCount > exportLimit ? exportLimit : hitCount;
    const totalCount = progress.total > 1 ? progress.total : limitedCount;
    const processedCount = progress.current;
    const progressStatus = status.error
      ? 'exception'
      : jobComplete
      ? 'success'
      : 'active';
    return (
      <>
        <Progress
          percent={Math.floor((processedCount * 100) / totalCount)}
          showInfo={true}
          status={progressStatus}
          type="circle"
          width={50}
        />
      </>
    );
  }

  function renderActions(job: ExportJob) {
    const jobId = job.jobId;
    const downloadReady = job.status.pending || !!job.status.error;
    return (
      <>
        <Tooltip title="download" mouseEnterDelay={0.5}>
          <Button
            onClick={() => props.downloadExport(jobId)}
            disabled={downloadReady}
          >
            <DownloadOutlined />
          </Button>
        </Tooltip>
        <Tooltip title="refresh" mouseEnterDelay={0.5}>
          <Button onClick={() => props.refreshExportJob(jobId)}>
            <ReloadOutlined />
          </Button>
        </Tooltip>
        <Tooltip title="delete" mouseEnterDelay={0.5}>
          <Button danger={true} onClick={() => props.deleteExportJob(jobId)}>
            <DeleteOutlined />
          </Button>
        </Tooltip>
      </>
    );
  }

  const columns = [
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 125,
      render: (d: Date) => <> {d.toLocaleString()} </>,
    },
    {
      title: 'Format',
      dataIndex: ['params', 'output'],
    },
    {
      title: 'Progress',
      render: renderStatus,
    },
    {
      title: 'Actions',
      width: 180,
      render: renderActions,
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={values(props.jobs)}
      pagination={false}
    />
  );
};

export default ExportTable;
