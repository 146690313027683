import { Layout } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { UserAccountCard } from '../containers/user-account-card.container';
import { UserTable } from '../containers/user-table.container';
import { ContentCard } from './content-card.component';
const Content = Layout.Content;

const UserManagerStyle = styled(Content)`
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  align-items: flex-start;

  .ant-table {
    border: none;
  }
`;

interface UserManagerState {
  selectedUserId: number | null;
}
export class UserManagerComponent extends React.Component<
  {},
  UserManagerState
> {
  constructor(props: {}) {
    super(props);

    this.state = { selectedUserId: null };
  }

  selectUser = (userId: number) => {
    this.setState({ selectedUserId: userId });
  };

  render() {
    const { selectedUserId } = this.state;

    return (
      <UserManagerStyle>
        <ContentCard>
          <UserTable onSelect={user => this.selectUser(user.userId)} />
        </ContentCard>
        <ContentCard>
          {selectedUserId && <UserAccountCard userId={selectedUserId} />}
        </ContentCard>
      </UserManagerStyle>
    );
  }
}
