import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AppRoutes, pathWithParams } from '../../app-routes';
import { DateComponent } from '../../common/components/date.component';
import { AnnotatedDocument } from '../../documents/models/annotated-document.model';
import { defaultDateFormat } from '../containers/document-set-manager.container';

const SummaryColumn = styled.div`
  display: flex;
  flex-flow: column;
  flex: 0 0;
  & > * {
    margin-bottom: 5px;
  }
`;

type DocManagementSummaryProps = {
  document: AnnotatedDocument;
  style?: React.CSSProperties;
};
export function DocManagementSummary({
  document,
  style = {},
}: DocManagementSummaryProps) {
  const dataMissingPlaceholder = 'N/A';
  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'space-between',
        ...style,
      }}
    >
      <SummaryColumn style={{ flexBasis: '25%' }}>
        <div>
          <strong>Document Id</strong>
        </div>
        <div>
          <strong>Annotated</strong>
        </div>
        <div>
          <strong>Reviewed</strong>
        </div>
      </SummaryColumn>
      <SummaryColumn style={{ flexBasis: '50%' }}>
        <div>{document.annotatedDocumentId || dataMissingPlaceholder}</div>
        <div>
          {document.lastAnnotationTimestamp ? (
            <DateComponent
              serializedDate={document.lastAnnotationTimestamp}
              format={defaultDateFormat}
            />
          ) : (
            dataMissingPlaceholder
          )}
        </div>
        <div>
          {document.lastReviewTimestamp ? (
            <DateComponent
              serializedDate={document.lastReviewTimestamp}
              format={defaultDateFormat}
            />
          ) : (
            dataMissingPlaceholder
          )}
        </div>
      </SummaryColumn>
      <div
        style={{
          display: 'flex',
          flexFlow: 'column',
          flex: '0 0 25%',
          alignItems: 'flex-end',
          fontSize: '14px',
        }}
      >
        {document.documentTextId ? (
          <Link
            style={{ marginRight: '8px' }}
            to={pathWithParams(AppRoutes.AnnotationPage, {
              id: document.annotatedDocumentId,
              action: 'edit',
            })}
          >
            View in Extract
          </Link>
        ) : (
          <span>Document text unavailable</span>
        )}
      </div>
    </div>
  );
}
