import React from 'react';
import styled from 'styled-components';
import { DiscontinousSelection } from '../../lib/discontinous-selection/components/discontinous-selection.component';
import { TextSegment } from '../../text-highlighter/components/text-segment.component';
import { HighlightCollection } from '../../text-highlighter/models/highlight-collection.model';
import { UserHighlight } from '../../text-highlighter/models/user-highlight.model';
import { PDF_SELECTION_MODE } from '../models/pdf-selection-modes.model';
import { PDFTextContent, PDFTextItem } from '../models/pdf-text-content.model';
import { PDFJSPageViewport } from '../models/pdfjs-page-viewport.model';
import AnnotationPopover from '../../annotations/components/annotation-popover.component';

const StyledContent = styled.div`
  position: absolute;
  text-align: left;
  color: transparent;

  .oseberg-text-highlight {
    opacity: 0.6;

    &::selection {
      color: rgba(0, 0, 0, 0);
    }
  }
`;

export interface PDFAnnotationLayerProps {
  highlights: HighlightCollection;
  textOffsets: number[];
  pdfTextContent: PDFTextContent;
  viewport: PDFJSPageViewport;
  onSelectionChange: (elm: HTMLElement[]) => any;
  selectionMode: PDF_SELECTION_MODE;
}
export class PDFAnnotationLayer extends React.Component<
  PDFAnnotationLayerProps
> {
  static className = 'ose-pdf-annotation-layer';

  shouldComponentUpdate(nextProps: PDFAnnotationLayerProps) {
    const shouldUpdate =
      this.props.highlights.id !== nextProps.highlights.id ||
      this.props.viewport !== nextProps.viewport ||
      this.props.pdfTextContent !== nextProps.pdfTextContent ||
      this.props.selectionMode !== nextProps.selectionMode;

    return shouldUpdate;
  }

  get scale() {
    return this.props.viewport.scale;
  }

  scaleByTransform(scaleBy: number) {
    return [scaleBy, 0, 0, scaleBy, 0, 0];
  }

  /**
   * Returns the highlighted text segments for a text item. Or, if the text item
   * is not highlighted, only the text as the element content.
   */
  renderHighlightContent(
    textItem: PDFTextItem,
    textOffset: number,
  ): JSX.Element[] | string {
    const str = textItem.str.trim();
    const endOffset = textOffset + str.length;
    const highlights = this.props.highlights.overlapsRange(
      textOffset,
      endOffset,
    );

    return highlights.length
      ? createHighlightElements(textItem, highlights, textOffset)
      : textItem.str;
  }

  /**
   * Inverts Y coordinate system and scales to vieport.
   * PDF y coordinates begin from bottom left, but screen
   * coordinates begin from top-left. This inverts pdf y coordinates
   * to match the screen coordinate system.
   * @param transform
   * @param viewport
   */
  pdfToScreen(transform: number[], viewport: PDFJSPageViewport): number[] {
    const yMax = this.props.viewport.viewBox[3];
    const scaleY = this.vectorLength(transform[2], transform[3]);
    return [
      transform[0],
      -transform[1],
      -transform[2],
      transform[3],
      transform[4] * viewport.scale,
      (yMax - transform[5] - scaleY) * viewport.scale,
    ];
  }

  transform(m1: number[], m2: number[]): number[] {
    return [
      m1[0] * m2[0] + m1[2] * m2[1],
      m1[1] * m2[0] + m1[3] * m2[1],
      m1[0] * m2[2] + m1[2] * m2[3],
      m1[1] * m2[2] + m1[3] * m2[3],
      m1[0] * m2[4] + m1[2] * m2[5] + m1[4],
      m1[1] * m2[4] + m1[3] * m2[5] + m1[5],
    ];
  }

  vectorLength(x: number, y: number): number {
    return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
  }

  extractScale(transform: number[]) {
    return [
      this.vectorLength(transform[0], transform[1]),
      this.vectorLength(transform[2], transform[3]),
    ];
  }

  /**
   * Returns the font size of a transform by measuring the
   * length of the y
   * @param transform
   */
  fontSize(transform: number[]) {
    return this.vectorLength(transform[2], transform[3]);
  }

  renderTextItem = (item: PDFTextItem, idx: number) => {
    const { textOffsets } = this.props;
    const scale = this.scale;
    const fontSize = this.fontSize(item.transform);

    const txItem = this.pdfToScreen(item.transform, this.props.viewport);
    const txReverseFontScaling = this.scaleByTransform(1 / fontSize);

    // Transform includes font size, remove font-size adjustment from transform.
    // (best allow css handle that).
    const tx2 = this.transform(txItem, txReverseFontScaling);

    const style = {
      position: 'absolute' as const,
      fontFamily: 'Courier',
      transformOrigin: 'left bottom 0px',
      height: '1em',
      whiteSpace: 'pre',
      fontSize: `${fontSize * scale}px`,
      color: 'rbga(255, 0, 0, 1)',
      transform: `matrix(${tx2.join(',')})`,
    };

    const startOffset = textOffsets[idx];

    return (
      <TextSegment
        key={idx}
        style={style}
        startOffset={startOffset}
        endOffset={
          typeof startOffset === 'number'
            ? textOffsets[idx] + item.str.trim().length
            : undefined
        }
      >
        {this.renderHighlightContent(item, textOffsets[idx])}
      </TextSegment>
    );
  };

  render() {
    const { pdfTextContent, viewport, selectionMode } = this.props;
    const width = viewport.viewBox[2] * this.scale;
    const height = viewport.viewBox[3] * this.scale;

    const style = {
      width,
      height,
      // These styles move this element off the canvas and back on using a transform.
      // This fixes severe performance issues with paint calls when the child text segements
      // use a matrix transform. Not absolutely certain why it works, but it's possible it causes
      // the whole element to be rendered off-screen, and then painted as a whole in position.
      left: `${viewport.width / 2}px`,
      top: `${viewport.height / 2}px`,
      transform: `translate(-${width / 2}px, -${height / 2}px) rotate(${
        viewport.rotation
      }deg)`,
    };

    return (
      <StyledContent className={PDFAnnotationLayer.className} style={style}>
        {selectionMode === 'selection-box' && (
          <DiscontinousSelection
            onSelectionChange={this.props.onSelectionChange}
            rotatedBy={viewport.rotation}
          >
            {pdfTextContent.items.map(this.renderTextItem)}
          </DiscontinousSelection>
        )}
        {selectionMode === 'normal' &&
          pdfTextContent.items.map(this.renderTextItem)}
      </StyledContent>
    );
  }
}

/**
 * Renders text highlights for a pdf.js text item.
 *
 * Accepts a pdf.js PDFTextItem, the highlights present in a document,
 * and the starting character offset of this text document.
 * Returns the text segments with proper highlights.
 * @param textItem
 * @param highlights
 * @param startOffset
 */
function createHighlightElements(
  textItem: PDFTextItem,
  highlights: UserHighlight[],
  startOffset: number,
): JSX.Element[] {
  const endOffset = startOffset + textItem.str.trim().length;
  const elements: any[] = [];

  if (startOffset < highlights[0].start) {
    const highlight = highlights[0];
    elements.push(
      createNonhighlightedElement(
        textItem,
        startOffset,
        highlight.start,
        startOffset,
      ),
    );
  }

  elements.push(createHighlightElement(textItem, highlights[0], startOffset));

  for (let i = 1; i < highlights.length; i++) {
    const highlight = highlights[i];
    const lastHighlight = highlights[i - 1];
    if (highlight.start !== lastHighlight.end) {
      elements.push(
        createNonhighlightedElement(
          textItem,
          lastHighlight.end,
          highlight.start,
          startOffset,
        ),
      );
    }

    elements.push(createHighlightElement(textItem, highlight, startOffset));
  }

  if (endOffset > highlights[highlights.length - 1].end) {
    const highlight = highlights[highlights.length - 1];

    elements.push(
      createNonhighlightedElement(
        textItem,
        highlight.end,
        endOffset,
        startOffset,
      ),
    );
  }

  return elements;
}

function createNonhighlightedElement(
  text: PDFTextItem,
  start: number,
  end: number,
  startOffset: number,
): JSX.Element {
  const overlayText = text.str
    .trim()
    .slice(Math.max(start - startOffset, 0), end - startOffset);
  const endOffset = Math.min(end, startOffset + overlayText.length);
  return (
    <TextSegment
      key={`${overlayText}_${startOffset}_${endOffset}`}
      className="pdf-text-content"
      startOffset={start}
      endOffset={endOffset}
      style={{ color: 'rgba(0, 0, 0, 0)' }}
    >
      {overlayText}
    </TextSegment>
  );
}

function createHighlightElement(
  text: PDFTextItem,
  highlight: UserHighlight,
  startOffset: number,
): JSX.Element {
  const textSlice = text.str
    .trim()
    .slice(
      Math.max(highlight.start - startOffset, 0),
      highlight.end - startOffset,
    );

  return (
    <TextSegment
      key={highlight.id}
      className="pdf-text-content"
      startOffset={Math.max(highlight.start, startOffset)}
      endOffset={Math.min(highlight.end, startOffset + text.str.length)}
      highlight={highlight}
      render={renderWithTooltip}
      style={{ color: 'rgba(0, 0, 0, 0)' }}
    >
      {textSlice}
    </TextSegment>
  );
}

function renderWithTooltip(
  RenderElement: JSX.Element,
  highlights: UserHighlight[],
): JSX.Element {
  const annotationIds = highlights.map(h => h.data.id);
  return (
    <AnnotationPopover annotationIds={annotationIds}>
      {RenderElement}
    </AnnotationPopover>
  );
}
