import { Select } from 'antd';
import React from 'react';
import { US_COUNTIES } from '../data/us-counties';
const Option = Select.Option;

interface CountySelectorProps {
  usState: string | null;
  selectedCounty?: string | string[];
  onChange: (value: string | string[]) => any;
  multi?: boolean;
}
interface CountySelectorState {
  options: string[];
}
export class CountySelector extends React.Component<
  CountySelectorProps,
  CountySelectorState
> {
  constructor(props: CountySelectorProps) {
    super(props);

    this.state = { options: this.getSelectorOptions(props.usState) };
  }

  componentWillReceiveProps(newProps: CountySelectorProps) {
    if (newProps.usState !== this.props.usState) {
      this.setState({ options: this.getSelectorOptions(newProps.usState) });
    }
  }

  getSelectorOptions(usState: string | null) {
    let options;
    if (usState && US_COUNTIES[usState]) {
      options = US_COUNTIES[usState];
    } else {
      options = US_COUNTIES.ALL;
    }

    return options;
  }

  render() {
    const { selectedCounty, onChange } = this.props;
    const mode = this.props.multi ? 'multiple' : undefined;
    return (
      <Select mode={mode} value={selectedCounty} onChange={onChange}>
        <Option key="all" value="all">
          All Counties
        </Option>
        {this.state.options.map(county => (
          <Option key={county} value={county}>
            {county}
          </Option>
        ))}
      </Select>
    );
  }
}
