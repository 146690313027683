import download from 'downloadjs';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AppState } from '../../app-state';
import { SourceDocumentUrlResource } from '../../documents/resources/source-document-url.resource';
import { AppActions } from '../../root.actions';
import {
  downloadDocumentById,
  downloadFile,
} from '../actions/download.actions';
import { AppEpic } from '../types/app-epic.type';
import { handleEpicError } from '../utils/epics';
import { authHeaders } from '../utils/fetch';

export function downloadEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(downloadFile)),
    mergeMap(action => {
      return ajax({
        url: action.payload.path,
        headers: authHeaders(state$.value),
        responseType: 'blob',
      }).pipe(
        tap(resp => download(resp.response, action.payload.saveAs)),
        catchError(handleEpicError('Download Error')),
      );
    }),
    // Pure side effect, filter everything.
    filter(e => false),
  );
}

export const downloadDocByIdEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(downloadDocumentById)),
    switchMap(action =>
      SourceDocumentUrlResource.get(
        state$.value,
        action.payload.annotatedDocumentId,
      ).pipe(
        map(resource => downloadFile(resource.url, action.payload.saveAs)),
        catchError(handleEpicError('Download Error')),
      ),
    ),
  );
