import { getType } from 'typesafe-actions';
import { signOut } from '../../auth/actions/auth.actions';
import { AuthActions } from '../../auth/actions/auth.types';
import { KeyValueMap } from '../../common/types/KeyValueMap.type';
import {
  fetchGroups,
  populateGroupAccounts,
  populateGroups,
  storeGroupAccount,
} from '../actions/groups.actions';
import { GroupsActions } from '../actions/groups.types';
import { GroupAccount } from '../models/group-account.model';
import { Group } from '../models/group.model';

export type GroupsState = {
  entities: KeyValueMap<Group>;
  accounts: KeyValueMap<GroupAccount>;
  loading: boolean;
};

const defaultState = {
  entities: {},
  accounts: {},
  loading: false,
};

export function groupsReducer(
  state: GroupsState = defaultState,
  action: GroupsActions | AuthActions,
): GroupsState {
  switch (action.type) {
    case getType(fetchGroups):
      return { ...state, loading: true };

    case getType(populateGroups): {
      const groups = KeyValueMap.usingPropertyKey<Group>(
        action.payload.groups,
        'groupId',
      );

      return {
        ...state,
        entities: groups,
        loading: false,
      };
    }

    case getType(populateGroupAccounts): {
      const groupAccounts = KeyValueMap.usingPropertyKey<GroupAccount>(
        action.payload.accounts,
        'group.groupId',
      );

      return {
        ...state,
        loading: false,
        accounts: groupAccounts,
      };
    }

    case getType(storeGroupAccount): {
      const account = action.payload.account;

      return {
        ...state,
        accounts: {
          ...state.accounts,
          [account.group.groupId]: account,
        },
      };
    }

    case getType(signOut):
      return defaultState;

    default:
      return state;
  }
}
