import { DatePicker } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Select } from 'antd';
import { LabeledValue, SelectValue } from 'antd/lib/select/index';
import { map } from 'lodash';
import moment from 'moment';
import { Moment } from 'moment';
import React from 'react';
import styled from 'styled-components';
import { Gray1 } from '../../common/colors';
import { CountySelector } from '../../common/components/county-selector.component';
import { StateSelector } from '../../common/components/state-selector.component';
import { YesNoSelector } from '../../common/components/yes-no-selector.component';
import { DocumentListFilter } from '../models/document-list-filter.model';

const FormContainer = styled.form`
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;

  h3 {
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 0.2em;
    border-bottom: 1px solid ${Gray1};
  }

  .force-clear {
    display: block;
  }
`;

const FormColumn = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: space-around;
`;

const ButtonColumn = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: space-around;
`;

const ControlGroup = styled.div`
  margin: 3px;
`;

interface FormProps {
  availableStatuses: string[];
  filter: DocumentListFilter;
  onSubmit: (filter: DocumentListFilter) => any;
}
interface FromState {
  filter: DocumentListFilter;
}
export class DocumentSelectionForm extends React.PureComponent<
  FormProps,
  FromState
> {
  constructor(props: FormProps) {
    super(props);

    this.state = { filter: props.filter };
  }

  onSubmit = () => {
    this.props.onSubmit(this.state.filter);
  };

  setFilterValue = (
    key: string,
    value: string | string[] | number | boolean | null,
  ) => {
    const filter = {
      ...this.state.filter,
      [key]: value,
    };

    this.setState({ filter });
  };

  coerceYesNo(value: 'yes' | 'no' | 'all' | string) {
    if (value === 'yes') {
      return true;
    } else if (value === 'no') {
      return false;
    }

    return value;
  }

  boolToYesNo(value: string) {
    const boolStr = value.toString().toLowerCase();
    if (boolStr === 'true') {
      return 'yes';
    } else if (boolStr === 'false') {
      return 'no';
    }
    return 'all';
  }

  onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.onSubmit();
  };

  render() {
    const filter = this.state.filter;

    return (
      <FormContainer onSubmit={this.onFormSubmit}>
        <FormColumn>
          <h3> Document Identification </h3>
          <ControlGroup>
            <label>Document Set Name</label>
            <Input
              placeholder="Enter Document Set Name"
              defaultValue={filter.documentSetName || undefined}
              onChange={e =>
                this.setFilterValue('documentSetName', e.target.value)
              }
            />
          </ControlGroup>
          <ControlGroup>
            <label>Aggregate Id</label>
            <Input
              placeholder="Enter id"
              defaultValue={filter.aggregateId || undefined}
              onChange={e => this.setFilterValue('aggregateId', e.target.value)}
            />
          </ControlGroup>
          <ControlGroup>
            <label>With Status</label>
            <Select
              mode="multiple"
              placeholder="Choose 1 or more document statuses"
              defaultValue={this.props.filter.positiveStatuses || undefined}
              onChange={(value: SelectValue) => {
                this.setFilterValue(
                  'positiveStatuses',
                  statusToStatusFilter(value) || null,
                );
              }}
            >
              {statusOptionsDisplay(this.props.availableStatuses)}
            </Select>
          </ControlGroup>
          <ControlGroup>
            <label>Without Status</label>
            <Select
              mode="multiple"
              placeholder="Choose 1 or more document statuses"
              defaultValue={this.props.filter.negativeStatuses || undefined}
              onChange={(value: SelectValue) => {
                this.setFilterValue(
                  'negativeStatuses',
                  statusToStatusFilter(value) || null,
                );
              }}
            >
              {statusOptionsDisplay(this.props.availableStatuses)}
            </Select>
          </ControlGroup>
        </FormColumn>
        <FormColumn>
          <h3> Document Properties </h3>
          <ControlGroup>
            <label>State</label>
            <StateSelector
              onChange={val => this.setFilterValue('state', val)}
              selectedState={filter.state}
            />
          </ControlGroup>

          <ControlGroup>
            <label>County</label>
            <CountySelector
              onChange={val => this.setFilterValue('county', val)}
              usState={filter.state}
              selectedCounty={filter.county}
            />
          </ControlGroup>

          <ControlGroup>
            <label>Instrument Type</label>
            <Input
              placeholder="e.g. Assignment"
              defaultValue={filter.instrumentType || undefined}
              onChange={e =>
                this.setFilterValue('instrumentType', e.target.value)
              }
            />
          </ControlGroup>
        </FormColumn>
        <FormColumn>
          <h3> Annotation Properties </h3>
          <ControlGroup>
            <label>Annotated?</label>
            <YesNoSelector
              defaultValue={this.boolToYesNo(this.props.filter.isAnnotated)}
              showAll={true}
              onChange={value =>
                this.setFilterValue('isAnnotated', this.coerceYesNo(value))
              }
            />
          </ControlGroup>

          <ControlGroup>
            <label>Reviewed?</label>
            <YesNoSelector
              defaultValue={this.boolToYesNo(this.props.filter.reviewCompleted)}
              showAll={true}
              onChange={value =>
                this.setFilterValue('reviewCompleted', this.coerceYesNo(value))
              }
            />
          </ControlGroup>

          <ControlGroup>
            <label>Last Annotated By</label>
            <Input
              placeholder="Enter username"
              defaultValue={filter.lastAnnotatorName || undefined}
              onChange={e =>
                this.setFilterValue('lastAnnotatorName', e.target.value)
              }
            />
          </ControlGroup>

          <ControlGroup>
            <label>Last Reviewed By</label>
            <Input
              placeholder="Enter username"
              defaultValue={filter.lastReviewerName || undefined}
              onChange={e =>
                this.setFilterValue('lastReviewerName', e.target.value)
              }
            />
          </ControlGroup>
        </FormColumn>
        <FormColumn>
          <h3> Document and Annotation Dates </h3>
          {dateFormElement(
            'recordedDate',
            'Recorded Date',
            this.setFilterValue,
            this.props.filter.recordedDate,
          )}
          {dateFormElement(
            'createdAt',
            'Created Date',
            this.setFilterValue,
            this.props.filter.createdAt,
          )}
          {dateFormElement(
            'lastAnnotationTimestamp',
            'Last Annotation Date',
            this.setFilterValue,
            this.props.filter.lastAnnotationTimestamp,
          )}
          {dateFormElement(
            'lastReviewTimestamp',
            'Last Review Date',
            this.setFilterValue,
            this.props.filter.lastReviewTimestamp,
          )}
        </FormColumn>

        <ButtonColumn>
          <ControlGroup>
            <Button
              style={{ width: '80px', height: '80px' }}
              type="primary"
              icon={<SearchOutlined />}
              shape="circle"
              onClick={this.onSubmit}
            />
            <input type="submit" style={{ display: 'none' }} />
          </ControlGroup>
        </ButtonColumn>
      </FormContainer>
    );
  }
}

function statusOptionsDisplay(options: string[]) {
  const nodes = options.map(n => {
    return (
      <Select.Option key={n} value={n}>
        {n}
      </Select.Option>
    );
  });

  return nodes;
}

function statusToStatusFilter(options: SelectValue) {
  if (isLabeledValue(options)) {
    return options.key;
  } else if (isLabeledValueArray(options)) {
    return options.join(',');
  } else if (isArray(options)) {
    return options.join(',');
  } else {
    return options;
  }
}

function isLabeledValue(options: any): options is LabeledValue {
  try {
    return 'key' in options;
  } catch {
    return false;
  }
}

function isLabeledValueArray(thing: any): thing is LabeledValue[] {
  if (isArray(thing)) {
    return thing.every(isLabeledValue);
  } else {
    return false;
  }
}

function isArray<T>(thing: any): thing is T[] {
  return Array.isArray(thing);
}

function toMoments(values: string[]): [Moment, Moment] | undefined {
  const dateFormat = 'YYYY-MM-DD';
  const startMoment = values[0] ? moment(values[0], dateFormat) : undefined;
  const endMoment = values[1] ? moment(values[1], dateFormat) : undefined;
  const moments: [Moment, Moment] | undefined =
    startMoment && endMoment ? [startMoment, endMoment] : undefined;
  return moments;
}

function dateFormElement(
  filter: string,
  label: string,
  callback: (label: string, value: string[] | null) => void,
  defaultValue: string[] | null,
) {
  const moments = toMoments(defaultValue || []);
  return (
    <ControlGroup>
      <label className="force-clear">{label}</label>
      <DatePicker.RangePicker
        defaultValue={moments}
        onChange={dates => {
          if (!dates || dates.length <= 0) {
            callback(filter, null);
          } else {
            callback(
              filter,
              map(dates, d => (d ? d.format('YYYY-MM-DD') : '')),
            );
          }
        }}
      />
    </ControlGroup>
  );
}
