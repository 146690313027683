import { connect } from 'react-redux';
import { setActiveAnnotationSchema } from '../../annotations/actions/annotation-schemas.actions';
import { clearAnnotationSelection } from '../../annotations/actions/ui-annotations.actions';
import { AnnotationTypeEditor } from '../../annotations/components/annotation-type-editor.component';
import { AnnotationSchema } from '../../annotations/models/annotation-schema.model';
import {
  selectActiveAnnotationSchema,
  selectUserSelectableSchemasArray,
} from '../../annotations/selectors/annotation-schema.selectors';
import { AppState } from '../../app-state';

export default connect(
  (state: AppState) => ({
    schema: selectActiveAnnotationSchema(state),
    allSchemas: selectUserSelectableSchemasArray(state),
  }),
  dispatch => ({
    onSelectSchema: (schema: AnnotationSchema) =>
      dispatch(setActiveAnnotationSchema(schema)),
    onClose: () => dispatch(clearAnnotationSelection()),
  }),
  null,
  { forwardRef: true },
)(AnnotationTypeEditor);
