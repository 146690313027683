import { Dictionary, keyBy } from 'lodash';
import { getType } from 'typesafe-actions';
import {
  replaceAnnotationSchemas,
  setActiveAnnotationSchema,
  setEditingAnnotationSchema,
} from '../actions/annotation-schemas.actions';
import { AnnotationSchemaActions } from '../actions/annotation-schemas.types';
import { AnnotationRelationRule } from '../models/annotation-relation-rule.model';
import { AnnotationSchema } from '../models/annotation-schema.model';
import { AnnotationTypesById } from '../models/annotation-type.model';

export type AnnotationSchemasState = {
  entities: Dictionary<AnnotationSchema>;
  annotationTypes: AnnotationTypesById;
  relationRules: Dictionary<AnnotationRelationRule>;
  activeSchema: AnnotationSchema | null;
  editingSchema: AnnotationSchema | null;
};

export const defaultSchemasState = {
  entities: {},
  annotationTypes: {},
  relationRules: {},
  activeSchema: null,
  editingSchema: null,
};

export function annotationSchemasReducer(
  state: AnnotationSchemasState = defaultSchemasState,
  action: AnnotationSchemaActions,
): AnnotationSchemasState {
  switch (action.type) {
    case getType(replaceAnnotationSchemas): {
      const { schemas } = action.payload;
      const entities = keyBy(
        schemas,
        (s: AnnotationSchema) => s.annotationSchemaId,
      );

      const annotationTypes = keyBy(
        AnnotationSchema.extractAnnotationTypes(schemas),
        'annotationTypeId',
      );

      const relationRules = keyBy(
        AnnotationSchema.extractRelationRules(schemas),
        'annotationRelationRuleId',
      );

      const activeSchemaId =
        state.activeSchema && state.activeSchema.annotationSchemaId;
      const activeSchema =
        activeSchemaId && entities[activeSchemaId]
          ? entities[activeSchemaId]
          : schemas.find(AnnotationSchema.isUserSelectable) || null;

      const editingSchemaId = state.editingSchema?.annotationSchemaId;
      const editingSchema = editingSchemaId ? entities[editingSchemaId] : null;

      return {
        ...state,
        entities,
        annotationTypes,
        relationRules,
        activeSchema,
        editingSchema,
      };
    }

    case getType(setActiveAnnotationSchema):
      return {
        ...state,
        activeSchema: action.payload.schema,
      };

    case getType(setEditingAnnotationSchema):
      return {
        ...state,
        editingSchema: action.payload.schema,
      };

    default:
      return state;
  }
}
