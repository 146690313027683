import { omitBy, reduce } from 'lodash';
import { KeyValueMap } from '../types/KeyValueMap.type';
import { QuerySort } from '../types/QuerySort';

export function queryString(params: KeyValueMap<any>): string {
  const cleanedParams = cleanParams(params);
  return reduce(
    cleanedParams,
    (paramStrs: string[], value, key) => {
      return [
        ...paramStrs,
        `${key.toString().trim()}=${value.toString().trim()}`,
      ];
    },
    [],
  ).join('&');
}

function sortParam(sortBy: QuerySort) {
  return {
    sortBy: `${sortBy.field.toString().trim()}.${
      sortBy.order === 'ascend' ? 'asc' : 'desc'
    }`,
  };
}

/**
 * Takes a base url and query parameters to build a full query string.
 * @param baseUrl string
 * @param params KeyValueMap<any>
 * @param sort QuerySort | null
 */
export function withQueryString(
  baseUrl: string,
  params: KeyValueMap<any>,
  sort?: QuerySort | null,
): string {
  const allParams = sort ? { ...params, ...sortParam(sort) } : params;
  const queryStr = queryString(allParams);

  return encodeURI(queryStr.length ? `${baseUrl}?${queryStr}` : baseUrl);
}

/**
 * Cleans params list of falsy values and empty strings.
 * Also removes params with value "all", since they are not considered
 * to be wildcards that do not filter the result set.
 * @param params Object of key-value pairs to search on.
 */
function cleanParams(params: KeyValueMap<any>) {
  return omitBy(
    params,
    val =>
      (!val && typeof val !== 'boolean') ||
      !val.toString().trim() ||
      val.toString().toLowerCase() === 'all',
  );
}
