import { Tooltip } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { Flag } from '../../flags';
import { PredictionSelectorPopover } from '../../predictions/components/prediction-selector-popover.component';
import { QueueControl } from '../../queues/containers/queue-control.container';
import { UndoRedoButtons } from '../../undo-redo/containers/undo-redo-buttons.container';
import { CustomAnnotationButton } from '../containers/custom-annotation-button.container';
import { DocumentStatusesContainer } from '../containers/document-statuses.container';
import { AnnotationsLastSavedText } from '../containers/last-saved.container';
import { SaveOrEditButton } from '../containers/save-or-edit-button.container';
import PageViewsSyncButton from '../../documents/containers/page-views-sync-button.container';

const ToolbarWrapper = styled.div`
  display: flex;

  min-height: 50px;
  background: white;
  border: solid 1px silver;
`;

const Left = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
`;

const Center = styled.div`
  display: flex;
  width: 50%;
  margin-right: 15px;
  align-items: center;
  justify-content: center;
  text-align: left;
`;

const Right = styled.div`
  display: flex;
  width: 50%;
  margin-right: 15px;
  align-items: center;
  justify-content: flex-end;
  text-align: left;
`;

const AnnotationButtonWrap = styled.span`
  display: flex;
  padding-right: 15px;
`;

const DocumentStatusWrap = styled.span`
  display: flex;
  padding-left: 15px;
  width: 50%;
`;

const SaveButtonAndTextWrap = styled.span`
  display: flex;
  margin-right: 15px;
`;

const QueueControlWrap = styled.span`
  margin-left: 10px;
  display: flex;
  height: 30px;
`;

const UndoRedoButtonWrap = styled.span`
  display: flex;
  margin-right: 8px;
`;

const PredictionSelectorPopoverWrap = styled.span`
  display: flex;
`;

type AnnotationToolbarProps = {
  predictEnabled: boolean;
  documentInEditMode: boolean;
};

export const AnnotationToolbarComponent = (props: AnnotationToolbarProps) => (
  <ToolbarWrapper>
    <Left>
      <QueueControlWrap>
        <Flag name={['features', 'queues']}>
          <QueueControl />
        </Flag>
      </QueueControlWrap>
      <DocumentStatusWrap>
        <DocumentStatusesContainer />
      </DocumentStatusWrap>
    </Left>
    <Center>
      <PageViewsSyncButton />
    </Center>
    <Right>
      <SaveButtonAndTextWrap>
        <AnnotationsLastSavedText />
      </SaveButtonAndTextWrap>
      <SaveButtonAndTextWrap>
        <SaveOrEditButton />
      </SaveButtonAndTextWrap>
      {props.documentInEditMode && (
        <>
          <AnnotationButtonWrap>
            <Tooltip
              title={
                <span>
                  Manually add an annotation that <br />
                  is not in the text, but is in the image.
                </span>
              }
            >
              <CustomAnnotationButton />
            </Tooltip>
          </AnnotationButtonWrap>
          <UndoRedoButtonWrap>
            <UndoRedoButtons />
          </UndoRedoButtonWrap>
          <PredictionSelectorPopoverWrap>
            <PredictionSelectorPopover disabled={!props.predictEnabled} />
          </PredictionSelectorPopoverWrap>
        </>
      )}
    </Right>
  </ToolbarWrapper>
);
