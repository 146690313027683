import { createSelector } from 'reselect';
import { AppState } from '../../app-state';
import { tokenValid } from '../util/token.util';

export const selectJwt = (state: AppState): string | null => state.auth.jwt;

export const selectAuthError = (state: AppState) => state.auth.authError;

export const selectUserIsLoggedIn = createSelector([selectJwt], jwt =>
  tokenValid(jwt),
);
