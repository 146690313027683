import { getType } from 'typesafe-actions';
import { AppState } from '../../app-state';
import {
  loadDocumentSummaryEnd,
  loadDocumentSummaryStart,
} from '../actions/document-summary-load-usage.actions';
import { DocumentSummaryLoadUsageActions } from '../actions/document-summary-load-usage.types';

export type UsageDocumentSummaryLoadState = {
  start: Date | null;
  end: Date | null;
};

const defaultState = {
  start: null,
  end: null,
};

export function usageDocumentSummaryLoadReducer(
  state: UsageDocumentSummaryLoadState = defaultState,
  action: DocumentSummaryLoadUsageActions,
  appState: AppState,
): UsageDocumentSummaryLoadState {
  switch (action.type) {
    case getType(loadDocumentSummaryStart):
      return { ...state, start: new Date() };

    case getType(loadDocumentSummaryEnd):
      return { ...state, end: new Date() };

    default:
      return state;
  }
}
