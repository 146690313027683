import { connect } from 'react-redux';
import { AppState } from '../../app-state';
import { ComponentSize } from '../../common/types/ComponentSize';
import { selectSourceDocumentIsEditable } from '../../documents/selectors/source-document.selectors';
import { setAnnotationTableColumnVisibility } from '../../ui-settings/actions/annotation-table-settings.actions';
import {
  createAnnotationRelation,
  deleteAnnotationChildRelations,
} from '../actions/annotation-relations.actions';
import {
  setDragTargetId,
  setSelectedAnnotations,
  triggerDeleteAnnotations,
  updateAnnotationTypes,
  updateAnnotationValues,
} from '../actions/annotations.actions';
import {
  AnnotationTableComponent,
  AnnotationTableFunctions,
  AnnotationTableParams,
} from '../components/annotation-table.component';
import { selectAnnotationTableRows } from '../selectors/annotation-relation.selectors';
import { selectActiveAnnotationSchema } from '../selectors/annotation-schema.selectors';
import { selectAnnotationsAreLoading } from '../selectors/annotation.selectors';
import { selectAnnotationTableColumnSettings } from '../selectors/ui.selectors';
import { selectUsername } from '../../accounts/selectors/users.selectors';
import { AnnotationSchema } from '../models/annotation-schema.model';
import { RelationType } from '../../lib/relations/relation-types';
import { Map } from 'immutable';
import { setFocusedAnnotationId } from '../actions/ui-annotations.actions';

export const AnnotationTable = connect(
  (
    state: AppState,
    ownProps: { size?: ComponentSize },
  ): AnnotationTableParams => ({
    rows: selectAnnotationTableRows(state),
    columns: selectAnnotationTableColumnSettings(state),
    loading: selectAnnotationsAreLoading(state),
    editable: selectSourceDocumentIsEditable(state),
    size: ownProps.size,
    lastManualAnnotationId: state.annotations.lastManualAnnotationId,
    activeUser: selectUsername(state),
    activeSchema:
      selectActiveAnnotationSchema(state) || AnnotationSchema.unknownSchema(),
    dragTargetState: state.annotations.dragTargetState,
    focusedAnnotationId: state._UI_annotations.focusedAnnotationId,
  }),
  (dispatch): AnnotationTableFunctions => ({
    onRemoveAnnotation: (id: string) =>
      dispatch(triggerDeleteAnnotations([id])),
    onCreateRelation: (
      left: string,
      right: string,
      relationType: RelationType,
    ) => {
      if (left === right) {
        dispatch(deleteAnnotationChildRelations(left));
      }

      dispatch(createAnnotationRelation({ left, right, relationType }));
    },
    onHideColumn: (colId: string, hide: boolean) => {
      dispatch(setAnnotationTableColumnVisibility(colId, hide));
    },
    onDeleteRelation: (annotationId: string) => {
      dispatch(deleteAnnotationChildRelations(annotationId));
    },
    setSelectedAnnotations: (ids: string[]) =>
      dispatch(setSelectedAnnotations(ids)),
    setDragTargetId: (id: string) => dispatch(setDragTargetId(id)),
    updateAnnotationValues: (m: Map<string, string>) =>
      dispatch(updateAnnotationValues(m)),
    updateAnnotationTypes: (m: Map<string, string>) =>
      dispatch(updateAnnotationTypes(m)),
    clearFocusedAnnotationId: () => dispatch(setFocusedAnnotationId(null)),
  }),
)(AnnotationTableComponent);
