import { SaveOutlined } from '@ant-design/icons';
import Tooltip from 'antd/lib/tooltip';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Permission } from '../../accounts/models/permission.model';
import { selectActiveUserAccount } from '../../accounts/selectors/users.selectors';
import { AppState } from '../../app-state';
import { ButtonSpinner } from '../../common/components/button-spinner.component';
import { SecondaryButton } from '../../common/components/secondary-button.component';
import { selectSourceDocumentIsEditable } from '../../documents/selectors/source-document.selectors';
import { saveButtonClicked } from '../actions/ui-annotations.actions';
import { selectAnnotationRelationErrors } from '../selectors/annotation-relation.selectors';
import { selectAnnotationReviewIsComplete } from '../selectors/annotation-review.selectors';
import {
  selectAnnotationsAreLoading,
  selectInvalidAnnotations,
  selectIsSaving,
} from '../selectors/annotation.selectors';
import { selectAnnotationDocumentStatuses } from '../selectors/document-status.selectors';
import { find } from 'lodash';

const VALIDATION_OVERRIDE_STATUS = 'Override Invalid Annotations';
const selectReviewableAndReviewed = createSelector(
  [selectActiveUserAccount, selectAnnotationReviewIsComplete],
  (account, reviewComplete) => {
    const canReview =
      account && Permission.canReviewDocuments(account.permissions);
    return canReview && reviewComplete;
  },
);

const selectNumInvalidAnnotations = createSelector(
  [selectInvalidAnnotations, selectAnnotationDocumentStatuses],
  (invalidAnnotations, statuses) => {
    const hasOverride = !!find(
      statuses,
      s => s.status === VALIDATION_OVERRIDE_STATUS,
    );
    return hasOverride ? 0 : invalidAnnotations.length;
  },
);

const selectSaveButtonIsDisabled = createSelector(
  [
    selectSourceDocumentIsEditable,
    selectAnnotationRelationErrors,
    selectNumInvalidAnnotations,
    selectAnnotationsAreLoading,
    selectIsSaving,
  ],
  (editable, errors, numInvalidAnnotations, loading, saving) =>
    !editable ||
    errors.length > 0 ||
    numInvalidAnnotations > 0 ||
    loading ||
    saving,
);

const selectDisabledText = createSelector(
  [selectAnnotationRelationErrors, selectNumInvalidAnnotations],
  (errors, numInvalidAnnotations) => {
    if (errors.length > 0) {
      return 'Please fix annotation errors before saving.';
    } else if (numInvalidAnnotations > 0) {
      const annotationText =
        numInvalidAnnotations > 1 ? 'annotations' : 'annotation';
      return `Please fix the ${numInvalidAnnotations} invalid ${annotationText} or add the "${VALIDATION_OVERRIDE_STATUS}" status.`;
    } else {
      return 'Document is loading, please wait.';
    }
  },
);

export const SaveAnnotationsButton = connect(
  (state: AppState) => ({
    isSaving: selectIsSaving(state),
    disabled: selectSaveButtonIsDisabled(state),
    disabledText: selectDisabledText(state),
    andReview: selectReviewableAndReviewed(state),
  }),
  dispatch => ({
    onClick: () => dispatch(saveButtonClicked()),
  }),
)((props: any) => {
  const andReview = props.andReview;
  return (
    <Tooltip title={props.disabled ? props.disabledText : ''}>
      <SecondaryButton
        onClick={() => props.onClick()}
        disabled={props.disabled}
        style={{ lineHeight: '1.4' }}
      >
        <span>
          {props.isSaving ? (
            <ButtonSpinner />
          ) : (
            <SaveOutlined style={{ marginRight: '8px' }} />
          )}
          Save {andReview && <span>and Review</span>}
        </span>
      </SecondaryButton>
    </Tooltip>
  );
});
