import { connect } from 'react-redux';
import { selectCurrentUserGroupId } from '../../accounts/selectors/groups.selectors';
import { AppState } from '../../app-state';
import { createSchema } from '../../annotations/actions/annotation-schemas.actions';
import {
  SchemaCreatorComponent,
  SchemaCreatorFormValues,
  SchemaCreatorProps,
} from '../components/schema-creator.component';

export const SchemaCreator = connect(
  (state: AppState) => ({
    currentUserGroupId: selectCurrentUserGroupId(state),
  }),
  (dispatch): Pick<SchemaCreatorProps, 'onSchemaCreate'> => ({
    onSchemaCreate: (v: SchemaCreatorFormValues) =>
      dispatch(
        createSchema(
          v.title,
          v.groupId,
          v.documentSetMatcher,
          v.documentTypeMatcher,
        ),
      ),
  }),
)(SchemaCreatorComponent);
