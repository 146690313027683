import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { AppState } from '../../app-state';
import { DateComponent } from '../../common/components/date.component';
import { selectLastSaved } from '../selectors/annotation.selectors';

const TextLine1 = styled.div`
  height: 50%;
  line-height: 1;
  padding: 8px 0 2px;
`;

const TextLine2 = styled.div`
  height: 50%;
  line-height: 1;
  padding-top: 2px;
`;

export const AnnotationsLastSavedText = connect((state: AppState) => ({
  time: selectLastSaved(state),
}))((props: { time: number | null }) => (
  <div>
    {props.time && <TextLine1>Last saved:</TextLine1>}
    {props.time && (
      <TextLine2>
        <DateComponent
          serializedDate={props.time}
          format={'yyyy-mm-dd, h:MM TT'}
        />
      </TextLine2>
    )}
  </div>
));
