import { createAction } from 'typesafe-actions';

enum Types {
  APPLICATION_EVENT_LOGGING_SUCCESSFUL = 'APPLICATION_EVENT_LOGGING_SUCCESSFUL',
}

export const appEventLoggingSuccess = createAction(
  Types.APPLICATION_EVENT_LOGGING_SUCCESSFUL,
  resolvePayload => (eventType: string) => resolvePayload({ eventType }),
);
