import { StateObservable } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AppState } from '../../app-state';
import { displaySuccessNotification } from '../../common/actions/notification.actions';
import { AppEpic } from '../../common/types/app-epic.type';
import { handleEpicError } from '../../common/utils/epics';
import { AppActions } from '../../root.actions';
import {
  createQueue,
  createQueueSuccess,
  fetchQueues,
} from '../actions/queue.actions';
import { QueuesResource } from '../resources/queues.resource';

export const createQueueEpic: AppEpic = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(createQueue)),
    switchMap(requestQueueCreation(state$)),
  );
};

const requestQueueCreation = (state$: StateObservable<AppState>) => (
  action: ReturnType<typeof createQueue>,
): Observable<AppActions> => {
  const {
    name: queueName,
    adminIds: adminGroups,
    workerIds: workerGroups,
  } = action.payload;
  return QueuesResource.create(state$.value, {
    queueName,
    adminGroups,
    workerGroups,
  }).pipe(
    switchMap(toCreationSuccessActions),
    catchError(handleEpicError('Error creating work queue')),
  );
};

const toCreationSuccessActions = () =>
  from([
    createQueueSuccess(),
    displaySuccessNotification('Queue created successfully'),
    fetchQueues(),
  ]);
