import { ActionsObservable, StateObservable } from 'redux-observable';
import { empty, of } from 'rxjs';
import { catchError, concat, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AppState } from '../../app-state';
import { displaySuccessNotification } from '../../common/actions/notification.actions';
import { AppEpic } from '../../common/types/app-epic.type';
import { handleEpicError } from '../../common/utils/epics';
import { AppActions } from '../../root.actions';
import {
  createDocumentSetAsync,
  deleteEmptyDocumentSetAsync,
  fetchDocumentSetList,
  fetchDocumentSetUploadPolicyAsync,
  populateDocumentSetList,
} from '../actions/document-sets.actions';
import { DocumentSetResource } from '../resources/document-set.resource';

export function fetchDocumentSetsEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(fetchDocumentSetList)),
    switchMap(() =>
      DocumentSetResource.getAll(state$.value).pipe(
        map(resp => populateDocumentSetList(resp.documentSets)),
        catchError(handleEpicError('Error fetching document set list')),
      ),
    ),
  );
}

export const createDocumentSetEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(createDocumentSetAsync.request)),
    switchMap(action =>
      DocumentSetResource.create(state$.value, action.payload).pipe(
        map(createDocumentSetAsync.success),
        handleSuccessfulDocumentSetChange('Document Set created successfully.'),
        catchError(e => {
          const defaultErrorMessage = 'Server Error';
          const errorMessage = e.response
            ? e.response.error || defaultErrorMessage
            : defaultErrorMessage;
          return handleEpicError(
            `Error creating Document Set: ${errorMessage}.`,
            createDocumentSetAsync.failure,
          )(e);
        }),
      ),
    ),
  );

export const fetchDocumentSetUploadPolicyEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(fetchDocumentSetUploadPolicyAsync.request)),
    switchMap(({ payload: { documentSetId } }) =>
      DocumentSetResource.getUploadPolicy(state$.value, documentSetId).pipe(
        map(uploadPolicy =>
          fetchDocumentSetUploadPolicyAsync.success({
            uploadPolicy,
            documentSetId,
          }),
        ),
        // not showing the user errors here because they will be common when a
        // user is allowed to view a document set but not to upload to it.
        catchError(e => {
          console.error(e);
          return empty();
        }),
      ),
    ),
  );

export const deleteEmptyDocumentSetEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(deleteEmptyDocumentSetAsync.request)),
    switchMap(({ payload: { documentSetId } }) =>
      DocumentSetResource.deleteEmptyDocset(state$.value)(documentSetId).pipe(
        map(deleteEmptyDocumentSetAsync.success),
        handleSuccessfulDocumentSetChange('Document Set deleted successfully.'),
        catchError(
          handleEpicError(
            'Error deleting Document Set',
            deleteEmptyDocumentSetAsync.failure,
          ),
        ),
      ),
    ),
  );

function handleSuccessfulDocumentSetChange(message: string) {
  return concat(
    of(fetchDocumentSetList()),
    of(displaySuccessNotification(message)),
  );
}
