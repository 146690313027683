import { createAction, createAsyncAction } from 'typesafe-actions';
import { QuerySort } from '../../common/types/QuerySort';
import { DocumentListFilter } from '../../documents/models/document-list-filter.model';
import DocumentSetUploadPolicy from '../models/document-set-upload-policy.model';
import { DocumentSet } from '../models/document-set.model';

enum Types {
  FETCH_DOCSET_LIST = 'FETCH_DOCSET_LIST: Fetches the list of unique document sets.',
  POPULATE_DOCSET_LIST = 'POPULATE_DOCSET_LIST: Populates the list of unique document sets.',
  CREATE_DOCUMENT_SET_REQUEST = 'CREATE_DOCUMENT_SET_REQUEST: [User] we want to create a doc set! oh boy! lets make the request.',
  CREATE_DOCUMENT_SET_SUCCESS = 'CREATE_DOCUMENT_SET_SUCCESS: We were able to create the doc set. Nice!',
  CREATE_DOCUMENT_SET_FAILURE = 'CREATE_DOCUMENT_SET_FAILURE: Oh no! The request to create a document set failed!',
  FETCH_DOCUMENT_SET_UPLOAD_POLICY_REQUEST = "FETCH_DOCUMENT_SET_UPLOAD_POLICY_REQUEST: [User] we're going to upload some documents. Grabbing a policy from the service to upload them directly to s3.",
  FETCH_DOCUMENT_SET_UPLOAD_POLICY_SUCCESS = 'FETCH_DOCUMENT_SET_UPLOAD_POLICY_SUCCESS: We got the s3 upload policy successfully. Ready to start uploading docs to s3.',
  FETCH_DOCUMENT_SET_UPLOAD_POLICY_FAILURE = "FETCH_DOCUMENT_SET_UPLOAD_POLICY_FAILURE: Getting the policy failed. Docs can't be uploaded until this is fixed!",
  DELETE_EMPTY_DOCUMENT_SET_REQUEST = "DELETE_EMPTY_DOCUMENT_SET_REQUEST: [User] maybe we didn't want to create that document set after all. Please remove it!",
  DELETE_EMPTY_DOCUMENT_SET_SUCCESS = 'DELETE_EMPTY_DOCUMENT_SET_SUCCESS: Document set deletion was successful',
  DELETE_EMPTY_DOCUMENT_SET_FAILURE = 'DELETE_EMPTY_DOCUMENT_SET_FAILURE: Document set deletion failed.',
  UPDATE_DOCUMENT_SET_TABLE_LIST = 'UPDATE_DOCUMENT_SET_TABLE_LIST: Updates the list of Documents in the Document Set table.',
  SET_DOCUMENT_SET_TABLE_FILTER = 'SET_DOCUMENT_SET_TABLE_FILTER: Sets the filter for the docs in the Document Set table.',
  SET_DOCUMENT_SET_TABLE_PAGE = 'SET_DOCUMENT_SET_TABLE_PAGE: Sets the page for the Document Set table.',
  SET_DOCUMENT_SET_TABLE_SORT = 'SET_DOCUMENT_SET_TABLE_SORT: Sets the sort for the Document Set table',
}

type FetchDocumentSetUploadPolicyAsyncResponsePayload = {
  uploadPolicy: DocumentSetUploadPolicy;
  documentSetId: number;
  error?: Error;
};

export const fetchDocumentSetList = createAction(Types.FETCH_DOCSET_LIST);

export const populateDocumentSetList = createAction(
  Types.POPULATE_DOCSET_LIST,
  resolvePayload => (documentSets: DocumentSet[]) =>
    resolvePayload({ documentSets }),
);

export const createDocumentSetAsync = createAsyncAction(
  Types.CREATE_DOCUMENT_SET_REQUEST,
  Types.CREATE_DOCUMENT_SET_SUCCESS,
  Types.CREATE_DOCUMENT_SET_FAILURE,
)<{ name: string; owningGroupId: number }, undefined, string>();

export const fetchDocumentSetUploadPolicyAsync = createAsyncAction(
  Types.FETCH_DOCUMENT_SET_UPLOAD_POLICY_REQUEST,
  Types.FETCH_DOCUMENT_SET_UPLOAD_POLICY_SUCCESS,
  Types.FETCH_DOCUMENT_SET_UPLOAD_POLICY_FAILURE,
)<
  { documentSetId: number },
  FetchDocumentSetUploadPolicyAsyncResponsePayload,
  string
>();

export const deleteEmptyDocumentSetAsync = createAsyncAction(
  Types.DELETE_EMPTY_DOCUMENT_SET_REQUEST,
  Types.DELETE_EMPTY_DOCUMENT_SET_SUCCESS,
  Types.DELETE_EMPTY_DOCUMENT_SET_FAILURE,
)<{ documentSetId: number }, undefined, string>();

export const setDocumentSetDocumentRequestParameters = createAction(
  'SET_DOCUMENT_SET_TABLE_FILTER: Sets the filter for the docs in the Document Set table.',
  resolvePayload => (
    filter: DocumentListFilter,
    page: number,
    sort: QuerySort | null = null,
  ) =>
    resolvePayload({
      documentRequestParameters: {
        filter,
        page,
        sort,
      },
    }),
);
