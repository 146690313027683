import { connect } from 'react-redux';
import { AppState } from '../../app-state';
import { CsvUpload } from '../components/csv-upload.component';
import { authHeaders } from '../utils/fetch';
import {
  PermissionActions,
  PermissionResource,
} from '../../accounts/models/permission.model';
import { selectIsPermitted } from '../../accounts/selectors/users.selectors';
import { displayErrorNotification } from '../actions/notification.actions';

export default connect(
  (state: AppState) => ({
    authHeaders: authHeaders(state),
    isPermitted: (resource: PermissionResource, resourceId: number) =>
      selectIsPermitted(PermissionActions.Upload, resource, resourceId)(state),
  }),
  {
    displayErrorNotification,
  },
)(CsvUpload);
