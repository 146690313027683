import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import { AppState } from '../../app-state';
import { CONTENT_TYPE_HEADERS, authHeaders } from '../../common/utils/fetch';
import { config } from '../../config/application.config';

export interface AnnotationDocumentStatuses {
  documentStatuses: AnnotationDocumentStatus[];
}

export interface AnnotationDocumentStatus {
  status: string;
  statusId: number;
}

export const AnnotationDocumentStatusResource = {
  baseUrl: config.annotationService.url,

  statusesUrl(annotatedDocumentId: number): string {
    return `${this.baseUrl}/document/${annotatedDocumentId}/statuses`;
  },

  get(
    state: AppState,
    annotatedDocumentId: number,
  ): Observable<AnnotationDocumentStatuses> {
    return ajax.getJSON<AnnotationDocumentStatuses>(
      this.statusesUrl(annotatedDocumentId),
      authHeaders(state),
    );
  },

  post(
    state: AppState,
    annotatedDocumentId: number,
    documentStatuses: AnnotationDocumentStatusBody,
  ): Observable<AnnotationDocumentStatuses> {
    return ajax
      .post(this.statusesUrl(annotatedDocumentId), documentStatuses, {
        ...authHeaders(state),
        ...CONTENT_TYPE_HEADERS.JSON,
      })
      .pipe(map(resp => resp.response));
  },
};

export type AnnotationDocumentStatusBody = {
  statuses: AnnotationDocumentStatus[];
};
