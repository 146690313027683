import { getType } from 'typesafe-actions';
import { toggleVisible } from '../actions/prediction-selector.actions';
import { PredictionSelectorActions } from '../actions/prediction-selector.types';
import { requestPredictions } from '../actions/predictions.actions';
import { PredictionsActions } from '../actions/predictions.types';

export type PredictionSelectorState = {
  visible: boolean;
};

const defaultPredictionSelectorState: PredictionSelectorState = {
  visible: false,
};

export function predictionSelectorReducer(
  state: PredictionSelectorState = defaultPredictionSelectorState,
  action: PredictionSelectorActions | PredictionsActions,
) {
  switch (action.type) {
    case getType(requestPredictions):
      return {
        visible: false,
      };

    case getType(toggleVisible):
      return {
        visible: !state.visible,
      };
    default:
      return state;
  }
}
