import { ActionsObservable, StateObservable } from 'redux-observable';
import {
  buffer,
  catchError,
  concatMap,
  debounceTime,
  filter,
  map as mapObs,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AppState } from '../../app-state';
import { AppActions } from '../../root.actions';
import {
  BatchAction,
  BatchableAction,
  batchUntilIdle,
} from '../actions/util.actions';
import { EMPTY } from 'rxjs';
import { flatMap } from 'lodash';
import { handleEpicError } from '../../common/utils/epics';
import { KeyValueMap } from '../types/KeyValueMap.type';

const MIN_IDLE_MS = 5000;

export function batchUntilIdleEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  const batchAction$ = action$.pipe(filter(isActionOf(batchUntilIdle)));

  return batchAction$.pipe(
    buffer(batchAction$.pipe(debounceTime(MIN_IDLE_MS))),
    concatMap(a => splitBatch(a)),
    mapObs(actions => {
      const actionCount = actions.length;
      if (actionCount === 0) return EMPTY;
      const type = actions[0].type;
      console.debug(`Running ${actionCount} batched actions: ${type} `);
      const payload = flatMap(actions, a => a.payload);
      return { type, payload };
    }),
    catchError(handleEpicError('Failed to run batched action.')),
  );
}

function splitBatch(actions: BatchAction[]): BatchableAction[][] {
  const batchableActions = actions.map(a => a.payload.action);
  const split = KeyValueMap.grouped(batchableActions, a => a.type).values();
  return Array.from(split);
}
