import {
  CheckCircleTwoTone,
  ClockCircleOutlined,
  CloseCircleTwoTone,
} from '@ant-design/icons';
import { Col, Row, Spin, Statistic } from 'antd';
import React from 'react';
import { OcrStatusSummary } from '../models/document-set-ocr-summary.model';

export type DocumentSetSummaryProps = {
  ocrStatusSummary: OcrStatusSummary | null;
  isOcrSummaryStatusLoading: boolean;
  totalDocuments: number | null;
};

export const DocumentSetSummary: React.FC<DocumentSetSummaryProps> = ({
  isOcrSummaryStatusLoading,
  ocrStatusSummary,
  totalDocuments,
}) => {
  const setStatus = (
    statusSummary: OcrStatusSummary | null,
    status: keyof OcrStatusSummary,
  ): number | null => (statusSummary ? statusSummary[status] : null);
  const completedDocuments = setStatus(ocrStatusSummary, 'completed');
  const pendingDocuments = setStatus(ocrStatusSummary, 'pending');
  const failedDocuments = setStatus(ocrStatusSummary, 'failed');

  return (
    <>
      <div style={{ width: '100%', textAlign: 'center', paddingBottom: '1em' }}>
        <Row gutter={24}>
          <Col span={6}>
            <Spin spinning={isOcrSummaryStatusLoading}>
              {totalDocuments !== null && (
                <Statistic title="Total Documents" value={totalDocuments} />
              )}
            </Spin>
          </Col>
          <Col span={6}>
            <Spin spinning={isOcrSummaryStatusLoading}>
              {completedDocuments !== null && (
                <Statistic
                  title={
                    <>
                      <CheckCircleTwoTone
                        style={{ paddingRight: 5 }}
                        twoToneColor="#52c41a"
                      />
                      Successful OCR
                    </>
                  }
                  value={completedDocuments}
                />
              )}
            </Spin>
          </Col>
          <Col span={6}>
            <Spin spinning={isOcrSummaryStatusLoading}>
              {pendingDocuments !== null && (
                <Statistic
                  title={
                    <>
                      <ClockCircleOutlined style={{ paddingRight: 5 }} />
                      Pending OCR
                    </>
                  }
                  value={pendingDocuments}
                />
              )}
            </Spin>
          </Col>
          <Col span={6}>
            <Spin spinning={isOcrSummaryStatusLoading}>
              {failedDocuments !== null && (
                <Statistic
                  title={
                    <>
                      <CloseCircleTwoTone
                        style={{ paddingRight: 5 }}
                        twoToneColor="#eb2f96"
                      />
                      Failed OCR
                    </>
                  }
                  value={failedDocuments}
                />
              )}
            </Spin>
          </Col>
        </Row>
      </div>
    </>
  );
};
