import { Color } from './color.model';
import { UserHighlight } from './user-highlight.model';

export class HighlightCollection implements UserHighlight {
  id: string | number;
  userHighlights: UserHighlight[];
  _start = 0;
  _end = 0;
  color: Color;

  constructor(highlights: UserHighlight[]) {
    this.userHighlights = highlights.sort(UserHighlight.compare);
    this.id = UserHighlight.aggregateId(highlights);

    if (this.userHighlights.length) {
      this._start = this.userHighlights[0].start;
      this._end = this.userHighlights[this.userHighlights.length - 1].end;
    }

    // TODO Blend
    this.color = new Color('#000000');
  }

  get start() {
    return this._start;
  }

  get end() {
    return this._end;
  }

  overlaps(rh: HighlightCollection): boolean {
    return (
      (rh.start < this._end && rh.end > this._start) ||
      (this._start < rh.end && this._end > rh.start)
    );
  }

  /**
   * Returns all overlapping highlights within the passed range.
   * This presently always loops through the entire collection, and is
   * inefficient without some sort of indexing.
   *
   * @param start
   * @param end
   */
  overlapsRange(start: number, end: number) {
    const comparable = { start, end, color: new Color('#000000') };
    return this.userHighlights.filter(p =>
      UserHighlight.overlapping(p, comparable),
    );
  }
}
