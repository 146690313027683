import { ApplicationConfig } from './application.config.type';

export const config: ApplicationConfig = {
  env: 'production',
  annotationService: {
    url: 'https://annotate.oseberg.io',
  },
  exportService: {
    url: 'https://ai-exports.oseberg.io',
  },
  auth: {
    url: 'https://auth.oseberg.io',
    appName: 'goat',
  },
  predictions: {
    retryIntervalMs: 1000,
    retryAttempts: 120,
  },
  annotations: {
    autoCollapseThreshold: 25,
  },
  undoRedo: {
    maxEntries: 10,
  },
  queues: {
    bulkMax: 50000,
  },
  flags: {
    features: {
      queues: true,
      manage: false,
      search: true,
      admin: true,
    },
    layout: {
      dragAndDrop: false,
    },
  },
  agGridKey:
    'CompanyName=Oseberg, Inc.,LicensedApplication=OseBerg Inc,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=1,AssetReference=AG-008800,ExpiryDate=23_August_2021_[v2]_MTYyOTY3MzIwMDAwMA==3368b17076ee2bdf85b07ce03c413e7a',
};
