import React from 'react';
import styled from 'styled-components';
import { Icons } from '../icons/icons';

const Icon = styled.span`
  height: 1em;
  display: inline-block;

  svg {
    height: 1em;
    width: 1em;
  }
`;

interface CustomIconProps {
  type: string;
  style?: React.CSSProperties;
  onClick?: (e: React.FormEvent<any>) => void;
}
export const CustomIcon: React.SFC<CustomIconProps> = props => (
  <Icon style={props.style} className="goat-icon" onClick={props.onClick}>
    {Icons[props.type]}
  </Icon>
);

export const CustomIcons = Object.keys(Icons);
