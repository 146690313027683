import React from 'react';
import styled from 'styled-components';
import { Card } from './card.component';
import { IconButton } from './icon-button.component';

type CardWithTitleProps = {
  title: string;
  style?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  onBackClicked?: () => void;
};

const Title = styled.h2`
  border-bottom: 1px solid #f3f3f3;
`;

const defaultStyle: React.CSSProperties = {
  padding: '12px',
  width: '95%',
  overflowY: 'auto',
};

const defaultContentStyle: React.CSSProperties = {
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
};

const CardWithTitle: React.SFC<CardWithTitleProps> = ({
  title,
  style = {},
  contentStyle = {},
  children,
  onBackClicked,
}) => {
  const backButton = !!onBackClicked ? (
    <IconButton
      style={{ marginRight: '8px' }}
      iconStyle={{ color: '#53adfe', fontSize: '18px' }}
      iconType="left"
      onClick={onBackClicked}
    />
  ) : null;
  return (
    <Card style={{ ...defaultStyle, ...style }}>
      <Title>
        {backButton}
        {title}
      </Title>
      <div style={{ ...defaultContentStyle, ...contentStyle }}>{children}</div>
    </Card>
  );
};

export default CardWithTitle;
