import { History } from 'history';
import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { AppRoutes } from './app-routes';
import { LoginModal } from './auth/containers/login-modal.container';
import { PrivateRoute } from './auth/containers/private-route.container';
import { NotFoundComponent } from './pages/404.page';
import { AdminPage } from './pages/admin.page';
import AnnotationPage from './pages/annotation.page';
import { LoginPage } from './pages/login.page';
import ManagePage from './pages/manage.page';
import { SearchPage } from './pages/search.page';

const AppWrapper = styled.div`
  height: 100%;
`;
interface AppProps {
  history: History<any>;
}
class App extends React.PureComponent<AppProps> {
  render() {
    return (
      <AppWrapper className="App">
        <Router history={this.props.history}>
          <Switch>
            <PrivateRoute
              exact={true}
              path={AppRoutes.Extract}
              component={AnnotationPage}
            />
            <PrivateRoute
              exact={true}
              path={AppRoutes.Home}
              component={SearchPage}
            />
            <PrivateRoute
              exact={true}
              path={AppRoutes.AnnotationPage}
              component={AnnotationPage}
            />
            <PrivateRoute
              exact={true}
              path={AppRoutes.Search}
              component={SearchPage}
            />
            <Route
              exact={true}
              path={`${AppRoutes.Login}*`}
              component={LoginPage}
            />
            <PrivateRoute
              exact={true}
              path={AppRoutes.Admin}
              component={AdminPage}
            />
            <PrivateRoute
              path={`${AppRoutes.Manage}/:resource?`}
              component={ManagePage}
            />
            <Route path="*" component={NotFoundComponent} />
          </Switch>
          <LoginModal />
        </Router>
      </AppWrapper>
    );
  }
}

export default App;
