import { filter, sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { AppState } from '../../app-state';
import { Annotation, AnnotationMetadata } from '../models/annotation.model';
import { Map } from 'immutable';
import { KeyValueMap } from '../../common/types/KeyValueMap.type';
import { AnnotationType } from '../models/annotation-type.model';
import { selectNearbyPagesInView } from '../../documents/selectors/page-controls.selectors';

export const selectAnnotationsAreLoading = (state: AppState): boolean =>
  state.annotations.loading;

export const selectAnnotationValues = (state: AppState): Map<string, string> =>
  state.annotations.values;

export const selectAnnotationTypes = (state: AppState): Map<string, string> =>
  state.annotations.types;

export const selectAnnotationValidations = (
  state: AppState,
): Map<string, boolean | undefined> => state.annotations.validations;

export const selectAnnotationMetadata = (
  state: AppState,
): Map<string, AnnotationMetadata> => state.annotations.metadata;

export const selectAnnotationIds = (state: AppState): string[] =>
  state.annotations.ids;

export const selectAnnotationsArray = createSelector(
  [
    selectAnnotationIds,
    selectAnnotationMetadata,
    selectAnnotationTypes,
    selectAnnotationValues,
    selectAnnotationValidations,
  ],
  (ids, metadata, types, values, validations) => {
    const annotations: Annotation[] = ids
      .map(i => {
        const m = metadata.get(i);
        const type = types.get(i);
        const value = values.get(i);
        const isValid = validations.get(i);
        if (!m || type === undefined || value === undefined) return null;
        return {
          ...m,
          value,
          type,
          isValid,
        };
      })
      .filter(a => !!a)
      .map(a => a!);
    const sorted = sortBy(annotations, a => a.annotatedDate);
    return sorted;
  },
);

export const selectAnnotationMetadataForHighlights = createSelector(
  [selectAnnotationMetadata, selectNearbyPagesInView],
  (metadata, pagesInView) =>
    metadata.filter(a => Annotation.shouldRenderHighlights(a, pagesInView)),
);

export const selectAnnotationsForHighlights = createSelector(
  [selectAnnotationMetadataForHighlights, selectAnnotationTypes],
  (metadata, types) => {
    const annotations = metadata.map(m => {
      const type = types.get(m.id) || AnnotationType.UnknownKey;
      return { ...m, type };
    });
    return annotations;
  },
);

export const selectAnnotations = createSelector(
  [selectAnnotationsArray],
  annotations => KeyValueMap.usingKey(annotations, 'id'),
);

export const selectIsSaving = (state: AppState) => state.annotations.isSaving;

export const selectLastSaved = (state: AppState) => state.annotations.lastSaved;

export const selectInvalidAnnotations = createSelector(
  [selectAnnotationsArray],
  annotations => filter(annotations, a => a.isValid === false),
);
