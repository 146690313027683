import { CloseOutlined } from '@ant-design/icons';
import { Input, Radio } from 'antd';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { AnnotationType } from '../../annotations/models/annotation-type.model';
import { ColorBox } from '../../common/components/color-box.component';
import { useAutoFocus } from '../../common/hooks/use-auto-focus.hook';
import { AnnotationSearchPopover } from '../containers/annotation-search-popover.container';

const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

const FieldWrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 15px;
`;

const AnnotationTypeWrap = styled.span`
  flex: 1;
  :hover {
    cursor: pointer;
  }
`;

export enum SearchMode {
  Contains = 'contains',
  Exists = 'exists',
  NotContains = 'does_not_contain',
  NotExists = 'does_not_exist',
}

export const SEARCH_MODES_WITH_INPUT: SearchMode[] = [
  SearchMode.Contains,
  SearchMode.NotContains,
];
export const NEGATED_SEARCH_MODES: SearchMode[] = [
  SearchMode.NotContains,
  SearchMode.NotExists,
];
export const REGULAR_SEARCH_MODES: SearchMode[] = [
  SearchMode.Contains,
  SearchMode.Exists,
];

type AnnotationSearchFieldUpdate = Partial<{
  value: string;
  searchMode: SearchMode;
  annotationType: AnnotationType;
}>;

export type AnnotationSearchFieldProps = {
  id: string;
  initialValue: string;
  searchMode: SearchMode;
  onChange: (u: AnnotationSearchFieldUpdate) => void;
  onRemove: (id: string) => void;
  annotationType: AnnotationType;
};

export const AnnotationSearchField: React.SFC<AnnotationSearchFieldProps> = props => {
  const defaultSearchMode: SearchMode = props.searchMode;

  // Using the autoFocus attribute doesn't work here because it focuses too
  // quickly on element creation, causing whatever hotkey that you pressed to
  // create the annotation search filed to be captured by the input. To
  // circumvent this, we need to introduce a delay.
  // Note that this effect only runs once due to specifying its dependency list
  // as only containing the inputElement ref (which by definition never changes for
  // the life of this component)
  const inputElement = useRef<Input>(null);
  useAutoFocus<Input>(inputElement, [inputElement]);

  const [searchMode, setSearchMode] = useState<SearchMode>(defaultSearchMode);
  const [text, setText] = useState(props.initialValue);
  const [annotationPopoverVisible, setAnnotationPopoverVisible] = useState(
    false,
  );

  const shouldShowInput = SEARCH_MODES_WITH_INPUT.some(
    mode => mode === searchMode,
  );

  return (
    <FieldWrap className="annotation-search-field">
      <AnnotationSearchPopover
        refocusWhenVisible={true}
        onAnnotationTypeSelected={at => {
          props.onChange({ annotationType: at });
          setAnnotationPopoverVisible(false);
        }}
        placement="right"
        visible={annotationPopoverVisible}
        onClose={() => setAnnotationPopoverVisible(false)}
      >
        <AnnotationTypeWrap
          onClick={_ => setAnnotationPopoverVisible(!annotationPopoverVisible)}
        >
          <ColorBox style={{ backgroundColor: props.annotationType.color }} />
          {props.annotationType.title}:
        </AnnotationTypeWrap>
      </AnnotationSearchPopover>

      <span
        style={{
          display: 'flex',
          flex: '1 1 auto',
          justifyContent: 'center',
        }}
      >
        <SearchModeSelector
          searchMode={searchMode}
          onSearchModeChange={mode => {
            setSearchMode(mode);
            props.onChange({ searchMode: mode });
          }}
        />
      </span>
      <Input
        style={{
          flex: '6 1',
        }}
        ref={inputElement}
        disabled={!shouldShowInput}
        value={text}
        onChange={({ target: { value } }) => {
          setText(value);
          props.onChange({ value });
        }}
      />
      <CloseOutlined
        style={{ marginLeft: '8px' }}
        onClick={_ => props.onRemove(props.id)}
      />
    </FieldWrap>
  );
};

type SearchModeSelectorProps = {
  searchMode: SearchMode;
  onSearchModeChange: (searchMode: SearchMode) => void;
};

function formatMode(s: string) {
  return s.split('_').join(' ');
}

const SearchModeSelector = (props: SearchModeSelectorProps) => (
  <RadioGroup
    style={{ display: 'flex', flexFlow: 'row nowrap' }}
    size="small"
    value={props.searchMode}
    onChange={({ target: { value } }) => {
      props.onSearchModeChange(value);
    }}
  >
    <div style={{ display: 'flex', flexFlow: 'column nowrap' }}>
      {REGULAR_SEARCH_MODES.map(key => (
        <RadioButton key={key} value={key}>
          {formatMode(key)}
        </RadioButton>
      ))}
    </div>
    <div style={{ display: 'flex', flexFlow: 'column nowrap' }}>
      {NEGATED_SEARCH_MODES.map(key => (
        <RadioButton key={key} value={key}>
          {formatMode(key)}
        </RadioButton>
      ))}
    </div>
  </RadioGroup>
);
