import { ActionType, createAction, getType } from 'typesafe-actions';

export type Callback = (v?: any) => void;
export type UploadConfirmState = {
  resolves: Callback[];
  rejects: Callback[];
};

export const UploadConfirmActions = {
  clear: createAction('CLEAR'),
  addResolve: createAction('ADD_RESOLVE', payload => (resolve: Callback) =>
    payload({ resolve }),
  ),
  addReject: createAction('ADD_REJECT', payload => (reject: Callback) =>
    payload({ reject }),
  ),
};

export function uploadConfirmReducer(
  state: UploadConfirmState,
  action: ActionType<typeof UploadConfirmActions>,
): UploadConfirmState {
  switch (action.type) {
    case getType(UploadConfirmActions.clear):
      return {
        resolves: [],
        rejects: [],
      };
    case getType(UploadConfirmActions.addResolve):
      return {
        ...state,
        resolves: [...state.resolves, action.payload.resolve],
      };
    case getType(UploadConfirmActions.addReject):
      return {
        ...state,
        rejects: [...state.rejects, action.payload.reject],
      };
    default:
      return state;
  }
}
