import React from 'react';
import { HotKeys } from 'react-hotkeys';
import { connect } from 'react-redux';
import { AppState } from '../../app-state';
import { redoAction, undoAction } from '../actions/undo-redo.actions';
import { ShortcutKeys } from '../shortcut-keys';

interface UndoRedoHotKeysProps {
  undo: () => void;
  redo: () => void;
}
class UndoRedoHotKeysComponent extends React.Component<UndoRedoHotKeysProps> {
  private handlers: {
    Undo: (e?: KeyboardEvent) => void;
    Redo: (e?: KeyboardEvent) => void;
  };

  constructor(props: UndoRedoHotKeysProps) {
    super(props);

    const preventDefault = (e?: KeyboardEvent) => {
      if (e) {
        e.preventDefault();
      }
    };
    this.handlers = {
      Undo: (e?: KeyboardEvent) => {
        preventDefault(e);
        props.undo();
      },
      Redo: (e?: KeyboardEvent) => {
        preventDefault(e);
        props.redo();
      },
    };
  }

  /* tslint:disable */
  render() {
    return (
      <HotKeys
        keyMap={ShortcutKeys}
        attach={window}
        handlers={this.handlers}
        focused
      >
        {this.props.children}
      </HotKeys>
    );
  }
  /* tslint:enable */
}

export const UndoRedoHotKeys = connect(
  (_: AppState) => ({}),
  dispatch => ({
    undo: () => {
      dispatch(undoAction());
    },
    redo: () => {
      dispatch(redoAction());
    },
  }),
)(UndoRedoHotKeysComponent);
