import { Dictionary } from 'lodash';
import { KeyValueMap } from './common/types/KeyValueMap.type';
import { withQueryString } from './common/utils/url';

export type AppRoute = string;
export const AppRoutes = {
  Home: '/',
  Extract: '/extract',
  AnnotationPage: '/document/:id/:action?',
  Login: '/login',
  NotFound: '404',
  Admin: '/admin',
  Search: '/search',
  Manage: '/management',
};

export function withUrlParams(route: string, params: KeyValueMap<string>) {
  return withQueryString(route, params);
}

export function routeActive(route: string): boolean {
  return window.location.pathname === route;
}

export function pathWithParams(route: string, params: Dictionary<any>): string {
  return Object.keys(params).reduce(
    (url, key) => url.replace(new RegExp(`:${key}[?]*`), params[key]),
    route,
  );
}

export type AnnotationPageRouteAction = 'edit' | 'view';
export type AnnotationPageRouteParams = {
  id: string;
  action: AnnotationPageRouteAction;
};

export const isExtractViewRoute = (route: string): boolean =>
  route.startsWith('/document') || route.startsWith('/extract');

export const isManageViewRoute = (route: string): boolean =>
  route.startsWith(AppRoutes.Manage);
