import { createAction } from 'typesafe-actions';
import { SortOrder } from '../../common/types/QuerySort';
import { AnnotatedDocument } from '../models/annotated-document.model';
import { DocumentListFilter } from '../models/document-list-filter.model';

enum Types {
  UPDATE_DOCUMENTS_LIST = 'UPDATE_DOCUMENTS_LIST',
  SET_DOCUMENT_LIST_FILTER = 'SET_DOCUMENT_LIST_FILTER',
  SET_DOCUMENT_LIST_PAGE = 'SET_DOCUMENT_LIST_PAGE',
  SET_DOCUMENT_LIST_SORT = 'SET_DOCUMENT_LIST_SORT',
  FETCH_DOCUMENTS_LIST = 'FETCH_DOCUMENTS_LIST',
  FETCH_DOCUMENTS_ERROR = 'FETCH_DOCUMENTS_ERROR',
}

export const fetchDocuments = createAction(
  Types.FETCH_DOCUMENTS_LIST,
  resolvePayload => (fetchingForDocumentSetManagement = false) =>
    resolvePayload({ fetchingForDocumentSetManagement }),
);

export const fetchDocumentsError = createAction(
  Types.FETCH_DOCUMENTS_ERROR,
  resolvePayload => (error: string) => resolvePayload({ error }),
);

export const updateDocuments = createAction(
  Types.UPDATE_DOCUMENTS_LIST,
  resolvePayload => (
    documents: AnnotatedDocument[],
    pageCount: number,
    totalItems: number,
  ) => resolvePayload({ documents, pageCount, totalItems }),
);

export const setDocumentListFilter = createAction(
  Types.SET_DOCUMENT_LIST_FILTER,
  resolvePayload => (filter: DocumentListFilter) => resolvePayload({ filter }),
);

export const setDocumentListPage = createAction(
  Types.SET_DOCUMENT_LIST_PAGE,
  resolvePayload => (page: number) => resolvePayload({ page }),
);

export const setDocumentListSort = createAction(
  Types.SET_DOCUMENT_LIST_SORT,
  resolvePayload => (field: string, order: SortOrder) =>
    resolvePayload({ field, order }),
);
