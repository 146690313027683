import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { CustomIcon, CustomIcons } from './custom-icon.component';

const IconButtonStyle = styled.span`
  .ant-btn {
    background: transparent;
    padding: 0;
    height: inherit;
    font-size: 15px;
    border: 0px;

    &:hover,
    &:disabled,
    &[disabled] {
      background: none;
    }
  }

  .goat-icon {
    height: 1em;
    fill: currentColor;
  }
`;

export interface IconButtonProps {
  iconType: string;
  href?: string;
  className?: string;
  disabled?: boolean;
  active?: boolean;
  style?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  onClick?: (e: React.FormEvent<any>) => void;
}
export class IconButton extends React.Component<IconButtonProps> {
  render() {
    const { href, className, iconType, active, iconStyle, style } = this.props;
    const disabled = this.props.disabled ? true : false;
    const isCustom = CustomIcons.indexOf(iconType) >= 0;

    return (
      <IconButtonStyle className={className} style={style}>
        <Button
          href={href}
          onClick={this.props.onClick}
          disabled={disabled}
          className={active ? 'active' : undefined}
        >
          {this.props.children && <span>{this.props.children}</span>}
          {isCustom ? (
            <CustomIcon style={iconStyle} type={iconType} />
          ) : (
            <LegacyIcon style={iconStyle} type={this.props.iconType} />
          )}
        </Button>
      </IconButtonStyle>
    );
  }
}
