import { PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Modal } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import React, { useState } from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '../../common/components/primary-button.component';
import { SecondaryButton } from '../../common/components/secondary-button.component';
import { validateForm } from '../../common/utils/validateForm';

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
`;

type AnnotationTypeCreatorRawFormValues = {
  category: string;
  title: string;
  key: string;
  color?: string;
  keyboardShortcut?: string;
};

export type AnnotationTypeCreatorFormValues = {
  category: string;
  title: string;
  key: string;
  color?: string;
  keyboardShortcut?: string;
};

export type AnnotationTypeCreatorProps = {
  disabled: boolean;
  onCreate: (v: AnnotationTypeCreatorFormValues) => unknown;
} & FormComponentProps;

// This is necessary to correct an error message from Ant's Form.create
const AntInput = React.forwardRef((props, _ref) => <Input {...props} />);

const { Item } = Form;
const AnnotationTypeCreatorForm: React.FC<AnnotationTypeCreatorProps> = ({
  form,
  disabled,
  onCreate,
}) => {
  const createAnnotationType = validateForm<AnnotationTypeCreatorRawFormValues>(
    form,
    v =>
      onCreate({
        category: v.category,
        title: v.title,
        key: v.key,
        color: v.color,
        keyboardShortcut: v.keyboardShortcut,
      }),
    true,
  );
  const [showModal, setModalView] = useState(false);
  const { getFieldDecorator } = form;

  return (
    <>
      <SecondaryButton onClick={() => setModalView(true)} disabled={disabled}>
        <PlusOutlined />
        Add AnnotationType
      </SecondaryButton>
      <Modal
        title="Create Annotation Type"
        visible={showModal}
        footer={null}
        onCancel={() => setModalView(false)}
        destroyOnClose={true}
      >
        <Form onSubmit={createAnnotationType}>
          <Item label="Category">
            {getFieldDecorator('category', {
              rules: [
                {
                  required: true,
                  message: 'Category is required.',
                },
              ],
            })(<AntInput />)}
          </Item>
          <Item label="Title">
            {getFieldDecorator('title', {
              rules: [
                {
                  required: true,
                  message: 'Title is required.',
                },
              ],
            })(<AntInput />)}
          </Item>
          <Item label="Key">
            {getFieldDecorator('key', {
              rules: [
                {
                  required: true,
                  message: 'Key is required.',
                },
              ],
            })(<AntInput />)}
          </Item>
          <Item label="Color">
            {getFieldDecorator('color', {
              rules: [
                {
                  required: false,
                  message: 'Color is required.',
                },
              ],
            })(<AntInput />)}
          </Item>
          <Item label="Keyboard Shortcut">
            {getFieldDecorator('keyboardShortcut', {
              rules: [{ required: false }],
            })(<AntInput />)}
          </Item>
          <Right>
            <PrimaryButton
              onClick={() => setModalView(false)}
              htmlType="submit"
            >
              Create Annotation Type
            </PrimaryButton>
          </Right>
        </Form>
      </Modal>
    </>
  );
};

export const AnnotationTypeCreatorComponent = Form.create<
  AnnotationTypeCreatorProps
>()(AnnotationTypeCreatorForm);
