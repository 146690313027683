import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Tree } from 'antd';
import { filter, flatMap, isString, map, xor } from 'lodash';
import { reject, startCase } from 'lodash/fp';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { AppState } from '../../app-state';
import {
  setActiveLabelGroup,
  setLabelsToPredictOn,
} from '../actions/predictions.actions';
import {
  LabelTreeData,
  LabelTreeDatum,
} from '../models/prediction-label-tree.model';
import { PredictionLabels } from '../models/predictions.model';
import { selectCurrentLabels } from '../selectors/predictions.selectors';
import {
  getTreeNodeAndChildren,
  isTreeNodeGroup,
  predictionLabelConfigToLabelTree,
} from '../util/predictions.util';

const TreeNode = Tree.TreeNode;

const DropdownTrigger = styled.a`
  padding: 0px 5px 8px;
  border-bottom: 1px solid #ccc;
  display: block;
  color: inherit;
`;

const NotSelectableStyle: React.CSSProperties = {
  userSelect: 'none',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  msUserSelect: 'none',
};

type LabelGroupSelectorProps = {
  currentlySelectedLabelGroup: string;
  labelGroups: string[];
  labelGroupChanged: (labelGroupName: string) => void;
};
const LabelGroupSelector = (props: LabelGroupSelectorProps) => (
  <Dropdown
    trigger={['click']}
    overlay={
      <Menu
        style={NotSelectableStyle}
        onClick={({ key }) => {
          if (isString(key)) {
            props.labelGroupChanged(key);
          }
        }}
      >
        {props.labelGroups.map(labelGroup => (
          <Menu.Item key={labelGroup}>
            {toPrettyLabelGroup(labelGroup)}
          </Menu.Item>
        ))}
      </Menu>
    }
  >
    <DropdownTrigger>
      {toPrettyLabelGroup(props.currentlySelectedLabelGroup)}
      <DownOutlined />
    </DropdownTrigger>
  </Dropdown>
);

function toPrettyLabelGroup(labelGroup: string): string {
  return `${startCase(labelGroup)} `;
}

type PredictionSelectorProps = {
  availableLabels: PredictionLabels;
  labelsToPredictOn: string[];
  predictionLabelsUpdated: (labels: string[]) => void;
  currentlySelectedLabelGroup: string;
  labelGroupChanged: (labelGroupName: string) => void;
};
class PredictionSelectorPopover extends React.PureComponent<
  PredictionSelectorProps
> {
  renderTreeNodes = (data: LabelTreeData) => {
    return data.map((item: LabelTreeDatum) => {
      return item.children ? (
        <TreeNode title={item.title} key={item.key}>
          {this.renderTreeNodes(item.children)}
        </TreeNode>
      ) : (
        <TreeNode {...item}>{}</TreeNode>
      );
    });
  };

  onTreeChange = (
    currentlyCheckedKeys:
      | (string | number)[]
      | { checked: (string | number)[]; halfChecked: (string | number)[] },
  ) => {
    const checkedKeys = filter(currentlyCheckedKeys, k => isString(k));
    const checkedLeafKeys = reject(isTreeNodeGroup)(checkedKeys);
    this.props.predictionLabelsUpdated(checkedLeafKeys);
  };

  onTreeSelect = (selectedKeys: (string | number)[]) => {
    const selectedStrKeys: string[] = map(
      filter(selectedKeys, k => isString(k)),
      s => s.toString(),
    );
    const labelGroupTree = predictionLabelConfigToLabelTree(
      this.props.availableLabels,
    );
    if (this.props.currentlySelectedLabelGroup in labelGroupTree) {
      const treeData = labelGroupTree[this.props.currentlySelectedLabelGroup];
      const selectedLabels = flatMap(treeData, (ltd: LabelTreeDatum) =>
        flatMap(selectedStrKeys, (k: string) => getTreeNodeAndChildren(ltd, k)),
      );
      this.onTreeChange(xor(selectedLabels, this.props.labelsToPredictOn));
    }
  };

  render() {
    const {
      availableLabels,
      currentlySelectedLabelGroup,
      labelGroupChanged,
    } = this.props;
    const labelGroupTree = predictionLabelConfigToLabelTree(availableLabels);
    const labelGroups = Object.keys(labelGroupTree);
    return (
      <div style={{ width: '300px' }}>
        <LabelGroupSelector
          labelGroupChanged={labelGroupChanged}
          currentlySelectedLabelGroup={
            (currentlySelectedLabelGroup in availableLabels &&
              currentlySelectedLabelGroup) ||
            'Please select a label group'
          }
          labelGroups={labelGroups}
        />
        {currentlySelectedLabelGroup in availableLabels && (
          <Tree
            checkedKeys={this.props.labelsToPredictOn}
            selectedKeys={[]}
            checkable={true}
            onCheck={this.onTreeChange}
            onSelect={this.onTreeSelect}
          >
            {this.renderTreeNodes(labelGroupTree[currentlySelectedLabelGroup])}
          </Tree>
        )}
      </div>
    );
  }
}

export const PredictionSelector = connect(
  (state: AppState) => ({
    labelsToPredictOn: selectCurrentLabels(state),
    availableLabels: state.predictions.availableLabels,
    currentlySelectedLabelGroup: state.predictions.currentlySelectedLabelGroup,
  }),
  dispatch => ({
    predictionLabelsUpdated: (labels: string[]) => {
      dispatch(setLabelsToPredictOn(labels));
    },
    labelGroupChanged: (labelGroupName: string) => {
      dispatch(setActiveLabelGroup(labelGroupName));
    },
  }),
)(PredictionSelectorPopover);
