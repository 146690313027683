import { ColDef } from 'ag-grid-community';
import { Dictionary } from 'lodash';
import { getType } from 'typesafe-actions';
import { PDF_SELECTION_MODE } from '../../documents/models/pdf-selection-modes.model';
import { setAnnotationTableColumnVisibility } from '../actions/annotation-table-settings.actions';
import { AnnotationTableSettingsActions } from '../actions/annotation-table-settings.types';
import { setPdfSelectionMode } from '../actions/pdf-viewer-settings.actions';
import { PDFViewerSettingsActions } from '../actions/pdf-viewer-settings.types';

type AnnotationTableSettings = {
  columns: Dictionary<ColDef>;
};

type PDFViewerSettings = {
  selectionMode: PDF_SELECTION_MODE;
};

export type UiSettingsState = {
  annotationTableSettings: AnnotationTableSettings;
  pdfViewerSettings: PDFViewerSettings;
};

const defaultAnnotationSettings = {
  columns: {
    type: {
      field: 'annotation.type',
      colId: 'type',
      hide: false,
    },
    value: {
      colId: 'value',
      field: 'annotation.value',
      headerName: 'Value',
      hide: false,
    },
    startOffset: {
      colId: 'startOffset',
      field: 'annotation.start',
      headerName: 'Start Offset',
      hide: false,
    },
    endOffset: {
      colId: 'endOffset',
      field: 'annotation.end',
      headerName: 'End Offset',
      hide: false,
    },
    annotatorName: {
      colId: 'annotatorName',
      field: 'annotation.annotatedBy',
      headerName: 'Annotator Name',
      hide: false,
    },
    annotationDate: {
      colId: 'annotationDate',
      field: 'annotation.annotatedDate',
      headerName: 'Time',
      hide: false,
    },
    actions: { colId: 'actions', hide: false },
  },
};

const defaultState = {
  annotationTableSettings: defaultAnnotationSettings,
  pdfViewerSettings: {
    selectionMode: 'selection-box' as PDF_SELECTION_MODE,
  },
};

export function uiSettingsReducer(
  state: UiSettingsState = defaultState,
  action: AnnotationTableSettingsActions | PDFViewerSettingsActions,
): UiSettingsState {
  switch (action.type) {
    case getType(setAnnotationTableColumnVisibility):
      const colId = action.payload.colId;
      const prevCols = state.annotationTableSettings.columns;
      return {
        ...state,
        annotationTableSettings: {
          ...state.annotationTableSettings,
          columns: {
            ...prevCols,
            [colId]: { ...prevCols[colId], hide: action.payload.hide },
          },
        },
      };

    case getType(setPdfSelectionMode):
      const selectionMode = action.payload.mode;
      return {
        ...state,
        pdfViewerSettings: { ...state.pdfViewerSettings, selectionMode },
      };

    default:
      return state;
  }
}
