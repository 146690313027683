/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { Popover } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import React from 'react';
import { AnnotationMode } from '../containers/annotation-shortcut-keys.container';
import { AnnotationSchema } from '../models/annotation-schema.model';
import { AnnotationType } from '../models/annotation-type.model';
import AnnotationListDropdown from '../containers/annotation-list-dropdown.container';

export interface PopoverProps {
  visible?: boolean;
  placement?: TooltipPlacement;
  trigger?: 'hover' | 'focus' | 'click';
  annotationMode?: AnnotationMode;
  hotkeysDisabled?: boolean;
  refocusWhenVisible?: boolean;
  allSchemas: AnnotationSchema[];
  schema: AnnotationSchema | null;
  onAnnotationTypeSelected: (type: AnnotationType) => void;
  onSelectSchema: (schema: AnnotationSchema) => void;
  onClose?: () => void;
  schemaEditorDisabled?: boolean;
  children: React.ReactNode | React.ReactNode[];
}

export const AnnotationListPopover = (props: PopoverProps) => {
  function renderPopoverContent(
    allSchemas: AnnotationSchema[],
    schema: AnnotationSchema | null,
    onAnnotationTypeSelected: (type: AnnotationType) => void,
    onSelectSchema: (schema: AnnotationSchema) => void,
    refocusWhenVisible?: boolean,
    onClose?: () => void,
    visible?: boolean,
    schemaEditorDisabled?: boolean,
  ) {
    return (
      <AnnotationListDropdown
        visible={visible}
        refocusWhenVisible={refocusWhenVisible}
        schema={schema}
        onAnnotationTypeSelected={onAnnotationTypeSelected}
        onSelectSchema={onSelectSchema}
        onClose={onClose}
        schemaEditorDisabled={schemaEditorDisabled}
      ></AnnotationListDropdown>
    );
  }

  if (!props.schema) {
    return null;
  }

  const popoverContent = renderPopoverContent(
    props.allSchemas,
    props.schema,
    props.onAnnotationTypeSelected,
    props.onSelectSchema,
    props.refocusWhenVisible,
    props.onClose,
    props.visible,
    props.schemaEditorDisabled,
  );
  const baseProps = {
    content: popoverContent,
    overlayClassName: 'annotation-list-popover',
    placement: props.placement,
    trigger: props.trigger,
    mouseLeaveDelay: 60,
    destroyTooltipOnHide: true,
  };
  const visible = props.visible;
  const visibleDefined = visible !== undefined;
  // remove 'visible' so that 'trigger' functions properly
  const popProps = visibleDefined
    ? { ...baseProps, visible: props.visible }
    : baseProps;

  return <Popover {...popProps}> {props.children} </Popover>;
};
