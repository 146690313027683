import { createSelector } from 'reselect';
import { AppState } from '../../app-state';
import { AnnotationRelation } from '../models/annotation-relation.model';
import { AnnotationTableRow } from '../models/annotation-table-row.model';
import { selectRelationRulesArray } from './annotation-schema.selectors';
import {
  selectAnnotations,
  selectAnnotationsArray,
} from './annotation.selectors';
import { Map } from 'immutable';
import { RelationType } from '../../lib/relations/relation-types';
import {
  selectRelationRuleConditionsArray,
  selectRelationRulesByName,
} from '../selectors/annotation-schema.selectors';

export const selectAnnotationRelations = (
  state: AppState,
): Map<string, AnnotationRelation> => state.annotationRelations.entities;

export const selectAnnotationRelationsArray = createSelector(
  [selectAnnotationRelations],
  relations => Array.from(relations.values()),
);

export const selectFlatTree = createSelector(
  [selectAnnotationsArray, selectAnnotationRelationsArray],
  AnnotationRelation.createKeyedFlatTree,
);

export const selectAnnotationRelationErrors = createSelector(
  [selectAnnotationRelations, selectAnnotations, selectRelationRulesArray],
  (relations, annotations, rules) =>
    AnnotationRelation.validate(annotations, relations, rules),
);

export const selectAnnotationTableRows = createSelector(
  [selectAnnotationsArray, selectFlatTree],
  (annotations, flatTree) =>
    AnnotationTableRow.createMany(annotations, flatTree),
);

export const selectAsRelation = (state: AppState) => (
  left: string,
  right: string,
  relationType: RelationType,
) => {
  const currentAnnotations = selectAnnotations(state);
  const rulesByName = selectRelationRulesByName(state);
  const conditions = selectRelationRuleConditionsArray(state);

  const leftAnnotation = currentAnnotations.get(left);
  const rightAnnotation = currentAnnotations.get(right);

  if (!leftAnnotation || !rightAnnotation) return null;

  const rule = AnnotationRelation.findApplicableRule(
    leftAnnotation.type,
    relationType,
    rightAnnotation.type,
    rulesByName,
    conditions,
  );

  if (!rule) return null;

  const relation = { left: leftAnnotation, right: rightAnnotation, rule };
  return relation;
};
