import { Dictionary } from 'lodash';
import { connect } from 'react-redux';
import {
  PermissionActions,
  PermissionResources,
} from '../../accounts/models/permission.model';
import { selectHasPermissions } from '../../accounts/selectors/users.selectors';
import { fetchAvailableDocumentStatuses } from '../../annotations/actions/available-document-status.actions';
import { AppState } from '../../app-state';
import { DocumentSet } from '../../document-sets/models/document-set.model';
import { fetchQueues } from '../../queues/actions/queue.actions';
import { selectAllQueuesAsArray } from '../../queues/selectors';
import { fetchTagsAsync } from '../../tags/actions/tag.actions';
import { selectGroupScopedTags } from '../../tags/selectors/tag.selectors';
import {
  clearSelectedSearchResults,
  submitSearch,
} from '../actions/search.actions';
import { SearchForm } from '../components/search-form.component';
import { selectShowingResults } from '../selectors';

const annotatePermissions = {
  action: PermissionActions.Annotate,
  resource: PermissionResources.DocumentSet,
};

type SearchFormContainerProps = { documentSets: Dictionary<DocumentSet> };

export const SearchFormContainer = connect(
  (state: AppState, ownProps: SearchFormContainerProps) => ({
    documentSets: ownProps.documentSets,
    documentSetsSelected: state.search.searchableDocumentSets,
    formValues: state.search.formValues,
    queryConditions: state.search.queryConditions,
    showAdvanced: state.search.showAdvanced,
    availableStatuses: state.availableDocumentStatuses.map(ds => ds.status),
    availableQueues: selectAllQueuesAsArray(state),
    availableTags: selectGroupScopedTags(state),
    showingResults: selectShowingResults(state),
    userHasAnnotationPermissions: selectHasPermissions([annotatePermissions])(
      state,
    ),
  }),
  {
    submitQuery: submitSearch,
    getDocStatuses: fetchAvailableDocumentStatuses,
    clearSelectedSearchResults,
    fetchQueues,
    fetchTags: fetchTagsAsync.request,
  },
)(SearchForm);
