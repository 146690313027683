import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from '../../app-state';
import { RelationCondition } from '../../lib/relations/relation-condition.model';
import {
  AnnotationGroupResource,
  NewAnnotationGroupResource,
} from '../resources/annotation-group.resource';
import {
  selectAnnotationsTypesByKey,
  selectRelationRuleConditionsArray,
  selectRelationRulesByName,
} from '../selectors/annotation-schema.selectors';
import { AnnotationRelationsRulesByName } from './annotation-relation-rule.model';
import { AnnotationRelation } from './annotation-relation.model';
import { AnnotationSaveEvent } from './annotation-save-event.model';
import { AnnotationTypesByKey } from './annotation-type.model';
import { Annotation } from './annotation.model';
import { Map } from 'immutable';

export type AnnotationGroup = {
  annotatedDocumentId: number;
  saveEvent: AnnotationSaveEvent;
  annotations: Map<string, Annotation>;
  relations: Map<string, AnnotationRelation>;
};

export const AnnotationGroup = {
  get(
    state: AppState,
    annotatedDocumentId: number,
  ): Observable<AnnotationGroup> {
    return AnnotationGroupResource.get(state, annotatedDocumentId).pipe(
      map(
        this.mapFromResource(
          selectAnnotationsTypesByKey(state),
          selectRelationRulesByName(state),
          selectRelationRuleConditionsArray(state),
        ),
      ),
    );
  },

  post(
    state: AppState,
    group: NewAnnotationGroupResource,
  ): Observable<AnnotationGroup> {
    return AnnotationGroupResource.post(state, group).pipe(
      map(
        this.mapFromResource(
          selectAnnotationsTypesByKey(state),
          selectRelationRulesByName(state),
          selectRelationRuleConditionsArray(state),
        ),
      ),
    );
  },

  /**
   * Maps an AnnotationGroupResource to an AnnotationGroup.
   */
  mapFromResource: (
    annotationTypes: AnnotationTypesByKey,
    rulesByName: AnnotationRelationsRulesByName,
    conditions: RelationCondition<string>[],
  ) => (group: AnnotationGroupResource) => {
    const annotations = Annotation.mapAndFilterResources(
      group.annotations,
      annotationTypes,
      group.annotatedDocumentId,
    );

    const relations = AnnotationRelation.mapAndFilterResource(
      group.relations,
      annotations,
      rulesByName,
      conditions,
    );

    return {
      annotatedDocumentId: group.annotatedDocumentId,
      annotations,
      relations,
      saveEvent: group.saveEvent,
    };
  },
};
