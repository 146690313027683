import { DownOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { Popover } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { AppState } from '../../app-state';
import { Loader } from '../../common/components/loader.component';
import { PrimaryButton } from '../../common/components/primary-button.component';
import { selectActiveSourceDocument } from '../../documents/selectors/source-document.selectors';
import { toggleVisible } from '../actions/prediction-selector.actions';
import { runPredictions } from '../actions/predictions.actions';
import { selectCurrentLabels } from '../selectors/predictions.selectors';
import { PredictionSelector } from './prediction-selector.component';

const ButtonWrap = styled.span`
  display: flex;
`;

type RunPredictionsButtonProps = {
  predictionsRunning: boolean;
  labelsSelected: string[];
  disabled: boolean;
  onClick: (e: React.SyntheticEvent<any>) => void;
};
const RunPredictionsButton = (props: RunPredictionsButtonProps) => {
  const predictButton = (
    <PrimaryButton
      style={{ borderRight: '1px solid white' }}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <>
        {props.predictionsRunning && <Loader style={{ width: 30 }} />}
        Predict
      </>
    </PrimaryButton>
  );

  const tooltipMessage = props.predictionsRunning
    ? 'Predictions are running'
    : props.labelsSelected.length === 0
    ? 'No labels are selected'
    : undefined;

  return tooltipMessage !== undefined ? (
    <Tooltip placement="left" title={tooltipMessage}>
      {predictButton}
    </Tooltip>
  ) : (
    predictButton
  );
};

type PredictionSelectorPopoverProps = {
  labelsToPredictOn?: string[];
  predictionsRunning: boolean;
  documentLoaded: boolean;
  visible: boolean;
  disabled?: boolean;
  predict: () => void;
  toggleVisible: () => void;
};
class PredictionSelectorPopoverUnconnected extends React.PureComponent<
  PredictionSelectorPopoverProps
> {
  render() {
    const {
      labelsToPredictOn,
      predictionsRunning,
      documentLoaded,
      visible,
      disabled,
      predict,
    } = this.props;

    return (
      <Popover
        content={<PredictionSelector />}
        visible={visible}
        mouseLeaveDelay={1}
      >
        <ButtonWrap>
          <RunPredictionsButton
            predictionsRunning={predictionsRunning}
            labelsSelected={
              labelsToPredictOn !== undefined ? labelsToPredictOn : []
            }
            disabled={
              disabled ||
              predictionsRunning ||
              !documentLoaded ||
              labelsToPredictOn === undefined ||
              labelsToPredictOn.length === 0
            }
            onClick={predict}
          />
          <PrimaryButton onClick={this.props.toggleVisible} disabled={disabled}>
            <DownOutlined />
          </PrimaryButton>
        </ButtonWrap>
      </Popover>
    );
  }
}

export const PredictionSelectorPopover = connect(
  (state: AppState) => ({
    labelsToPredictOn: selectCurrentLabels(state),
    visible: state._UI_predictionSelector.visible,
    predictionsRunning: state.predictions.predictionsStatus === 'running',
    documentLoaded: !!selectActiveSourceDocument(state),
  }),
  dispatch => ({
    predict: () => {
      dispatch(runPredictions());
    },
    toggleVisible: () => {
      dispatch(toggleVisible());
    },
  }),
)(PredictionSelectorPopoverUnconnected);
