import { connect } from 'react-redux';
import {
  selectActiveUserIsAdmin,
  selectUserEmail,
} from '../../accounts/selectors/users.selectors';
import { AppState } from '../../app-state';
import { signOut } from '../../auth/actions/auth.actions';
import { IdentityDropdownComponent } from '../components/identity-dropdown.component';

export const IdentityDropdown = connect(
  (state: AppState) => ({
    username: selectUserEmail(state),
    isAdmin: selectActiveUserIsAdmin(state),
  }),
  dispatch => ({
    signOut: () => dispatch(signOut()),
  }),
)(IdentityDropdownComponent);
