import { Tabs } from 'antd';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import DocumentSetManagement from '../document-sets/containers/document-set-management.container';
import { QueueManager } from '../queues/components/queue-manager.component';
import { SchemaManager } from '../schemas/components/schema-manager.component';
import { LookupManager } from '../domain/components/domain-lookup-manager.component';
const TabPane = Tabs.TabPane;

export type ManagedResource = 'document-set';
export type ManagePageRouteParams = {
  resource: ManagedResource;
};

const ManagementContent = styled.div`
  padding: 0px 32px 16px 32px;
  .ant-tabs-ink-bar {
    background-color: #2f374a;
  }

  .ant-tabs-tab-active.ant-tabs-tab {
    color: #2f374a;
  }

  .ant-tabs-content {
    height: calc(100% - 60px);
  }
`;

const ManagementPanel: React.SFC<RouteComponentProps<
  ManagePageRouteParams
>> = ({ history, match }) => {
  const defaultActiveTabKey: ManagedResource = match.params.resource;
  return (
    <ManagementContent>
      <Tabs
        onChange={activeKey => history.push(`/management/${activeKey}`)}
        defaultActiveKey={defaultActiveTabKey}
        style={{ fontSize: '16px', width: '100%', height: '100%' }}
      >
        <TabPane tab="Document Sets" key="document-set">
          <DocumentSetManagement />
        </TabPane>
        <TabPane tab="Queues" key="queues">
          <QueueManager />
        </TabPane>
        <TabPane tab="Schemas" key="schemas">
          <SchemaManager />
        </TabPane>
        <TabPane tab="Lookups" key="lookups">
          <LookupManager />
        </TabPane>
      </Tabs>
    </ManagementContent>
  );
};

export default withRouter(ManagementPanel);
