import { reduce } from 'lodash';
import React from 'react';
import { HotKeys } from 'react-hotkeys';
import { AnnotationType } from '../models/annotation-type.model';

export interface AnnotationShortcutKeysProps {
  annotations: AnnotationType[] | null;
  onAnnotationPress: (type: AnnotationType) => any;
  children?: React.ReactNode | React.ReactNode[];
}
interface AnnotationShortcutKeysState {
  handlers: any;
  keyMap: any;
}
export class AnnotationShortcutKeys extends React.PureComponent<
  AnnotationShortcutKeysProps,
  AnnotationShortcutKeysState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      handlers: this.createHandlers(props.annotations),
      keyMap: this.createKeyMap(props.annotations),
    };
  }

  componentWillReceiveProps(nextProps: AnnotationShortcutKeysProps) {
    this.setState({
      handlers: this.createHandlers(nextProps.annotations),
      keyMap: this.createKeyMap(nextProps.annotations),
    });
  }

  createHandlers(annotations: AnnotationType[] | null) {
    return reduce(
      annotations,
      (handlers, type) => {
        const setAnnotation = () => this.setAnnotation(type);
        const handler = (event: Event) => {
          event.preventDefault();
          return setAnnotation();
        };
        return {
          ...handlers,
          [type.annotationTypeId]: handler,
        };
      },
      {},
    );
  }

  createKeyMap(annotations: AnnotationType[] | null) {
    return reduce(
      annotations,
      (keys, type) => {
        return {
          ...keys,
          [type.annotationTypeId]: type.keyboardShortcut,
        };
      },
      {},
    );
  }

  setAnnotation(type: AnnotationType) {
    this.props.onAnnotationPress(type);
  }

  render() {
    const { keyMap, handlers } = this.state;

    return (
      <HotKeys keyMap={keyMap} handlers={handlers} focused={true}>
        {this.props.children}
      </HotKeys>
    );
  }
}
