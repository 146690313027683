import {
  CheckOutlined,
  RightOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Table } from 'antd';
import { Input } from 'antd';
import { filter } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { GroupAccount } from '../../accounts/models/group-account.model';
import { Permission } from '../../accounts/models/permission.model';
import { SelectableRow } from '../../common/components/table/selectable-row.component';
import { SearchBarWrapper } from './search-bar-wrapper.component';

const columns = [
  {
    title: 'Group ID',
    dataIndex: ['group', 'groupId'],
    key: 'group.groupId',
    sorter: (a: GroupAccount, b: GroupAccount) =>
      a.group.groupId - b.group.groupId,
  },
  {
    title: 'Group Name',
    dataIndex: ['group', 'groupName'],
    key: 'group.groupName',
    sorter: (a: GroupAccount, b: GroupAccount) =>
      a.group.groupName < b.group.groupName ? -1 : 1,
  },
  {
    title: 'Ann.',
    render: (account: GroupAccount) =>
      Permission.canAnnotateDocuments(account.permissions) && <CheckOutlined />,
  },
  {
    title: 'Review',
    render: (account: GroupAccount) =>
      Permission.canReviewDocuments(account.permissions) && <CheckOutlined />,
  },
  {
    title: 'Admin',
    render: (account: GroupAccount) =>
      Permission.hasAdminPermissions(account.permissions) && <CheckOutlined />,
  },
  {
    title: '',
    render: () => (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a>
        <RightOutlined />
      </a>
    ),
  },
];

const TableStyle = styled.div`
  .ant-table-wrapper {
    margin-top: 12px;
  }

  .ant-spin-nested-loading > div > .ant-spin {
    width: 0px;
  }
`;

interface GroupTableProps {
  groupAccounts: GroupAccount[];
  loading: boolean;
  onInit: () => any;
  onSelect: (groupAccount: GroupAccount) => any;
}
interface GroupTableState {
  rowData: any[];
  filterValue: string;
  selectedIdx: number | null;
}
export class GroupTableComponent extends React.Component<
  GroupTableProps,
  GroupTableState
> {
  constructor(props: GroupTableProps) {
    super(props);

    this.state = {
      rowData: props.groupAccounts,
      filterValue: '',
      selectedIdx: null,
    };
  }

  componentWillMount() {
    this.props.onInit();
  }

  setSelectedRow = (groupAccount: GroupAccount, idx: number) => {
    this.setState({ selectedIdx: idx });
    this.props.onSelect(groupAccount);
  };

  componentWillReceiveProps(newProps: GroupTableProps) {
    if (newProps.groupAccounts !== this.props.groupAccounts) {
      this.updateFilter(this.state.filterValue, newProps.groupAccounts);
    }
  }

  updateSearch = (e: any) => {
    this.updateFilter(e.target.value, this.props.groupAccounts);
  };

  updateFilter(value: string, accounts: GroupAccount[]) {
    const regex = new RegExp(value, 'i');
    const filteredData = filter(accounts, grpAccount => {
      return (
        grpAccount.group.groupName.match(regex) ||
        (grpAccount.group.groupId + '').match(regex)
      );
    });

    this.setState({
      rowData: filteredData,
      filterValue: value,
    });
  }

  render() {
    const { selectedIdx } = this.state;

    return (
      <TableStyle>
        <SearchBarWrapper>
          <Input
            addonBefore={<SearchOutlined />}
            placeholder={'Search Groups'}
            onPressEnter={this.updateSearch}
          />
        </SearchBarWrapper>
        <Table
          columns={columns}
          dataSource={this.state.rowData}
          pagination={false}
          size="small"
          loading={this.props.loading}
          components={{ body: { row: SelectableRow } }}
          rowKey={(account: GroupAccount) => account.group.groupId + ''}
          onRow={(groupAccount: GroupAccount, idx: number | undefined) => ({
            selected: idx === selectedIdx ? true : false,
            onClick: () => {
              if (idx !== undefined) {
                this.setSelectedRow(groupAccount, idx);
              }
            },
          })}
        />
      </TableStyle>
    );
  }
}
