import { getType } from 'typesafe-actions';
import { AppState } from '../../app-state';
import {
  setAnnotationPanePageOffsets,
  setPaneSize,
} from '../actions/ui-panes.actions';
import { UIPanesActions } from '../actions/ui-panes.types';

export type PaneSettings = { size: number };
export type UiPanesReducerState = {
  pane_0: PaneSettings;
  pane_1: PaneSettings;

  annotationPanePageOffsets: number[];
};

const defaultState = {
  pane_0: { size: 720 },
  pane_1: { size: 200 },
  annotationPanePageOffsets: [],
};

export function uiPanesReducer(
  state: UiPanesReducerState = defaultState,
  action: UIPanesActions,
  appState: AppState,
): UiPanesReducerState {
  switch (action.type) {
    case getType(setPaneSize):
      return {
        ...state,
        [`pane_${action.payload.paneId}`]: {
          size: action.payload.size,
        },
      };

    case getType(setAnnotationPanePageOffsets):
      return {
        ...state,
        annotationPanePageOffsets: action.payload.offsets,
      };

    default:
      return state;
  }
}
