import { Color } from './color.model';

/**
 * User highlight when passed as a element prop.
 * The difference is that color is passed as a string.
 * To convert into library's internal representation of Color,
 * the color is converted to a Color class.
 */
export interface UserHighlightProp {
  start: number;
  end: number;
  color: string;
  data?: any;
  htmlClass?: any;
}

export const UserHighlightProp = {
  uniqueId(highlight: UserHighlightProp) {
    return (
      'highlight-' +
      highlight.start +
      '-' +
      highlight.end +
      '-' +
      highlight.color
    );
  },
};

export interface UserHighlight {
  id: string | number;
  start: number;
  end: number;
  color: Color;
  data?: any;
  htmlClass?: any;
}

export interface UserHighlightComparable {
  start: number;
  end: number;
  color: Color;
}

export const UserHighlight = {
  /**
   * Inserts a highlight into a sorted user highlight array.
   * Important: the set must be presorted.
   * @param h
   * @param set
   */
  insert(h: UserHighlight, set: UserHighlight[]): UserHighlight[] {
    if (set.length <= 0) {
      return [h];
    }

    let i = 0;
    while (UserHighlight.compare(h, set[i]) > 0) {
      i++;
    }

    set.splice(i, 0, h);
    return set;
  },

  compare(h1: UserHighlightComparable, h2: UserHighlightComparable) {
    if (h1.start !== h2.start) {
      return h1.start - h2.start;
    } else if (h1.end !== h2.end) {
      return h1.end - h2.end;
    } else {
      return h1.color.hex > h2.color.hex ? 1 : -1;
    }
  },

  /**
   * Creates an aggregate id from the passed highlights.
   * Make sure the highlights are sorted!
   * @param highlights
   */
  aggregateId(highlights: UserHighlight[]) {
    return highlights.map(h => h.id).reduce((id1, id2) => id1 + '-' + id2, '');
  },

  /**
   * Converts a UserHighlightProp to a UserHighlight.
   */
  fromProp: (prop: UserHighlightProp): UserHighlight => ({
    ...prop,
    id: UserHighlightProp.uniqueId(prop),
    color: new Color(prop.color),
    data: prop.data,
  }),

  create: (fields: UserHighlightProp): UserHighlight => {
    return UserHighlight.fromProp(fields);
  },

  overlapping(
    h1: UserHighlightComparable,
    h2: UserHighlightComparable,
  ): boolean {
    return (
      (h1.start < h2.end && h1.end > h2.start) ||
      (h2.start < h1.end && h2.end > h1.start)
    );
  },

  uniqueId(highlight: UserHighlight) {
    return (
      'highlight-' +
      highlight.start +
      '-' +
      highlight.end +
      '-' +
      highlight.color.hex
    );
  },
};
