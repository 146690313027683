import { map } from 'lodash';
import { createSelector } from 'reselect';
import { AppState } from '../../app-state';
import { BulkAnnotationUploadState } from '../reducers/bulk-annotations-importer-status.reducer';
import { AnnotationKeyLookupFailures } from '../resources/annotation-group.resource';

export type BulkAnnotationUploadDetailsProps = {
  status: BulkAnnotationUploadState['status'];
  message: string;
  errorList: string[] | undefined;
  successfulDocumentCount: number | undefined;
};

export const selectBulkAnnotationsUploadStatus = (state: AppState) =>
  state.bulkAnnotationsImportStatuses.status;

export const selectBulkAnnotationsUploadErrors = (state: AppState) =>
  state.bulkAnnotationsImportStatuses.errors;

export const selectBulkAnnotationsUploadErrorList = createSelector(
  selectBulkAnnotationsUploadErrors,
  errors => {
    if (errors) {
      return map(errors.failures, AnnotationKeyLookupFailures.toErrorMessage);
    }
  },
);

export const selectBulkAnnotationsUploadErrorSuccessCount = createSelector(
  selectBulkAnnotationsUploadErrors,
  errors => {
    if (errors) {
      return errors.successCount;
    }
  },
);

export const selectBulkAnnotationsUploadStatusMessage = createSelector(
  selectBulkAnnotationsUploadStatus,
  status => {
    switch (status) {
      case 'uploading':
        return 'Please wait for your file to finish uploading.';
      case 'partialSuccess':
        return 'Your upload has finished with some errors. Please view the list below for more details.';
      case 'failure':
        return 'Your CSV could not be processed. Please check the list below and update your file accordingly.';
      case 'success':
        return 'Your upload has completed successfully.';
      default:
        return 'Loading...';
    }
  },
);

export const selectBulkAnnotationsUploadDetails = createSelector(
  selectBulkAnnotationsUploadStatus,
  selectBulkAnnotationsUploadStatusMessage,
  selectBulkAnnotationsUploadErrorList,
  selectBulkAnnotationsUploadErrorSuccessCount,
  (status, message, errorList, successfulDocumentCount) => {
    const statusDetails: BulkAnnotationUploadDetailsProps = {
      status,
      message,
      errorList,
      successfulDocumentCount,
    };
    return statusDetails;
  },
);
