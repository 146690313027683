import { catchError, filter, mapTo, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AppEpic } from '../../common/types/app-epic.type';
import { handleEpicError } from '../../common/utils/epics';
import { deleteQueue, fetchQueues } from '../actions/queue.actions';
import { QueuesResource } from '../resources/queues.resource';

export const deleteQueueEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(deleteQueue)),
    switchMap(({ payload: { queueId } }) =>
      QueuesResource.delete(state$.value, queueId).pipe(
        mapTo(fetchQueues()),
        catchError(
          handleEpicError('Failed to delete queue. Please try again later.'),
        ),
      ),
    ),
  );
