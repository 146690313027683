import { createAction } from 'typesafe-actions';
import { KeyValueMap } from '../../common/types/KeyValueMap.type';
import {
  FormValues,
  SelectableDocumentSet,
} from '../components/search-form.component';
import { QueryResponse } from '../models/query-response.model';
import { QueryCondition } from '../models/query.model';

enum Types {
  SUBMIT_SEARCH = '[User] Search: Clicked the submit search button',
  POPULATE_RESPONSE = 'Search: got back results from search',
  SEARCH_FAILURE = 'Search: search failed',
  PAGE_CHANGE = '[User] Search: Requested different page',
  GET_SEARCHABLE_DOC_SETS = 'Search: page load, need to get available document sets',
  GET_MAPPING = 'Search: page load, need to get mappings for indexes the user can read',
  POPULATE_MAPPINGS = 'Search: got back mappings for indexes user can read',
  GET_MAPPINGS_FAILURE = 'Search: getting mappings for indexes user can read failed',
  SEARCH_RESULT_SELECT = '[User] Search: a search result was selected',
  ALL_SEARCH_RESULTS_CHECKED = '[User] Search: the select all checkbox was clicked',
  CLEAR_SELECTED_SEARCH_RESULTS = 'Search: we need to clear all selected search results',
  CHANGE_SEARCH_RESULT_ORDER = 'Search: change search result order',
}

export const submitSearch = createAction(
  Types.SUBMIT_SEARCH,
  resolvePayload => (
    documentSetsSelected: KeyValueMap<SelectableDocumentSet>,
    formValues: FormValues,
    showAdvanced: boolean,
    queryConditions: KeyValueMap<QueryCondition>,
  ) =>
    resolvePayload({
      documentSetsSelected,
      formValues,
      showAdvanced,
      queryConditions,
    }),
);

export const populateResponse = createAction(
  Types.POPULATE_RESPONSE,
  resolvePayload => (response: QueryResponse) => resolvePayload({ response }),
);

export const searchFailure = createAction(
  Types.SEARCH_FAILURE,
  resolvePayload => (error: string) => resolvePayload({ error }),
);

export const pageChange = createAction(
  Types.PAGE_CHANGE,
  resolvePayload => (page: number) => resolvePayload({ page }),
);

export const searchResultSelect = createAction(
  Types.SEARCH_RESULT_SELECT,
  resolvePayload => (id: number) => resolvePayload({ id }),
);

export const allSearchResultsChecked = createAction(
  Types.ALL_SEARCH_RESULTS_CHECKED,
);

export const clearSelectedSearchResults = createAction(
  Types.CLEAR_SELECTED_SEARCH_RESULTS,
);

export const changeSearchResultOrder = createAction(
  Types.CHANGE_SEARCH_RESULT_ORDER,
  resolvePayload => (field: string, desc: boolean) =>
    resolvePayload({ field, desc }),
);
