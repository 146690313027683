import { createSelector } from 'reselect';
import { selectGroupAccounts } from '../../accounts/selectors/groups.selectors';
import { AppState } from '../../app-state';
import { AdminTab } from '../models/admin-tab.model';

export const selectActiveTab = (state: AppState): AdminTab =>
  state._UI_admin.activeTab;

export const selectSelectedGroupAccountId = (state: AppState): number | null =>
  state._UI_admin.selectedGroupAccount;

export const selectSelectedGroupAccount = createSelector(
  [selectSelectedGroupAccountId, selectGroupAccounts],
  (id, accounts) => (id ? accounts[id] : null),
);
