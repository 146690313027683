import { connect } from 'react-redux';
import {
  clearAnnotationSelection,
  setAnnotationSelection,
} from '../../annotations/actions/ui-annotations.actions';
import { AnnotationSelection } from '../../annotations/models/annotation-selection.model';
import {
  selectFullText,
  selectTextOffsets,
} from '../../annotations/selectors/annotation-pages.selectors';
import {
  selectAnnotationHighlights,
  selectEmphasizeAnnotationId,
} from '../../annotations/selectors/ui.selectors';
import { AppState } from '../../app-state';
import { selectJwt } from '../../auth/selectors/auth.selectors';
import {
  sourceDocumentError,
  sourceDocumentLoaded,
} from '../actions/source-document.actions';
import { PdfViewer } from '../components/pdf-viewer.component';
import {
  selectSourceDocumentCurrentPageNumber,
  selectSourceDocumentError,
  selectSourceDocumentLoading,
  selectSourceDocumentUrl,
} from '../selectors/source-document.selectors';
import {
  selectPdfViewerRotation,
  selectPdfViewerSelectionMode,
  selectPdfViewerZoom,
} from '../selectors/ui.selecetors';

export const PdfViewerContainer = connect(
  (state: AppState) => ({
    page: selectSourceDocumentCurrentPageNumber(state),
    url: selectSourceDocumentUrl(state),
    authToken: selectJwt(state),
    loading: selectSourceDocumentLoading(state),
    error: selectSourceDocumentError(state),
    highlights: selectAnnotationHighlights(state),
    documentText: selectFullText(state),
    rotation: selectPdfViewerRotation(state),
    zoom: selectPdfViewerZoom(state),
    selectionMode: selectPdfViewerSelectionMode(state),
    emphasizeAnnotationId: selectEmphasizeAnnotationId(state),
    textOffsets: selectTextOffsets(state),
  }),
  dispatch => ({
    onDocumentLoad: (pdf: any) => {
      dispatch(sourceDocumentLoaded(pdf.numPages));
    },
    onError: (error: string) => {
      dispatch(sourceDocumentError(error));
    },
    onSelectionChange: (selection: AnnotationSelection[]) => {
      dispatch(setAnnotationSelection(selection, 'pdf-viewer'));
    },
    onSelectionCleared: () => dispatch(clearAnnotationSelection()),
  }),
)(PdfViewer);
