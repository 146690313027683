import { InfoSvg } from './info.svg';
import { RotateDocumentSvg } from './rotate-document.svg';
import { SelectionSquareSvg } from './selection-square.svg';
import { ZoomInSvg } from './zoom-in.svg';
import { ZoomOutSvg } from './zoom-out.svg';

export const Icons = {
  'zoom-in': ZoomInSvg,
  'zoom-out': ZoomOutSvg,
  info: InfoSvg,
  'selection-square': SelectionSquareSvg,
  'rotate-document': RotateDocumentSvg,
};
