import { ActionsObservable, StateObservable } from 'redux-observable';
import { empty, from, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AppState } from '../../app-state';
import { AppActions } from '../../root.actions';
import { saveAnnotationDocumentStatuses } from '../actions/annotation-document-status.actions';
import { saveAnnotations } from '../actions/annotations.actions';
import {
  saveButtonClicked,
  scrollAnnotationIntoView,
} from '../actions/ui-annotations.actions';
import { AnnotationPage } from '../models/annotation-page.model';
import { Annotation } from '../models/annotation.model';
import { selectAnnotationPagesArray } from '../selectors/annotation-pages.selectors';
import { selectAnnotations } from '../selectors/annotation.selectors';
import { setPage } from '../../documents/actions/page-controls.actions';
import { PageView } from '../../documents/models/page-view.model';

export function saveButtonClickedEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(saveButtonClicked)),
    switchMap(action =>
      from([saveAnnotations(), saveAnnotationDocumentStatuses()]),
    ),
  );
}

/**
 * This epic performs two side effects:
 *   1. Scrolls annotation into view in the annotation viewer
 *   2. Moves the pdf viewer to the page which contains the annotation.
 * @param action$
 * @param state$
 */
export function scrollAnnotationIntoViewEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  const scrollIntoView = (atn: Annotation) => {
    const elms = document.getElementsByClassName(Annotation.htmlClass(atn.id));

    /* tslint:disable */
    /**
     * This behavior is based
     * on the way highlighted annotations are created in the application. The
     * text viewer will have one matching html element per annotation, while
     * the pdf may have zero to several.
     * Zero if not on same page, several because an annotation may span multiple
     * of the pdf's text elements.
     */
    for (let i = 0; i < elms.length; i++) {
      elms[i].scrollIntoView();
    }
    /* tslint:enable */
  };

  return action$.pipe(
    filter(isActionOf(scrollAnnotationIntoView)),
    switchMap(action => {
      const atn = selectAnnotation(state$.value, action.payload.annotationId);

      if (atn) {
        scrollIntoView(atn);
        const pages = selectAnnotationPagesArray(state$.value);
        const pageNum = AnnotationPage.annotationPageNumbers(atn, pages);
        if (pageNum.length) {
          return of(setPage(PageView.All, pageNum[0]));
        }
      }

      return empty();
    }),
  );
}

function selectAnnotation(
  state: AppState,
  annotationId: string,
): Annotation | null {
  const annotation = selectAnnotations(state).get(annotationId)!;

  return annotation || null;
}
