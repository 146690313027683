import { get, map, values } from 'lodash';
import { createSelector } from 'reselect';
import { AppState } from '../../app-state';
import { KeyValueMap } from '../../common/types/KeyValueMap.type';
import { GroupAccount } from '../models/group-account.model';
import { Group } from '../models/group.model';

export const selectCurrentUserGroup = (state: AppState): Group => {
  return get(state, 'users.activeUserAccount.groups[0]');
};

export const selectCurrentUserIsPopulated = (state: AppState): boolean =>
  !!state.users.activeUserAccount;

export const selectAllCurrentUserGroups = (state: AppState): Group[] => {
  return get(state, 'users.activeUserAccount.groups', []);
};
export const selectCurrentUserGroupId = createSelector(
  [selectCurrentUserGroup],
  userGroup => (userGroup ? userGroup.groupId : null),
);

export const selectGroupsAreLoading = (state: AppState): boolean =>
  state.groups.loading;

export const selectGroupAccounts = (
  state: AppState,
): KeyValueMap<GroupAccount> => get(state, 'groups.accounts', {});

export const selectGroupAccountsArray = createSelector(
  [selectGroupAccounts],
  accounts => values(accounts),
);

export const selectAllGroupsArray = createSelector(
  [selectGroupAccounts],
  (accounts): Group[] => values(accounts).map(a => a.group),
);

function groupsToGroupNames(groups: Group[]): string[] {
  return map(groups, g => g.groupName);
}

export const selectAllGroupNames = createSelector(
  [selectAllGroupsArray],
  groupsToGroupNames,
);

export const selectAllCurrentUserGroupNames = createSelector(
  [selectAllCurrentUserGroups],
  groupsToGroupNames,
);

export const selectUserGroupCount = createSelector(
  [selectGroupAccountsArray],
  groups => groups.length,
);
