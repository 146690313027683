import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map as mapObservable } from 'rxjs/operators';
import { AppState } from '../../app-state';
import { KeyValueMap } from '../../common/types/KeyValueMap.type';
import { QuerySort } from '../../common/types/QuerySort';
import { CONTENT_TYPE_HEADERS, authHeaders } from '../../common/utils/fetch';
import * as urlUtils from '../../common/utils/url';
import { config } from '../../config/application.config';
import { AnnotatedDocument } from '../models/annotated-document.model';
import { DocumentLockResource } from './document-lock.resource';

export type OcrStatus = 'pending' | 'started' | 'completed' | 'failed';

export type OcrStatusResult = {
  annotatedDocumentId: number;
  status: OcrStatus;
};

export interface DocumentSummaryResource {
  annotatedDocumentId: number;
  documentSummary: AnnotatedDocument;
}

export interface DocumentSummarySearchResource {
  documents: AnnotatedDocument[];
  pageNum: number;
  totalItems: number;
  totalPages: number;
}

export const DocumentSummaryResource = {
  url: config.annotationService.url + '/document-summary',
  documentSetUrl: config.annotationService.url + '/document/document-sets',
  lockDocumentUrl: (sourceDocumentId: number) =>
    `${config.annotationService.url}/document/${sourceDocumentId}/lock`,
  docUrl(annotatedDocumentId: number) {
    return `${this.url}/${annotatedDocumentId}`;
  },
  ocrStatusUrl(annotatedDocumentId: number) {
    return `${config.annotationService.url}/document/${annotatedDocumentId}/ocr-status`;
  },
  get(
    state: AppState,
    annotatedDocumentId: number,
  ): Observable<DocumentSummaryResource> {
    return ajax.getJSON<DocumentSummaryResource>(
      this.docUrl(annotatedDocumentId),
      authHeaders(state),
    );
  },

  search: (state: AppState) => (filter: KeyValueMap<any> = {}) => (
    page = 1,
    pageSize = 10,
    sort: QuerySort | null = null,
  ): Observable<DocumentSummarySearchResource> => {
    const docUrl = urlUtils.withQueryString(
      DocumentSummaryResource.url,
      { ...filter, page, pageSize },
      sort,
    );
    return ajax.getJSON<DocumentSummarySearchResource>(
      docUrl,
      authHeaders(state),
    );
  },
  lock(
    state: AppState,
    annotatedDocumentId: number,
  ): Observable<DocumentLockResource> {
    return ajax
      .post(
        this.lockDocumentUrl(annotatedDocumentId),
        {},
        {
          ...authHeaders(state),
          ...CONTENT_TYPE_HEADERS.JSON,
        },
      )
      .pipe(mapObservable(resp => resp.response));
  },
  getOcrStatus: (state: AppState) => (
    annotatedDocumentId: number,
  ): Observable<OcrStatusResult> => {
    return ajax.getJSON<OcrStatusResult>(
      DocumentSummaryResource.ocrStatusUrl(annotatedDocumentId),
      authHeaders(state),
    );
  },
};
