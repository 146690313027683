import { getType } from 'typesafe-actions';
import { sourceDocumentLoaded } from '../actions/source-document.actions';
import { SourceDocumentActions } from '../actions/source-document.types';
import {
  rotatePdfViewerBy,
  zoomPdfViewerBy,
} from '../actions/ui-pdf-viewer.actions';
import { UIPDFViewerActions } from '../actions/ui-pdf-viewer.types';
export type UiPdfViewerState = {
  rotation: number;
  zoom: number;
};

const defaultUiPdfViewerState = {
  rotation: 0,
  zoom: 1.0,
};

const MAX_ZOOM = 5.0;
const MIN_ZOOM = 0.25;

export function uiPdfViewerReducer(
  state: UiPdfViewerState = defaultUiPdfViewerState,
  action: UIPDFViewerActions | SourceDocumentActions,
): UiPdfViewerState {
  switch (action.type) {
    case getType(rotatePdfViewerBy):
      return {
        ...state,
        rotation: (state.rotation + action.payload.angle) % 360,
      };

    case getType(sourceDocumentLoaded):
      // Reset rotation when a new document is selected.
      return {
        ...state,
        rotation: 0,
      };

    case getType(zoomPdfViewerBy): {
      const pct = action.payload.pct;
      return {
        ...state,
        zoom: clamp(state.zoom + pct, MIN_ZOOM, MAX_ZOOM),
      };
    }

    default:
      return state;
  }
}

function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}
