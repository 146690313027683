import {
  chain,
  filter,
  find,
  flatMap,
  intersection,
  isArray,
  map,
} from 'lodash';
import { UserHighlightProp } from '../../text-highlighter/models/user-highlight.model';
import {
  AnnotationResource,
  AnnotationSegmentsResource,
} from '../resources/annotation.resource';
import { AnnotationBounds } from './annotation-bounds.model';
import { AnnotationType, AnnotationTypesByKey } from './annotation-type.model';
import { AnnotationRelation } from './annotation-relation.model';
import { Map } from 'immutable';
import { AnnotationSchema } from './annotation-schema.model';

export type Annotation = {
  value: string;
  isValid?: boolean;
} & AnnotationMetadataWithType;

export type AnnotationMetadataWithType = AnnotationMetadata & { type: string };

export type AnnotationMetadata = {
  id: string;
  start?: number;
  end?: number;
  annotatedBy: string;
  annotatedDate?: string;
  nativeText: string;

  // Position within original ocr'd document.
  // May span multiple pages.
  bounds?: AnnotationBounds[];

  // Discontinuous annotations have individual continuous segments.
  discontinuous?: boolean;
  discontinuousSegments?: AnnotationSegment[];

  // for when an annotation originally came from a prediction
  predictionId?: number;
  fromUserSpecificPrediction?: boolean;

  isMetadata?: boolean;
};

export interface AnnotationSegment {
  start?: number;
  end?: number;
  nativeText: string;
  bounds?: AnnotationBounds[];
}

export const Annotation = {
  hasValidOffsets(a: Annotation | AnnotationSegment) {
    return typeof a.start === 'number' && typeof a.end === 'number';
  },

  mapFromResources(
    responseAnnotations: AnnotationResource[],
    annotationTypes: AnnotationTypesByKey,
    sourceDocumentId: number,
  ): Map<string, Annotation> {
    const newMap = Map(
      responseAnnotations.map(res => {
        const id = res.attributeAnnotationId + '';
        return [
          id,
          {
            id: id,
            sourceId: sourceDocumentId,
            type: res.annotationType,
            start: res.startOffset,
            end: res.endOffset,
            annotatedBy: res.annotatorName,
            annotatedDate: res.annotatedAt,
            value: res.value,
            nativeText: res.nativeText,
            bounds: res.bounds ? res.bounds.bounds : undefined,
            discontinuous: res.discontinuous,
            discontinuousSegments: res.discontinuousSegments
              ? AnnotationSegment.mapFromResource(res.discontinuousSegments)
              : undefined,
            predictionId: res.predictionId,
          },
        ];
      }),
    );
    return newMap;
  },

  mapToResource(
    annotations: Map<string, Annotation> | Annotation[],
  ): AnnotationResource[] {
    return map(Array.from(annotations.values()), annotation => {
      const segments = annotation.discontinuousSegments;

      return {
        clientId: annotation.id.toString(),
        annotationType: annotation.type,
        startOffset: annotation.start,
        endOffset: annotation.end,
        annotatorName: annotation.annotatedBy,
        value: annotation.value,
        nativeText: annotation.nativeText,
        annotatedAt: annotation.annotatedDate,
        bounds: annotation.bounds ? { bounds: annotation.bounds } : undefined,
        discontinuous: !!annotation.discontinuous,
        discontinuousSegments: segments
          ? AnnotationSegment.mapToResource(segments)
          : undefined,
        predictionId: annotation.predictionId,
        isMetadata: !!annotation.isMetadata,
      };
    });
  },

  /**
   * Validates annotation. More or less a sanity check to ensure
   * data coming from endpoints will not break the application.
   * @param annotation
   *
   * @returns string Error(s)
   */
  validateObject(annotation: Annotation): string[] | null {
    const errors: string[] = [];
    if (!annotation.id) {
      errors.push('Missing id.');
    }

    return errors.length ? errors : null;
  },

  /**
   * 1) Maps annotation resources to Annotations.
   * @param resources
   * @param annotations
   */
  mapAndFilterResources(
    resources: AnnotationResource[],
    annotationTypes: AnnotationTypesByKey,
    sourceDocumentId: number,
  ): Map<string, Annotation> {
    return Annotation.mapFromResources(
      resources,
      annotationTypes,
      sourceDocumentId,
    );
  },

  /**
   * Returns a unique list of annotation types associated with the
   * passed array of annotations.
   */
  mapTypes(annotations: Annotation[]): string[] {
    return chain(annotations)
      .map('type')
      .uniqBy('id')
      .value();
  },

  /**
   * Returns a standardized html class applied to this annotation within
   * text.
   * @param annotation
   */
  htmlClass(annotationId: string): string {
    return `annotation-${annotationId}`;
  },

  /**
   * Does the annotation exist in the text or was it added manually
   * (or differently)?
   * Does so by hecking whether it has a valid text offset.
   * @param annotation
   */
  isTextAnnotation(annotation: AnnotationMetadata): boolean {
    return typeof annotation.start === 'number';
  },

  /**
   * Does the passed annotation have highlights?
   * @param atn
   */
  hasHighlights(atn: AnnotationMetadata) {
    return atn.discontinuous || Annotation.hasValidOffsets(atn);
  },

  /**
   * Maps an annotation into an array of highlights for that annotation.
   * @param atn
   */
  mapToUserHighlightProp(
    atn: AnnotationMetadataWithType,
    activeSchema: AnnotationSchema,
  ): UserHighlightProp[] {
    const annotationTypes = activeSchema.annotationTypes;
    const getTypeByKey = (key: string) =>
      annotationTypes.find(t => t.key === key) || AnnotationType.Unknown(key);
    const annotationType = getTypeByKey(atn.type);
    if (!Annotation.hasHighlights(atn)) {
      return [];
    }

    if (!atn.discontinuous) {
      // Single, continuous annotation.
      return [
        {
          start: atn.start,
          end: atn.end,
          color: annotationType.color,
          data: atn,
          htmlClass: Annotation.htmlClass(atn.id),
        } as UserHighlightProp,
      ];
    } else {
      // Discontinuous annotation with multiple highlight ranges.
      if (!atn.discontinuousSegments) {
        console.error(
          `Discontinuous annotation missing segments. Annotation Id: ${atn.id}`,
        );
        return [];
      }

      return atn.discontinuousSegments.filter(Annotation.hasValidOffsets).map(
        (seg, idx) =>
          ({
            start: seg.start,
            end: seg.end,
            color: annotationType.color,
            data: atn,
            htmlClass: `${Annotation.htmlClass(atn.id)}-${idx}`,
          } as UserHighlightProp),
      );
    }
  },

  getParents(
    id: string,
    annotations: Map<string, Annotation>,
    relations: Map<string, AnnotationRelation>,
  ): Annotation[] {
    return this.prependParents(id, annotations, relations).slice(0, -1);
  },

  prependParents(
    id: string,
    annotations: Map<string, Annotation>,
    relations: Map<string, AnnotationRelation>,
  ): Annotation[] {
    const annotation = annotations.get(id)!;
    const current = annotation ? [annotation] : [];
    const parentId: string | undefined = find(
      Array.from(relations.values()),
      r1 => r1.left.id === id,
    )?.right.id;
    const parent: Annotation | undefined = parentId
      ? annotations.get(parentId)!
      : undefined;
    return !!parent && !!parentId
      ? this.prependParents(parentId, annotations, relations).concat(current)
      : current;
  },

  getChildren(
    id: string,
    annotations: Map<string, Annotation>,
    relations: Map<string, AnnotationRelation>,
  ): Annotation[][] {
    return map(this.appendChildren(id, annotations, relations), c =>
      c.slice(1),
    );
  },

  appendChildren(
    id: string,
    annotations: Map<string, Annotation>,
    relations: Map<string, AnnotationRelation>,
  ): Annotation[][] {
    const annotation = annotations.get(id)!;
    const current = annotation ? [annotation] : [];
    const childRels = Array.from(relations.values()).filter(
      r1 => r1.right.id === id,
    );
    const childIds: string[] = map(childRels, c => c.left.id);
    const children: Annotation[] = filter(
      map(childIds, i => annotations.get(i)!),
      c => !!c,
    );
    const grandChildren: Annotation[][] = flatMap(children, c =>
      this.appendChildren(c.id, annotations, relations),
    );
    return children.length > 0
      ? map(grandChildren, g => current.concat(g))
      : [current];
  },

  getDenormalizedRows(
    annotation: Annotation,
    annotations: Map<string, Annotation>,
    relations: Map<string, AnnotationRelation>,
  ): Annotation[][] {
    const parents = this.getParents(
      annotation.id,
      annotations,
      relations,
    ).reverse();
    const rootNodes = parents.concat([annotation]);
    const children = this.getChildren(annotation.id, annotations, relations);
    const denormalized: Annotation[][] =
      children.length > 0
        ? map(children, c => rootNodes.concat(c))
        : [rootNodes];
    return denormalized;
  },

  hasHighlight(a: AnnotationMetadata) {
    return a.start !== undefined || a.discontinuous;
  },

  isInView(a: AnnotationMetadata, pagesInView: number[]) {
    const pages = (a.bounds || []).map(b => b.page);
    return intersection(pages, pagesInView).length > 0;
  },

  shouldRenderHighlights(a: AnnotationMetadata, pagesInView: number[]) {
    return this.hasHighlight(a) && this.isInView(a, pagesInView);
  },
};

export const AnnotationSegment = {
  mapToResource(segments: AnnotationSegment[]) {
    return {
      segments: segments.map(seg => ({
        startOffset: seg.start as number,
        endOffset: seg.end as number,
        nativeText: seg.nativeText,
        bounds: seg.bounds,
      })),
    };
  },

  mapFromResource(resource: AnnotationSegmentsResource) {
    if (!resource.segments || !isArray(resource.segments)) {
      console.error(
        'Malformed discontinuous annotation resource. Unable to parse annotation segments',
      );
      return [];
    }

    return resource.segments.map(seg => ({
      start: seg.startOffset,
      end: seg.endOffset,
      nativeText: seg.nativeText,
      bounds: seg.bounds,
    }));
  },
};
