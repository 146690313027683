import { Dictionary, keyBy, map, sortBy, values } from 'lodash';
import React from 'react';
import {
  Permission,
  PermissionActions,
  PermissionResource,
  PermissionResources,
} from '../../accounts/models/permission.model';

import { SimpleRole } from '../../accounts/models/simple-role.model';
import PermissionTable from '../containers/permission-table.container';
import { SimpleRoleOverride } from './permission-table.component';
import { DocumentSet } from '../../document-sets/models/document-set.model';
import { Queue } from '../../queues/models/queue.model';
import { Export as ExportType } from '../../search/models/export.model';
import { PredictionModel } from '../../predictions/models/prediction-model.model';
import { Collapse } from 'antd';
import { AnnotationSchema } from '../../annotations/models/annotation-schema.model';
import { DomainLookup } from '../../domain/models/lookup-context.model';

export type PermissionTablesDispatchProps = {
  fetchLookups: () => unknown;
};

export type PermissionTablesProps = {
  documentSets: DocumentSet[];
  schemas: AnnotationSchema[];
  queues: Queue[];
  exports: ExportType[];
  predictionModels: PredictionModel[];
  domainLookups: DomainLookup[];
  userPermissions: Permission[];
  targetPermissions: Permission[];
  targetPermissionOverrides: PermissionOverride[];
  onSaveSimpleRoles: (roles: SimpleRole[]) => any;
} & PermissionTablesDispatchProps;

export type PermissionOverride = {
  permission: Permission;
  overriddenBy: string;
};

type ResourceValue = {
  id: number;
  title: string;
};

const { Panel } = Collapse;

export const PermissionTables = (props: PermissionTablesProps) => {
  const [targetSimpleRoles, setTargetSimpleRoles] = React.useState(
    roles(props.targetPermissions),
  );

  const targetSimpleRoleOverrides: SimpleRoleOverride[] = props.targetPermissionOverrides
    .map(o => {
      const role = SimpleRole.fromPermission(o.permission);
      return { role, overriddenBy: o.overriddenBy };
    })
    .filter(o => !!o.role && !!o.overriddenBy)
    .map(o => o as SimpleRoleOverride);

  const userRoles = roles(props.userPermissions);

  React.useEffect(reset, [props.targetPermissions]);

  React.useEffect(() => {
    props.fetchLookups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fetchLookups]);

  function reset() {
    setTargetSimpleRoles(roles(props.targetPermissions));
  }

  function roles(permissions: Permission[]) {
    return SimpleRole.fromPermissions(permissions);
  }

  function userAdminResources(
    resourceType: PermissionResource,
    resources: Dictionary<ResourceValue>,
  ) {
    const isGlobalAdmin = props.userPermissions.filter(
      p =>
        p.resource === PermissionResources.All &&
        p.action === PermissionActions.All,
    );
    const adminRoles = userRoles
      .filter(r => r.resource === resourceType)
      .filter(
        r =>
          isGlobalAdmin ||
          r.action === PermissionActions.Admin ||
          r.action === PermissionActions.All,
      );
    const adminResourceIds = map(adminRoles, r => +r.resourceId);
    const adminResources = values(resources).filter(
      r => isGlobalAdmin || adminResourceIds.indexOf(r.id) >= 0,
    );

    const sortedAdminResources = sortBy(adminResources, d =>
      d.title.toLowerCase(),
    );
    return sortedAdminResources;
  }

  const {
    Admin,
    All,
    Annotate,
    Clear,
    Delete,
    Download,
    Export,
    Import,
    Modify,
    Read,
    Review,
    Upload,
    UseModel,
    Write,
  } = PermissionActions;

  const documentSets = keyBy(
    map([...new Set(props.documentSets)], d => {
      return { id: d.documentSetId, title: d.name };
    }),
    'id',
  );

  const schemas = keyBy(
    map([...new Set(props.schemas)], d => {
      return { id: d.annotationSchemaId, title: d.title };
    }),
    'id',
  );

  const queues = keyBy(
    map(props.queues, d => {
      return { id: d.queueId, title: d.queueName };
    }),
    'id',
  );

  const exports = keyBy(props.exports, 'id');
  const predictionModels = keyBy(
    map(props.predictionModels, d => {
      return { id: d.modelId, title: d.modelName };
    }),
    'id',
  );

  const domainLookups = keyBy(
    map(props.domainLookups, l => {
      return { id: l.domainLookupId, title: l.name };
    }),
    'id',
  );

  const onSaveTargetSimpleRoles = (r: SimpleRole[]) => {
    props.onSaveSimpleRoles(r);
    setTargetSimpleRoles(r);
  };

  return (
    <>
      <Collapse bordered={false}>
        <Panel header="Document Sets" key="1">
          <PermissionTable
            targetRoles={targetSimpleRoles}
            targetRoleOverrides={targetSimpleRoleOverrides}
            resources={userAdminResources(
              PermissionResources.DocumentSet,
              documentSets,
            )}
            onSaveSimpleRoles={onSaveTargetSimpleRoles}
            resourceType={PermissionResources.DocumentSet}
            actions={[
              Read,
              Download,
              Export,
              Import,
              Annotate,
              Review,
              Admin,
              All,
            ]}
          ></PermissionTable>
        </Panel>
        <Panel header="Schemas" key="2">
          <PermissionTable
            targetRoles={targetSimpleRoles}
            targetRoleOverrides={targetSimpleRoleOverrides}
            resources={userAdminResources(PermissionResources.Schema, schemas)}
            onSaveSimpleRoles={onSaveTargetSimpleRoles}
            resourceType={PermissionResources.Schema}
            actions={[Read, Modify, Delete, Admin, All]}
          ></PermissionTable>
        </Panel>
        <Panel header="Queues" key="3">
          <PermissionTable
            targetRoles={targetSimpleRoles}
            targetRoleOverrides={targetSimpleRoleOverrides}
            resources={userAdminResources(PermissionResources.Queue, queues)}
            onSaveSimpleRoles={onSaveTargetSimpleRoles}
            resourceType={PermissionResources.Queue}
            actions={[Read, Write, Admin, All]}
          ></PermissionTable>
        </Panel>
        <Panel header="Exports" key="4">
          <PermissionTable
            targetRoles={targetSimpleRoles}
            targetRoleOverrides={targetSimpleRoleOverrides}
            resources={userAdminResources(PermissionResources.Export, exports)}
            onSaveSimpleRoles={onSaveTargetSimpleRoles}
            resourceType={PermissionResources.Export}
            actions={[Read, Admin, All]}
          ></PermissionTable>
        </Panel>
        <Panel header="Prediction Models" key="5">
          <PermissionTable
            targetRoles={targetSimpleRoles}
            targetRoleOverrides={targetSimpleRoleOverrides}
            resources={userAdminResources(
              PermissionResources.PredictionModel,
              predictionModels,
            )}
            onSaveSimpleRoles={onSaveTargetSimpleRoles}
            resourceType={PermissionResources.PredictionModel}
            actions={[UseModel, Admin, All]}
          ></PermissionTable>
        </Panel>
        <Panel header="Domain Lookups" key="6">
          <PermissionTable
            targetRoles={targetSimpleRoles}
            targetRoleOverrides={targetSimpleRoleOverrides}
            resources={userAdminResources(
              PermissionResources.DomainLookup,
              domainLookups,
            )}
            onSaveSimpleRoles={onSaveTargetSimpleRoles}
            resourceType={PermissionResources.DomainLookup}
            actions={[Read, Modify, Upload, Clear, Delete, Admin, All]}
          ></PermissionTable>
        </Panel>
      </Collapse>
    </>
  );
};
