import { createAction } from 'typesafe-actions';
enum Types {
  OPEN_DOCUMENT_SELECTOR = 'OPEN_DOCUMENT_SELECTOR',
  CLOSE_DOCUMENT_SELECTOR = 'CLOSE_DOCUMENT_SELECTOR',
  TOGGLE_DOCUMENT_SELECTOR = 'TOGGLE_DOCUMENT_SELECTOR',
}

export const openDocumentSelector = createAction(Types.OPEN_DOCUMENT_SELECTOR);

export const closeDocumentSelector = createAction(
  Types.CLOSE_DOCUMENT_SELECTOR,
);

export const toggleDocumentSelector = createAction(
  Types.TOGGLE_DOCUMENT_SELECTOR,
);
