import React from 'react';
import { connect } from 'react-redux';
import { fetchAvailableDocumentStatuses } from '../../annotations/actions/available-document-status.actions';
import { AppState } from '../../app-state';
import { PrimaryButton } from '../../common/components/primary-button.component';
import { fetchDocuments } from '../actions/document-list.actions';
import { toggleDocumentSelector } from '../actions/document-selector.actions';

export const OpenDocumentSelectorComponent = (props: {
  onClick: () => void;
}) => (
  <PrimaryButton onClick={() => props.onClick()}>Open Document</PrimaryButton>
);

export const OpenDocumentSelectorButton = connect(
  (_: AppState) => ({}),
  dispatch => ({
    onClick: () => {
      dispatch(fetchDocuments());
      dispatch(toggleDocumentSelector());
      dispatch(fetchAvailableDocumentStatuses());
    },
  }),
)(OpenDocumentSelectorComponent);
