import { Dictionary, keyBy } from 'lodash';
import { getType } from 'typesafe-actions';
import {
  fetchDocumentSetUploadPolicyAsync,
  populateDocumentSetList,
} from '../../document-sets/actions/document-sets.actions';
import { DocumentSetsActions } from '../../document-sets/actions/document-sets.types';
import DocumentSetUploadPolicy from '../../document-sets/models/document-set-upload-policy.model';
import { DocumentSet } from '../../document-sets/models/document-set.model';

export type DocumentSetsState = {
  entities: Dictionary<DocumentSet>;
  uploadPolicies: Dictionary<DocumentSetUploadPolicy>;
};

const defaultState = {
  entities: {},
  uploadPolicies: {},
};

export function documentSetsReducer(
  state: DocumentSetsState = defaultState,
  action: DocumentSetsActions,
): DocumentSetsState {
  switch (action.type) {
    case getType(populateDocumentSetList): {
      const documentSets = action.payload.documentSets;

      return {
        ...state,
        entities: keyBy(documentSets, 'documentSetId'),
      };
    }
    case getType(fetchDocumentSetUploadPolicyAsync.success):
      return {
        ...state,
        uploadPolicies: {
          ...state.uploadPolicies,
          [action.payload.documentSetId]: action.payload.uploadPolicy,
        },
      };

    default:
      return state;
  }
}
