import { Button } from 'antd';
import styled from 'styled-components';
import { Orange1 } from '../colors';

export const PrimaryButton = styled(Button as any)`
  &.ant-btn {
    background-color: ${Orange1};
    color: white;
    border: none;
    border-radius: 0;
  }
`;
