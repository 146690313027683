import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select } from 'antd';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import React from 'react';
import { ExternalGroup } from '../../accounts/models/external-group.model';
import { AddNewUserPayload } from '../../admin/models/add-new-user-payload.model';
import { PrimaryButton } from '../../common/components/primary-button.component';
import { StateWithSetter } from '../../common/types/state-with-setter.type';
import { validateForm } from '../../common/utils/validateForm';

export type CreateUserFormProps = {
  form: WrappedFormUtils<any>;
  groups: ExternalGroup[] | null;
  usernameState: StateWithSetter<string>;
  userEmailState: StateWithSetter<string>;
  isSubmitting: boolean;
  usernameCheckResults: JSX.Element;
  emailCheckResults: JSX.Element;
  addNewUserAccount: (payload: AddNewUserPayload) => void;
};

const { Item } = Form;
const CreateUserForm: React.FC<CreateUserFormProps> = ({
  form,
  groups,
  isSubmitting,
  usernameState,
  userEmailState,
  addNewUserAccount,
  usernameCheckResults,
  emailCheckResults,
}) => {
  const [username, setUsername] = usernameState;
  const [userEmail, setUserEmail] = userEmailState;
  const createNewUserAccount = validateForm<AddNewUserPayload>(
    form,
    addNewUserAccount,
    true,
  );
  const { getFieldDecorator } = form;
  const { Option } = Select;
  return (
    <Form onSubmit={createNewUserAccount} style={{ width: '100%' }}>
      <Item label="Email">
        {getFieldDecorator('email', {
          rules: [
            {
              required: true,
              message: 'Email is required.',
              pattern: /\S+@\S+\.\S+/g,
            },
          ],
        })(
          <Input
            placeholder="Email"
            suffix={userEmail === '' ? <></> : emailCheckResults}
            onBlur={e => {
              setUserEmail(e.target.value);
            }}
          />,
        )}
      </Item>
      <div style={{ display: 'flex' }}>
        <Item
          style={{ flexFlow: 'row nowrap', width: '50%' }}
          label="First Name"
        >
          {getFieldDecorator('first_name', {
            rules: [
              {
                required: true,
                message: 'Please input your First Name.',
              },
            ],
          })(<Input placeholder="First Name" />)}
        </Item>
        <Item
          style={{ flexFlow: 'row nowrap', width: '50%', paddingLeft: 15 }}
          label="Last Name"
        >
          {getFieldDecorator('last_name', {
            rules: [
              {
                required: true,
                message: 'Please confirm your password.',
              },
            ],
          })(<Input placeholder="Last Name" />)}
        </Item>
      </div>
      <Item label="Username">
        {getFieldDecorator('username', {
          rules: [
            {
              required: true,
              message: 'Username is required.',
            },
            {
              required: true,
              message:
                'Username can only contain lowercase letters, numbers, or (.)',
              pattern: /^[a-z0-9.]*$/g,
            },
          ],
        })(
          <Input
            suffix={username === '' ? <></> : usernameCheckResults}
            onBlur={e => {
              setUsername(e.target.value);
            }}
            placeholder="Username"
          />,
        )}
      </Item>
      <Item label="Group">
        {getFieldDecorator('groups', {
          rules: [
            {
              required: true,
              message: 'Please add the user to a group.',
            },
          ],
        })(
          <Select
            optionFilterProp="children"
            filterOption={(input, option) => {
              const children = option?.children || '';
              return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            placeholder="Add to Group"
            mode="multiple"
          >
            {groups
              ? groups.map((group: ExternalGroup) => (
                  <Option key={group.groupId} value={group.groupId}>
                    {group.groupName}
                  </Option>
                ))
              : ''}
          </Select>,
        )}
      </Item>
      <PrimaryButton style={{ borderRadius: 8 }} htmlType="submit">
        <LegacyIcon type={isSubmitting ? 'loading' : 'user-add'} /> Create User
      </PrimaryButton>
    </Form>
  );
};

export const CreateUserFormComponent = Form.create<CreateUserFormProps>()(
  CreateUserForm,
);
