import { Observable } from 'rxjs';
import { AjaxResponse, ajax } from 'rxjs/ajax';
import { map as observableMap } from 'rxjs/operators';
import { AppState } from '../../app-state';
import { CONTENT_TYPE_HEADERS, authHeaders } from '../../common/utils/fetch';
import { config } from '../../config/application.config';
import { GroupAccount } from '../models/group-account.model';
import { SimpleRole } from '../models/simple-role.model';

export interface GroupAccountResource {
  groupAccount: GroupAccount;
}

export interface GroupAccountsResource {
  groupAccounts: GroupAccount[];
}

export const GroupAccountResource = {
  accountUrl: `${config.annotationService.url}/group-account`,
  allAccountsUrl: `${config.annotationService.url}/group-accounts`,

  putSimpleRolesUrl(groupId: number) {
    return `${this.accountUrl}/${groupId}/simple-roles`;
  },

  getAll(state: AppState): Observable<GroupAccountsResource> {
    return ajax.getJSON<GroupAccountsResource>(
      this.allAccountsUrl,
      authHeaders(state),
    );
  },

  putSimpleRoles(
    groupId: number,
    roles: SimpleRole[],
    state: AppState,
  ): Observable<SimpleRole[]> {
    return ajax
      .put(
        this.putSimpleRolesUrl(groupId),
        { roles },
        {
          ...authHeaders(state),
          ...CONTENT_TYPE_HEADERS.JSON,
        },
      )
      .pipe(observableMap((_: AjaxResponse) => roles));
  },
};
