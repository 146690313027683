import { map } from 'lodash';
import { Observable } from 'rxjs';
import { AjaxResponse, ajax } from 'rxjs/ajax';
import { map as mapObs } from 'rxjs/operators';
import { AppState } from '../../app-state';
import { CONTENT_TYPE_HEADERS, authHeaders } from '../../common/utils/fetch';
import { config } from '../../config/application.config';
import {
  DomainLookup,
  DomainLookupValue,
} from '../models/lookup-context.model';
import { Annotation } from '../../annotations/models/annotation.model';

export const LookupResource = {
  lookupsUrl: `${config.annotationService.url}/lookups`,
  lookupIdUrl: (lookupId: number) => `${LookupResource.lookupsUrl}/${lookupId}`,
  updateLookupUrl: (lookupId: number) =>
    `${LookupResource.lookupIdUrl(lookupId)}/update`,

  clearLookupUrl: (lookupId: number) =>
    `${LookupResource.lookupIdUrl(lookupId)}/clear`,

  uploadLookupUrl: (lookupId: number) =>
    `${LookupResource.lookupIdUrl(lookupId)}/upload`,

  lookupUrl: (schemaId: number) => {
    return `${config.annotationService.url}/lookup/${schemaId}`;
  },

  getAllLookups(state: AppState): Observable<DomainLookup[]> {
    const lookups: Observable<{ lookups: DomainLookup[] }> = ajax.getJSON(
      LookupResource.lookupsUrl,
      authHeaders(state),
    );
    return lookups.pipe(mapObs(e => e.lookups));
  },

  createLookup(
    state: AppState,
    name: string,
    lookupType: string,
    groupId: number,
  ): Observable<DomainLookup> {
    const gId = +groupId;
    const payload = {
      name,
      lookupType,
      groupId: gId,
    };
    return ajax
      .post(this.lookupsUrl, payload, {
        ...authHeaders(state),
        ...CONTENT_TYPE_HEADERS.JSON,
      })
      .pipe(mapObs((r: AjaxResponse) => r.response));
  },

  updateLookup(
    state: AppState,
    lookup: DomainLookup,
  ): Observable<DomainLookup> {
    return ajax
      .post(this.updateLookupUrl(lookup.domainLookupId), lookup, {
        ...authHeaders(state),
        ...CONTENT_TYPE_HEADERS.JSON,
      })
      .pipe(mapObs((r: AjaxResponse) => r.response));
  },

  deleteLookup(state: AppState, lookupId: number) {
    return ajax.delete(this.lookupIdUrl(lookupId), {
      ...authHeaders(state),
    });
  },

  clearLookup(state: AppState, lookupId: number) {
    const url = this.clearLookupUrl(lookupId);
    return ajax.post(
      url,
      {},
      {
        ...authHeaders(state),
      },
    );
  },

  lookup(
    state: AppState,
    schemaId: number,
    annotations: Annotation[][],
  ): Observable<DomainLookupValue[]> {
    const annObj = map(annotations, a =>
      a.reduce((c, n) => {
        const key = n.type;
        return { ...c, [key]: n.value };
      }, {}),
    );

    const body = { annotations: annObj };
    const results: Observable<{
      lookupResults: DomainLookupValue[];
    }> = ajax({
      url: LookupResource.lookupUrl(schemaId),
      method: 'POST',
      body: body,
      headers: { ...authHeaders(state), ...CONTENT_TYPE_HEADERS.JSON },
      responseType: 'json',
    }).pipe(mapObs(e => e.response));
    return results.pipe(mapObs(e => e.lookupResults));
  },
};
