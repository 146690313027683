import * as d3 from 'd3';
import 'd3-selection-multi';
import React from 'react';
import { uniqueId } from '../../../common/utils/random-id';

const BOX_RADIUS = 6;
interface Rect {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface RectSelectorProps {
  onSelect: (rect: Rect) => any;
  divRef?: (ref: HTMLDivElement | null) => any;
}
export class RectSelector extends React.PureComponent<RectSelectorProps> {
  private id = uniqueId('rect-svg-');
  private svg: d3.Selection<SVGSVGElement, {}, HTMLElement, any> | null = null;
  private rectAnchor: { x: number; y: number } | null = null;
  private selectionRect: Rect | null = null;

  onMouseDown: d3.ValueFn<d3.ContainerElement, {}, void> = (_, i, c) => {
    const elm = c[i];
    const p = d3.mouse(elm);
    this.selectionRect = { x: p[0], y: p[1], width: 0, height: 0 };
    this.rectAnchor = { x: p[0], y: p[1] };

    if (this.svg) {
      this.svg.append('rect').attrs({
        class: 'selection',
        rx: BOX_RADIUS,
        ry: BOX_RADIUS,
        ...this.selectionRect,
      });
    }
  };

  onMouseMove: d3.ValueFn<d3.ContainerElement, {}, void> = (_, i, c) => {
    if (!this.svg) {
      return;
    }
    const elm = c[i];
    const s = this.svg.select('rect.selection');
    if (s.empty() || !this.rectAnchor) {
      return;
    }
    const pos = d3.mouse(elm);

    this.selectionRect = {
      x: pos[0] < this.rectAnchor.x ? pos[0] : this.rectAnchor.x,
      y: pos[1] < this.rectAnchor.y ? pos[1] : this.rectAnchor.y,
      width: Math.abs(pos[0] - this.rectAnchor.x),
      height: Math.abs(pos[1] - this.rectAnchor.y),
    };

    s.attrs({ ...this.selectionRect });
  };

  onMouseUp = () => {
    if (this.selectionRect) {
      this.props.onSelect(this.selectionRect);
    }

    if (this.svg) {
      this.svg.select('.selection').remove();
    }

    this.selectionRect = null;
    this.rectAnchor = null;
  };

  componentDidMount() {
    this.svg = d3
      .select<SVGSVGElement, {}>(`#${this.id}`)
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .on('mousedown', this.onMouseDown)
      .on('mousemove', this.onMouseMove)
      .on('mouseup', this.onMouseUp);
  }

  render() {
    return (
      <div
        id={this.id}
        ref={this.props.divRef}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: '0',
          left: '0',
        }}
      />
    );
  }
}
