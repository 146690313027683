import { connect } from 'react-redux';
import { fetchAllGroupAccounts } from '../../accounts/actions/groups.actions';
import {
  selectGroupAccountsArray,
  selectGroupsAreLoading,
} from '../../accounts/selectors/groups.selectors';
import { AppState } from '../../app-state';
import { GroupTableComponent } from '../components/group-table.component';

export const GroupTable = connect(
  (state: AppState) => ({
    groupAccounts: selectGroupAccountsArray(state),
    loading: selectGroupsAreLoading(state),
  }),
  dispatch => ({
    onInit: () => dispatch(fetchAllGroupAccounts()),
  }),
)(GroupTableComponent);
