import { Dropdown, Menu } from 'antd';
import { has } from 'lodash';
import React from 'react';
import { SecondaryButton } from '../../common/components/secondary-button.component';
import { KeyValueMap } from '../../common/types/KeyValueMap.type';
import { Queue } from '../models/queue.model';
import { QueueStatusResponse } from '../resources/queues.resource';
import { QueueSummary } from './queue-summary.component';

type QueueSelectorProps = {
  queues: Queue[];
  selectedQueue: Queue | null;
  onQueueSelect: (q: Queue) => void;
  onClick: () => void;
  queueStatuses: KeyValueMap<QueueStatusResponse | null>;
};

export class QueueSelector extends React.PureComponent<QueueSelectorProps> {
  onSelect = (q: Queue) => () => {
    this.props.onQueueSelect(q);
  };

  menu = (queues: Queue[]) => (
    <Menu>
      {queues.map(q => (
        <Menu.Item onClick={this.onSelect(q)} key={q.queueId}>
          <QueueSummary
            queue={q}
            queueStatus={resolveQueueStatus(
              this.props.queueStatuses,
              q.queueId,
            )}
          />
        </Menu.Item>
      ))}
    </Menu>
  );

  render() {
    const { queues, selectedQueue, queueStatuses, onClick } = this.props;
    return (
      <Dropdown trigger={['click']} overlay={this.menu(queues)}>
        <SecondaryButton onClick={onClick} style={{ height: '100%' }}>
          {!!selectedQueue ? (
            <QueueSummary
              queue={selectedQueue}
              queueStatus={resolveQueueStatus(
                queueStatuses,
                selectedQueue.queueId,
              )}
            />
          ) : (
            <span>Select Queue</span>
          )}
        </SecondaryButton>
      </Dropdown>
    );
  }
}

function resolveQueueStatus(
  statuses: KeyValueMap<QueueStatusResponse | null>,
  queueId: number,
): QueueStatusResponse | null | {} {
  return has(statuses, queueId) ? statuses[queueId] : {};
}
