import { filter, flatMap, flatten, map } from 'lodash';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { EMPTY, from, of } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import {
  catchError,
  concatMap,
  filter as filterObs,
  map as mapObs,
  mapTo,
  mergeMap,
  switchMap,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AppState } from '../../app-state';
import { handleEpicError } from '../../common/utils/epics';
import { AppActions } from '../../root.actions';
import {
  addLookupAnnotationIds,
  addLookupValues,
  clearLookup,
  createLookup,
  deleteLookup,
  fetchLookupValues,
  fetchLookups,
  fetchNullKeyLookupValues,
  setLookupAnnotationIds,
  setLookupValues,
  setLookups,
  updateLookup,
} from '../actions/lookup-context.actions';
import { DomainLookupValue } from '../models/lookup-context.model';
import { LookupResource } from '../resources/lookup.resource';
import { validateAnnotations } from '../../annotations/actions/annotations.actions';
import { selectAnnotationsArray } from '../../annotations/selectors/annotation.selectors';
import { AppEpic } from '../../common/types/app-epic.type';
import { refreshActiveUserAccount } from '../../accounts/actions/users.actions';
import { selectActiveSchemaAnnotationType } from '../../annotations/selectors/annotation-schema.selectors';

export function fetchLookupValuesEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filterObs(isActionOf(fetchLookupValues)),
    switchMap(action => {
      const schemaId = action.payload.schemaId;
      const state = state$.value;
      const lookups = state.annotationSchemas.activeSchema?.lookups || [];
      const lookupAnnotationTypes = flatMap(lookups, l => l.keys);
      const rows = action.payload.annotations;
      // const currentLookupAnnotationIds = state.lookupContext.lookupAnnotationIds;
      const filteredRows = map(rows, annotations => {
        //const containsNewAnnotation = some(annotations, a =>
        //  every(currentLookupAnnotationIds, i => i !== a.id),
        //);
        return filter(
          annotations,
          a =>
            // todo (leads to missed annotations): containsNewAnnotation &&
            lookupAnnotationTypes.indexOf(a.type) !== -1,
        );
      });
      const nonEmptyRows = [
        ...new Set(filter(filteredRows, r => r.length > 0)),
      ];

      const lookupAnnotationIds = map(flatten(rows), a => a.id);

      if (nonEmptyRows.length === 0) {
        return EMPTY;
      } else {
        return LookupResource.lookup(state$.value, schemaId, nonEmptyRows).pipe(
          mergeMap((vs: DomainLookupValue[]) =>
            of(
              addLookupValues(vs),
              addLookupAnnotationIds(lookupAnnotationIds),
            ),
          ),
          catchError((error: AjaxError) => {
            const errorMessage = 'Failed to retrieve lookup values';
            return handleEpicError(errorMessage)(error);
          }),
        );
      }
    }),
  );
}

export function fetchNullKeyLookupValuesEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filterObs(isActionOf(fetchNullKeyLookupValues)),
    switchMap(action => {
      const schemaId = action.payload.schemaId;
      const state = state$.value;
      return LookupResource.lookup(state, schemaId, []).pipe(
        mergeMap((vs: DomainLookupValue[]) => of(addLookupValues(vs))),
        catchError((error: AjaxError) => {
          const errorMessage = 'Failed to retrieve null-key lookup values';
          return handleEpicError(errorMessage)(error);
        }),
      );
    }),
  );
}

export function addLookupValuesEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filterObs(isActionOf(addLookupValues)),
    mapObs(action => {
      const state = state$.value;
      const currentLookupValues = state.lookupContext.lookupValues;
      const lookupValues = action.payload.lookupValues.concat(
        currentLookupValues,
      );
      return setLookupValues(lookupValues);
    }),
  );
}

export function setLookupValuesEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filterObs(isActionOf(setLookupValues)),
    concatMap(action => {
      const state = state$.value;
      const currentAnnotations = selectAnnotationsArray(state);
      const annotationsToValidate = currentAnnotations.filter(a => {
        const validators = selectAnnotationType(state, a.type).validatorConfigs;
        return a.isValid === undefined && validators.length > 0;
      });
      return from([validateAnnotations(annotationsToValidate)]);
    }),
  );
}

export function addLookupAnnotationIdsEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filterObs(isActionOf(addLookupAnnotationIds)),
    mapObs(action => {
      const state = state$.value;
      const currentIds = state.lookupContext.lookupAnnotationIds;
      const ids = [...new Set(currentIds.concat(action.payload.ids))];
      return setLookupAnnotationIds(ids);
    }),
  );
}

export function fetchLookupsEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filterObs(isActionOf(fetchLookups)),
    switchMap(action => {
      return LookupResource.getAllLookups(state$.value).pipe(
        mapObs(ls => setLookups(ls)),
        catchError((error: AjaxError) => {
          const errorMessage = 'Failed to retrieve lookups';
          return handleEpicError(errorMessage)(error);
        }),
      );
    }),
  );
}

export const createLookupEpic: AppEpic = (action$, state$) => {
  return action$.pipe(
    filterObs(isActionOf(createLookup)),
    switchMap(a => {
      const { title, lookupType, groupId } = a.payload;
      const state = state$.value;
      return LookupResource.createLookup(state, title, lookupType, groupId);
    }),
    concatMap(e => of(refreshActiveUserAccount(), fetchLookups())),
    catchError(handleEpicError('Error creating lookup')),
  );
};

export const updateLookupEpic: AppEpic = (action$, state$) => {
  return action$.pipe(
    filterObs(isActionOf(updateLookup)),
    switchMap(a => {
      const state = state$.value;
      return LookupResource.updateLookup(state, a.payload.lookup);
    }),
    mapObs(fetchLookups),
    catchError(handleEpicError('Error updating lookup')),
  );
};

export function deleteLookupEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filterObs(isActionOf(deleteLookup)),
    switchMap(({ payload: { lookupId } }) =>
      LookupResource.deleteLookup(state$.value, lookupId).pipe(
        mapTo(fetchLookups()),
        catchError(
          handleEpicError('Failed to delete lookup. Please try again later.'),
        ),
      ),
    ),
  );
}

export function clearLookupEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filterObs(isActionOf(clearLookup)),
    switchMap(({ payload: { lookupId } }) =>
      LookupResource.clearLookup(state$.value, lookupId).pipe(
        mapTo(fetchLookups()),
        catchError(
          handleEpicError('Failed to clear lookup. Please try again later.'),
        ),
      ),
    ),
  );
}

const selectAnnotationType = selectActiveSchemaAnnotationType;
