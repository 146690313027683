/**
 * Represents a continuous range of selected text within a full text document.
 */
export type AnnotationSelection = {
  text: string;
  start?: number;
  end?: number;
};

export type AnnotationSelectionContext = 'pdf-viewer' | 'text-viewer';

export const AnnotationSelection = {
  /**
   * Returns continuous groups of annotation selections from the passed elements.
   * These elements *must* be raw html elements created from the TextSegment component.
   * This method relies on the offset attributes added by TextSegment.
   * @param elm
   */
  fromElements(elms: HTMLElement[], fullText: string): AnnotationSelection[] {
    const cnt = elms.length;

    if (cnt <= 0) {
      return [];
    }

    elms.sort(sortByStartOffset).filter(elementValid);

    if (elms.length !== cnt) {
      console.error(
        'Invalid elements found & filtered when creating annotation selection.',
      );
    }

    const selections: AnnotationSelection[] = [];
    const firstStart = getStartOffset(elms[0]);
    const firstEnd = getEndOffset(elms[0]);
    let currentSel = {
      start: firstStart,
      end: firstEnd,
      text: fullText.slice(firstStart, firstEnd),
    };

    for (let i = 1; i < elms.length; i++) {
      const last = elms[i - 1];
      const current = elms[i];
      if (last.nextElementSibling === current) {
        currentSel.end = getEndOffset(current);
        currentSel.text = fullText.slice(currentSel.start, currentSel.end);
      } else {
        selections.push(currentSel);
        const start = getStartOffset(current);
        const end = getEndOffset(current);
        currentSel = {
          start,
          end,
          text: fullText.slice(start, end),
        };
      }
    }

    selections.push(currentSel);

    return selections;
  },
};

/**
 * Sorts elements with text offsets by their starting offset.
 * @param e1
 * @param e2
 */
export function sortByStartOffset(e1: HTMLElement, e2: HTMLElement): number {
  return getStartOffset(e1) - getStartOffset(e2);
}

export function elementValid(elm: HTMLElement): boolean {
  const start = elm.attributes['data-start-offset'];
  const end = elm.attributes['data-end-offset'];

  return (
    start &&
    end &&
    !isNaN(parseInt(start.value, 10)) &&
    !isNaN(parseInt(end.value, 10))
  );
}

export function getStartOffset(e1: HTMLElement): number {
  const attr = e1.attributes['data-start-offset'];
  if (!attr) {
    console.error(
      'Missing start offset in element. Only elements created by the TextSegment component have text offsets.',
    );
    return 0;
  }

  return parseInt(attr.value, 10);
}

export function getEndOffset(e1: HTMLElement): number {
  const attr = e1.attributes['data-end-offset'];
  if (!attr) {
    console.error(
      'Missing end offset in element. Only elements created by the TextSegement component have text offsets.',
    );
    return 0;
  }

  return parseInt(attr.value, 10);
}
