import { getType } from 'typesafe-actions';
import {
  cancelExportJob,
  deleteExportJob,
  markDownloadComplete,
  markDownloading,
  refreshExportProgress,
  setExportError,
  setExternalJobId,
  submitExportJob,
} from '../actions/export.actions';
import {
  Export,
  ExportJob,
  ExportProgress,
  ExportStatus,
  exportJob,
} from '../models/export.model';
import { ExportAction } from '../actions/export.types';
import { KeyValueMap } from '../../common/types/KeyValueMap.type';
import { keyBy } from 'lodash';
import { omit } from 'lodash';

const exports = [
  {
    id: 1,
    title: 'ML Training Data Export',
    params: { output: 'json', strategy: 'default', limit: 5000 },
  },
  {
    id: 2,
    title: 'Oseberg Excel Export',
    params: { output: 'xlsx', strategy: 'run-sheet', limit: 5000 },
  },
  {
    id: 3,
    title: 'Image Export',
    params: { output: 'pdf', strategy: 'default', limit: 500 },
  },
  {
    id: 4,
    title: 'Blackacre Data Export',
    params: { output: 'csv', strategy: 'dba', limit: 5000 },
  },
  {
    id: 5,
    title: 'Blackacre Image Export',
    params: { output: 'pdf', strategy: 'dba', limit: 1000 },
  },
  {
    id: 6,
    title: 'SWD Permit Data Export',
    params: { output: 'csv', strategy: 'swd', limit: 5000 },
  },
  {
    id: 7,
    title: 'NM OCD Data Export',
    params: { output: 'xlsx', strategy: 'nm-ocd', limit: 5000 },
  },
  {
    id: 8,
    title: 'BPO Commingle Export',
    params: { output: 'csv', strategy: 'bpo-commingle', limit: 5000 },
  },
  {
    id: 9,
    title: 'BPO Docket Export',
    params: { output: 'csv', strategy: 'bpo-docket', limit: 5000 },
  },
  {
    id: 10,
    title: 'BPO Plugging Export',
    params: { output: 'csv', strategy: 'bpo-plugging', limit: 5000 },
  },
  {
    id: 11,
    title: 'BPO Spud Export',
    params: { output: 'csv', strategy: 'bpo-spud', limit: 5000 },
  },
  {
    id: 12,
    title: 'BPO Transfer Export',
    params: { output: 'csv', strategy: 'bpo-transfer', limit: 5000 },
  },
  {
    id: 13,
    title: 'BPO Completion Export',
    params: { output: 'csv', strategy: 'bpo-completion', limit: 5000 },
  },
  {
    id: 14,
    title: 'BPO Change File Export',
    params: { output: 'csv', strategy: 'bpo-change-file', limit: 5000 },
  },
  {
    id: 15,
    title: 'BPO Respondent Export',
    params: { output: 'csv', strategy: 'bpo-respondent', limit: 5000 },
  },
  {
    id: 16,
    title: 'BPO Form Export',
    params: { output: 'csv', strategy: 'bpo-form', limit: 5000 },
  },
  {
    id: 17,
    title: 'BPO Zone Export',
    params: { output: 'csv', strategy: 'bpo-zone', limit: 5000 },
  },
  {
    id: 18,
    title: 'Time Sheet Export',
    params: { output: 'xlsx', strategy: 'time-sheet', limit: 500000 },
  },
];
export const defaultExportState: ExportState = {
  exports: keyBy(exports, 'id'),
  jobs: {},
};

export type ExportState = {
  exports: KeyValueMap<Export>;
  jobs: KeyValueMap<ExportJob>;
};

export function exportsReducer(
  state: ExportState = defaultExportState,
  action: ExportAction,
): ExportState {
  const actionPayload = action.payload;
  const actionJobId = actionPayload ? actionPayload.jobId : undefined;
  if (!actionJobId) return state;
  switch (action.type) {
    case getType(submitExportJob):
      return setJob(
        state,
        exportJob(
          action.payload.jobId,
          action.payload.query,
          action.payload.params,
        ),
      );
    case getType(setExternalJobId):
      return updateJob(state, actionJobId, {
        externalJobId: action.payload.externalJobId,
      });
    case getType(setExportError):
      return updateExportStatus(state, actionJobId, {
        error: action.payload.error,
        pending: false,
        completed: false,
      });
    case getType(refreshExportProgress):
      const progress = action.payload.progress;
      const pending =
        action.payload.progress.current !== action.payload.progress.total;
      return updateExportStatus(state, actionJobId, { pending, progress });
    case getType(markDownloading):
      return updateExportStatus(state, actionJobId, { downloading: true });
    case getType(markDownloadComplete):
      return updateExportStatus(state, actionJobId, {
        completed: true,
        pending: false,
        downloading: false,
      });
    case getType(cancelExportJob):
      console.debug('Canceling export job');
      return updateExportStatus(state, actionJobId, { pending: false });
    case getType(deleteExportJob):
      console.debug('Deleting export job');
      return deleteJob(state, actionJobId);
    default:
      return state;
  }
}

export function updateExportProgress(
  state: ExportState,
  jobId: string,
  updates: object,
) {
  const job = state.jobs[jobId];
  if (job) {
    const currentExportProgress = job.status.progress;
    const newExportProgress = { ...currentExportProgress, ...updates };
    return setExportProgress(state, jobId, newExportProgress);
  } else return state;
}

export function setExportProgress(
  state: ExportState,
  jobId: string,
  progress: ExportProgress,
) {
  return updateExportStatus(state, jobId, { progress });
}

export function updateExportStatus(
  state: ExportState,
  jobId: string,
  updates: object,
) {
  const job = state.jobs[jobId];
  if (job) {
    const currentExportStatus = job.status;
    const newExportStatus = { ...currentExportStatus, ...updates };
    return setExportStatus(state, jobId, newExportStatus);
  } else return state;
}

export function setExportStatus(
  state: ExportState,
  jobId: string,
  status: ExportStatus,
) {
  return updateJob(state, jobId, { status });
}

export function updateJob(state: ExportState, jobId: string, updates: object) {
  const currentJob = state.jobs[jobId];
  if (currentJob) {
    const newJob = { ...currentJob, ...updates };
    return setJob(state, newJob);
  } else return state;
}

export function setJob(state: ExportState, job: ExportJob) {
  const jobs = state.jobs;
  const jobId = job.jobId;
  return {
    exports: state.exports,
    jobs: {
      ...jobs,
      [jobId]: {
        ...jobs[jobId],
        ...job,
      },
    },
  };
}

export function deleteJob(state: ExportState, jobId: string) {
  return { exports: state.exports, jobs: omit(state.jobs, jobId) };
}
