import { ActionsObservable, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { AjaxError } from 'rxjs/internal-compatibility';
import {
  catchError,
  concatMap,
  delay,
  filter,
  map,
  merge,
  mergeMap,
  switchMap,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AppState } from '../../app-state';
import { handleEpicError } from '../../common/utils/epics';
import { AppActions } from '../../root.actions';
import {
  downloadExport,
  markDownloadComplete,
  markDownloading,
  refreshExportJob,
  refreshExportProgress,
  setExportError,
  setExternalJobId,
  submitExportJob,
} from '../actions/export.actions';
import { ExportAction } from '../actions/export.types';
import { ExportJob } from '../models/export.model';
import { defaultExportProgress } from '../models/export.model';
import { SearchResource } from '../resources/search.resource';
import { selectExportJob } from '../selectors/export.selectors';

export function submitExportJobEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(submitExportJob)),
    switchMap(action => {
      const payload = action.payload;
      const state = state$.value;
      const jobId = payload.jobId;
      const query = payload.query;
      const exportParams = payload.params;
      const output = exportParams.output;
      return SearchResource.submitExportJob(state)(query, exportParams).pipe(
        map(externalJobId => {
          console.debug(`Export job submitted: ${externalJobId}`);
          return setExternalJobId(jobId, externalJobId);
        }),
        catchError((error: AjaxError) => {
          console.debug('Failed to submit export');
          const err = `An unknown error occurred during ${output} download`;
          return handleEpicError(err, () => setExportError(jobId, err))(error);
        }),
      );
    }),
  );
}

export function refreshExportJobEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(refreshExportJob)),
    validateJob(state$),
    map(action => {
      const payload = action.payload;
      const job = action.job;
      const jobId = payload.jobId;
      const params = job.params;
      const query = job.query;
      return submitExportJob(jobId, query, params);
    }),
  );
}

export function setExternalJobIdEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(setExternalJobId)),
    map(action => {
      const jobId = action.payload.jobId;
      const externalJobId = action.payload.externalJobId;
      console.debug(`Setting external job id: ${externalJobId}`);
      const progress = defaultExportProgress;
      return refreshExportProgress(jobId, progress);
    }),
    catchError((error: AjaxError) => {
      const err = 'An unknown error occurred during export.';
      console.debug(`Failed to set job id`);
      return handleEpicError(err)(error);
    }),
  );
}

const REFRESH_PROGRESS_EVERY_MS = 1000;
export function refreshExportProgressEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(refreshExportProgress)),
    delay(REFRESH_PROGRESS_EVERY_MS),
    validateJob(state$),
    filter(a => exportPending(a.job)),
    mergeMap(action => {
      const payload = action.payload;
      const jobId = payload.jobId;
      const externalJobId = action.job.externalJobId;
      console.debug(`Refreshing export job progress: ${externalJobId}`);
      return SearchResource.exportProgress(externalJobId).pipe(
        map(progress => refreshExportProgress(jobId, progress)),
        catchError((error: AjaxError) => {
          console.debug(`Failed to refresh export progress: ${jobId}`);
          const err = 'An unknown error occurred during export.';
          return handleEpicError(err, () => setExportError(jobId, err))(error);
        }),
      );
    }),
  );
}

export function downloadExportEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(downloadExport)),
    validateJob(state$),
    filter(a => !exportDownloading(a.job)),
    mergeMap(action => {
      const payload = action.payload;
      const job = action.job;
      const jobId = payload.jobId;
      const externalJobId = job.externalJobId;
      console.debug(`Downloading export: ${externalJobId}`);
      const exportParams = job.params;
      const downloadKey = exportParams
        ? `${exportParams.output}_${exportParams.strategy}`
        : 'search';
      const fileName = `${downloadKey}_export.zip`;
      const download = SearchResource.downloadExport(
        externalJobId,
        fileName,
      ).pipe(
        map(response => {
          const status = response.status;
          const error =
            status !== 200
              ? `Export download error, got status ${response.status}`
              : null;
          console.error(error);
          const newAction = error
            ? setExportError(jobId, error)
            : markDownloadComplete(jobId);
          return newAction;
        }),
        catchError((error: AjaxError) => {
          console.debug(`Failed to download export: ${jobId}`);
          const err = 'An unknown error occurred during export download.';
          return handleEpicError(err, () => setExportError(jobId, err))(error);
        }),
      );
      return of(markDownloading(jobId)).pipe(merge(download));
    }),
  );
}

export function setJobPayload(state: AppState, action: ExportAction) {
  const payload = action.payload;
  const job = selectExportJob(state)(payload.jobId)!;
  const externalJobId = job ? job.externalJobId : null;
  const jobPayload = externalJobId ? { ...job, externalJobId } : null;
  return jobPayload ? { payload, job: jobPayload } : null;
}

export function exportDownloading(job: ExportJob) {
  return job.status.downloading;
}

export function exportPending(job: ExportJob) {
  return job.status.pending;
}

export function validateJob(state$: StateObservable<AppState>) {
  return concatMap((action: ExportAction) =>
    of(action).pipe(
      map(a => setJobPayload(state$.value, a)),
      filter(a => !!a),
      map(a => a!),
    ),
  );
}
