import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AppEpic } from '../../common/types/app-epic.type';
import { handleEpicError } from '../../common/utils/epics';
import {
  changeSearchResultOrder,
  pageChange,
  populateResponse,
  searchFailure,
  submitSearch,
} from '../actions/search.actions';
import { SearchResource } from '../resources/search.resource';

export const searchEpic: AppEpic = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf([submitSearch, pageChange, changeSearchResultOrder])),
    switchMap(_ => {
      const state = state$.value;

      return SearchResource.query(state).pipe(
        map(r => populateResponse(r)),
        catchError(handleEpicError('Error Running Search', searchFailure)),
      );
    }),
  );
};
