import React, { useState } from 'react';
import styled from 'styled-components';
import { useInterval } from '../../common/hooks/use-interval.hook';
import { PdfPageControlsContainer } from '../../documents/containers/pdf-page-controls.container';
import { PdfViewerContainer } from '../../documents/containers/pdf-viewer.container';

const panelMargin = 0;
const pdfControlsSize = 42;
const InnerPanel = styled.div`
  margin: ${panelMargin}px;
  width: 100%;
  height: 100%;
`;
const PdfPageControlsWrapper = styled.div`
  position: sticky;
  z-index: 1;
  top: 0;
  width: calc(100% - ${panelMargin * 2}px);
`;
const PaneContent = styled.div`
  width: calc(100% - ${panelMargin * 2}px);
  height: calc(100% - ${panelMargin * 2}px);

  /* If second child, shares the pane with a toolbar. */
  &:nth-child(2) {
    height: calc(100% - ${pdfControlsSize + panelMargin}px);
  }
`;
const ScrollableContent = styled(PaneContent)`
  overflow: scroll;
`;

type PdfViewerPanelProps = {
  size: {
    height: number;
    width: number;
  };
};

export const PdfViewerPanel = ({ size }: PdfViewerPanelProps) => {
  const [pdfRenderSize, setPdfRenderSize] = useState(size);

  // Debounce updates to render width so as user resizes, pdf doesn't render
  // every frame.
  useInterval(() => {
    setPdfRenderSize(size);
  }, 1000);

  return (
    <InnerPanel>
      <PdfPageControlsWrapper>
        <PdfPageControlsContainer />
      </PdfPageControlsWrapper>
      <ScrollableContent>
        <PdfViewerContainer size={pdfRenderSize} />
      </ScrollableContent>
    </InnerPanel>
  );
};
