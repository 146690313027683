import styled from 'styled-components';
import { ErrorColor } from '../colors';
import { PerformantIconButton } from './performant-icon-button.component';
export const RemoveButton = styled(PerformantIconButton)`
  font-size: 16px;
  :hover {
    color: ${ErrorColor};
    cursor: pointer;
  }
`;
