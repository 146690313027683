import { some } from 'lodash';
import { getType } from 'typesafe-actions';
import { AppState } from '../../app-state';
import { AuthActions } from '../../auth/actions/auth.types';
import { KeyValueMap } from '../../common/types/KeyValueMap.type';
import {
  closeDocument,
  fetchAndSetSourceDocument,
  setSourceDocument,
} from '../../documents/actions/source-document.actions';
import { SourceDocumentActions } from '../../documents/actions/source-document.types';
import { PredictionsActions } from '../../predictions/actions/predictions.types';
import {
  fetchAnnotationPages,
  fetchAnnotationPagesError,
} from '../actions/annotation-pages.actions';
import { AnnotationPagesActions } from '../actions/annotation-pages.types';
import {
  appendAnnotations,
  copyAnnotations,
  createAnnotation,
  deleteAnnotation,
  deleteAnnotations,
  fetchAnnotations,
  fetchAnnotationsError,
  replaceAnnotations,
  saveAnnotations,
  saveAnnotationsError,
  saveAnnotationsSuccess,
  setAnnotationSaveEvent,
  setDragTargetId,
  setDragTargetState,
  setLastManualAnnotation,
  setSelectedAnnotations,
  updateAnnotationTypes,
  updateAnnotationValidations,
  updateAnnotationValues,
} from '../actions/annotations.actions';
import { AnnotationsActions } from '../actions/annotations.types';
import { AnnotationSaveEvent } from '../models/annotation-save-event.model';
import { Annotation, AnnotationMetadata } from '../models/annotation.model';
import { AnnotationSchemaActions } from '../actions/annotation-schemas.types';
import { Map } from 'immutable';
import {
  selectAnnotations,
  selectAnnotationsArray,
} from '../selectors/annotation.selectors';
import { DragTargetState } from '../models/drag-target-state.model';
import { selectUsername } from '../../accounts/selectors/users.selectors';

export type AnnotationsState = {
  sourceDocumentId: number | null;
  metadata: Map<string, AnnotationMetadata>;
  values: Map<string, string>;
  types: Map<string, string>;
  validations: Map<string, boolean | undefined>;
  ids: string[];
  error: string | null;
  isSaving: boolean;
  saveEvent: AnnotationSaveEvent | null;
  saveError: string | null;
  lastSaved: number | null;
  loading: boolean;
  lastManualAnnotationId: string | null;
  selectedAnnotations: string[];
  dragTargetId: string | null;
  dragTargetState: DragTargetState;
  clipboard: Map<string, Annotation>;
};

export const defaultAnnotationsState = {
  sourceDocumentId: null,
  ids: [],
  metadata: Map<string, AnnotationMetadata>(),
  values: Map<string, string>(),
  types: Map<string, string>(),
  validations: Map<string, boolean | undefined>(),
  error: null,
  isSaving: false,
  saveEvent: null,
  lastSaved: null,
  saveError: null,
  loading: false,
  lastManualAnnotationId: null,
  selectedAnnotations: [],
  dragTargetId: null,
  dragTargetState: DragTargetState.None,
  clipboard: Map<string, Annotation>(),
};

export function annotationsReducer(
  state: AnnotationsState = defaultAnnotationsState,
  action:
    | AnnotationsActions
    | AnnotationPagesActions
    | AuthActions
    | SourceDocumentActions
    | PredictionsActions
    | AnnotationSchemaActions,
  appState: AppState,
): AnnotationsState {
  switch (action.type) {
    case getType(fetchAnnotations): {
      const newSourceId = action.payload.sourceDocumentId;
      const sourceChanged = newSourceId !== state.sourceDocumentId;
      return {
        ...state,
        sourceDocumentId: newSourceId,
        ids: [],
        metadata: Map<string, AnnotationMetadata>(),
        values: Map<string, string>(),
        types: Map<string, string>(),
        validations: Map<string, boolean | undefined>(),
        isSaving: false,
        saveEvent: null,
        lastSaved: sourceChanged ? null : state.lastSaved,
        saveError: null,
        loading: true,
      };
    }

    case getType(fetchAnnotationsError): {
      return {
        ...state,
        ids: [],
        metadata: Map<string, AnnotationMetadata>(),
        values: Map<string, string>(),
        types: Map<string, string>(),
        validations: Map<string, boolean | undefined>(),
        error: action.payload.error,
        isSaving: false,
        saveEvent: null,
        lastSaved: null,
        saveError: null,
        loading: false,
      };
    }

    case getType(appendAnnotations): {
      const { annotations, sourceDocumentId } = action.payload;
      const newAnnotations = Array.from(annotations.entries());
      const oldAnnotations = Array.from(selectAnnotations(appState).entries());
      const allAnnotations = Map(oldAnnotations.concat(newAnnotations));

      const values = allAnnotations.mapEntries(([id, a]) => [id, a.value]);
      const types = allAnnotations.mapEntries(([id, a]) => [id, a.type]);
      const ids = Array.from(allAnnotations.keys());

      return {
        ...state,
        sourceDocumentId,
        loading: false,
        metadata: allAnnotations,
        values,
        types,
        ids,
      };
    }

    case getType(replaceAnnotations): {
      const { annotations, sourceDocumentId } = action.payload;

      const values = annotations.mapEntries(([id, a]) => [id, a.value]);
      const types = annotations.mapEntries(([id, a]) => [id, a.type]);
      const ids = Array.from(annotations.keys());

      return {
        ...state,
        sourceDocumentId,
        metadata: annotations,
        values,
        types,
        ids,
      };
    }

    case getType(copyAnnotations): {
      const { annotationIds } = action.payload;
      const annotations = selectAnnotationsArray(appState);
      const clipboard = annotations.filter(a =>
        some(annotationIds, i => a.id === i),
      );
      return {
        ...state,
        clipboard: KeyValueMap.usingKey(clipboard, 'id'),
      };
    }

    case getType(createAnnotation): {
      const { annotation, sourceDocumentId } = action.payload;
      const id = annotation.id;

      return {
        ...state,
        sourceDocumentId,
        metadata: state.metadata.set(id, annotation),
        values: state.values.set(id, annotation.value),
        types: state.types.set(id, annotation.type),
        ids: state.ids.concat([id]),
      };
    }

    case getType(setLastManualAnnotation): {
      const id = action.payload.id;
      return {
        ...state,
        lastManualAnnotationId: id,
      };
    }

    case getType(updateAnnotationValues): {
      const ids = Array.from(action.payload.keys());
      const metadata = updateAnnotatedBy(appState, state)(ids);
      return {
        ...state,
        values: state.values.merge(action.payload),
        metadata,
      };
    }

    case getType(updateAnnotationTypes): {
      const ids = Array.from(action.payload.keys());
      const metadata = updateAnnotatedBy(appState, state)(ids);
      return {
        ...state,
        types: state.types.merge(action.payload),
        metadata,
      };
    }

    case getType(updateAnnotationValidations): {
      return {
        ...state,
        validations: state.validations.merge(action.payload),
      };
    }

    case getType(deleteAnnotation): {
      const { annotationId } = action.payload;
      return {
        ...state,
        ids: state.ids.filter(i => i !== annotationId),
        metadata: state.metadata.delete(annotationId),
        values: state.values.delete(annotationId),
        types: state.types.delete(annotationId),
        validations: state.validations.delete(annotationId),
      };
    }

    case getType(deleteAnnotations): {
      const { annotationIds } = action.payload;
      return {
        ...state,
        ids: state.ids.filter(id => !some(annotationIds, i => id === i)),
        metadata: state.metadata.deleteAll(annotationIds),
        values: state.values.deleteAll(annotationIds),
        types: state.types.deleteAll(annotationIds),
        validations: state.validations.deleteAll(annotationIds),
      };
    }

    case getType(setSelectedAnnotations): {
      const annotationIds = action.payload.annotationIds;
      return {
        ...state,
        selectedAnnotations: annotationIds,
      };
    }

    case getType(fetchAnnotationPages):
      return { ...state, error: null };

    case getType(fetchAnnotationPagesError):
      const { error } = action.payload;
      return { ...state, error, loading: false };

    case getType(saveAnnotations):
      return { ...state, isSaving: true, saveError: null };

    case getType(saveAnnotationsSuccess):
      const isSameSource =
        action.payload.sourceDocumentId === state.sourceDocumentId;
      return {
        ...state,
        isSaving: false,
        lastSaved: isSameSource ? action.payload.time : state.lastSaved,
      };

    case getType(saveAnnotationsError):
      return { ...state, isSaving: false, saveError: action.payload.error };

    case getType(setAnnotationSaveEvent):
      return { ...state, saveEvent: action.payload.saveEvent };

    case getType(setDragTargetId):
      return { ...state, dragTargetId: action.payload.dragTargetId };

    case getType(setDragTargetState):
      return { ...state, dragTargetState: action.payload.dragTargetState };

    case getType(closeDocument):
    case getType(fetchAndSetSourceDocument):
    case getType(setSourceDocument):
      return { ...defaultAnnotationsState, clipboard: state.clipboard };

    default:
      return state;
  }
}

const updateAnnotatedBy = (appState: AppState, state: AnnotationsState) => (
  ids: string[],
) => {
  const activeUser = selectUsername(appState) || '';
  const metadata = state.metadata;
  const metadataUpdates = Map(
    ids.map(id => {
      const prevMetadata = metadata.get(id);
      const sameUser = activeUser === prevMetadata?.annotatedBy;
      if (!prevMetadata || sameUser) return [id, null];
      const newMetadata = { ...prevMetadata, annotatedBy: activeUser };
      return [id, newMetadata];
    }),
  )
    .filter(a => !!a)
    .map(a => a!);
  return metadataUpdates.isEmpty() ? metadata : metadata.merge(metadataUpdates);
};
