import * as d3 from 'd3';
import 'd3-selection-multi';
import React from 'react';
import styled from 'styled-components';
import { uniqueId } from '../../../common/utils/random-id';
import { Rect } from '../models/rect.model';

const SvgWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;

  svg {
    fill: rgba(85, 146, 248, 0.5);
  }
`;

/**
 * This components renders an overlay on selected text.
 * Aka. the "blue squares" on top of highlighted text.
 */
interface SelectionOverlayProps {
  selectedRects: Rect[];
}
export class SelectionOverlay extends React.PureComponent<
  SelectionOverlayProps
> {
  private id = uniqueId('sel-overlay-svg-');
  private svg: d3.Selection<SVGSVGElement, {}, HTMLElement, any> | null = null;

  componentWillReceiveProps(nextProps: SelectionOverlayProps) {
    if (this.props.selectedRects !== nextProps.selectedRects) {
      this.renderSvg(nextProps.selectedRects);
    }
  }

  renderSvg(rects: Rect[]) {
    if (this.svg) {
      this.svg.remove();
    }

    this.svg = d3
      .select<SVGSVGElement, {}>(`#${this.id}`)
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%');

    this.svg
      .selectAll('rect')
      .data(rects)
      .enter()
      .append('rect')
      .attr('x', d => d.x)
      .attr('y', d => d.y)
      .attr('height', d => d.height)
      .attr('width', d => d.width);
  }

  render() {
    return <SvgWrap id={this.id} />;
  }
}
