import { createAction } from 'typesafe-actions';
import { DomainLookupValue } from '../models/lookup-context.model';
import { Annotation } from '../../annotations/models/annotation.model';
import { DomainLookup } from '../models/lookup-context.model';

enum Types {
  FETCH_LOOKUP_VALUES = 'Lookup: Fetches all lookup values for active document',
  FETCH_NULL_KEY_LOOKUP_VALUES = 'Lookup: Fetches null-key lookup values (constant value lookups)',
  SET_LOOKUP_VALUES = 'Lookup: sets lookup values for active document',
  ADD_LOOKUP_VALUES = 'Lookup: add lookup values for active document',
  ADD_LOOKUP_ANNOTATION_IDS = 'Lookup: adds annotation ids to lookup annotation ids',
  SET_LOOKUP_ANNOTATION_IDS = 'Lookup: sets annotation ids that have been included in lookup',
  FETCH_LOOKUPS = 'Lookup: Fetches all lookups',
  SET_LOOKUPS = 'Lookup: sets lookups',
  CREATE_LOOKUP = 'Lookup: create lookup table.',
  UPDATE_LOOKUP = 'Lookup: update specified lookup table.',
  DELETE_LOOKUP = 'Lookup: delete specified lookup table.',
  CLEAR_LOOKUP = 'Lookup: clear specified lookup table.',
}

export const fetchLookupValues = createAction(
  Types.FETCH_LOOKUP_VALUES,
  resolvePayload => (schemaId: number, annotations: Annotation[][]) =>
    resolvePayload({ schemaId, annotations }),
);

export const fetchNullKeyLookupValues = createAction(
  Types.FETCH_NULL_KEY_LOOKUP_VALUES,
  resolvePayload => (schemaId: number) => resolvePayload({ schemaId }),
);

export const setLookupValues = createAction(
  Types.SET_LOOKUP_VALUES,
  resolvePayload => (lookupValues: DomainLookupValue[]) =>
    resolvePayload({ lookupValues }),
);

export const addLookupValues = createAction(
  Types.ADD_LOOKUP_VALUES,
  resolvePayload => (lookupValues: DomainLookupValue[]) =>
    resolvePayload({ lookupValues }),
);

export const addLookupAnnotationIds = createAction(
  Types.ADD_LOOKUP_ANNOTATION_IDS,
  resolvePayload => (ids: string[]) => resolvePayload({ ids }),
);

export const setLookupAnnotationIds = createAction(
  Types.SET_LOOKUP_ANNOTATION_IDS,
  resolvePayload => (ids: string[]) => resolvePayload({ ids }),
);

export const fetchLookups = createAction(Types.FETCH_LOOKUPS);

export const setLookups = createAction(
  Types.SET_LOOKUPS,
  resolvePayload => (lookups: DomainLookup[]) => resolvePayload({ lookups }),
);

export const createLookup = createAction(
  Types.CREATE_LOOKUP,
  resolvePayload => (title: string, lookupType: string, groupId: number) =>
    resolvePayload({ title, lookupType, groupId }),
);

export const updateLookup = createAction(
  Types.UPDATE_LOOKUP,
  resolvePayload => (lookup: DomainLookup) => resolvePayload({ lookup }),
);

export const deleteLookup = createAction(
  Types.DELETE_LOOKUP,
  resolvePayload => (lookupId: number) => resolvePayload({ lookupId }),
);

export const clearLookup = createAction(
  Types.CLEAR_LOOKUP,
  resolvePayload => (lookupId: number) => resolvePayload({ lookupId }),
);
