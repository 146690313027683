import { connect } from 'react-redux';
import { selectCurrentUserGroupId } from '../../accounts/selectors/groups.selectors';
import { AppState } from '../../app-state';
import { createLookup } from '../actions/lookup-context.actions';
import {
  LookupCreatorComponent,
  LookupCreatorFormValues,
  LookupCreatorProps,
} from '../components/domain-lookup-creator.component';

export const LookupCreator = connect(
  (state: AppState) => ({
    currentUserGroupId: selectCurrentUserGroupId(state),
  }),
  (dispatch): Pick<LookupCreatorProps, 'onLookupCreate'> => ({
    onLookupCreate: (v: LookupCreatorFormValues) =>
      dispatch(createLookup(v.name, v.lookupType, v.groupId)),
  }),
)(LookupCreatorComponent);
