import React from 'react';
import loader from '../images/Loader.svg';

interface LoaderProps {
  style?: React.CSSProperties;
  className?: string;
}
export class Loader extends React.Component<LoaderProps> {
  render() {
    return (
      // eslint-disable-next-line jsx-a11y/alt-text
      <img
        className={this.props.className}
        src={loader}
        style={{ ...this.props.style }}
      />
    );
  }
}
