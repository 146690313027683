import { getType } from 'typesafe-actions';
import { AppState } from '../../app-state';
import {
  setActiveAdminTab,
  setSelectedGroupAccount,
} from '../actions/ui-admin.actions';
import { UIAdminActions } from '../actions/ui-admin.types';
import { AdminTab, AdminTabs } from '../models/admin-tab.model';

export type UiAdminState = {
  activeTab: AdminTab;
  selectedGroupAccount: number | null;
};

const defaultState = {
  activeTab: AdminTabs.Users,
  selectedGroupAccount: null,
};

export function uiAdminReducer(
  state: UiAdminState = defaultState,
  action: UIAdminActions,
  appState: AppState,
): UiAdminState {
  switch (action.type) {
    case getType(setActiveAdminTab):
      return {
        ...state,
        activeTab: action.payload.tab,
      };

    case getType(setSelectedGroupAccount):
      return {
        ...state,
        selectedGroupAccount: action.payload.groupId || null,
      };

    default:
      return state;
  }
}
