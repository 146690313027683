import { ActionsObservable, StateObservable } from 'redux-observable';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AppState } from '../../app-state';
import { handleEpicError } from '../../common/utils/epics';
import { AppActions } from '../../root.actions';
import {
  fetchDocuments,
  fetchDocumentsError,
  updateDocuments,
} from '../actions/document-list.actions';
import { DocumentSummaryResource } from '../resources/document-summary.resource';

export function documentListEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(fetchDocuments)),
    switchMap(action => {
      const state = state$.value;
      const { sort, page } = state.documentList;
      const documentFilter = state.documentList.filter;
      const recordedDate = documentFilter.recordedDate;
      const createdAt = documentFilter.createdAt;
      const lastAnnotationTimestamp = documentFilter.lastAnnotationTimestamp;
      const lastReviewTimestamp = documentFilter.lastReviewTimestamp;
      const docFilter = {
        ...documentFilter,
        recordedDate: formatDates(recordedDate),
        createdAt: formatDates(createdAt),
        lastAnnotationTimestamp: formatDates(lastAnnotationTimestamp),
        lastReviewTimestamp: formatDates(lastReviewTimestamp),
      };
      return DocumentSummaryResource.search(state)(docFilter)(
        page,
        10,
        sort,
      ).pipe(
        map(docSummary =>
          updateDocuments(
            docSummary.documents,
            docSummary.totalPages,
            docSummary.totalItems,
          ),
        ),
        catchError(
          handleEpicError('Error fetching documents', fetchDocumentsError),
        ),
      );
    }),
  );
}

function formatDates(dates: string[] | null): string | null {
  return dates && dates.join('::');
}
