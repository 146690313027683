import { DeleteOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../app-state';
import DangerButton from '../../common/components/danger-button.component';
import { SecondaryButton } from '../../common/components/secondary-button.component';
import { SetState } from '../../common/types/set-state.type';
import { deleteDocumentAsync } from '../actions/source-document.actions';
import { AnnotatedDocument } from '../models/annotated-document.model';
import { selectActiveSourceDocument } from '../selectors/source-document.selectors';

export type DeleteDocumentButtonComponentProps = {
  document: AnnotatedDocument | null;
  onDelete: (document: AnnotatedDocument) => void;
  setSyncDocumentSet: SetState<boolean>;
  setDrawerOpen: SetState<boolean>;
};

export const DeleteDocumentButtonComponent: React.FC<DeleteDocumentButtonComponentProps> = ({
  document,
  onDelete,
  setSyncDocumentSet,
  setDrawerOpen,
}) => {
  const [modalShowing, setModalShowing] = useState(false);
  return (
    <>
      <DangerButton onClick={() => setModalShowing(!modalShowing)}>
        <DeleteOutlined />
        Delete
      </DangerButton>
      <Modal
        title={
          <>
            <DeleteOutlined /> Delete Document
          </>
        }
        visible={modalShowing}
        onCancel={() => setModalShowing(false)}
        footer={[
          document ? (
            <>
              <DangerButton onClick={() => onDelete(document)}>
                <DeleteOutlined />
                Delete
              </DangerButton>
              <SecondaryButton
                key="cancel"
                onClick={() => setModalShowing(false)}
              >
                Cancel
              </SecondaryButton>
            </>
          ) : (
            <>
              <SecondaryButton
                key="cancel"
                onClick={() => {
                  setModalShowing(false);
                  setSyncDocumentSet(true);
                  setDrawerOpen(false);
                }}
              >
                Close
              </SecondaryButton>
            </>
          ),
        ]}
      >
        {document ? (
          <p>
            Are you sure you want to delete document "{document.aggregateId}"?
          </p>
        ) : (
          <p>Document was successfully deleted.</p>
        )}
      </Modal>
    </>
  );
};

export const DeleteDocumentButton = connect(
  (state: AppState) => ({
    document: selectActiveSourceDocument(state),
  }),
  dispatch => ({
    onDelete: (document: AnnotatedDocument) =>
      dispatch(
        deleteDocumentAsync.request({
          annotatedDocumentId: document.annotatedDocumentId,
          documentSetId: document.documentSetId,
        }),
      ),
  }),
)(DeleteDocumentButtonComponent);
