import React from 'react';
import { PrimaryButton } from '../../common/components/primary-button.component';

export type DeleteQueueButtonProps = {
  queueId: number;
  onDelete: (queueId: number) => void;
};

export const DeleteQueueButton: React.SFC<DeleteQueueButtonProps> = props => {
  return (
    <PrimaryButton onClick={(_: any) => props.onDelete(props.queueId)}>
      Delete Queue
    </PrimaryButton>
  );
};
