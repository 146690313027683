import { LockOutlined } from '@ant-design/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Restricted } from '../../accounts/containers/restricted.container';
import {
  PermissionActions,
  PermissionResources,
} from '../../accounts/models/permission.model';
import { AppRoutes, pathWithParams } from '../../app-routes';
import { AnnotatedDocument } from '../models/annotated-document.model';

const DocumentActionWrapper = styled.span`
  a,
  span {
    padding: 0 8px;

    &:first-child {
      padding-left: 0;
    }

    &:not(:last-child) {
      border-right: 1px solid #ccc;
    }
  }
`;

const LockIcon = styled(LockOutlined)`
  font-size: 20px;
`;

interface DocumentSelectionOptionsProps {
  document: AnnotatedDocument;
  locked: boolean;
  onSelected: () => void;
}

const OpenButton: React.SFC<DocumentSelectionOptionsProps> = props =>
  props.locked ? (
    <span>
      <LockIcon />
    </span>
  ) : (
    <Link
      onClick={props.onSelected}
      to={pathWithParams(AppRoutes.AnnotationPage, {
        id: props.document.annotatedDocumentId,
        action: 'edit',
      })}
    >
      Annotate
    </Link>
  );

export const DocumentSelectionOptions: React.SFC<DocumentSelectionOptionsProps> = (
  props: DocumentSelectionOptionsProps,
) => {
  return (
    <DocumentActionWrapper>
      <Link
        onClick={props.onSelected}
        to={pathWithParams(AppRoutes.AnnotationPage, {
          id: props.document.annotatedDocumentId,
          action: 'view',
        })}
      >
        View
      </Link>
      <Restricted
        required={{
          resource: PermissionResources.DocumentSet,
          action: PermissionActions.Annotate,
        }}
      >
        <OpenButton {...props} />
      </Restricted>
    </DocumentActionWrapper>
  );
};

export enum DocumentSelectionOption {
  Annotate = 'annotate',
  View = 'view',
}
