import {
  Button,
  Card,
  Form,
  Input,
  Popconfirm,
  Select,
  Table,
  Tooltip,
} from 'antd';
import { FormInstance } from 'antd/lib/form';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { LookupCreator } from '../containers/domain-lookup-creator.container';
import { DomainLookup } from '../models/lookup-context.model';
import { DateComponent } from '../../common/components/date.component';
import { tableSearchProps } from '../../common/utils/tableSearchProps';
import { ClearOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  PermissionAction,
  PermissionActions,
} from '../../accounts/models/permission.model';
import { Group } from '../../accounts/models/group.model';
import { map, sortBy } from 'lodash';
import { isEqual } from 'lodash/fp';
import DomainLookupUpload from '../containers/domain-lookup-upload.container';

export type LookupTableDispatchProps = {
  fetchLookups: () => unknown;
  fetchGroups: () => unknown;
  clearLookup: (lookupId: number) => unknown;
  deleteLookup: (lookupId: number) => unknown;
  updateLookup: (lookup: DomainLookup) => unknown;
  isPermitted: (lookupId: number, action: PermissionAction) => boolean;
};

export type LookupTableProps = {
  lookups: DomainLookup[];
  groups: Group[];
  lookupKeys: string[];
} & LookupTableDispatchProps;

const LookupDiv = styled.div`
  overflow: auto;
  max-height: calc(100vh - 76px);
  width: 100%;
  margin-top: 1em;
`;

export const LookupTableComponent: React.FC<LookupTableProps> = props => {
  const [lookups, setLookups] = React.useState(sortLookups(props.lookups));
  const isPermitted = props.isPermitted;

  useEffect(() => {
    props.fetchLookups();
    props.fetchGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fetchLookups, props.fetchGroups]);

  useEffect(() => {
    setLookups(sortLookups(props.lookups));
  }, [props.lookups]);

  function groupName(groupId: number) {
    return props.groups.find(g => g.groupId === groupId)?.groupName;
  }

  function compareStrings(s1?: string, s2?: string) {
    return (s1 || '').localeCompare(s2 || '');
  }

  function handleSave(row: DomainLookup) {
    const newData = [...lookups];
    const index = newData.findIndex(
      item => row.domainLookupId === item.domainLookupId,
    );
    const item = newData[index];

    if (!isEqual(row, item)) {
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      setLookups(newData);
      props.updateLookup(row);
    }
  }

  function renderDeleteButton(lookup: DomainLookup) {
    return (
      <Tooltip title="delete" mouseEnterDelay={0.5}>
        <Popconfirm
          title="Are you sure?"
          onConfirm={() => props.deleteLookup(lookup.domainLookupId)}
          disabled={
            !isPermitted(lookup.domainLookupId, PermissionActions.Delete)
          }
        >
          <Button
            danger={true}
            disabled={
              !isPermitted(lookup.domainLookupId, PermissionActions.Delete)
            }
          >
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      </Tooltip>
    );
  }

  function renderClearButton(lookup: DomainLookup) {
    return (
      <Tooltip title="clear" mouseEnterDelay={0.5}>
        <Popconfirm
          title="Are you sure?"
          onConfirm={() => props.clearLookup(lookup.domainLookupId)}
          disabled={
            !isPermitted(lookup.domainLookupId, PermissionActions.Clear)
          }
        >
          <Button
            danger={true}
            disabled={
              !isPermitted(lookup.domainLookupId, PermissionActions.Clear)
            }
          >
            <ClearOutlined />
          </Button>
        </Popconfirm>
      </Tooltip>
    );
  }

  const columns = [
    {
      title: 'Lookup ID',
      dataIndex: 'domainLookupId',
      rowKey: 'domainLookupId',
      sorter: (a: DomainLookup, b: DomainLookup) =>
        a.domainLookupId - b.domainLookupId,
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      rowKey: 'createdAt',
      render: d => <DateComponent serializedDate={d} />,
      sorter: (a: DomainLookup, b: DomainLookup) =>
        a.createdAt.localeCompare(b.createdAt),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      rowKey: 'name',
      editable: true,
      sorter: (a: DomainLookup, b: DomainLookup) =>
        compareStrings(a.name, b.name),
      ...tableSearchProps('lookupName'),
    },
    {
      title: 'Type',
      dataIndex: 'lookupType',
      editable: true,
      sorter: (a: DomainLookup, b: DomainLookup) =>
        compareStrings(a.lookupType, b.lookupType),
      ...tableSearchProps('lookupType'),
    },
    {
      title: 'Group',
      dataIndex: 'groupId',
      rowKey: 'group',
      sorter: (a, b) =>
        compareStrings(groupName(a.groupId), groupName(b.groupId)),
      editable: true,
      render: gid => groupName(gid) || '-',
    },
    {
      title: 'Keys',
      dataIndex: 'keys',
      editable: true,
      key: 'lookup-keys',
      render: ls => renderArray(ls),
      sorter: (a, b) => a.keys.length - b.keys.length,
    },
    {
      title: 'Values',
      dataIndex: 'values',
      editable: true,
      key: 'lookup-values',
      render: ls => renderArray(ls),
      sorter: (a, b) => a.values.length - b.values.length,
    },
    {
      title: 'Size',
      dataIndex: 'size',
    },
    {
      title: 'Action',
      rowKey: 'action',
      width: '10%',
      render: (_: any, lookup: DomainLookup) => (
        <>
          {renderDeleteButton(lookup)}
          {renderClearButton(lookup)}
          <DomainLookupUpload
            lookupId={lookup.domainLookupId}
            keyColumns={lookup.keys}
            valueColumns={lookup.values}
          />
        </>
      ),
    },
  ];
  const newColumns = columns.map(col => {
    if (!col['editable']) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DomainLookup) => ({
        record,
        editable: col['editable'],
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
        isPermitted: props.isPermitted,
        groups: props.groups,
        lookupKeys: props.lookupKeys,
      }),
    };
  });
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  return (
    <Card style={{ height: '85vh' }}>
      <LookupCreator />
      <LookupDiv>
        <Table
          components={components}
          dataSource={lookups}
          columns={newColumns}
          pagination={false}
          scroll={{ y: '70vh' }}
          size="middle"
          rowKey={(lookup: any) => lookup.domainLookupId}
        />
      </LookupDiv>
    </Card>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof DomainLookup;
  record: DomainLookup;
  handleSave: (record: DomainLookup) => void;
  isPermitted: (id: number, action: PermissionAction) => boolean;
  groups: Group[];
  lookupKeys: string[];
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  isPermitted,
  groups,
  lookupKeys,
  ...restProps
}) => {
  const [editing, setEditing] = React.useState(false);
  const inputRef = React.useRef<Input>(null);
  const oldValue = dataIndex ? record[dataIndex] : undefined;
  const [value, setValue] = React.useState(oldValue);

  const updateValue = (v: any) => {
    const newRecord = { ...record, [dataIndex]: v };
    handleSave(newRecord);
    toggleEdit();
  };

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const ENTER_KEY = 13;
  const ESC_KEY = 27;

  const toggleEdit = () => {
    setEditing(!editing);
  };

  let childNode = children;

  const canEdit =
    editable && isPermitted(record.domainLookupId, PermissionActions.Modify);

  function getInputNode(ref: React.RefObject<any>, index: string) {
    switch (index) {
      case 'groupId': {
        const options = map(groups, g => {
          return { label: g.groupName, value: g.groupId };
        });
        return (
          <Select
            style={{ width: '100%' }}
            ref={ref}
            showAction={['focus', 'click']}
            onChange={updateValue}
            onBlur={_ => toggleEdit()}
            showSearch
            placeholder="Search to Select"
            options={options}
            filterOption={(search, option) => {
              const group = groups.find(g => g.groupId === option?.value);
              const groupName = group?.groupName || '';
              return groupName.toLowerCase().includes(search.toLowerCase());
            }}
          />
        );
      }
      case 'keys':
      case 'values': {
        const options = map(lookupKeys, k => {
          return { value: k };
        });

        const setKeys = (values: string[]) => {
          setValue(values.sort());
        };
        return (
          <Select
            style={{ width: '100%' }}
            value={(value as string[]).sort()}
            ref={ref}
            showAction={['focus', 'click']}
            onChange={setKeys}
            onBlur={e => updateValue(value)}
            onInputKeyDown={k => {
              const code = k.which;
              if (code === ESC_KEY || code === ENTER_KEY) {
                k.stopPropagation();
                updateValue(value);
              }
            }}
            showSearch
            placeholder="Search to Select"
            mode="multiple"
            options={options}
          />
        );
      }
      case 'lookupType': {
        const options = [{ value: 'alias' }, { value: 'validation' }];
        return (
          <Select
            style={{ width: '100%' }}
            value={value}
            ref={ref}
            showAction={['focus', 'click']}
            onChange={updateValue}
            onBlur={_ => toggleEdit()}
            showSearch
            placeholder="Search to Select"
            options={options}
            filterOption={(search, option) => {
              return option?.value.toLowerCase().includes(search.toLowerCase());
            }}
          />
        );
      }
      default: {
        return (
          <Input
            defaultValue={value}
            onChange={e => setValue(e.currentTarget.value)}
            ref={inputRef}
            onPressEnter={e => updateValue(value)}
            onBlur={e => updateValue(value)}
          />
        );
      }
    }
  }

  if (canEdit) {
    childNode = editing ? (
      getInputNode(inputRef, dataIndex)
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const sortLookups = (ls: DomainLookup[]) =>
  sortBy(ls, l => l.domainLookupId).reverse();

function renderArray(arr: string[]) {
  const str = arr.length > 0 ? arr.sort().join(' ') : '-';
  return <div style={{ wordSpacing: '999999px' }}>{str}</div>;
}
