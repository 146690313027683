import { TextSegment } from '../components/text-segment.component';

/**
 * Represents a range of selected text within a full text.
 * Used to normalize a browser's "Selection" to the correct
 * range of selected text within a set of components that
 * use the text-highlighter's TextSegment component.
 */
export interface TextSelection {
  isSelected: boolean;
  text: string;
  startOffset?: number;
  endOffset?: number;
  selection: Selection | null;
}

export const TextSelection = {
  /**
   * Normalizes a Selection object to a TextSelection within
   * a full text body with TextSegment components. This is used
   * when it's important to know the offsets within a full text body
   * that contains multiple components.
   * The character offsets of a text segment relative to the full text
   * exist as data attributes on the text element.
   * @param selection
   * @param fullText
   */
  normalize(selection: Selection, fullText: string): TextSelection {
    if (!selection.anchorNode || !selection.focusNode) {
      return {
        isSelected: false,
        text: selection.toString(),
        selection,
      };
    }

    let anchorOffset = this.normalizeElementSelection(
      selection.anchorOffset,
      this.searchForTextSegmentAncestor(
        this.findTerminalNode(selection.anchorNode).parentElement,
      ),
    );
    let focusOffset = this.normalizeElementSelection(
      selection.focusOffset,
      this.searchForTextSegmentAncestor(
        this.findTerminalNode(selection.focusNode).parentElement,
      ),
    );

    let isSelected = anchorOffset + focusOffset !== -2;

    if (isSelected && anchorOffset === -1) {
      focusOffset = fullText.length - 1;
    } else if (isSelected && focusOffset === -1) {
      anchorOffset = 0;
    }

    const startOffset = Math.min(anchorOffset, focusOffset);
    const endOffset = Math.max(anchorOffset, focusOffset);
    isSelected = isSelected && startOffset !== endOffset;

    return {
      isSelected,
      text: isSelected
        ? fullText.slice(startOffset, endOffset)
        : selection.toString(),
      startOffset,
      endOffset,
      selection,
    };
  },

  /**
   * Crawls down the first child of each node
   * until a terminal node without children is found.
   * @param node
   */
  findTerminalNode(node: Node) {
    let terminalNode = node;
    while (terminalNode.childNodes.length) {
      terminalNode = terminalNode.childNodes[0];
    }

    return terminalNode;
  },

  /**
   * Searches for a TextSegment ancestor element.
   * @param elm
   */
  searchForTextSegmentAncestor(elm: HTMLElement | null) {
    if (!elm || !elm.parentElement || !elm.className) {
      return null;
    }

    let limit = 8;
    const element = null;
    let next: HTMLElement | null = elm;
    while (limit-- > 0 && next && !element) {
      if (!matchElm(elm, TextSegment.elementClass)) {
        next = next.parentElement;
      } else {
        return elm;
      }
    }

    return element;
  },

  normalizeElementSelection(selectionOffset: number, elm: HTMLElement | null) {
    const elmOffset = this.elementOffset(elm);
    return elmOffset === -1 ? elmOffset : elmOffset + selectionOffset;
  },

  elementOffset(elm: HTMLElement | null) {
    if (!elm || !matchElm(elm, TextSegment.elementClass)) {
      return -1;
    }

    return parseInt(elm.dataset.startOffset as string, 10);
  },
};

const matchElm = (elm: HTMLElement | null, str: string) =>
  elm?.className?.match ? elm.className.match(str) : null;
