import { Observable, from, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError } from 'rxjs/operators';
import { AppState } from '../../app-state';
import { CONTENT_TYPE_HEADERS, authHeaders } from '../../common/utils/fetch';
import { config } from '../../config/application.config';
import { GroupScopedTag } from '../models/group-scoped-tag.model';

type BaseAddTagToDocumentsParams = {
  tag: string;
  owningGroupId: number;
};

type EsQueryParams = {
  indices: string;
  query: object;
};

type DocIdParams = {
  annotatedDocumentIds: number[];
};

type BaseRemoveTagFromDocumentsParams = {
  tagId: number;
};

type AddTagToDocumentsParams = BaseAddTagToDocumentsParams & DocIdParams;

type BulkAddTagToDocumentsParams = BaseAddTagToDocumentsParams & EsQueryParams;

type RemoveTagFromDocumentsParams = BaseRemoveTagFromDocumentsParams &
  DocIdParams;

type BulkRemoveTagFromDocumentsParams = BaseRemoveTagFromDocumentsParams &
  EsQueryParams;

export const GroupScopedTagsResource = {
  groupScopedTagsUrl: `${config.annotationService.url}/tags/scoped/group`,

  groupScopedTagsForDocumentUrl: (documentSetId: number) =>
    `${config.annotationService.url}/document/${documentSetId}/tags/scoped/group`,

  getAll(state: AppState) {
    return ajax.getJSON<GroupScopedTag[]>(
      this.groupScopedTagsUrl,
      authHeaders(state),
    );
  },

  getGroupScopedTagsForDocument: (state: AppState) => (
    documentSetId: number,
  ): Observable<GroupScopedTag[]> => {
    return ajax.getJSON<GroupScopedTag[]>(
      GroupScopedTagsResource.groupScopedTagsForDocumentUrl(documentSetId),
      authHeaders(state),
    );
  },

  addTagForDocuments(state: AppState, params: AddTagToDocumentsParams) {
    return ajax.post(this.groupScopedTagsUrl, params, {
      ...authHeaders(state),
      ...CONTENT_TYPE_HEADERS.JSON,
    });
  },

  addTagForDocumentsBulk(state: AppState, params: BulkAddTagToDocumentsParams) {
    return ajax.post(`${this.groupScopedTagsUrl}/bulk`, params, {
      ...authHeaders(state),
      ...CONTENT_TYPE_HEADERS.JSON,
    });
  },

  removeTagForDocuments(
    state: AppState,
    params: RemoveTagFromDocumentsParams,
  ): Observable<{ totalDeleted: number } | { failed: boolean }> {
    const req: RequestInit = {
      method: 'DELETE',
      body: JSON.stringify(params),
      headers: {
        ...authHeaders(state),
        ...CONTENT_TYPE_HEADERS.JSON,
      },
    };
    // have to use fetch here because ajax doesn't accept a
    // body in its delete requests even though it is not
    // prohibited by the spec.
    return from(
      fetch(this.groupScopedTagsUrl, req)
        .then((res: Response) => res.text())
        .then(text => JSON.parse(text)),
    ).pipe(catchError(_ => of({ failed: true })));
  },

  removeTagForDocumentsBulk(
    state: AppState,
    params: BulkRemoveTagFromDocumentsParams,
  ): Observable<{ totalDeleted: number } | { failed: boolean }> {
    const req: RequestInit = {
      method: 'DELETE',
      body: JSON.stringify(params),
      headers: {
        ...authHeaders(state),
        ...CONTENT_TYPE_HEADERS.JSON,
      },
    };
    // have to use fetch here because ajax doesn't accept a
    // body in its delete requests even though it is not
    // prohibited by the spec.
    return from(
      fetch(`${this.groupScopedTagsUrl}/bulk`, req)
        .then((res: Response) => res.text())
        .then(text => JSON.parse(text)),
    ).pipe(catchError(_ => of({ failed: true })));
  },
};
