import { find, flatMap, flatten, forEach, isNumber, map, values } from 'lodash';
import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { EMPTY, from, of } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import {
  catchError,
  concatMap,
  delayWhen,
  filter as filterObs,
  first,
  map as mapObs,
  mergeMap,
  switchMap,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { isArray } from 'util';
import uuid from 'uuid/v4';
import { Permission } from '../../accounts/models/permission.model';
import {
  selectActiveUserAccount,
  selectUsername,
} from '../../accounts/selectors/users.selectors';
import {
  fetchSaveEventReview,
  postAnnotationReview,
} from '../../annotation-review/actions/annotation-review.actions';
import { AppState } from '../../app-state';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from '../../common/actions/notification.actions';
import { AppEpic } from '../../common/types/app-epic.type';
import { KeyValueMap } from '../../common/types/KeyValueMap.type';
import { handleEpicError } from '../../common/utils/epics';
import { setSourceDocument } from '../../documents/actions/source-document.actions';
import { AppActions } from '../../root.actions';
import {
  appendAnnotationRelations,
  createAnnotationRelation,
  deleteAnnotationChildRelations,
} from '../actions/annotation-relations.actions';
import {
  appendAnnotations,
  copyAnnotations,
  copySelectedAnnotations,
  createAnnotation,
  createAnnotationFromSelection,
  createAnnotationFromType,
  deleteAnnotations,
  deleteSelectedAnnotations,
  fetchAnnotations,
  fetchAnnotationsError,
  pasteAnnotations,
  saveAnnotations,
  saveAnnotationsBulkAsync,
  saveAnnotationsError,
  saveAnnotationsSuccess,
  setAnnotationSaveEvent,
  setDragTargetId,
  setDragTargetState,
  setLastManualAnnotation,
  triggerDeleteAnnotations,
  updateAnnotation,
  updateAnnotationTypes,
  updateAnnotationValidations,
  updateAnnotationValues,
  validateAnnotations,
} from '../actions/annotations.actions';
import { AnnotationsActions } from '../actions/annotations.types';
import { AnnotationGroup } from '../models/annotation-group.model';
import { AnnotationDocumentStatus } from '../resources/annotation-document-status.resource';
import {
  AnnotationGroupResource,
  AnnotationKeyLookupFailures,
  NewAnnotationGroupResource,
} from '../resources/annotation-group.resource';
import {
  selectActiveAnnotationSchemaId,
  selectActiveSchemaAnnotationTypes,
  selectAnnotationSchemasExist,
} from '../selectors/annotation-schema.selectors';
import { REVIEWED } from './annotation-document-statuses.epics';
import { Annotation, AnnotationSegment } from '../models/annotation.model';
import { AnnotationType } from '../models/annotation-type.model';
import { AnnotationPage } from '../models/annotation-page.model';
import { fetchLookupValues } from '../../domain/actions/lookup-context.actions';
import { LookupContextActions } from '../../domain/actions/lookup-context.types';
import { AnnotationSchemaValidator } from '../models/annotation-schema-validator.model';
import { AnnotationBounds } from '../models/annotation-bounds.model';
import { AnnotationSelection } from '../models/annotation-selection.model';
import { selectAnnotations } from '../selectors/annotation.selectors';
import {
  selectAnnotationRelations,
  selectAsRelation,
} from '../selectors/annotation-relation.selectors';
import { Map } from 'immutable';
import { DragTargetState } from '../models/drag-target-state.model';
import { RELATION_TYPES } from '../../lib/relations/relation-types';
import { selectAnnotationPagesArray } from '../selectors/annotation-pages.selectors';

export function annotationsEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filterObs(isActionOf(fetchAnnotations)),
    // Delay until schemas are populated in state.
    delayWhen(_ =>
      state$.pipe(filterObs(selectAnnotationSchemasExist), first()),
    ),
    switchMap(action => {
      const {
        payload: { sourceDocumentId },
      } = action;
      return AnnotationGroup.get(state$.value, sourceDocumentId).pipe(
        mergeMap((grp: AnnotationGroup) =>
          from([
            setAnnotationSaveEvent(grp.saveEvent),
            fetchSaveEventReview(grp.saveEvent.annotationSaveEventId),
            appendAnnotationRelations(grp.relations),
            appendAnnotations(grp.annotations, sourceDocumentId),
          ]),
        ),
        catchError((error: AjaxError) => {
          const errorMessage = 'Failed to retrieve annotations';
          return error.status === 404
            ? of(fetchAnnotationsError(errorMessage))
            : handleEpicError(errorMessage, fetchAnnotationsError)(error);
        }),
      );
    }),
  );
}

/**
 * Fetches annotations every time the source document is updated.
 * @param action$
 * @param store
 */
export const fetchAnnotationsOnSourceDocumentUpdate: AppEpic = (
  action$: ActionsObservable<AppActions>,
) => {
  return action$.pipe(
    filterObs(isActionOf(setSourceDocument)),
    mapObs(action =>
      fetchAnnotations(action.payload.document.annotatedDocumentId),
    ),
  );
};

export function saveAnnotationsEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filterObs(isActionOf(saveAnnotations)),
    switchMap(() => {
      const state = state$.value;
      const reviewed =
        state.annotationReview.completed ||
        containsReviewed(state.annotationDocumentStatuses) ||
        false;
      const newGroup = NewAnnotationGroupResource.create(
        selectAnnotations(state),
        selectAnnotationRelations(state),
        selectUsername(state) || 'unknown',
        state.annotations.sourceDocumentId as number,
        state.annotationPages.documentTextId as number,
        reviewed,
      );

      return AnnotationGroup.post(state, newGroup).pipe(
        mergeMap((grp: AnnotationGroup) =>
          from(postSaveActions(grp, reviewed, state$.value)),
        ),
        catchError(
          handleEpicError('Error saving annotations', saveAnnotationsError),
        ),
      );
    }),
  );
}

function postSaveActions(
  annotationGroup: AnnotationGroup,
  reviewed: boolean,
  state: AppState,
) {
  let emitActions: Action[] = [
    saveAnnotationsSuccess(
      annotationGroup.annotatedDocumentId,
      new Date().getTime(),
    ),
  ];
  const fromCurrentSourceDoc =
    annotationGroup.annotatedDocumentId === state.annotations.sourceDocumentId;
  if (fromCurrentSourceDoc) {
    // Only repopulate annotations if user hasn't opened a new document.
    emitActions = [
      ...emitActions,
      setAnnotationSaveEvent(annotationGroup.saveEvent),
    ];
  }

  // This is a little confusing because right now we're combining annotation saves
  // and reviews into one save action/button. Hopefully, reviews will be a separate
  // workflow so that the logic isn't intertwined.
  // After annotations are saved, we have to grab the new save event id and submit
  // a review for that new save event, if the review checkbox is checked.
  // TECH DEBT we used to repopulate the annotations and reviews after saving
  // them, this does not do anything other than update the keys of the
  // annotation and relations objects in state so that they are the serial
  // primary keys from the db - note whenever we save we don't send those in the
  // payload. So right now, by not repopulating, we end up using uuids for keys
  // that do have a serial primary key associated with them, but this does not
  // represent a problem per say. The reason we don't want to repopulate is to
  // preserve state like collapse and expand which utilizes ids to say which
  // ones are collapsed/expanded
  const account = selectActiveUserAccount(state);
  const canReview =
    account && Permission.canReviewDocuments(account.permissions);
  if (canReview && reviewed) {
    emitActions = [
      ...emitActions,
      postAnnotationReview(
        annotationGroup.saveEvent.annotationSaveEventId,
        reviewed,
      ),
    ];
  }

  return emitActions;
}

export const saveAnnotationsBulkEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filterObs(isActionOf(saveAnnotationsBulkAsync.request)),
    switchMap(({ payload: { data, documentSetId } }) => {
      const annotatorName = selectUsername(state$.value) || '';
      const parseCSVRowDataToSubrequest = NewAnnotationGroupResource.createCSVRowDataParser(
        annotatorName,
        documentSetId,
        values(state$.value.annotationSchemas.annotationTypes),
      );

      const requestWithPossibleFailures = map<
        object,
        NewAnnotationGroupResource | AnnotationKeyLookupFailures[]
      >(data, parseCSVRowDataToSubrequest);

      const [failures, request]: [
        AnnotationKeyLookupFailures[],
        NewAnnotationGroupResource[],
      ] = partitionErrors(requestWithPossibleFailures);
      const errorDetails = map(
        failures,
        AnnotationKeyLookupFailures.toErrorMessage,
      );

      if (failures.length > 0) {
        console.error(errorDetails);
        const message =
          'The CSV format is invalid. Please check the list for more details.';
        return of(
          saveAnnotationsBulkAsync.failure({
            status: 'failure',
            errors: {
              failures,
              successCount: 0,
            },
            originalRequest: request,
          }),
          displayErrorNotification(
            'CSV annotation uploaded with errors.',
            message,
          ),
        );
      }

      return AnnotationGroupResource.postBulk(state$.value, request).pipe(
        switchMap(res => {
          if (res.failures.length > 0) {
            if (res.successCount <= 0) {
              const errMessage =
                'The CSV format is invalid. Please check the list for more details.';
              return of(
                saveAnnotationsBulkAsync.failure({
                  status: 'failure',
                  errors: res,
                  originalRequest: request,
                }),
                displayErrorNotification(
                  'CSV annotation successfully uploaded with errors.',
                  errMessage,
                ),
              );
            }
            const message =
              'Your request was completed successfully but had some errors.';
            return of(
              saveAnnotationsBulkAsync.success({
                status: 'partialSuccess',
                errors: res,
                originalRequest: request,
              }),
              displaySuccessNotification(message),
            );
          } else {
            const message =
              'CSV annotations upload complete. Please allow a moment for the annotations to appear.';
            return of(
              saveAnnotationsBulkAsync.success({
                status: 'success',
                errors: {
                  failures: res.failures,
                  successCount: res.successCount,
                },
                originalRequest: request,
              }),
              displaySuccessNotification(message),
            );
          }
        }),
        catchError(handleEpicError('Error uploading CSV')),
      );
    }),
  );

export function deleteSelectedAnnotationsEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filterObs(isActionOf(deleteSelectedAnnotations)),
    mapObs(action => {
      const state = state$.value.annotations;
      const annotationIds = state.selectedAnnotations;
      return triggerDeleteAnnotations(annotationIds);
    }),
  );
}

export function triggerDeleteAnnotationsEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filterObs(isActionOf(triggerDeleteAnnotations)),
    concatMap(action => {
      const state = state$.value;
      const currentAnnotations = selectAnnotations(state);
      const currentRelations = selectAnnotationRelations(state);
      const annotationIdsToDelete = action.payload.annotationIds;
      const annotationsToDelete = map(
        annotationIdsToDelete,
        i => currentAnnotations.get(i)!,
      ).filter(a => !!a);
      const annotations: Annotation[][] = flatMap(annotationsToDelete, a =>
        Annotation.getDenormalizedRows(a, currentAnnotations, currentRelations),
      );
      const schemaId = state.annotationSchemas.activeSchema?.annotationSchemaId;
      const annotationChildren: Annotation[] = flatMap(annotationsToDelete, a =>
        flatten(
          Annotation.getChildren(a.id, currentAnnotations, currentRelations),
        ),
      );
      const validateAnnotationss: AnnotationsActions = validateAnnotations(
        annotationChildren,
      );
      const fetchLookup: LookupContextActions = fetchLookupValues(
        schemaId || 0,
        annotations,
      );
      const actions: Action[] = [
        deleteAnnotations(annotationIdsToDelete),
        fetchLookup,
        validateAnnotationss,
      ];
      return from(actions);
    }),
    catchError((error: AjaxError) => handleEpicError()(error)),
  );
}

export function pasteAnnotationsEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filterObs(isActionOf(pasteAnnotations)),
    concatMap(action => {
      const state = state$.value;
      const annotationState = state.annotations;
      const relationState = state.annotationRelations;
      const activeUser = selectUsername(state);
      const annClipboard = Array.from(annotationState.clipboard.values());
      const annotations = map(annClipboard, a => {
        return {
          ...a,
          annotatedBy: activeUser || a.annotatedBy,
          annotatedDate: new Date().toISOString(),
          id: uuid(),
          oldId: a.id,
          start: undefined,
          end: undefined,
          bounds: undefined,
          discontinuousSegments: undefined,
        };
      });
      const annotationsMap = KeyValueMap.usingKey(annotations, 'id');
      const oldAnnotationsMap = KeyValueMap.usingKey(annotations, 'oldId');
      const relClipboard = Array.from(relationState.clipboard.values());
      const relations = map(relClipboard, r => {
        const left = oldAnnotationsMap.get(r.left.id)!;
        const right = oldAnnotationsMap.get(r.right.id)!;
        const isNew = left && right;
        return { ...r, left, right, isNew, id: uuid() };
      }).filter((r, i) => r.isNew);
      const relationsMap = KeyValueMap.usingKey(relations, 'id');
      console.debug(`pasting ${annotations.length} annotations...`);
      const sourceDocumentId = annotationState.sourceDocumentId;
      const appendNewAnnotations = appendAnnotations(
        annotationsMap,
        sourceDocumentId,
      );
      const appendNewRelations = appendAnnotationRelations(relationsMap);

      return of(appendNewAnnotations, appendNewRelations);
    }),
  );
}

export function copySelectedAnnotationsEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filterObs(isActionOf(copySelectedAnnotations)),
    mapObs(action => {
      const state = state$.value.annotations;
      const annotationIds = state.selectedAnnotations;
      console.debug(`copying ${annotationIds.length} annotations...`);
      return copyAnnotations(annotationIds);
    }),
  );
}

export function updateAnnotationEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  const splitUpdates = (annotations: Annotation[]) => {
    const state = state$.value;
    const currentAnnotations = selectAnnotations(state);
    const prevAndNew = annotations
      .map(updated => {
        const current = currentAnnotations.get(updated.id);
        if (!current) return null;
        else return { updated, current };
      })
      .filter(a => !!a)
      .map(a => a!);
    const valueUpdates = toUpdateMap(prevAndNew, a => a.value);
    const typeUpdates = toUpdateMap(prevAndNew, a => a.type);
    const validationUpdates = toUpdateMap(prevAndNew, a => a.isValid);
    const updateValues = ifNonEmpty(valueUpdates, updateAnnotationValues);
    const updateTypes = ifNonEmpty(typeUpdates, updateAnnotationTypes);
    const updateValidations = ifNonEmpty(
      validationUpdates,
      updateAnnotationValidations,
    );
    const actions: AppActions[] = [updateValues, updateTypes, updateValidations]
      .filter(a => !!a)
      .map(a => a!);
    return actions;
  };
  return action$.pipe(
    filterObs(isActionOf(updateAnnotation)),
    concatMap(a => splitUpdates(a.payload)),
  );
}

export function updateAnnotationValuesEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filterObs(isActionOf(updateAnnotationValues)),
    concatMap(a => {
      const state = state$.value;
      const annotationIds = Array.from(a.payload.keys());
      return validationActions(state, annotationIds);
    }),
    catchError((error: AjaxError) => {
      const errorMessage = 'Failed to update annotation validations';
      return handleEpicError(errorMessage)(error);
    }),
  );
}

export function updateAnnotationTypesEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filterObs(isActionOf(updateAnnotationTypes)),
    concatMap(a => {
      const state = state$.value;
      const annotationIds = Array.from(a.payload.keys());
      return validationActions(state, annotationIds);
    }),
    catchError((error: AjaxError) => {
      const errorMessage = 'Failed to update annotation validations';
      return handleEpicError(errorMessage)(error);
    }),
  );
}

export function createAnnotationFromTypeEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filterObs(isActionOf(createAnnotationFromType)),
    concatMap(action => {
      const state = state$.value;
      const id = uuid();
      const annotation: Annotation = {
        id,
        type: action.payload.type.key,
        annotatedBy: selectUsername(state) || 'unknown',
        value: '',
        annotatedDate: new Date().toISOString(),
        nativeText: '',
      };

      const sourceDocumentId = state.annotations.sourceDocumentId;
      const actions = !!sourceDocumentId
        ? [
            createAnnotation(annotation, sourceDocumentId),
            setLastManualAnnotation(id),
          ]
        : [];
      return from(actions);
    }),
    catchError((error: AjaxError) => {
      const errorMessage = 'Failed to create annotation from type.';
      return handleEpicError(errorMessage)(error);
    }),
  );
}

export function createAnnotationFromSelectionEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filterObs(isActionOf(createAnnotationFromSelection)),
    mapObs(action => {
      const state = state$.value;
      const annotationState = state.annotations;
      const sourceDocumentId = annotationState.sourceDocumentId;
      const selection = state._UI_annotations.selection;
      const annotationPages = selectAnnotationPagesArray(state);
      const userName = selectUsername(state) || 'unknown';
      const annotationType = action.payload.type;
      const annotation = annotationFromSelection(
        annotationType,
        selection,
        annotationPages,
        userName,
      );
      if (!sourceDocumentId || !annotation) {
        return null;
      }
      return createAnnotation(annotation, sourceDocumentId);
    }),
    catchError((error: AjaxError) => {
      const errorMessage = 'Failed to create annotation';
      return handleEpicError(errorMessage)(error);
    }),
  );
}

export function createAnnotationEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filterObs(isActionOf(createAnnotation)),
    concatMap(action => {
      const state = state$.value;
      const annotation = action.payload.annotation;
      if (annotation.value === '') {
        return of();
      }
      const currentAnnotations = selectAnnotations(state);
      const currentRelations = selectAnnotationRelations(state);
      const annotations: Annotation[][] = Annotation.getDenormalizedRows(
        annotation,
        currentAnnotations,
        currentRelations,
      );
      const schemaId = state.annotationSchemas.activeSchema?.annotationSchemaId;
      const validateAnnotationsAction: AnnotationsActions = validateAnnotations(
        [annotation],
      );
      const fetchLookup: LookupContextActions = fetchLookupValues(
        schemaId || 0,
        annotations,
      );
      const actions: Action[] =
        annotations.length > 0 ? [fetchLookup, validateAnnotationsAction] : [];
      return from(actions);
    }),
    catchError((error: AjaxError) => {
      const errorMessage = 'Failed to update annotation validation';
      return handleEpicError(errorMessage)(error);
    }),
  );
}

export function createAnnotationRelationEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filterObs(isActionOf(createAnnotationRelation)),
    concatMap(action => {
      const state = state$.value;
      const currentAnnotations = selectAnnotations(state);
      const currentRelations = selectAnnotationRelations(state);

      const { left, right, relationType } = action.payload;

      const relation = selectAsRelation(state)(left, right, relationType);
      if (!relation) return EMPTY;

      const annotation = currentAnnotations.get(relation.left.id)!;
      const annotations: Annotation[][] = Annotation.getDenormalizedRows(
        annotation,
        currentAnnotations,
        currentRelations,
      );
      const schemaId = state.annotationSchemas.activeSchema?.annotationSchemaId;
      return of(
        fetchLookupValues(schemaId || 0, annotations),
        validateAnnotations([annotation]),
      );
    }),
  );
}

export function deleteAnnotationChildRelationsEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filterObs(isActionOf(deleteAnnotationChildRelations)),
    concatMap(action => {
      const annotationId = action.payload.annotationId;
      const state = state$.value;
      const currentAnnotations = selectAnnotations(state);
      const currentRelations = selectAnnotationRelations(state);
      const annotation = currentAnnotations.get(annotationId);
      if (!annotation) return EMPTY;
      const annotations: Annotation[][] = Annotation.getDenormalizedRows(
        annotation,
        currentAnnotations,
        currentRelations,
      );
      const schemaId = state.annotationSchemas.activeSchema?.annotationSchemaId;
      return of(
        fetchLookupValues(schemaId || 0, annotations),
        validateAnnotations([annotation]),
      );
    }),
  );
}

export function setDragTargetIdEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  const Valid = DragTargetState.Valid;
  const Invalid = DragTargetState.Invalid;
  return action$.pipe(
    filterObs(isActionOf(setDragTargetId)),
    mapObs(action => {
      const state = state$.value;
      const targetId = action.payload.dragTargetId;
      if (!targetId) return setDragTargetState(DragTargetState.None);

      const sourceIds = state.annotations.selectedAnnotations;
      const getDragState = (sourceId: string) => {
        if (sourceId === targetId) return DragTargetState.Self;
        const rule = selectAsRelation(state)(
          sourceId,
          targetId,
          RELATION_TYPES.CHILD_OF,
        )?.rule;
        return rule ? DragTargetState.Valid : Invalid;
      };
      const dragStates = sourceIds.map(getDragState);
      const allValid = dragStates.every(s => s === Valid);
      const validState = allValid ? Valid : Invalid;
      const dragState = dragStates.length === 1 ? dragStates[0] : validState;
      return setDragTargetState(dragState);
    }),
  );
}

export function validateAnnotationsEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filterObs(isActionOf(validateAnnotations)),
    mapObs(action => {
      const annotations = action.payload.annotations;
      const state = state$.value;
      const currentAnnotations = selectAnnotations(state);
      const currentRelations = selectAnnotationRelations(state);
      const lookups = state.annotationSchemas.activeSchema?.lookups || [];
      const lookupValues = state.lookupContext.lookupValues;
      const validations = map(annotations, a => {
        const validators = getAnnotationTypeByKey(state, a.type)
          .validatorConfigs;
        const parents = Annotation.getParents(
          a.id,
          currentAnnotations,
          currentRelations,
        );
        return {
          id: a.id,
          isValid: AnnotationSchemaValidator.validate(
            lookups,
            validators,
            lookupValues,
            a,
            parents,
          ),
        };
      });

      const validationMap: Map<string, boolean | undefined> = Map(
        validations.map(a => [a.id, a.isValid]),
      );

      return updateAnnotationValidations(validationMap);
    }),
  );
}

function annotationFromSelection(
  annotationType: AnnotationType,
  selection: AnnotationSelection[],
  pages: AnnotationPage[],
  userName: string,
): Annotation | null {
  const cnt = selection.length;
  if (cnt === 1) {
    return singleAnnotationFromSelection(
      annotationType,
      selection,
      pages,
      userName,
    );
  } else if (cnt > 1) {
    return discontinuousAnnotationFromSelection(
      annotationType,
      selection,
      pages,
      userName,
    );
  } else {
    return null;
  }
}

function singleAnnotationFromSelection(
  annotationType: AnnotationType,
  selection: AnnotationSelection[],
  annotationPages: AnnotationPage[],
  userName: string,
): Annotation {
  const sel = selection[0];
  let bounds: AnnotationBounds[] | undefined;
  if (isNumber(sel.start) && isNumber(sel.end)) {
    bounds = AnnotationBounds.fromOffsets(sel.start, sel.end, annotationPages);
  }

  const annotation = {
    id: uuid(),
    type: annotationType.key,
    start: sel.start,
    end: sel.end,
    annotatedBy: userName,
    annotatedDate: new Date().toISOString(),
    value: sel.text,
    nativeText: sel.text,
    discontinuous: false,
    bounds,
  };

  return annotation;
}

function discontinuousAnnotationFromSelection(
  annotationType: AnnotationType,
  selection: AnnotationSelection[],
  annotationPages: AnnotationPage[],
  userName: string,
): Annotation {
  const discontinuousSegments: AnnotationSegment[] = selection.map(
    (as: AnnotationSelection) => ({
      start: as.start,
      end: as.end,
      nativeText: as.text,
      bounds:
        isNumber(as.start) && isNumber(as.end)
          ? AnnotationBounds.fromOffsets(as.start, as.end, annotationPages)
          : undefined,
    }),
  );

  // Calculate the bounds for entire annotation.
  const trueStart = Math.min(
    ...selection.map(sel => (isNumber(sel.start) ? sel.start : Infinity)),
  );
  const trueEnd = Math.max(
    ...selection.map(sel => (isNumber(sel.end) ? sel.end : -Infinity)),
  );
  let bounds: AnnotationBounds[] | undefined;
  if (isNumber(trueStart) && isNumber(trueEnd)) {
    bounds = AnnotationBounds.fromOffsets(trueStart, trueEnd, annotationPages);
  }

  const segmentTexts = selection.map(s => s.text);

  const annotation: Annotation = {
    id: uuid(),
    type: annotationType.key,
    annotatedBy: userName,
    annotatedDate: new Date().toISOString(),
    value: segmentTexts.join(' '),
    nativeText: segmentTexts.join(''),
    discontinuous: true,
    discontinuousSegments,
    bounds,
  };

  return annotation;
}

function containsReviewed(
  annotationDocumentStatuses: AnnotationDocumentStatus[],
) {
  return !!find(annotationDocumentStatuses, x => x.status === REVIEWED);
}

// I had to write this special version of "partition" myself
// because the typings available for that function in lodash
// were simply not strong enough to express these concepts in types
function partitionErrors(
  requestsAndFailures: (
    | NewAnnotationGroupResource
    | AnnotationKeyLookupFailures[]
  )[],
): [AnnotationKeyLookupFailures[], NewAnnotationGroupResource[]] {
  const failures: AnnotationKeyLookupFailures[][] = [];
  const successes: NewAnnotationGroupResource[] = [];
  forEach(requestsAndFailures, v => {
    if (isArray(v) && AnnotationKeyLookupFailures.isFailure(v[0])) {
      failures.push(v);
    } else if (!isArray(v)) {
      successes.push(v);
    }
  });
  return [flatten(failures), successes];
}

function getAnnotationTypeByKey(state: AppState, key: string) {
  const annotationTypes = selectActiveSchemaAnnotationTypes(state) || [];
  return (
    annotationTypes.find(t => t.key === key) || AnnotationType.Unknown(key)
  );
}

type PrevAndNew = { current: Annotation; updated: Annotation };
function toUpdateMap<T>(
  prevAndNew: PrevAndNew[],
  updateOn: (Annotation) => T,
): Map<string, T> {
  const updates = prevAndNew
    .filter(a => updateOn(a.updated) !== updateOn(a.current))
    .map(a => a.updated);
  return Map(updates.map(a => [a.id, updateOn(a)]));
}

function ifNonEmpty<K, V, A>(m: Map<K, V>, f: (m: Map<K, V>) => A) {
  return m.size > 0 ? f(m) : null;
}

const annotationsToLookup = (state: AppState) => (annotationId: string) => {
  const currentAnnotations = selectAnnotations(state);
  const currentRelations = selectAnnotationRelations(state);
  const annotation = currentAnnotations.get(annotationId);
  if (!annotation) return [];

  const annotations: Annotation[][] = Annotation.getDenormalizedRows(
    annotation,
    currentAnnotations,
    currentRelations,
  );
  return annotations;
};
const annotationsToValidate = (state: AppState) => (annotationId: string) => {
  const currentAnnotations = selectAnnotations(state);
  const currentRelations = selectAnnotationRelations(state);
  const annotation = currentAnnotations.get(annotationId);
  if (!annotation) return [];

  const annotationChildren: Annotation[] = flatten(
    Annotation.getChildren(annotationId, currentAnnotations, currentRelations),
  );
  const annotations = [annotation].concat(annotationChildren);
  return annotations;
};

const validationActions = (state: AppState, annotationIds: string[]) => {
  const schemaId = selectActiveAnnotationSchemaId(state);
  const validateAnnotationss: AnnotationsActions = validateAnnotations(
    flatMap(annotationIds, annotationsToValidate(state)),
  );
  const fetchLookup: LookupContextActions = fetchLookupValues(
    schemaId || 0,
    flatMap(annotationIds, annotationsToLookup(state)),
  );
  const actions: Action[] = [fetchLookup, validateAnnotationss];
  return from(actions);
};
