import { inRange, isUndefined } from 'lodash';
import { DocumentPageResource } from '../resources/document-text.resource';
import { Annotation } from './annotation.model';
import { Map } from 'immutable';

export type AnnotationPage = {
  annotatedDocumentId: number;
  pageNum: number;
  text: string;
  ocrxOffsets: number[];
  ocrxPolys?: number[][][];
};

export const AnnotationPage = {
  mapFromResourcePages(
    responsePages: Map<string, DocumentPageResource>,
    annotatedDocumentId: number,
  ): Map<number, AnnotationPage> {
    const rPages = Array.from(responsePages.entries());
    return Map(
      rPages.map(([pageNumber, page]) => {
        const pageNum = parseInt(pageNumber, 10);
        return [
          pageNum,
          {
            annotatedDocumentId,
            pageNum,
            text: page.text,
            ocrxOffsets: page.ocrx_offsets || [],
            ocrxPolys: page.ocrx_polys,
          },
        ];
      }),
    );
  },

  /**
   * Returns the page numbers on which the annotation lies, if any.
   * @param annotation
   * @param pages
   */
  annotationPageNumbers(
    annotation: Annotation,
    pages: AnnotationPage[],
  ): number[] {
    return pages
      .filter((page, idx) => {
        if (isUndefined(annotation.start) || isUndefined(annotation.end)) {
          return false;
        }

        const nextPage = pages[idx + 1] || null;

        const pageStart = page.ocrxOffsets[0];
        // character offsets span to first letter of following page, non-inclusive
        const pageEnd = nextPage
          ? nextPage.ocrxOffsets[0]
          : page.ocrxOffsets[page.ocrxOffsets.length - 1];

        return (
          inRange(annotation.start, pageStart, pageEnd) ||
          inRange(annotation.end, pageStart, pageEnd) ||
          inRange(pageStart, annotation.start, annotation.end)
        );
      })
      .map(page => page.pageNum);
  },
};
