import { filter, get, map, startsWith } from 'lodash';
import { createSelector } from 'reselect';
import { AppState } from '../../app-state';
import { selectDocumentSetSearchIndices, selectedDocumentSets } from '../utils';

export const selectSelectedResults = (state: AppState) =>
  state.search.selectedResults;

export const selectShowingResults = (state: AppState) => !!state.search.results;

export const selectCurrentQuery = (state: AppState) =>
  state.search.query || { query: {} };

export const selectCurrentQueryWithIds = (state: AppState) => {
  const selectedResults = selectSelectedResults(state);
  const isSelected = selectedResults.selectedIds.length > 0;
  const inverseSelected = selectedResults.inverseSelected;
  const termQ = { terms: { _id: selectedResults.selectedIds } };
  const currentQuery = selectCurrentQuery(state);
  const boolQ = currentQuery.query['bool'];
  const filterQ = (boolQ['filter'] as object[]) || [];
  const mustNotQ = (boolQ['must_not'] as object[]) || [];
  const newFilterQ = inverseSelected ? filterQ : filterQ.concat(termQ);
  const newMustNotQ = !inverseSelected ? mustNotQ : mustNotQ.concat(termQ);
  const adjustedQuery = {
    query: {
      ...currentQuery.query,
      bool: {
        ...boolQ,
        filter: newFilterQ,
        must_not: newMustNotQ,
      },
    },
  };
  const newQuery = isSelected ? adjustedQuery : currentQuery;
  return newQuery;
};

export const selectSearchableDocumentSets = (state: AppState) =>
  state.search.searchableDocumentSets;

export const selectSelectedSearchableDocumentSets = createSelector(
  [selectSearchableDocumentSets],
  docSets => selectedDocumentSets(docSets),
);

export const selectIndicesToSearch = createSelector(
  [selectSelectedSearchableDocumentSets],
  docSets => selectDocumentSetSearchIndices(docSets).join(','),
);

export const selectAnnotatioTypesWithExistsConditionsInQuery = createSelector(
  [selectCurrentQuery],
  query => {
    const queryFilters: { [filterType: string]: { field: string } }[] = get(
      query,
      'query.bool.filter',
      [],
    );
    const annotationExistsQueries = filter(
      queryFilters,
      f => 'exists' in f && startsWith(f.exists.field, 'annotation'),
    );

    return map(annotationExistsQueries, q => q.exists.field);
  },
);
