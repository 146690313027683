import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AppEpic } from '../../common/types/app-epic.type';
import { handleEpicError } from '../../common/utils/epics';
import {
  completeQueueItem,
  setCurrentQueueItem,
} from '../actions/queue.actions';
import { QueuesResource } from '../resources/queues.resource';

export const completeQueueItemEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(completeQueueItem)),
    switchMap(({ payload: { queueId, queueItemId } }) =>
      QueuesResource.completeQueueItem(state$.value, queueId, queueItemId).pipe(
        map(item => setCurrentQueueItem(item)),
        catchError(
          handleEpicError('Could not complete queue item. Item not found.'),
        ),
      ),
    ),
  );
