import { AppState } from '../../app-state';
import { selectJwt } from '../../auth/selectors/auth.selectors';
import { jsonAuthHeaders } from '../headers';

export const CONTENT_TYPE_HEADERS = {
  JSON: { 'Content-Type': 'application/json' },
};

export function authHeaders(appState: AppState) {
  return jwtHeader(selectJwt(appState) as string);
}

export function jwtHeader(jwt: string) {
  return jsonAuthHeaders(jwt);
}
