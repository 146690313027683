import { createAction } from 'typesafe-actions';
import { PageView } from '../models/page-view.model';

enum Types {
  SET_SYNC_VIEWS = 'Page Controls: set sync views flag',
  TOGGLE_SYNC_VIEWS = 'Page Controls: toggle sync views flag',
  SET_PAGE_COUNT = 'Page Controls: set page count',
  SET_PAGE = 'Page Controls: set current page',
  INCREMENT_PAGE = 'Page Controls: increment current page',
  DECREMENT_PAGE = 'Page Controls: decrement current page',
}

export const setSyncViews = createAction(
  Types.SET_SYNC_VIEWS,
  resolvePayload => (syncViews: boolean) => resolvePayload({ syncViews }),
);

export const toggleSyncViews = createAction(Types.TOGGLE_SYNC_VIEWS);

export const setPageCount = createAction(
  Types.SET_PAGE_COUNT,
  resolvePayload => (pageCount: number) => resolvePayload({ pageCount }),
);

export const setPage = createAction(
  Types.SET_PAGE,
  resolvePayload => (view: PageView, page: number) =>
    resolvePayload({ view, page }),
);

export const incrementPage = createAction(
  Types.INCREMENT_PAGE,
  resolvePayload => (view: PageView) => resolvePayload({ view }),
);

export const decrementPage = createAction(
  Types.DECREMENT_PAGE,
  resolvePayload => (view: PageView) => resolvePayload({ view }),
);
