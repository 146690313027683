import { ActionsObservable, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AppState } from '../../app-state';
import { handleEpicError } from '../../common/utils/epics';
import { setSourceDocument } from '../../documents/actions/source-document.actions';
import { AppActions } from '../../root.actions';
import {
  fetchAnnotationPages,
  fetchAnnotationPagesError,
  populateAnnotationPages,
} from '../actions/annotation-pages.actions';
import { AnnotationPage } from '../models/annotation-page.model';
import { DocumentTextResource } from '../resources/document-text.resource';
import { Map } from 'immutable';

/**
 * Fetches updated annotation text from webservice.
 * @param action$
 * @param store
 */
export function fetchAnnotationPagesEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(fetchAnnotationPages)),
    switchMap(action => {
      const { documentId } = action.payload;

      return DocumentTextResource.get(state$.value, documentId).pipe(
        map(response => {
          const { documentText, annotatedDocumentId } = response;
          const { documentTextId, ocrVersion } = documentText;
          const dText = documentText.documentText.pages;
          const dTextPages = Map(
            Object.keys(dText).map(pageNum => [pageNum, dText[pageNum]]),
          );
          const pages = AnnotationPage.mapFromResourcePages(
            dTextPages,
            annotatedDocumentId,
          );

          return populateAnnotationPages(
            pages,
            annotatedDocumentId,
            documentTextId,
            ocrVersion,
          );
        }),
        catchError((error: AjaxError) => {
          const errorMessage = 'Failed to retrieve document text';
          return error.status === 404
            ? of(fetchAnnotationPagesError(errorMessage))
            : handleEpicError(errorMessage, fetchAnnotationPagesError)(error);
        }),
      );
    }),
  );
}

/**
 * Fetches annotation text every time the source document is updated.
 * @param action$
 * @param store
 */
export function fetchAnnotationPagesOnSourceDocumentUpdate(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(setSourceDocument)),
    filter(action => {
      const currentId = state$.value.annotationPages.sourceDocumentId;
      const newId = action.payload.document
        ? action.payload.document.aggregateId
        : null;

      return currentId !== newId;
    }),
    map(action =>
      fetchAnnotationPages(action.payload.document.annotatedDocumentId),
    ),
  );
}
