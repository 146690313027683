import { Popover } from 'antd';
import Tooltip from 'antd/lib/tooltip';
import React from 'react';
import styled from 'styled-components';
import { Gray1 } from '../../common/colors';
import * as Colors from '../../common/colors';
import { IconButton } from '../../common/components/icon-button.component';
import {
  PageControls,
  PageControlsProps,
} from '../../common/components/page-controls.component';
import { AnnotatedDocument } from '../models/annotated-document.model';
import { PDF_SELECTION_MODE } from '../models/pdf-selection-modes.model';
import { documentIsDownloadable } from '../util/document.util';
import { DocumentInfoComponent } from './document-info.component';

const PageControlsWrapper = styled.div`
  background-color: ${Colors.ToolbarBackground};
  color: #707171;
  border-bottom: 1px solid ${Gray1};
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  max-height: 42px;
`;

const Left = styled.div`
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 33%;

  > span {
    flex: 0;
    height: 100%;
  }

  a.ant-btn,
  button.ant-btn {
    float: left;
    height: 100%;
    font-size: 24px;
    font-weight: bold;
    border-radius: 0;
    padding: 8px 9px;
    border-right: 1px solid ${Gray1} !important;
    /* background-color: #6BC298; */
    /* color: white; */

    &:hover {
      /* background-color: #afdec8; */
    }

    &.active {
      background-color: #f3f3f3;
    }
  }
`;

const Center = styled.div`
  width: 34%;
  padding: 4px 0;
`;

const Right = styled.div`
  width: 33%;
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  > span {
    flex: 0;
    height: 100%;
  }

  a.ant-btn,
  button.ant-btn {
    float: right;
    height: 100%;
    font-size: 24px;
    font-weight: bold;
    border-radius: 0;
    padding: 8px 9px;
    border-left: 1px solid ${Gray1} !important;
    /* background-color: #6BC298; */
    /* color: white; */

    &.active {
      background-color: #f5f5f5;
    }

    i.anticon-reload {
      transform: scale(-1, -1);
    }

    &:hover {
      /* background-color: #afdec8; */
    }
  }
`;

const ButtonGroup = styled.span`
  border-right: 1px solid ${Gray1};
  margin-right: 1px;
  display: flex;
  flex-direction: row;
`;

const DocDetailsBlock = styled.div`
  white-space: nowrap;
  overflow: hidden;
  padding-left: 6px;
`;

const ZoomIncrement = 0.25;

export type PdfPageControlsDispatchProps = {
  downloadDocument: (url: string, doc: AnnotatedDocument) => any;
  rotateDocument: () => any;
  zoomDocument: (pct: number) => any;
  onSelectionModeClick: (currentMode: PDF_SELECTION_MODE) => any;
} & Pick<
  PageControlsProps,
  'incrementClicked' | 'decrementClicked' | 'gotoPage'
>;

export type PdfPageControlsProps = {
  document: AnnotatedDocument | null;
  downloadUrl: string | null;
  selectionMode: PDF_SELECTION_MODE;
  ocrVersion: string | null;
} & PageControlsProps &
  PdfPageControlsDispatchProps;
interface PdfPageControlsState {
  showInfoPopover: boolean;
}
export class PdfPageControls extends React.PureComponent<
  PdfPageControlsProps,
  PdfPageControlsState
> {
  state = {
    showInfoPopover: false,
  };

  get selectionModeTooltipText(): string | JSX.Element {
    if (this.props.selectionMode === 'normal') {
      return 'Selection Mode (Normal)';
    } else {
      return (
        <span>
          Selection Mode (Draw Rectangle)
          <br />
          Hold "shift" to add to selection.
          <br />
          Hold "alt" to subtract from selection.
          <br />
          "shift + click" adds word to selection.
          <br />
          "alt + click" subtracts word from selection.
          <br />
        </span>
      );
    }
  }

  onDownloadClick = () => {
    const { downloadUrl, document } = this.props;

    if (downloadUrl && document) {
      this.props.downloadDocument(downloadUrl, document);
    }
  };

  render() {
    const props = this.props;
    const doc = props.document;
    return (
      <PageControlsWrapper>
        <Left>
          <Tooltip
            title="Download PDF"
            placement="bottomLeft"
            mouseEnterDelay={0.7}
          >
            <span>
              <IconButton
                onClick={this.onDownloadClick}
                disabled={
                  !props.downloadUrl || (!!doc && !documentIsDownloadable(doc))
                }
                iconType="download"
              />
            </span>
          </Tooltip>
          <Tooltip
            title="Document Info"
            placement="bottomLeft"
            mouseEnterDelay={0.7}
          >
            <span>
              <IconButton
                onClick={() =>
                  doc &&
                  this.setState({
                    showInfoPopover: !this.state.showInfoPopover,
                  })
                }
                disabled={!doc}
                iconType="info"
              />
            </span>
          </Tooltip>
          {doc && (
            <DocDetailsBlock>
              <Tooltip
                title={doc.aggregateId}
                placement="bottom"
                mouseEnterDelay={0.7}
              >
                <strong>Aggregate ID</strong>
                <br />
                <span title={doc.aggregateId}>{doc.aggregateId}</span>
              </Tooltip>
            </DocDetailsBlock>
          )}
        </Left>
        <Center>
          <Popover
            placement="bottom"
            content={
              doc && (
                <DocumentInfoComponent
                  document={doc}
                  ocrVersion={this.props.ocrVersion}
                  onClose={() => this.setState({ showInfoPopover: false })}
                />
              )
            }
            visible={this.state.showInfoPopover}
          >
            <PageControls {...props} />
          </Popover>
        </Center>
        <Right>
          <ButtonGroup>
            <Tooltip
              title={this.selectionModeTooltipText}
              placement="bottomRight"
              mouseEnterDelay={0.7}
            >
              <span>
                <IconButton
                  onClick={() =>
                    props.onSelectionModeClick(this.props.selectionMode)
                  }
                  active={this.props.selectionMode === 'selection-box'}
                  iconType="selection-square"
                />
              </span>
            </Tooltip>
          </ButtonGroup>
          <ButtonGroup>
            <Tooltip
              title="Zoom In"
              placement="bottomRight"
              mouseEnterDelay={0.7}
            >
              <span>
                <IconButton
                  onClick={() => doc && props.zoomDocument(ZoomIncrement)}
                  disabled={!doc}
                  iconType="zoom-in"
                />
              </span>
            </Tooltip>
            <Tooltip
              title="Zoom Out"
              placement="bottomRight"
              mouseEnterDelay={0.7}
            >
              <span>
                <IconButton
                  onClick={() => doc && props.zoomDocument(-ZoomIncrement)}
                  disabled={!doc}
                  iconType="zoom-out"
                />
              </span>
            </Tooltip>
          </ButtonGroup>
          <Tooltip
            title="Rotate Document"
            placement="bottomRight"
            mouseEnterDelay={0.7}
          >
            <span>
              <IconButton
                onClick={() => doc && props.rotateDocument()}
                disabled={!doc}
                iconType="rotate-document"
              />
            </span>
          </Tooltip>
        </Right>
      </PageControlsWrapper>
    );
  }
}
