import React from 'react';
import styled from 'styled-components';
import { GroupAccount } from '../../accounts/models/group-account.model';
import { SimpleRole } from '../../accounts/models/simple-role.model';
import { DateComponent } from '../../common/components/date.component';
import PermissionTables from '../containers/permission-tables.container';
import { PermissionOverride } from './permission-tables.component';

const CardStyle = styled.div`
  text-align: left;
`;

const CardContent = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
`;

const Title = styled.h2`
  text-align: center;
  font-weight: bold;
`;

const UserDetails = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column;
  font-size: 18px;
`;

const Padded = styled.div`
  text-align: left;
  padding: 0 8px;
  > * {
    flex: 1 50%;
    padding: 14px;
  }
`;

const Row = styled(Padded)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }

  &:first-child {
    border-top: 1px solid #eee;
  }
`;

interface GroupAccountCardProps {
  account: GroupAccount;
  onSaveSimpleRoles: (roles: SimpleRole[]) => any;
}
export class GroupAccountCardComponent extends React.Component<
  GroupAccountCardProps
> {
  render() {
    const { account } = this.props;

    const groupRoles = account.roles;
    const permissions = account.permissions;
    const permissionOverrides: PermissionOverride[] = permissions
      .map(p => {
        const complexGroupRole = groupRoles.find(
          r => r.roleId === p.roleId && !r.isSimpleRole,
        );
        const overriddenBy = complexGroupRole
          ? `Overridden by role: ${complexGroupRole.roleName}`
          : undefined;
        return { permission: p, overriddenBy };
      })
      .filter(o => !!o.overriddenBy)
      .map(o => o as PermissionOverride);

    return (
      <CardStyle>
        <Title>{account.group.groupName}</Title>
        <CardContent>
          <UserDetails>
            <Row>
              <strong>Group Name</strong>
              <span>{account.group.groupName}</span>
            </Row>
            <Row>
              <strong>Group ID</strong>
              <span>{account.group.groupId}</span>
            </Row>
            <Row>
              <strong>Created On</strong>
              <span>
                {<DateComponent serializedDate={account.group.createdAt} />}
              </span>
            </Row>

            <Row>
              <strong>Number of Users</strong>
              <span>{account.users.length}</span>
            </Row>

            <Padded>
              <strong>Permissions</strong>
              <PermissionTables
                targetPermissions={this.props.account.permissions}
                targetPermissionOverrides={permissionOverrides}
                onSaveSimpleRoles={this.props.onSaveSimpleRoles}
              ></PermissionTables>
            </Padded>
          </UserDetails>
        </CardContent>
      </CardStyle>
    );
  }
}
