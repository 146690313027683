import { AnnotatedDocument } from '../../documents/models/annotated-document.model';
import { QueueItem } from './queue-item.model';

export type WorkableQueueItem = {
  queueItemId: number;
  queueId: number;
  annotatedDocument: AnnotatedDocument;
  createdAt: string;
  completedAt?: string;
};

export const WorkableQueueItem = {
  toQueueItem(item: WorkableQueueItem): QueueItem {
    const { queueId, queueItemId, completedAt, createdAt } = item;
    return {
      queueItemId,
      queueId,
      completedAt,
      createdAt,
      annotatedDocumentId: item.annotatedDocument.annotatedDocumentId,
    };
  },
};
