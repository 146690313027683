import { createAction } from 'typesafe-actions';
import { AdminTab } from '../models/admin-tab.model';

enum Types {
  SET_ACTIVE_ADMIN_TAB = 'SET_ACTIVE_ADMIN_TAB: Sets the active admin tab in the admin side panel.',
  SET_SELECTED_GROUP_ACCOUNT = 'SET_SELECTED_GROUP_ACCOUNT',
}

export const setActiveAdminTab = createAction(
  Types.SET_ACTIVE_ADMIN_TAB,
  resolvePayload => (tab: AdminTab) => resolvePayload({ tab }),
);

export const setSelectedGroupAccount = createAction(
  Types.SET_SELECTED_GROUP_ACCOUNT,
  resolvePayload => (groupId: number) => resolvePayload({ groupId }),
);
