import { createAsyncAction } from 'typesafe-actions';
import { GroupScopedTag } from '../models/group-scoped-tag.model';

enum Types {
  ADD_TAG_TO_DOCUMENTS_REQUEST = '[User] the user wants to add a tag to a selection of documents',
  ADD_TAG_TO_DOCUMENTS_SUCCESS = 'The requested tags were added successfully',
  ADD_TAG_TO_DOCUMENTS_ERROR = 'There was a problem adding the requested tags',
  ADD_TAG_TO_DOCUMENTS_BULK_REQUEST = '[User] the user wants to add a tag to all of their search results (bulk)',
  ADD_TAG_TO_DOCUMENTS_BULK_SUCCESS = 'The requested tags were added successfully (bulk)',
  ADD_TAG_TO_DOCUMENTS_BULK_ERROR = 'There was a problem adding the requested tags (bulk)',
  REMOVE_TAG_FROM_DOCUMENTS_REQUEST = '[User] the user wants to remove a tag from a selection of documents',
  REMOVE_TAG_FROM_DOCUMENTS_SUCCESS = 'The requested tags were removed successfully',
  REMOVE_TAG_FROM_DOCUMENTS_ERROR = 'There was a problem removing the requested tags',
  REMOVE_TAG_FROM_DOCUMENTS_BULK_REQUEST = '[User] the user wants to remove a tag from all of their search results (bulk)',
  REMOVE_TAG_FROM_DOCUMENTS_BULK_SUCCESS = 'The requested tags were removed successfully (bulk)',
  REMOVE_TAG_FROM_DOCUMENTS_BULK_ERROR = 'There was a problem removing the requested tags (bulk)',
  FETCH_TAGS_REQUEST = 'Requesting tags from tags endpoint...',
  FETCH_TAGS_SUCCESS = 'We got the tags, adding them to the store',
  FETCH_TAGS_FAILURE = 'Something went wrong fetching tags',
}

type AddTagToDocumentsAsyncPayload = {
  tag: string;
  annotatedDocumentIds: number[];
};

type AddTagToDocumentsAsyncResponsePayload = {
  originalResponse: AddTagToDocumentsAsyncPayload;
  error?: Error;
};

type RemoveTagFromDocumentsAsyncPayload = {
  tagId: number;
  annotatedDocumentIds: number[];
};

type RemoveTagFromDocumentsAsyncResponsePayload = {
  originalResponse: RemoveTagFromDocumentsAsyncPayload;
  error?: Error;
};
export const addTagToDocumentsAsync = createAsyncAction(
  Types.ADD_TAG_TO_DOCUMENTS_REQUEST,
  Types.ADD_TAG_TO_DOCUMENTS_SUCCESS,
  Types.ADD_TAG_TO_DOCUMENTS_ERROR,
)<
  AddTagToDocumentsAsyncPayload,
  AddTagToDocumentsAsyncResponsePayload,
  AddTagToDocumentsAsyncResponsePayload
>();

export const addTagToDocumentsBulkAsync = createAsyncAction(
  Types.ADD_TAG_TO_DOCUMENTS_BULK_REQUEST,
  Types.ADD_TAG_TO_DOCUMENTS_BULK_SUCCESS,
  Types.ADD_TAG_TO_DOCUMENTS_BULK_ERROR,
)<{ tag: string }, undefined, Error>();

export const removeTagFromDocumentsAsync = createAsyncAction(
  Types.REMOVE_TAG_FROM_DOCUMENTS_REQUEST,
  Types.REMOVE_TAG_FROM_DOCUMENTS_SUCCESS,
  Types.REMOVE_TAG_FROM_DOCUMENTS_ERROR,
)<
  RemoveTagFromDocumentsAsyncPayload,
  RemoveTagFromDocumentsAsyncResponsePayload,
  RemoveTagFromDocumentsAsyncResponsePayload
>();

export const removeTagFromDocumentsBulkAsync = createAsyncAction(
  Types.REMOVE_TAG_FROM_DOCUMENTS_BULK_REQUEST,
  Types.REMOVE_TAG_FROM_DOCUMENTS_BULK_SUCCESS,
  Types.REMOVE_TAG_FROM_DOCUMENTS_BULK_ERROR,
)<{ tagId: number }, undefined, Error>();

export const fetchTagsAsync = createAsyncAction(
  Types.FETCH_TAGS_REQUEST,
  Types.FETCH_TAGS_SUCCESS,
  Types.FETCH_TAGS_FAILURE,
)<undefined, GroupScopedTag[], Error>();
