import { createAction, createAsyncAction } from 'typesafe-actions';
import { AnnotationSaveEvent } from '../models/annotation-save-event.model';
import { AnnotationType } from '../models/annotation-type.model';
import { Annotation } from '../models/annotation.model';
import { BulkAnnotationUploadState } from '../reducers/bulk-annotations-importer-status.reducer';
import { Map } from 'immutable';
import { DragTargetState } from '../models/drag-target-state.model';

export const appendAnnotations = createAction(
  'APPEND_ANNOTATIONS',
  resolvePayload => (
    annotations: Map<string, Annotation>,
    sourceDocumentId: number | null,
  ) => resolvePayload({ annotations, sourceDocumentId }),
);

export const replaceAnnotations = createAction(
  'REPLACE_ANNOTATIONS',
  resolvePayload => (
    annotations: Map<string, Annotation>,
    sourceDocumentId: number | null,
  ) => resolvePayload({ annotations, sourceDocumentId }),
);

export const createAnnotation = createAction(
  'CREATE_ANNOTATION',
  resolvePayload => (annotation: Annotation, sourceDocumentId: number | null) =>
    resolvePayload({ annotation, sourceDocumentId }),
);

export const createAnnotationFromType = createAction(
  'CREATE_ANNOTATION_FROM_TYPE',
  resolvePayload => (type: AnnotationType) => resolvePayload({ type }),
);

export const deleteAnnotation = createAction(
  'DELETE_ANNOTATION',
  resolvePayload => (annotationId: string) => resolvePayload({ annotationId }),
);

export const deleteAnnotations = createAction(
  'DELETE_ANNOTATIONS',
  resolvePayload => (annotationIds: string[]) =>
    resolvePayload({ annotationIds }),
);

export const triggerDeleteAnnotations = createAction(
  'TRIGGER_DELETE_ANNOTATIONS',
  resolvePayload => (annotationIds: string[]) =>
    resolvePayload({ annotationIds }),
);

export const setSelectedAnnotations = createAction(
  'SET_SELECTED_ANNOTATIONS',
  resolvePayload => (annotationIds: string[]) =>
    resolvePayload({ annotationIds }),
);

export const deleteSelectedAnnotations = createAction(
  'DELETE_SELECTED_ANNOTATIONS',
);

export const copySelectedAnnotations = createAction(
  'COPY_SELECTED_ANNOTATIONS',
);

export const copyAnnotations = createAction(
  'COPY_ANNOTATIONS',
  resolvePayload => (annotationIds: string[]) =>
    resolvePayload({ annotationIds }),
);

export const pasteAnnotations = createAction('PASTE_ANNOTATIONS');

export const updateAnnotation = createAction(
  'UPDATE_ANNOTATION',
  resolvePayload => (annotations: Annotation[]) => resolvePayload(annotations),
);

export const updateAnnotationValues = createAction(
  'UPDATE_ANNOTATION_VALUES',
  resolvePayload => (values: Map<string, string>) => resolvePayload(values),
);

export const updateAnnotationTypes = createAction(
  'UPDATE_ANNOTATION_TYPES',
  resolvePayload => (types: Map<string, string>) => resolvePayload(types),
);

export const updateAnnotationValidations = createAction(
  'UPDATE_ANNOTATION_VALIDATIONS',
  resolvePayload => (validations: Map<string, boolean | undefined>) =>
    resolvePayload(validations),
);

export const fetchAnnotations = createAction(
  'FETCH_ANNOTATIONS',
  resolvePayload => (sourceDocumentId: number) =>
    resolvePayload({ sourceDocumentId }),
);

export const fetchAnnotationsError = createAction(
  'FETCH_ANNOTATIONS_ERROR',
  resolvePayload => (error: string) => resolvePayload({ error }),
);

export const saveAnnotations = createAction('SAVE_ANNOTATIONS');

export const saveAnnotationsSuccess = createAction(
  'SAVE_ANNOTATIONS_SUCCESS',
  resolvePayload => (sourceDocumentId: number, time: number) =>
    resolvePayload({ sourceDocumentId, time }),
);

export const saveAnnotationsError = createAction(
  'SAVE_ANNOTATIONS_ERROR',
  resolvePayload => (error: string) => resolvePayload({ error }),
);

export const createAnnotationFromSelection = createAction(
  'CREATE_ANNOTATION_FROM_SELECTION',
  resolvePayload => (type: AnnotationType) => resolvePayload({ type }),
);

export const setAnnotationSaveEvent = createAction(
  'SET_ANNOTATION_SAVE_EVENT',
  resolvePayload => (saveEvent: AnnotationSaveEvent) =>
    resolvePayload({ saveEvent }),
);

export const saveAnnotationsBulkAsync = createAsyncAction(
  'saveAnnotationsBulkAsync.request: The user has given us a CSV that we need to parse to a bulk upload request to send to the server.',
  'saveAnnotationsBulkAsync.success: Saving the annotations was successful',
  'saveAnnotationsBulkAsync.failure: Saving the annotations failed for some reason. Notify the user!',
)<
  { data: object[]; documentSetId: number },
  BulkAnnotationUploadState,
  BulkAnnotationUploadState
>();

export const validateAnnotations = createAction(
  'VALIDATE_ANNOTATION',
  resolvePayload => (annotations: Annotation[]) =>
    resolvePayload({ annotations }),
);

export const setLastManualAnnotation = createAction(
  'SET_LAST_MANUAL_ANNOTATION',
  resolvePayload => (id: string | null) => resolvePayload({ id }),
);

export const setDragTargetState = createAction(
  'SET_DRAG_TARGET_STATE',
  resolvePayload => (dragTargetState: DragTargetState) =>
    resolvePayload({ dragTargetState }),
);

export const setDragTargetId = createAction(
  'SET_DRAG_TARGET_ID',
  resolvePayload => (dragTargetId: string | null) =>
    resolvePayload({ dragTargetId }),
);
