import { ActionsObservable, StateObservable } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AppState } from '../../app-state';
import { AppEpic } from '../../common/types/app-epic.type';
import { handleEpicError } from '../../common/utils/epics';
import { AppActions } from '../../root.actions';
import {
  fetchAllGroupAccounts,
  fetchGroups,
  populateGroupAccounts,
  populateGroups,
  saveGroupSimpleRoles,
} from '../actions/groups.actions';
import { GroupAccountResource } from '../resources/group-account.resource';
import { GroupsResource } from '../resources/groups.resource';

export const groupsEpic: AppEpic = (
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) => {
  return action$.pipe(
    filter(isActionOf(fetchGroups)),
    switchMap(() =>
      GroupsResource.getAll(state$.value).pipe(
        map(groupsResource => populateGroups(groupsResource.groups)),
        catchError(handleEpicError('Error when fetching groups')),
      ),
    ),
  );
};

export function saveGroupSimpleRolesEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(saveGroupSimpleRoles)),
    switchMap(action => {
      const { groupId, roles } = action.payload;
      return GroupAccountResource.putSimpleRoles(
        groupId,
        roles,
        state$.value,
      ).pipe(
        switchMap(_ => from([fetchAllGroupAccounts()])),
        catchError(handleEpicError('Error saving group roles')),
      );
    }),
  );
}

export function getAllGroupAccountsEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(fetchAllGroupAccounts)),
    mergeMap(action =>
      GroupAccountResource.getAll(state$.value).pipe(
        map(resp => populateGroupAccounts(resp.groupAccounts)),
        catchError(handleEpicError('Unable to get all group accounts')),
      ),
    ),
  );
}
