import { of } from 'rxjs';
import { catchError, filter, map, mapTo, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { selectCurrentUserGroup } from '../../accounts/selectors/groups.selectors';
import { displaySuccessNotification } from '../../common/actions/notification.actions';
import { AppEpic } from '../../common/types/app-epic.type';
import {
  selectCurrentQueryWithIds,
  selectIndicesToSearch,
} from '../../search/selectors';
import {
  addTagToDocumentsAsync,
  addTagToDocumentsBulkAsync,
  fetchTagsAsync,
  removeTagFromDocumentsAsync,
  removeTagFromDocumentsBulkAsync,
} from '../actions/tag.actions';
import { GroupScopedTagsResource } from '../resources/group-scoped-tags.resource';

export const addTagToDocumentsEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(addTagToDocumentsAsync.request)),
    switchMap(action =>
      GroupScopedTagsResource.addTagForDocuments(state$.value, {
        ...action.payload,
        owningGroupId: selectCurrentUserGroup(state$.value).groupId,
      }).pipe(
        map(() =>
          addTagToDocumentsAsync.success({
            originalResponse: action.payload,
          }),
        ),
        catchError(err =>
          of(
            addTagToDocumentsAsync.failure({
              originalResponse: action.payload,
              error: err,
            }),
          ),
        ),
      ),
    ),
  );

export const addTagToDocumentsBulkEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(addTagToDocumentsBulkAsync.request)),
    switchMap(action =>
      GroupScopedTagsResource.addTagForDocumentsBulk(state$.value, {
        ...action.payload,
        owningGroupId: selectCurrentUserGroup(state$.value).groupId,
        indices: selectIndicesToSearch(state$.value),
        query: selectCurrentQueryWithIds(state$.value).query,
      }).pipe(
        map(addTagToDocumentsBulkAsync.success),
        catchError(err => of(addTagToDocumentsBulkAsync.failure(err))),
      ),
    ),
  );

export const removeTagFromDocumentsEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(removeTagFromDocumentsAsync.request)),
    switchMap(action =>
      GroupScopedTagsResource.removeTagForDocuments(
        state$.value,
        action.payload,
      ).pipe(
        map(() =>
          removeTagFromDocumentsAsync.success({
            originalResponse: action.payload,
          }),
        ),
        catchError(err =>
          of(
            removeTagFromDocumentsAsync.failure({
              originalResponse: action.payload,
              error: err,
            }),
          ),
        ),
      ),
    ),
  );

export const removeTagFromDocumentsBulkEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(removeTagFromDocumentsBulkAsync.request)),
    switchMap(action =>
      GroupScopedTagsResource.removeTagForDocumentsBulk(state$.value, {
        ...action.payload,
        indices: selectIndicesToSearch(state$.value),
        query: selectCurrentQueryWithIds(state$.value).query,
      }).pipe(
        map(removeTagFromDocumentsBulkAsync.success),
        catchError(err => of(removeTagFromDocumentsBulkAsync.failure(err))),
      ),
    ),
  );

export const addTagSuccessEpic: AppEpic = action$ =>
  action$.pipe(
    filter(
      isActionOf([
        addTagToDocumentsAsync.success,
        addTagToDocumentsBulkAsync.success,
      ]),
    ),
    mapTo(displaySuccessNotification('Tags added successfully')),
  );

export const removeTagSuccessEpic: AppEpic = action$ =>
  action$.pipe(
    filter(
      isActionOf([
        removeTagFromDocumentsAsync.success,
        removeTagFromDocumentsBulkAsync.success,
      ]),
    ),
    mapTo(displaySuccessNotification('Tags removed successfully')),
  );

export const fetchGroupScopedTagsEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(fetchTagsAsync.request)),
    switchMap(_ =>
      GroupScopedTagsResource.getAll(state$.value).pipe(
        map(fetchTagsAsync.success),
        catchError(err => of(fetchTagsAsync.failure(err))),
      ),
    ),
  );

export const refreshTagsOnAddSuccess: AppEpic = action$ =>
  action$.pipe(
    filter(
      isActionOf([
        addTagToDocumentsAsync.success,
        addTagToDocumentsBulkAsync.success,
      ]),
    ),
    map(fetchTagsAsync.request),
  );
