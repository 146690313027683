import { AppState } from '../../app-state';

export const selectExportJob = (state: AppState) => (jobId: string) => {
  const job = state.exports.jobs[jobId];
  return job;
};

export const selectExportJobExists = (state: AppState) => (jobId: string) => {
  return !!selectExportJob(state)(jobId);
};

export const selectExportStatus = (state: AppState) => (jobId: string) => {
  const job = selectExportJob(state)(jobId);
  if (job) return job.status;
  else return null;
};

export const selectExportProgress = (state: AppState) => (jobId: string) => {
  const job = selectExportJob(state)(jobId);
  if (job) return job.status.progress;
  else return null;
};

export const selectExportParams = (state: AppState) => (jobId: string) => {
  const job = selectExportJob(state)(jobId);
  if (job) return job.params;
  else return null;
};

export const selectExportComplete = (state: AppState) => (jobId: string) => {
  const progress = selectExportProgress(state)(jobId);
  return progress ? progress.total === progress.current : null;
};

export const selectExportPending = (state: AppState) => (jobId: string) => {
  const status = selectExportStatus(state)(jobId);
  return status ? status.pending : false;
};
