import { createAction } from 'typesafe-actions';
import { PDF_SELECTION_MODE } from '../../documents/models/pdf-selection-modes.model';

enum Types {
  SET_PDF_SELECTION_MODE = '[User] User chose to toggle the pdf text selection mode.',
}

interface SetPdfSelectionModePayload {
  mode: PDF_SELECTION_MODE;
}

export const setPdfSelectionMode = createAction(
  Types.SET_PDF_SELECTION_MODE,
  resolvePayload => (mode: PDF_SELECTION_MODE) => resolvePayload({ mode }),
);
