/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, Route, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Restricted } from '../../accounts/containers/restricted.container';
import {
  PermissionActions,
  PermissionResources,
} from '../../accounts/models/permission.model';
import {
  AppRoutes,
  isExtractViewRoute,
  isManageViewRoute,
  routeActive,
} from '../../app-routes';
import * as Colors from '../../common/colors';
import { StyledHeader } from '../../common/components/styled-header.component';
import { DocumentSelectorPopover } from '../../documents/containers/document-selector-popover.container';
import { Flag } from '../../flags';
import logo from '../../logo.svg';
import { IdentityDropdown } from '../containers/identity-dropdown.container';
import ContextualModals from './contextual-modals.container';

const CenterArea = styled.div`
  flex: 2;
  margin: 0 48px;
  text-align: left;
  display: flex;

  button {
    margin-left: 12px;
    height: 48px;
  }
`;

const HeaderLink = styled(Link)`
  color: white;
  cursor: pointer;
  padding: 0 12px;
  font-size: 16px;

  &.active {
    color: ${Colors.ActiveLinkColor};
    border-bottom: 2px solid ${Colors.ActiveLinkColor};
  }
`;

const LogoArea = styled.div`
  width: 100px;
  height: 48px;
`;

const annotatePermissions = {
  action: PermissionActions.Annotate,
  resource: PermissionResources.DocumentSet,
};

const adminPermissions = {
  action: PermissionActions.All,
  resource: PermissionResources.Permission,
};

const MainHeader = (props: RouteComponentProps) => {
  return (
    <StyledHeader>
      <LogoArea>
        <Link to={AppRoutes.Search}>
          <img src={logo} />
        </Link>
      </LogoArea>
      <CenterArea>
        <HeaderLink
          to={AppRoutes.Search}
          className={
            routeActive(AppRoutes.Search) || routeActive(AppRoutes.Home)
              ? 'active'
              : 'inactive'
          }
        >
          Search
        </HeaderLink>
        <Restricted required={annotatePermissions}>
          <HeaderLink
            to={AppRoutes.Extract}
            className={
              isExtractViewRoute(props.location.pathname)
                ? 'active'
                : 'inactive'
            }
          >
            Extract
          </HeaderLink>
        </Restricted>
        <Flag name={['features', 'manage']}>
          <HeaderLink
            to={`${AppRoutes.Manage}/document-set`}
            className={
              isManageViewRoute(props.location.pathname) ? 'active' : 'inactive'
            }
          >
            Manage
          </HeaderLink>
        </Flag>
        <Restricted required={adminPermissions}>
          <HeaderLink
            to={AppRoutes.Admin}
            className={routeActive(AppRoutes.Admin) ? 'active' : 'inactive'}
          >
            Admin
          </HeaderLink>
        </Restricted>
        <ContextualModals />
        <Restricted required={annotatePermissions}>
          <Route
            path={AppRoutes.Extract}
            exact={true}
            component={DocumentSelectorPopover}
          />
          <Route
            path={AppRoutes.AnnotationPage}
            exact={true}
            component={DocumentSelectorPopover}
          />
        </Restricted>
      </CenterArea>
      <IdentityDropdown />
    </StyledHeader>
  );
};

export const Header = withRouter(MainHeader);
