import { Select } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { find } from 'lodash';
import React from 'react';
import { OptionalDocumentStatus } from '../actions/annotation-document-status.actions';
import { AnnotationDocumentStatus } from '../resources/annotation-document-status.resource';
import { DocumentStatus } from '../resources/available-document-statuses.resource';

const Option = Select.Option;

const fontFamily =
  'Monospaced Number,Chinese Quote,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif';

export interface DocumentStatusesProps {
  availableStatuses: DocumentStatus[];
  documentStatuses: AnnotationDocumentStatus[];
  onAddStatus: (status: OptionalDocumentStatus) => void;
  onRemoveStatus: (status: OptionalDocumentStatus) => void;
  disabled: boolean;
}

export class DocumentStatuses extends React.PureComponent<
  DocumentStatusesProps
> {
  getSelectOptions(availableStatuses: DocumentStatus[]) {
    return availableStatuses.map(x => (
      <Option key={x.status} value={x.status}>
        {' '}
        {x.status}{' '}
      </Option>
    ));
  }

  handleSelect = (value: SelectValue) => {
    const status = find(this.props.availableStatuses, x => x.status === value);
    const newStatus = status
      ? { status: status.status, statusId: status.statusId }
      : status;
    this.props.onAddStatus(newStatus);
  };

  handleDeselect = (value: SelectValue) => {
    const status = this.props.documentStatuses.find(x => x.status === value);
    this.props.onRemoveStatus(status);
  };

  render() {
    const selectOptions = this.getSelectOptions(this.props.availableStatuses);
    const selected = this.props.documentStatuses.map(x => x.status);

    return (
      <Select
        mode="tags"
        size="small"
        style={{ width: '100%', fontFamily }}
        placeholder="Document Statuses"
        onSelect={this.handleSelect}
        onDeselect={this.handleDeselect}
        value={selected}
        disabled={this.props.disabled}
      >
        {selectOptions}
      </Select>
    );
  }
}
