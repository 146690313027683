import { createAction } from 'typesafe-actions';
import { QueueItem } from '../models/queue-item.model';
import { Queue } from '../models/queue.model';

enum Types {
  CREATE_QUEUE = 'CREATE_QUEUE',
  CREATE_QUEUE_SUCCESS = 'CREATE_QUEUE_SUCCESS',
  POPULATE_QUEUES = 'POPULATE_QUEUES',
  FETCH_QUEUES = 'FETCH_QUEUES',
  DELETE_QUEUE = 'DELETE_QUEUE: the user has decided to delete one of their precious queues. Their loss!',
  GET_NEXT_QUEUE_ITEM = '[User] move on to the next document in their selected queue.',
  SET_CURRENT_QUEUE_ITEM = '[Queues] Saving the queue item currently being worked so we can reference it when completing it later',
  COMPLETE_QUEUE_ITEM = '[User] the user has marked the current queue item they are working as completed.',
  ADD_QUEUE_ITEMS = '[User] the user wants to add items to a queue.',
  ADD_QUEUE_ITEMS_BULK = '[User] the user wants to add all searched items to a queue .',
  ADD_QUEUE_ITEMS_SUCCESS = '[Queues] items added to the queue',
}

export const fetchQueues = createAction(Types.FETCH_QUEUES);

export const populateQueues = createAction(
  Types.POPULATE_QUEUES,
  resolvePayload => (queues: Queue[]) => resolvePayload({ queues }),
);

export const createQueueSuccess = createAction(Types.CREATE_QUEUE_SUCCESS);

export const createQueue = createAction(
  Types.CREATE_QUEUE,
  resolvePayload => (name: string, adminIds: number[], workerIds: number[]) =>
    resolvePayload({ name, adminIds, workerIds }),
);

export const getNextQueueItem = createAction(
  Types.GET_NEXT_QUEUE_ITEM,
  resolvePayload => (queueId: number) => resolvePayload({ queueId }),
);

export const setCurrentQueueItem = createAction(
  Types.SET_CURRENT_QUEUE_ITEM,
  resolvePayload => (item: QueueItem) => resolvePayload({ item }),
);

export const completeQueueItem = createAction(
  Types.COMPLETE_QUEUE_ITEM,
  resolvePayload => (queueId: number, queueItemId: number) =>
    resolvePayload({ queueId, queueItemId }),
);

export const addQueueItems = createAction(
  Types.ADD_QUEUE_ITEMS,
  resolvePayload => (queueId: number, annotatedDocumentIds: number[]) =>
    resolvePayload({ queueId, annotatedDocumentIds }),
);

export const addQueueItemsBulk = createAction(
  Types.ADD_QUEUE_ITEMS_BULK,
  resolvePayload => (queueId: number) => resolvePayload({ queueId }),
);

export const deleteQueue = createAction(
  Types.DELETE_QUEUE,
  resolvePayload => (queueId: number) => resolvePayload({ queueId }),
);

export const addQueueItemsSuccess = createAction(Types.ADD_QUEUE_ITEMS_SUCCESS);
