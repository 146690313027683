import { createSelector } from 'reselect';
import { AppState } from '../../app-state';
import { selectAnnotationsArray } from '../../annotations/selectors/annotation.selectors';
import { selectActiveSchemaLookupTypes } from '../../annotations/selectors/annotation-schema.selectors';
import { Annotation } from '../../annotations/models/annotation.model';
import { DomainLookup } from '../models/lookup-context.model';

export const selectLookupAnnotationIds = (state: AppState): string[] =>
  state.lookupContext.lookupAnnotationIds;

export const selectAllAnnotationLookupsRetrieved = createSelector(
  [
    selectLookupAnnotationIds,
    selectAnnotationsArray,
    selectActiveSchemaLookupTypes,
  ],
  (lookupIds, annotations, lookupTypes) => {
    const existsInLookup = (a: Annotation) => lookupIds.indexOf(a.id) !== -1;
    const matchesLookupType = (a: Annotation) =>
      lookupTypes.indexOf(a.type) !== -1;
    const allRetrieved = annotations.every(
      a => existsInLookup(a) || !matchesLookupType(a),
    );
    return allRetrieved;
  },
);

export const selectLookups = (state: AppState): DomainLookup[] =>
  state.lookupContext.lookups;
