import { CloseOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import styled from 'styled-components';
import React from 'react';
import { isNull } from 'util';
import { DefaultTextColor } from '../../common/colors';
import {
  AnnotationMode,
  AnnotationShortcuts,
} from '../containers/annotation-shortcut-keys.container';
import { AnnotationSchema } from '../models/annotation-schema.model';
import { AnnotationType } from '../models/annotation-type.model';
import { AnnotationListComponent } from './annotation-list.component';

// See index.css for additional styling.
// AnnotationListDropdown element is mounted to body, so certain styling must exist at global level.
const StyledAnnotationList = styled(AnnotationListComponent)`
  max-height: 80vh;
  overflow-y: scroll;
  padding-top: 6px;
`;

const NotSelectableStyle: React.CSSProperties = {
  userSelect: 'none',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  msUserSelect: 'none',
};

const MenuButton = styled.a`
  &:not(:hover) {
    color: ${DefaultTextColor};
  }
`;

const DropdownTrigger = styled.a`
  padding: 0px 5px 8px;
  border-bottom: 1px solid #ccc;
  display: block;
  color: inherit;
`;

const ContentWrapper = styled.div`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export interface AnnotationListDropdownProps {
  visible?: boolean;
  trigger?: 'hover' | 'focus' | 'click';
  annotationMode?: AnnotationMode;
  hotkeysDisabled?: boolean;
  refocusWhenVisible?: boolean;
  allSchemas: AnnotationSchema[];
  schema: AnnotationSchema | null;
  onAnnotationTypeSelected: (type: AnnotationType) => void;
  onSelectSchema: (schema: AnnotationSchema) => void;
  onClose?: () => void;
  setEditingSchema: (schema: AnnotationSchema | null) => void;
  canModifySchema: (schemaId: number) => boolean;
  schemaEditorDisabled?: boolean;
}

export const AnnotationListDropdown = (props: AnnotationListDropdownProps) => {
  let contentDivRef: HTMLAnchorElement | null = null;

  React.useEffect(() => {
    if (props.visible && props.refocusWhenVisible) {
      resetFocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);

  function close() {
    if (props.onClose) {
      props.onClose();
    }
  }

  function setRef(ref: HTMLAnchorElement | null) {
    // we need to set focus on the trap
    // the first time we set the reference
    // so we don't have to manually focus the
    // popover to get hotkeys to work
    if (isNull(contentDivRef) && props.refocusWhenVisible) {
      contentDivRef = ref;
      resetFocus();
    } else {
      contentDivRef = ref;
    }
  }

  function resetFocus() {
    if (contentDivRef) {
      contentDivRef.focus();
    }
  }

  function renderCategorySelector(schema: AnnotationSchema) {
    const { onSelectSchema, allSchemas } = props;
    return (
      <Dropdown
        trigger={['click']}
        overlay={
          <Menu
            style={NotSelectableStyle}
            onClick={({ key }) => {
              onSelectSchema(allSchemas[key]);
              resetFocus();
            }}
          >
            {allSchemas.map(renderMenuItem)}
          </Menu>
        }
      >
        <DropdownTrigger>
          {schema.title} <DownOutlined />
        </DropdownTrigger>
      </Dropdown>
    );
  }

  const renderMenuItem = (schema: AnnotationSchema, idx: number) => (
    <Menu.Item key={idx}>{schema.title}</Menu.Item>
  );

  if (!props.schema) {
    return null;
  }

  const { onAnnotationTypeSelected, schema } = props;

  const onCloseDefined = props.onClose !== undefined;
  const closeButton = onCloseDefined ? (
    <MenuButton style={{ float: 'right' }} onClick={close}>
      <CloseOutlined style={{ fontSize: '18px' }} />
    </MenuButton>
  ) : null;

  const schemaEditorDisabled =
    props.schemaEditorDisabled === undefined
      ? false
      : props.schemaEditorDisabled;
  const showSchemaEditor =
    !schemaEditorDisabled && props.canModifySchema(schema.annotationSchemaId);

  const SchemaEditorModal = showSchemaEditor ? (
    <MenuButton
      style={{ float: 'right', marginRight: '12px' }}
      onClick={() => {
        close();
        props.setEditingSchema(props.schema);
      }}
    >
      <EditOutlined style={{ fontSize: '18px' }} />
    </MenuButton>
  ) : null;

  /* eslint-disable */
  return (
    <AnnotationShortcuts
      mode={props.annotationMode}
      disabled={props.hotkeysDisabled}
      onAnnotationPress={(at: AnnotationType) =>
        props.onAnnotationTypeSelected(at)
      }
    >
      <ContentWrapper>
        {/* Focus trap for shortcut keys (loses focus on dropdown selection) */}
        <a href="javascript:void(0);" ref={setRef} />
        {closeButton}
        {SchemaEditorModal}
        {renderCategorySelector(schema)}
        <StyledAnnotationList
          onClick={onAnnotationTypeSelected}
          annotationSchema={schema}
        />
      </ContentWrapper>
    </AnnotationShortcuts>
  );
  /* eslint-enable */
};
