import { connect } from 'react-redux';
import { AppState } from '../../app-state';
import { selectUserHasSourceDocumentLock } from '../../documents/selectors/document-lock.selectors';
import { selectSourceDocumentIsEditable } from '../../documents/selectors/source-document.selectors';
import { AnnotationToolbarComponent } from '../components/annotation-toolbar.component';

export const AnnotationToolbar = connect((state: AppState) => ({
  predictEnabled: selectSourceDocumentIsEditable(state),
  documentInEditMode: selectUserHasSourceDocumentLock(state),
}))(AnnotationToolbarComponent);
