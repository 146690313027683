import { connect } from 'react-redux';
import { selectOcrVersion } from '../../annotations/selectors/annotation-pages.selectors';
import { AppState } from '../../app-state';
import { downloadFile } from '../../common/actions/download.actions';
import { setPdfSelectionMode } from '../../ui-settings/actions/pdf-viewer-settings.actions';
import {
  rotatePdfViewerBy,
  zoomPdfViewerBy,
} from '../actions/ui-pdf-viewer.actions';
import {
  PdfPageControls,
  PdfPageControlsDispatchProps,
} from '../components/pdf-page-controls.component';
import { AnnotatedDocument } from '../models/annotated-document.model';
import { PDF_SELECTION_MODE } from '../models/pdf-selection-modes.model';
import {
  selectSourceDocumentCurrentPageNumber,
  selectSourceDocumentMeta,
  selectSourceDocumentUrl,
} from '../selectors/source-document.selectors';
import { selectPdfViewerSelectionMode } from '../selectors/ui.selecetors';
import {
  decrementPage,
  incrementPage,
  setPage,
} from '../../documents/actions/page-controls.actions';
import { selectPageCount } from '../../documents/selectors/page-controls.selectors';
import { PageView } from '../../documents/models/page-view.model';

const PAGE_VIEW = PageView.Pdf;

export const PdfPageControlsContainer = connect(
  (state: AppState) => ({
    currentPage: selectSourceDocumentCurrentPageNumber(state),
    numberPages: selectPageCount(state),
    document: selectSourceDocumentMeta(state),
    downloadUrl: selectSourceDocumentUrl(state),
    selectionMode: selectPdfViewerSelectionMode(state),
    ocrVersion: selectOcrVersion(state),
  }),
  (dispatch): PdfPageControlsDispatchProps => ({
    incrementClicked: () => dispatch(incrementPage(PAGE_VIEW)),
    decrementClicked: () => dispatch(decrementPage(PAGE_VIEW)),
    gotoPage: page => dispatch(setPage(PAGE_VIEW, page)),
    downloadDocument: (url: string, doc: AnnotatedDocument) =>
      dispatch(downloadFile(url, `${doc.aggregateId}.pdf`)),
    rotateDocument: () => dispatch(rotatePdfViewerBy(90)),
    zoomDocument: (pct: number) => dispatch(zoomPdfViewerBy(pct)),
    onSelectionModeClick: (currentMode: PDF_SELECTION_MODE) => {
      const nextMode = currentMode === 'normal' ? 'selection-box' : 'normal';
      return dispatch(setPdfSelectionMode(nextMode));
    },
  }),
)(PdfPageControls);
