import { createAction } from 'typesafe-actions';

enum Types {
  SET_ANNOTATION_TABLE_COLUMN_HIDDEN = '[User] User chose to show/hide an annotation table column',
}

export const setAnnotationTableColumnVisibility = createAction(
  Types.SET_ANNOTATION_TABLE_COLUMN_HIDDEN,
  resolvePayload => (colId: string, hide: boolean) =>
    resolvePayload({
      colId,
      hide,
    }),
);
