import { getType } from 'typesafe-actions';
import {
  setPage,
  setPageCount,
  setSyncViews,
} from '../actions/page-controls.actions';
import { Map } from 'immutable';
import { PageControlsActions } from '../actions/page-controls.types';
import { sourceDocumentLoaded } from '../../documents/actions/source-document.actions';
import { SourceDocumentActions } from '../../documents/actions/source-document.types';
import { PageView } from '../models/page-view.model';

export type PageControlsState = {
  pageCount: number;
  pageByView: Map<string, number>;
  syncViews: boolean;
};

const defaultPageControlsState = {
  pageCount: 0,
  pageByView: Map<PageView, number>()
    .set(PageView.Text, 1)
    .set(PageView.Pdf, 1),
  syncViews: false,
};

export function pageControlsReducer(
  state: PageControlsState = defaultPageControlsState,
  action: PageControlsActions | SourceDocumentActions,
): PageControlsState {
  switch (action.type) {
    case getType(sourceDocumentLoaded):
      const pageCount = action.payload.pageCount;
      return { ...state, pageCount };
    case getType(setPage): {
      const { view, page } = action.payload;
      const newState = setCurrentPage(state, view, page);
      return newState;
    }
    case getType(setPageCount): {
      return { ...state, pageCount: action.payload.pageCount };
    }
    case getType(setSyncViews): {
      const { syncViews } = action.payload;
      return { ...state, syncViews };
    }

    default:
      return state;
  }
}

function setCurrentPage(
  state: PageControlsState,
  view: string,
  pageNumber: number,
) {
  const prevPageByView = state.pageByView.set(PageView.Latest, pageNumber);
  if (pageNumber >= 1 && pageNumber <= state.pageCount) {
    if (state.syncViews || view === PageView.All) {
      const pageByView = prevPageByView
        .map(_ => pageNumber)
        .set(view, pageNumber);
      return { ...state, pageByView };
    } else {
      const pageByView = prevPageByView.set(view, pageNumber);
      return { ...state, pageByView };
    }
  } else {
    return state;
  }
}
