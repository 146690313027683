export const Blue1 = '#303749';
export const Blue2 = '#565E70';
export const Blue3 = '#2F374A';
export const Red1 = '#FF0000';
export const DodgerBlue = '#1790FF';
export const Orange1 = '#E75925';
export const Orange2 = '#D76036';
export const Gray1 = '#C2C3C4';
export const Gray2 = '#545456';
export const Gray3 = '#F5F5F5';

export const ErrorColor = '#D00000';
export const SuccessGreen = '#4BB543';
export const AnchorColor = '#1890ff';
export const ActiveLinkColor = '#40a9ff';

export const DefaultTextColor = 'rgba(0, 0, 0, 0.65)';

// Annotation table
export const TableRowValid = '#E0F5FE';
export const TableRowInvalid = '#F2D7D5';
export const TableRowSelf = '#FCF3CF';

export const ToolbarBackground = '#F5F7F7';
export const ToolbarFontColor = '#707171';

export const LightGreen = 'LightGreen';
export const LightRed = '#ff3333';

export const White = 'white';
export const Black = 'black';

export const PickerColors = [
  '#4D4D4D',
  '#999999',
  '#FFFFFF',
  '#F44E3B',
  '#FE9200',
  '#FCDC00',
  '#DBDF00',
  '#A4DD00',
  '#68CCCA',
  '#73D8FF',
  '#AEA1FF',
  '#FDA1FF',
  '#333333',
  '#808080',
  '#cccccc',
  '#D33115',
  '#E27300',
  '#FCC400',
  '#B0BC00',
  '#68BC00',
  '#16A5A5',
  '#009CE0',
  '#7B64FF',
  '#FA28FF',
  '#000000',
  '#666666',
  '#B3B3B3',
  '#9F0500',
  '#C45100',
  '#FB9E00',
  '#808900',
  '#194D33',
  '#0C797D',
  '#0062B1',
  '#653294',
  '#AB149E',
];
