import { Dictionary, keyBy, values } from 'lodash';
import { flatMap, map, sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { AppState } from '../../app-state';
import { RelationCondition } from '../../lib/relations/relation-condition.model';
import { isCondition } from '../../lib/relations/relation-types';
import { AnnotationRelationsRulesById } from '../models/annotation-relation-rule.model';
import { AnnotationRelation } from '../models/annotation-relation.model';
import { AnnotationSchema } from '../models/annotation-schema.model';
import {
  AnnotationType,
  AnnotationTypesById,
} from '../models/annotation-type.model';

export const selectAnnotationSchemas = (
  state: Pick<AppState, 'annotationSchemas'>,
): Dictionary<AnnotationSchema> => state.annotationSchemas.entities;

export const selectAnnotationSchemasExist = createSelector(
  [selectAnnotationSchemas],
  schemas => Object.keys(schemas).length > 0,
);

export const selectActiveAnnotationSchema = (
  state: AppState,
): AnnotationSchema | null => state.annotationSchemas.activeSchema;

export const selectActiveAnnotationSchemaId = createSelector(
  [selectActiveAnnotationSchema],
  schema => schema?.annotationSchemaId,
);

export const selectAnnotationsById = (state: AppState): AnnotationTypesById =>
  state.annotationSchemas.annotationTypes;

export const selectRelationRulesById = (
  state: AppState,
): AnnotationRelationsRulesById => state.annotationSchemas.relationRules;

export const selectAnnotationSchemasArray = createSelector(
  [selectAnnotationSchemas],
  schemas => sortBy(values(schemas), s => s.title.toLowerCase()),
);

export const selectUserSelectableSchemasArray = createSelector(
  [selectAnnotationSchemasArray],
  schemas => schemas.filter(AnnotationSchema.isUserSelectable),
);

export const selectAllAnnotationTypes = createSelector(
  [selectUserSelectableSchemasArray],
  schemas => flatMap(schemas, s => s.annotationTypes),
);

export const selectAllAnnotationKeys = createSelector(
  [selectAllAnnotationTypes],
  ts => sortBy([...new Set(ts.map(s => s.key))], k => k.toLowerCase()),
);

export const selectAnnotationTypesArray = createSelector(
  [selectAnnotationsById],
  atnTypes => values(atnTypes),
);

export const selectActiveSchemaAnnotationTypes = createSelector(
  [selectActiveAnnotationSchema],
  schema => schema?.annotationTypes || [],
);

export const selectActiveSchemaAnnotationType = (
  state: AppState,
  key: string,
) =>
  selectActiveSchemaAnnotationTypes(state).find(t => t.key === key) ||
  AnnotationType.Unknown(key);

export const selectAnnotationShortcuts = createSelector(
  [selectActiveAnnotationSchema],
  schema =>
    schema ? schema.annotationTypes.filter(atn => atn.keyboardShortcut) : [],
);

export const selectWildcardAnyAnnotationType = createSelector(
  [selectAnnotationTypesArray],
  atnTypes => atnTypes.find(atn => atn.key === AnnotationType.WildcardAnyKey),
);

export const selectAnnotationsTypesByKey = createSelector(
  [selectActiveSchemaAnnotationTypes, selectAnnotationTypesArray],
  (activeAtnTypes, atnTypes) => keyBy(activeAtnTypes || atnTypes, 'key'),
);

export const selectRelationRulesArray = createSelector(
  [selectRelationRulesById],
  rules => values(rules),
);

export const selectRelationRulesByName = createSelector(
  [selectRelationRulesArray],
  rules => keyBy(rules, AnnotationRelation.ruleName),
);

export const selectRelationRuleConditionsArray = createSelector(
  [selectRelationRulesArray],
  rules => {
    // Although inelegant, loop allows typesafe refinement of RelationRule -> RelationCondition.
    const conditions: RelationCondition<string>[] = [];
    for (const nextCondition of rules) {
      if (isCondition(nextCondition)) {
        conditions.push(nextCondition);
      }
    }

    return conditions;
  },
);

export const selectRelationRuleConditionsByName = createSelector(
  [selectRelationRuleConditionsArray],
  conditions => keyBy(conditions, AnnotationRelation.ruleName),
);

export const selectActiveSchemaLookups = createSelector(
  [selectActiveAnnotationSchema],
  s => s?.lookups || [],
);

export const selectActiveSchemaLookupTypes = createSelector(
  [selectActiveSchemaLookups],
  ls => [...new Set(flatMap(ls, l => l.keys))],
);

export const selectActiveSchemaEditors = createSelector(
  [selectActiveSchemaAnnotationTypes],
  annotationTypes => {
    const configs = flatMap(annotationTypes || [], t =>
      map(t.editorConfigs, c => {
        return { annotationType: t.key, ...c };
      }),
    );
    return configs;
  },
);

export const selectActiveSchemaValidators = createSelector(
  [selectActiveSchemaAnnotationTypes],
  annotationTypes => {
    const configs = flatMap(annotationTypes || [], t =>
      map(t.validatorConfigs, c => {
        return { annotationType: t.key, ...c };
      }),
    );
    return configs;
  },
);
