import { isEqual } from 'lodash';
import React from 'react';

export function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
export function delayed(ms: number, f: () => void) {
  (async () => {
    await delay(ms);
    f();
  })();
  return;
}

export function useForceUpdate() {
  // eslint-disable-next-line
  const [_value, setValue] = React.useState(0);
  return () => setValue(value => value + 1);
}

export function useDeepEffect(effectFunc, deps) {
  const isFirst = React.useRef(true);
  const prevDeps = React.useRef(deps);

  React.useEffect(() => {
    const isSame = prevDeps.current.every((obj, index) =>
      isEqual(obj, deps[index]),
    );
    if (isFirst.current || !isSame) {
      effectFunc();
    }
    isFirst.current = false;
    prevDeps.current = deps;
    // eslint-disable-next-line
    }, deps);
}

type Callback = () => void;
type UpdateWithCallback<V> = (V, Callback) => void;

// same as useState, except setState function accepts callback to run upon state update
// use instead of useState+useEffect in cases where you need to more directly match a particular state update with a particular effect
export function useStateEffect<V>(initVal: V) {
  const callbackList = React.useRef<Callback[]>([]);
  const [state, setState] = React.useState<V>(initVal);
  const setStateWithCallback = (val: V, callback?: Callback) => {
    setState(val);
    if (callback) callbackList.current.push(callback);
  };
  React.useEffect(() => {
    callbackList.current.forEach(callback => callback());
    callbackList.current = [];
  }, [state]);
  const hook: [V, UpdateWithCallback<V>] = [state, setStateWithCallback];
  return hook;
}

// same as useStateEffect, except state reverts to initial value upon callback completion
export function useTempStateEffect<V>(initVal: V) {
  const [state, setState] = useStateEffect(initVal);
  const tempUpdate = (val: V, f: Callback) => {
    const run = () => {
      f();
      setState(initVal, undefined);
    };
    setState(val, run);
  };
  const hook: [V, UpdateWithCallback<V>] = [state, tempUpdate];
  return hook;
}

// provides stateful tracking of synchronous function calls
// calling 'withFlag' sets 'inProgress' to true, and reverts it upon completion of provided callback
export function useStatusFlag() {
  const [inProgress, setInProgress] = useTempStateEffect(false);
  const withFlag = (f: Callback) => setInProgress(true, f);
  const hook: [boolean, (c: Callback) => void] = [inProgress, withFlag];
  return hook;
}
