import { getType } from 'typesafe-actions';
import { populateRoles } from '../actions/role.actions';
import { RoleActions } from '../actions/role.types';
import { Role } from '../models/role.model';
export const defaultRoleState: RoleState = {
  entities: [],
};

export type RoleState = {
  entities: Role[];
};

export function rolesReducer(
  state: RoleState = defaultRoleState,
  action: RoleActions,
): RoleState {
  switch (action.type) {
    case getType(populateRoles):
      return {
        ...state,
        entities: action.payload,
      };
    default:
      return state;
  }
}
