import { MutableRefObject, useEffect, useRef } from 'react';

/* tslint:disable */
/**
 * This hook is useful for scenarios where
 * you need to use setInterval inside of a useEffect hook
 * (setInterval will not update state as it is only accessed on first render)
 *
 * @param callback the callback that gets called for the interval
 *
 * @param delay the delay for the interval
 */
export function useInterval(
  callback: () => unknown | void,
  delay: number | null,
) {
  const savedCallback = useRef<Function | null>(null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  });

  // Set up the interval.
  useEffect(() => {
    if (delay === null) return;
    function tick(funcCallback: MutableRefObject<Function | null>) {
      if (funcCallback.current !== null) {
        funcCallback.current();
      }
    }
    const id = setInterval(() => tick(savedCallback), delay);
    return () => clearInterval(id);
  }, [delay]);
}
/* tslint:enable */
