import { Card, Table } from 'antd';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { QueueCreator } from '../containers/queue-creator.container';
import { Queue } from '../models/queue.model';
import { DeleteQueueButton } from './delete-queue-button.component';
import { DateComponent } from '../../common/components/date.component';
import { tableSearchProps } from '../../common/utils/tableSearchProps';

export type QueueViewerDispatchProps = {
  fetchQueues: () => unknown;
  deleteQueue: (queueId: number) => unknown;
};

export type QueueViewerProps = {
  queues: Queue[];
} & QueueViewerDispatchProps;

const QueueDiv = styled.div`
  overflow: auto;
  max-height: calc(100vh - 76px);
  width: 100%;
  margin-top: 1em;
`;

export const QueueViewerComponent: React.FC<QueueViewerProps> = ({
  queues,
  deleteQueue,
  fetchQueues,
}) => {
  useEffect(() => {
    fetchQueues();
  }, [fetchQueues]);
  const columns = [
    {
      title: <strong>Queue ID</strong>,
      dataIndex: 'queueId',
      rowKey: 'queueId',
      width: '10%',
      sorter: (a: Queue, b: Queue) => a.queueId - b.queueId,
    },
    {
      title: <strong>Queue Name</strong>,
      dataIndex: 'queueName',
      rowKey: 'queueName',
      width: '40%',
      sorter: (a: Queue, b: Queue) => a.queueName.localeCompare(b.queueName),
      ...tableSearchProps('queueName'),
    },
    {
      title: <strong>Creator Id</strong>,
      dataIndex: 'creatorId',
      rowKey: 'creatorId',
      width: '10%',
      sorter: (a: Queue, b: Queue) => a.creatorId - b.creatorId,
    },
    {
      title: <strong>Group Id</strong>,
      dataIndex: 'groupId',
      rowKey: 'groupId',
      width: '10%',
      sorter: (a: Queue, b: Queue) => a.groupId - b.groupId,
    },
    {
      title: <strong>Created Date</strong>,
      dataIndex: 'createdAt',
      rowKey: 'createdAt',
      width: '20%',
      render: d => <DateComponent serializedDate={d} />,
      sorter: (a: Queue, b: Queue) => a.createdAt.localeCompare(b.createdAt),
    },
    {
      title: <strong>Action</strong>,
      dataIndex: '',
      rowKey: 'action',
      width: '10%',
      render: (_: any, queue: Queue) => (
        <DeleteQueueButton queueId={queue.queueId} onDelete={deleteQueue} />
      ),
    },
  ];
  return (
    <Card style={{ height: '85vh' }}>
      <QueueCreator />
      <QueueDiv>
        <Table
          dataSource={queues}
          columns={columns}
          pagination={false}
          scroll={{ y: '70vh' }}
          size="middle"
          rowKey={(queue: any) => queue.queueId}
        />
      </QueueDiv>
    </Card>
  );
};
