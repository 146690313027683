import { createAction } from 'typesafe-actions';

enum Types {
  DOWNLOAD_FILE = 'DOWNLOAD_FILE',
  DOWNLOAD_DOCUMENT_BY_ID = 'DOWNLOAD_DOCUMENT_BY_ID',
}

export const downloadFile = createAction(
  Types.DOWNLOAD_FILE,
  resolvePayload => (path: string, saveAs: string) =>
    resolvePayload({ path, saveAs }),
);

export const downloadDocumentById = createAction(
  Types.DOWNLOAD_DOCUMENT_BY_ID,
  resolvePayload => (annotatedDocumentId: number, saveAs: string) =>
    resolvePayload({ annotatedDocumentId, saveAs }),
);
