import { of } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AppEpic } from '../../common/types/app-epic.type';
import { handleEpicError } from '../../common/utils/epics';
import { openDocument } from '../../documents/actions/source-document.actions';
import {
  getNextQueueItem,
  setCurrentQueueItem,
} from '../actions/queue.actions';
import { WorkableQueueItem } from '../models/workable-queue-item.model';
import { QueuesResource } from '../resources/queues.resource';

export const getNextQueueItemEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(getNextQueueItem)),
    switchMap(action =>
      QueuesResource.getNextItem(state$.value, action.payload.queueId).pipe(
        switchMap((item: WorkableQueueItem) =>
          of(
            setCurrentQueueItem(WorkableQueueItem.toQueueItem(item)),
            openDocument(item.annotatedDocument.annotatedDocumentId, true),
          ),
        ),
        catchError(
          handleEpicError(
            'Could not get the next item. There are no more workable items in this queue.',
          ),
        ),
      ),
    ),
  );
