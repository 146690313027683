import { map } from 'lodash';
import { FlatTree } from './annotation-table-settings.model';
import { Annotation } from './annotation.model';
import { SimpleTreeNode } from './simple-tree.model';

export interface AnnotationTableRow {
  annotationId: string;
  annotation: Annotation;
  treePath: string[];
  visible: boolean;
}

export const AnnotationTableRow = {
  /**
   * Creates annotation table rows from an array of annotations.
   * Requires a flattened annotation tree for relational references.
   */
  createMany: (
    annotations: Annotation[],
    flatTree: FlatTree,
  ): AnnotationTableRow[] => {
    const result = map(annotations, (atn: Annotation) => {
      const treePath =
        flatTree[atn.id] !== undefined
          ? AnnotationTableRow.treePath(flatTree[atn.id], flatTree)
          : [atn.id];
      return {
        annotationId: atn.id,
        annotation: atn,
        visible: true,
        treePath,
      };
    });
    return result;
  },

  /**
   * Returns the tree path of an annotation as an array of ids,
   * beginning with the root id.
   * @returns string[]
   */
  treePath: (
    treeNode: SimpleTreeNode<Annotation>,
    tree: FlatTree,
  ): string[] => {
    if (!treeNode.parent) {
      return [treeNode.value.id];
    }

    return [
      ...AnnotationTableRow.treePath(tree[treeNode.parent.id], tree),
      treeNode.value.id,
    ];
  },

  /**
   * Returns the calculated number of lines a value cell within the
   * annotation table will require, given a row node and the current
   * column width.
   * @returns number
   */
  valueCellLineCount: (row: AnnotationTableRow, colWidth: number): number => {
    const charWidthEstimate = 6.2;
    // Narrower columns wrap more often.
    const wrapMultiplier = colWidth < 200 ? 1.15 : 1;
    const rowValue = row.annotation.value;
    const numChars = rowValue ? rowValue.length : 0;
    return Math.ceil(
      (wrapMultiplier * (numChars * charWidthEstimate)) / colWidth,
    );
  },

  /**
   * Returns the calculated height a table row will require to fully
   * display the content of a value cell.
   * @returns number
   */
  valueCellHeight: (row: AnnotationTableRow, colWidth: number): number => {
    const lineHeight = 18;
    const padding = 8;
    const lines = AnnotationTableRow.valueCellLineCount(row, colWidth);

    return lines * lineHeight + padding;
  },
};
