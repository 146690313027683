import React from 'react';

// https://www.flaticon.com/free-icon/square_483790
export const SelectionSquareSvg = (
  <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 469.333 469.333">
    <g>
      <g>
        <g>
          <path d="M466.211,387.125L316.877,237.792c-3.042-3.052-7.646-3.948-11.625-2.313c-3.979,1.646-6.583,5.542-6.583,9.854v213.333c0,4.313,2.604,8.208,6.583,9.854c1.313,0.552,2.708,0.813,4.083,0.813c2.771,0,5.5-1.083,7.542-3.125l60.875-60.875h80.917c4.313,0,8.208-2.594,9.854-6.583C470.169,394.76,469.252,390.177,466.211,387.125z" />
          <path d="M53.336,341.333H42.669v-10.667c0-11.781-9.542-21.333-21.333-21.333s-21.333,9.552-21.333,21.333v32C0.002,374.448,9.544,384,21.336,384h32c11.792,0,21.333-9.552,21.333-21.333S65.127,341.333,53.336,341.333z" />
          <path d="M21.336,181.333c11.792,0,21.333-9.552,21.333-21.333v-42.667C42.669,105.552,33.127,96,21.336,96s-21.333,9.552-21.333,21.333V160C0.002,171.781,9.544,181.333,21.336,181.333z" />
          <path d="M21.336,288c11.792,0,21.333-9.552,21.333-21.333V224c0-11.781-9.542-21.333-21.333-21.333S0.002,212.219,0.002,224v42.667C0.002,278.448,9.544,288,21.336,288z" />
          <path d="M53.336,0h-32C9.544,0,0.002,9.552,0.002,21.333v32c0,11.781,9.542,21.333,21.333,21.333s21.333-9.552,21.333-21.333V42.667h10.667c11.792,0,21.333-9.552,21.333-21.333S65.127,0,53.336,0z" />
          <path d="M160.002,0h-42.667c-11.792,0-21.333,9.552-21.333,21.333s9.542,21.333,21.333,21.333h42.667c11.792,0,21.333-9.552,21.333-21.333S171.794,0,160.002,0z" />
          <path d="M266.669,0h-42.667c-11.792,0-21.333,9.552-21.333,21.333s9.542,21.333,21.333,21.333h42.667c11.792,0,21.333-9.552,21.333-21.333S278.461,0,266.669,0z" />
          <path d="M160.002,341.333h-42.667c-11.792,0-21.333,9.552-21.333,21.333S105.544,384,117.336,384h42.667c11.792,0,21.333-9.552,21.333-21.333S171.794,341.333,160.002,341.333z" />
          <path d="M266.669,341.333h-42.667c-11.792,0-21.333,9.552-21.333,21.333S212.211,384,224.002,384h42.667c11.792,0,21.333-9.552,21.333-21.333S278.461,341.333,266.669,341.333z" />
          <path d="M330.669,42.667h10.667v10.667c0,11.781,9.542,21.333,21.333,21.333c11.792,0,21.333-9.552,21.333-21.333v-32C384.002,9.552,374.461,0,362.669,0h-32c-11.792,0-21.333,9.552-21.333,21.333S318.877,42.667,330.669,42.667z" />
          <path d="M362.669,202.667c-11.792,0-21.333,9.552-21.333,21.333v21.333c0,11.781,9.542,21.333,21.333,21.333c11.792,0,21.333-9.552,21.333-21.333V224C384.002,212.219,374.461,202.667,362.669,202.667z" />
          <path d="M384.002,117.333c0-11.781-9.542-21.333-21.333-21.333c-11.792,0-21.333,9.552-21.333,21.333V160c0,11.781,9.542,21.333,21.333,21.333c11.792,0,21.333-9.552,21.333-21.333V117.333z" />
        </g>
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);
