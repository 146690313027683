import { getType } from 'typesafe-actions';
import {
  fetchAnnotations,
  setAnnotationSaveEvent,
} from '../../annotations/actions/annotations.actions';
import { AnnotationsActions } from '../../annotations/actions/annotations.types';
import { closeDocument } from '../../documents/actions/source-document.actions';
import { SourceDocumentActions } from '../../documents/actions/source-document.types';
import {
  fetchSaveEventReview,
  setAnnotationReviewStatus,
  setReviewingSaveEventId,
} from '../actions/annotation-review.actions';
import { AnnotationReviewActions } from '../actions/annotation-review.types';

export type AnnotationReviewState = {
  // Annotation save event id being reviewed.
  reviewSaveEventId: number | null;
  completed: boolean | null;
  loading: boolean;
};

const defaultAnnotationReviewState = {
  reviewSaveEventId: null,
  completed: null,
  loading: false,
};

export function annotationReviewReducer(
  state: AnnotationReviewState = defaultAnnotationReviewState,
  action: AnnotationsActions | AnnotationReviewActions | SourceDocumentActions,
): AnnotationReviewState {
  switch (action.type) {
    case getType(setAnnotationSaveEvent):
      const saveEvent = action.payload.saveEvent;
      return {
        ...state,
        reviewSaveEventId: saveEvent.annotationSaveEventId,
      };

    case getType(setAnnotationReviewStatus):
      return {
        ...state,
        completed: action.payload.completed,
      };

    case getType(setReviewingSaveEventId):
      return {
        ...state,
        reviewSaveEventId: action.payload.saveEventId,
        loading: false,
      };

    case getType(fetchSaveEventReview):
      return {
        ...state,
        loading: true,
      };

    case getType(closeDocument):
    case getType(fetchAnnotations):
      // Clear review state when loading new annotations.
      return defaultAnnotationReviewState;

    default:
      return state;
  }
}
