import { get } from 'lodash';
import { match } from 'react-router-dom';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { EMPTY } from 'rxjs';
import { empty, from, of } from 'rxjs';
import { AjaxError } from 'rxjs/internal-compatibility';
import {
  catchError,
  concat,
  concatMapTo,
  debounceTime,
  filter,
  first,
  map,
  mergeMap,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { postAnnotationReviewSuccess } from '../../annotation-review/actions/annotation-review.actions';
import { setSchemaForDocumentSet } from '../../annotations/actions/annotation-schemas.actions';
import { saveAnnotationsSuccess } from '../../annotations/actions/annotations.actions';
import { selectAnnotationSchemasExist } from '../../annotations/selectors/annotation-schema.selectors';
import {
  AnnotationPageRouteAction,
  AnnotationPageRouteParams,
  AppRoutes,
  pathWithParams,
} from '../../app-routes';
import { AppState } from '../../app-state';
import { selectUserIsLoggedIn } from '../../auth/selectors/auth.selectors';
import { displayErrorNotification } from '../../common/actions/notification.actions';
import { AppEpic } from '../../common/types/app-epic.type';
import { handleEpicError } from '../../common/utils/epics';
import { history } from '../../history';
import { AppActions } from '../../root.actions';
import { fetchDocumentsError } from '../actions/document-list.actions';
import {
  clearSourceDocumentUrl,
  deleteDocumentAsync,
  fetchAndSetSourceDocument,
  fetchSourceDocumentFromUrl,
  lockSourceDocument,
  openDocument,
  refreshActiveSourceDocumentMeta,
  refreshSourceDocumentLock,
  setSourceDocument,
  setSourceDocumentLockStatusEpicOnly,
  setSourceDocumentUrl,
  updateDocumentMetadataAsync,
  updateSourceDocument,
  upgradeSourceDocumentLock,
} from '../actions/source-document.actions';
import { AnnotatedDocument } from '../models/annotated-document.model';
import { DocumentLock } from '../models/document-lock-status.model';
import { DocumentSummaryResource } from '../resources/document-summary.resource';
import { DocumentResource } from '../resources/document.resource';
import { SourceDocumentUrlResource } from '../resources/source-document-url.resource';
import {
  selectActiveSourceDocument,
  selectSourceDocumentIsEditable,
} from '../selectors/source-document.selectors';

export function getSourceUrlEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(setSourceDocument)),
    switchMap(action => {
      const { annotatedDocumentId } = action.payload.document;
      return SourceDocumentUrlResource.get(
        state$.value,
        annotatedDocumentId,
      ).pipe(
        map(resource =>
          setSourceDocumentUrl(annotatedDocumentId, resource.url),
        ),
        catchError((error: AjaxError) => {
          const clearAction = clearSourceDocumentUrl(annotatedDocumentId);
          return error.status === 404
            ? of(clearAction)
            : handleEpicError(
                'Error fetching PDF URL',
                () => clearAction,
              )(error);
        }),
      );
    }),
  );
}

export function refreshSourceDocument(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(
      isActionOf([
        refreshActiveSourceDocumentMeta,
        saveAnnotationsSuccess,
        postAnnotationReviewSuccess,
      ]),
    ),
    // Saving annotations & posting a review happen consecutively.
    // Debouncing helps avoid redundant document updates.
    debounceTime(2000),
    filter(() => !!state$.value.sourceDocument.meta),
    switchMap(() => {
      const state = state$.value;
      const doc = state.sourceDocument.meta as AnnotatedDocument;

      return DocumentSummaryResource.get(state, doc.annotatedDocumentId).pipe(
        map(docSummaryResource =>
          updateSourceDocument(docSummaryResource.documentSummary),
        ),
        catchError(
          handleEpicError(
            'Error updating source document',
            fetchDocumentsError,
          ),
        ),
      );
    }),
  );
}

function lockDocument(document: AnnotatedDocument, appState: AppState) {
  const annotatedDocumentId = document.annotatedDocumentId;
  return DocumentSummaryResource.lock(appState, annotatedDocumentId).pipe(
    map(DocumentLock.mapFromResource),
    map(lockStatus => setSourceDocumentLockStatusEpicOnly(lockStatus)),
    catchError((error: AjaxError) => {
      return from([
        displayErrorNotification(
          `Document lock error.`,
          get(error, 'response.error', null),
        ),
        setSourceDocumentLockStatusEpicOnly(DocumentLock.defaultNoLock()),
      ]);
    }),
  );
}

export function lockNewSourceDocumentEpic(
  action$: ActionsObservable<AppActions>,
  _: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(setSourceDocument)),
    filter(action => action.payload.lockForEditing),
    map(action => lockSourceDocument(action.payload.document)),
  );
}

export function lockSourceDocumentEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(lockSourceDocument)),
    switchMap(action => lockDocument(action.payload.document, state$.value)),
  );
}

const REFRESH_LOCK_EVERY_MS = 40000;
export function refreshDocumentLockEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf([lockSourceDocument, refreshSourceDocumentLock])),
    debounceTime(REFRESH_LOCK_EVERY_MS),
    takeWhile(() => selectSourceDocumentIsEditable(state$.value)),
    switchMap(_ => {
      const appState = state$.value;
      const doc = appState.sourceDocument.meta as AnnotatedDocument;
      return lockDocument(doc, appState).pipe(
        concat(of(refreshSourceDocumentLock())),
      );
    }),
  );
}

export function upgradeDocumentLockEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(upgradeSourceDocumentLock)),
    switchMap(() => {
      const doc = selectActiveSourceDocument(state$.value);
      if (doc) {
        return of(openDocument(doc.annotatedDocumentId, true));
      } else {
        return empty();
      }
    }),
  );
}

// Triggers fetch of correct document on url change on annotation page,
// if a source document and action are specified.
export const fetchSourceDocumentFromUrlEpic: AppEpic = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(fetchSourceDocumentFromUrl)),
    filter(() => selectUserIsLoggedIn(state$.value)),
    map(({ payload }) => payload),
    filter(isNonNullMatch),
    map((matched: match<AnnotationPageRouteParams>) => {
      const { id, action } = matched.params;
      const annotatedDocumentId = parseInt(id, 10);
      const lockForEditing = action === 'edit' ? true : false;

      return fetchAndSetSourceDocument(annotatedDocumentId, lockForEditing);
    }),
  );
};

const isNonNullMatch = (
  x: match<AnnotationPageRouteParams> | null,
): x is match<AnnotationPageRouteParams> => !!x;

// Sets the active source document using the annotatedDocumentId.
// If the AnnotatedDocument does not exist in the store, will first make
// a GET request to the service for the document.
export const fetchAndSetSourceDocumentEpic: AppEpic = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(fetchAndSetSourceDocument)),
    mergeMap(action => {
      const { annotatedDocumentId, lockForEditing } = action.payload;
      // Emits only after annotation schemas are populated.
      const annotationSchemasPopulated$ = state$.pipe(
        filter(selectAnnotationSchemasExist),
        first(),
      );
      return AnnotatedDocument.get(annotatedDocumentId, state$.value).pipe(
        mergeMap(doc =>
          // Set the document schema only after annotations schemas have been populated.
          annotationSchemasPopulated$.pipe(
            concatMapTo(
              of(
                setSchemaForDocumentSet(
                  doc.documentSetName,
                  doc.instrumentType,
                ),
                setSourceDocument(doc, lockForEditing),
              ),
            ),
          ),
        ),
        catchError(() => EMPTY),
      );
    }),
  );
};

export const openDocumentEpic: AppEpic = action$ =>
  action$.pipe(
    filter(isActionOf(openDocument)),
    tap(({ payload: { id, lockForEditing } }) => {
      const action: AnnotationPageRouteAction = lockForEditing
        ? 'edit'
        : 'view';
      history.push(pathWithParams(AppRoutes.AnnotationPage, { id, action }));
    }),
    switchMap(_ => empty()),
  );

export const updateDocumentMetadataEpic: AppEpic = (actions$, state$) =>
  actions$.pipe(
    filter(isActionOf(updateDocumentMetadataAsync.request)),
    switchMap(action =>
      DocumentResource.updateDocumentMetadata(
        state$.value,
        action.payload,
      ).pipe(
        map(() =>
          updateDocumentMetadataAsync.success({
            originalRequest: action.payload,
          }),
        ),
        catchError(err =>
          of(
            updateDocumentMetadataAsync.failure({
              originalRequest: action.payload,
              error: err,
            }),
          ),
        ),
      ),
    ),
  );
export const deleteDocumentEpic: AppEpic = (
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) =>
  action$.pipe(
    filter(isActionOf(deleteDocumentAsync.request)),
    switchMap(action =>
      DocumentResource.deleteDocument(state$.value)(
        action.payload.documentSetId,
        action.payload.annotatedDocumentId,
      ).pipe(
        map(deleteDocumentAsync.success),
        catchError(e => {
          const defaultErrorMessage = 'Server Error';
          const errorMessage = e.response
            ? e.response.error || defaultErrorMessage
            : defaultErrorMessage;
          return handleEpicError(
            `Error deleting Document: ${errorMessage}.`,
            deleteDocumentAsync.failure,
          )(e);
        }),
      ),
    ),
  );
