import { filter, includes, values } from 'lodash';
import { sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { Permission } from '../../accounts/models/permission.model';
import { selectActiveUserPermissions } from '../../accounts/selectors/users.selectors';
import { AppState } from '../../app-state';
import { ResourceIdRangeHandler } from '../../common/utils/resource-id-range-handler';
import DocumentSetUploadPolicy from '../models/document-set-upload-policy.model';
import { DocumentSet } from '../models/document-set.model';

export const selectDocumentSets = (state: AppState) =>
  state.documentSets.entities;

export const selectDocumentSetUploadPolicies = (state: AppState) =>
  state.documentSets.uploadPolicies;

export const selectDocumentSetsArray = createSelector(
  [selectDocumentSets],
  ds => sortBy(values(ds) || [], d => d.name.toLowerCase()),
);

export const selectDocumentSetsCanAnnotate = createSelector(
  [selectDocumentSetsArray, selectActiveUserPermissions],
  (documentSets: DocumentSet[], permissions: Permission[]) => {
    const resourceIds = ResourceIdRangeHandler.activeDocumentSetIdsUserCanAdministrate(
      permissions,
    );
    if (includes(resourceIds, 'admin')) {
      return documentSets;
    }
    return filter(documentSets, doc =>
      includes(resourceIds, doc.documentSetId),
    );
  },
);
export const selectDocumentSetUploadPolicyByDocSetId = (
  docSetId: number,
  // need to specify this function type because the outcome of this access
  // could technically be undefined even though the Dictionary type doesn't
  // reflect that (for ease of development reasons)
): ((state: AppState) => DocumentSetUploadPolicy | null) =>
  createSelector(
    [selectDocumentSetUploadPolicies],
    policies => policies[docSetId] || null,
  );
