import { getType } from 'typesafe-actions';
import { fetchAnnotationsError } from '../../annotations/actions/annotations.actions';
import { AnnotationsActions } from '../../annotations/actions/annotations.types';
import { AuthActions } from '../../auth/actions/auth.types';
import {
  clearSourceDocumentUrl,
  closeDocument,
  deleteDocumentAsync,
  lockSourceDocument,
  setSourceDocument,
  setSourceDocumentLockStatusEpicOnly,
  setSourceDocumentUrl,
  sourceDocumentError,
  sourceDocumentLoaded,
  updateSourceDocument,
} from '../actions/source-document.actions';
import { SourceDocumentActions } from '../actions/source-document.types';
import { AnnotatedDocument } from '../models/annotated-document.model';
import {
  DOCUMENT_LOCK_STATUS,
  DocumentLock,
} from '../models/document-lock-status.model';

export type SourceDocumentState = {
  pageCount: number;
  loading: boolean;
  documentLock: DocumentLock;
  error: string | null;
  meta: AnnotatedDocument | null;
  url: string | null;
};

const defaultSourceDocument = {
  pageCount: 0,
  url: null,
  loading: false,
  documentLock: DocumentLock.defaultNoLock(),
  error: null,
  meta: null,
};

export function sourceDocumentReducer(
  state: SourceDocumentState = defaultSourceDocument,
  action: SourceDocumentActions | AuthActions | AnnotationsActions,
): SourceDocumentState {
  switch (action.type) {
    case getType(sourceDocumentError):
      return {
        ...state,
        error: action.payload.error,
      };

    case getType(setSourceDocument): {
      const newDoc = action.payload.document;
      const oldId = state.meta ? state.meta.annotatedDocumentId : null;
      const isNewDocument = newDoc.annotatedDocumentId !== oldId;
      const documentLock = isNewDocument
        ? DocumentLock.defaultNoLock()
        : state.documentLock;
      return isNewDocument || state.error
        ? {
            ...state,
            meta: newDoc,
            loading: isNewDocument,
            documentLock,
            error: null,
          }
        : state;
    }

    case getType(clearSourceDocumentUrl): {
      const { annotatedDocumentId } = action.payload;
      const currentDocId = state.meta ? state.meta.annotatedDocumentId : null;
      return currentDocId && currentDocId === annotatedDocumentId
        ? { ...state, url: null }
        : state;
    }

    case getType(setSourceDocumentUrl): {
      const { annotatedDocumentId, url } = action.payload;
      const currentDocId = state.meta ? state.meta.annotatedDocumentId : null;
      if (currentDocId && currentDocId === annotatedDocumentId) {
        return { ...state, url };
      } else {
        return state;
      }
    }

    case getType(updateSourceDocument): {
      const updateDoc = action.payload.document;
      const oldDoc = state.meta;
      if (!oldDoc || updateDoc.aggregateId !== oldDoc.aggregateId) {
        console.warn(
          'Cannot update source document with a new document. Use SET_SOURCE_DOCUMENT',
        );
        return state;
      } else {
        return {
          ...state,
          meta: { ...updateDoc },
        };
      }
    }

    case getType(sourceDocumentLoaded):
      return {
        ...state,
        pageCount: action.payload.pageCount,
        loading: false,
      };

    case getType(setSourceDocumentLockStatusEpicOnly):
      return {
        ...state,
        documentLock: action.payload.lockStatus,
      };

    case getType(lockSourceDocument):
      return {
        ...state,
        documentLock: {
          ...state.documentLock,
          status: DOCUMENT_LOCK_STATUS.PENDING,
        },
      };

    case getType(deleteDocumentAsync.success):
      return defaultSourceDocument;

    // If an error occurs while fetching annotations, remove active document lock
    // So that the user is not working a document in with unsynced annotation state.
    case getType(fetchAnnotationsError):
      return {
        ...state,
        documentLock: {
          ...state.documentLock,
          status: DOCUMENT_LOCK_STATUS.NONE,
        },
      };

    case getType(closeDocument):
      return defaultSourceDocument;

    default:
      return state;
  }
}
