import { getType } from 'typesafe-actions';
import {
  authenticateFailure,
  authenticateSuccess,
  showLoginModal,
  signOut,
} from '../actions/auth.actions';
import { AuthActions } from '../actions/auth.types';
export type AuthState = {
  jwt: string | null;
  authError: string | null;
  showLoginModal: boolean;
};

const defaultAuthState = {
  jwt: null,
  authError: null,
  showLoginModal: false,
};

export function authReducer(
  state: AuthState = defaultAuthState,
  action: AuthActions,
): AuthState {
  switch (action.type) {
    case getType(authenticateSuccess):
      return {
        ...state,
        jwt: action.payload.jwt,
        authError: null,
        showLoginModal: false,
      };

    case getType(showLoginModal):
      return {
        ...state,
        showLoginModal: true,
      };

    case getType(authenticateFailure):
      return {
        ...state,
        authError: action.payload.error,
      };

    case getType(signOut):
      return {
        ...state,
        authError: null,
        jwt: null,
      };

    default:
      return state;
  }
}
