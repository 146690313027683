import { Popover } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { selectUserId } from '../../accounts/selectors/users.selectors';
import { selectAvailableDocumentStatusStrings } from '../../annotations/selectors/document-status.selectors';
import { AppState } from '../../app-state';
import {
  fetchDocuments,
  setDocumentListFilter,
  setDocumentListPage,
  setDocumentListSort,
} from '../actions/document-list.actions';
import {
  closeDocumentSelector,
  openDocumentSelector,
} from '../actions/document-selector.actions';
import { DocumentSelector as DocumentSelectorWindow } from '../components/document-selector.component';
import { AnnotatedDocument } from '../models/annotated-document.model';
import { DocumentListFilter } from '../models/document-list-filter.model';
import {
  selectDocumentListArray,
  selectDocumentListCurrentPage,
  selectDocumentListFilter,
  selectDocumentListIsLoading,
  selectDocumentListPageCount,
  selectDocumentListSort,
  selectDocumentListTotalItems,
} from '../selectors/document-list.selectors';
import { selectDocumentSelectorIsVisible } from '../selectors/ui.selecetors';
import { OpenDocumentSelectorButton } from './open-document-selector-button.container';
import { QuerySort } from '../../common/types/QuerySort';

type DocumentSelectorDispatchProps = {
  onSearch: (filter: DocumentListFilter) => void;
  onVisibleChange: (visible: boolean) => void;
  onDocumentSelected: () => void;
  onSelectPage: (page: number) => void;
  onSort: (val: string, order: string) => void;
};
type DocumentSelectorProps = {
  documents: AnnotatedDocument[];
  filter: DocumentListFilter;
  visible: boolean;
  loading: boolean;
  currentPage: number;
  totalPages: number;
  totalItems: number;
  currentUserId: number | null;
  availableStatuses: string[];
  sort: QuerySort | null;
} & DocumentSelectorDispatchProps;

export class DocumentSelector extends React.Component<DocumentSelectorProps> {
  render() {
    return (
      <Popover
        overlayStyle={{ width: '100%' }}
        content={
          <DocumentSelectorWindow
            {...this.props}
            onSearch={filter => this.props.onSearch(filter)}
            onClose={_ => this.props.onVisibleChange(false)}
            onDocumentSelected={this.props.onDocumentSelected}
            onSelectPage={page => this.props.onSelectPage(page)}
            onSort={(field, value) => this.props.onSort(field, value)}
            sort={this.props.sort}
          />
        }
        visible={this.props.visible}
        mouseLeaveDelay={1}
      >
        <OpenDocumentSelectorButton />
      </Popover>
    );
  }
}

export const DocumentSelectorPopover = connect(
  (state: AppState) => ({
    documents: selectDocumentListArray(state),
    visible: selectDocumentSelectorIsVisible(state),
    loading: selectDocumentListIsLoading(state),
    filter: selectDocumentListFilter(state),
    currentPage: selectDocumentListCurrentPage(state),
    currentUserId: selectUserId(state),
    totalPages: selectDocumentListPageCount(state),
    totalItems: selectDocumentListTotalItems(state),
    availableStatuses: selectAvailableDocumentStatusStrings(state),
    sort: selectDocumentListSort(state),
  }),
  (dispatch): DocumentSelectorDispatchProps => ({
    onVisibleChange: visible => {
      visible
        ? dispatch(openDocumentSelector())
        : dispatch(closeDocumentSelector());
    },
    onSearch: filter => {
      dispatch(setDocumentListPage(1));
      dispatch(setDocumentListFilter(filter));
      dispatch(fetchDocuments());
    },
    onDocumentSelected: () => {
      dispatch(closeDocumentSelector());
    },
    onSelectPage: page => {
      dispatch(setDocumentListPage(page));
      dispatch(fetchDocuments());
    },
    onSort: (field: string, value: string) => {
      dispatch(setDocumentListSort(field, value as 'ascend' | 'descend'));
      dispatch(fetchDocuments());
    },
  }),
)(DocumentSelector);
