import React from 'react';
import styled from 'styled-components';
import { SchemaViewer } from '../containers/schema-viewer.container';

const SchemaManagerStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SchemaManager: React.SFC = () => (
  <SchemaManagerStyle>
    <SchemaViewer />
  </SchemaManagerStyle>
);
