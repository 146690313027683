export interface Rect {
  x: number;
  y: number;
  width: number;
  height: number;
}

export const Rect = {
  mapFromRTreeRect(rect: { x: number; y: number; w: number; h: number }) {
    return {
      x: rect.x,
      y: rect.y,
      width: rect.w,
      height: rect.h,
    };
  },
};
