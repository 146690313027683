import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { map } from 'rxjs/internal/operators/map';
import { AppState } from '../../app-state';
import {
  DocumentSummaryResource,
  OcrStatus,
} from '../resources/document-summary.resource';
import { selectAnnotatedDocumentById } from '../selectors/document-list.selectors';
import { DocumentAnnotations } from './document-annotations.model';
import { RawMetadata } from './raw-metadata.model';

export type AnnotatedDocument = {
  annotatedDocumentId: number;
  aggregateId: string;
  uri: string;
  instrumentType?: string;
  recordedDate: string;
  county: string;
  state: string;
  documentSetName: string;
  documentSetId: number;
  annotatedDate?: string;
  annotations?: DocumentAnnotations;
  isAnnotated: boolean;
  documentTextId: number;
  docImageBucket: string;
  docImageKey: string;
  lastAnnotationSaveEventId?: number;
  lastAnnotationTimestamp?: string;
  lastAnnotatorName?: string;
  reviewCompleted: boolean;
  lastReviewerId?: number;
  lastReviewerName?: string;
  lastReviewTimestamp?: string;
  isLocked: boolean;
  lockedByUserId?: number;
  ocrStatus: OcrStatus;
  metadata: RawMetadata;
};

export const AnnotatedDocument = {
  // Returns observable of an annotated document, checking the state store first
  // before retrieving the document from the api.
  get: (
    annotatedDocumentId: number,
    appState: AppState,
  ): Observable<AnnotatedDocument> => {
    const existingDoc = selectAnnotatedDocumentById(appState, {
      annotatedDocumentId,
    });

    return existingDoc
      ? of(existingDoc)
      : DocumentSummaryResource.get(appState, annotatedDocumentId).pipe(
          map(doc => doc.documentSummary),
        );
  },
};
