import { match } from 'react-router-dom';
import {
  createAction,
  createAsyncAction,
  createStandardAction,
} from 'typesafe-actions';
import { AnnotationPageRouteParams } from '../../app-routes';
import { AnnotatedDocument } from '../models/annotated-document.model';
import { DocumentLock } from '../models/document-lock-status.model';
import { RawMetadata } from '../models/raw-metadata.model';

enum Types {
  LOCK_SOURCE_DOCUMENT = 'LOCK_SOURCE_DOCUMENT',
  LOCK_SOURCE_DOCUMENT_ERROR = 'LOCK_SOURCE_DOCUMENT_ERROR',
  REFRESH_ACTIVE_SOURCE_DOCUMENT_META = 'REFRESH_ACTIVE_SOURCE_DOCUMENT_META: Refreshes active source document metadata (see AnnotatedDocument).',
  REFRESH_SOURCE_DOCUMENT_LOCK = 'REFRESH_SOURCE_DOCUMENT_LOCK',
  SET_SOURCE_DOCUMENT = 'SET_SOURCE_DOCUMENT: Sets a new source document. Any followup actions like requesting annotations should follow this action.',
  SET_SOURCE_DOCUMENT_LOCK_STATUS = 'SET_SOURCE_DOCUMENT_LOCK_STATUS: Set whether active user has lock on document. Important: should only be called in epic.',
  CLEAR_SOURCE_DOCUMENT_URL = 'CLEAR_SOURCE_DOCUMENT_URL: Clears the url of the source document',
  SET_SOURCE_DOCUMENT_URI = 'SET_SOURCE_DOCUMENT_URI: Sets the url of the active source document.',
  SOURCE_DOCUMENT_ERROR = 'SOURCE_DOCUMENT_ERROR',
  SOURCE_DOCUMENT_LOADED = 'SOURCE_DOCUMENT_LOADED',
  UPDATE_SOURCE_DOCUMENT = "UPDATE_SOURCE_DOCUMENT: Updates only the existing source document's info. Must have same aggregate id. Should be an isolated update.",
  UPGRADE_SOURCE_DOCUMENT_LOCK = 'UPGRADE_SOURCE_DOCUMENT_LOCK: Attempt an upgrade from no document lock to an active lock. Used by epics to sync client with server so user is editing latest state.',
  FETCH_AND_SET_SOURCE_DOCUMENT = 'FETCH_AND_SET_SOURCE_DOCUMENT: Set a source document using the annotated_document_id. If the document is not in state store, it must first be fetched the document before setting.',
  OPEN_DOCUMENT = 'OPEN_DOCUMENT: Open a document by changing the application route.',
  CLOSE_DOCUMENT = 'CLOSE_DOCUMENT: Clear out all information relating to the currently open document',
  UPDATE_DOCUMENT_METADATA_REQUEST = "UPDATE_DOCUMENT_METADATA_REQUEST: [User] lets change this documents' metadata!",
  UPDATE_DOCUMENT_METADATA_SUCCESS = 'UPDATE_DOCUMENT_METADATA_SUCCESS: document metadata update successful!',
  UPDATE_DOCUMENT_METADATA_FAILURE = 'UPDATE_DOCUMENT_METADATA_FAILURE: document metadata update failed!',
}
type UpdateDocumentMetadataAsyncPayload = {
  metadata: RawMetadata;
  annotatedDocumentId: number;
};
type UpdateDocumentMetadataAsyncResponsePayload = {
  originalRequest: UpdateDocumentMetadataAsyncPayload;
  error?: Error;
};
export const sourceDocumentError = createAction(
  Types.SOURCE_DOCUMENT_ERROR,
  resolvePayload => (error: string) => resolvePayload({ error }),
);

// Lock the source document for editing.
// Will request a lock from the back-end service.
export const lockSourceDocument = createAction(
  Types.LOCK_SOURCE_DOCUMENT,
  resolvePayload => (document: AnnotatedDocument) =>
    resolvePayload({ document }),
);

export const lockSourceDocumentErrorAction = createAction(
  Types.LOCK_SOURCE_DOCUMENT_ERROR,
  resolvePayload => (error: string) => resolvePayload({ error }),
);

export const refreshSourceDocumentLock = createAction(
  Types.REFRESH_SOURCE_DOCUMENT_LOCK,
);

// Set the active source document.
export const setSourceDocument = createAction(
  Types.SET_SOURCE_DOCUMENT,
  resolvePayload => (document: AnnotatedDocument, lockForEditing = false) =>
    resolvePayload({ document, lockForEditing }),
);

// Set a source document refenced by the specifed annotated id.
// If the matching document does not exist in state,
// it is first fetched from the back-end service.
export const fetchAndSetSourceDocument = createAction(
  Types.FETCH_AND_SET_SOURCE_DOCUMENT,
  resolvePayload => (annotatedDocumentId: number, lockForEditing = false) =>
    resolvePayload({ annotatedDocumentId, lockForEditing }),
);

export const clearSourceDocumentUrl = createAction(
  Types.CLEAR_SOURCE_DOCUMENT_URL,
  resolvePayload => (annotatedDocumentId: number) =>
    resolvePayload({ annotatedDocumentId }),
);

// Set the pdf url of the source document.
export const setSourceDocumentUrl = createAction(
  Types.SET_SOURCE_DOCUMENT_URI,
  resolvePayload => (annotatedDocumentId: number, url: string) =>
    resolvePayload({ annotatedDocumentId, url }),
);

export const updateSourceDocument = createAction(
  Types.UPDATE_SOURCE_DOCUMENT,
  resolvePayload => (document: AnnotatedDocument) =>
    resolvePayload({ document }),
);

export const setSourceDocumentLockStatusEpicOnly = createAction(
  Types.SET_SOURCE_DOCUMENT_LOCK_STATUS,
  resolvePayload => (lockStatus: DocumentLock) =>
    resolvePayload({ lockStatus }),
);

export const sourceDocumentLoaded = createAction(
  Types.SOURCE_DOCUMENT_LOADED,
  resolvePayload => (pageCount: number) => resolvePayload({ pageCount }),
);

export const refreshActiveSourceDocumentMeta = createAction(
  Types.REFRESH_ACTIVE_SOURCE_DOCUMENT_META,
);

export const openDocument = createAction(
  Types.OPEN_DOCUMENT,
  resolvePayload => (id: number, lockForEditing = false) =>
    resolvePayload({ id, lockForEditing }),
);

// going to use this action as a chance to experiment with
// this new action creator and inlining the action types
// (since we don't actually outwardly expose them and writing
// actions this way is muuuch easier/faster)
export const fetchSourceDocumentFromUrl = createStandardAction(
  'FETCH_SOURCE_DOCUMENT_FROM_URL: the url route for the extract view has changed. we need to populate the new document!',
)<match<AnnotationPageRouteParams>>();

export const closeDocument = createAction(Types.CLOSE_DOCUMENT);

// Attempt an upgrade from no document lock to an active lock.
// Used by epics to sync client with server so user is editing latest state.
export const upgradeSourceDocumentLock = createAction(
  Types.UPGRADE_SOURCE_DOCUMENT_LOCK,
);

export const updateDocumentMetadataAsync = createAsyncAction(
  Types.UPDATE_DOCUMENT_METADATA_REQUEST,
  Types.UPDATE_DOCUMENT_METADATA_SUCCESS,
  Types.UPDATE_DOCUMENT_METADATA_FAILURE,
)<
  UpdateDocumentMetadataAsyncPayload,
  UpdateDocumentMetadataAsyncResponsePayload,
  UpdateDocumentMetadataAsyncResponsePayload
>();

export const deleteDocumentAsync = createAsyncAction(
  'deleteDocumentAsync.request: delete the current document and all its information',
  'deleteDocumentAsync.success: delete was successful!',
  'deleteDocumentAsync.failure: there was a problem deleting the document',
)<{ annotatedDocumentId: number; documentSetId: number }, undefined, string>();
