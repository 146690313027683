import { Color } from './color.model';
import { UserHighlight } from './user-highlight.model';

export class RenderHighlight implements UserHighlight {
  id: string | number;
  start: number;
  end: number;
  userHighlights: UserHighlight[];

  private _color: Color | null = null;

  static create(highlights: UserHighlight[]): RenderHighlight[] {
    const sorted = highlights
      .sort(UserHighlight.compare)
      .map(h => new RenderHighlight([h]));

    if (sorted.length <= 1) {
      return sorted;
    }

    const renderHighlights = sorted;
    let i = 0;
    let r1;
    let r2;
    let overlapping = false;
    do {
      r1 = renderHighlights[i];
      r2 = renderHighlights[i + 1];

      overlapping = r1.overlaps(r2);
      if (overlapping) {
        if (r1.exactlyOverlaps(r2)) {
          renderHighlights.splice(
            i,
            2,
            new RenderHighlight([...r1.userHighlights, ...r2.userHighlights]),
          );
        } else {
          renderHighlights.splice(i, 2, ...this.splitOnOverlap(r1, r2));
        }

        // TODO: Huge Efficiency Improvement: **/
        // This sort is only required in rare cases of 3+ stacking overlapping
        // highlights. Since this is already sorted, the next compares
        // should just be inserted into the proper location.
        // This sort increases worst-case time complexity by an additional power of 2.
        renderHighlights.sort(UserHighlight.compare);

        // Ensure the new highlight doesn't overlap previous highlights.
        if (i > 0) {
          const current = renderHighlights[i];
          while (current.start < renderHighlights[i - 1].end) {
            i--;
          }
        }
      } else {
        overlapping = false;
        i++;
      }
    } while (renderHighlights[i + 1]);

    return renderHighlights;
  }

  /**
   * Creates three non-overlapping highlights from two overlapping highlights.
   * The middle highlight reflecting the overlap, and ends the non-overlapping
   * highlights.
   * @param first
   * @param second
   */
  static splitOnOverlap(first: RenderHighlight, second: RenderHighlight) {
    const overlapStart = Math.max(first.start, second.start);
    const overlapEnd = Math.min(first.end, second.end);

    const trueStart = Math.min(first.start, second.start);
    const trueEnd = Math.max(first.end, second.end);

    const startingHighlight = first.start <= second.start ? first : second;
    const endingHighlight = first.end <= second.end ? second : first;

    const newHighlights: RenderHighlight[] = [];
    if (trueStart !== overlapStart) {
      newHighlights.push(
        new RenderHighlight(
          startingHighlight.userHighlights.map(h =>
            UserHighlight.create({
              start: trueStart,
              end: overlapStart,
              color: h.color.hex,
              data: h.data,
              htmlClass: h.htmlClass,
            }),
          ),
        ),
      );
    }

    newHighlights.push(
      new RenderHighlight([...first.userHighlights, ...second.userHighlights]),
    );

    if (trueEnd !== overlapEnd) {
      newHighlights.push(
        new RenderHighlight(
          endingHighlight.userHighlights.map(h =>
            UserHighlight.create({
              start: overlapEnd,
              end: trueEnd,
              color: h.color.hex,
              data: h.data,
              htmlClass: h.htmlClass,
            }),
          ),
        ),
      );
    }

    return newHighlights;
  }

  /**
   * Creates a RenderHighlight from user defined highlights.
   * A render highlight can be formed from multiple user highlights,
   * blending the colors to form an overlapping highlight. The start
   * and end offsets are calculated from the overlap of the passed
   * highlights.
   * @param highlights
   * @param start
   * @param end
   */
  constructor(highlights: UserHighlight[]) {
    this.userHighlights = highlights.sort(UserHighlight.compare);

    const overlaps = this.findOverlap(this.userHighlights);
    this.start = overlaps.start;
    this.end = overlaps.end;
    this.id = UserHighlight.uniqueId(this);

    if (this.start > this.end) {
      console.error(
        `Highlights do not overlap id: ${this.id}, start ${this.start}, end: ${this.end}`,
      );
    }
  }

  get color() {
    if (!this._color) {
      this._color = Color.blend(this.userHighlights.map(h => h.color));
    }

    return this._color;
  }

  before(rh: RenderHighlight) {
    return UserHighlight.compare(this, rh) < 0;
  }

  after(rh: RenderHighlight) {
    return UserHighlight.compare(this, rh) > 0;
  }

  overlaps(rh: RenderHighlight): boolean {
    return (
      (rh.start < this.end && rh.end > this.start) ||
      (this.start < rh.end && this.end > rh.start)
    );
  }

  /**
   * Do these two highlights overlap exactly (same start & end)
   * @param rh
   */
  exactlyOverlaps(rh: RenderHighlight): boolean {
    return rh.start === this.start && rh.end === this.end;
  }

  findOverlap(sortedHighlights: UserHighlight[]) {
    const start = Math.max(...sortedHighlights.map(h => h.start));
    const end = Math.min(...sortedHighlights.map(h => h.end));

    return { start, end };
  }
}
