import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';

export const validateForm = <T>(
  form: WrappedFormUtils<any>,
  onSuccess: (values: T) => void,
  resetFormOnSuccess = false,
  fieldsToReset: string[] = [],
) => (e: React.FormEvent) => {
  e.preventDefault();
  form.validateFieldsAndScroll((err, values: T) => {
    if (!err) {
      onSuccess(values);
      if (resetFormOnSuccess) {
        if (fieldsToReset.length > 0) {
          form.resetFields(fieldsToReset);
        } else {
          form.resetFields();
        }
      }
    }
  });
};
