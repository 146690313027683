import { values } from 'lodash';
import { createSelector } from 'reselect';
import { isNull } from 'util';
import { AppState } from '../../app-state';
import { KeyValueMap } from '../../common/types/KeyValueMap.type';
import { FromPropsResolver } from '../../root.selector.types';
import {
  Permission,
  PermissionAction,
  PermissionResource,
  SelectedPermission,
} from '../models/permission.model';
import { UserAccount } from '../models/user-account.model';
import { User } from '../models/user.model';

export const selectActiveUserAccount = (state: AppState): UserAccount | null =>
  state.users.activeUserAccount;

export const selectUserAccounts = (state: AppState): KeyValueMap<UserAccount> =>
  state.users.accounts;

export const selectUsers = (state: AppState): KeyValueMap<User> =>
  state.users.entities;

export const selectUsersAreLoading = (state: AppState): boolean =>
  state.users.loading;

export const selectActiveUserPermissions = createSelector(
  [selectActiveUserAccount],
  activeUserAccount => {
    if (!activeUserAccount) {
      return [];
    } else {
      return activeUserAccount.permissions;
    }
  },
);

export const createIsPermittedSelector = <P>(
  r: FromPropsResolver<P, SelectedPermission[]>,
) =>
  createSelector(
    [selectActiveUserPermissions, r],
    (userPermissions, required) =>
      Permission.hasPermissions(userPermissions, required),
  );

export const selectHasPermissions = (permissions: SelectedPermission[]) =>
  createSelector([selectActiveUserPermissions], userPermissions =>
    Permission.hasPermissions(userPermissions, permissions),
  );

export const selectIsPermitted = (
  action: PermissionAction,
  resource: PermissionResource,
  resourceId?: number,
) =>
  createSelector([selectActiveUserPermissions], userPermissions =>
    Permission.hasPermission(userPermissions, action, resource, resourceId),
  );

export const createUserAccountByIdSelector = <P>(
  r: FromPropsResolver<P, number>,
) => createSelector([selectUserAccounts, r], (accounts, id) => accounts[id]);

export const selectUsersArray = createSelector([selectUsers], users =>
  values(users),
);

export const selectActiveUser = createSelector(
  [selectActiveUserAccount],
  account => (isNull(account) ? null : account.user),
);

export const selectUsername = createSelector([selectActiveUser], user =>
  isNull(user) ? null : user.username,
);

export const selectUserEmail = createSelector([selectActiveUser], user =>
  isNull(user) ? null : user.subject,
);

export const selectUserId = createSelector([selectActiveUser], user =>
  isNull(user) ? null : user.userId,
);

export const selectActiveUserIsAdmin = createSelector(
  [selectActiveUserAccount],
  account => account && UserAccount.isAdmin(account),
);
