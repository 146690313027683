import { Tree } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { DefaultTextColor } from '../../common/colors';
import { ColorBox } from '../../common/components/color-box.component';
import {
  AnnotationCategory,
  AnnotationSchema,
} from '../models/annotation-schema.model';
import { AnnotationType } from '../models/annotation-type.model';

const TreeNode = Tree.TreeNode;

const StyledWrapper = styled.div`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .ant-tree > li:first-child {
    padding-top: 0px;
  }

  .ant-tree li span.ant-tree-switcher {
    width: 12px;
  }

  .ant-tree li {
    padding: 2px 0;

    ul {
      padding-top: 0px;
      padding: 0;
    }
  }
`;

const ClickableAnnotation = styled.a`
  color: ${DefaultTextColor};
`;

interface AnnotationListProps {
  onClick: (annotation: AnnotationType) => any;
  className?: string;
  annotationSchema: AnnotationSchema;
}
export class AnnotationListComponent extends React.PureComponent<
  AnnotationListProps
> {
  onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  render() {
    const { className, annotationSchema } = this.props;

    return (
      <StyledWrapper className={className} onClick={this.onClick}>
        <Tree defaultExpandAll={true}>
          {annotationSchema.categories.map(this.renderAnnotationSet)}
        </Tree>
      </StyledWrapper>
    );
  }

  renderAnnotationSet = (category: AnnotationCategory) => {
    const { title, annotationTypes } = category;
    return (
      <TreeNode key={title} title={<strong>{title}</strong>}>
        {annotationTypes.map(type => (
          <TreeNode
            key={`annotation-${title}-${type.key}`}
            title={
              <ClickableAnnotation onClick={e => this.props.onClick(type)}>
                <ColorBox style={{ backgroundColor: type.color }} />
                {type.title} ({type.keyboardShortcut})
              </ClickableAnnotation>
            }
          />
        ))}
      </TreeNode>
    );
  };
}
