import { map, pickBy } from 'lodash';
import { KeyValueMap } from '../../common/types/KeyValueMap.type';
import { SelectableDocumentSet } from '../components/search-form.component';

export const selectedDocumentSets = (
  docs: KeyValueMap<SelectableDocumentSet>,
) => {
  return pickBy(docs, v => v.selected);
};

export const selectDocumentSetSearchIndices = (
  docs: KeyValueMap<SelectableDocumentSet>,
) => {
  return map(
    docs,
    ({ documentSetId, groupId }) => `goat-${groupId}-${documentSetId}`,
  );
};
