import { Dictionary, find, groupBy, keyBy, map } from 'lodash';
import { flatMap } from 'lodash/fp';
import {
  AnnotationCategoryResource,
  AnnotationRelationRuleResource,
  AnnotationSchemaResource,
  AnnotationTypeResource,
} from '../resources/annotation-schema.resource';
import { AnnotationRelationRule } from './annotation-relation-rule.model';
import { AnnotationType } from './annotation-type.model';
import { DomainLookup } from '../../domain/models/lookup-context.model';

export type AnnotationSchema = {
  annotationSchemaId: number;
  groupId: number;
  title: string;
  documentSetMatcher?: string;
  classificationMatcher?: string;
  createdAt: string;
  updatedAt: string;
  categories: AnnotationCategory[];
  annotationTypes: AnnotationType[];
  relationRules: AnnotationRelationRule[];
  lookups: DomainLookup[];
};

export type AnnotationCategory = {
  annotationCategoryId: number;
  title: string;
  annotationTypes: AnnotationType[];
  createdAt: string;
  updatedAt: string;
};

export const AnnotationSchema = {
  unknownSchema(): AnnotationSchema {
    return {
      annotationSchemaId: -1,
      groupId: -1,
      title: 'Unknown',
      createdAt: '',
      updatedAt: '',
      categories: [],
      annotationTypes: [],
      relationRules: [],
      lookups: [],
    };
  },

  /**
   * The functional schema is an annotation schema that exists only to encapsulate
   * annotation types used for application logic. Examples of these types are
   * "Any" and "Unknown".
   * This is the expected id of that schema when returned from the api.
   */
  FunctionalSchemaId: -1,

  /**
   * Maps an annotation schema from annotation schema resource.
   * All annotation types across schemas must be passed in to
   * prevent errors when an annotation relationship crosses schemas.
   * @param schema
   * @param allAnnotationTypesById
   */
  mapFromResource(
    schema: AnnotationSchemaResource,
    allAnnotationTypesById: Dictionary<AnnotationTypeResource>,
  ): AnnotationSchema {
    const atnsByCategory = groupBy(
      schema.annotationTypes,
      (atn: AnnotationType) => atn.annotationCategoryId,
    );

    const categories = map(
      schema.categories,
      (cat: AnnotationCategoryResource) => ({
        ...cat,
        annotationTypes: atnsByCategory[cat.annotationCategoryId] || [],
      }),
    );

    const getKeyById = (id: number) => {
      const isAll = id === 1;
      const keyById = allAnnotationTypesById[id]?.key;
      return isAll ? AnnotationType.WildcardAnyKey : keyById;
    };

    const relationRules = map(
      schema.relationRules,
      (rule: AnnotationRelationRuleResource) => ({
        annotationRelationRuleId: rule.annotationRelationRuleId,
        type: rule.relationType,
        description: rule.description,
        left: getKeyById(rule.leftAnnotationTypeId),
        right: getKeyById(rule.rightAnnotationTypeId),
        required: rule.required || false,
        createdAt: rule.createdAt,
        updatedAt: rule.updatedAt,
      }),
    ).filter(AnnotationRelationRule.validateRule);

    return {
      annotationSchemaId: schema.annotationSchemaId,
      groupId: schema.groupId,
      title: schema.title,
      documentSetMatcher: schema.documentSetMatcher,
      classificationMatcher: schema.classificationMatcher,
      createdAt: schema.createdAt,
      updatedAt: schema.updatedAt,
      categories,
      annotationTypes: schema.annotationTypes,
      relationRules,
      lookups: schema.lookups,
    };
  },

  mapFromResources(schemas: AnnotationSchemaResource[]): AnnotationSchema[] {
    const atnsById = keyBy(
      AnnotationSchema.extractAnnotationTypes(schemas),
      (atn: AnnotationType) => atn.annotationTypeId,
    );

    return schemas.map(schema =>
      AnnotationSchema.mapFromResource(schema, atnsById),
    );
  },

  /**
   * Return only the schemas visible & seletable by the user.
   * Context:
   * The API returns an invisible schema containing functional annotions types such as
   * "any" or "unknown". This schema has an id of -1.
   * @param schema
   */
  isUserSelectable(schema: AnnotationSchema): boolean {
    return schema.annotationSchemaId > 0;
  },

  matchDocumentSet(
    documentSetName: string,
    classification: string | undefined,
    schemas: AnnotationSchema[],
  ): AnnotationSchema | null {
    const maybeSchema = find(schemas, schema => {
      if (!schema.documentSetMatcher) {
        return false;
      }
      const documentSetMatcher = new RegExp(schema.documentSetMatcher || '.*');
      const classificationMatcher = new RegExp(
        schema.classificationMatcher || '.*',
      );
      const classificationVal = classification || '';
      const matchesDocumentSet = !!documentSetName.match(documentSetMatcher);
      const matchesClassification = !!classificationVal.match(
        classificationMatcher,
      );
      return matchesDocumentSet && matchesClassification;
    });

    return maybeSchema || null;
  },

  /**
   * Returns a flat array of all annotation types in the passed schemas.
   * @param AnnotationSchema[]
   * @returns AnnotationTypeResource[]
   */
  extractAnnotationTypes: flatMap(
    (s: Pick<AnnotationSchema, 'annotationTypes'>) => s.annotationTypes,
  ),

  /**
   * Returns a flat array of all annotation types in the passed schemas.
   * @param AnnotationSchema[]
   * @returns AnnotationRelationRuleResource[]
   */
  extractRelationRules: flatMap(
    (s: Pick<AnnotationSchema, 'relationRules'>) => s.relationRules,
  ),
};
