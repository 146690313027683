import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { AddNewUserPayload } from '../../admin/models/add-new-user-payload.model';
import { AppState } from '../../app-state';
import { CONTENT_TYPE_HEADERS, authHeaders } from '../../common/utils/fetch';
import { config } from '../../config/application.config';
import { User } from '../models/user.model';

interface UsersResource {
  users: User[];
}

export const UsersResource = {
  usersUrl: `${config.annotationService.url}/users`,
  usernameCheckUrl: `${config.annotationService.url}/external-auth/validate/username`,
  emailCheckUrl: `${config.annotationService.url}/external-auth/validate/email`,

  getAll(state: AppState): Observable<UsersResource> {
    return ajax.getJSON<UsersResource>(this.usersUrl, authHeaders(state));
  },
  createUser(state: AppState, userInfo: AddNewUserPayload) {
    return ajax.post(this.usersUrl, userInfo, authHeaders(state));
  },
  checkUsername(state: AppState, username: { username: string }) {
    return ajax.post(this.usernameCheckUrl, username, {
      ...CONTENT_TYPE_HEADERS.JSON,
      ...authHeaders(state),
    });
  },
  checkEmail(state: AppState, email: { email: string }) {
    return ajax.post(this.emailCheckUrl, email, {
      ...CONTENT_TYPE_HEADERS.JSON,
      ...authHeaders(state),
    });
  },
};
