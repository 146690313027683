import { Button } from 'antd';
import styled from 'styled-components';
import { Red1 } from '../colors';

const DangerButton = styled(Button as any)`
  &.ant-btn {
    background-color: ${Red1};
    color: white;
    border: none;
    border-radius: 0;
  }
`;

export default DangerButton;
