import { connect } from 'react-redux';
import { AppState } from '../../app-state';
import {
  clearLookup,
  deleteLookup,
  fetchLookups,
  updateLookup,
} from '../actions/lookup-context.actions';
import { LookupTableComponent } from '../components/domain-lookup-table.component';
import { selectLookups } from '../selectors/lookup-context.selectors';
import {
  PermissionAction,
  PermissionResources,
} from '../../accounts/models/permission.model';
import { selectIsPermitted } from '../../accounts/selectors/users.selectors';
import { selectAllGroupsArray } from '../../accounts/selectors/groups.selectors';
import { fetchAllGroupAccounts } from '../../accounts/actions/groups.actions';
import { sortBy } from 'lodash';
import { selectAllAnnotationKeys } from '../../annotations/selectors/annotation-schema.selectors';

export const LookupTable = connect(
  (state: AppState) => ({
    lookups: selectLookups(state),
    groups: sortBy(selectAllGroupsArray(state), g => g.groupName.toLowerCase()),
    lookupKeys: selectAllAnnotationKeys(state),
    isPermitted: (lookupId: number, action: PermissionAction) =>
      selectIsPermitted(
        action,
        PermissionResources.DomainLookup,
        lookupId,
      )(state),
  }),
  {
    fetchGroups: fetchAllGroupAccounts,
    updateLookup,
    fetchLookups,
    deleteLookup,
    clearLookup,
  },
)(LookupTableComponent);
