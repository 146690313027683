import { ColDef } from 'ag-grid-community';
import { find } from 'lodash';
import { isEqual } from 'lodash/fp';
import { Annotation } from './annotation.model';
import { SimpleTreeNode } from './simple-tree.model';

export type FlatTree = {
  [id: string]: SimpleTreeNode<Annotation>;
};

export const AnnotationTableSettings = {
  /**
   * Filters out restricted column definitions for a user
   * based on whether the grid is in edit mode or not.
   */
  filterColumns: (tableEditable: boolean, colDefs: ColDef[]) => {
    const removeColumns = tableEditable
      ? []
      : [
          'startOffset',
          'endOffset',
          'actions',
          'annotatorName',
          'annotationDate',
        ];

    return colDefs.filter(col => !find(removeColumns, isEqual(col.colId)));
  },
};
