import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { getType } from 'typesafe-actions';
import { selectActiveUserAccount } from '../../accounts/selectors/users.selectors';
import { postAnnotationReview } from '../../annotation-review/actions/annotation-review.actions';
import {
  saveAnnotations,
  saveAnnotationsBulkAsync,
} from '../../annotations/actions/annotations.actions';
import { AppState } from '../../app-state';
import { downloadFile } from '../../common/actions/download.actions';
import { handleEpicError } from '../../common/utils/epics';
import { CONTENT_TYPE_HEADERS, authHeaders } from '../../common/utils/fetch';
import { config } from '../../config/application.config';
import { fetchDocumentSetUploadPolicyAsync } from '../../document-sets/actions/document-sets.actions';
import {
  setSourceDocument,
  updateDocumentMetadataAsync,
} from '../../documents/actions/source-document.actions';
import {
  completeQueueItem,
  getNextQueueItem,
} from '../../queues/actions/queue.actions';
import { AppActions } from '../../root.actions';
import {
  cancelExportJob,
  deleteExportJob,
  downloadExport,
  markDownloadComplete,
  markDownloading,
  refreshExportJob,
  setExportError,
  setExternalJobId,
  submitExportJob,
} from '../../search/actions/export.actions';
import { ExportAction } from '../../search/actions/export.types';
import { submitSearch } from '../../search/actions/search.actions';
import { selectExportJob } from '../../search/selectors/export.selectors';
import {
  addTagToDocumentsAsync,
  removeTagFromDocumentsAsync,
} from '../../tags/actions/tag.actions';
import { appEventLoggingSuccess } from '../actions/application-event-logging.actions';
import { loadDocumentSummaryEnd } from '../actions/document-summary-load-usage.actions';

export const applicationEventUrl = `${config.annotationService.url}/application-event`;

const eventMap = {
  [getType(setSourceDocument)]: (
    action: ReturnType<typeof setSourceDocument>,
  ) => {
    return {
      eventType: 'Open Document',
      eventData: action.payload,
    };
  },
  [getType(saveAnnotations)]: (
    _: ReturnType<typeof saveAnnotations>,
    state: AppState,
  ) => {
    const eventData = { ...state.sourceDocument.meta };
    return { eventType: 'Save Annotations', eventData };
  },
  [getType(postAnnotationReview)]: (
    action: ReturnType<typeof postAnnotationReview>,
    state: AppState,
  ) => {
    const eventData = { ...action.payload, ...state.sourceDocument.meta };
    return {
      eventType: 'Review Annotations',
      eventData,
    };
  },
  [getType(saveAnnotationsBulkAsync.success)]: (
    action: ReturnType<typeof saveAnnotationsBulkAsync.success>,
  ) => {
    const eventData = { ...action.payload };
    return {
      eventType: 'Bulk Upload Annotations Success',
      eventData,
    };
  },
  [getType(saveAnnotationsBulkAsync.failure)]: (
    action: ReturnType<typeof saveAnnotationsBulkAsync.failure>,
  ) => {
    const eventData = { ...action.payload };
    return {
      eventType: 'Bulk Upload Annotations Failure',
      eventData,
    };
  },
  [getType(loadDocumentSummaryEnd)]: (
    _: ReturnType<typeof loadDocumentSummaryEnd>,
    state: AppState,
  ) => {
    const start = state._USAGE_document_summary_load.start;
    const end = state._USAGE_document_summary_load.end;
    const timeTaken =
      !!start && !!end ? Math.abs(end.getTime() - start.getTime()) : NaN;
    const userAccount = selectActiveUserAccount(state);
    const userName = !!userAccount ? userAccount.user.username : 'unknown user';
    const queryParams = state.documentList.filter;

    const eventData = { queryStart: start, timeTaken, userName, queryParams };
    return {
      eventType: 'Document Summary Load',
      eventData,
    };
  },
  [getType(getNextQueueItem)]: (
    action: ReturnType<typeof getNextQueueItem>,
  ) => {
    const eventData = { ...action.payload };
    return {
      eventType: 'Get next queue item',
      eventData,
    };
  },
  [getType(completeQueueItem)]: (
    action: ReturnType<typeof completeQueueItem>,
  ) => {
    const eventData = { ...action.payload };
    return {
      eventType: 'Complete queue item',
      eventData,
    };
  },
  [getType(submitSearch)]: (action: ReturnType<typeof submitSearch>) => {
    const eventData = { ...action.payload };
    return {
      eventType: 'Search Documents',
      eventData,
    };
  },
  [getType(downloadFile)]: (action: ReturnType<typeof downloadFile>) => {
    const eventData = { ...action.payload };
    return {
      eventType: 'Download File',
      eventData,
    };
  },
  [getType(addTagToDocumentsAsync.success)]: (
    action: ReturnType<typeof addTagToDocumentsAsync.success>,
  ) => {
    const eventData = { ...action.payload };
    return {
      eventType: 'Add tag to Document success',
      eventData,
    };
  },
  [getType(addTagToDocumentsAsync.failure)]: (
    action: ReturnType<typeof addTagToDocumentsAsync.failure>,
  ) => {
    const eventData = { ...action.payload };
    return {
      eventType: 'Add tag to Document failure',
      eventData,
    };
  },
  [getType(removeTagFromDocumentsAsync.success)]: (
    action: ReturnType<typeof removeTagFromDocumentsAsync.success>,
  ) => {
    const eventData = { ...action.payload };
    return {
      eventType: 'Remove tag from Document success',
      eventData,
    };
  },
  [getType(removeTagFromDocumentsAsync.failure)]: (
    action: ReturnType<typeof removeTagFromDocumentsAsync.failure>,
  ) => {
    const eventData = { ...action.payload };
    return {
      eventType: 'Remove tag from Document failure',
      eventData,
    };
  },
  [getType(fetchDocumentSetUploadPolicyAsync.success)]: (
    action: ReturnType<typeof fetchDocumentSetUploadPolicyAsync.success>,
  ) => {
    const eventData = { ...action.payload };
    return {
      eventType:
        'Fetch Document Policy for Upload (Document is being uploaded) success',
      eventData,
    };
  },
  [getType(fetchDocumentSetUploadPolicyAsync.failure)]: (
    action: ReturnType<typeof fetchDocumentSetUploadPolicyAsync.failure>,
  ) => {
    const eventData = action.payload;
    return {
      eventType:
        'Fetch Document Policy for Upload (Document is being uploaded) failure',
      eventData,
    };
  },
  [getType(updateDocumentMetadataAsync.success)]: (
    action: ReturnType<typeof updateDocumentMetadataAsync.success>,
  ) => {
    const eventData = { ...action.payload };
    return {
      eventType: 'Change document metadata success',
      eventData,
    };
  },
  [getType(updateDocumentMetadataAsync.failure)]: (
    action: ReturnType<typeof updateDocumentMetadataAsync.failure>,
  ) => {
    const eventData = { ...action.payload };
    return {
      eventType: 'Change document metadata failure',
      eventData,
    };
  },
  [getType(submitExportJob)]: (
    action: ReturnType<typeof submitExportJob>,
    state: AppState,
  ) => exportEvent(action, state),
  [getType(setExternalJobId)]: (
    action: ReturnType<typeof setExternalJobId>,
    state: AppState,
  ) => exportEvent(action, state),
  [getType(setExportError)]: (
    action: ReturnType<typeof setExportError>,
    state: AppState,
  ) => exportEvent(action, state),
  [getType(downloadExport)]: (
    action: ReturnType<typeof downloadExport>,
    state: AppState,
  ) => exportEvent(action, state),
  [getType(markDownloadComplete)]: (
    action: ReturnType<typeof markDownloadComplete>,
    state: AppState,
  ) => exportEvent(action, state),
  [getType(markDownloading)]: (
    action: ReturnType<typeof markDownloading>,
    state: AppState,
  ) => exportEvent(action, state),
  [getType(cancelExportJob)]: (
    action: ReturnType<typeof cancelExportJob>,
    state: AppState,
  ) => exportEvent(action, state),
  [getType(deleteExportJob)]: (
    action: ReturnType<typeof deleteExportJob>,
    state: AppState,
  ) => exportEvent(action, state),
  [getType(refreshExportJob)]: (
    action: ReturnType<typeof refreshExportJob>,
    state: AppState,
  ) => exportEvent(action, state),
};

export function exportEvent(action: ExportAction, state: AppState) {
  const eventData = selectExportJob(state)(action.payload.jobId);
  const event = {
    eventType: action.type,
    eventData,
  };
  return event;
}

export function applicationEventLoggingEpic(
  action$: ActionsObservable<Action>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(
      isActionOf([
        setSourceDocument,
        saveAnnotations,
        postAnnotationReview,
        saveAnnotationsBulkAsync.success,
        saveAnnotationsBulkAsync.failure,
        loadDocumentSummaryEnd,
        getNextQueueItem,
        completeQueueItem,
        submitSearch,
        downloadFile,
        addTagToDocumentsAsync.success,
        addTagToDocumentsAsync.failure,
        removeTagFromDocumentsAsync.success,
        removeTagFromDocumentsAsync.failure,
        fetchDocumentSetUploadPolicyAsync.success,
        fetchDocumentSetUploadPolicyAsync.failure,
        updateDocumentMetadataAsync.success,
        updateDocumentMetadataAsync.failure,
        cancelExportJob,
        deleteExportJob,
        downloadExport,
        markDownloadComplete,
        markDownloading,
        refreshExportJob,
        setExportError,
        setExternalJobId,
        submitExportJob,
      ]),
    ),
    switchMap(sendApplicationEvent(state$)),
  );
}

const sendApplicationEvent = (state$: StateObservable<AppState>) => (
  action: AppActions,
): Observable<AppActions> => {
  const state = state$.value;
  const getEvent = eventMap[action.type];
  const postBody = getEvent(action, state);

  return ajax
    .post(applicationEventUrl, postBody, {
      ...authHeaders(state),
      ...CONTENT_TYPE_HEADERS.JSON,
    })
    .pipe(
      map(_ => appEventLoggingSuccess(postBody.eventType)),
      catchError(handleEpicError('Error logging application event')),
    );
};
