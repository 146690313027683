import { DataSourceItemObject } from 'antd/lib/auto-complete';
import { map } from 'lodash';
import { createSelector } from 'reselect';
import { AppState } from '../../app-state';
import { Role } from '../models/role.model';

export const selectAllRoles = (state: AppState) => {
  return state.roles.entities;
};

export const selectRoleIds = createSelector(
  [selectAllRoles],
  (roles: Role[]) => {
    return map(roles, (role: Role) => role.roleId);
  },
);

export const selectRoleTitleAndDescriptionsDataSource = createSelector(
  [selectAllRoles],
  (roles: Role[]) => {
    return map(
      roles,
      (r: Role): DataSourceItemObject => {
        const nameIsDescription = r.roleName === r.description;
        const text = nameIsDescription
          ? r.roleName
          : `${r.roleName} – ${r.description}`;
        return {
          value: r.roleId.toString(),
          text,
        };
      },
    );
  },
);
