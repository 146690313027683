import { Dictionary } from 'lodash';
import { RelationRule } from '../../lib/relations/relation-rule.model';

export type AnnotationRelationRule = RelationRule<string> & {
  annotationRelationRuleId: number;
  createdAt: string;
  updatedAt: string;
};

export type AnnotationRelationsRulesById = Dictionary<AnnotationRelationRule>;
export type AnnotationRelationsRulesByName = Dictionary<AnnotationRelationRule>;

export const AnnotationRelationRule = {
  validateRule(rule: AnnotationRelationRule) {
    if (!rule.left || !rule.right) {
      console.error('Invalid relation rule filtered', rule);
      return false;
    }

    return true;
  },
};
