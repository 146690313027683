import { connect } from 'react-redux';
import { fetchUsers } from '../../accounts/actions/users.actions';
import {
  selectUsersAreLoading,
  selectUsersArray,
} from '../../accounts/selectors/users.selectors';
import { AppState } from '../../app-state';
import { UserTableComponent } from '../components/user-table.component';

export const UserTable = connect(
  (state: AppState) => ({
    users: selectUsersArray(state),
    loading: selectUsersAreLoading(state),
  }),
  dispatch => ({
    onInit: () => dispatch(fetchUsers()),
  }),
)(UserTableComponent);
