import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { ColorBox } from '../../common/components/color-box.component';
import { AnnotationTableRow } from '../models/annotation-table-row.model';
import { AnnotationType } from '../models/annotation-type.model';
import { connect } from 'react-redux';
import { AppState } from '../../app-state';
import {
  emphasizeAnnotationHighlight,
  removeAnnotationHighlightEmphasis,
  scrollAnnotationIntoView,
} from '../actions/ui-annotations.actions';
import { selectActiveSchemaAnnotationTypes } from '../selectors/annotation-schema.selectors';
import { selectAnnotationTypes } from '../selectors/annotation.selectors';

export type TypeCellProps = {
  onClick: (annotationId: string) => void;
  onMouseOver: (annotationId: string) => void;
  onMouseOut: (annotationId: string) => void;
  activeTypes: AnnotationType[];
  annotationType: string;
} & ICellRendererParams;

export const InnerAnnotationTypeCell = React.memo((props: TypeCellProps) => {
  const getTypeByKey = (key: string) =>
    props.activeTypes.find(t => t.key === key) || AnnotationType.Unknown(key);
  const rowData: AnnotationTableRow = props.data;
  const annotationId = rowData.annotationId;
  const annotationTypeKey = props.annotationType;
  const annotationType = getTypeByKey(annotationTypeKey);
  return (
    <div className="inner-annotation-type-cell">
      <ColorBox
        style={{
          backgroundColor: annotationType.color,
          width: '12px',
          minWidth: '12px',
          height: '12px',
        }}
      />
      <strong
        style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        onClick={_ => props.onClick(annotationId)}
        onMouseOver={_ => props.onMouseOver(annotationId)}
        onMouseOut={_ => props.onMouseOut(annotationId)}
      >
        {annotationType.title}
      </strong>
    </div>
  );
});

export default connect(
  (state: AppState, ownProps: ICellRendererParams) => ({
    activeTypes: selectActiveSchemaAnnotationTypes(state),
    annotationType:
      selectAnnotationTypes(state).get(ownProps.data.annotationId) ||
      AnnotationType.UnknownKey,
  }),
  dispatch => ({
    onClick: (annotationId: string) =>
      dispatch(scrollAnnotationIntoView(annotationId)),
    onMouseOver: (annotationId: string) =>
      dispatch(emphasizeAnnotationHighlight(annotationId)),
    onMouseOut: (annotationId: string) =>
      dispatch(removeAnnotationHighlightEmphasis(annotationId)),
  }),
)(InnerAnnotationTypeCell);
