import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import { EMPTY, from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { getType, isActionOf } from 'typesafe-actions';
import { AppState } from '../../app-state';
import { authenticateSuccess } from '../../auth/actions/auth.actions';
import { tokenValid } from '../../auth/util/token.util';
import { displaySuccessNotification } from '../../common/actions/notification.actions';
import { handleEpicError } from '../../common/utils/epics';
import { AppActions } from '../../root.actions';
import {
  createUserAccount,
  fetchUserAccount,
  fetchUsers,
  populateUsers,
  refreshActiveUserAccount,
  saveUserSimpleRoles,
  setActiveUserAccount,
  storeUserAccount,
} from '../actions/users.actions';
import { UserAccountResource } from '../resources/user-account.resource';
import { UsersResource } from '../resources/users.resource';

export function usersEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(fetchUsers)),
    switchMap(() => {
      return UsersResource.getAll(state$.value).pipe(
        map(usersResource => populateUsers(usersResource.users)),
        catchError(handleEpicError('Fetch Error')),
      );
    }),
  );
}

export function getUserAccountEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(fetchUserAccount)),
    mergeMap(action =>
      UserAccountResource.get(action.payload.userId, state$.value).pipe(
        map(resp => storeUserAccount(resp.userAccount)),
        catchError(handleEpicError('Unable to get user account')),
      ),
    ),
  );
}

export function createUserAccountEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(createUserAccount.request)),
    switchMap(({ payload }) => {
      return UserAccountResource.createAccount(payload, state$.value).pipe(
        switchMap(_ =>
          of(
            createUserAccount.success(),
            displaySuccessNotification('Account successfully created!'),
          ),
        ),
        catchError(
          handleEpicError('Error creating account', createUserAccount.failure),
        ),
      );
    }),
  );
}

export function getAuthenticatedUserAccountEpic(
  action$: ActionsObservable<AppActions | { type: 'persist/REHYDRATE' }>,
  state$: StateObservable<AppState>,
) {
  const authenticated = (state: AppState) => tokenValid(state.auth.jwt);

  return action$.pipe(
    ofType(getType(authenticateSuccess), 'persist/REHYDRATE'),
    filter(() => authenticated(state$.value)),
    switchMap(() =>
      UserAccountResource.getAuthenticatedAccount(state$.value).pipe(
        map(resp => setActiveUserAccount(resp.userAccount)),
        catchError(handleEpicError('Unable to get user account')),
      ),
    ),
  );
}

export function saveUserSimpleRolesEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(saveUserSimpleRoles)),
    switchMap(action => {
      const { userId, roles } = action.payload;
      return UserAccountResource.putSimpleRoles(
        userId,
        roles,
        state$.value,
      ).pipe(
        switchMap(_ => from([fetchUsers()])),
        catchError(handleEpicError('Error saving user roles')),
      );
    }),
  );
}

export function refreshActiveUserEpic(
  action$: ActionsObservable<AppActions>,
  state$: StateObservable<AppState>,
) {
  return action$.pipe(
    filter(isActionOf(refreshActiveUserAccount)),
    map(a => {
      const activeUserId = state$.value.users.activeUserAccount?.user?.userId;
      return activeUserId ? fetchUserAccount(activeUserId) : EMPTY;
    }),
    catchError(handleEpicError('Error refreshing active user')),
  );
}
