import { flatMap, kebabCase, map, mapValues, startsWith } from 'lodash';
import {
  LabelTreeData,
  LabelTreeDatum,
  PredictionLabelTree,
} from '../models/prediction-label-tree.model';
import {
  PredictionLabel,
  PredictionLabels,
  PredictionLabelsCategory,
} from '../models/predictions.model';

const TREE_NODE_GROUP_SIGNIFIER = 'tree-node-group';

export function predictionLabelConfigToLabelTree(
  config: PredictionLabels,
): PredictionLabelTree {
  return mapValues(config, toLabelTreeData);
}

function toLabelTreeData(
  labelGroup: PredictionLabelsCategory[],
): LabelTreeData {
  return labelGroup.map(lg => ({
    title: lg.display,
    key: toTreeNodeKey(lg.display),
    children: lg.labels.map(toLabelTreeDatum),
  }));
}

function toTreeNodeKey(displayLabel: string): string {
  return `${TREE_NODE_GROUP_SIGNIFIER}:${kebabCase(displayLabel)}`;
}

function toLabelTreeDatum(label: PredictionLabel): LabelTreeDatum {
  return { title: label.display, key: label.value };
}

export function isTreeNodeGroup(key: string): boolean {
  return startsWith(key, TREE_NODE_GROUP_SIGNIFIER);
}

export function flattenTreeData(item: LabelTreeDatum): LabelTreeDatum[] {
  return [item].concat(
    item.children ? flatMap(item.children, flattenTreeData) : [],
  );
}

export function getTreeNodeAndChildren(
  item: LabelTreeDatum,
  key: string,
): string[] {
  if (item.key === key) {
    return map(flattenTreeData(item), ltd => ltd.key);
  } else if (item.children) {
    return flatMap(item.children, child => getTreeNodeAndChildren(child, key));
  } else {
    return [];
  }
}
