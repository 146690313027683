import { createSelector } from 'reselect';
import { AppState } from '../../app-state';
import {
  DOCUMENT_LOCK_STATUS,
  DocumentLock,
  DocumentLockStatus,
} from '../models/document-lock-status.model';

export const selectDocumentLock = (state: AppState): DocumentLock =>
  state.sourceDocument.documentLock;

export const selectSourceDocumentLockStatus = (
  state: AppState,
): DocumentLockStatus => {
  return state.sourceDocument.documentLock.status;
};

export const selectUserHasSourceDocumentLock = createSelector(
  [selectSourceDocumentLockStatus],
  lockStatus => lockStatus === DOCUMENT_LOCK_STATUS.ACTIVE,
);
