import React, { useEffect } from 'react';

/* tslint:disable */
/**
 *
 * @param ref reference to auto focus
 * @param refocusOnChangeTo list of values that, on changing,
 *   should trigger a refocus.
 */
export const useAutoFocus = <T extends { focus: Function }>(
  ref: React.RefObject<T>,
  refocusOnChangeTo?: any[],
): void => {
  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refocusOnChangeTo);
};
/* tslint:enable */
