import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { Flag } from '../../flags';

const SignOut = styled.a`
  color: #c0c3c8;
`;

const Identity = styled.div`
  color: white;
  display: inline-block;

  &:hover {
    color: #e75925;
  }
`;

const ControlWrap = styled.div`
  .ant-dropdown-trigger {
    cursor: pointer;
  }

  a {
    padding: 0 8px;
  }
`;

// had to enable search through this boolean flag due to menu items
// needing to be a direct child of Menu in order for the css to apply
// correctly. Otherwise, we would just wrap the Menu.Item for search
// in a flag.
const UserDropdown = (props: IdentityDropdownProps, searchEnabled = false) => (
  <Menu>
    <Menu.Item>
      <SignOut onClick={() => props.signOut()}>Sign Out</SignOut>
    </Menu.Item>
  </Menu>
);

interface IdentityDropdownProps {
  username: string | null;
  isAdmin: boolean | null;
  signOut: () => any;
  className?: string;
}
export class IdentityDropdownComponent extends React.PureComponent<
  IdentityDropdownProps
> {
  render() {
    return (
      <ControlWrap className={this.props.className}>
        <Dropdown
          trigger={['click']}
          overlay={
            <Flag
              name={['features', 'search']}
              render={() => UserDropdown(this.props, true)}
              fallbackRender={() => UserDropdown(this.props)}
            />
          }
        >
          <Identity className="identity">
            {this.props.username} <DownOutlined />
          </Identity>
        </Dropdown>
      </ControlWrap>
    );
  }
}
