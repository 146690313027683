import { Icon as LegacyIcon } from '@ant-design/compatible';
import { FileTextOutlined } from '@ant-design/icons';
import {
  Alert,
  Avatar,
  Collapse,
  Divider,
  List,
  Modal,
  Progress,
  Skeleton,
  Table,
  message,
} from 'antd';
import { ColumnProps } from 'antd/lib/table';
import Upload, { DraggerProps } from 'antd/lib/upload';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import _, { map, toString } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { EMPTY, Observable, from, of } from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  last,
  mergeMap,
  switchMap,
  throwIfEmpty,
} from 'rxjs/operators';
import styled from 'styled-components';
import {
  Permission,
  PermissionActions,
  PermissionResources,
} from '../../accounts/models/permission.model';
import { UserAccount } from '../../accounts/models/user-account.model';
import { AppState } from '../../app-state';
import { DateComponent } from '../../common/components/date.component';
import { DefaultButton } from '../../common/components/neutral-button.component';
import { useFetchedState } from '../../common/hooks/use-fetched-state.hook';
import { formatBytes } from '../../common/utils/formatBytes';
import { DocumentResource } from '../../documents/resources/document.resource';
import { UploadDataState } from '../containers/document-set-importer.container';
import { defaultDateFormat } from '../containers/document-set-manager.container';
import DocumentSetUploadPolicy from '../models/document-set-upload-policy.model';
import {
  DocumentSetResource,
  DocumentWithAggregateId,
} from '../resources/document-set.resource';
import {
  Callback,
  UploadConfirmActions,
  uploadConfirmReducer,
} from './upload-confirm-reducer';

const Dragger = Upload.Dragger;
const { Panel } = Collapse;

type DocSetUploaderProps = {
  uploadPolicy: DocumentSetUploadPolicy;
  documentSetId: number;
  currentlyActiveDocumentSetId: string;
  uploadDataState: UploadDataState;
  onClick: () => void;
};

const InvisibleDragger = styled(Dragger)`
  & > div {
    background: white !important;
    border: 1px invisible !important;
    &:hover {
      border: 1px dotted blue !important;
    }
  }
`;
const GreyBox = styled.div`
  min-height: 105px;
  width: 44%;
  border-radius: 12px;
  padding: 4em 0;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.05);
  border: 1px dashed grey;
`;

const PanelContents = styled.div`
  max-height: 38vh;
  overflow: auto;
  @media (max-width: 1366px) {
    max-height: 34vh;
  }
`;

type DuplicateUploadConfirmModalProps = {
  documentSetId: number;
  resolves: Callback[];
  rejects: Callback[];
  uploadIds: string[];
  userAccount: UserAccount | null;
  deleteDocument: (documentSetId: number, annotatedDocumentId: number) => any;
  fetchExistingDocuments: (
    documentSetId: number,
    aggregateIds: string[],
  ) => Observable<DocumentWithAggregateId[]>;
};
function DuplicateUploadConfirmModalUnconnected({
  documentSetId,
  resolves,
  rejects,
  uploadIds,
  userAccount,
  deleteDocument,
  fetchExistingDocuments,
}: DuplicateUploadConfirmModalProps) {
  // This does not catch every case because it does not confirm that the
  // permissions to delete documents is for the document set being managed here,
  // but that information is not readily accessible from the returned permission
  // object, and this covers the majority of cases
  const canDelete =
    userAccount &&
    Permission.hasPermission(
      userAccount.permissions,
      PermissionActions.DeleteDocument,
      PermissionResources.DocumentSet,
    );
  const visible =
    uploadIds.length > 0 &&
    resolves.length === uploadIds.length &&
    rejects.length === uploadIds.length;

  // there is a possible edge case if a second upload is started and the
  // duplicates field has been set, but the loadingDuplicateIds hasn't yet been
  // set to true that the useEffect could erroneously confirm the values.
  const [idsChecked, setIdsChecked] = useState<string[]>([]);
  const [duplicateCheckFailed, setDuplicateCheckFailed] = useState<boolean>(
    false,
  );
  const [deleting, setDeleting] = useState<boolean>(false);
  const [deletionFailed, setDeletionFailed] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  function succeed() {
    resolves.forEach(r => r());
  }

  function fail() {
    rejects.forEach(r => r());
  }

  const [duplicateIds, loadingDuplicateIds] = useFetchedState(
    of(uploadIds).pipe(
      filter(() => visible),
      throwIfEmpty(),
      switchMap(ids => {
        setDuplicateCheckFailed(false);
        setDeletionFailed(false);
        return fetchExistingDocuments(documentSetId, ids);
      }),
      finalize(() => {
        setIdsChecked(uploadIds);
      }),
      catchError(() => {
        setDuplicateCheckFailed(true);
        return of([]);
      }),
    ),
    [uploadIds, visible],
  );

  useEffect(() => {
    if (
      !duplicateCheckFailed &&
      !loadingDuplicateIds &&
      duplicateIds !== null &&
      duplicateIds.length === 0 &&
      _.isEqual(uploadIds, idsChecked)
    ) {
      setDuplicateCheckFailed(false);
      setDeletionFailed(false);
      succeed();
    }
    // THe linter wants to complain that the succeed function is not listed as a
    // dependency, but adding it would result in a plethora of false positives.
    // eslint-disable-next-line
  }, [
    visible,
    duplicateCheckFailed,
    duplicateIds,
    loadingDuplicateIds,
    idsChecked,
  ]);

  return (
    <Modal
      closable={false}
      confirmLoading={loadingDuplicateIds || deleting}
      onCancel={fail}
      onOk={() => {
        if (duplicateIds) {
          setDeletionFailed(false);
          setDeleting(true);
          from(duplicateIds)
            .pipe(
              mergeMap(document =>
                deleteDocument(
                  document.documentSetId,
                  document.annotatedDocumentId,
                ),
              ),
              last(),
              catchError(e => {
                setDeletionFailed(true);
                setDeleting(false);
                return EMPTY;
              }),
            )
            .subscribe(() => {
              setDuplicateCheckFailed(false);
              setDeletionFailed(false);
              setDeleting(false);
              succeed();
            });
        }
      }}
      okButtonProps={{
        disabled:
          !canDelete ||
          deleting ||
          deletionFailed ||
          duplicateCheckFailed ||
          loadingDuplicateIds,
        danger: true,
      }}
      okText={
        loadingDuplicateIds
          ? 'Checking'
          : deleting
          ? 'Deleting'
          : 'Delete and Reupload'
      }
      okType="danger"
      title={
        duplicateCheckFailed
          ? 'Failed to check for duplicate uploads'
          : deleting || deletionFailed
          ? 'Deleting documents...'
          : loadingDuplicateIds
          ? 'Checking for duplicate uploads...'
          : 'Duplicate documents detected!'
      }
      visible={visible}
    >
      {duplicateCheckFailed || deletionFailed ? (
        <Alert
          message="Error"
          description={
            duplicateCheckFailed
              ? 'Failed to check for duplicate documents. Please try the upload at another time'
              : 'Failed to delete existing documents. Please try the upload at another time'
          }
          showIcon
          type="error"
        />
      ) : loadingDuplicateIds || deleting ? (
        <Progress percent={100} showInfo={false} status="active" type="line" />
      ) : (
        <>
          <p>
            The following duplicates were detected. Would you like to delete the
            existing documents and replace them with the new uploads?
          </p>
          {duplicateIds ? (
            <List
              dataSource={duplicateIds}
              header={`${duplicateIds.length} documents`}
              itemLayout="horizontal"
              pagination={{
                current: page,
                hideOnSinglePage: true,
                onChange: setPage,
                pageSize: 10,
                total: duplicateIds.length,
              }}
              renderItem={({ aggregateId }) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar icon={<FileTextOutlined />} />}
                    title={aggregateId}
                  />
                </List.Item>
              )}
            />
          ) : (
            <Skeleton />
          )}
          <Divider />
          {canDelete ? (
            <Alert
              message="Warning"
              description="This will delete any existing annotations on these documents!"
              showIcon
              type="warning"
            />
          ) : (
            <Alert
              message="Error"
              description="You do not have permission to delete documents"
              showIcon
              type="error"
            />
          )}
        </>
      )}
    </Modal>
  );
}
const DuplicateUploadConfirmModal = connect((state: AppState) => ({
  userAccount: state.users.activeUserAccount,
  deleteDocument: DocumentResource.deleteDocument(state),
  fetchExistingDocuments: DocumentSetResource.fetchExistingDocuments(
    state.auth.jwt,
  ),
}))(DuplicateUploadConfirmModalUnconnected);

const UploadNotes = () => {
  return (
    <>
      <p style={{ textAlign: 'center', paddingTop: '1em' }}>
        Please avoid leaving or reloading the Document Set once the upload
        begins. Once your documents have finished uploading, you may review them
        and add any additional metadata you wish to be associated to the
        document.
      </p>
    </>
  );
};

const showStatusIcon = (percent: number) => {
  const StatusIcon = props => <LegacyIcon {...props} />;
  const statusIconPropsMap = {
    started: { type: 'sync', spin: true },
    completed: {
      type: 'check-circle',
      theme: 'twoTone',
      twoToneColor: '#52c41a',
    },
  };
  let status = 'started';
  if (percent === 100) status = 'completed';
  return (
    <>
      <span style={{ paddingRight: '6px' }}>{percent}%</span>
      <StatusIcon {...statusIconPropsMap[status]} />
    </>
  );
};

type DocUploaderProps = {
  draggerProps: DraggerProps;
  uploadInfo: UploadChangeParam<UploadFile> | undefined;
  columns: ColumnProps<UploadFile>[];
};
const DocUploader: React.FC<DocUploaderProps> = ({
  draggerProps,
  uploadInfo,
  columns,
}) => (
  <>
    <InvisibleDragger {...draggerProps}>
      <GreyBox
        style={
          uploadInfo
            ? {
                minHeight: 50,
                padding: '2em 0',
                margin: '2em auto',
              }
            : {}
        }
      >
        <DefaultButton>Drag or Click to Upload</DefaultButton>
      </GreyBox>
    </InvisibleDragger>
    {uploadInfo ? (
      <Table
        dataSource={uploadInfo.fileList}
        columns={columns}
        pagination={false}
        loading={false}
        rowKey="name"
      />
    ) : (
      <UploadNotes />
    )}
  </>
);

const DocSetUploader: React.SFC<DocSetUploaderProps> = ({
  uploadPolicy,
  onClick,
  documentSetId,
  currentlyActiveDocumentSetId,
  uploadDataState,
}) => {
  const [uploadInfo, setUploadInfo] = uploadDataState;
  const [uploadIds, setUploadIds] = useState<string[]>([]);
  const [
    uploadConfirmState,
    uploadConfirmDispatch,
  ] = useReducer(uploadConfirmReducer, { resolves: [], rejects: [] });

  const headerMessage =
    !uploadInfo || uploadInfo.file.status === 'done'
      ? 'Upload Documents – Drag documents into panel to begin uploading.'
      : 'Uploading Documents – Please stay on page until files are uploaded.';

  const columns = [
    {
      title: 'Original Filename',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'File Size',
      dataIndex: 'size',
      key: 'size',
      render: (bytes: number) => {
        return formatBytes(bytes);
      },
    },
    {
      title: 'Uploaded At',
      dataIndex: 'lastModified',
      key: 'lastModified',
      render: (text: string) => {
        return (
          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-between',
            }}
          >
            <span>
              <DateComponent serializedDate={text} format={defaultDateFormat} />
            </span>
          </div>
        );
      },
    },
    {
      title: 'Upload Status',
      dataIndex: 'percent',
      key: 'percent',
      render: (percent: number) => {
        return showStatusIcon(Math.round(percent));
      },
    },
  ];

  const draggerProps: DraggerProps = {
    name: 'file',
    multiple: true,
    action: uploadPolicy.uploadUrl,
    data: uploadPolicyToFormData(uploadPolicy),
    beforeUpload: (_file, fileList) =>
      new Promise((resolve, reject) => {
        function clearUploadConfirm() {
          uploadConfirmDispatch(UploadConfirmActions.clear());
          setUploadIds([]);
        }
        uploadConfirmDispatch(
          UploadConfirmActions.addReject(() => {
            clearUploadConfirm();
            reject();
          }),
        );
        uploadConfirmDispatch(
          UploadConfirmActions.addResolve(() => {
            clearUploadConfirm();
            resolve();
          }),
        );
        setUploadIds(map(fileList, f => f.name));
      }),
    onChange(info) {
      const status = info.file.status;
      setUploadInfo(info);
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    showUploadList: false,
  };

  const panelKey = toString(documentSetId);

  return (
    <>
      <DuplicateUploadConfirmModal
        documentSetId={documentSetId}
        resolves={uploadConfirmState.resolves}
        rejects={uploadConfirmState.rejects}
        uploadIds={uploadIds}
      />
      <Collapse
        onChange={onClick}
        activeKey={[currentlyActiveDocumentSetId]}
        accordion={true}
      >
        <Panel header={headerMessage} key={panelKey} forceRender={true}>
          <PanelContents>
            <DocUploader
              draggerProps={draggerProps}
              columns={columns}
              uploadInfo={uploadInfo}
            />
          </PanelContents>
        </Panel>
      </Collapse>
    </>
  );
};

function uploadPolicyToFormData(
  uploadPolicy: DocumentSetUploadPolicy,
): (file: UploadFile) => any {
  return file => {
    const key = `${uploadPolicy.keyPrefix}${file.name}`;
    return {
      ...uploadPolicy.requiredParameters,
      key,
    };
  };
}

export default DocSetUploader;
