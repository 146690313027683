import React from 'react';
import styled from 'styled-components';
import { QueueViewer } from '../containers/queue-viewer.container';

const QueueManagerStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const QueueManager: React.SFC = () => (
  <QueueManagerStyle>
    <QueueViewer />
  </QueueManagerStyle>
);
