import { connect } from 'react-redux';
import { GroupAccount } from '../../accounts/models/group-account.model';
import { AppState } from '../../app-state';
import { setSelectedGroupAccount } from '../actions/ui-admin.actions';
import { GroupManagerComponent } from '../components/group-manager.component';
import { selectSelectedGroupAccount } from '../selectors/ui.selectors';

export const GroupManager = connect(
  (state: AppState) => ({
    selectedGroupAccount: selectSelectedGroupAccount(state),
  }),
  dispatch => ({
    onGroupSelected: (account: GroupAccount) =>
      dispatch(setSelectedGroupAccount(account.group.groupId)),
  }),
)(GroupManagerComponent);
