import { every } from 'lodash';
import { KeyValueMap } from '../../common/types/KeyValueMap.type';
import { ResourceIdRangeHandler } from '../../common/utils/resource-id-range-handler';

export interface Permission {
  permissionId: number;
  roleId: number;
  resource: PermissionResource;
  resourceIdRange: string;
  action: PermissionAction;
  effect: string;
  createdAt: string;
  modifiedAt: string;
}

export type PermissionCondition = {
  filter?: KeyValueMap<string[] | number[]>;
};

export type SelectedPermission = {
  action: PermissionAction;
  resource: PermissionResource;
  resourceId?: number;
};

export type PermissionResource =
  | '*'
  | 'document-set'
  | 'domain-lookup'
  | 'export'
  | 'group'
  | 'permission'
  | 'prediction-model'
  | 'queue'
  | 'role'
  | 'schema'
  | 'domain-lookup'
  | 'user';
export const PermissionResources = {
  All: '*' as const,
  DocumentSet: 'document-set' as const,
  DomainLookup: 'domain-lookup' as const,
  Export: 'export' as const,
  Group: 'group' as const,
  Permission: 'permission' as const,
  PredictionModel: 'prediction-model' as const,
  Queue: 'queue' as const,
  Role: 'role' as const,
  Schema: 'schema' as const,
  User: 'user' as const,
};

export type PermissionAction =
  | '*'
  | 'admin'
  | 'annotate'
  | 'clear'
  | 'delete'
  | 'delete-document'
  | 'download'
  | 'export'
  | 'import'
  | 'modify'
  | 'read'
  | 'review'
  | 'upload'
  | 'use-model'
  | 'write';

export const PermissionActions = {
  All: '*' as const,
  Admin: 'admin' as const,
  Annotate: 'annotate' as const,
  Clear: 'clear' as const,
  Delete: 'delete' as const,
  DeleteDocument: 'delete-document' as const,
  Download: 'download' as const,
  Export: 'export' as const,
  Import: 'import' as const,
  Modify: 'modify' as const,
  Read: 'read' as const,
  Review: 'review' as const,
  Upload: 'upload' as const,
  UseModel: 'use-model' as const,
  Write: 'write' as const,
};

export const Permission = {
  hasPermission(
    permissions: Permission[] = [],
    action: PermissionAction,
    resource: PermissionResource,
    resourceId?: number,
  ): boolean {
    return !!permissions.find(
      perm =>
        (perm.action === action || perm.action === PermissionActions.All) &&
        perm.resource === resource &&
        Permission.resourceIdMatches(perm, resourceId),
    );
  },

  resourceIdMatches(permission: Permission, resourceId?: number): boolean {
    return (
      !resourceId ||
      ResourceIdRangeHandler.singleIdFromResourceRange(
        permission.resourceIdRange,
      ) === resourceId
    );
  },

  hasPermissions(
    userPermissions: Permission[] = [],
    required: SelectedPermission[],
  ): boolean {
    return every(required, permission =>
      Permission.hasPermission(
        userPermissions,
        permission.action,
        permission.resource,
        permission.resourceId,
      ),
    );
  },

  findPermissions(
    permissions: Permission[],
    action: PermissionAction,
    resource: PermissionResource,
  ): Permission[] {
    return permissions.filter(
      perm =>
        (perm.action === action || perm.action === PermissionActions.All) &&
        perm.resource === resource,
    );
  },

  hasAdminPermissions(permissions: Permission[]): boolean {
    const { User, Group, Role } = PermissionResources;
    const { Read, Write } = PermissionActions;

    return (
      Permission.hasPermission(permissions, Read, User) &&
      Permission.hasPermission(permissions, Read, Group) &&
      Permission.hasPermission(permissions, Write, Role) &&
      Permission.hasPermission(
        permissions,
        Write,
        PermissionResources.Permission,
      )
    );
  },

  canAnnotateDocuments(permissions: Permission[]): boolean {
    return Permission.hasPermission(
      permissions,
      PermissionActions.Annotate,
      PermissionResources.DocumentSet,
    );
  },

  canReviewDocuments(permissions: Permission[]): boolean {
    return Permission.hasPermission(
      permissions,
      PermissionActions.Review,
      PermissionResources.DocumentSet,
    );
  },

  adminPermissions() {
    return [
      this.createPermission(PermissionResources.User, PermissionActions.Read),
      this.createPermission(PermissionResources.Group, PermissionActions.Read),
      this.createPermission(PermissionResources.Role, PermissionActions.Write),
      this.createPermission(
        PermissionResources.Permission,
        PermissionActions.Write,
      ),
    ];
  },

  createPermission(
    resource: PermissionResource,
    action: PermissionAction,
    condition?: PermissionCondition,
  ) {
    return {
      permissionId: 0,
      roleId: 0,
      resource,
      resourceIdRange: '(0,0)',
      action,
      effect: 'allow',
      condition,
      createdAt: new Date().toISOString(),
      modifiedAt: new Date().toISOString(),
    };
  },
};
