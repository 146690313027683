import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { AppState } from '../../app-state';
import { authHeaders } from '../../common/utils/fetch';
import { config } from '../../config/application.config';
import { ExternalGroup } from '../models/external-group.model';
import { Group } from '../models/group.model';

interface GroupsResource {
  groups: Group[];
}

export interface ExternalGroupsResource {
  groups: ExternalGroup[];
}

export const GroupsResource = {
  groupsUrl: `${config.annotationService.url}/groups`,
  externalAuthGroupsUrls: `${config.annotationService.url}/external-auth/groups`,

  getAll(state: AppState): Observable<GroupsResource> {
    return ajax.getJSON<GroupsResource>(this.groupsUrl, authHeaders(state));
  },

  getAllExternal(state: AppState): Observable<ExternalGroupsResource> {
    return ajax.getJSON<ExternalGroupsResource>(
      this.externalAuthGroupsUrls,
      authHeaders(state),
    );
  },
};
