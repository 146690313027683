import { LicenseManager } from 'ag-grid-enterprise';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, connect } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { persistReducer, persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
import App from './App';
import { AppState } from './app-state';
import { appInit } from './common/actions/init.actions';
import { config } from './config/application.config';
import { FlagsProvider } from './flags';
import { history } from './history';
import './index.css';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import { AppActions } from './root.actions';
import { rootEpic } from './root.epic';
import { appReducers, createRootReducer } from './root.reducer';
import { undoRedo } from './undo-redo/undo-redo.middleware';

// Redux Devtools
declare const window: any;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistWhitelist = ['auth', 'predictions'];

const persistConfig = {
  key: 'root',
  storage,
  whitelist: persistWhitelist,
};

const persistedReducer = persistReducer(
  persistConfig as any,
  createRootReducer(appReducers),
);

const epicMiddleware = createEpicMiddleware<AppActions, AppActions, AppState>();
//const userTiming = () => next => action => {
//  if (performance.mark === undefined) return next(action);
//  performance.mark(`${action.type}_start`);
//  const result = next(action);
//  performance.mark(`${action.type}_end`);
//  performance.measure(
//    `${action.type}`,
//    `${action.type}_start`,
//    `${action.type}_end`,
//  );
//  return result;
//};
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(undoRedo, epicMiddleware)),
);
epicMiddleware.run(rootEpic);
const persistor = persistStore(store);

store.dispatch(appInit());

// We're creating our own binding to the store here as a result of this issue:
// https://github.com/garbles/flag/issues/30
const ConnectedFlagsProvider = connect((state: AppState) => ({
  flags: state.flags,
}))(FlagsProvider as any);

// Add ag-grid license
if (config.agGridKey) {
  LicenseManager.setLicenseKey(config.agGridKey);
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedFlagsProvider>
      <PersistGate loading={null} persistor={persistor}>
        <App history={history} />
      </PersistGate>
    </ConnectedFlagsProvider>
  </Provider>,
  document.getElementById('root') as HTMLElement,
);

// For now, unregister service worker. The current service worker setup
// is aggressively caching and not updating resources for new versions.
// Until we have time to troubleshoot, service workers are disabled.
unregisterServiceWorker();
