import download from 'downloadjs';
import { Dictionary, keyBy } from 'lodash';
import Moment from 'moment';
import { EMPTY, Observable } from 'rxjs';
import { AjaxResponse, ajax } from 'rxjs/ajax';
import { map, tap } from 'rxjs/operators';
import { AppState } from '../../app-state';
import {
  CONTENT_TYPE_HEADERS,
  authHeaders,
  jwtHeader,
} from '../../common/utils/fetch';
import { config } from '../../config/application.config';
import { OcrStatusSummary } from '../models/document-set-ocr-summary.model';
import DocumentSetUploadPolicy from '../models/document-set-upload-policy.model';
import { DocumentSet } from '../models/document-set.model';

export interface DocumentSetResource {
  documentSetId: number;
  documentSet: DocumentSet;
}

export interface DocumentSetsResource {
  documentSets: DocumentSet[];
}

export interface DocumentWithAggregateId {
  aggregateId: string;
  annotatedDocumentId: number;
  documentSetId: number;
}

export const DocumentSetResource = {
  documentSetUrl: `${config.annotationService.url}/document-set`,
  documentSetsUrl: `${config.annotationService.url}/document-sets`,
  searchableDocumentSetsUrl: `${config.annotationService.url}/document-sets?searchable=true`,
  exportServiceUrl: config.exportService.url,

  getDocumentSetUrl: (id: number) =>
    `${DocumentSetResource.documentSetUrl}/${id}`,

  fetchExistingDocumentsUrl: (id: number) =>
    `${DocumentSetResource.getDocumentSetUrl(id)}/existing`,

  uploadPolicyUrl: (id: number) =>
    `${DocumentSetResource.documentSetUrl}/${id}/import`,

  getDocumentCountUrl: (id: number) =>
    `${DocumentSetResource.getDocumentSetUrl(id)}/count`,

  getDocumentSetSummaryUrl: (id: number) =>
    `${DocumentSetResource.getDocumentSetUrl(id)}/status`,

  exportDocumentSetUrl: (id: number) =>
    `${config.exportService.url}/document-set/${id}`,

  fetchExistingDocuments(
    jwt: string | null,
  ): (
    documentSetId: number,
    aggregateIds: string[],
  ) => Observable<DocumentWithAggregateId[]> {
    return (documentSetId: number, aggregateIds: string[]) => {
      return jwt
        ? ajax({
            url: DocumentSetResource.fetchExistingDocumentsUrl(documentSetId),
            body: JSON.stringify(aggregateIds),
            method: 'POST',
            responseType: 'text',
            headers: {
              ...jwtHeader(jwt),
              ...CONTENT_TYPE_HEADERS.JSON,
            },
          }).pipe(
            map((r: AjaxResponse) => {
              const response: {
                existing: DocumentWithAggregateId[];
              } = JSON.parse(r.response);
              return response.existing;
            }),
          )
        : EMPTY;
    };
  },

  fetchSearchable(jwt: string | null): Observable<Dictionary<DocumentSet>> {
    return jwt
      ? ajax
          .getJSON<DocumentSetsResource>(
            DocumentSetResource.searchableDocumentSetsUrl,
            jwtHeader(jwt),
          )
          .pipe(map(r => keyBy(r.documentSets, 'documentSetId')))
      : EMPTY;
  },

  getAll(state: AppState): Observable<DocumentSetsResource> {
    return ajax.getJSON<DocumentSetsResource>(
      this.documentSetsUrl,
      authHeaders(state),
    );
  },

  getDocumentSetSummary: (state: AppState) => (
    documentSetId: number,
  ): Observable<OcrStatusSummary> =>
    ajax
      .getJSON<{ ocrStatusSummary: OcrStatusSummary }>(
        DocumentSetResource.getDocumentSetSummaryUrl(documentSetId),
        authHeaders(state),
      )
      .pipe(map(({ ocrStatusSummary }) => ocrStatusSummary)),

  getDocumentCount: (state: AppState) => (
    documentSetId: number,
  ): Observable<number> =>
    ajax
      .getJSON<{ count: number }>(
        DocumentSetResource.getDocumentCountUrl(documentSetId),
        authHeaders(state),
      )
      .pipe(map(({ count }) => count)),

  deleteEmptyDocset: (state: AppState) => (documentSetId: number) =>
    ajax.delete(
      DocumentSetResource.getDocumentSetUrl(documentSetId),
      authHeaders(state),
    ),

  create(
    state: AppState,
    requestParams: { name: string; owningGroupId: number },
  ) {
    return ajax.post(this.documentSetUrl, requestParams, {
      ...authHeaders(state),
      ...CONTENT_TYPE_HEADERS.JSON,
    });
  },

  get: (state: AppState) => (documentSetId: number) =>
    ajax
      .getJSON<{ documentSetId: number; documentSet: DocumentSet }>(
        DocumentSetResource.getDocumentSetUrl(documentSetId),
        authHeaders(state),
      )
      .pipe(map(({ documentSet }) => documentSet)),

  getUploadPolicy(state: AppState, documentSetId: number) {
    return ajax.getJSON<DocumentSetUploadPolicy>(
      this.uploadPolicyUrl(documentSetId),
      authHeaders(state),
    );
  },

  downloadExport: (state: AppState) => (
    documentSetId: number,
    params: DocumentSetExportParams,
    saveAs: string,
  ) => {
    return ajax({
      url: DocumentSetResource.exportDocumentSetUrl(documentSetId),
      body: DocumentSetExportParams.serialize(params),
      method: 'POST',
      responseType: 'blob',
      headers: {
        ...authHeaders(state),
        ...CONTENT_TYPE_HEADERS.JSON,
        Accept: 'application/zip',
      },
    }).pipe(
      tap(resp => {
        console.debug(resp);
        download(resp.response, saveAs);
      }),
    );
  },
};

export type DocumentSetExportParams = {
  output: string;
  strategy: string;
  limit?: number;
  filters: {
    annotatedAfter?: Moment.Moment;
    annotatedBefore?: Moment.Moment;
    createdAfter?: Moment.Moment;
    createdBefore?: Moment.Moment;
    isAnnotated?: boolean;
    isReviewed?: boolean;
    withTags?: { type: string }[];
  };
};

export const DocumentSetExportParams = {
  serialize: (params: DocumentSetExportParams): any => {
    const serialized: any = { ...params };

    // Formats the date in zulu time like: 2020-01-30T00:00:00Z
    // Done this way to avoid mucking with timezones.
    function formatDate(date: Moment.Moment) {
      return `${date.format('YYYY-MM-DD')}T00:00:00.000Z`;
    }

    if (serialized.filters.annotatedAfter) {
      serialized.filters.annotatedAfter = formatDate(
        serialized.filters.annotatedAfter,
      );
    }

    if (serialized.filters.annotatedBefore) {
      serialized.filters.annotatedBefore = formatDate(
        serialized.filters.annotatedBefore,
      );
    }

    if (serialized.filters.reviewedAfter) {
      serialized.filters.reviewedAfter = formatDate(
        serialized.filters.reviewedAfter,
      );
    }

    if (serialized.filters.reviewedBefore) {
      serialized.filters.reviewedBefore = formatDate(
        serialized.filters.reviewedBefore,
      );
    }

    return serialized;
  },
};
