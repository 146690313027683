import { connect } from 'react-redux';
import { AppState } from '../../app-state';
import { selectSourceDocumentIsEditable } from '../../documents/selectors/source-document.selectors';
import {
  OptionalDocumentStatus,
  addAnnotationDocumentStatus,
  removeAnnotationDocumentStatus,
} from '../actions/annotation-document-status.actions';
import { DocumentStatuses } from '../components/document-statuses';
import {
  selectAvailableDocumentStatuses,
  selectCurrentAnnotationDocumentStatuses,
} from '../selectors/document-status.selectors';

export const DocumentStatusesContainer = connect(
  (state: AppState) => ({
    availableStatuses: selectAvailableDocumentStatuses(state),
    documentStatuses: selectCurrentAnnotationDocumentStatuses(state),
    disabled: !selectSourceDocumentIsEditable(state),
  }),
  dispatch => ({
    onAddStatus: (status: OptionalDocumentStatus) => {
      dispatch(addAnnotationDocumentStatus(status));
    },
    onRemoveStatus: (status: OptionalDocumentStatus) => {
      dispatch(removeAnnotationDocumentStatus(status));
    },
  }),
)(DocumentStatuses);
