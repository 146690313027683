import { has } from 'lodash';
import React from 'react';
import { Observable, from, of, pipe } from 'rxjs';
import {
  delay,
  map,
  mergeMap,
  partition,
  reduce,
  switchMap,
} from 'rxjs/operators';
import { isNumber } from 'util';
import { Blue1 } from '../../common/colors';
import { KeyValueMap } from '../../common/types/KeyValueMap.type';
import { CompleteQueueItemButton } from '../containers/complete-queue-item-button.container';
import { QueueItem } from '../models/queue-item.model';
import { Queue } from '../models/queue.model';
import {
  QueueStatusFailure,
  QueueStatusResponse,
} from '../resources/queues.resource';
import { NextQueueItemButton } from './next-queue-item-button.component';
import { QueueSelector } from './queue-selector.component';

type QueueControlComponentProps = {
  queues: Queue[];
  getQueueStatus: (
    queueId: number,
  ) => Observable<QueueStatusResponse | QueueStatusFailure>;
  currentQueueItem: QueueItem | null;
  onInit: () => void;
  onNextItemRequested: (queueId: number) => void;
};

type QueueControlComponentState = {
  selectedQueue: Queue | null;
  queueStatuses: KeyValueMap<QueueStatusResponse | null>;
};

const buttonBorderStyle: React.CSSProperties = {
  borderLeft: `1px solid ${Blue1} `,
};

export class QueueControlComponent extends React.PureComponent<
  QueueControlComponentProps,
  QueueControlComponentState
> {
  shouldShowCompleteQueueItemButton = QueueItem.exists;

  constructor(props: QueueControlComponentProps) {
    super(props);
    this.state = {
      selectedQueue: null,
      queueStatuses: {},
    };
  }

  shouldShowNextQueueItemButton = (): boolean => !!this.state.selectedQueue;

  componentWillMount() {
    this.props.onInit();
  }

  setSelectedQueue = (q: Queue) => {
    this.setState({ selectedQueue: q }, this.refreshQueueStatuses(q.queueId));
  };

  refreshQueueStatuses = (queueId?: number, delayMs = 0) => () => {
    const createQueueStatusMap = pipe(
      delay<number>(delayMs),
      mergeMap(this.props.getQueueStatus),
      pipe(toResult),
    );

    const queueIds$ =
      // if you do not specify a queueId to refresh for, refresh all of them
      // else only refresh the requested one
      isNumber(queueId)
        ? of(queueId)
        : from(this.props.queues.map(q => q.queueId));

    const subscription = queueIds$.pipe(createQueueStatusMap).subscribe(s => {
      this.setState({ queueStatuses: s }, () => subscription.unsubscribe());
    });
  };

  onNextItem = (): void => {
    if (!this.state.selectedQueue) {
      return;
    }
    const selectedQueueId = this.state.selectedQueue.queueId;
    this.refreshQueueStatuses(selectedQueueId)();
    this.props.onNextItemRequested(selectedQueueId);
  };

  render() {
    const { queues, currentQueueItem } = this.props;

    return (
      <>
        <QueueSelector
          queues={queues}
          queueStatuses={this.state.queueStatuses}
          onClick={this.refreshQueueStatuses()}
          onQueueSelect={this.setSelectedQueue}
          selectedQueue={this.state.selectedQueue}
        />
        {this.shouldShowNextQueueItemButton() && (
          <NextQueueItemButton
            style={buttonBorderStyle}
            onClick={this.onNextItem}
          />
        )}
        {this.shouldShowCompleteQueueItemButton(currentQueueItem) && (
          <CompleteQueueItemButton
            currentQueueItem={currentQueueItem}
            onClick={this.refreshQueueStatuses(currentQueueItem.queueId, 100)}
            style={buttonBorderStyle}
          />
        )}
      </>
    );
  }
}

const toResult = (
  responses$: Observable<QueueStatusResponse | QueueStatusFailure>,
): Observable<KeyValueMap<QueueStatusResponse | null>> => {
  // TODO the typings on the new operator functions aren't quite
  // correct yet so this type isn't able to be inferred correctly
  // manually coercing for now until this improves
  const [failure$, success$] = partition(isFailure)(responses$) as [
    Observable<QueueStatusFailure>,
    Observable<QueueStatusResponse>,
  ];

  const successfulResponseMap$ = createKeyValueMap(success$);

  return addFailures(failure$)(successfulResponseMap$);
};

const addFailuresToMap = (
  acc: KeyValueMap<QueueStatusResponse | null>,
  v: QueueStatusFailure,
): typeof acc => ({ ...acc, [v.failedQueueId]: null });

const addFailures = (failure$: Observable<QueueStatusFailure>) =>
  switchMap((successes: KeyValueMap<QueueStatusResponse>) =>
    reduce(addFailuresToMap, successes)(failure$),
  );

const isFailure = (
  response: QueueStatusFailure | QueueStatusResponse,
): response is QueueStatusFailure => has(response, 'failedQueueId');

const toKeyValueMap = (key: string) => <T extends object>(
  vs: T[],
): KeyValueMap<T> => KeyValueMap.usingPropertyKey(vs, key);

const collectAll = <T extends object>(acc: T[], val: T) => [...acc, val];

const createKeyValueMap = pipe(
  reduce<QueueStatusResponse, QueueStatusResponse[]>(collectAll, []),
  map<QueueStatusResponse[], KeyValueMap<QueueStatusResponse>>(
    toKeyValueMap('queueId'),
  ),
);
