import { Observable, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { displaySuccessNotification } from '../../common/actions/notification.actions';
import { AppEpic } from '../../common/types/app-epic.type';
import { handleEpicError } from '../../common/utils/epics';
import {
  addQueueItems,
  addQueueItemsBulk,
  addQueueItemsSuccess,
} from '../actions/queue.actions';
import { QueuesResource } from '../resources/queues.resource';

export const addQueueItemsEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(addQueueItems)),
    switchMap(({ payload: { queueId, annotatedDocumentIds } }) =>
      standardQueueItemAddHandler(
        QueuesResource.addQueueItems(
          state$.value,
          queueId,
          annotatedDocumentIds,
        ),
      ),
    ),
  );

export const addQueueItemsEpicBulk: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(addQueueItemsBulk)),
    switchMap(({ payload: { queueId } }) =>
      standardQueueItemAddHandler(
        QueuesResource.addQueueItemsBulk(state$.value, queueId),
      ),
    ),
  );

const standardQueueItemAddHandler = (stream: Observable<AjaxResponse>) => {
  return stream.pipe(
    switchMap(_ =>
      of(
        addQueueItemsSuccess(),
        displaySuccessNotification('Your items were successfully added!'),
      ),
    ),
    catchError(
      handleEpicError(
        'Could not add your selected items to the queue at the moment',
      ),
    ),
  );
};
