import { Dictionary, keyBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import styled from 'styled-components';
import { Group } from '../../accounts/models/group.model';
import { GroupsResource } from '../../accounts/resources/groups.resource';
import { AppState } from '../../app-state';
import { PrivateRoute } from '../../auth/containers/private-route.container';
import CardWithTitle from '../../common/components/card-with-title.component';
import { useFetchedState } from '../../common/hooks/use-fetched-state.hook';
import { SetState } from '../../common/types/set-state.type';
import getLocalRouteForMatch from '../../get-local-route-for-match';
import { fetchDocumentSetList } from '../actions/document-sets.actions';
import DocumentSetList from '../components/document-set-list.component';
import { DocumentSet } from '../models/document-set.model';
import { selectDocumentSetsCanAnnotate } from '../selectors/document-set.selectors';
import DocumentSetCreator from './document-set-creator.container';
import DocumentSetManagerComponent from './document-set-manager.container';

const MidGutter = styled.div`
  flex: 0 0 20px;
`;
const DSMContent = styled.div`
  display: flex;
  flex-flow: row;
`;

const DSMContentInsides = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
`;

type DocumentSetManagementProps = {
  onSetDocumentSets: () => void;
  documentSets: DocumentSet[];
  fetchAllGroups: Observable<Group[]>;
} & RouteComponentProps;

const DocumentSetManagementComponent: React.FC<DocumentSetManagementProps> = ({
  onSetDocumentSets,
  documentSets,
  fetchAllGroups,
  match,
  history,
}) => {
  const [groups] = useFetchedState<Group[]>(fetchAllGroups, []);

  const getLocalRoute = getLocalRouteForMatch(match);
  const [
    selectedDocumentSet,
    setSelectedDocumentSetState,
  ] = useState<DocumentSet | null>(null);
  const deselectDocumentSet = () => {
    history.push(match.url);
    setSelectedDocumentSetState(null);
  };

  useEffect(() => {
    onSetDocumentSets();
  }, [onSetDocumentSets]);

  return (
    <DSMContent>
      <DocumentSetList
        groups={groups || []}
        documentSets={documentSets}
        onCreateNew={() => history.push(getLocalRoute('create'))}
        onDocumentSelect={documentSet => {
          history.push(getLocalRoute(`${documentSet.documentSetId}`));
        }}
        selectedDocumentSet={selectedDocumentSet}
      />
      <MidGutter />
      <Switch>
        <PrivateRoute
          path={getLocalRoute('create')}
          render={routerProps => (
            <DSMContentInsides>
              <DocumentSetCreator
                {...routerProps}
                onCancel={() => history.goBack()}
              />
            </DSMContentInsides>
          )}
        />
        <PrivateRoute
          path={getLocalRoute(':documentSetId')}
          render={routerProps => (
            <DSMContentInsides>
              <DocumentSetManagementRoutes
                {...routerProps}
                documentSets={keyBy(documentSets, 'documentSetId')}
                deselectDocumentSet={deselectDocumentSet}
                setSelectedDocumentSetState={setSelectedDocumentSetState}
              />
            </DSMContentInsides>
          )}
        />
        <PrivateRoute
          render={() => (
            <DSMContentInsides>
              <CardWithTitle title="Please select a document set to continue"></CardWithTitle>
            </DSMContentInsides>
          )}
        />
      </Switch>
    </DSMContent>
  );
};

type DocumentSetManagementRoutesProps = {
  documentSets: Dictionary<DocumentSet>;
  deselectDocumentSet: () => void;
  setSelectedDocumentSetState: SetState<DocumentSet | null>;
} & RouteComponentProps<{ documentSetId: string }>;

const DocumentSetManagementRoutes: React.FC<DocumentSetManagementRoutesProps> = ({
  match,
  documentSets,
  deselectDocumentSet,
  setSelectedDocumentSetState,
}) => {
  const docSetIdFromRoute = match.params.documentSetId;
  const selectedDocumentSet = useMemo(() => {
    const documentSet = documentSets[docSetIdFromRoute];
    setSelectedDocumentSetState(documentSet);
    return documentSet;
  }, [docSetIdFromRoute, documentSets, setSelectedDocumentSetState]);

  return (
    <>
      {selectedDocumentSet && (
        <DocumentSetManagerComponent
          documentSet={selectedDocumentSet}
          deselectDocumentSet={deselectDocumentSet}
        />
      )}
    </>
  );
};

const DocumentSetManagement = connect(
  (state: AppState) => ({
    documentSets: selectDocumentSetsCanAnnotate(state),
    fetchAllGroups: GroupsResource.getAll(state).pipe(map(gr => gr.groups)),
  }),
  dispatch => ({
    onSetDocumentSets: () => dispatch(fetchDocumentSetList()),
  }),
)(DocumentSetManagementComponent);

export default withRouter(DocumentSetManagement);
