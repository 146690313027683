import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../app-state';
import { AnnotationSchema } from '../models/annotation-schema.model';
import { selectUserSelectableSchemasArray } from '../selectors/annotation-schema.selectors';
import { setEditingAnnotationSchema } from '../actions/annotation-schemas.actions';
import {
  AnnotationListDropdown,
  AnnotationListDropdownProps,
} from '../components/annotation-list-dropdown.component';
import { selectIsPermitted } from '../../accounts/selectors/users.selectors';
import {
  PermissionActions,
  PermissionResources,
} from '../../accounts/models/permission.model';

export default connect(
  (state: AppState) => ({
    allSchemas: selectUserSelectableSchemasArray(state),
    canModifySchema: (schemaId: number) =>
      selectIsPermitted(
        PermissionActions.Modify,
        PermissionResources.Schema,
        schemaId,
      )(state),
  }),
  dispatch => ({
    setEditingSchema: (schema: AnnotationSchema | null) =>
      dispatch(setEditingAnnotationSchema(schema)),
  }),
)((props: AnnotationListDropdownProps) => (
  <AnnotationListDropdown {...props} />
));
