import { createSelector } from 'reselect';
import { AppState } from '../../app-state';

export const selectUndoRedoPointer = (state: AppState) =>
  state.undoRedo.pointer;

export const selectUndoRedoHistory = (state: AppState) =>
  state.undoRedo.history;

export const selectCurrentUndoRedoStep = createSelector(
  [selectUndoRedoPointer, selectUndoRedoHistory],
  (pointer, history) => history[pointer],
);

export const selectCanUndo = createSelector(
  [selectUndoRedoPointer],
  pointer => pointer > -1,
);

export const selectCanRedo = createSelector(
  [selectUndoRedoPointer, selectUndoRedoHistory],
  (pointer, history) => pointer < history.length - 1,
);
