import { get } from 'lodash';
import { from, throwError } from 'rxjs';
import {
  catchError,
  concat,
  delay,
  filter,
  map,
  mergeMap,
  retryWhen,
  switchMap,
  take,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { AppState } from '../../app-state';
import { AppEpic } from '../../common/types/app-epic.type';
import { handleEpicError } from '../../common/utils/epics';
import { config } from '../../config/application.config';
import { setSourceDocument } from '../../documents/actions/source-document.actions';
import {
  appendPredictions,
  fetchPredictionModels,
  fetchPredictionsError,
  fetchSavedPredictions,
  getPredictionLabelsAsync,
  predictionsSuccess,
  setPredictionModels,
  userSpecificPredictionsJobStarted,
} from '../actions/predictions.actions';
import {
  PredictionLabels,
  Predictions,
  RawPredictionLabels,
} from '../models/predictions.model';
import { SavedPredictionResource } from '../resources/saved-prediction.resource';
import { UserSpecificPredictionResource } from '../resources/user-specific-prediction.resource';
import { PredictionModelResource } from '../resources/prediction-model.resource';

export const getPredictionLabels: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(getPredictionLabelsAsync.request)),
    switchMap(action =>
      RawPredictionLabels.get(state$.value).pipe(
        map(response =>
          getPredictionLabelsAsync.success(
            PredictionLabels.fromRawLabels(response.labels),
          ),
        ),
        catchError(
          handleEpicError(
            'Error retrieving available prediction labels',
            getPredictionLabelsAsync.failure,
          ),
        ),
      ),
    ),
  );

export const pollUserSpecificPredictionJob: AppEpic = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(userSpecificPredictionsJobStarted)),
    switchMap(action => {
      const { jobId } = action.payload;
      return UserSpecificPredictionResource.get(state$.value, jobId).pipe(
        retryWhen(errors =>
          errors.pipe(
            delay(config.predictions.retryIntervalMs),
            take(config.predictions.retryAttempts),
            concat(
              throwError(
                'The predictions you requested are taking too long to generate. Please try again later.',
              ),
            ),
          ),
        ),
        mergeMap(onPredictionFetchSuccess),
        catchError(
          handleEpicError('Error fetching predictions', fetchPredictionsError),
        ),
      );
    }),
  );
};

export const fetchSavedPredictionsEpic: AppEpic = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(fetchSavedPredictions)),
    mergeMap(action => {
      return SavedPredictionResource.get(
        state$.value,
        action.payload.documentTextId,
        action.payload.saveEventTimestamp,
      );
    }),
    mergeMap(onPredictionFetchSuccess),
  );
};

function onPredictionFetchSuccess(p: Predictions) {
  return from([predictionsSuccess(), appendPredictions(p)]);
}

export const fetchSavedPredictionsOnSourceDocumentUpdate: AppEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter(isActionOf(setSourceDocument)),
    map(_ => {
      const state: AppState = state$.value;
      const documentTextId: number | null = get(
        state,
        'sourceDocument.meta.documentTextId',
      );
      const annotationTimestamp: string | null = get(
        state,
        'sourceDocument.meta.lastAnnotationTimestamp',
        null,
      );

      if (!documentTextId) {
        throw new Error(
          'Unable to fetch saved predictions. No documentTextId.',
        );
      }
      return fetchSavedPredictions(
        documentTextId,
        unixTimestampIfNotNull(annotationTimestamp),
      );
    }),
    catchError(
      handleEpicError(
        'Error fetching saved predictions',
        fetchPredictionsError,
      ),
    ),
  );
};

export const fetchPredictionModelsEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(fetchPredictionModels)),
    switchMap(() => PredictionModelResource.getPredictionModels(state$.value)),
    map(models => setPredictionModels(models)),
    catchError(handleEpicError('Error retrieving available prediction models')),
  );

function unixTimestampIfNotNull(time: string | null): null | number {
  if (time) {
    return new Date(time).getTime();
  }
  return null;
}
