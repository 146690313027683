import { createAction, createAsyncAction } from 'typesafe-actions';
import { PredictionLabels, Predictions } from '../models/predictions.model';
import { PredictionModel } from '../models/prediction-model.model';

enum Types {
  SET_LABELS_TO_PREDICT_ON = '[User] The labels that we want to run preductions on has changed.',
  SET_ACTIVE_LABEL_GROUP = '[User] The active label group that we want to run predictions on has changed.',
  RUN_PREDICTIONS = "[User] We're running predictions for a set of labels given some document text.",
  RUN_LOOKUP_PREDICTIONS = "[User] We're running lookup predictions.",
  REQUEST_PREDICTIONS = "[User] We're putting in a request with the service for some predictions for a set of labels given some document text.",
  PREDICTIONS_SUCCESS = '[Epic] We got back a successful set of predictions and can stop asking for it.',
  USER_SPECIFIC_PREDICTION_JOB_STARTED = '[Epic] The user specific predictions job request was accepted. We will need to poll the service until the job has finished to get the results',
  FETCH_SAVED_PREDICTIONS = '[Epic] Fetching all predictions for this document that have not been converted to annotations since the last annotation save event',
  FETCH_PREDICTIONS_ERROR = 'FETCH_PREDICTIONS_ERROR',
  APPEND_PREDICTIONS = 'APPEND_PREDICTIONS',
  FETCH_PREDICTION_MODELS = 'FETCH_PREDICTION_MODELS',
  SET_PREDICTION_MODELS = 'SET_PREDICTION_MODELS',
}

export const getPredictionLabelsAsync = createAsyncAction(
  'getPredictionLabelsAsync.request: On app start, retrieve the list of labels from the service',
  'getPredictionLabelsAsync.success: We have successfully retrieved the labels from the service',
  'getPredictionLabelsAsync.failure: We failed to retrieve the list of available prediction labels',
)<undefined, PredictionLabels, string>();

export const setLabelsToPredictOn = createAction(
  Types.SET_LABELS_TO_PREDICT_ON,
  resolvePayload => (labels: string[]) => resolvePayload({ labels }),
);

export const setActiveLabelGroup = createAction(
  Types.SET_ACTIVE_LABEL_GROUP,
  resolvePayload => (labelGroupName: string) =>
    resolvePayload({ labelGroupName }),
);

export const runPredictions = createAction(Types.RUN_PREDICTIONS);
export const runLookupPredictions = createAction(Types.RUN_LOOKUP_PREDICTIONS);

export const requestPredictions = createAction(Types.REQUEST_PREDICTIONS);

export const predictionsSuccess = createAction(Types.PREDICTIONS_SUCCESS);

export const userSpecificPredictionsJobStarted = createAction(
  Types.USER_SPECIFIC_PREDICTION_JOB_STARTED,
  resolvePayload => (jobId: string) => resolvePayload({ jobId }),
);

export const fetchSavedPredictions = createAction(
  Types.FETCH_SAVED_PREDICTIONS,
  resolvePayload => (
    documentTextId: number,
    saveEventTimestamp: number | null,
  ) => resolvePayload({ documentTextId, saveEventTimestamp }),
);

export const fetchPredictionsError = createAction(
  Types.FETCH_PREDICTIONS_ERROR,
  resolvePayload => (error: string) => resolvePayload({ error }),
);

export const appendPredictions = createAction(
  Types.APPEND_PREDICTIONS,
  resolvePayload => (predictions: Predictions) => resolvePayload(predictions),
);

export const fetchPredictionModels = createAction(
  Types.FETCH_PREDICTION_MODELS,
);

export const setPredictionModels = createAction(
  Types.SET_PREDICTION_MODELS,
  resolvePayload => (models: PredictionModel[]) => resolvePayload(models),
);
