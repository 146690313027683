import { from, of } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { showLoginModal } from '../../auth/actions/auth.actions';
import { displayErrorNotification } from '../actions/notification.actions';

/**
 * Handles an epic error by
 *  1) Logging it to the console
 *  2) Returning an observable with an action to display an app notification.
 *  3) If passed, also includes a custom action to pass the error to.
 * @param title Error title to display.
 * @param errorAction A custom error action to emit.
 */
export const handleEpicError = (title = 'Error', errorAction?: ErrorAction) => (
  err: string | AjaxError,
) => {
  if (err instanceof AjaxError && err.status === 401) {
    return of(showLoginModal());
  }

  let errorMsg = err.toString();
  if (typeof err === 'object') {
    errorMsg = (err.response && err.response.error) || err.message;
  }

  const emitActions = [
    errorAction ? errorAction(title + ':' + errorMsg) : undefined,
    displayErrorNotification(title, errorMsg),
  ].filter(action => !!action);

  console.error(err);
  return from(emitActions);
};

export type ErrorAction = (error: string) => any;
