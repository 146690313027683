import { createAction } from 'typesafe-actions';
import { AnnotationPage } from '../models/annotation-page.model';
import { Map } from 'immutable';

enum Types {
  FETCH_ANNOTATION_PAGES = 'FETCH_ANNOTATION_PAGES',
  FETCH_ANNOTATION_PAGES_ERROR = 'FETCH_ANNOTATION_PAGES_ERROR',
  POPULATE_ANNOTATION_PAGES = 'POPULATE_ANNOTATION_PAGES',
}

export const fetchAnnotationPages = createAction(
  Types.FETCH_ANNOTATION_PAGES,
  resolvePayload => (documentId: number) => resolvePayload({ documentId }),
);

export const fetchAnnotationPagesError = createAction(
  Types.FETCH_ANNOTATION_PAGES_ERROR,
  resolvePayload => (error: string) => resolvePayload({ error }),
);

export const populateAnnotationPages = createAction(
  Types.POPULATE_ANNOTATION_PAGES,
  resolvePayload => (
    pages: Map<number, AnnotationPage>,
    annotatedDocumentId: number,
    documentTextId: number,
    ocrVersion: string,
    ocrxOffsets?: { [page: string]: number[] },
  ) =>
    resolvePayload({
      pages,
      annotatedDocumentId,
      documentTextId,
      ocrxOffsets,
      ocrVersion,
    }),
);
